import React from "react";
import { observer } from "mobx-react";
import { ControlGroup, Icon } from "@blueprintjs/core";

import formatValueWithUnit from "shared/helpers/formatValueWithUnit/formatValueWithUnit";
import { InfluenceStatus } from "modules/Influence/Influence.model";
import { influenceTypeNames } from "modules/Influence/influenceConstants";
import {
  StyledButtonIcon,
  StyledCard,
  StyledHeaderTitle,
  StyledInfluenceInput
} from "modules/Influence/InfluenceStyles";

import Row from "shared/components/Row/Row";
import { useStores } from "store/Store";

type Props = {
  readOnly: boolean;
};

function PriceAdjustmentForm(props: Props) {
  const { readOnly } = props;
  const { influenceStore } = useStores();
  const { parameters, status, updateInputValue } = influenceStore;
  const { inputValue, value } = parameters;

  const renderContent = () => {
    if (readOnly) {
      return <div className="ml-auto">{formatValueWithUnit(value, "percent")}</div>;
    }

    const isLoading = status.includes(InfluenceStatus.LOADING);

    return (
      <>
        <StyledButtonIcon disabled={isLoading}>
          <Icon icon="percentage" />
        </StyledButtonIcon>
        <StyledInfluenceInput
          allowNumericCharactersOnly
          clampValueOnBlur
          data-testid="influence-percent-input"
          disabled={isLoading}
          fill
          max={500}
          min={-100}
          onValueChange={updateInputValue}
          placeholder="0"
          value={inputValue}
        />
      </>
    );
  };

  return (
    <StyledCard className="p-3 mb-2">
      <Row className="align-items-center">
        <div className="col-5">
          <StyledHeaderTitle>{influenceTypeNames.PA}</StyledHeaderTitle>
        </div>
        <div className="col-7 pl-0">
          <ControlGroup className="align-items-center" data-testid="influence-percent-value">
            <strong className="mr-2">Adj. </strong>
            {renderContent()}
          </ControlGroup>
        </div>
      </Row>
    </StyledCard>
  );
}

export default observer(PriceAdjustmentForm);
