import React from "react";
import { format, parseISO } from "date-fns";
import { css, Global } from "@emotion/react";
import { Position, Tooltip } from "@blueprintjs/core";

import DataTableCellValue from "shared/components/DataTable/DataTableCellValue/DataTableCellValue";
import DateTimeCell from "shared/components/DataTable/DateTimeCell/DateTimeCell";
import minMaxDataTableCell from "../../Influence/minMaxDataTableCell/minMaxDataTableCell";
import StatusIndicator from "shared/components/StatusIndicator/StatusIndicator";
import { Cell, Row } from "types/react-table.types";
import ListCellRenderer from "shared/components/ListCellRenderer/ListCellRenderer";
import { CellType } from "types/DataTable.types";

interface PriceAdjustment {
  type: "PA";
  value: number;
}

interface MinMaxAdjustment {
  type: "MM";
  value: string;
}

interface AbsolutePriceAdjustment {
  type: "AP";
  value: {
    value: number;
    currency: number;
  };
}

interface CompSensAdjustment {
  type: "CS";
  value: number;
}

type Influence = PriceAdjustment | MinMaxAdjustment | AbsolutePriceAdjustment | CompSensAdjustment;

const renderValue = (influence: Influence, options?: object) => {
  switch (influence.type) {
    case "PA": {
      return <DataTableCellValue options={options} type={CellType.PercentRelative} value={influence.value} />;
    }
    case "MM": {
      return <DataTableCellValue options={options} type={CellType.MinMax} value={influence.value} />;
    }
    case "AP": {
      return <DataTableCellValue options={options} type={CellType.Currency} value={influence.value.value} />;
    }
    default:
      return influence.value;
  }
};

const getValue = (row: Row, name: string, options?: object) => {
  let value = row.original[name];

  if (row.depth > 0) {
    value = row.values[name];
  }

  return name === "value" ? renderValue({ type: row.original.typeKey, value }, options) : value;
};

const renderValueWithTooltip = (row: Row) => {
  const {
    id,
    original: { value }
  } = row;
  const renderValueText = getValue(row, "value", { withLimits: true });

  if (Array.isArray(value)) {
    const sortedByHighestNDO = value.slice().sort((ndo1, ndo2) => (ndo1.end > ndo2.end ? 1 : -1));

    const tooltipContent = (
      <div>
        {sortedByHighestNDO.map(
          (range, index) =>
            range.ndo && (
              // eslint-disable-next-line react/no-array-index-key
              <div key={`tooltip-${id}-${index}`} data-testid="tooltip-min-max-row">
                <strong>{`NDO ${range.ndo.start}-${range.ndo.end}`}: </strong>
                {minMaxDataTableCell(range)}
              </div>
            )
        )}
      </div>
    );

    return (
      <>
        <Global
          styles={css`
            .bp3-tooltip .bp3-popover-content {
              padding: 5px 10px;
            }
            .bp3-popover-target {
              max-width: 100%;
            }
          `}
        />

        <Tooltip className="w-100 text-truncate" content={tooltipContent} position={Position.BOTTOM}>
          {renderValueText}
        </Tooltip>
      </>
    );
  }

  return renderValueText;
};

export const cellRenderers = (computedDateFormat: string) => ({
  analystName: ({ row }) => getValue(row, "analystName"),
  comment: ({ value }: Cell) => <span title={value}>{value}</span>,
  influenceId: ({ row, value }) => !row.depth && value,
  maxDepDate: ({ value }) => value && format(parseISO(value), computedDateFormat),
  minDepDate: ({ value }) => value && format(parseISO(value), computedDateFormat),
  numberOfFlights: ({ row }) => getValue(row, "numberOfFlights"),
  owMarkets: ({ value }) => value && <ListCellRenderer list={value} showCounter />,
  status: ({ value, row }: Cell) =>
    !row.depth ? <StatusIndicator isActive={value === "active"} text={value} /> : null,
  updatedOn: ({ row }) => <DateTimeCell value={`${getValue(row, "updatedOn")}Z`} />,
  value: ({ row }) => renderValueWithTooltip(row)
});
