// @flow
import React, { useEffect, useRef, useState } from "react";
import { Button, Checkbox, Classes, FormGroup, InputGroup, Intent, Menu, Popover, Position } from "@blueprintjs/core";
import { observer } from "mobx-react";
import styled from "@emotion/styled";
import clsx from "clsx";
import { isPast, isToday, parseISO, startOfToday, subYears } from "date-fns";
import isEmpty from "lodash.isempty";

import EventsAutocomplete from "./EventsAutocomplete";
import SidebarDateRangeSelect from "shared/components/Sidebar/SidebarDateRangeSelect/SidebarDateRangeSelect";
import TagSelect from "modules/Events/TagSelect/TagSelect";
import { categories } from "modules/Events/categories";
import ColumnWrapper from "modules/Events/ColumnWrapper/ColumnWrapper";
import displayConflictErrors from "modules/Events/displayConflictErrors/displayConflictErrors";
import { renderTagSelect } from "modules/Events/renderTagSelect/renderTagSelect";
import { useStores } from "store/Store";
import EventsDateAutocomplete from "./EventsDateAutocomplete";
import { Status } from "modules/App/Status";
import EventManagementNameTooltip from "./EventManagementNameTooltip";
import EventWarning from "./EventWarning";

const StyledPopoverContent = styled(Menu)`
  &.bp3-menu {
    max-height: 310px;
    max-width: 276px;
    min-height: 80px;
    width: 276px;
  }
`;

const StyledFormGroup = styled(FormGroup)`
  .bp3-label {
    margin-bottom: 0.5rem !important;
  }
`;

function EventsManagementGeneralColumn({ isAddingEvent }: { isAddingEvent: boolean }) {
  const {
    eventsManagementStore: {
      activeSuggestionType,
      changeField,
      clearExistingEvents,
      eventCategories,
      eventCategoriesById,
      getEventById,
      getExistingEvents,
      isConflicted,
      isSuggestModalLoading,
      obsoletePlaces,
      page: { clonedModalEvent = {}, modalEvent = {}, suggestedEvents = {} }
    },
    systemSettingsStore
  } = useStores();
  const dateAutocompleteRef = useRef(null);
  const [isDateSelectActive, setIsDateSelectActive] = useState(false);
  const [isDateAutocomplete, setIsDateAutocomplete] = useState(false);
  const { categoryId, dateRange = {}, name, nonWorkingDay } = modalEvent;
  const { data, status } = suggestedEvents;

  useEffect(() => {
    if (isAddingEvent && dateRange.start && dateRange.end && isDateSelectActive) {
      setIsDateAutocomplete(true);
      getExistingEvents({ dateRange }, "dateRange");
    }
  }, [dateRange]);

  useEffect(() => {
    const handleClickOutside = event => {
      // Do nothing if clicking ref's element or descendent elements
      if (dateAutocompleteRef?.current && !dateAutocompleteRef.current.contains(event.target)) {
        clearExistingEvents();
        setIsDateAutocomplete(false);
        setIsDateSelectActive(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dateAutocompleteRef]);

  const styledTagSelectClassName = clsx("category-select", Classes.POPOVER_DISMISS, {
    [`-color-${categories[categoryId]?.key}`]: !!categoryId
  });

  const parsedStartDate = dateRange?.start && parseISO(dateRange?.start);
  const isPastDate = parsedStartDate && isPast(parsedStartDate) && !isToday(parsedStartDate);
  const isNameAndDateConflicting = displayConflictErrors(modalEvent, clonedModalEvent);

  const intent = isNameAndDateConflicting ? Intent.DANGER : Intent.NONE;
  const inputProps = { intent, maxLength: 50 };

  const setFieldsFromAutocomplete = ({ value: id }) => {
    getEventById(id);
    clearExistingEvents();
    setIsDateAutocomplete(false);
  };

  const renderNameInput = () => {
    return isAddingEvent ? (
      <EventsAutocomplete
        dataTestid="event-general-name"
        inputProps={inputProps}
        onSelectItem={setFieldsFromAutocomplete}
        title="Name"
      />
    ) : (
      <StyledFormGroup className="font-weight-bold mb-3" label="Name" labelFor="event-general-name">
        <EventManagementNameTooltip maxLength={inputProps.maxLength} name={name}>
          <InputGroup
            autoComplete="off"
            className="w-100"
            data-testid="event-general-name"
            disabled={isSuggestModalLoading}
            id="event-general-name"
            intent={intent}
            maxLength={inputProps.maxLength}
            onChange={({ target }) => changeField("name", target.value)}
            placeholder="Enter event name"
            value={name}
          />
        </EventManagementNameTooltip>
      </StyledFormGroup>
    );
  };

  const detailsAutocomplete = (
    <StyledPopoverContent>
      <EventsDateAutocomplete ref={dateAutocompleteRef} onSelectItem={item => setFieldsFromAutocomplete(item)} />
    </StyledPopoverContent>
  );

  const isDateRangeLoading = activeSuggestionType === "dateRange" && status === Status.LOADING;

  const isObsoletePlace = !isEmpty(Object.values(obsoletePlaces).flat());

  const renderDateInput = () => {
    const dateRangeInput = (
      <SidebarDateRangeSelect
        className="date-range-select"
        computedDateFormat={systemSettingsStore.computedDateFormat}
        dates={dateRange}
        extraProps={{
          endInputProps: { disabled: isSuggestModalLoading, intent, onClick: () => setIsDateSelectActive(true) },
          startInputProps: { disabled: isSuggestModalLoading, intent, onClick: () => setIsDateSelectActive(true) }
        }}
        isLoading={isDateRangeLoading}
        minDate={subYears(startOfToday(), 20)}
        onChange={filterValue => changeField("dateRange", filterValue)}
        popoverProps={{
          boundary: "viewport"
        }}
        title="Date range"
        withResetDates={false}
      />
    );

    return isAddingEvent ? (
      <Popover
        boundary="viewport"
        content={detailsAutocomplete}
        disabled={isSuggestModalLoading || isEmpty(data)}
        fill
        isOpen={isDateSelectActive && isDateAutocomplete}
        modifiers={{ arrow: false }}
        onClose={() => setIsDateSelectActive(false)}
        popoverClassName="-event-modal"
        position={Position.BOTTOM}
      >
        {dateRangeInput}
      </Popover>
    ) : (
      dateRangeInput
    );
  };

  return (
    <ColumnWrapper type="general">
      {renderNameInput()}
      <TagSelect
        className={styledTagSelectClassName}
        disabled={isSuggestModalLoading}
        items={eventCategories}
        onChange={item => changeField("categoryId", item.value)}
        popoverProps={{
          boundary: "viewport",
          popoverClassName: clsx(Classes.POPOVER_DISMISS, "sidebar-select", "tag-select", "-event-modal")
        }}
        renderSelect={itemId => renderTagSelect(eventCategoriesById[itemId])}
        selectedItem={categoryId}
        title="Category"
      >
        <Button rightIcon="double-caret-vertical" text="Select event category" />
      </TagSelect>

      {renderDateInput()}
      <Checkbox
        checked={nonWorkingDay}
        data-testid="events-non-working-day-checkbox"
        disabled={isSuggestModalLoading}
        label="Public Holiday"
        onChange={e => changeField("nonWorkingDay", e.target.checked)}
      />
      {isNameAndDateConflicting && (
        <EventWarning text="Event with the same name and date(s) already exist." type="error" />
      )}
      {!isNameAndDateConflicting && isPastDate && (
        <EventWarning text="One or more selected dates are in the past." type="warning" />
      )}
      {!isNameAndDateConflicting && isConflicted && (
        <EventWarning
          link="https://help.cirrus.ai/en/articles/4911231-events"
          text="One or more selected locations are mutually exclusive. Locations of lower granularity will be removed to avoid duplicates once an event is saved."
          type="warning"
        />
      )}
      {isObsoletePlace && (
        <EventWarning text="This location or provenance is no longer serviced by airline." type="warning" />
      )}
    </ColumnWrapper>
  );
}

export default observer(EventsManagementGeneralColumn);
