// @flow

import React from "react";
import { observer } from "mobx-react";
import { Position, Tooltip } from "@blueprintjs/core";

type Props = {
  children: React.ReactNode,
  maxLength: number,
  name: string
};

function EventManagementNameTooltip({ children, name, maxLength }: Props) {
  const isLimitReached = name.length >= maxLength;

  if (!isLimitReached) {
    return children;
  }

  return (
    <Tooltip
      boundary="viewport"
      content={<span>Limit of {maxLength} characters has been reached</span>}
      isOpen={isLimitReached}
      position={Position.BOTTOM}
      targetClassName="w-100 d-flex position-relative"
      targetTagName="div"
      wrapperTagName="div"
    >
      {children}
    </Tooltip>
  );
}

export default observer(EventManagementNameTooltip);
