import React from "react";
import { Menu } from "@blueprintjs/core";
import { Tab } from "models/Tab/Tab.model";

import { useStores } from "store/Store";

type Props = {
  forceRenameLabel: Function;
  tab: Tab;
};

function SaveAsTeamAnalysisMenuItem({ forceRenameLabel, tab }: Props) {
  const { tabsStore } = useStores();

  const handleClick = () => {
    if (tab.hasDefaultLabel) {
      return forceRenameLabel("team");
    }
    tabsStore.saveAsTeamAnalysis(tab.id);
  };

  return <Menu.Item icon="bookmark" onClick={handleClick} text="Save as Team Analysis" />;
}

export default SaveAsTeamAnalysisMenuItem;
