// @flow

import React, { useEffect } from "react";
import isEmpty from "lodash.isempty";
import styled from "@emotion/styled";
import { Button, Classes, Colors, Dialog } from "@blueprintjs/core";
import { observer } from "mobx-react";

import EventsManagementGeneralColumn from "./EventsManagementGeneralColumn";
import EventsManagementLocationSelects from "./EventsManagementLocationSelects";
import EventsManagementProvenanceSelects from "./EventsManagementProvenanceSelects";
import renderToggleForm from "modules/Events/renderToggleForm/renderToggleForm";
import displayConflictErrors from "modules/Events/displayConflictErrors/displayConflictErrors";
import getPlaceCodes from "modules/Events/getPlaceCodes/getPlaceCodes";
import { useStores } from "store/Store";
import isNumber from "shared/helpers/isNumber/isNumber";

const StyledEventsManagementsDialog = styled(Dialog)`
  max-width: 100%;
  width: auto;

  .event-column-general {
    background-color: ${Colors.LIGHT_GRAY5};
  }

  .event-column-provenances .bp3-heading {
    display: none;
  }

  .event-provenances-title {
    font-weight: 600;
  }

  .bp3-dialog-header,
  .bp3-dialog-footer {
    z-index: 1;
  }

  .bp3-dialog-footer {
    position: relative;
  }

  .bp3-heading {
    font-weight: bold !important;
  }

  .bp3-dialog-body {
    height: 400px;
    min-width: 300px;
    width: 300px;

    & + .bp3-dialog-body {
      border-left: 1px solid ${Colors.LIGHT_GRAY1};
    }
  }

  .bp3-input {
    box-sizing: border-box;
  }

  .bp3-control-group {
    transform: none; //fix disappearing shadow on right side of input
  }
`;

const StyledDialogFooter = styled("div")`
  box-shadow: 0 -1px 0 ${Colors.BLACK}26;
`;

function EventManagementsModal() {
  const { eventsManagementStore, modalStore } = useStores();
  const { submitEvent, page, changeField, initEventModal, isEventEqual, clearEvent } = eventsManagementStore;
  const { modalActive, setModal } = modalStore;

  const { selectedEvent = {}, modalEvent = {}, clonedModalEvent = {} } = page;
  const {
    categoryId,
    dateRange,
    isLocationGlobal,
    isProvenanceGlobal,
    locations,
    name,
    nonWorkingDay,
    provenances,
    provenanceType
  } = modalEvent;

  const isAddingEvent = modalActive === "addEvent";
  const modalTitle = isAddingEvent ? "Add New Event" : "Edit Event";

  useEffect(() => {
    initEventModal(isAddingEvent);
  }, [initEventModal, isAddingEvent, selectedEvent]);

  const selectedLocationCountries = locations?.countries || [];
  const selectedLocationMetros = locations?.metros || [];
  const selectedLocationAirports = locations?.airports || [];
  const selectedProvenanceCountries = provenances?.countries || [];
  const selectedProvenanceMetros = provenances?.metros || [];
  const selectedProvenanceAirports = provenances?.airports || [];
  const displayConflicts = displayConflictErrors(modalEvent, clonedModalEvent);

  const locationsAreSelected =
    !isLocationGlobal && [selectedLocationAirports, selectedLocationCountries, selectedLocationMetros].every(isEmpty);

  const provenancesAreSelected =
    !isProvenanceGlobal &&
    ((provenanceType === "airports" && isEmpty(selectedProvenanceAirports)) ||
      (provenanceType === "countries" && isEmpty(selectedProvenanceCountries)) ||
      (provenanceType === "metros" && isEmpty(selectedProvenanceMetros)));

  const isSaveButtonDisabled =
    [name, dateRange?.start, dateRange?.end].some(isEmpty) ||
    !isNumber(categoryId) ||
    locationsAreSelected ||
    provenancesAreSelected ||
    isEventEqual ||
    displayConflicts;

  const locationColumn = renderToggleForm({
    isChecked: isLocationGlobal,
    name: "Location(s)",
    renderSelects: <EventsManagementLocationSelects />,
    toggleFunction: value => changeField("isLocationGlobal", value),
    type: "locations"
  });

  const provenanceColumn = renderToggleForm({
    isChecked: isProvenanceGlobal,
    name: "Provenance(s)",
    renderSelects: <EventsManagementProvenanceSelects />,
    toggleFunction: value => changeField("isProvenanceGlobal", value),
    type: "provenances"
  });

  const submitParams = {
    categoryId,
    dateRange,
    locations: isLocationGlobal
      ? null
      : {
          airports: getPlaceCodes(locations?.airports),
          countries: getPlaceCodes(locations?.countries),
          metros: getPlaceCodes(locations?.metros)
        },
    name,
    nonWorkingDay,
    provenances: isProvenanceGlobal
      ? null
      : {
          ...(provenanceType === "airports" && { airports: getPlaceCodes(selectedProvenanceAirports) }),
          ...(provenanceType === "countries" && { countries: getPlaceCodes(selectedProvenanceCountries) }),
          ...(provenanceType === "metros" && { metros: getPlaceCodes(selectedProvenanceMetros) })
        }
  };

  const openAddEventModal = () => {
    setModal("addEvent");
  };

  const closeModal = () => {
    clearEvent();
    modalStore.closeModal();
  };

  const onModalClose = () => {
    if (!isEventEqual && isAddingEvent) {
      setModal("confirmation", {
        action: closeModal,
        buttonText: "Quit",
        cancelAction: openAddEventModal,
        header: "You have unsaved changes",
        text: "Cancelling will discard all unsaved changes. You won’t be able to restore your work.",
        title: "Quit without saving?"
      });
    } else {
      closeModal();
    }
  };

  const onSaveClick = () => {
    submitEvent(submitParams, !isAddingEvent && selectedEvent?.id).then(
      () => modalStore.closeModal(),
      () => {}
    );
  };

  return (
    <StyledEventsManagementsDialog
      canEscapeKeyClose={false}
      canOutsideClickClose={false}
      className="p-0"
      isOpen={["addEvent", "editEvent"].includes(modalActive)}
      onClose={onModalClose}
      title={modalTitle}
    >
      <div className="d-flex overflow-auto">
        <EventsManagementGeneralColumn isAddingEvent={isAddingEvent} />
        {locationColumn}
        {provenanceColumn}
      </div>

      <StyledDialogFooter className={`${Classes.DIALOG_FOOTER} mx-0 px-3 py-2`}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button
            className="ml-auto px-3"
            data-testid="events-management-modal-save-btn"
            disabled={isSaveButtonDisabled}
            intent="primary"
            onClick={onSaveClick}
            text="Save"
          />
        </div>
      </StyledDialogFooter>
    </StyledEventsManagementsDialog>
  );
}

export default observer(EventManagementsModal);
