import { format, parseISO, startOfWeek, parse } from "date-fns";
import groupBy from "lodash.groupby";

import isNumber from "shared/helpers/isNumber/isNumber";
import {
  dayPayloadData,
  groupedDayDataByWeeksTypes,
  transformedHeatmapDataTypes,
  weekDaysType,
  weekPayloadData
} from "./DashboardHeatmapStore.types";

export const initColumns = [
  ["finalRevenueGroup", ["xDayRevenuePotential"]],
  ["revenueGroup", ["raskDiffToBaseline"]],
  ["loadFactorGroup", ["loadFactor", "diffToElb"]],
  ["influenceGroup", ["xDayInfluenceImpactBuild"]]
];

const computeDailyMetrics = (data: groupedDayDataByWeeksTypes[]): weekDaysType | {} => {
  const DAY_KEYS = {
    1: "depDayMonday",
    2: "depDayTuesday",
    3: "depDayWednesday",
    4: "depDayThursday",
    5: "depDayFriday",
    6: "depDaySaturday",
    7: "depDaySunday"
  };
  return data.reduce(
    (acc, el) => ({
      ...acc,
      [DAY_KEYS[el.depDow]]: {
        depDate: el.depDate,
        diffToElb: el.diffToElb,
        loadFactor: el.loadFactor,
        numberOfFlights: el.numberOfFlights,
        raskDiffToBaseline: el.raskDiffToBaseline,
        xDayInfluenceImpactBuild: el.xDayInfluenceImpactBuild,
        xDayRevenuePotential: el.xDayRevenuePotential
      }
    }),
    {}
  );
};

export const transformPayloadToHeatmapData = (
  dayData: dayPayloadData,
  weekData: weekPayloadData
): transformedHeatmapDataTypes[] => {
  const mapDayDataToWeeks = dayData.rows.map(item => {
    const formattedDate = startOfWeek(parse(item.depDate, "yyyy-MM-dd", new Date()), { weekStartsOn: 1 });

    return {
      ...item,
      depWeek: format(formattedDate, "yyyy-MM-dd")
    };
  });

  const mapWeekData = weekData.rows.map(item => ({
    ...item,
    depWeek: format(parseISO(item.depWeek), "yyyy-MM-dd")
  }));

  const groupedDayDataByWeeks: groupedDayDataByWeeksTypes[] = groupBy(mapDayDataToWeeks, cell => cell.depWeek);
  const groupedWeekDataByWeeks = groupBy(mapWeekData, cell => cell.depWeek);

  return Object.entries(groupedDayDataByWeeks).reduce((acc: transformedHeatmapDataTypes[], item) => {
    const depWeek = item[0];
    return [
      ...acc,
      {
        depWeek,
        // daily metrics //
        ...((Array.isArray(item[1]) && computeDailyMetrics(item[1])) || {}),
        // weekly metrics //
        diffToElb: isNumber(groupedWeekDataByWeeks[depWeek][0].diffToElb)
          ? Number(groupedWeekDataByWeeks[depWeek][0].diffToElb.toFixed(2))
          : null,
        loadFactor: isNumber(groupedWeekDataByWeeks[depWeek][0].loadFactor)
          ? Number(groupedWeekDataByWeeks[depWeek][0].loadFactor.toFixed(2))
          : null,
        numberOfFlightsOriginal: isNumber(groupedWeekDataByWeeks[depWeek][0].numberOfFlights)
          ? Number(groupedWeekDataByWeeks[depWeek][0].numberOfFlights.toFixed(2))
          : null,
        raskDiffToBaseline: isNumber(groupedWeekDataByWeeks[depWeek][0].raskDiffToBaseline)
          ? Number(groupedWeekDataByWeeks[depWeek][0].raskDiffToBaseline.toFixed(2))
          : null,
        xDayInfluenceImpactBuild: isNumber(groupedWeekDataByWeeks[depWeek][0].xDayInfluenceImpactBuild)
          ? Number(groupedWeekDataByWeeks[depWeek][0].xDayInfluenceImpactBuild.toFixed(2))
          : null,
        xDayRevenuePotential: isNumber(groupedWeekDataByWeeks[depWeek][0].xDayRevenuePotential)
          ? Number(groupedWeekDataByWeeks[depWeek][0].xDayRevenuePotential.toFixed(2))
          : null
      }
    ];
  }, []);
};
