import { Position, Switch, Tooltip } from "@blueprintjs/core";
import React from "react";
import { observer } from "mobx-react";

type Props = {
  checked: boolean,
  disabled: boolean,
  onChange: Function
};

function FiltersSwitch(props: Props) {
  const { checked, disabled, onChange } = props;
  return (
    <Tooltip
      content="Temporarily deactivate applied filters without removing them"
      hoverOpenDelay={2000}
      position={Position.BOTTOM}
    >
      <Switch
        checked={checked}
        className="mx-2 mb-0"
        data-testid="filter-toggle"
        disabled={disabled}
        onChange={onChange}
      />
    </Tooltip>
  );
}

export default observer(FiltersSwitch);
