import React from "react";
import { observer } from "mobx-react";
import { format, parseISO } from "date-fns";

import { useStores } from "store/Store";

type Props = {
  value: string | null;
};

function DateTimeCell({ value }: Props) {
  const { systemSettingsStore } = useStores();

  if (!value) {
    return null;
  }

  const date = parseISO(value);
  const formatter = `${systemSettingsStore.computedDateFormat} HH:mm:ss`;

  return <>{format(date, formatter)}</>;
}

export default observer(DateTimeCell);
