export type Pagination = {
  pageIndex: number,
  pageSize: number,
  pageCount: number,
  totalRows: number
}

export enum CellType {
  Currency = "currency",
  CurrencyPrecise = "currency-precise",
  Date = "date",
  DateMonth = "date-month",
  DayWeekLong = "day-week-long",
  Influence = "influence",
  InfluenceCurrency = "influence-currency",
  InfluencePercent = "influence-percent",
  Integer = "integer",
  MinMax = "min-max",
  Percent = "percent",
  PercentRelative = "percent-relative",
  Quarter = "quarter",
  String = "string"
}
