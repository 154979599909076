import React, { useContext } from "react";
import { Classes, Colors, Tag, TagProps } from "@blueprintjs/core";
import { format, isValid, parseISO } from "date-fns";

import { filterTitleFromArray } from "../../../helpers/filterTitleValue/filterTitleValue";
import TagFilterToggle from "../TagFilterToggle/TagFilterToggle";
import styled from "@emotion/styled";
import { observer } from "mobx-react";
import TagsListContext from "../TagsListContext/TagsListContext";

const StyledValueTag = styled(Tag)<TagProps & { disabled?: boolean }>`
  background: ${({ disabled }) => (disabled ? Colors.LIGHT_GRAY2 : Colors.GRAY1)}!important;
  color: ${({ disabled }) => (disabled ? Colors.GRAY2 : Colors.LIGHT_GRAY5)}!important;
`;

type Props = {
  name?: string;
  title: string;
  value: string | number | boolean | string[] | object[] | { end: string; start: string };
};

function ChipContent(props: Props) {
  const { value, title, name } = props;

  const {
    additionalTagLabels,
    computedDateFormat,
    disabledFilters,
    onFilterValueToggle,
    onFilterValueClick,
    onValueRemove,
    valueRenderers
  } = useContext(TagsListContext);

  const disabledValues = name && disabledFilters?.[name];
  const customValueRenderer = name && valueRenderers?.[name];
  const isRemovable = typeof onValueRemove === "function";

  const onRemove = (e: React.MouseEvent<HTMLButtonElement>, option?: string) => {
    e && e.stopPropagation();
    onValueRemove && onValueRemove(name, option);
  };

  const renderValue = value => {
    const type = typeof value;
    const isValueArray = Array.isArray(value);
    let result;

    const baseTagProps = {
      className: `d-flex mr-1 mb-1 ${Classes.POPOVER_DISMISS}`,
      "data-testid": "filter-value-tag",
      ...(onFilterValueClick && { interactive: true, onClick: () => name && onFilterValueClick(name) }),
      ...(isRemovable && { onRemove: e => onRemove(e) })
    };

    if (customValueRenderer) {
      if (isValueArray) {
        return value.map(option => {
          const isValueDisabled = disabledValues?.includes(option);

          const customTagArrayProps = {
            ...baseTagProps,
            ...(isRemovable && { onRemove: e => onRemove(e, option) }),
            disabled: isValueDisabled,
            key: option
          };

          return (
            <StyledValueTag {...customTagArrayProps}>
              <span className="d-flex">
                {onFilterValueToggle && (
                  <TagFilterToggle
                    disabled={isValueDisabled}
                    onToggle={() => name && onFilterValueToggle(name, option)}
                  />
                )}
                {customValueRenderer(option)}
              </span>
            </StyledValueTag>
          );
        });
      }
      return <Tag {...baseTagProps}>{customValueRenderer(value)}</Tag>;
    }

    if (type === "boolean") {
      result = value ? "Yes" : "No";
    }

    if (value && value.label) {
      result = value.label;
    }

    if (isValueArray) {
      return value.map((option, index) => {
        const isConditionalFilter = option !== null && !!option.func;
        const filterValue = isConditionalFilter ? index : option;
        const isValueDisabled = disabledValues?.includes(filterValue);

        let tagText = filterTitleFromArray(option, name, option);
        if (additionalTagLabels?.[tagText]) {
          tagText = additionalTagLabels[tagText];
        }
        const tagArrayProps = {
          ...baseTagProps,
          ...(isRemovable && { onRemove: e => onRemove(e, option) }),
          disabled: isValueDisabled,
          key: tagText
        };

        return (
          <StyledValueTag {...tagArrayProps}>
            <span className="d-flex">
              {onFilterValueToggle && (
                <TagFilterToggle
                  disabled={isValueDisabled}
                  onToggle={() => name && onFilterValueToggle(name, filterValue)}
                />
              )}
              {tagText}
            </span>
          </StyledValueTag>
        );
      });
    }

    // value is object {start, end}
    if (value && (value.start != null || value.end != null)) {
      const parsedStartDate = parseISO(value.start);
      const parsedEndDate = parseISO(value.end);
      const isValidDate = isValid(parsedStartDate) || isValid(parsedEndDate);
      if (isValidDate) {
        result = `${format(parsedStartDate, computedDateFormat)} – ${format(parsedEndDate, computedDateFormat)}`;
      } else {
        result = `${value.start} – ${value.end}`;
      }
    }

    return <Tag {...baseTagProps}>{result}</Tag>;
  };

  return (
    <>
      <h5 className="bp3-heading">{`Filter by ${title}`}</h5>
      <div className="d-flex flex-wrap">{renderValue(value)}</div>
    </>
  );
}

export default observer(ChipContent);
