import React, { useState } from "react";
import { observer } from "mobx-react";
import { Colors, Icon, Menu, Popover } from "@blueprintjs/core";
import styled from "@emotion/styled";
import { useHistory } from "react-router-dom";
import { useStores } from "store/Store";
import { actionsDictionary, typeDictionary } from "../templates.utils";
import type { MySavedAnalysis } from "modules/Templates/Templates.model";

type Props = {
  template: MySavedAnalysis;
  type: string;
};

const StyledTemplateMenuContainer = styled("div")<{ isActive: boolean }>`
  ${({ isActive }) => isActive && `transform: rotate(90deg); visibility: visible !important`};
`;

function TemplateItemActions(props: Props) {
  const { template, type } = props;
  const { id, label } = template;

  const history = useHistory();

  const { appStore, modalStore, tabsStore, templatesStore } = useStores();
  const { isAdmin = false } = appStore.auth;

  const [actionsMenuStatus, setActionsMenuStatus] = useState(false);

  const showDeleteConfirmation = (id: string, label: string, type: string) => {
    const isTeamAnalysis = type === "Team Analyses";
    const deleteAction = isTeamAnalysis ? "deleteTeamAnalyses" : "removeView";
    const typeText = isTeamAnalysis ? "team analysis" : "saved analysis";

    const config = {
      action: () => templatesStore[deleteAction](id, label),
      buttonText: "Delete",
      header: `Are you sure you want to delete this ${typeText} (${label})?`,
      text:
        "It will no longer be available for all users in your organisation. You won’t be able to restore your work.",
      title: `Delete a ${typeText} (${label})`
    };
    modalStore.setModal("confirmation", config);
  };

  const { actions, isAdminOnly } = actionsDictionary[type];
  const isDisplayed = !isAdminOnly ? true : isAdmin === isAdminOnly;

  const actionsMenu = (
    <Menu>
      {actions.map((action: string) => {
        switch (action) {
          case "delete":
            return isDisplayed ? (
              <Menu.Item
                key={`${type}-${action}-${id}`}
                icon="trash"
                onClick={() => {
                  showDeleteConfirmation(id, label, type);
                }}
                text="Delete"
              />
            ) : null;
          case "edit":
            return isDisplayed ? (
              <Menu.Item
                key={`${type}-${action}-${id}`}
                icon="edit"
                onClick={() => {
                  if (type === "Team Analyses") {
                    tabsStore.createTabFromSavedAnalysisForEditing(template, typeDictionary[type]);
                    templatesStore.toggleSidebar();
                    history.push({ pathname: "/editing-team-analysis", state: id });
                  }
                }}
                text="Edit"
              />
            ) : null;
          case "hide":
            return isDisplayed ? (
              <Menu.Item key={`${type}-${action}-${id}`} icon="eye-off" onClick={() => {}} text="Hide Template" />
            ) : null;
          default:
            return isDisplayed ? (
              <Menu.Item key={`${type}-${action}-${id}`} icon="star-empty" onClick={() => {}} text="Make Default" />
            ) : null;
        }
      })}
    </Menu>
  );

  return (
    <StyledTemplateMenuContainer
      className="ml-auto more-options"
      data-testid="analysis-actions"
      isActive={actionsMenuStatus}
      onClick={e => e.stopPropagation()}
    >
      <Popover content={actionsMenu} isOpen={actionsMenuStatus} onInteraction={state => setActionsMenuStatus(state)}>
        <Icon color={Colors.GRAY1} icon="more" iconSize={14} onClick={() => {}} />
      </Popover>
    </StyledTemplateMenuContainer>
  );
}

export default observer(TemplateItemActions);
