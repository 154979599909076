import React, { useEffect, useRef } from "react";
import { observer } from "mobx-react";
import { Button, ControlGroup, HTMLSelect, Intent, NumericInput } from "@blueprintjs/core";
import styled from "@emotion/styled";
import kebabCase from "lodash.kebabcase";

import { formatPreciseNumber } from "../../../helpers/formatPreciseNumber/formatPreciseNumber";
import type { Condition } from "types/Sidebar.types";
import isNumber from "../../../helpers/isNumber/isNumber";

const FUNCTIONS = [
  { label: ">", value: "gt" },
  { label: "≥", value: "gte" },
  { label: "<", value: "lt" },
  { label: "≤", value: "lte" },
  { label: "=", value: "eq" },
  { label: "≠", value: "neq" }
];

type Props = {
  conditionalFilters: Condition[];
  inputHint?: string;
  isNonNegative?: boolean;
  name: string;
  onAddCondition: Function;
  onChangeCondition: Function;
  onDeleteCondition: Function;
  title: string;
  max?: number;
};

const StyledConditionInput = styled(NumericInput)`
  .input--right-icon + .bp3-input {
    padding-left: 10px;
    padding-right: 30px;
  }
`;

const StyledInputHint = styled("div")`
  left: auto !important;
  right: 0;
`;

const StyledAddConditionButton = styled(Button)`
  font-size: 13px;
`;

function SidebarConditionalFilter(props: Props) {
  const {
    conditionalFilters: conditions = [],
    inputHint,
    isNonNegative = false,
    max,
    name = "",
    onAddCondition,
    onChangeCondition,
    onDeleteCondition,
    title = ""
  } = props;
  const testId = kebabCase(`conditional-filter-${name}-${title}`);

  const lastInputRef = useRef<NumericInput>(null);
  const previousConditionsLengthRef = useRef<number>();

  useEffect(() => {
    const input = lastInputRef.current;
    const previousConditionsLength = previousConditionsLengthRef.current;
    if (previousConditionsLength != null && previousConditionsLength < conditions.length && input != null) {
      previousConditionsLengthRef.current = conditions.length;
      input.inputElement && input.inputElement.focus();
    }
  });

  const addCondition = () => {
    previousConditionsLengthRef.current = conditions.length;
    const func = FUNCTIONS[0].value;
    onAddCondition({ func, name, value: "" });
  };

  const renderCondition = (condition: Condition, conditionIndex) => {
    const inputHintIcon = inputHint ? (
      <StyledInputHint className="input--right-icon bp3-icon">{inputHint}</StyledInputHint>
    ) : undefined;

    const isValidNumber = condition.value
      ? (!isNonNegative && condition.value === "-") || isNumber(condition.value)
      : true;

    const onValueChange = (numberValue: number, stringValue: string) => {
      const conditionValue = numberValue.toString() !== stringValue || stringValue === "" ? stringValue : numberValue;
      onChangeCondition(name, conditionIndex, { ...condition, value: conditionValue });
    };

    return (
      <div key={`${name}-${conditionIndex}`} className="d-flex flex-column mb-1">
        <div className="d-flex">
          <ControlGroup>
            <HTMLSelect
              onChange={e => onChangeCondition(name, conditionIndex, { ...condition, func: e.currentTarget.value })}
              options={FUNCTIONS}
              value={condition.func}
            />
            <StyledConditionInput
              ref={lastInputRef}
              clampValueOnBlur
              data-testid="conditional-filter-input"
              intent={!isValidNumber ? Intent.DANGER : undefined}
              leftIcon={inputHintIcon}
              max={max}
              maxLength={16}
              minorStepSize={0.005}
              onValueChange={onValueChange}
              value={formatPreciseNumber(condition.value)}
              {...(isNonNegative && { min: 0 })}
            />
          </ControlGroup>
          <Button
            className="ml-2"
            data-testid="conditional-filter-delete-btn"
            icon="cross"
            minimal
            onClick={() => onDeleteCondition(name, conditionIndex)}
          />
        </div>
        {!isValidNumber ? <span className="mt-1 text-danger bp3-text-small">Value is not numeric</span> : null}
      </div>
    );
  };

  return (
    <div className="d-flex flex-column mb-3" data-testid={testId}>
      <h6 className="bp3-heading">{title || name}</h6>
      <div className="d-flex flex-column">
        {conditions.map(renderCondition)}
        <StyledAddConditionButton
          className="px-0 justify-content-start"
          data-testid="sidebar-add-condition-button"
          disabled={conditions.length >= 5}
          icon="plus"
          minimal
          onClick={addCondition}
        >
          Add Condition
        </StyledAddConditionButton>
      </div>
    </div>
  );
}

export default observer(SidebarConditionalFilter);
