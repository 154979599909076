// @flow

import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Button, Keys, NumericInput, RangeSlider } from "@blueprintjs/core";
import clsx from "clsx";

type BoundNumericInputProps = {
  defaultValue: string,
  max: number,
  min: number,
  onValueChange: string => void,
  value: string
};

function BoundNumericInput({ defaultValue, max, min, onValueChange, value, ...rest }: BoundNumericInputProps) {
  const [rawValue, setRawValue] = useState("");

  useEffect(() => {
    if (String(value) !== rawValue) {
      setRawValue(String(value));
    }
  }, [value]);

  const handleCommit = committedValue => {
    const safeValue = parseInt((committedValue || "").trim(), 10);

    if (Number.isNaN(safeValue) || safeValue < min || safeValue > max) {
      setRawValue(String(value));
    } else {
      setRawValue(String(safeValue));
      onValueChange(safeValue);
    }
  };

  return (
    <NumericInput
      {...rest}
      fill
      max={max}
      min={min}
      minorStepSize={null}
      onBlur={() => {
        handleCommit(rawValue);
      }}
      onButtonClick={(_: number, stringValue: string) => {
        handleCommit(stringValue);
      }}
      onKeyDown={event => {
        if (event.keyCode === Keys.ENTER) {
          handleCommit(rawValue);
        }
      }}
      onValueChange={(_: number, stringValue: string) => {
        setRawValue(stringValue);
      }}
      rightElement={
        <Button
          className={clsx(value === defaultValue && "invisible")}
          icon="cross"
          minimal
          onClick={() => {
            setRawValue(String(defaultValue));
            onValueChange(defaultValue);
          }}
        />
      }
      value={rawValue}
    />
  );
}

type Props = {
  onChange: Function,
  title: string,
  values: Object
};

function SidebarNDOSelect(props: Props) {
  const { title, values = { end: 0, start: 360 }, onChange, max, min, className } = props;

  const rangeStart = Math.max(values.start, values.end);
  const rangeEnd = Math.min(values.start, values.end);

  return (
    <div className={clsx("d-flex flex-column mb-3", className)} data-testid="sidebar-ndo-select">
      {title && <h6 className="bp3-heading">{title}</h6>}
      <div className="d-flex mb-2">
        <BoundNumericInput
          defaultValue={max || 360}
          max={max || 360}
          min={min || rangeEnd}
          onValueChange={(numberValue: number) => {
            onChange({ end: rangeEnd, start: numberValue });
          }}
          value={rangeStart}
        />
        <BoundNumericInput
          className="ml-2"
          defaultValue={min || 0}
          max={max || rangeStart}
          min={min || 0}
          onValueChange={(numberValue: number) => {
            onChange({ end: numberValue, start: rangeStart });
          }}
          value={rangeEnd}
        />
      </div>
      <div className="mx-3">
        <RangeSlider
          labelRenderer={val => Math.abs(val)}
          labelStepSize={90}
          max={min || 0}
          min={max ? max * -1 : -360}
          onChange={changeValue => {
            onChange({ end: Math.abs(Math.max(...changeValue)), start: Math.abs(Math.min(...changeValue)) });
          }}
          value={[rangeStart * -1, rangeEnd * -1]}
        />
      </div>
    </div>
  );
}

export default observer(SidebarNDOSelect);
