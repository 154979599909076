import React from "react";
import { Boundary, Icon, OverflowList } from "@blueprintjs/core";
import { observer } from "mobx-react";
import omit from "lodash.omit";

import getActiveFilters from "../../helpers/getActiveFilters/getActiveFilters";
import Tag from "./Tag/Tag";
import OverflownTagList from "./OverflownTagList/OverflownTagList";
import { useStores } from "store/Store";
import { FilterKey } from "types/Tab.types";
import TagsListContext from "./TagsListContext/TagsListContext";
import TagsListItem from "./TagsListItem/TagsListItem";

type Props = {
  additionalTagLabels?: object;
  disabled?: boolean;
  disabledFilters?: object;
  filterOrder: string[];
  initialFilters: object;
  isDataLoading?: boolean;
  minItems?: number;
  onFilterValueToggle?: (filterKey: FilterKey, filterValue: string) => void;
  onFilterRemove?: (name: string) => void;
  onFilterToggle?: (name: string) => void;
  onFilterValueClick?: (name: string) => void;
  onValueRemove?: (name?: string, option?: string) => void;
  params: object;
  valueRenderers?: object;
  xDayBuild?: number;
};

function TagsList(props: Props) {
  const { disabled = false, disabledFilters, filterOrder = [], initialFilters, minItems = 0, params } = props;

  const { systemSettingsStore } = useStores();
  const { computedDateFormat, computedDateMonthFormat, isMiles } = systemSettingsStore;

  const entries = params ? Object.entries(params) : [];

  const activeFilters = getActiveFilters(entries, initialFilters).sort((filterA, filterB) => {
    const filterAIndex = filterOrder.indexOf(filterA[0]);
    const filterBIndex = filterOrder.indexOf(filterB[0]);
    return filterAIndex > filterBIndex ? 1 : -1;
  });

  const tagRenderer = ([name, value]) => {
    return <TagsListItem key={name} disabled={disabled} name={name} value={value} />;
  };

  const overflowRenderer = overflowedEntries => {
    return (
      <Tag
        key="overflown-tag"
        disabled={
          disabled ||
          (disabledFilters &&
            overflowedEntries.map(([entryKey]) => entryKey).every(filterKey => filterKey in disabledFilters))
        }
        inline
        popoverContent={<OverflownTagList disabled={disabled} tags={overflowedEntries} />}
        testId="popover-tag"
      >
        {`${overflowedEntries.length} more`}
        <Icon className="ml-2" icon="caret-down" iconSize={15} />
      </Tag>
    );
  };

  const contextValue = {
    ...omit(props, ["disabled", "initialFilters", "filterOrder", "minItems", "params"]),
    computedDateFormat,
    computedDateMonthFormat,
    disabledFilters,
    isMiles
  };

  return (
    <TagsListContext.Provider value={{ ...contextValue }}>
      <OverflowList
        collapseFrom={Boundary.END}
        items={activeFilters}
        minVisibleItems={minItems}
        observeParents
        overflowRenderer={overflowRenderer}
        visibleItemRenderer={tagRenderer}
      />
    </TagsListContext.Provider>
  );
}

export default observer(TagsList);
