import {
  baseBookingsGroup,
  baseCapacityGroup,
  baseCompetitorFareGroup,
  baseFinalLoadFactorGroup,
  baseFinalRaskGroup,
  baseFinalRevenueGroup,
  baseFinalRevenuePerBookingGroup,
  baseDepDayOfWeekGroup,
  baseRevenuePerBookingGroup,
  baseFinalYieldGroup,
  baseFlightGroup,
  baseInfluenceGroup,
  baseInfluenceImpactGroup,
  baseLacGroup,
  baseLoadFactorGroup,
  basePricePercentileGroup,
  baseRaskGroup,
  baseRevenueGroup,
  baseSeatsAllocationGroup,
  baseSellingFareGroup,
  baseYieldGroup
} from "./constants";

import prepareMetricGroup from "./prepareMetricGroup";

export type MetricLabelsProps = {
  longVersion?: boolean;
  shortVersion?: boolean;
  shortVersionByGroup?: boolean;
  isMiles?: boolean;
  withParent?: boolean;
  withUnits?: boolean;
  xDayBuild?: number;
};

const depDayLabels = (args: MetricLabelsProps) =>
  prepareMetricGroup({
    args,
    baseGroup: baseDepDayOfWeekGroup,
    parent: "depDayOfWeekGroup"
  });

const finalRevenueLabels = (args: MetricLabelsProps) =>
  prepareMetricGroup({
    args,
    baseGroup: baseFinalRevenueGroup,
    parent: "finalRevenueGroup"
  });

const finalRevenuePerBookingsLabels = (args: MetricLabelsProps) =>
  prepareMetricGroup({
    args,
    baseGroup: baseFinalRevenuePerBookingGroup,
    parent: "finalRevenuePerBookingGroup"
  });

const revenuePerBookingsLabels = (args: MetricLabelsProps) =>
  prepareMetricGroup({
    args,
    baseGroup: baseRevenuePerBookingGroup,
    parent: "revenuePerBookingGroup"
  });

const finalRaskGroupLabels = (args: MetricLabelsProps) =>
  prepareMetricGroup({
    args,
    baseGroup: baseFinalRaskGroup,
    parent: "finalRaskGroup"
  });

const raskGroupLabels = (args: MetricLabelsProps) =>
  prepareMetricGroup({ args, baseGroup: baseRaskGroup, parent: "raskGroup" });

const revenueGroupLabels = (args: MetricLabelsProps) =>
  prepareMetricGroup({
    args,
    baseGroup: baseRevenueGroup,
    parent: "revenueGroup"
  });

const loadFactorGroupLabels = (args: MetricLabelsProps) =>
  prepareMetricGroup({
    args,
    baseGroup: baseLoadFactorGroup,
    parent: "loadFactorGroup"
  });

const finalLoadFactorGroupLabels = (args: MetricLabelsProps) =>
  prepareMetricGroup({
    args,
    baseGroup: baseFinalLoadFactorGroup,
    parent: "finalLoadFactorGroup"
  });

const capacityGroupLabels = (args: MetricLabelsProps) =>
  prepareMetricGroup({
    args,
    baseGroup: baseCapacityGroup,
    parent: "capacityGroup"
  });

const bookingsGroupLabels = (args: MetricLabelsProps) =>
  prepareMetricGroup({
    args,
    baseGroup: baseBookingsGroup,
    parent: "bookingsGroup"
  });

const yieldGroupLabels = (args: MetricLabelsProps) =>
  prepareMetricGroup({ args, baseGroup: baseYieldGroup, parent: "yieldGroup" });

const finalYieldGroupLabels = (args: MetricLabelsProps) =>
  prepareMetricGroup({ args, baseGroup: baseFinalYieldGroup, parent: "finalYieldGroup" });

export const sellingFareGroupLabels = (args: MetricLabelsProps) =>
  prepareMetricGroup({
    args,
    baseGroup: baseSellingFareGroup,
    parent: "sellingFareGroup"
  });

const pricePercentileGroupLabels = (args: MetricLabelsProps) =>
  prepareMetricGroup({
    args,
    baseGroup: basePricePercentileGroup,
    parent: "pricePercentileGroup"
  });

export const lacGroupLabels = (args: MetricLabelsProps) =>
  prepareMetricGroup({ args, baseGroup: baseLacGroup, parent: "lacGroup" });

const seatsAllocationLabels = (args: MetricLabelsProps) =>
  prepareMetricGroup({
    args,
    baseGroup: baseSeatsAllocationGroup,
    parent: "lacSeatsAvailableGroup"
  });

export const competitorFareGroupLabels = (args: MetricLabelsProps) =>
  prepareMetricGroup({
    args,
    baseGroup: baseCompetitorFareGroup,
    parent: "competitorFareGroup"
  });

const influenceGroupLabels = (args: MetricLabelsProps) =>
  prepareMetricGroup({
    args,
    baseGroup: baseInfluenceGroup,
    parent: "influenceGroup"
  });

const influenceImpactGroupLabels = (args: MetricLabelsProps) =>
  prepareMetricGroup({
    args,
    baseGroup: baseInfluenceImpactGroup,
    parent: "influenceImpactGroup"
  });

const flightGroupLabels = (args: MetricLabelsProps) =>
  prepareMetricGroup({
    args,
    baseGroup: baseFlightGroup,
    parent: "flight"
  });

export default function metricLabels(props: MetricLabelsProps = {}) {
  return {
    ...depDayLabels(props),
    ...competitorFareGroupLabels(props),
    ...flightGroupLabels(props),
    ...finalRevenueLabels(props),
    ...finalRaskGroupLabels(props),
    ...finalRevenuePerBookingsLabels(props),
    ...revenuePerBookingsLabels(props),
    ...raskGroupLabels(props),
    ...revenueGroupLabels(props),
    ...finalLoadFactorGroupLabels(props),
    ...loadFactorGroupLabels(props),
    ...yieldGroupLabels(props),
    ...finalYieldGroupLabels(props),
    ...sellingFareGroupLabels(props),
    ...pricePercentileGroupLabels(props),
    ...lacGroupLabels(props),
    ...seatsAllocationLabels(props),
    ...influenceGroupLabels(props),
    ...influenceImpactGroupLabels(props),
    ...bookingsGroupLabels(props),
    ...capacityGroupLabels(props)
  };
}
