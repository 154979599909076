import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";

import Row from "shared/components/Row/Row";
import { useStores } from "store/Store";
import DistanceControl from "./DistanceControl";
import { SystemSettingsDescription, SystemSettingsSectionHeader } from "./SystemSettingsUI";

type Props = {
  distanceUnit: string,
  onChange(): void
};

function SystemSettingsDistance(props: Props) {
  const { systemSettingsStore } = useStores();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    systemSettingsStore
      .getDistanceUnit()
      .then(distanceUnit => props.onChange({ distanceUnit }))
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <>
      <SystemSettingsSectionHeader className="mt-4">Distance Units</SystemSettingsSectionHeader>
      <Row>
        <div className="col-6">
          <SystemSettingsDescription className="m-0">
            Select between metric and imperial units as default. This will update all distance-related metrics in the
            system.
          </SystemSettingsDescription>
        </div>
        <div className="col-6 d-flex justify-content-end align-items-start">
          <DistanceControl distanceUnit={props.distanceUnit} isLoading={isLoading} onChange={props.onChange} />
        </div>
      </Row>
    </>
  );
}

export default observer(SystemSettingsDistance);
