import React from "react";
import { Button, ButtonGroup, Colors } from "@blueprintjs/core";
import { observer } from "mobx-react";
import isEqual from "lodash.isequal";
import styled from "@emotion/styled";
import kebabCase from "lodash.kebabcase";

type Props = {
  isMultiple?: boolean;
  items: string[] | { label: string; value: string | boolean }[];
  onSelect: (valueToSelect: string[] | undefined) => void;
  selectedItems: string[];
  title: string;
};

const StyledButton = styled(Button)`
  &:focus {
    outline: ${Colors.BLUE3}99 auto 2px !important;
    z-index: 100 !important;
  }
`;

function ButtonSelector(props: Props) {
  const { title = "", selectedItems = [], items = [], onSelect, isMultiple = true } = props;
  const isCollection = typeof items[0] === "object";

  const isSelected = item => {
    if (isMultiple) {
      return selectedItems.includes(item);
    }
    return isEqual(selectedItems, [item]);
  };

  const handleSelect = item => {
    if (isMultiple) {
      if (isSelected(item)) {
        return onSelect(selectedItems.filter(singleItem => singleItem !== item));
      }

      return onSelect([...selectedItems, item]);
    }

    if (isSelected(item)) {
      return onSelect(undefined);
    }
    return onSelect(item);
  };

  const buttons = items.map(button => {
    const item = isCollection ? button.value : button;
    const itemLabel = isCollection ? button.label : button;

    return (
      <StyledButton
        key={itemLabel}
        active={isSelected(item)}
        className="px-0 flex-grow-1"
        data-testid={`button-${itemLabel}`}
        onClick={() => {
          handleSelect(item);
        }}
      >
        {itemLabel}
      </StyledButton>
    );
  });
  const testId = kebabCase(`${title}-buttons`);

  return (
    <div className="d-flex flex-column mb-3" data-testid={testId}>
      <h6 className="bp3-heading">{title}</h6>
      <ButtonGroup>{buttons}</ButtonGroup>
    </div>
  );
}

export default observer(ButtonSelector);
