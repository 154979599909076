import React, { useMemo } from "react";
import { observer } from "mobx-react";
import { format, parseISO } from "date-fns";

import { filterOrder } from "modules/InfluenceHistory/influenceHistoryMetrics/influenceHistoryMetrics";
import { FILTERS_INIT } from "modules/InfluenceHistory/InfluenceHistory.utils";
import { daysOfWeek, extraMetrics } from "modules/App/constants";
import { influenceStatusNames } from "modules/Influence/influenceConstants";
import TagsList from "shared/components/TagsList/TagsList";
import metricLabels from "shared/metricLabels/metricLabels";
import { useStores } from "store/Store";

function InfluenceHistoryTagList() {
  const { influenceHistoryStore, systemSettingsStore } = useStores();
  const { mappings, page } = influenceHistoryStore;
  const { computedDateFormat, computedDateMonthFormat, isMiles } = systemSettingsStore;

  const additionalTags = useMemo(() => {
    const tags = {};
    mappings.creators.forEach(user => {
      tags[user.value] = user.label;
    });
    return tags;
  }, [JSON.stringify(mappings.creators)]);

  const filterLabels = {
    ...metricLabels({ isMiles, withParent: true, withUnits: true }),
    ...extraMetrics,
    adjustmentIds: "Influence ID",
    createdOn: "Influences Created on",
    status: "Influence Status",
    type: "Type of Influence",
    updatedOn: "Influences Modified On",
    userIds: "Analyst"
  };

  const onFilterValueClick = (filterKey: string) => {
    influenceHistoryStore.setSidebarOpen(true);
    influenceHistoryStore.setSidebarFilterQuery(filterLabels[filterKey], filterKey);
  };

  const valueRenderers = {
    depDow: value => daysOfWeek[value]?.short,
    depMonth: value => value && format(parseISO(value), computedDateMonthFormat),
    depWeek: value => value && format(parseISO(value), computedDateFormat),
    quarter: value => `${value.substring(0, 4)} ${value.substring(4)}`,
    status: value => influenceStatusNames[value]
  };

  return (
    <TagsList
      additionalTagLabels={additionalTags}
      disabled={!page.filtersEnabled}
      disabledFilters={page.disabledFilters}
      filterOrder={filterOrder}
      initialFilters={FILTERS_INIT}
      isDataLoading={influenceHistoryStore.isDataLoading}
      onFilterRemove={influenceHistoryStore.clearSearchParam}
      onFilterToggle={influenceHistoryStore.toggleFilter}
      onFilterValueClick={onFilterValueClick}
      onFilterValueToggle={influenceHistoryStore.toggleFilterValue}
      onValueRemove={influenceHistoryStore.removeFilterValue}
      params={page.applied.filters}
      valueRenderers={valueRenderers}
    />
  );
}

export default observer(InfluenceHistoryTagList);
