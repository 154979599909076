import React from "react";
import { Button, Spinner } from "@blueprintjs/core";

import { SystemSettingsControlLabel, SystemSettingsSelect } from "./SystemSettingsUI";
import { delimiterOptions } from "models/SystemSettings.model";
import SystemSettingsMenuItem from "./SystemSettingsMenuItem";

type Props = {
  dateDelimiter: string,
  isLoading: boolean,
  onChange: Function
};

function DateDelimiterControl({ dateDelimiter, onChange, isLoading }: Props) {
  const delimiterLabel = delimiterOptions.find(option => option.value === dateDelimiter)?.label;
  const buttonText = isLoading ? "Loading..." : delimiterLabel;
  const rightIcon = isLoading ? <Spinner size={12} /> : "caret-down";

  return (
    <div className="d-flex justify-content-end align-items-center">
      <SystemSettingsControlLabel className="m-0 mr-2">Delimiter</SystemSettingsControlLabel>
      <SystemSettingsSelect
        disabled={isLoading}
        filterable={false}
        itemRenderer={(item, itemProps) => (
          <SystemSettingsMenuItem
            key={item.label}
            data-testid="date-delimiter-menu-item"
            item={item}
            itemProps={itemProps}
            selected={dateDelimiter}
          />
        )}
        items={delimiterOptions}
        onItemSelect={option => onChange({ dateDelimiter: option.value })}
        popoverProps={{ minimal: true }}
      >
        <Button
          data-testid="system-settings-date-delimiter-select"
          disabled={isLoading}
          rightIcon={rightIcon}
          text={buttonText}
        />
      </SystemSettingsSelect>
    </div>
  );
}
export default DateDelimiterControl;
