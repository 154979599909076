const removeValueInFilter = (
  filterKey: string,
  valueToRemove: string,
  disabledFilters: object,
  filters: object,
  initialValue: string[] | { end: string; start: string } | boolean
) => {
  const isValueAnArray = Array.isArray(filters[filterKey]);

  if (isValueAnArray) {
    filters[filterKey].remove(valueToRemove);
  } else {
    filters[filterKey] = initialValue;
  }

  if (filterKey in disabledFilters) {
    if (isValueAnArray) {
      disabledFilters[filterKey].remove(valueToRemove);
    } else {
      delete disabledFilters[filterKey];
    }
  }
};
export default removeValueInFilter;
