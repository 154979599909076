import { Intent } from "@blueprintjs/core";
import { AxiosError } from "axios";
import cloneDeep from "lodash.clonedeep";
import upperFirst from "lodash.upperfirst";
import { action, computed, makeObservable, observable } from "mobx";
import type { Influence } from "modules/Influence/influenceConstants";
import { INFLUENCES_LIST } from "modules/Influence/influenceConstants";

import api from "services/Api";
import { AppToaster } from "services/Toaster";
import {
  InfluenceData,
  InfluenceParameters,
  InfluencePreviewResponse,
  InfluenceValidationError,
  PriceLimitsInfluenceParams
} from "types/Influence.types";

export enum InfluenceStatus {
  DONE = "DONE",
  DONE_BASE = "DONE_BASE",
  DONE_PREVIEW = "DONE_PREVIEW",
  ERROR = "ERROR",
  ERROR_BASE = "ERROR_BASE",
  ERROR_PREVIEW = "ERROR_PREVIEW",
  INIT = "INIT",
  LOADING = "LOADING",
  LOADING_BASE = "LOADING_BASE",
  LOADING_PREVIEW = "LOADING_PREVIEW"
}

export const influenceSteps = { SAVE: 3, SELECTION: 1, SET: 2, SUMMARY: 4 };

export const DATA_INIT: InfluenceData = {
  base: { aggregated: null, rows: null },
  influenceId: "",
  preview: { aggregated: null, rows: null },
  previewCounters: null
};

export const PARAMETERS_INIT: InfluenceParameters = {
  comment: "",
  inputValue: 0,
  value: 0
};

export const STATE_INIT = {
  influenceStep: influenceSteps.SELECTION,
  influenceType: ""
};

export const PRICE_LIMITS_INIT: PriceLimitsInfluenceParams = {
  endNdo: undefined,
  max: null,
  min: null,
  startNdo: undefined
};

export class InfluenceStore {
  influencesList: Influence[] = INFLUENCES_LIST;

  @observable data: InfluenceData = cloneDeep(DATA_INIT); // data from endpoint
  @observable parameters: InfluenceParameters = cloneDeep(PARAMETERS_INIT); // params sent to endpoint
  @observable state = cloneDeep(STATE_INIT); // progress of influence
  @observable status: InfluenceStatus = InfluenceStatus.INIT;
  @observable validationErrors: InfluenceValidationError[] = [];
  @observable metricsLastUpdated: InfluencePreviewResponse["metricsLastUpdated"];

  constructor() {
    makeObservable(this);
  }

  @action.bound
  resetSteps() {
    this.data = cloneDeep(DATA_INIT);
    this.parameters = cloneDeep(PARAMETERS_INIT);
    this.state = cloneDeep(STATE_INIT);
    this.status = InfluenceStatus.INIT;
  }

  @action.bound
  updateState(change: object) {
    this.state = {
      ...this.state,
      ...change
    };
  }

  @action.bound
  changeStep(step: number) {
    this.updateState({ influenceStep: this.state.influenceStep + step });
  }

  @action.bound
  updateParameters(change: object) {
    this.parameters = {
      ...this.parameters,
      ...change
    };
  }

  @action.bound
  updateInputValue(numberValue: number, stringValue: string) {
    const newValue = Number(stringValue) !== numberValue || stringValue === "" ? stringValue : Math.round(numberValue);
    this.updateParameters({ inputValue: newValue });
  }

  @action.bound
  updateInfluence(influenceId: string, payload: object) {
    const [type, id] = influenceId.split("-");
    const params = { influenceId: id };
    this.status = InfluenceStatus.LOADING;

    const apiCall = {
      AP: () => api.updateAbsolutePriceInfluence(payload, params),
      CS: () => api.updateCompetitorSensitivityInfluence(payload, params),
      MM: () => api.updateMinMaxInfluence(payload, params),
      PA: () => api.updatePercentInfluence(payload, params)
    };

    return apiCall[type]()
      .then(() => {
        this.status = InfluenceStatus.DONE;
      })
      .catch(() => {
        this.status = InfluenceStatus.ERROR;
      });
  }

  @action.bound
  saveInfluence(type: string, influence: object) {
    this.status = InfluenceStatus.LOADING;

    const apiCall = {
      AP: () => api.saveAbsoluteInfluence(influence),
      CS: () => api.saveCompetitorSensitivityInfluence(influence),
      MM: () => api.savePriceLimitsInfluence(influence),
      PA: () => api.savePercentInfluence(influence)
    };

    apiCall[type]()
      .then(({ data }) => {
        this.data = data;
        this.status = InfluenceStatus.DONE;
      })
      .catch(() => {
        this.status = InfluenceStatus.ERROR;
      });
  }

  @action.bound
  previewInfluence(type: string, influence: any, params: any) {
    const { isBaseValue } = params || {};
    this.status = isBaseValue ? InfluenceStatus.LOADING_BASE : InfluenceStatus.LOADING_PREVIEW;

    const action = params?.influenceId ? "edit" : "create";

    const apiCall = {
      create: {
        AP: () => api.previewAbsolutePriceInfluence(influence),
        CS: () => api.previewCompSensInfluence(influence),
        MM: () => api.previewPriceLimitsInfluence(influence),
        PA: () => api.previewPercentInfluence(influence)
      },
      edit: {
        AP: () => api.previewAbsolutePriceInfluenceEdit(influence, params),
        CS: () => api.previewCompSensInfluenceEdit(influence, params),
        MM: () => api.previewPriceLimitsInfluenceEdit(influence, params),
        PA: () => api.previewPercentInfluenceEdit(influence, params)
      }
    };

    return apiCall[action][type]()
      .then(({ data }: { data: InfluencePreviewResponse }) => {
        const { aggregated, previewCounters, rows = [], metricsLastUpdated } = data;

        if (isBaseValue) {
          this.data.base = { aggregated, rows };
          this.data.preview = {};
          this.data.previewCounters = previewCounters;
        } else {
          this.data.preview = { aggregated, rows };
          this.data.previewCounters = previewCounters;
        }

        if (previewCounters && previewCounters.unaffectedValueErrors > 0) {
          AppToaster.show({
            intent: Intent.PRIMARY,
            message: "Some flights cannot be influenced. The FLYR dev team has been informed and will be investigating."
          });
        }

        this.status = isBaseValue ? InfluenceStatus.DONE_BASE : InfluenceStatus.DONE_PREVIEW;
        this.metricsLastUpdated = metricsLastUpdated;
        this.validationErrors = [];
      })
      .catch((error: AxiosError) => {
        if (error.response?.status === 409) {
          this.validationErrors = error.response.data.errors as InfluenceValidationError[];
        }

        this.status = isBaseValue ? InfluenceStatus.ERROR_BASE : InfluenceStatus.ERROR_PREVIEW;
      });
  }

  @action.bound
  updateMinMaxValue(index: number, fieldName: string, value: number | null) {
    if (!Number.isNaN(value)) {
      this.parameters.inputValue[index][fieldName] = value;
    }
  }

  @computed
  get selectedInfluence(): Influence | { rows: undefined } {
    return this.influencesList.find(el => el.type === this.state.influenceType) || { rows: undefined };
  }

  @computed
  get columnsList() {
    return this.selectedInfluence.rows ? this.selectedInfluence.rows.map(row => row.key) : [];
  }

  @computed
  get columnsListForFlightsTable() {
    return this.columnsList.map(key => `influence${upperFirst(key)}`);
  }

  @computed
  get isPreviewActive() {
    return Boolean(this.state.influenceType);
  }

  @computed
  get isLoading() {
    return this.status === InfluenceStatus.LOADING;
  }

  @computed
  get isError() {
    return this.status === InfluenceStatus.ERROR;
  }
}
