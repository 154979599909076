import { action, computed, makeObservable, observable } from "mobx";

import type { RootStore } from "modules/App/Root.model";
import { FlightsTable } from "modules/FlightsTable/FlightsTable.model";
import { initColumns, transformPayloadToHeatmapData } from "./DashboardHeatmapStore.utils";
import { Status } from "modules/App/Status";
import { isCancel } from "services/Api";

export const heatmapMetrics = [
  { label: "Final Revenue", value: "xDayRevenuePotential" },
  { label: "Revenue", value: "raskDiffToBaseline" },
  { label: "Load Factor", value: "diffToElb" },
  { label: "Influence", value: "xDayInfluenceImpactBuild" }
];

const defaultMetricBoundary = {
  diffToElb: 0,
  raskDiffToBaseline: 0,
  xDayInfluenceImpactBuild: 0,
  xDayRevenuePotential: 0
};

export class DashboardHeatmapStore {
  @observable dayTable: FlightsTable = {};
  @observable heatmap: FlightsTable = {};
  @observable weekTable: FlightsTable = {};

  @observable xDayBuild: number = 7;
  @observable numberOfFlights: number = 0;
  @observable status: Status = Status.INIT;
  @observable selectedMetric = "xDayRevenuePotential";
  @observable selectedMetricBoundary = defaultMetricBoundary;

  rootStore: RootStore;
  table: FlightsTable;

  constructor(rootStore: RootStore) {
    makeObservable(this);
    const daySeed = {
      table: new FlightsTable({
        aggregations: ["depDate", "depDow"],
        apiIdentifier: "dashboardDayHeatmap",
        columns: initColumns,
        fixedColumns: {},
        pagination: {
          pageCount: 1,
          pageIndex: 0,
          pageSize: 365,
          totalRows: 365
        },
        selectedRows: null
      })
    };

    Object.assign(this, daySeed);
    const weekSeed = {
      table: new FlightsTable({
        aggregations: ["depWeek"],
        apiIdentifier: "dashboardWeekHeatmap",
        columns: initColumns,
        fixedColumns: {},
        pagination: {
          pageCount: 1,
          pageIndex: 0,
          pageSize: 53,
          totalRows: 53
        },
        selectedRows: null
      })
    };
    Object.assign(this, weekSeed);

    this.rootStore = rootStore;
    this.dayTable = daySeed.table;
    this.weekTable = weekSeed.table;
  }

  @action.bound
  changeSelectedMetric(metric: string) {
    if (!metric) {
      this.selectedMetric = "xDayRevenuePotential";
      return;
    }
    this.selectedMetric = metric;
  }

  @action.bound
  getHeatmapData() {
    this.status = Status.LOADING;
    const flightsDayDataParams = {
      aggregations: ["depDate", "depDow"],
      filters: {
        analystId: this.rootStore.dashboardStore.isMyMarkets ? [this.rootStore.appStore.userId] : []
      },
      pagination: {
        pageCount: 1,
        pageIndex: 0,
        pageSize: 365,
        totalRows: 365
      },
      sortBy: { direction: "asc", field: "depDate" },
      xDayBuild: this.xDayBuild
    };
    const flightsWeekDataParams = {
      aggregations: ["depWeek"],
      filters: {
        analystId: this.rootStore.dashboardStore.isMyMarkets ? [this.rootStore.appStore.userId] : []
      },
      sortBy: { direction: "asc", field: "depWeek" },
      xDayBuild: this.xDayBuild
    };

    const daysPromise = this.dayTable.fetchFlightsData(flightsDayDataParams, "watchlist-days-heatmap");
    const weeksPromise = this.weekTable.fetchFlightsData(flightsWeekDataParams, "watchlist-weeks-heatmap");

    const promiseArray = [daysPromise, weeksPromise];

    return Promise.all(promiseArray)
      .then(responses => {
        const daysResolved = responses[0];
        const weeksResolved = responses[1];

        this.selectedMetricBoundary = heatmapMetrics
          .map(metric => metric.value)
          .reduce((accumulator, current) => {
            return {
              ...accumulator,
              [current]: Math.max(
                Math.max(...daysResolved.data.rows.map(el => el[current])),
                Math.abs(Math.min(...daysResolved.data.rows.map(el => el[current])))
              )
            };
          }, defaultMetricBoundary);

        const transformedData = transformPayloadToHeatmapData(daysResolved.data, weeksResolved.data);

        this.heatmap = {
          ...this.weekTable,
          aggregations: ["depWeek"],
          columns: [
            [
              "depDayOfWeekGroup",
              [
                "depDayMonday",
                "depDayTuesday",
                "depDayWednesday",
                "depDayThursday",
                "depDayFriday",
                "depDaySaturday",
                "depDaySunday"
              ]
            ],
            ...this.weekTable.columns
          ],
          data: transformedData,
          showPagination: false,
          sortBy: { direction: "asc", field: "depWeek" }
        };
        this.numberOfFlights = transformedData.reduce((acc, week) => acc + (week.numberOfFlightsOriginal || 0), 0);
        this.status = Status.DONE;
      })
      .catch(thrown => {
        if (isCancel(thrown)) {
          return null;
        }
        this.status = Status.ERROR;
      });
  }

  @computed
  get isError(): boolean {
    return this.status === Status.ERROR;
  }

  @computed
  get isLoaded(): boolean {
    return this.status === Status.DONE;
  }

  @computed
  get isLoading(): boolean {
    return this.status === Status.LOADING;
  }
}
