import React from "react";
import { Classes } from "@blueprintjs/core";
import styled from "@emotion/styled";

const StyledColumnWrapper = styled("div")`
  padding: 12px;
`;

const ColumnWrapper = ({ type, children }: { type: string; children: React.ReactNode }) => (
  <StyledColumnWrapper
    className={`${Classes.DIALOG_BODY} m-0 overflow-auto event-column-${type}`}
    data-testid={`events-management-modal-${type}`}
  >
    {children}
  </StyledColumnWrapper>
);

export default ColumnWrapper;
