import React, { useState } from "react";
import { Colors, Icon, Popover, PopoverInteractionKind, Position } from "@blueprintjs/core";
import styled from "@emotion/styled";
import { css, Global } from "@emotion/react";

const StyledIcon = styled(Icon)`
  cursor: pointer;

  svg:hover {
    fill: ${Colors.DARK_GRAY5};
  }
`;

const globalStyle = (
  <Global
    styles={css`
      .heatmap-legend-popover {
        font-size: 12px;
        line-height: 1.25;
        max-width: 465px;
      }
    `}
  />
);

const LEGEND_TEXT =
  "Day cells are colored based on the currently available values following the color scale presented below. The “border values” for the scale present the extremums of the entire range of days.";

function ColorTooltip({ children, href = "", text = LEGEND_TEXT }) {
  const [isOpen = false, setIsOpen] = useState<boolean>(false);
  return (
    <span>
      {globalStyle}
      <Popover
        content={
          <div className="py-2 px-3">
            <div>{text}</div>
            <a href={href} rel="noopener noreferrer" target="_blank">
              Read more
            </a>
            {children}
          </div>
        }
        interactionKind={PopoverInteractionKind.CLICK}
        isOpen={isOpen}
        onInteraction={state => setIsOpen(state)}
        popoverClassName="heatmap-legend-popover"
        position={Position.BOTTOM}
      >
        <StyledIcon className="mr-1" color={Colors.LIGHT_GRAY2} icon="help" />
      </Popover>
    </span>
  );
}
export default ColorTooltip;
