import React from "react";
import styled from "@emotion/styled";

const IndicatorWrapperStyled = styled.div`
  color: ${({ isActive }) => (isActive ? "inherit" : "#8A9BA8")};
  text-transform: capitalize;
`;
const IndicatorStyled = styled.span`
  background-color: ${({ isActive }) => (isActive ? "#3DCC91" : "#CED9E0")};
  border-radius: 10px;
  display: inline-block;
  height: 8px;
  width: 8px;
`;

type Props = {
  text: ?string,
  isActive: boolean
};

const StatusIndicator = (props: Props) => {
  const { isActive } = props;
  const { text = isActive ? "On" : "Off" } = props;

  return (
    <IndicatorWrapperStyled isActive={isActive}>
      <IndicatorStyled className="mr-1" isActive={isActive} />
      <span>{text}</span>
    </IndicatorWrapperStyled>
  );
};

export default StatusIndicator;
