// @flow

import { action, computed, makeObservable, observable } from "mobx";
import isEmpty from "lodash.isempty";
import { computedFn } from "mobx-utils";
import uniq from "lodash.uniq";

import api from "services/Api";
import { Status } from "modules/App/Status";

const naturalSort = (a: string, b: string) => a.localeCompare(b, undefined, { numeric: true });

export class CirrusMappingsStore {
  @observable status: string = Status.INIT;
  @observable data: Object = {};

  constructor() {
    makeObservable(this);
  }

  @action
  getMappings() {
    this.status = Status.LOADING;
    api
      .getCirrusMappings()
      .then(({ data }) => {
        this.data = data;
        this.status = Status.DONE;
      })
      .catch(() => {
        this.status = Status.ERROR;
      });
  }

  @action.bound
  getFlightNumbers = computedFn((owMarket: string[] = []): string[] => {
    const { flights = [] } = this.data;

    const filterByMarkets = market => (isEmpty(owMarket) ? market : owMarket.includes(market.owMarket));
    const flightNumbers = flights.filter(filterByMarkets).flatMap(flight => flight.flightNumbers);

    return uniq(flightNumbers).sort(naturalSort);
  }, true);

  @computed
  get cabinClasses(): string[] {
    const { cabinClassMappings = [] } = this.data;
    const cabinClasses = cabinClassMappings.map(({ cabinClass }) => cabinClass);

    return uniq(cabinClasses);
  }

  @computed
  get owMarkets(): string[] {
    const { flights = [] } = this.data;
    const owMarkets = flights.map(flight => flight.owMarket).sort();

    return uniq(owMarkets);
  }

  @computed
  get isLoading(): boolean {
    return this.status === Status.LOADING;
  }

  @computed
  get isError(): boolean {
    return this.status === Status.ERROR;
  }
}
