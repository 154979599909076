import { Colors } from "@blueprintjs/core";
import styled from "@emotion/styled";
import clsx from "clsx";
import isEmpty from "lodash.isempty";
import React from "react";
import roundTo from "round-to";

import formatValueWithUnit from "../../../helpers/formatValueWithUnit/formatValueWithUnit";
import isNumber from "../../../helpers/isNumber/isNumber";
import minMaxDataTableCell from "modules/Influence/minMaxDataTableCell/minMaxDataTableCell";
import { valueWithLimits } from "modules/InfluenceHistory/valueWithLimits/valueWithLimits";
import { Formatter } from "../DataTable.types";
import { CellType } from "types/DataTable.types";

const withDecimalNumber = (withFixedNumber, percent) =>
  withFixedNumber && percent && !percent.includes(".") && percent !== "0%" && percent !== "-0%"
    ? `${percent.split("%")[0]}.0%`
    : percent;

const StyledCellBackground = styled("div")<{ color: string; noBackground: boolean; value: number }>`
  background: ${({ color, noBackground }) => (noBackground ? "transparent" : color || "rgba(231, 238, 243, 0.75)")};
  bottom: 0;
  left: 0;
  position: absolute;
  top: 0;
  width: ${({ value }) => `${Math.min(value, 100)}%`};
`;

const StyledCellInfluenceBase = styled("span")(
  ({ isStriked }: { isStriked: boolean }) =>
    isStriked && {
      fontWeight: 300,
      opacity: 0.5,
      textDecoration: "line-through"
    }
);

const StyledCellInfluencePreview = styled("strong")`
  color: ${({ isPercentage, value }: { isPercentage: boolean; value: number }) => {
    if (isPercentage && value > 0) return Colors.GREEN4;
    if (isPercentage && value < 0) return Colors.RED4;
    return Colors.VIOLET4;
  }};
`;

const StyledCellValue = styled("div")`
  text-align: right;
  position: relative;
  z-index: 1;
`;

const basePreview = (value, type) => {
  const influenceType = type.slice(10);

  const formattedBaseValue =
    value.base === null ? "-" : isNumber(value.base) && formatValueWithUnit(value.base, influenceType);
  const formattedPreviewValue =
    value.preview === null ? "-" : isNumber(value.preview) && formatValueWithUnit(value.preview, influenceType);

  return { base: formattedBaseValue, influenceType, preview: formattedPreviewValue };
};

const renderBasePreview = (value, type) => {
  const { base, preview, influenceType } = basePreview(value, type);

  return (
    <div className="d-flex flex-grow-1 justify-content-between" data-type={type}>
      <StyledCellInfluenceBase
        className="mr-1"
        data-testid="cell-influence-base-text"
        isStriked={isNumber(preview) || !isEmpty(preview)}
      >
        {base}
      </StyledCellInfluenceBase>
      <StyledCellInfluencePreview isPercentage={influenceType === "percent"} value={value.preview}>
        {preview}
      </StyledCellInfluencePreview>
    </div>
  );
};

const renderCurrency = (value: number, type: CellType, formatter: Formatter) => {
  if (!isNumber(value)) {
    return "";
  }

  return (
    <div className="d-flex flex-grow-1 justify-content-between" data-type={type}>
      <span>$</span>
      <span>{formatter.format(value)}</span>
    </div>
  );
};

const renderPercent = (
  value: number,
  type: CellType,
  formatter: Formatter,
  withFixedNumber: boolean,
  options: { noBackground?: boolean }
) => {
  const { noBackground = false } = options;
  if (!isNumber(value)) {
    return "";
  }

  const roundedValue = roundTo(value, 0);
  const percentValue = withDecimalNumber(withFixedNumber, formatter.format(value * 0.01));
  const isPercentRelative = type === "percent-relative";

  return (
    <>
      <StyledCellBackground
        color="inherit"
        noBackground={noBackground}
        value={roundedValue}
        {...(isPercentRelative && {
          color: `${value > 0 ? Colors.GREEN5 : Colors.RED3}33`,
          value: Math.abs(roundedValue)
        })}
      />
      <StyledCellValue data-type={type}>{percentValue}</StyledCellValue>
    </>
  );
};

const renderMinMax = (value, isWithLimits: boolean) => {
  let renderedValue = minMaxDataTableCell(value[0]);

  if (isWithLimits) {
    renderedValue = valueWithLimits({ type: "MM", value });
  }

  return (
    <div className="d-flex flex-grow-1 justify-content-between" data-type="min-max">
      <span
        className={clsx({
          "bp3-text-overflow-ellipsis": isWithLimits
        })}
      >
        {renderedValue}
      </span>
    </div>
  );
};

export { renderBasePreview, renderCurrency, renderPercent, renderMinMax };
