import React, { useMemo } from "react";
import { observer } from "mobx-react";
import { useLocation } from "react-router-dom";

import styled from "@emotion/styled";
import DefaultHeader from "./DefaultHeader";
import TeamAnalysisEditHeader from "./TeamAnalysisEditHeader";

export const StyledHeader = styled("nav")`
  background-color: #000340;
  min-height: 40px;
`;

function Header() {
  const location = useLocation();

  const isRoot = useMemo(() => location.pathname === "/" || location.pathname === "/dashboard", [location.pathname]);
  const isEditingTemplate = useMemo(() => location.pathname === "/editing-team-analysis", [location.pathname]);

  return (
    <StyledHeader className="d-flex align-items-end px-2">
      {isEditingTemplate ? <TeamAnalysisEditHeader /> : <DefaultHeader isRoot={isRoot} />}
    </StyledHeader>
  );
}

export default observer(Header);
