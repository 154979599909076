import React, { useEffect } from "react";
import { observer } from "mobx-react";

import ErrorBoundary from "shared/components/ErrorBoundary/ErrorBoundary";
import StyledScrollableContainer from "shared/components/StyledScrollableContainer/StyledScrollableContainer";
import TopBar from "shared/components/TopBar/TopBar";
import { useStores } from "store/Store";
import RegionsTable from "components/Regions/RegionsTable";

function Regions() {
  const { regionsStore } = useStores();
  const { getRegionsData } = regionsStore;

  useEffect(() => {
    getRegionsData();
  }, []);

  return (
    <StyledScrollableContainer className="d-flex flex-column">
      <TopBar />
      <div className="p-2 d-flex flex-column flex-shrink-1 mh-0">
        <ErrorBoundary>
          <RegionsTable />
        </ErrorBoundary>
      </div>
    </StyledScrollableContainer>
  );
}

export default observer(Regions);
