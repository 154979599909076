import React, { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import styled from "@emotion/styled";
import { observer } from "mobx-react";
import { Spinner, Colors } from "@blueprintjs/core";

import MarketsItem from "./MarketsItem";
import { useStores } from "store/Store";
import { fetchingMarketsProps } from "models/Dashboard/DashboardMarkets.model";

const StyledList = styled("ul")`
  max-height: 100%;
  overflow: auto;
`;

const StyledInfiniteScroll = styled(InfiniteScroll)`
  & > div + div > li:first-of-type {
    border-top: 1px solid ${Colors.LIGHT_GRAY2}; // fix border after fetching next page
  }
`;

function Markets() {
  const { dashboardMarketsStore } = useStores();
  const {
    data,
    getFlightsData,
    isFetchedAllData,
    sizeData,
    table: {
      pagination: { pageIndex: paginationPageIndex }
    }
  } = dashboardMarketsStore;

  const [pageIndex, setPageIndex] = useState(paginationPageIndex || 0);

  const fetchMoreData = () => {
    if (!isFetchedAllData) {
      let newPageIndex;

      if (pageIndex === 0 && sizeData === 40) {
        // hack for bigger devices when list is smaller than scroll space
        newPageIndex = 2;
        getFlightsData({
          pageIndex: newPageIndex,
          pageSize: fetchingMarketsProps.pagination.pageSize
        });
      } else {
        newPageIndex = pageIndex + 1;
        getFlightsData({
          pageIndex: newPageIndex
        });
      }

      setPageIndex(newPageIndex);
    }
  };

  return (
    <StyledList className="my-0 p-0 list-unstyled" id="markets-module-list">
      <StyledInfiniteScroll
        dataLength={sizeData}
        hasMore={!isFetchedAllData}
        loader={<Spinner className="p-4" />}
        next={fetchMoreData}
        scrollableTarget="markets-module-list"
      >
        {data.map(pieceData => (
          <MarketsItem key={`market-piece-${pieceData.pageIndex}`} pieceData={pieceData} />
        ))}
      </StyledInfiniteScroll>
    </StyledList>
  );
}

export default observer(Markets);
