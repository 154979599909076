import { Button, Classes, Colors, Dialog } from "@blueprintjs/core";
import styled from "@emotion/styled";
import { addSeconds, getTime } from "date-fns";
import { observer } from "mobx-react";
import pluralize from "pluralize";
import { rgba } from "polished";
import React from "react";
import { useTimer } from "react-timer-hook";

import RevenueUpliftBackground from "../../../assets/ruv-background.svg";
import formatValueWithUnit from "shared/helpers/formatValueWithUnit/formatValueWithUnit";
import { useStores } from "store/Store";

export const StyledDialogFooter = styled("div")`
  box-shadow: 0 -1px 0 rgba(16, 22, 26, 0.15);
`;

export const StyledDialogBody = styled("div")`
  background-color: ${rgba(Colors.BLUE5, 0.1)};
`;

export const StyledDialogImage = styled("div")`
  background-image: url(${RevenueUpliftBackground});
  background-position: top;
  background-repeat: no-repeat;
`;

export const StyledUpliftCircle = styled("div")`
  width: 160px;
  height: 160px;
  background: linear-gradient(180deg, ${rgba(Colors.WHITE, 0.7)} 0%, ${rgba(Colors.WHITE, 0.1)} 100%);
  border-radius: 50%;
  backdrop-filter: blur(1.5px);
`;

export const StyledUpliftValue = styled("span")`
  color: ${Colors.BLUE4};
  font-size: 28px;
  line-height: 23px;
`;

const formatCurrency = (amount: number) => `$${new Intl.NumberFormat("en-US", { notation: "compact" }).format(amount)}`;

function RevenueUpliftModal() {
  const { appStore, modalStore } = useStores();
  const { ruv } = appStore;

  const { seconds } = useTimer({
    expiryTimestamp: getTime(addSeconds(new Date(), 10))
  });

  return (
    <Dialog
      autoFocus={false}
      canEscapeKeyClose={false}
      canOutsideClickClose={false}
      className="p-0"
      isCloseButtonShown={false}
      isOpen
      title="Cirrus is creating revenue uplift!"
    >
      <StyledDialogBody className={`${Classes.DIALOG_BODY} m-0 p-3`} data-testid="ruv-details">
        <StyledDialogImage className="d-flex flex-column w-100 pt-4">
          <div className="d-flex justify-content-between mb-5 mx-5">
            <StyledUpliftCircle className="d-flex flex-column justify-content-center align-items-center">
              <span className="bp3-text-muted bp3-text-small mb-2">Annual revenue uplift</span>
              <StyledUpliftValue>+{formatCurrency(ruv.revenueUpliftTotal)}</StyledUpliftValue>
            </StyledUpliftCircle>
            <StyledUpliftCircle className="d-flex flex-column justify-content-center align-items-center">
              <span className="bp3-text-muted bp3-text-small mb-2">Revenue uplift %</span>
              <StyledUpliftValue>+{ruv.revenueUpliftPercent}%</StyledUpliftValue>
            </StyledUpliftCircle>
          </div>
          <span className="mb-2">
            Over the past <span className="font-weight-bold">{pluralize("day", ruv.period, true)}</span>, Cirrus has a
            measured impact on overall revenue performance of{" "}
            <span className="font-weight-bold">+{ruv.revenueUpliftPercent}%</span> compared to the legacy system.
          </span>
          <span className="mb-2">
            Over a full year on the full network, this would represent{" "}
            <span className="font-weight-bold">{formatValueWithUnit(ruv.revenueUpliftTotal, "currency")}</span>.
          </span>
        </StyledDialogImage>
      </StyledDialogBody>
      <StyledDialogFooter className={`${Classes.DIALOG_FOOTER} mx-0 px-3 py-2`}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button
            data-testid="continue-work-button"
            disabled={seconds > 0}
            intent="primary"
            onClick={modalStore.closeModal}
          >
            Continue work {seconds ? `(${seconds})` : ""}
          </Button>
        </div>
      </StyledDialogFooter>
    </Dialog>
  );
}

export default observer(RevenueUpliftModal);
