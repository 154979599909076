import React, { useLayoutEffect, useRef } from "react";
import { observer } from "mobx-react";
import { MenuDivider } from "@blueprintjs/core";

import FilterGroups from "shared/components/Sidebar/FilterGroups/FilterGroups";
import SearchInput from "shared/components/SearchInput/SearchInput";
import Sidebar from "shared/components/Sidebar/Sidebar";
import { filterGroups } from "../../Explore/metrics/metrics";
import focusOnFilter from "shared/helpers/focusOnFilter/focusOnFilter";
import SidebarApplyButton from "shared/components/Sidebar/SidebarApplyButton/SidebarApplyButton";
import { useStores } from "store/Store";
import FiltersActionButtons from "components/FiltersActionButtons";

import { FILTERS_INIT } from "models/Tab/Tab.utils";

type Props = {
  submitSearchForm: Function;
  tabId: string;
};

function AnalysisSidebar(props: Props) {
  const { submitSearchForm, tabId } = props;
  const { analysisMappingsStore, regionsStore, systemSettingsStore, tabsStore } = useStores();
  const { computedDateFormat, computedDateMonthFormat, isMiles } = systemSettingsStore;
  const tab = tabsStore.tabsById[tabId];
  const { setSidebarFilterQuery, setSidebarOpen, sidebar } = tab;
  const { filterQuery, isOpen, filterKey } = sidebar;

  const sidebarRef = useRef(null);

  useLayoutEffect(() => {
    focusOnFilter(filterKey, filterQuery, sidebarRef);
  }, [filterKey, filterQuery]);

  const onSubmit = () => {
    setSidebarOpen(false);
    setSidebarFilterQuery("");
    submitSearchForm();
  };

  return (
    <Sidebar
      ref={sidebarRef}
      footer={<SidebarApplyButton initialFilters={FILTERS_INIT} onSubmit={onSubmit} pageContext={tab} />}
      isOpen={isOpen}
      setOpen={setSidebarOpen}
    >
      <SearchInput onChange={setSidebarFilterQuery} value={filterQuery} />
      <MenuDivider className="m-0" />
      <FiltersActionButtons tab={tab} />
      <MenuDivider className="m-0" />
      <FilterGroups
        computedDateFormat={computedDateFormat}
        computedDateMonthFormat={computedDateMonthFormat}
        filterGroups={filterGroups}
        isMiles={isMiles}
        mappings={analysisMappingsStore}
        pageContext={tab}
        regions={regionsStore}
      />
    </Sidebar>
  );
}

export default observer(AnalysisSidebar);
