import { PIVOT_THRESHOLD_COLORS } from "modules/App/constants";

export const getPivotThresholdColor = (value: number, boundary: number | null) => {
  if (value === 0 || boundary === null) {
    return PIVOT_THRESHOLD_COLORS.NEUTRAL;
  }
  if (Math.abs(value) > boundary * 0.8) {
    return value > 0 ? PIVOT_THRESHOLD_COLORS.POSITIVE100 : PIVOT_THRESHOLD_COLORS.NEGATIVE100;
  }
  if (Math.abs(value) > boundary * 0.6) {
    return value > 0 ? PIVOT_THRESHOLD_COLORS.POSITIVE80 : PIVOT_THRESHOLD_COLORS.NEGATIVE80;
  }
  if (Math.abs(value) > boundary * 0.4) {
    return value > 0 ? PIVOT_THRESHOLD_COLORS.POSITIVE60 : PIVOT_THRESHOLD_COLORS.NEGATIVE60;
  }
  if (Math.abs(value) > boundary * 0.2) {
    return value > 0 ? PIVOT_THRESHOLD_COLORS.POSITIVE40 : PIVOT_THRESHOLD_COLORS.NEGATIVE40;
  }
  if (Math.abs(value) <= boundary * 0.2) {
    return value > 0 ? PIVOT_THRESHOLD_COLORS.POSITIVE20 : PIVOT_THRESHOLD_COLORS.NEGATIVE20;
  }
  return PIVOT_THRESHOLD_COLORS.DISABLE;
};
