import React, { useCallback, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react";
import {
  Button,
  Checkbox,
  Collapse,
  Colors,
  Icon,
  InputGroup,
  Menu,
  MenuDivider,
  MenuItem,
  Popover,
  Position
} from "@blueprintjs/core";
import styled from "@emotion/styled";
import { css, Global } from "@emotion/react";
import isEmpty from "lodash.isempty";
import isEqual from "lodash.isequal";
import difference from "lodash.difference";
import intersection from "lodash.intersection";
import uniq from "lodash.uniq";
import pluralize from "pluralize";
import { IconName } from "@blueprintjs/icons";

import { useDebounce } from "use-debounce";

import { FiltersHelpTexts } from "modules/Explore/filtersHelpTexts";
import SeriesLegend from "../SeriesLegend/SeriesLegend";
import SidebarFilter from "../Sidebar/SidebarFilter/SidebarFilter";
import ReadMoreButton from "../ReadMoreButton/ReadMoreButton";
import { useStores } from "store/Store";

const isOptionVisible = (filterName: string, filterKey: string, filterQuery: string): boolean =>
  `${filterName} ${filterKey}`.toLowerCase().includes(filterQuery.toLowerCase());

const StyledActionButton = styled(Button)`
  height: 30px;
  min-width: 72px;
  flex: 0 0 calc(100% / 3 - 4px);
`;

const StyledMenuFooter = styled("div")`
  border-top: 1px solid ${Colors.LIGHT_GRAY2};
  bottom: 0;
  left: 0;
  right: 0;
`;

const StyledMenu = styled(Menu)`
  max-height: 350px !important;
  max-width: 270px !important;
  width: 270px !important;
`;

interface StyledMenuHeaderType {
  isOpen: boolean;
}

const StyledMenuHeader = styled("div")<StyledMenuHeaderType>`
  border-top: 1px solid ${Colors.LIGHT_GRAY2};
  border-bottom: ${({ isOpen }) => (isOpen ? `1px solid ${Colors.LIGHT_GRAY2}` : "1px solid transparent")};
  cursor: pointer;
  height: 36px;

  .bp3-menu-header {
    cursor: pointer;
    letter-spacing: 1px;
    margin: 0;
    padding: 0 8px;
    h6 {
      padding: 0;
    }
  }

  .bp3-control {
    align-items: center;
    display: flex;
    justify-content: center;

    .bp3-control-indicator {
      margin-top: 0;
    }
  }
`;

const StyledMenuItem = styled(MenuItem)`
  background: ${Colors.LIGHT_GRAY5};
  height: 30px;
`;

const StyledMenuDivider = styled(MenuDivider)`
  border-color: ${Colors.LIGHT_GRAY2};
`;
type metricsGroup = {
  isDisabled?: boolean;
  isMetric?: boolean;
  isNonNegative?: boolean;
  key?: string;
  excludeInFilters?: boolean;
  max?: number;
}[];
type allMetrics = (string | metricsGroup)[][];

type Props = {
  allMetrics: allMetrics;
  baseMetrics: (string | string[])[][];
  buttonIcon: IconName;
  buttonLabel: string;
  changeColumns: Function;
  changeGroupsStatus: Function;
  columnLabels: object;
  columns: (string | string[])[][];
  disabled: boolean;
  groupStatuses: Array<any>;
  onReset?: Function;
  showLegend?: boolean;
};

function ColumnSelect(props: Props) {
  const {
    allMetrics = [],
    baseMetrics = [],
    buttonIcon,
    buttonLabel = "",
    changeColumns,
    changeGroupsStatus,
    columns = [],
    columnLabels = {},
    disabled = false,
    groupStatuses = [],
    onReset,
    showLegend = false
  } = props;
  const { systemSettingsStore } = useStores();
  const { isMiles } = systemSettingsStore;

  const allMetricsKeys = allMetrics
    .map(([, metrics]: [string, metricsGroup]) => metrics.map(metric => metric.key))
    .flat();
  const allMetricsKeysWithoutDisabled = allMetrics
    .map(([, metrics]: [string, metricsGroup]) =>
      metrics.filter(metric => !metric.isDisabled).map(metric => metric.key)
    )
    .flat() as string[];

  const disabledKeys = allMetrics
    .flatMap(([, metrics]: [string, metricsGroup]) => metrics)
    .filter(metric => metric.isDisabled)
    .map(metric => metric.key);

  const currentMetrics = columns
    .flatMap(([, metrics]: string[]) => metrics)
    .filter(metricKey => !disabledKeys.includes(metricKey));

  const columnsCount = currentMetrics.length;

  const [metricsState, setMetricsState] = useState<string[]>(currentMetrics);
  const [filterInput, setFilterInput] = useState("");
  const [activeFilter] = useDebounce(filterInput, 400);

  const columnName = useCallback((title, key) => `${columnLabels[title]} ${columnLabels[key]} ${key}`, [columnLabels]);

  const filteredSeries = useMemo(
    () =>
      allMetrics
        .filter(
          ([title, metrics]: [string, metricsGroup]) =>
            metrics.filter(metric => columnName(title, metric.key).toLowerCase().includes(activeFilter.toLowerCase()))
              .length
        )
        .map(([groupTitle]) => groupTitle),
    [activeFilter, allMetrics, columnName]
  );

  const updateGroupsStatuses = useCallback(
    (selectedGroup?: string) => {
      const newGroupStatuses = groupStatuses.map(({ label, isOpen }) => {
        let isOpenValue = !isOpen;
        if (selectedGroup !== label) {
          isOpenValue = activeFilter ? filteredSeries.includes(label) : isOpen;
        }
        return {
          isOpen: isOpenValue,
          label
        };
      });
      changeGroupsStatus && changeGroupsStatus(newGroupStatuses);
    },
    [activeFilter, filteredSeries, groupStatuses, changeGroupsStatus]
  );

  useEffect(() => {
    updateGroupsStatuses();
  }, [activeFilter]);

  const columnHelpUrls = FiltersHelpTexts(isMiles);

  const addMetrics = (names: Array<string>) => {
    setMetricsState(
      uniq([...metricsState, ...names]).sort((a, b) => (allMetricsKeys.indexOf(a) > allMetricsKeys.indexOf(b) ? 1 : -1))
    );
  };

  const applyChanges = () => {
    const newColumnsSetting = metricsState.reduce(
      (accumulator, currentValue) => {
        const parentNode =
          allMetrics.find(([, metrics]: [string, metricsGroup]) =>
            metrics.map(metric => metric.key).includes(currentValue)
          ) || [];

        return accumulator.map(([groupTitle, metrics]) =>
          groupTitle === parentNode[0] && currentValue
            ? [groupTitle, [...metrics, currentValue]]
            : [groupTitle, metrics]
        );
      },
      allMetrics.map(([groupTitle]) => [groupTitle, []])
    );
    setFilterInput("");
    changeColumns(newColumnsSetting);
  };

  const removeMetrics = (names: Array<string>) => setMetricsState(difference(metricsState, names));

  const renderMetric = (group: string, item: { key: string; isDisabled: boolean }, index: number) => {
    const { key, isDisabled } = item;
    const isSelected = metricsState.includes(key);
    const legend = showLegend ? <SeriesLegend group={group} index={index} size={10} /> : null;

    const label = (
      <span className="d-inline-flex align-items-center justify-content-between w-100">
        {columnLabels[key] || key}
        {legend}
      </span>
    );

    const checkbox = (
      <Checkbox
        key={key}
        checked={isDisabled ? false : isSelected}
        className="m-0 py-1"
        disabled={isDisabled}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          event.target.checked ? addMetrics([key]) : removeMetrics([key])
        }
      >
        {label}
      </Checkbox>
    );

    const filterName = columnName(group, key).toLowerCase();

    return (
      <SidebarFilter key={key} isVisible={isOptionVisible(filterName, key, activeFilter)}>
        <StyledMenuItem
          className="p-0 align-items-center"
          data-testid={`metric-checkbox-${key}`}
          shouldDismissPopover={false}
          text={<div className="px-2">{checkbox}</div>}
        />
      </SidebarFilter>
    );
  };

  const filterMetrics = () =>
    allMetrics
      .map(([groupTitle, groupMetrics]: [string, metricsGroup]) => {
        return groupMetrics
          .filter(metric => {
            const { key } = metric;
            return columnName(groupTitle, key).toLowerCase().includes(activeFilter.toLowerCase());
          })
          .map(({ key }) => key);
      })
      .flat() as string[];

  const clearAllMetrics = () =>
    isEmpty(activeFilter)
      ? setMetricsState([])
      : setMetricsState(metricsState.filter(metric => !filterMetrics().includes(metric)));

  const selectAllMetrics = () =>
    isEmpty(activeFilter)
      ? setMetricsState(allMetricsKeysWithoutDisabled)
      : setMetricsState(uniq([...metricsState, ...filterMetrics()]));

  const buttons = (
    <div className="d-flex justify-content-between m-2">
      <StyledActionButton
        disabled={!isEmpty(activeFilter)}
        onClick={() => {
          setMetricsState(baseMetrics.map(([, metrics]) => metrics).flat());
          onReset && onReset();
        }}
      >
        Reset
      </StyledActionButton>
      <StyledActionButton className="d-flex p-0" onClick={clearAllMetrics}>
        Clear All
      </StyledActionButton>
      <StyledActionButton className="d-flex p-0" onClick={selectAllMetrics}>
        Select All
      </StyledActionButton>
    </div>
  );

  const metricGroupIsClicked = (target, name) =>
    target.parentElement.getAttribute(name) &&
    ["chevron-up", "chevron-down"].includes(target.parentElement.getAttribute(name));

  const metrics = allMetrics.map(([groupTitle, groupMetrics]: [string, [{ key: string }]]) => {
    const groupExtraProps = columnHelpUrls[groupTitle];

    const { isOpen = false } = groupStatuses.find(el => el.label === groupTitle) || {};

    const groupMetricKeys = groupMetrics
      .map(item => item.key)
      .filter(metricKey => {
        const isDisabled = disabledKeys.includes(metricKey);
        const isFilteringActive = columnName(groupTitle, metricKey).toLowerCase().includes(activeFilter.toLowerCase());

        return isFilteringActive && !isDisabled;
      });

    const isGroupSelected = intersection(metricsState, groupMetricKeys).length;
    const isAllGroupMetricsSelected = isGroupSelected === groupMetricKeys.length;

    const groupCheckbox = (
      <Checkbox
        key={groupTitle}
        checked={!!isGroupSelected}
        className="m-0 ml-2"
        data-testid={`metric-group-checkbox-${groupTitle}`}
        indeterminate={isGroupSelected && !isAllGroupMetricsSelected}
        label={columnLabels[groupTitle]}
        onChange={() => (isAllGroupMetricsSelected ? removeMetrics(groupMetricKeys) : addMetrics(groupMetricKeys))}
      />
    );

    const menuTitle = (
      <StyledMenuHeader
        className="d-flex align-items-center"
        data-testid="metric-group-title-with-help-text"
        isOpen={isOpen}
        onClick={({ target, currentTarget }) => {
          if (
            target === currentTarget ||
            metricGroupIsClicked(target, "data-icon") ||
            metricGroupIsClicked(target, "icon")
          ) {
            updateGroupsStatuses(groupTitle);
          }
        }}
      >
        {groupCheckbox}
        {groupExtraProps && <ReadMoreButton href={groupExtraProps.url} />}
        <Icon className="ml-auto mr-2" color={Colors.GRAY1} icon={isOpen ? "chevron-up" : "chevron-down"} />
      </StyledMenuHeader>
    );

    if (!activeFilter) {
      return (
        <React.Fragment key={`fragment-${groupTitle}`}>
          {menuTitle}

          <Collapse data-testid="metric-group" isOpen={isOpen}>
            {groupMetrics.map((metric: { key: string; isDisabled: boolean }, index: number) =>
              renderMetric(groupTitle, metric, index)
            )}
          </Collapse>
        </React.Fragment>
      );
    }

    return filteredSeries.includes(groupTitle) ? (
      <React.Fragment key={`fragment-${groupTitle}`}>
        {menuTitle}

        <Collapse data-testid="metric-group" isOpen={isOpen}>
          {groupMetrics.map((metric: { key: string; isDisabled: boolean }, index: number) =>
            renderMetric(groupTitle, metric, index)
          )}
        </Collapse>
      </React.Fragment>
    ) : null;
  });

  const numberOfMetrics = Array.isArray(metricsState) ? metricsState.length : undefined;

  const menu = (
    <StyledMenu className="mb-5 p-0" data-testid="columns-menu">
      <InputGroup
        autoComplete="off"
        autoFocus
        className="m-2"
        data-testid="search-columns"
        id="text-input"
        leftIcon="search"
        onChange={({ target }) => setFilterInput(target.value.toLowerCase())}
        placeholder="Search..."
        rightElement={filterInput.length ? <Button icon="cross" minimal onClick={() => setFilterInput("")} /> : <div />}
        value={filterInput}
      />
      <StyledMenuDivider className="m-0" />
      {buttons}
      {activeFilter && isEmpty(filteredSeries) ? <div className="p-2">No results found.</div> : metrics}
      <StyledMenuFooter className="d-flex position-fixed">
        <Button
          className="m-2"
          disabled={isEqual(currentMetrics, metricsState)}
          fill
          intent="primary"
          onClick={applyChanges}
        >
          Apply ({pluralize("metric", numberOfMetrics, true)})
        </Button>
      </StyledMenuFooter>
    </StyledMenu>
  );

  const button = (
    <Button
      className="d-flex"
      data-testid="metric-selector"
      disabled={disabled}
      icon={buttonIcon}
      onClick={() => setMetricsState([...currentMetrics])}
    >
      <span>
        {buttonLabel}: <strong>{columnsCount}</strong>
      </span>
      <Icon className="ml-3" icon="caret-down" />
    </Button>
  );

  return (
    <>
      <Global
        styles={css`
          .bp3-popover .bp3-popover-content {
            height: 100%;
          }
        `}
      />
      <Popover content={menu} disabled={disabled} minimal position={Position.BOTTOM}>
        {button}
      </Popover>
    </>
  );
}

export default observer(ColumnSelect);
