import isEnv from "shared/helpers/isEnv/isEnv";

export const typeDictionary = {
  "My Saved Analyses": "saved",
  "System Analyses": "system",
  "Team Analyses": "team"
};

export const actionsDictionary = {
  "My Saved Analyses": {
    actions: ["delete"],
    isAdminOnly: false
  },

  "System Analyses": {
    actions: isEnv(["development"]) ? ["hide", "default"] : [],
    isAdminOnly: true
  },

  "Team Analyses": {
    actions: isEnv(["development"]) ? ["edit", "hide", "default", "delete"] : ["edit", "delete"],
    isAdminOnly: true
  }
};
