import { action, makeObservable, observable } from "mobx";

export class ModalStore {
  @observable modalActive?: string = "";
  @observable data?: any = {};

  constructor() {
    makeObservable(this);
  }

  @action.bound
  closeModal() {
    this.modalActive = undefined;
    this.data = undefined;
  }

  @action.bound
  setModal(modalName: string, data?: any) {
    this.modalActive = modalName;
    this.data = data;
  }
}
