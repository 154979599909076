import React from "react";
import { observer } from "mobx-react";
import styled from "@emotion/styled";
import { Colors, Intent, Menu, Popover, Position } from "@blueprintjs/core";
import { useHistory } from "react-router-dom";
import isEmpty from "lodash.isempty";
import pluralize from "pluralize";

import filterNamesMap from "modules/Explore/filterNames";
import { useStores } from "store/Store";
import { MAX_TAB_COUNT } from "modules/App/constants";
import { AppToaster } from "services/Toaster";
import groupConditionalFilters from "shared/helpers/groupConditionalFilters/groupConditionalFilters";
import getActiveFilters from "shared/helpers/getActiveFilters/getActiveFilters";
import { FILTERS_INIT } from "models/Tab/Tab.utils";
import type { MySavedAnalysis } from "modules/Templates/Templates.model";
import { tabCountReachedMessage } from "modules/App/messages";
import TemplateItemActions from "../TemplateItemActions/TemplateItemActions";
import { actionsDictionary, typeDictionary } from "../templates.utils";

const StyledPopoverContent = styled(Menu)`
  &.bp3-menu {
    width: 300px;
  }
  .list-unstyled li {
    list-style: none;
  }
`;

const StyledTemplateFilters = styled("div")`
  color: ${Colors.GRAY1};
  &:hover {
    border-bottom: 1px solid ${Colors.GRAY1};
  }
`;

const StyledTemplateItem = styled("div")`
  height: 54px;
  .more-options {
    visibility: hidden;
  }

  &:hover {
    background-color: ${Colors.LIGHT_GRAY3};
    border-radius: 2px;
    cursor: pointer;

    .more-options {
      transform: rotate(90deg);
      visibility: visible;
    }
  }
`;

const StyledTemplateLabel = styled("div")`
  font-weight: 600;
`;

const StyledFilterLabel = styled("div")`
  flex: 1 1 50px;
  white-space: nowrap;
`;

type Props = {
  template: MySavedAnalysis;
  type: string;
};

function TemplateItem(props: Props) {
  const history = useHistory();
  const { appStore, tabsStore, systemSettingsStore, templatesStore } = useStores();
  const { isAdmin = false } = appStore.auth;
  const { isMiles } = systemSettingsStore;

  const { template, type } = props;
  const { label, view } = template;
  const { conditionalFilters, filters, flightsTable } = view;

  const groupedFilters = Object.entries({
    ...filters,
    ...groupConditionalFilters(conditionalFilters)
  });
  const appliedFilters = getActiveFilters(groupedFilters, FILTERS_INIT);
  const appliedFiltersCount = appliedFilters.length;
  const filterNames = filterNamesMap(isMiles);
  const filterText = `⋅ ${pluralize("filter", appliedFiltersCount, true)}`;

  const { actions, isAdminOnly } = actionsDictionary[type];
  const isDisplayed = !isAdminOnly ? true : isAdmin === isAdminOnly;
  const isActionsMenu = !isEmpty(actions) && isDisplayed;

  const createTab = () => {
    if (tabsStore.tabs.length < MAX_TAB_COUNT) {
      tabsStore.createTabFromSavedAnalysis(template, typeDictionary[type]);
      templatesStore.toggleSidebar();
      history.push(`/analysis/${tabsStore.lastTabId}/explore`);
    } else {
      AppToaster.show({ intent: Intent.WARNING, message: tabCountReachedMessage });
    }
  };

  const aggregationsLabel = (
    <div className="flex-grow-1 text-truncate">
      {isEmpty(flightsTable.aggregations)
        ? "Network"
        : flightsTable.aggregations.map(aggregation => filterNames[aggregation]).join("/")}
    </div>
  );

  const filtersLabel = <StyledFilterLabel className="ml-auto p-0 text-right"> {filterText} </StyledFilterLabel>;

  const detailsPopover = (
    <StyledPopoverContent className="pt-2 pb-1 px-3">
      {!isEmpty(flightsTable.aggregations) ? (
        <ul className="my-0 p-0 list-unstyled">
          <li className="pb-1">
            <strong>Aggregations</strong>
          </li>
          {flightsTable.aggregations.map(aggregation => (
            <li key={aggregation} className="pb-1">
              {filterNames[aggregation]}
            </li>
          ))}
        </ul>
      ) : null}
      {!isEmpty(appliedFilters) ? (
        <>
          {!isEmpty(flightsTable.aggregations) ? <Menu.Divider className="mx-0 my-1" /> : null}
          <ul className="my-0 p-0 list-unstyled">
            <li className="pb-1">
              <strong> Filters </strong>
            </li>
            {appliedFilters.map(([filterKey]) => (
              <li key={filterKey} className="pb-1">
                {filterNames[filterKey]}
              </li>
            ))}
          </ul>
        </>
      ) : null}
    </StyledPopoverContent>
  );

  return (
    <StyledTemplateItem className="p-2" data-testid="template-item" onClick={createTab}>
      <div className="d-flex">
        <StyledTemplateLabel className="text-truncate">{label}</StyledTemplateLabel>
        {isActionsMenu && <TemplateItemActions template={template} type={type} />}
      </div>
      <div className="mt-1" data-testid="template-details" onClick={e => e.stopPropagation()}>
        <Popover
          boundary="viewport"
          captureDismiss
          content={detailsPopover}
          data-testid="template-item-details"
          fill
          position={Position.BOTTOM}
        >
          <StyledTemplateFilters className="d-flex">
            {aggregationsLabel}
            {filtersLabel}
          </StyledTemplateFilters>
        </Popover>
      </div>
    </StyledTemplateItem>
  );
}

export default observer(TemplateItem);
