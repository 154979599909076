import isEmpty from "lodash.isempty";
import union from "lodash.union";
import pickBy from "lodash.pickby";

export const sharedSeries = [
  "raskBaseline",
  "revenueBaseline",
  "loadFactorBaseline",
  "revenuePerBookingBaseline",
  "yieldBaseline"
];

export const isSeriesWithoutData = allSeries =>
  isEmpty(Object.values(allSeries).filter((series: any[]) => !series.every(point => point === null || point === 0)));

export function mergePastAndFutureTimeline(collection, isNdo: boolean) {
  const {
    kpisTimestamp,
    maxLacRank,
    ndo: seriesNdo,
    date: seriesScenarioDate,
    today,
    todayNdo,
    ...series
  } = collection.data;

  if (isSeriesWithoutData(series)) {
    return {
      date: [],
      kpisTimestamp,
      maxLacRank,
      ndo: [],
      ...(isNdo ? { todayNdo } : { today: today || seriesScenarioDate[seriesScenarioDate.length - 1] }),
      series
    };
  }
  if (!isNdo) {
    return {
      ...collection.data,
      today: today || seriesScenarioDate[seriesScenarioDate.length - 1]
    };
  }
  const seriesDates = seriesNdo;

  const seriesKeys = Object.keys(pickBy(series, value => Array.isArray(value) && value.length === seriesDates.length));

  const mergeNdoSeries = () => {
    const ndoRange = [Math.min(...seriesNdo), Math.max(...seriesNdo)];
    const ndoDiff = ndoRange[1] - ndoRange[0];
    const timeline = [...new Array(ndoDiff + 1).fill(ndoRange[1]).map((e, i) => e - i)];
    const mergedSeries = seriesKeys.reduce((accumulator, currentSerie) => {
      const data = timeline.map(e => {
        if (e > Math.max(...seriesDates) || e < Math.min(...seriesDates)) {
          return null;
        }
        const matchingIndex = seriesDates.indexOf(e);
        return series[currentSerie][matchingIndex];
      });

      return {
        ...accumulator,
        [currentSerie]: [...data]
      };
    }, {});

    return { mergedSeries, timeline };
  };

  const { timeline, mergedSeries } = mergeNdoSeries();

  return {
    maxLacRank,
    ndo: timeline,
    today,
    todayNdo,
    ...mergedSeries
  };
}

export function mergePastAndFutureTimelines(collections, isNdo: boolean) {
  const [historicalCollection, expectedCollection] = collections;
  const {
    kpisTimestamp,
    maxLacRank,
    ndo: historicalNdo,
    date: historicalScenarioDate,
    ...historicalSeries
  } = historicalCollection.data;
  const {
    kpisTimestamp: forecastTimestamp, // eslint-disable-line
    ndo: forecastedNdo,
    date: forecastedScenarioDate,
    today,
    todayNdo,
    ...forecastedSeries
  } = expectedCollection.data;

  if (isSeriesWithoutData(historicalSeries) && isSeriesWithoutData(forecastedSeries)) {
    return {
      date: [],
      forecastedSeries,
      historicalSeries,
      kpisTimestamp,
      maxLacRank,
      ndo: [],
      ...(isNdo
        ? { todayNdo }
        : { today: today || historicalScenarioDate[historicalScenarioDate.length - 1] || forecastedScenarioDate[0] })
    };
  }
  const historicalDates = isNdo ? historicalNdo : historicalScenarioDate;
  const forecastedDates = isNdo ? forecastedNdo : forecastedScenarioDate;

  const historicalSeriesKeys = Object.keys(
    pickBy(historicalSeries, value => Array.isArray(value) && value.length === historicalDates.length)
  );
  const forecastedSeriesKeys = Object.keys(
    pickBy(forecastedSeries, value => Array.isArray(value) && value.length === forecastedDates.length)
  );

  const mergeNdoSeries = () => {
    const allSeries = union(historicalSeriesKeys, forecastedSeriesKeys);
    const ndoRange = [
      Math.min(...historicalDates, ...forecastedDates),
      Math.max(...historicalDates, ...forecastedDates)
    ];
    const ndoDiff = ndoRange[1] - ndoRange[0];
    const timeline = [...new Array(ndoDiff + 1).fill(ndoRange[1]).map((e, i) => e - i)];

    const mergedSeries = allSeries.reduce((accumulator, currentSerie) => {
      const isHistorical = historicalSeriesKeys.includes(currentSerie);
      const isForecast = forecastedSeriesKeys.includes(currentSerie);
      const isSharedSeries = sharedSeries.includes(currentSerie);
      let data;
      if (isHistorical) {
        data = timeline.map(e => {
          if (e > Math.max(...historicalDates) || e < Math.min(...historicalDates)) {
            return null;
          }
          const matchingIndex = historicalDates.indexOf(e);
          return historicalSeries[currentSerie][matchingIndex];
        });
      }

      if (isForecast) {
        data = timeline.map(e => {
          if (e > Math.max(...forecastedDates) || e < Math.min(...forecastedDates)) {
            return null;
          }
          const matchingIndex = forecastedDates.indexOf(e);
          return forecastedSeries[currentSerie][matchingIndex];
        });
      }

      if (isSharedSeries) {
        data = timeline.map(e => {
          if (
            e > Math.max(...forecastedDates, ...historicalDates) ||
            e < Math.min(...forecastedDates, ...historicalDates)
          ) {
            return null;
          }
          const matchingForecastIndex = forecastedDates.indexOf(e);
          const matchingHistoricalIndex = historicalDates.indexOf(e);
          if (matchingForecastIndex && forecastedSeries[currentSerie][matchingForecastIndex]) {
            return forecastedSeries[currentSerie][matchingForecastIndex];
          }
          if (matchingHistoricalIndex && historicalSeries[currentSerie][matchingHistoricalIndex]) {
            return historicalSeries[currentSerie][matchingHistoricalIndex];
          }
          return null;
        });
      }

      return {
        ...accumulator,
        [currentSerie]: [...data]
      };
    }, {});

    return { mergedSeries, timeline };
  };

  const mergeObservationDateSeries = () => {
    const lastHistoricalScenarioDate = historicalScenarioDate[historicalScenarioDate.length - 1];
    const firstForecastedScenarioDate = forecastedScenarioDate[0];

    const isOverlap = historicalScenarioDate.includes(firstForecastedScenarioDate);
    const overlappedDays =
      historicalScenarioDate.indexOf(lastHistoricalScenarioDate) -
      historicalScenarioDate.indexOf(firstForecastedScenarioDate);

    const adjustForcastedData = (data: number[] | string[], isSharedSeries: boolean) => {
      if (!isOverlap) {
        return data;
      }
      return data.slice(isSharedSeries ? overlappedDays + 1 : overlappedDays);
    };

    const allSeries = union(historicalSeriesKeys, forecastedSeriesKeys);
    const timeline = [...historicalScenarioDate, ...adjustForcastedData(forecastedScenarioDate, true)];

    const historicalFill = new Array(historicalScenarioDate.length - 1).fill(null);
    const expectedFill = new Array(forecastedScenarioDate.length - 1).fill(null);

    const mergedSeries = allSeries.reduce((accumulator, currentSerie) => {
      const isHistorical = historicalSeriesKeys.includes(currentSerie);
      const isForecast = forecastedSeriesKeys.includes(currentSerie);
      const historyData = isHistorical ? historicalSeries[currentSerie] : historicalFill;

      const data = isForecast ? forecastedSeries[currentSerie] : expectedFill;
      const isSharedSeries = sharedSeries.includes(currentSerie);
      const expectedData = adjustForcastedData(data, isSharedSeries);

      return {
        ...accumulator,
        [currentSerie]: [...historyData, ...expectedData]
      };
    }, {});
    return { mergedSeries, timeline };
  };

  const { timeline, mergedSeries } = isNdo ? mergeNdoSeries() : mergeObservationDateSeries();

  return {
    maxLacRank,
    today,
    todayNdo,
    ...(isNdo ? { ndo: timeline } : { date: timeline }),
    ...mergedSeries
  };
}
