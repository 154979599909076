import React from "react";
import { observer } from "mobx-react";
import difference from "lodash.difference";

import SidebarSelect from "shared/components/Sidebar/SidebarSelect/SidebarSelect";
import SidebarFilter from "shared/components/Sidebar/SidebarFilter/SidebarFilter";
import SidebarHourSelect from "shared/components/Sidebar/SidebarHourSelect/SidebarHourSelect";
import ButtonSelector from "shared/components/ButtonSelector/ButtonSelector";
import isSidebarFilterVisible from "shared/helpers/isSidebarFilterVisible/isSidebarFilterVisible";

import type { AnalysisMappingsStore } from "models/AnalysisMappings.model";
import type { Tab } from "models/Tab/Tab.model";

type Props = {
  mappings: AnalysisMappingsStore;
  pageContext: Tab;
};

function InfluenceHistoryFlightFilters(props: Props) {
  const { mappings, pageContext } = props;
  const { disabledFilters, filters, changeFilter, sidebar } = pageContext;

  const matchingFlightNumbers = mappings.getFlightNumbers(filters);
  const flightNumbers = difference(mappings.getFlightNumbers(null), matchingFlightNumbers);

  return (
    <>
      <SidebarFilter
        isTemporarilyDisabled={"flightNumber" in disabledFilters}
        isVisible={isSidebarFilterVisible("flight number", "flightNumber", sidebar)}
      >
        <SidebarSelect
          fuzzySearch
          items={[
            { groupItems: matchingFlightNumbers, label: "Matching pre-selected" },
            { groupItems: flightNumbers, label: "All flights" }
          ]}
          onChange={filterValue => changeFilter("flightNumber", filterValue)}
          selectedItems={filters.flightNumber}
          title="Flight Number"
          withAutoSeparatedItems
        />
      </SidebarFilter>
      <SidebarFilter
        isTemporarilyDisabled={"depTime" in disabledFilters}
        isVisible={isSidebarFilterVisible("departure time", "depTime", sidebar)}
      >
        <SidebarHourSelect
          hours={filters.depTime}
          onChange={filterValue => changeFilter("depTime", filterValue)}
          title="Departure Time"
        />
      </SidebarFilter>
      <SidebarFilter
        isTemporarilyDisabled={"depTimeBucket" in disabledFilters}
        isVisible={isSidebarFilterVisible("departure time bucket", "depTimeBucket", sidebar)}
      >
        <SidebarSelect
          fuzzySearch
          items={mappings.data.depTimeBucket}
          onChange={filterValue => changeFilter("depTimeBucket", filterValue)}
          selectedItems={filters.depTimeBucket}
          title="Departure Time Bucket"
        />
      </SidebarFilter>
      <SidebarFilter
        isTemporarilyDisabled={"cabinClass" in disabledFilters}
        isVisible={isSidebarFilterVisible("cabin class", "cabinClass", sidebar)}
      >
        <ButtonSelector
          items={mappings.getCabinClasses()}
          onSelect={filterValue => changeFilter("cabinClass", filterValue)}
          selectedItems={filters.cabinClass}
          title="Cabin Class"
        />
      </SidebarFilter>
    </>
  );
}

export default observer(InfluenceHistoryFlightFilters);
