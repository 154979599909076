import { observer } from "mobx-react";
import React from "react";
import { Button, InputGroup } from "@blueprintjs/core";

type Props = {
  onChange: (filterQuery: string, filterKey: string) => void;
  placeholder?: string;
  value: string;
};

function SearchInput(props: Props) {
  const { onChange, placeholder = "Search filters...", value = "" } = props;
  const isValue = value.length > 0;

  return (
    <InputGroup
      autoComplete="off"
      autoFocus
      className="m-2"
      data-testid="search-filters"
      id="text-input"
      leftIcon="search"
      onChange={({ target }) => onChange(target.value, "")}
      placeholder={placeholder}
      rightElement={
        isValue ? (
          <Button data-testid="search-filters-clear" icon="cross" minimal onClick={() => onChange("", "")} />
        ) : undefined
      }
      value={value}
    />
  );
}

export default observer(SearchInput);
