import isEmpty from "lodash.isempty";

function toggleFilterValue(filterKey, filterValueToToggle, disabledFilters) {
  if (!disabledFilters[filterKey]) {
    disabledFilters[filterKey] = [];
  }

  if (disabledFilters[filterKey].includes(filterValueToToggle)) {
    disabledFilters[filterKey] = disabledFilters[filterKey].filter(
      disabledFilterValue => disabledFilterValue !== filterValueToToggle
    );
    if (isEmpty(disabledFilters[filterKey])) {
      delete disabledFilters[filterKey];
    }
  } else {
    disabledFilters[filterKey].push(filterValueToToggle);
  }
}

export default toggleFilterValue;
