import React from "react";
import { observer } from "mobx-react";

import PriceAdjustmentForm from "modules/Influence/PriceAdjustmentForm/PriceAdjustmentForm";
import PriceLimits from "modules/Influence/PriceLimits/PriceLimits";
import CompSensForm from "modules/Influence/CompSensForm/CompSensForm";
import { useStores } from "store/Store";
import AbsolutePriceAdjustmentForm from "modules/Influence/AbsolutePriceAdjustmentForm/AbsolutePriceAdjustmentForm";

type Props = {
  readOnly?: boolean;
};

function InfluenceControlForm(props: Props) {
  const { readOnly = false } = props;
  const { influenceStore } = useStores();
  const { state } = influenceStore;
  const { influenceType } = state;

  const renderControls = () => {
    switch (influenceType) {
      case "PA":
        return <PriceAdjustmentForm readOnly={readOnly} />;
      case "AP":
        return <AbsolutePriceAdjustmentForm readOnly={readOnly} />;
      case "MM": {
        return <PriceLimits readOnly={readOnly} />;
      }
      case "CS": {
        return <CompSensForm readOnly={readOnly} />;
      }
      default:
        return null;
    }
  };

  return renderControls();
}

export default observer(InfluenceControlForm);
