import React from "react";
import { Button, Spinner } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { SystemSettingsSelect } from "./SystemSettingsUI";
import SystemSettingsMenuItem from "./SystemSettingsMenuItem";

const switcherOptions = [
  { label: "ON", value: true },
  { label: "OFF", value: false }
];

type Props = {
  cirrusDefaultStatus: string,
  isLoading: boolean,
  onChange: Function
};

function CirrusDefaultStatusControl({ cirrusDefaultStatus, onChange, isLoading }: Props) {
  const cirrusStatusLabel = switcherOptions.find(option => option.value === cirrusDefaultStatus)?.label;
  const buttonText = isLoading ? "Loading..." : cirrusStatusLabel;
  const rightIcon = isLoading ? <Spinner size={12} /> : "caret-down";

  return (
    <SystemSettingsSelect
      disabled={isLoading}
      filterable={false}
      itemRenderer={(item, itemProps) => (
        <SystemSettingsMenuItem
          key={item.label}
          data-testid="cirrus-default-status-menu-item"
          item={item}
          itemProps={itemProps}
          selected={cirrusDefaultStatus}
        />
      )}
      items={switcherOptions}
      onItemSelect={option => onChange({ cirrusDefaultStatus: option.value })}
      popoverProps={{ minimal: true }}
    >
      <Button
        data-testid="system-settings-cirrus-status-select"
        disabled={isLoading}
        rightIcon={rightIcon}
        text={buttonText}
      />
    </SystemSettingsSelect>
  );
}

export default observer(CirrusDefaultStatusControl);
