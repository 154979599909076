import React from "react";
import { observer } from "mobx-react";

import formatValueWithUnit from "shared/helpers/formatValueWithUnit/formatValueWithUnit";
import { influenceTypeNames } from "modules/Influence/influenceConstants";
import { StyledCard, StyledHeaderTitle } from "../InfluenceStyles";

import Row from "shared/components/Row/Row";
import { PriceLimit } from "./PriceLimits.types";

type Props = {
  index: number;
  submittedPriceLimits: PriceLimit[];
};

function PriceLimitsSummary(props: Props) {
  const { index, submittedPriceLimits } = props;
  const { min, max, startNdo, endNdo } = submittedPriceLimits[index];

  const renderValue = value => {
    if (value === null || value === undefined) {
      return "--";
    }
    if (value === -1) {
      return "Remove Limits";
    }
    return formatValueWithUnit(value, "currency");
  };

  return (
    <StyledCard className="p-3 mb-2" data-testid="price-limits-summary">
      <Row className="mb-2">
        <div className="col-12">
          <StyledHeaderTitle>
            {influenceTypeNames.MM} {submittedPriceLimits.length > 1 && `#${index + 1}`}
          </StyledHeaderTitle>
        </div>
      </Row>
      <Row>
        <div className="col-8">
          <div className="mr-2 bp3-text-muted bp3-text-small" data-testid="influence-price-limits-ndo-info">
            Prices impacted between{" "}
            <strong>
              {startNdo} to {endNdo}
            </strong>{" "}
            days to departure.
          </div>
        </div>
        <div className="pl-0 col-4">
          <div className="align-items-center flex-column" data-testid="influence-price-limits-value">
            <div className="d-flex align-items-center w-100 mb-2">
              <strong className="mr-2">Min</strong>
              <div className="ml-auto">{renderValue(min)}</div>
            </div>
            <div className="d-flex align-items-center w-100 mb-2">
              <strong className="mr-2">Max</strong>
              <div className="ml-auto">{renderValue(max)}</div>
            </div>
            <div className="d-flex align-items-center w-100 mb-2">
              <strong className="mr-2">Start</strong>
              <div className="ml-auto">NDO {startNdo}</div>
            </div>
            <div className="d-flex align-items-center w-100">
              <strong className="mr-2">End</strong>
              <div className="ml-auto">NDO {endNdo}</div>
            </div>
          </div>
        </div>
      </Row>
    </StyledCard>
  );
}

export default observer(PriceLimitsSummary);
