import { Metric } from "../../Explore/metrics/metrics";

export const influenceHistoryMetrics: Metric[] = [
  {
    filters: [{ key: "owMarket" }],
    key: "market",
    name: "Market"
  },
  {
    filters: [
      { key: "flightNumber" },
      { isMetric: true, key: "depTime" },
      { key: "depTimeBucket" },
      { key: "cabinClass" }
    ],
    key: "flight",
    name: "Flight"
  },
  {
    filters: [
      { key: "quarter" },
      { key: "ndo" },
      { key: "depMonth" },
      { key: "depWeek" },
      { key: "depDate" },
      { key: "depDow" }
    ],
    key: "dep-date",
    name: "Departure Date"
  },
  {
    filters: [
      { key: "type" },
      { key: "adjustmentIds" },
      { key: "status" },
      { key: "createdOn" },
      { key: "updatedOn" },
      { key: "userIds" }
    ],
    key: "influence",
    name: "Influence"
  }
];

export const filterOrder = influenceHistoryMetrics.map(({ filters }) => filters.map(({ key }) => key)).flat();
