import React, { useEffect, useState } from "react";
import isEmpty from "lodash.isempty";
import { observer } from "mobx-react";
import styled from "@emotion/styled";
import { Collapse, Colors, Icon, IconName } from "@blueprintjs/core";
import kebabCase from "lodash.kebabcase";

import TemplateItem from "../TemplateItem/TemplateItem";
import ReadMoreButton from "shared/components/ReadMoreButton/ReadMoreButton";
import { useStores } from "store/Store";

const StyledCollapse = styled(Collapse)`
  background-color: ${Colors.LIGHT_GRAY5};
  left: 0px;
  top: 1px;
  width: 280px;
`;

const StyledEmptyList = styled("div")`
  background-color: ${Colors.LIGHT_GRAY5};
`;

const StyledListHeader = styled("div")<{ isOpen: boolean }>`
  border-top: 1px solid ${Colors.LIGHT_GRAY2};
  border-bottom: ${({ isOpen }) => `1px solid ${isOpen ? Colors.LIGHT_GRAY2 : "transparent"}`};
  color: ${Colors.DARK_GRAY1};
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  height: 50px;
  letter-spacing: 0.8px;
  line-height: 1.1875;
`;

const StyledTemplateItem = styled(TemplateItem)`
  cursor: pointer;
`;

const emptyDictionary = {
  "My Saved Analyses": {
    hint: "To save an analysis, click on a chevron button in the analysis tab.",
    readMore: "Read More",
    text: "There are no Saved Analyses yet."
  },

  "System Analyses": {
    hint: "",
    readMore: "",
    text: "There are no System Analyses available for your organization."
  },

  "Team Analyses": {
    hint: "",
    readMore: "Learn how to create one",
    text: "There are no Team Analyses yet."
  }
};

const docsUrl = "https://help.cirrus.ai/en/articles/4999097-saved-analyses-and-templates";

type Props = {
  filterQuery: string;
  icon: IconName;
  templateKey: string;
  type: string;
};

function TemplatesList(props: Props) {
  const { templatesStore } = useStores();
  const { filterQuery, icon, templateKey, type } = props;
  const [isOpen, setIsOpen] = useState(true);

  const templates = templatesStore[templateKey];
  const filteredTemplates = filterQuery
    ? templates.filter(template => template?.label.toLowerCase().includes(filterQuery))
    : templates;
  const numberOfResults = filterQuery ? filteredTemplates.length : templates.length;

  useEffect(() => {
    if (numberOfResults) {
      setIsOpen(true);
    }
  }, [filterQuery, numberOfResults]);

  if (isEmpty(filteredTemplates) && filterQuery) {
    return null;
  }

  const readMoreUrl = emptyDictionary[type].readMore;
  const emptyRecords = (
    <StyledEmptyList className="text-center px-2 py-3">
      <p className="my-1">{emptyDictionary[type].text}</p>
      <p className="mb-1">{emptyDictionary[type].hint}</p>
      {readMoreUrl && (
        <a href={docsUrl} rel="noopener noreferrer" target="_blank">
          {emptyDictionary[type].readMore}
        </a>
      )}
    </StyledEmptyList>
  );

  const items = isEmpty(filteredTemplates) ? (
    emptyRecords
  ) : (
    <StyledCollapse isOpen={isOpen} keepChildrenMounted>
      <div className="p-2" data-testid={kebabCase(`${type}-list`)}>
        {filteredTemplates.map(template => (
          <StyledTemplateItem key={template.id} template={template} type={type} />
        ))}
      </div>
    </StyledCollapse>
  );

  return (
    <>
      <StyledListHeader
        className="d-flex align-items-center px-2"
        data-testid="template-list"
        isOpen={isOpen}
        onClick={() => setIsOpen(!isOpen)}
      >
        <Icon className="mr-1" color={Colors.GRAY1} icon={icon} />
        {`${type} (${numberOfResults})`}
        <div onClick={e => e.stopPropagation()}>
          <ReadMoreButton href={docsUrl} text="Read more" />
        </div>
        <Icon className="ml-auto" color={Colors.GRAY1} icon={isOpen ? "chevron-up" : "chevron-down"} />
      </StyledListHeader>
      {items}
    </>
  );
}

export default observer(TemplatesList);
