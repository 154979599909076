import React, { useContext } from "react";
import { observer } from "mobx-react";
import styled from "@emotion/styled";
import { Colors, Tooltip } from "@blueprintjs/core";
import TagsListContext from "../TagsListContext/TagsListContext";

const StyledFilterToggle = styled("div")<{ disabled: boolean; isLoading?: boolean }>`
  align-items: center;
  height: 100%;
  display: flex;
  width: 15px;
  height: 100%;
  cursor: ${({ isLoading }) => (isLoading ? "wait" : "cursor")};

  &:before {
    background-color: ${({ disabled }) => (disabled ? Colors.GRAY2 : Colors.GREEN5)};
    border-radius: 50%;
    content: "";
    display: inline-block;
    height: 5px;
    width: 5px;
  }

  &:hover {
    &:before {
      background-color: ${({ disabled }) => (disabled ? Colors.GRAY4 : Colors.GREEN4)};
    }
  }
`;

type Props = {
  disabled?: boolean;
  onToggle: () => void;
};

function TagFilterToggle(props: Props) {
  const { disabled = false, onToggle } = props;
  const { isDataLoading } = useContext(TagsListContext);

  const handleClick = event => {
    event.stopPropagation();
    !isDataLoading && onToggle();
  };
  const tooltipText = disabled ? "Enable this filter" : "Temporarily disable this filter";

  return (
    <Tooltip boundary="viewport" className="d-flex" content={tooltipText} hoverOpenDelay={1000}>
      <StyledFilterToggle
        data-testid="toggle-filter-button"
        disabled={disabled}
        isLoading={isDataLoading}
        onClick={handleClick}
      />
    </Tooltip>
  );
}

export default observer(TagFilterToggle);
