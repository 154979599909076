import React, { useEffect } from "react";
import { useRouteMatch } from "react-router-dom";
import { observer } from "mobx-react";
import { runInAction } from "mobx";

import AnalysisContent from "./AnalysisContent";
import { useStores } from "store/Store";
import { AnalysisSubpages } from "modules/App/App.types";

function Analysis() {
  const route = useRouteMatch<{ tabId: string }>();
  const { tabsStore } = useStores();

  const subpage = route.url.split("/").pop() as typeof AnalysisSubpages[number];
  const tab = tabsStore.tabsById[route.params.tabId];

  useEffect(() => {
    if (tab) {
      localStorage.setItem("activeTab", tab.id);
      if (
        !tab.activeSubPage ||
        (subpage === "explore" && tab.activeSubPage === "influence") ||
        (subpage === "influence" && tab.activeSubPage === "explore")
      ) {
        tab.changeActiveSubPage(subpage);
      } else {
        runInAction(() => {
          tab.flightsTable.pagination.pageIndex = 0;
        });
      }
    } else {
      localStorage.removeItem("activeTab");
    }
  }, [tab]);

  return <AnalysisContent tabId={route.params.tabId} />;
}

export default observer(Analysis);
