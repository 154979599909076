import React from "react";
import { observer } from "mobx-react";
import clsx from "clsx";
import { Position, Spinner, Switch, Tooltip } from "@blueprintjs/core";

import Module from "shared/components/Module/Module";
import ModuleTypeSelect from "./ModuleTypeSelect";
import WatchlistProgressBar from "./WatchlistProgressBar";
import { Status } from "modules/App/Status";
import { useStores } from "store/Store";
import { WidgetStatusRender } from "./Dashboard.utils";

type Props = {
  children: React.ReactElement | null;
  status: Status;
  isEmpty: boolean;
};

const spinnerComponent = [<Spinner className="mb-1" size={16} />];

function WatchlistModule({ children = null, status, isEmpty }: Props) {
  const { dashboardWatchlistStore } = useStores();
  const {
    data,
    analysesCount,
    currentAnalysesCount,
    filterWatchlist,
    selectedMetric,
    changeSelectedMetric,
    toggleFilterWatchlist
  } = dashboardWatchlistStore;
  const withCenteredText = (isEmpty && status === Status.DONE) || status !== Status.DONE;
  const showProgress = data.length && data.length < analysesCount;

  const watchlistSubtitle = () => {
    if (!filterWatchlist) {
      return [`· ${analysesCount} analyses`];
    }

    if ([Status.LOADING, Status.INIT].includes(status)) {
      return spinnerComponent;
    }

    return !showProgress || currentAnalysesCount ? [`· ${currentAnalysesCount} analyses`] : spinnerComponent;
  };

  return (
    <div className={clsx("d-flex flex-column flex-shrink-1 mh-0 h-100")}>
      <Module
        childrenClassName="h-100"
        className={clsx("flex-shrink-1 ", { "h-100": withCenteredText, "h-auto": !withCenteredText })}
        minHeight={0}
        subtitle={watchlistSubtitle()}
        title="Watchlist"
        titleTools={
          <div className="d-flex h-100 align-items-center">
            <Tooltip content="Hide analyses with 0 flights" hoverOpenDelay={1000} position={Position.BOTTOM}>
              <Switch
                checked={filterWatchlist}
                className="mx-2 mt-1 mb-0 py-1"
                data-testid="watchlist-toggle"
                disabled={status !== Status.DONE}
                onChange={toggleFilterWatchlist}
              />
            </Tooltip>
            <ModuleTypeSelect
              className="watchlist-module-type-select"
              onChange={changeSelectedMetric}
              selected={selectedMetric}
            />
          </div>
        }
      >
        {showProgress ? <WatchlistProgressBar value={data.length / analysesCount} /> : null}

        <WidgetStatusRender isEmpty={isEmpty} status={status} type="watchlist">
          {children}
        </WidgetStatusRender>
      </Module>
    </div>
  );
}

export default observer(WatchlistModule);
