import React from "react";
import styled from "@emotion/styled";
import { AnchorButton, Colors, Icon, Spinner } from "@blueprintjs/core";
import { rgba } from "polished";

type Props = {
  isLoading?: boolean;
  disabled?: boolean;
  onClick: () => void;
};

export const StyledAnchorButton = styled(AnchorButton)`
  background-color: ${rgba(Colors.LIGHT_GRAY5, 0.7)} !important;
  border-radius: 0;
  min-height: 100% !important;
  min-width: 100% !important;
`;

function LinkCell({ isLoading, disabled, onClick }: Props) {
  if (isLoading) {
    return <Spinner size={12} />;
  }

  return (
    <StyledAnchorButton
      disabled={disabled}
      icon={<Icon color={Colors.GRAY2} icon="share" iconSize={10} />}
      intent="primary"
      minimal
      onClick={onClick}
      small
    />
  );
}

export default LinkCell;
