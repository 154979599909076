import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { NonIdealState, Spinner } from "@blueprintjs/core";

import { useStores } from "store/Store";
import globalStyles from "../globalStyles";

import Login from "views/Login";
import Application, { StyledApp } from "../Application";
import AuthFooter from "../AuthFooter/AuthFooter";

function AuthGate() {
  const { appStore, themeStore } = useStores();

  useEffect(() => {
    appStore.init();
  }, [appStore]);

  if (appStore.isUninitialized || !window.crypto) {
    return null;
  }

  if (appStore.isLoggedOut) {
    return (
      <StyledApp className={themeStore.themeClass}>
        {globalStyles}
        <Login />
        <AuthFooter />
      </StyledApp>
    );
  }

  if (appStore.isLoading) {
    return (
      <StyledApp className={`d-flex ${themeStore.themeClass}`}>
        {globalStyles}
        <NonIdealState
          description={<span className="bp3-text-muted bp3-text-small">Initializing application</span>}
          icon={<Spinner />}
        />
        <AuthFooter />
      </StyledApp>
    );
  }

  if (appStore.isError) {
    return (
      <StyledApp className={`d-flex ${themeStore.themeClass}`}>
        {globalStyles}
        <NonIdealState
          description={<span className="bp3-text-muted bp3-text-small">Error happened</span>}
          icon="build"
        />
        <AuthFooter />
      </StyledApp>
    );
  }

  return (
    <>
      {globalStyles}
      <Application />
    </>
  );
}

export default observer(AuthGate);
