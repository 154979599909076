import React from "react";
import { observer } from "mobx-react";

import DataTableCell from "../DataTableCell/DataTableCell";
import DataTableRow from "../DataTableRow/DataTableRow";
import { Cell, Row, Page } from "types/react-table.types";
import { CellType } from "types/DataTable.types";

type Props = {
  cellRenderers?: { [key: string]: CellType | ((cell: Cell) => React.Component) };
  columnConfig?: { columnsWithoutFixedNumber: string[]; columnsAligned: { [key: string]: string } };
  extraCellStyles?: object;
  onRowClick?: (row: Row) => void;
  prepareRow: (row: Row) => void;
  rows: Page;
  selectedRows?: number[];
};

const DataTableRows = ({
  cellRenderers,
  columnConfig,
  extraCellStyles = {},
  onRowClick,
  prepareRow,
  rows,
  selectedRows = []
}: Props) =>
  rows.map((row: Row) => {
    prepareRow(row);
    return (
      <DataTableRow
        key={row.id}
        isNested={row.depth > 0}
        isSelected={selectedRows?.includes(row.id)}
        onRowClick={onRowClick}
        row={row}
      >
        {row.cells.map((cell: Cell, index: number) => (
          <DataTableCell
            key={`${cell?.column?.id}-${index}`}
            cell={cell}
            columnConfig={columnConfig}
            customRenderer={cellRenderers?.[cell?.column?.id]}
            extraStyles={extraCellStyles[cell?.column?.id]}
          />
        ))}
      </DataTableRow>
    );
  });

export default observer(DataTableRows);
