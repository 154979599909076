import React from "react";
import pluralize from "pluralize";
import { Button, Colors, Icon, Intent } from "@blueprintjs/core";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import { Status } from "modules/App/Status";

import ModuleItem, { RenderItemContent } from "./ModuleItem";
import { AppToaster } from "services/Toaster";
import { tabCountReachedMessage } from "modules/App/messages";
import { useStores } from "store/Store";

type Props = {
  pieceData: {
    data: {
      numberOfFlights: number;
      rtMarket: string;
    }[];
    label?: string;
    pageIndex: number;
    status: Status;
    type?: string;
  };
};

const tabNewConfig = (
  tab: {
    applied: {
      filters: {};
    };
    flightsTable: {
      aggregations: string[];
    };
    filters: {};
    id: string;
    label: string;
  },
  { isMyMarkets, userId, rtMarket }: { isMyMarkets: boolean; userId: number; rtMarket: string }
) => {
  const newTab = tab;

  const newFilters = {
    analystId: isMyMarkets ? [userId] : [],
    cabinClass: ["Y"],
    rtMarket: [rtMarket]
  };

  newTab.applied.filters = newFilters;
  newTab.filters = newFilters;
  newTab.flightsTable.aggregations = ["rtMarket", "depMonth"];
  newTab.label = rtMarket;
  return newTab;
};

function MarketsItem({ pieceData }: Props) {
  const { appStore, dashboardMarketsStore, dashboardStore, tabsStore } = useStores();
  const history = useHistory();

  const { isMyMarkets } = dashboardStore;
  const { addTab, canCreateTab, defaultNewTab } = tabsStore;

  const { getFlightsData, selectedMetric } = dashboardMarketsStore;
  const { userId } = appStore;
  const { data, pageIndex, status } = pieceData;

  const isDone = status === Status.DONE;

  const refetchPieceData = () => getFlightsData({ pageIndex });

  const addNewTab = (rtMarket: string) => {
    const newTab = tabNewConfig(defaultNewTab(), { isMyMarkets, rtMarket, userId });

    addTab(newTab);
    history.push(`/analysis/${newTab.id}/explore`);
  };

  const onClickMarketHeader = rtMarket => () => {
    if (canCreateTab) {
      addNewTab(rtMarket);
    } else {
      AppToaster.show({ intent: Intent.WARNING, message: tabCountReachedMessage });
    }
  };

  const renderItem = (pieceData, market, marketIndex) => {
    const subtitle = `${market.numberOfFlights} ${pluralize("flights", market.numberOfFlights)}`;
    return (
      <ModuleItem
        key={`markets-module-${pieceData.pageIndex}-${marketIndex}`}
        onClick={onClickMarketHeader(market.rtMarket)}
        status={status}
        subtitle={subtitle}
        title={market.rtMarket}
      >
        <RenderItemContent data={market} selectedMetric={selectedMetric} />
      </ModuleItem>
    );
  };

  const renderError = () => (
    <div className="d-flex">
      <div className="flex-column">
        <Icon color={Colors.GOLD4} icon="warning-sign" /> Error getting data
        <Button data-testid="markets-item-error-button" icon="refresh" minimal onClick={refetchPieceData} />
      </div>
    </div>
  );

  return (
    <div>{isDone ? data.map((market, marketIndex) => renderItem(pieceData, market, marketIndex)) : renderError()}</div>
  );
}

export default observer(MarketsItem);
