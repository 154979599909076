import { action, computed, makeObservable, observable } from "mobx";
import { AxiosPromise } from "axios";

import type { RootStore } from "modules/App/Root.model";
import { FlightCountResponse, FlightsResponse } from "types/Flights.types";
import { FlightsTable } from "modules/FlightsTable/FlightsTable.model";
import { initColumns } from "./DashboardMarketMonthMetrics.utils";
import { isCancel } from "services/Api";
import { Status } from "modules/App/Status";

const config = {
  extraParams: {
    predefinedOutputFilters: {
      xDayFinalRevenueBuildExpectedThreshold: true
    }
  }
};

export class DashboardMarketMonthMetricsStore {
  @observable xDayBuild: number = 7;
  @observable flightsCount: {
    numberOfRows?: number;
    status: Status;
    totalNumberOfFlights?: number;
  } = {
    numberOfRows: undefined,
    status: Status.INIT,
    totalNumberOfFlights: undefined
  };

  rootStore: RootStore;
  table: FlightsTable;

  constructor(rootStore: RootStore, newMarket?: object) {
    makeObservable(this);
    const seed = {
      ...newMarket,
      table: new FlightsTable({
        apiIdentifier: "dashboardMarketMonths",
        columns: initColumns,
        fixedColumns: {},
        selectedRows: null
      })
    };

    Object.assign(this, seed);

    this.rootStore = rootStore;
    this.table = seed.table;
  }

  @action.bound
  getFlightsData(params: object): AxiosPromise<FlightsResponse> {
    const flightsDataParams = {
      filters: {
        analystId: this.rootStore.dashboardStore.isMyMarkets ? this.rootStore.dashboardStore.analystFilterId : []
      },
      ...config,
      ...params,
      xDayBuild: this.xDayBuild
    };

    return this.table.fetchFlightsData(flightsDataParams, "dashboard-markets-month").then(resolvedPromise => {
      if (resolvedPromise) {
        const { lastUpdated } = resolvedPromise.data;
        this.rootStore.dashboardStore.setLastUpdated(lastUpdated);
      } else {
        this.rootStore.dashboardStore.setLastUpdated("");
      }
    });
  }

  @action.bound
  getFlightsCountData(params: object): AxiosPromise<FlightCountResponse> {
    const { flightsCount, xDayBuild } = this;

    flightsCount.status = Status.LOADING;
    flightsCount.numberOfRows = undefined;
    flightsCount.totalNumberOfFlights = undefined;

    const flightsCountParams = {
      filters: {
        analystId: this.rootStore.dashboardStore.isMyMarkets ? this.rootStore.dashboardStore.analystFilterId : []
      },
      ...params,
      ...config,
      xDayBuild
    };

    return this.table
      .fetchFlightsCountData(flightsCountParams, "dashboard-markets-month-count")
      .then(data => {
        const { numberOfRows, totalNumberOfFlights } = data;
        flightsCount.numberOfRows = numberOfRows;
        flightsCount.totalNumberOfFlights = totalNumberOfFlights;
        flightsCount.status = Status.DONE;
      })
      .catch(thrown => {
        if (isCancel(thrown)) {
          flightsCount.status = Status.LOADING;
          return;
        }
        flightsCount.status = Status.ERROR;
      });
  }

  @action.bound
  refetchTableData(params: object) {
    this.getFlightsData(params);
    this.getFlightsCountData(params);
  }

  @computed
  get isError(): boolean {
    return this.table.status === Status.ERROR;
  }

  @computed
  get isLoaded(): boolean {
    return this.table.status === Status.DONE;
  }

  @computed
  get isLoading(): boolean {
    return this.table.status === Status.LOADING;
  }
}
