// @flow

import React, { useState } from "react";
import { observer } from "mobx-react";
import styled from "@emotion/styled";
import { Button, Colors } from "@blueprintjs/core";
import kebabCase from "lodash.kebabcase";

const SidebarGroupStyled = styled("div")`
  border-bottom: 1px solid #e1e8ed;
`;

const HeaderGroupStyled = styled(Button)`
  font-size: 16px;
  .bp3-button-text {
    display: flex;
    width: 100%;
    align-items: center;
  }
`;

const ChildrenGroupStyled = styled("div")`
  visibility: ${({ isShown }) => (isShown ? "" : "hidden")};
  height: ${({ isShown }) => (isShown ? "auto" : "0")};
  border-top: ${({ isShown }) => (isShown ? "1px solid #e1e8ed" : "0")};
  background: ${Colors.LIGHT_GRAY5};
`;

type Props = {
  children: ?Element,
  forceShown: ?boolean,
  title: string,
  titleRight: ?Element,
  withoutCollapsing: ?boolean
};

function SidebarGroup(props: Props) {
  const { children = null, forceShown = false, title = "", titleRight = null, withoutCollapsing = false } = props;

  const [isManuallyShown, setManuallyShown] = useState(false);
  const isShown = isManuallyShown || forceShown;
  const testId = kebabCase(title);

  if (withoutCollapsing) {
    return <div data-testid="sidebar-group">{children}</div>;
  }

  return (
    <SidebarGroupStyled data-testid="sidebar-group">
      <HeaderGroupStyled
        className="m-0 p-3 bp3-heading"
        data-testid={`sidebar-button-${testId}`}
        fill
        minimal
        onClick={() => {
          if (forceShown) return;
          setManuallyShown(!isShown);
        }}
        rightIcon={isShown ? "chevron-down" : "chevron-right"}
      >
        <span className="mr-auto" data-testid="sidebar-group-title">
          {title}
        </span>
        {titleRight}
      </HeaderGroupStyled>
      <ChildrenGroupStyled className={`${isShown ? "pt-3 px-3 pb-1" : "p-0"}`} isShown={isShown}>
        {isShown && children}
      </ChildrenGroupStyled>
    </SidebarGroupStyled>
  );
}

export default observer(SidebarGroup);
