import React from "react";
import { Button, Spinner } from "@blueprintjs/core";

import { SystemSettingsSelect } from "./SystemSettingsUI";
import SystemSettingsMenuItem from "./SystemSettingsMenuItem";

const unitOptions = [
  { label: "Kilometers (km)", value: "km" },
  { label: "Miles (mi)", value: "mi" }
];

type Props = {
  distanceUnit: string,
  isLoading: boolean,
  onChange: Function
};

function DistanceControl({ distanceUnit, onChange, isLoading }: Props) {
  const distanceLabel = unitOptions.find(option => option.value === distanceUnit)?.label;
  const buttonText = isLoading ? "Loading..." : distanceLabel;
  const rightIcon = isLoading ? <Spinner size={12} /> : "caret-down";

  return (
    <SystemSettingsSelect
      disabled={isLoading}
      filterable={false}
      itemRenderer={(item, itemProps) => (
        <SystemSettingsMenuItem
          key={item.label}
          data-testid="distance-menu-item"
          item={item}
          itemProps={itemProps}
          selected={distanceUnit}
        />
      )}
      items={unitOptions}
      onItemSelect={option => onChange({ distanceUnit: option.value })}
      popoverProps={{ minimal: true }}
    >
      <Button
        data-testid="system-settings-distance-select"
        disabled={isLoading}
        rightIcon={rightIcon}
        text={buttonText}
      />
    </SystemSettingsSelect>
  );
}

export default DistanceControl;
