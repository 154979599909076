import textMetrics from "text-metrics";

const elementTag = document.querySelector("th");
const textMetricsForTh = textMetrics.init(elementTag);
const textMetricsForThBold = textMetrics.init(elementTag, { fontWeight: 600 });

export function ThBoldTextWidth(text) {
  return Math.ceil(textMetricsForThBold.width(text));
}

export default function columnTextWidths(columnLabels: Object) {
  const columnsWithSizes = {};
  Object.entries(columnLabels).forEach(([key, value]) => {
    columnsWithSizes[key] = Math.ceil(textMetricsForTh.width(value));
  });
  return columnsWithSizes;
}
