import { InfluenceValidationError } from "types/Influence.types";

export const errorMessages = {
  contradictedValues:
    "Some of previously applied limits contradict with defined values. Please, remove or overwrite previously applied limits to proceed with this one or set non-contradictory limits",
  endIsHigher: "Start NDO must be greater than or equal to End NDO",
  NDORangesExceeds500: "NDO value must not exceed 500",
  overlappingNdoRanges: "NDO ranges must not overlap across all price limits"
};

export const extractErrorFields = (validationErrors: InfluenceValidationError[], index: number) =>
  validationErrors
    .filter(error => error.error_path.includes(`[${index}]`))
    .map(error => error.error_path.split(`[${index}]`)[1])
    .map(field => field.replace(/\[|]/g, ""));
