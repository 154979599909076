import React from "react";
import { observer } from "mobx-react";
import { useLocation } from "react-router-dom";
import styled from "@emotion/styled";

import AdminToolsNavigation from "components/AdminToolsNavigation";
import StyledScrollableContainer from "shared/components/StyledScrollableContainer/StyledScrollableContainer";
import CirrusDefaultMarket from "./CirrusDefaultMarket";
import CirrusODDates from "./CirrusODDates";
import SystemSettings from "./SystemSettings";
import MarketMappings from "./MarketMappings";
import RegionsSubregions from "./Regions";
import isEnv from "shared/helpers/isEnv/isEnv";

const StyledAdminContent = styled("div")`
  min-width: 0;
`;

const ADMIN_TOOLS_ROUTING = {
  ...(isEnv(["development", "staging"]) && { "/cirrus-status/default-markets": CirrusDefaultMarket }),
  "/cirrus-status/od-dates": CirrusODDates,
  "/market-mappings": MarketMappings,
  "/regions": RegionsSubregions,
  "/system-settings": SystemSettings
};

export const ADMIN_TOOLS_ROUTES = Object.keys(ADMIN_TOOLS_ROUTING);

function AdminTools() {
  const { pathname } = useLocation();

  const Content = ADMIN_TOOLS_ROUTING[pathname];

  return (
    <StyledScrollableContainer className="d-flex flex-grow-1 h-100">
      <AdminToolsNavigation currentPage={pathname} />
      <StyledAdminContent className="flex-grow-1 d-flex flex-column">
        <Content />
      </StyledAdminContent>
    </StyledScrollableContainer>
  );
}

export default observer(AdminTools);
