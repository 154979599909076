// @flow

import type { MetricLabelsProps } from "./metricLabels";
import metricLongVersion from "./metricLongVersion";
import metricShortName from "./metricShortName";
import metricShortVersionByGroup from "./metricShortVersionByGroup";
import metricWithParent from "./metricWithParent";
import metricWithUnit from "./metricWithUnit";

type MetricGroupsProps = {
  args: MetricLabelsProps,
  baseGroup: Object | Function,
  parent: string
};

export function applyBuildPeriod(baseLabels: Object, xDayBuild: number) {
  const adjustedLabels = {};
  Object.entries(baseLabels).forEach((props: Object) => {
    const [key: string, value: string] = props;
    adjustedLabels[key] = value.replace("XD", `${xDayBuild}D`);
  });

  return adjustedLabels;
}

export default function prepareMetricGroup(props: MetricGroupsProps) {
  const { baseGroup, parent, args } = props;
  const {
    longVersion = false,
    shortVersion = false,
    shortVersionByGroup = false,
    isMiles = false,
    withParent = false,
    withUnits = false,
    xDayBuild = 7
  } = args;

  let baseLabels = baseGroup(isMiles);
  baseLabels = applyBuildPeriod(baseLabels, xDayBuild);

  if (withParent) {
    baseLabels = metricWithParent({
      group: baseLabels,
      parent,
      withoutDuplication: true
    });
  }

  if (withUnits) {
    baseLabels = metricWithUnit({ group: baseLabels, withUnits });
  }

  if (shortVersion) {
    return metricShortName({ group: baseLabels });
  }

  if (shortVersionByGroup) {
    baseLabels = metricWithParent({ group: baseLabels, parent });
    return metricShortVersionByGroup({ group: baseLabels });
  }

  if (longVersion) {
    baseLabels = metricWithParent({
      group: baseLabels,
      parent,
      withoutDuplication: true
    });
    return metricLongVersion({ group: baseLabels, parent, xDayBuild });
  }

  return baseLabels;
}
