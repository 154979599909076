// @flow

import React, { useEffect, useRef } from "react";
import { observer } from "mobx-react";

import { useStores } from "store/Store";

import Markets from "./Markets";
import { StyledCol } from "./Dashboard.utils";
import MarketsModule from "./MarketsModule";
import { fetchingMarketsProps } from "models/Dashboard/DashboardMarkets.model";

function MarketsModuleContainer() {
  const { dashboardStore, dashboardMarketsStore, analysisMappingsStore } = useStores();
  const { isLoaded, activePage } = dashboardStore;
  const { getFlightsCountData, resetMarkets } = dashboardMarketsStore;
  const { isAnalystDone } = analysisMappingsStore;
  const marketRef = useRef(null);

  useEffect(() => {
    return () => {
      resetMarkets();
    };
  }, [resetMarkets, activePage]);

  useEffect(() => {
    if (isLoaded && isAnalystDone) {
      const marketComponent = marketRef.current;
      if (
        marketComponent !== null &&
        // @ts-ignore
        marketComponent.clientHeight &&
        // @ts-ignore
        marketComponent.clientHeight > 1200 // 20 items * 60 height
      ) {
        getFlightsCountData({ pageSize: 40, pagination: { ...fetchingMarketsProps.pagination, pageSize: 40 } });
      } else {
        getFlightsCountData();
      }
    }
  }, [isLoaded, activePage, getFlightsCountData, isAnalystDone]);

  return (
    <StyledCol className="col-3 mh-0">
      <MarketsModule forwardedRef={marketRef}>
        <Markets />
      </MarketsModule>
    </StyledCol>
  );
}

export default observer(MarketsModuleContainer);
