import isEmpty from "lodash.isempty";
import { observer } from "mobx-react";
import React, { useMemo } from "react";

import columnTextWidths from "shared/helpers/columnTextWidths/columnTextWidths";
import { extraMetrics, metricsTypes } from "modules/App/constants";
import mergeContextualColumns from "modules/FlightsTable/mergeContextualColumns/mergeContextualColumns";
import metricLabels from "shared/metricLabels/metricLabels";
import { Status } from "modules/App/Status";
import formatLastUpdatedText from "shared/helpers/formatLastUpdatedText/formatLastUpdatedText";
import { getName } from "./FlightsTable.utils";
import { FILTERS_INIT } from "models/Tab/Tab.utils";
import { useStores } from "store/Store";
import { AnalysisSubpages } from "modules/App/App.types";
import type { FlightEntry } from "types/Flights.types";

import type { Row } from "types/react-table.types";
import DataTable from "shared/components/DataTable/DataTable";
import { SortingBy } from "shared/components/DataTable/DataTable.types";
import DateTimeCell from "shared/components/DataTable/DateTimeCell/DateTimeCell";
import InfluenceHistoryLinkCell from "./InfluenceHistoryLinkCell/InfluenceHistoryLinkCell";
import FlightsTableTools from "./FlightsTableTools/FlightsTableTools";
import Module from "shared/components/Module/Module";
import NumberOfFlights from "shared/components/NumberOfFlights/NumberOfFlights";

type Props = {
  baseMetrics: (string | string[])[][];
  subpage: typeof AnalysisSubpages[number];
  moduleProps?: object;
  tabId: string;
};

export const columnWidths = {
  depDate: 85,
  influenceHistoryLink: 24
};

const columnConfig = {
  columnsAligned: {
    depDow: "left",
    numberOfFlights: "right"
  },
  columnsWithoutFixedNumber: [
    "finalLoadFactorBaseline",
    "finalLoadFactorExpected",
    "priceAdjustment",
    "pricePercentile",
    "pricePercentileFrm",
    "xDayFinalLoadFactorBuildExpected",
    "xDayFinalLoadFactorExpected",
    "xDayPricePercentile",
    "xDayPricePercentileBuild",
    "xDayPricePercentileFrm"
  ]
};

function FlightsTable(props: Props) {
  const { baseMetrics = [], moduleProps, subpage, tabId } = props;
  const {
    analysisMappingsStore,
    capacityStore,
    influenceStore,
    influenceHistoryStore,
    systemSettingsStore,
    regionsStore,
    tabsStore
  } = useStores();
  const tab = tabsStore.tabsById[tabId];
  const { flightsTable, flightsCount, getRowInfluenceIds, xDayBuild } = tab;
  const { aggregations, data, influenceImpactGroupColumns, lastUpdated, pagination, status } = flightsTable;
  const { selectedRowsNumberOfFlights } = flightsCount;
  const { computedDateFormat, isMiles } = systemSettingsStore;

  const isLoading = status === Status.LOADING;
  const isColumnSelectDisabled = isLoading || influenceStore.isPreviewActive || capacityStore.isPreviewActive;
  const isInfluence = subpage === "influence";
  const isAggregationsDisabled = isLoading || isInfluence || capacityStore.isPreviewActive;

  const fixedColumns = {
    ...flightsTable.fixedColumns,
    influenceGroup: isInfluence ? "right" : flightsTable.fixedColumns.influenceGroup,
    influenceImpactGroup: "right",
    selection: "left"
  };

  const columnLabels = {
    ...extraMetrics,
    ...metricLabels({ isMiles, withUnits: true, xDayBuild }),
    departureDayOfWeek: "Dep DOW",
    depDate: "Dep Date",
    depDow: "Dep DOW",
    depMonth: "Dep Month",
    depTime: "Dep Time",
    depTimeBucket: "Dep Time Bucket",
    depWeek: "Dep Week",
    destination: "Dest",
    distance: "Dist",
    flightCount: "Flt Count",
    flightNumber: "Flight Num",
    influenceHistoryLink: null,
    lastModifiedInfluenceAnalystId: "Analyst Lst Mdf",
    lastModifiedInfluenceDate: "Date Lst Mdf",
    ndo: "NDO",
    numberOfFlights: "Flt Count"
  };

  const columnDescriptions = metricLabels({
    isMiles,
    longVersion: true,
    xDayBuild
  });

  const filterLabels = {
    ...extraMetrics,
    ...metricLabels({ isMiles, withParent: true, withUnits: true, xDayBuild }),
    depTime: "Departure Time"
  };

  const columns = useMemo(() => {
    return mergeContextualColumns(flightsTable.columns, influenceImpactGroupColumns);
  }, [influenceImpactGroupColumns, flightsTable.columns]);

  const sortedColumns = useMemo(() => {
    return columns.slice().sort(([columnIdA], [columnIdB]) => {
      if (fixedColumns[columnIdA] === "left") {
        if (fixedColumns[columnIdB] === "left") {
          return 0;
        }
        return -1;
      }
      return 1;
    });
  }, [JSON.stringify(fixedColumns), columns]);

  const getRowIdCallback = (row: Row) =>
    aggregations
      .filter(aggregationKey => aggregationKey !== "numberOfFlights")
      .map(aggregation => row[aggregation])
      .join("_");

  const columnTextWidth = useMemo(() => columnTextWidths(columnLabels), [columnLabels]);

  const onColumnFilter = columnId => {
    tab.setSidebarOpen(true);
    tab.setSidebarFilterQuery(filterLabels[columnId], columnId);
  };

  const onSortedChange = currentSort => {
    pagination.pageIndex = 0;

    if (currentSort) {
      tab.flightsTable.sortBy = {
        direction: currentSort[0].desc ? "desc" : "asc",
        field: currentSort[0].id
      };
    }
  };

  const normalizedData = data.map((row: FlightEntry) => {
    return {
      ...row,
      analyst: getName(row.analystId, analysisMappingsStore.analystGroupedByUserId, "label"),
      lastModifiedInfluenceAnalystId: getName(
        row.lastModifiedInfluenceAnalystId,
        influenceHistoryStore.creatorsGroupedByUserId,
        "label"
      ),
      region: getName(row.regionId, regionsStore.regionsById),
      subregion: getName(row.subregionId, regionsStore.subregionsById)
    };
  });

  const onColumnHide = (columnId, parentId) => {
    if (parentId === "aggregations") {
      tab.hideAggregation(columnId);
      tab.refetchFlightsTableData();
      tab.updateSelectedRows([], []);
    } else {
      tab.hideColumn(columnId);
    }
  };

  const headerMenuLabels = {
    hideColumn: parentId => (parentId === "aggregations" ? "Remove Aggregation" : "Hide column")
  };

  const insertColumn = (columns, findGroupName: string, columnToAdd: string) => {
    const group = columns.find(col => col[0] === findGroupName);
    if (!group) return columns;

    const [groupName, groupColumns] = group;
    const groupIndex = columns.indexOf(group);
    if (isEmpty(groupColumns)) return columns;

    const newGroup = [groupName, [columnToAdd, ...groupColumns.filter(name => name !== columnToAdd)]];

    return [...columns.slice(0, groupIndex), newGroup, ...columns.slice(groupIndex + 1, columns.length)];
  };

  const generateColumns = () => {
    const aggregationsGroup = ["aggregations", [...aggregations, "numberOfFlights"]];
    const columns = insertColumn(sortedColumns, "influenceGroup", "influenceHistoryLink");

    return [aggregationsGroup, ...columns];
  };

  const lastUpdatedText = lastUpdated ? formatLastUpdatedText(lastUpdated, computedDateFormat) : "";

  const subtitle = !isLoading ? [lastUpdatedText].filter(Boolean).join(" · ") : null;
  const extraSubContent = (
    <NumberOfFlights
      flightsCount={tab.flightsCount}
      flightsTable={tab.flightsTable}
      selectedRowsNumberOfFlights={tab.selectedRowsNumberOfFlights}
    />
  );

  const cellRenderers = {
    ...metricsTypes,
    influenceHistoryLink: props => (
      <InfluenceHistoryLinkCell getRowInfluenceIds={getRowInfluenceIds} subpage={subpage} {...props} />
    ),
    lastModifiedInfluenceDate: props => <DateTimeCell value={props.value} />
  };
  const isPreviewActive = influenceStore.isPreviewActive || capacityStore.isPreviewActive;

  return (
    <div className="d-flex flex-column flex-shrink-1 mh-0 flex-grow-1 h-100">
      <Module
        childrenClassName="d-flex flex-column h-100"
        className="flex-grow-1 mb-2"
        minHeight={data.length > 7 ? 315 : 0}
        subtitle={[extraSubContent, subtitle && `· ${subtitle}`]}
        title="Flights"
        titleTools={
          <FlightsTableTools
            aggregationsDisabled={isAggregationsDisabled}
            baseMetrics={baseMetrics}
            columnLabels={columnLabels}
            columnSelectDisabled={isColumnSelectDisabled}
            tab={tab}
          />
        }
        {...moduleProps}
      >
        <div className="d-flex flex-column h-100" data-testid="flights-table">
          <DataTable
            {...tab.flightsTable}
            aggregationsDisabled={isAggregationsDisabled}
            baseMetrics={baseMetrics}
            cellRenderers={cellRenderers}
            columnAccessors={{
              analystId: "analyst",
              regionId: "region",
              subregionId: "subregion"
            }}
            columnConfig={columnConfig}
            columnDescriptions={columnDescriptions}
            columnLabels={columnLabels}
            columns={generateColumns()}
            columnTextWidth={columnTextWidth}
            columnWidths={columnWidths}
            data={normalizedData}
            fetchData={tab.refetchFlightsTableData}
            filters={tab.applied}
            fixedColumns={fixedColumns}
            getRowIdCallback={getRowIdCallback}
            headerMenuLabels={headerMenuLabels}
            initialFilters={FILTERS_INIT}
            isAdjustable
            isPreviewActive={isPreviewActive}
            nonFilterableColumns={["influenceHistoryLink", "numberOfFlights", "numberOfImpactedFlights"]}
            nonHideableColumns={["influenceHistoryLink", "numberOfFlights", "numberOfImpactedFlights", "aggregations"]}
            onColumnFilter={onColumnFilter}
            onColumnHide={onColumnHide}
            onRowToggle={tab.updateSelectedRows}
            onShiftToggle={tab.shiftToggleRows}
            onSortedChange={onSortedChange}
            selectedRowsNumberOfFlights={selectedRowsNumberOfFlights}
            sortingBy={SortingBy.Backend}
            sortingEnabled={!isPreviewActive}
            title="Flights"
            toggleFixedColumn={tab.toggleFixedColumn}
          />
        </div>
      </Module>
    </div>
  );
}

export default observer(FlightsTable);
