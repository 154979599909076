export const changeUserSortOrder = (users: { value: number }[], currentUserId?: number) => {
  const currentUserIndex = (currentUserId && users.findIndex(({ value }) => value === currentUserId)) || -1;

  if (currentUserIndex !== -1) {
    const newSortedUsers = [...users];
    newSortedUsers.splice(currentUserIndex, 1);

    return [users[currentUserIndex], ...newSortedUsers];
  }

  return users;
};
