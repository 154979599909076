import React from "react";
import pluralize from "pluralize";
import TagsListItem from "../TagsListItem/TagsListItem";
import { observer } from "mobx-react";

type Props = {
  disabled?: boolean;
  tags: [string, any][];
};

function OverflownTagList(props: Props) {
  const { disabled = false, tags } = props;

  const filterText = pluralize("more filter", tags.length, true);

  return (
    <div>
      <p>{filterText}</p>
      {tags.map(tag => {
        const [name, value] = tag;
        return <TagsListItem key={name} disabled={disabled} inline={false} name={name} value={value} />;
      })}
    </div>
  );
}

export default observer(OverflownTagList);
