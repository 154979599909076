import React from "react";
import { Spinner } from "@blueprintjs/core";
import kebabCase from "lodash.kebabcase";

import { previewInfluenceGroup } from "shared/metricLabels/constants";
import { metricsTypes } from "modules/App/constants";
import formatValueWithUnit from "shared/helpers/formatValueWithUnit/formatValueWithUnit";
import isNumber from "shared/helpers/isNumber/isNumber";

import { InfluenceAggregated, InfluencePreviewCounters } from "types/Influence.types";
import { InfluenceStatus } from "modules/Influence/Influence.model";
import { StyledBaseCell, StyledPreviewCell } from "shared/components/StyledAdjustmentCell/StyledAdjustmentTableCell";

type Props = {
  data: {
    influenceId: string;
    base: { aggregated: InfluenceAggregated };
    preview: { aggregated: InfluenceAggregated };
    previewCounters: InfluencePreviewCounters;
  };
  rows: { key: string }[];
  status: InfluenceStatus;
};

const spinner = <Spinner className="d-flex justify-content-end influence-control-table-spinner" size={18} />;

function InfluenceControlTable(props: Props) {
  const { data, status, rows = [] } = props;

  const renderValue = (aggregated: InfluenceAggregated, key: string) => {
    if (!aggregated || !isNumber(aggregated[key])) {
      return "-";
    }
    return formatValueWithUnit(aggregated[key], metricsTypes[key]);
  };

  const tableRows = rows
    .filter(row => row.key !== "numberOfImpactedFlights")
    .map(row => {
      const { key } = row;
      const label = previewInfluenceGroup[key];
      const testId = `influence-control-table-${kebabCase(label)}`;
      const { base, preview } = data;

      return (
        <tr key={key} data-testid={testId}>
          <td>{label}</td>
          <StyledBaseCell className="text-right" isStriked={status === InfluenceStatus.DONE_PREVIEW} width="110">
            {status === InfluenceStatus.LOADING_BASE ? spinner : renderValue(base?.aggregated, key)}
          </StyledBaseCell>
          <StyledPreviewCell className="text-right" width="110">
            {status === InfluenceStatus.LOADING_PREVIEW ? spinner : renderValue(preview?.aggregated, key)}
          </StyledPreviewCell>
        </tr>
      );
    });

  return (
    <table className="bp3-html-table bp3-html-table-condensed bp3-html-table-striped w-100">
      <tbody>{tableRows}</tbody>
    </table>
  );
}

export default InfluenceControlTable;
