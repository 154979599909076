// @flow

import React from "react";
import { observer } from "mobx-react";
import styled from "@emotion/styled";

import { dashStylesCSS } from "modules/App/constants";
import { getMetricsColor } from "modules/BuildCurves/getMetricsColor/metricsColors";

type Props = {
  className: string,
  group: string,
  index: number,
  size: number
};

const squareIcon = (fill, strokeType, size, className) => {
  const params = {
    className,
    fill,
    style: { height: `${size}px`, width: `${size}px` },
    viewBox: "0 0 12 12",
    xmlns: "http://www.w3.org/2000/svg"
  };

  if (strokeType === "dashed") {
    return (
      <svg {...params}>
        <path d="M12 7.5V11c0 .6-.4 1-1 1H7.5v-1.5h3v-3H12zm-10.5 0v3h3V12H1a1 1 0 01-1-1V7.5h1.5zM11 0c.6 0 1 .4 1 1v3.5h-1.5v-3h-3V0H11zM0 1c0-.6.4-1 1-1h3.5v1.5h-3v3H0V1z" />
      </svg>
    );
  }

  if (strokeType === "dashdotted") {
    return (
      <svg {...params} fill="none">
        <path
          clipRule="evenodd"
          d="M1.5 3H0V1.5H1.5V3ZM12 3H10.5V1.5H12V3ZM1.5 10.5H0V9H1.5V10.5ZM12 10.5H10.5V9H12V10.5ZM3 10.5V12H1.5V10.5H3ZM10.5 10.5V12H9V10.5H10.5ZM3 0V1.5H1.5V0H3ZM10.5 0V1.5H9V0H10.5ZM1.5 1.5V10.5H10.5V1.5H1.5ZM1 0C0.447715 0 0 0.447715 0 1V11C0 11.5523 0.447715 12 1 12H11C11.5523 12 12 11.5523 12 11V1C12 0.447715 11.5523 0 11 0H1Z"
          fill={fill}
          fillRule="evenodd"
        />
      </svg>
    );
  }

  return (
    <svg {...params}>
      <path d="M8.5 10.5V12H7v-1.5zM12 10.5v.5c0 .6-.4 1-1 1h-.5v-1.5H12zM5 10.5V12H3.5v-1.5zM1.5 10.5V12H1a1 1 0 01-1-1v-.5h1.5zM12 7v1.5h-1.5V7zM1.5 7v1.5H0V7zM12 3.5V5h-1.5V3.5zM1.5 3.5V5H0V3.5zM5 0v1.5H3.5V0zM8.5 0v1.5H7V0zM0 1c0-.6.4-1 1-1h.5v1.5H0V1zM12 1.5h-1.5V0h.5c.6 0 1 .4 1 1v.5z" />
    </svg>
  );
};

const SeriesLegendStyled = styled("div")`
  background: ${({ background }) => background};
  border-radius: 1px;
  display: inline-block;
  height: ${({ size }) => `${size}px`};
  width: ${({ size }) => `${size}px`};
`;

function SeriesLegend(props: Props) {
  const { className, group, index, size = 8 } = props;

  const color = getMetricsColor(group, index);
  const background = index ? "transparent" : color;

  if (dashStylesCSS[index] !== "solid") {
    return squareIcon(color, dashStylesCSS[index], size, className);
  }

  return <SeriesLegendStyled background={background} className={className} size={size} />;
}

export default observer(SeriesLegend);
