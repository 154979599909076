import { Button, Colors } from "@blueprintjs/core";
import styled from "@emotion/styled";
import { format } from "date-fns";
import pluralize from "pluralize";
import React, { useEffect, useMemo } from "react";
import { observer } from "mobx-react";

import columnTextWidths from "shared/helpers/columnTextWidths/columnTextWidths";
import { cellRenderers } from "modules/InfluenceHistory/influenceHistoryCellRenderers/influenceHistoryCellsRenders";
import { Status } from "modules/App/Status";
import { useStores } from "store/Store";
import DataTable from "shared/components/DataTable/DataTable";
import Module from "shared/components/Module/Module";
import EditInfluenceButton from "../EditInfluenceButton/EditInfluenceButton";
import InfluenceHistoryRowExpander from "../InfluenceHistoryRowExpander/InfluenceHistoryRowExpander";

export const columnLabels = {
  analystName: "Analyst",
  comment: "Comment",
  influence: "Influence",
  influenceId: "Influence ID",
  maxDepDate: "Max Dep Date",
  minDepDate: "Min Dep Date",
  numberOfFlights: "Flt Count",
  owMarkets: "OW Markets Applied",
  status: "Status",
  type: "Type",
  updatedOn: `Last Modified (${format(new Date(), "O")})`,
  value: "Value"
};

const columnSortType = {
  comment: null,
  owMarkets: null,
  type: null,
  value: null
};

const StyledModule = styled(Module)`
  tr.nested {
    color: ${Colors.GRAY2};
  }
`;

function InfluenceHistoryTable() {
  const { influenceHistoryStore, modalStore, systemSettingsStore } = useStores();
  const { computedDateFormat } = systemSettingsStore;
  const { page, sortable } = influenceHistoryStore;
  const { historyTable } = page;
  const { data: historyData, selectedRows } = historyTable;

  const isRowSelectionDisabled = historyData.every(({ status }) => status !== "active");

  const sortByConfig = { value: sortable ? "alphanumeric" : null };
  const isLoaded = historyTable.status === Status.DONE;
  const subtitle = isLoaded ? `${historyTable.pagination.totalRows} rows` : null;
  const disabledRows: string[] = [];

  const deleteInfluences = params => influenceHistoryStore.deleteInfluences(params);

  const actionButtons = (
    <>
      <EditInfluenceButton />
      <Button
        data-testid="delete-influence-button"
        disabled={!selectedRows.length}
        icon="trash"
        onClick={() => modalStore.setModal("deleteInfluence", { deleteInfluences, selectedRows })}
      >
        Delete {selectedRows.length || "selected"} {pluralize("Influence", selectedRows.length, false)}
      </Button>
    </>
  );

  useEffect(() => {
    if (historyData.length) {
      historyData.forEach(({ influenceId, status }) => {
        if (status !== "active") {
          disabledRows.push(influenceId);
        }
      });
    }
  }, [historyData]);

  const columnTextWidth = useMemo(() => columnTextWidths(columnLabels), []);

  const fetchData = (params, options) => {
    influenceHistoryStore.getInfluenceHistoryData(params);
    if (options?.saveOptions) {
      influenceHistoryStore.saveView();
    }
  };

  return (
    <StyledModule
      childrenClassName="d-flex flex-column h-100"
      className="flex-shrink-1 mb-2"
      minHeight={historyData.length > 7 ? 315 : 0}
      subtitle={[subtitle && `· ${subtitle}`]}
      title="Influence History"
      titleTools={isLoaded && actionButtons}
    >
      <DataTable
        {...page.historyTable}
        cellRenderers={cellRenderers(computedDateFormat)}
        columnAccessors={{ value: "valueText" }}
        columnConfig={{ columnsAligned: { value: "right" } }}
        columnLabels={columnLabels}
        columnSortType={{ ...columnSortType, ...sortByConfig }}
        columnTextWidth={columnTextWidth}
        columnWidths={{ status: 65 }}
        computedDateFormat={computedDateFormat}
        deleteInfluences={deleteInfluences}
        disabledRows={disabledRows}
        expandableRows
        expanderRenderer={row => <InfluenceHistoryRowExpander row={row} />}
        fetchData={fetchData}
        isPreviewActive={isRowSelectionDisabled}
        onRowToggle={(selectedRows: string[]) => influenceHistoryStore.updateSelectedRows(selectedRows)}
        onShiftToggle={influenceHistoryStore.shiftToggleRows}
        rowIdAccessor="influenceId"
        setModal={modalStore.setModal}
        sortByConfig={sortByConfig}
      />
    </StyledModule>
  );
}

export default observer(InfluenceHistoryTable);
