import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react";
import { Intent } from "@blueprintjs/core";
import { AppToaster } from "services/Toaster";

import AnalysisContent from "./AnalysisContent";

import { useStores } from "store/Store";

function EditAnalysis() {
  const history = useHistory();
  const { tabsStore } = useStores();

  const tab = tabsStore.tabsById[history.location.state];

  useEffect(() => {
    if (tab) {
      AppToaster.show({
        intent: Intent.PRIMARY,
        message: "You’re in Analysis Edit mode. Navigation is temporary disabled"
      });
    } else {
      history.push("/");
    }
  }, [tab]);

  return <AnalysisContent tabId={tab?.id} />;
}

export default observer(EditAnalysis);
