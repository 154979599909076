import "core-js/stable"; // polyfills
import { configure as configureMobx } from "mobx";

import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import * as Integrations from "@sentry/integrations";
import { IntercomProvider } from "react-use-intercom";
import GoogleAnalytics from "ga-4-react";
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
import { FocusStyleManager } from "@blueprintjs/core";
import getEnv from "@beam-australia/react-env";

import { env, GTAG_ID, INTERCOM_APP_ID, LOGROCKET_ID, partner, SENTRY_DSN, SENTRY_RELEASE } from "config/config";
import isEnv from "shared/helpers/isEnv/isEnv";
import { isCypress, isLocalhost } from "modules/App/constants";
import AuthGate from "modules/App/AuthGate/AuthGate";
import { ApplicationConfig } from "config/config.types";
import { ApplicationConfigProvider } from "config/ApplicationConfigContext";
import { Mode } from "modules/App/App.types";

import "bootstrap-4-grid/css/grid.css";
import "normalize.css/normalize.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";

configureMobx({ enforceActions: "never" });

function setupSentry() {
  const dsn = SENTRY_DSN[env];

  if (!dsn || dsn.includes("__")) {
    console.info("Sentry is not initialized"); // eslint-disable-line no-console
    return;
  }

  Sentry.init({
    beforeBreadcrumb(breadcrumb, hint) {
      if (breadcrumb.category === "ui.input" || breadcrumb.category === "ui.click") {
        const { timestamp, category, message } = breadcrumb;
        const { target = {} } = hint?.event || {};

        const data: { [key: string]: any } = { selector: message };
        if (target.value) {
          data.value = target.value;
        }
        if (target.placeholder) {
          data.placeholder = target.placeholder;
        }
        if (target.innerText && !target.innerText.includes("\n")) {
          data.text = target.innerText;
        }
        if (hint?.event.key) {
          data.pressedKey = hint?.event.key;
        }
        if (hint?.event.path && hint.event.path.length) {
          const testPath = hint.event.path
            .map(el => el.dataset && el.dataset.testid)
            .filter(Boolean)
            .reverse()
            .join(" > ");
          if (testPath) {
            data.testPath = testPath;
          }
        }
        return { category, data, timestamp };
      }

      return breadcrumb;
    },
    denyUrls: [/cdn\.lr-ingest\.io/, /js\.intercomcdn\.com/],
    dsn,
    environment: partner.toUpperCase(),
    ignoreErrors: ["ResizeObserver loop limit exceeded"],
    integrations: [new Integrations.CaptureConsole({ levels: ["error"] })],
    maxBreadcrumbs: 25,
    release: SENTRY_RELEASE
  });
}

function setupLogRocket() {
  try {
    // https://docs.logrocket.com/docs/react-plugin
    LogRocket.init(LOGROCKET_ID);
    setupLogRocketReact(LogRocket);

    // https://docs.logrocket.com/docs/frontend-error-reproduction
    LogRocket.getSessionURL(sessionURL => {
      Sentry.configureScope(scope => {
        scope.setExtra("sessionURL", sessionURL);
      });
    });
  } catch {} // eslint-disable-line no-empty
}

function setupGoogleAnalytics() {
  const ga = new GoogleAnalytics(GTAG_ID[env]);
  try {
    ga.initialize();
  } catch {} // eslint-disable-line no-empty
}

const mode = getEnv("MODE") as string;

if (!mode || ![Mode.Network, Mode.P2P].includes(mode.toUpperCase() as Mode)) {
  throw new Error(`Invalid mode provided! Received: ${mode}`);
}

const configFile = mode === Mode.P2P ? "p2p.config" : "network.config";

import(`./config/${configFile}`)
  .then(module => module.default)
  .then((applicationConfig: ApplicationConfig) => {
    ReactDOM.render(
      <ApplicationConfigProvider value={applicationConfig}>
        {!isCypress && !isLocalhost && isEnv(["staging", "integration", "production"]) ? (
          <IntercomProvider appId={INTERCOM_APP_ID}>
            <AuthGate />
          </IntercomProvider>
        ) : (
          <AuthGate />
        )}
      </ApplicationConfigProvider>,
      document.getElementById("root")
    );
  });

FocusStyleManager.onlyShowFocusOnTabs();

if (!isLocalhost) {
  if (isEnv(["staging", "integration", "production"])) {
    setupSentry();
  }

  if (isEnv(["integration", "production"])) {
    setupGoogleAnalytics();
  }

  if (!isCypress) {
    setupLogRocket();
  }
}
