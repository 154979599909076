import React from "react";
import { observer } from "mobx-react";
import isEmpty from "lodash.isempty";

import SidebarGroup from "../SidebarGroup/SidebarGroup";
import metricLabels from "../../../metricLabels/metricLabels";
import { extraMetrics } from "modules/App/constants";
import { FiltersHelpTexts } from "modules/Explore/filtersHelpTexts";
import ConditionalFiltersGroup from "modules/Analysis/AnalysisSidebarFilterGroups/ConditionalFiltersGroup";

import type { AnalysisMappingsStore } from "models/AnalysisMappings.model";
import type { RegionsStore } from "models/Regions.model";
import type { Tab } from "models/Tab/Tab.model";
import type { Metric } from "modules/Explore/metrics/metrics";
import FilterCounters from "../FilterCounters/FilterCounters";

type Props = {
  analystList?: string[];
  computedDateFormat?: string;
  computedDateMonthFormat?: string;
  filterGroups: Metric[];
  filterQuery?: string;
  mappings?: AnalysisMappingsStore;
  pageContext: Tab;
  regions?: RegionsStore;
  isMiles: boolean;
  withoutCollapsing?: boolean;
};

const forcedGroupsWithHelp = ["competitorFareGroup"];

function FilterGroups(props: Props) {
  const { filterGroups, isMiles, pageContext, withoutCollapsing } = props;
  const { id, sidebar, xDayBuild = 7 } = pageContext;
  const { filterQuery } = sidebar;

  const titleLabels = {
    ...extraMetrics,
    ...metricLabels({ isMiles, withUnits: true, xDayBuild })
  };
  const filterLabels = {
    ...extraMetrics,
    ...metricLabels({ isMiles, shortVersion: true, withParent: true, withUnits: true, xDayBuild })
  };

  const isFilterQuery = !isEmpty(filterQuery);
  const columnHelpUrls = FiltersHelpTexts(isMiles);

  const renderHelpText = (type: string) => {
    if (!columnHelpUrls[type]) {
      return null;
    }

    return (
      <a
        className="mb-3 d-inline-block"
        href={columnHelpUrls[type].url}
        rel="noopener noreferrer"
        target="_blank"
        title={columnHelpUrls.shortText}
      >
        {columnHelpUrls[type].text}
      </a>
    );
  };

  const filterGroupsFilteredBySearch = filterGroups.filter(({ filters, key, name }: Metric) => {
    const groupTitle = titleLabels[key] || name;

    const filterNames = filters
      .map(({ key }) => `${groupTitle} ${titleLabels[key]} ${filterLabels[key]} ${key}`)
      .join(" ")
      .toLowerCase();

    if (sidebar && sidebar.filterKey) {
      return filters.map(({ key }) => key).includes(sidebar.filterKey);
    }

    return filterQuery ? filterNames.includes(filterQuery.toLowerCase()) : true;
  });

  const renderGroup = ({ Component, filters, key, name }: Metric) => {
    const isConditionalFilterGroup = !Component;
    const shouldRenderHelp = forcedGroupsWithHelp.includes(key);

    const filtersComponentProps = {
      ...props,
      filters: filters.filter(filter => !filter.excludeInFilters),
      pageContext,
      tabId: id,
      xDayBuild
    };

    return (
      <SidebarGroup
        key={key}
        forceShown={isFilterQuery}
        title={titleLabels[key] || name}
        titleRight={<FilterCounters groupKey={key} pageContext={pageContext} />}
        withoutCollapsing={withoutCollapsing}
      >
        {(isConditionalFilterGroup || shouldRenderHelp) && renderHelpText(key)}
        {Component && <Component {...filtersComponentProps} />}
        {isConditionalFilterGroup && (
          <ConditionalFiltersGroup {...filtersComponentProps} labels={{ filterLabels, titleLabels }} />
        )}
      </SidebarGroup>
    );
  };

  return <>{filterGroupsFilteredBySearch.map(renderGroup)}</>;
}

export default observer(FilterGroups);
