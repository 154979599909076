import { getTime, parseISO } from "date-fns";
import isEmpty from "lodash.isempty";
import pickBy from "lodash.pickby";

import isNumber from "shared/helpers/isNumber/isNumber";

export const mapToTime = (value: string) => getTime(parseISO(value));

export default function parseGraphSeries(data: any, isNdo: boolean) {
  // eslint-disable-next-line
  const { kpisTimestamp, maxLacRank, ndo, date, today, todayNdo, ...series } = data;
  const dates = isNdo ? ndo : date;
  const validSeries = pickBy(series, value => Array.isArray(value) && value.length === dates.length);

  if (dates && !isEmpty(dates)) {
    const dateTime = isNdo ? dates : dates.map(mapToTime);

    const seriesNames = Object.keys(validSeries);
    const adjustedSeries = {};
    const mapSeriesValue = (value: number, index: number) => [dateTime[index], value];

    seriesNames.forEach((seriesName: string) => {
      const seriesValues = series[seriesName];
      if (seriesValues.some(isNumber)) {
        adjustedSeries[seriesName] = seriesValues.map(mapSeriesValue);
      }
    });

    return {
      ...adjustedSeries,
      maxLacRank,
      ...(isNdo ? { ndo } : { date: dateTime }),
      ...(today && { today: parseISO(today) }),
      ...(isNumber(todayNdo) && { todayNdo })
    };
  }

  return {
    ...series,
    maxLacRank,
    ...(isNdo ? { ndo } : { date }),
    ...(today && { today: parseISO(today) }),
    ...(isNumber(todayNdo) && { todayNdo })
  };
}
