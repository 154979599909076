import { format, isValid, parseISO } from "date-fns";

import { functionNames } from "modules/Explore/ConditionalFilterFuctionNames";
import formatValueWithUnit from "../formatValueWithUnit/formatValueWithUnit";
import { metricsTypes } from "modules/App/constants";

export function filterTitleFromArray(value, name, conditionFilterCallback) {
  const isConditionalFilter = typeof value === "object" && value !== null;
  const valueFunction = isConditionalFilter ? `${functionNames[value?.func]} ` : "";
  const filterType = name && metricsTypes[name];
  const tagValue = isConditionalFilter ? value?.value : conditionFilterCallback;
  return `${valueFunction}${formatValueWithUnit(tagValue, filterType)}`;
}

export default function filterTitleValue(value, name, additionalTagLabels, computedDateFormat = "yyyy-MM-dd") {
  const type = typeof value;

  if (type === "string" || type === "number") {
    return value;
  }

  if (type === "boolean") {
    return value ? "Yes" : "No";
  }

  if (Array.isArray(value)) {
    const newValues = additionalTagLabels ? value.map(val => additionalTagLabels[val] || val) : value;
    return filterTitleFromArray(value[0], name, newValues.join(", "));
  }

  if (value && (value.start != null || value.end != null)) {
    const isValidDate = isValid(parseISO(value.start)) || isValid(parseISO(value.end));
    if (isValidDate) {
      return `${format(parseISO(value.start), computedDateFormat)} – ${format(
        parseISO(value.end),
        computedDateFormat
      )}`;
    }
    return `${value.start} – ${value.end}`;
  }
}
