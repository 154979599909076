import React from "react";
import { observer } from "mobx-react";

import TagsList from "shared/components/TagsList/TagsList";
import { daysOfWeek, extraMetrics } from "modules/App/constants";
import { FILTERS_INIT } from "models/Cirrus/Cirrus.utils";
import { CirrusDefaultMarketStore } from "models/Cirrus/CirrusDefaultMarket.model";
import { CirrusODDatesStore } from "models/Cirrus/CirrusODDates.model";
import { cirrusODDatesMetrics } from "./cirrusODDatesMetrics";

type Props = {
  cirrusStoreContext: CirrusDefaultMarketStore | CirrusODDatesStore;
};

function CirrusTagList({ cirrusStoreContext }: Props) {
  const { page, removeFilterValue } = cirrusStoreContext;

  const filterOrder = cirrusODDatesMetrics.flatMap(({ filters }) => filters.map(({ key }) => key));

  const onFilterValueClick = (filterId: string) => {
    cirrusStoreContext.setSidebarOpen(true);
    cirrusStoreContext.setSidebarFilterQuery(extraMetrics[filterId], filterId);
  };

  return (
    <TagsList
      disabled={!page.filtersEnabled}
      disabledFilters={page.disabledFilters}
      filterOrder={filterOrder}
      initialFilters={FILTERS_INIT}
      onFilterRemove={cirrusStoreContext.resetSearchParam}
      onFilterToggle={cirrusStoreContext.toggleFilter}
      onFilterValueClick={onFilterValueClick}
      onFilterValueToggle={cirrusStoreContext.toggleFilterValue}
      onValueRemove={removeFilterValue}
      params={page.applied.filters}
      valueRenderers={{
        cirrusStatus: value => (value ? "On" : "Off"),
        depDow: value => daysOfWeek[value]?.short
      }}
    />
  );
}

export default observer(CirrusTagList);
