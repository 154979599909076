import React, { useMemo } from "react";
import { observer } from "mobx-react";
import pluralize from "pluralize";
import styled from "@emotion/styled";

import columnTextWidths from "shared/helpers/columnTextWidths/columnTextWidths";
import { useStores } from "store/Store";
import Module from "shared/components/Module/Module";
import DataTable from "shared/components/DataTable/DataTable";
import StatusIndicator from "shared/components/StatusIndicator/StatusIndicator";
import CirrusOnOffButtons from "./CirrusOnOffButtons";
import { Status } from "modules/App/Status";

const StyledModule = styled(Module)`
  max-width: 800px;
`;

type CellProps = {
  value: string;
};

function CirrusDefaultMarketTable() {
  const { cirrusDefaultMarketStore, systemSettingsStore } = useStores();
  const { changeSelectedRows, fetchData, page, shiftToggleRows } = cirrusDefaultMarketStore;
  const { table } = page;
  const { computedDateFormat } = systemSettingsStore;
  const { dataStats, pagination, status } = table;

  const columnLabels = {
    cabinClass: "Cabin Class",
    cirrusStatus: "On/Off",
    owMarket: "OW Market",
    status: "Status"
  };

  const cellRenderers = {
    cirrusStatus: ({ value }: CellProps) => <StatusIndicator isActive={value} />
  };

  const columnTextWidth = useMemo(() => columnTextWidths(columnLabels), [columnLabels]);

  const getRowIdCallback = row => {
    const status = row.cirrusStatus ? "on" : "off";
    return `${row.id}_${status}`;
  };

  const isLoaded = status === Status.DONE;
  const subtitle = `${dataStats.enabled} of ${pluralize("OW Market", pagination.totalRows, true)} turned on`;
  const titleTools = isLoaded && (
    <CirrusOnOffButtons cirrusStoreContext={cirrusDefaultMarketStore} noFiltersAllowed unit="OW Market" />
  );

  return (
    <StyledModule
      className="h-100"
      minHeight={0}
      subtitle={isLoaded ? [subtitle] : undefined}
      title="Cirrus On/Off"
      titleTools={titleTools}
    >
      <DataTable
        {...table}
        cellRenderers={cellRenderers}
        columnLabels={columnLabels}
        columnTextWidth={columnTextWidth}
        columnWidths={{ cirrusStatus: 30 }}
        computedDateFormat={computedDateFormat}
        fetchData={fetchData}
        getRowIdCallback={getRowIdCallback}
        onRowToggle={changeSelectedRows}
        onShiftToggle={shiftToggleRows}
      />
    </StyledModule>
  );
}

export default observer(CirrusDefaultMarketTable);
