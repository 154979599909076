import difference from "lodash.difference";

type ScopingFilter = {
  destination?: string[];
  origin?: string[];
  owMarket?: string[];
  regionId?: string[];
  rtMarket?: string[];
  subregionId?: string[];
};

const getGroupItemsForFilter = (scopingFilter: ScopingFilter, getterFunction: Function) => {
  const matchingItems = getterFunction(scopingFilter);
  const allItems = difference(getterFunction(null), matchingItems);
  return [allItems, matchingItems];
};

export default getGroupItemsForFilter;
