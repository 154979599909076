import React from "react";

type TagsListContextType = {
  additionalTagLabels?: object;
  computedDateFormat: string;
  computedDateMonthFormat: string;
  disabledFilters?: object;
  isDataLoading?: boolean;
  isMiles: boolean;
  onFilterRemove?: (name: string) => void;
  onFilterToggle?: (name: string) => void;
  onFilterValueClick?: (name: string) => void;
  onFilterValueToggle?: (filterKey: string, filterValue: string) => void;
  onValueRemove?: (name?: string, option?: string) => void;
  valueRenderers?: object;
  xDayBuild: number;
};
const TagsListContext = React.createContext<TagsListContextType>({
  computedDateFormat: "",
  computedDateMonthFormat: "",
  isMiles: false,
  xDayBuild: 7
});

export default TagsListContext;
