import { observer } from "mobx-react";
import React from "react";

import TagsList from "shared/components/TagsList/TagsList";
import { FILTERS_INIT } from "models/MarketMappings.model";
import { extraMetrics } from "modules/App/constants";
import { useStores } from "store/Store";
import { marketMappingsMetrics } from "./MarketMappingsSidebar";

function MarketMappingsTagsList() {
  const { marketMappingsStore, regionsStore, usersStore } = useStores();
  const { searchParams } = marketMappingsStore;
  const analystList = usersStore.analystList;
  const filterOrder = marketMappingsMetrics.flatMap(({ filters }) => filters.map(({ key }) => key));

  const onFilterValueClick = (filterId: string) => {
    marketMappingsStore.setSidebarOpen(true);
    marketMappingsStore.setSidebarFilterQuery(extraMetrics[filterId], filterId);
  };

  const getAnalystLabelById = analystId =>
    (analystList && analystList.find(analyst => analyst.value === analystId)?.label) || analystId;

  const valueRenderers = {
    analystId: value => getAnalystLabelById(value),
    regionId: value => regionsStore.regionsById[value],
    subregionId: value => regionsStore.subregionsById[value]
  };

  return (
    <TagsList
      additionalTagLabels={{ rtMarkets: "RT Market" }}
      disabled={!marketMappingsStore.filtersEnabled}
      disabledFilters={marketMappingsStore.disabledFilters}
      filterOrder={filterOrder}
      initialFilters={FILTERS_INIT}
      isDataLoading={marketMappingsStore.isLoading}
      onFilterRemove={marketMappingsStore.resetSearchParam}
      onFilterToggle={marketMappingsStore.toggleFilter}
      onFilterValueClick={onFilterValueClick}
      onFilterValueToggle={marketMappingsStore.toggleFilterValue}
      onValueRemove={marketMappingsStore.removeFilterValue}
      params={searchParams}
      valueRenderers={valueRenderers}
    />
  );
}

export default observer(MarketMappingsTagsList);
