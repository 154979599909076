import React from "react";
import { observer } from "mobx-react";

import SidebarSingleSelect from "shared/components/Sidebar/SidebarSingleSelect/SidebarSingleSelect";
import { SelectItem } from "types/MarketMappings.types";
import { useStores } from "store/Store";

type Props = {
  onChange: (item: SelectItem) => void;
  selectedAnalyst: SelectItem | null;
};

function MarketMappingsAnalystSelect({ onChange, selectedAnalyst }: Props) {
  const { usersStore } = useStores();
  const analystList = usersStore.analystList;

  return (
    <div className="d-flex">
      <SidebarSingleSelect
        items={analystList}
        selectedItem={selectedAnalyst}
        selectItem={item => onChange(item)}
        title="Analyst"
      />
    </div>
  );
}

export default observer(MarketMappingsAnalystSelect);
