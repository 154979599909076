import metricLabels from "shared/metricLabels/metricLabels";
import { extraMetrics } from "../App/constants";

export const FiltersHelpTexts = (isMiles?: boolean) => {
  const columnLabels = {
    ...extraMetrics,
    ...metricLabels({ isMiles, withUnits: true })
  };

  const textMaker = (type: string) => `What are ${type} metrics?`;

  return {
    bookingsGroup: {
      text: textMaker(columnLabels.bookingsGroup),
      url: "https://help.cirrus.ai/en/articles/4306359-booking-metrics"
    },
    capacityGroup: {
      text: textMaker(columnLabels.capacityGroup),
      url: "https://help.cirrus.ai/en/articles/4284640-capacity-metrics"
    },
    competitorFareGroup: {
      text: textMaker(columnLabels.competitorFareGroup),
      url: "https://help.cirrus.ai/en/articles/4284643-competitor-fare-metrics"
    },
    finalLoadFactorGroup: {
      text: textMaker(columnLabels.finalLoadFactorGroup),
      url: "https://help.cirrus.ai/en/articles/4306360-load-factor-metrics"
    },
    finalRaskGroup: {
      text: textMaker(columnLabels.finalRaskGroup),
      url: "https://help.cirrus.ai/en/articles/4284614-revenue-metrics"
    },
    finalRevenueGroup: {
      text: textMaker(columnLabels.finalRevenueGroup),
      url: "https://help.cirrus.ai/en/articles/4284614-revenue-metrics"
    },
    finalRevenuePerBookingGroup: {
      text: textMaker(columnLabels.finalRevenuePerBookingGroup),
      url: "https://help.cirrus.ai/en/articles/4657898-revenue-per-booking-metrics"
    },
    finalYieldGroup: {
      text: textMaker(columnLabels.finalYieldGroup),
      url: "https://help.cirrus.ai/en/articles/4284641-yield-metrics"
    },
    flight: {
      text: textMaker(columnLabels.flightGroup),
      url: "https://help.cirrus.ai/en/articles/4529279-flight-metrics"
    },
    influenceGroup: {
      text: textMaker(columnLabels.influenceGroup),
      url: "https://help.cirrus.ai/en/articles/4306363-analyst-metrics"
    },
    lacGroup: {
      text: textMaker(columnLabels.lacGroup),
      url: "https://help.cirrus.ai/en/articles/4306361-pricing-metrics"
    },
    lacSeatsAvailableGroup: {
      text: textMaker(columnLabels.lacSeatsAvailableGroup),
      url: "https://help.cirrus.ai/en/articles/4306361-pricing-metrics"
    },
    loadFactorGroup: {
      text: textMaker(columnLabels.loadFactorGroup),
      url: "https://help.cirrus.ai/en/articles/4306360-load-factor-metrics"
    },
    pricePercentileGroup: {
      text: textMaker(columnLabels.pricePercentileGroup),
      url: "https://help.cirrus.ai/en/articles/4306361-pricing-metrics"
    },
    raskGroup: {
      text: textMaker(columnLabels.raskGroup),
      url: "https://help.cirrus.ai/en/articles/4284614-revenue-metrics"
    },
    revenueGroup: {
      text: textMaker(columnLabels.revenueGroup),
      url: "https://help.cirrus.ai/en/articles/4284614-revenue-metrics"
    },
    revenuePerBookingGroup: {
      text: textMaker(columnLabels.revenuePerBookingGroup),
      url: "https://help.cirrus.ai/en/articles/4657898-revenue-per-booking-metrics"
    },
    sellingFareGroup: {
      text: textMaker(columnLabels.sellingFareGroup),
      url: "https://help.cirrus.ai/en/articles/4306361-pricing-metrics"
    },
    shortText: "Read more",
    yieldGroup: {
      text: textMaker(columnLabels.yieldGroup),
      url: "https://help.cirrus.ai/en/articles/4284641-yield-metrics"
    }
  };
};
