import { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { useStores } from "store/Store";
import { NavigationBlocker } from "../navigationBlocker/navigationBlocker";

export const useNavigationBlocker = (shouldBlock: boolean) => {
  const { tabsStore } = useStores();
  const history = useHistory();

  useEffect(() => {
    if (shouldBlock) {
      NavigationBlocker.unblock = history.block(location => {
        tabsStore.shouldConfirmLeavingTab(true, () => {
          history.push(location.pathname);
        });
        return false;
      });
    }
  }, [shouldBlock]);
};
