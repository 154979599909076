import React, { useContext } from "react";

import isEnv from "shared/helpers/isEnv/isEnv";
import { ApplicationConfig } from "./config.types";

export const ApplicationContext = React.createContext<ApplicationConfig>((null as unknown) as ApplicationConfig);

export const ApplicationConfigProvider = ApplicationContext.Provider;

export const useConfig = (): ApplicationConfig => {
  const applicationConfig = useContext(ApplicationContext);

  if (!applicationConfig) {
    if (isEnv(["test"])) {
      return require("./p2p.config").default; // to not mock at every test
    }
    throw new Error("Config is not initialized!");
  }
  return applicationConfig;
};
