// @flow
import React from "react";
import { observer } from "mobx-react";

import { useStores } from "store/Store";
import renderSelect from "modules/Events/renderSelect/renderSelect";

function EventsManagementLocationSelects() {
  const { eventsManagementStore } = useStores();
  const {
    airports,
    changeField,
    countries,
    isSuggestModalLoading,
    metros,
    obsoletePlaces,
    page: {
      modalEvent: { locations }
    },
    conflictedLocationsMetros,
    conflictedLocationsAirport
  } = eventsManagementStore;

  return (
    <div>
      {renderSelect({
        isDisabledGlobally: isSuggestModalLoading,
        items: countries,
        obsoletePlaces: obsoletePlaces.countries,
        onChange: value => changeField("countries", value, "locations"),
        parent: "locations",
        selectedItems: locations?.countries,
        type: "country",
        withIATACode: true
      })}
      {renderSelect({
        conflictList: conflictedLocationsMetros,
        isDisabledGlobally: isSuggestModalLoading,
        items: metros,
        obsoletePlaces: obsoletePlaces.metros,
        onChange: value => changeField("metros", value, "locations"),
        parent: "locations",
        selectedItems: locations?.metros,
        type: "metro",
        withIATACode: true
      })}
      {renderSelect({
        conflictList: conflictedLocationsAirport,
        isDisabledGlobally: isSuggestModalLoading,
        items: airports,
        obsoletePlaces: obsoletePlaces.airports,
        onChange: value => changeField("airports", value, "locations"),
        parent: "locations",
        selectedItems: locations?.airports,
        type: "airport"
      })}
    </div>
  );
}

export default observer(EventsManagementLocationSelects);
