// @flow

import React from "react";
import { Position, Tooltip } from "@blueprintjs/core";
import { observer } from "mobx-react";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import clsx from "clsx";

import UnsavedTabIndicator from "./UnsavedTabIndicator";
import { TabStruct } from "models/Tab/Tab.utils";

type Props = {
  tab: TabStruct,
  tooltipContent: Function
};

const StyledTabLink = styled(Link)`
  &:hover {
    text-decoration: none;

    .text {
      text-decoration: underline;
    }
  }
`;

function InactiveTabContent(props: Props) {
  const { tab, tooltipContent } = props;

  const tabLabelClassName = clsx("ml-2 text-truncate d-block", {
    "mr-2": tab.isEditMode
  });

  return (
    <Tooltip
      boundary="viewport"
      className="text-truncate"
      content={tooltipContent}
      fill
      hoverOpenDelay={1000}
      modifiers={{ arrow: false }}
      position={Position.BOTTOM_LEFT}
    >
      <StyledTabLink className="d-flex align-items-center" to={`/analysis/${tab.id}/explore`}>
        <UnsavedTabIndicator tab={tab} />
        <span className={tabLabelClassName}>{tab.label}</span>
      </StyledTabLink>
    </Tooltip>
  );
}

export default observer(InactiveTabContent);
