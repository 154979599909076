import React, { useState } from "react";
import { observer } from "mobx-react";
import isEmpty from "lodash.isempty";
import { Button, Classes, Colors, Dialog } from "@blueprintjs/core";
import styled from "@emotion/styled";

import MarketMappingsAnalystSelect from "./MarketMappingsAnalystSelect";
import MarketMappingsRegionSelect from "./MarketMappingsRegionSelect";
import MarketMappingsSubregionSelect from "./MarketMappingsSubregionSelect";
import { SelectItem } from "types/MarketMappings.types";
import { useStores } from "store/Store";

const StyledMarketMappingsModalBody = styled("div")`
  .bp3-input-group {
    width: 220px;
  }
`;
const StyledDialogFooter = styled("div")`
  box-shadow: 0 -1px 0 ${Colors.BLACK}26;
`;

function MarketMappingsModal() {
  const { marketMappingsStore, modalStore } = useStores();

  const [selectedAnalyst, setSelectedAnalyst] = useState<SelectItem | null>(null);
  const [selectedRegion, setSelectedRegion] = useState<SelectItem | null>(null);
  const [selectedSubregion, setSelectedSubregion] = useState<SelectItem | null>(null);

  const modalTitle = `Update Mappings (${marketMappingsStore.marketCount})`;

  const isRegionSelected = selectedRegion && selectedRegion?.value !== null;
  const isSubregionSelected = selectedSubregion && selectedSubregion.value !== null;

  const isSaveButtonDisabled =
    [selectedAnalyst, selectedRegion, selectedSubregion].every(isEmpty) ||
    (isSubregionSelected && !isRegionSelected) ||
    false;

  return (
    <Dialog
      className="p-0"
      isOpen={modalStore.modalActive === "market-mappings"}
      onClose={modalStore.closeModal}
      title={modalTitle}
    >
      <StyledMarketMappingsModalBody className={`${Classes.DIALOG_BODY} m-0 p-3`} data-testid="market-mappings-modal">
        <MarketMappingsAnalystSelect onChange={setSelectedAnalyst} selectedAnalyst={selectedAnalyst} />
        <MarketMappingsRegionSelect
          onChange={setSelectedRegion}
          selectedRegion={selectedRegion}
          selectedSubregion={selectedSubregion}
        />
        <MarketMappingsSubregionSelect
          onChange={setSelectedSubregion}
          selectedRegion={selectedRegion}
          selectedSubregion={selectedSubregion}
        />
      </StyledMarketMappingsModalBody>

      <StyledDialogFooter className={`${Classes.DIALOG_FOOTER} mx-0 px-3 py-2`}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button
            className="ml-auto px-3"
            data-testid="market-mapping-modal-save-btn"
            disabled={isSaveButtonDisabled}
            intent="primary"
            onClick={() => {
              marketMappingsStore.updateMarkets({
                analyst: selectedAnalyst || {},
                region: selectedRegion || {},
                subRegion: selectedSubregion || {}
              });
              modalStore.closeModal();
            }}
            text="Save"
          />
        </div>
      </StyledDialogFooter>
    </Dialog>
  );
}

export default observer(MarketMappingsModal);
