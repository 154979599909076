import React, { useEffect, useMemo, useRef, useState } from "react";
import { observer } from "mobx-react";
import { useDebouncedCallback } from "use-debounce";
import isEmpty from "lodash.isempty";

import GraphResetZoom from "shared/Graph/GraphResetZoom/GraphResetZoom";
import isNumber from "shared/helpers/isNumber/isNumber";
import metricLabels from "shared/metricLabels/metricLabels";
import {
  allMetricsGroupedByKey,
  buildCurvesMetrics as allMetrics,
  buildCurvesMetricsPositions,
  buildCurvesMetricsPositions as metricPositions
} from "../Explore/metrics/metrics";
import { Status } from "../App/Status";

import CabinClassSelect from "components/CabinClassSelect";
import ColumnSelect from "shared/components/ColumnSelect/ColumnSelect";
import Module from "shared/components/Module/Module";
import { BuildCurvesStyles, StyledOpacity } from "./BuildCurves.styles";
import { BuildCurvesTypes } from "./BuildCurves.types";

const GRAPH_EXTRA_SPACING = 5;

function BuildCurves(props: BuildCurvesTypes) {
  const {
    baseMetrics = [],
    cabinClass,
    cabinClasses = [],
    changeCabinClass,
    changeDateOption,
    changeGroupsStatus,
    changeSelectedRange,
    changeSeries,
    data,
    displayGraphMenu,
    fetchData,
    flightsTableSelectedRows,
    groupStatuses = [],
    isBuildCurvesZoomed,
    isNdo = false,
    moduleProps,
    range,
    selectedRange,
    series = [],
    status,
    subtitle = [],
    today,
    toggleGraphMenu,
    isMiles
  } = props;

  const isInitialized = useRef({ fetching: false, translucency: false });
  const [isTranslucent, setTranslucent] = useState(false);

  const trigger = JSON.stringify(flightsTableSelectedRows);
  const [triggerDebounced, setTriggerDebounced] = useState(trigger);
  const debounced = useDebouncedCallback(newTrigger => {
    setTriggerDebounced(newTrigger);
    setTranslucent(false);
  }, 1000);

  debounced.callback(trigger);
  // effect for opacity
  useEffect(() => {
    // on first change do nothing
    if (!isInitialized.current.translucency) {
      isInitialized.current.translucency = true;
      return;
    }

    setTranslucent(true);
  }, [trigger]);

  // effect for fetching data
  useEffect(() => {
    // on first change do nothing
    if (!isInitialized.current.fetching) {
      isInitialized.current.fetching = true;
      return;
    }

    if (fetchData) {
      fetchData();
    }
  }, [triggerDebounced]);

  // if selected cabin class is not on the list, select first one
  useEffect(() => {
    if (cabinClasses.length && !cabinClasses.includes(cabinClass[0])) {
      changeCabinClass(cabinClasses[0]);
    }
  }, [JSON.stringify(cabinClasses)]);

  const isLoading = status === Status.LOADING;
  const isDone = status === Status.DONE;
  const columnLabels = metricLabels({ isMiles, withUnits: true });
  const seriesSelectorLabels = { ...columnLabels };
  const tooltipLabels = metricLabels({ isMiles, withUnits: false });
  const allMetricsGrouped = useMemo(allMetricsGroupedByKey, []);

  // metrics from final groups are added to non-final groups so name needs prefix
  ["finalRaskExpected", "finalRevenueExpected"].forEach(finalKey => {
    columnLabels[finalKey] = `Final ${columnLabels[finalKey]}`;
    seriesSelectorLabels[finalKey] = `Final ${seriesSelectorLabels[finalKey]}`;
    tooltipLabels[finalKey] = `Final ${tooltipLabels[finalKey]}`;
  });

  // custom shorten labels for groups
  tooltipLabels.loadFactorGroup = "LF";
  tooltipLabels.revenueGroup = "Rev";

  ["finalLoadFactorExpected", "finalYieldExpected", "finalRevenuePerBookingExpected"].forEach(key => {
    columnLabels[key] = columnLabels[key].replace("Exp", "Final Exp");
    seriesSelectorLabels[key] = seriesSelectorLabels[key].replace("Exp", "Final Exp");
    tooltipLabels[key] = tooltipLabels[key].replace("Exp", "Final Exp");
  });

  columnLabels.revenuePerBookingGroup = "Rev/Bkg";
  tooltipLabels.revenuePerBookingGroup = "Rev/Bkg";

  columnLabels.competitorFareGroup = "Comp Fare";
  tooltipLabels.competitorFareGroup = "Comp Fare";

  const isSelectedRange = isNumber(selectedRange?.start) && isNumber(selectedRange?.end);
  const dates = isNdo ? data.ndo : data.date;
  const isData = isDone && !isEmpty(dates);
  const isSingleDataRange = range && range?.start === range?.end;
  const isGraphTimelineVisible = isSelectedRange && isData && !isSingleDataRange;
  const selectedSeries = series.map(group => group[1]).flat();

  const chartSpacing = useMemo(() => {
    const hasOnlyRightSideSeries = selectedSeries.every(
      type => buildCurvesMetricsPositions[allMetricsGrouped[type]] === "right"
    );
    const spacingRight = hasOnlyRightSideSeries ? GRAPH_EXTRA_SPACING : 0;

    const hasOnlyLeftSideSeries = selectedSeries.every(
      type => buildCurvesMetricsPositions[allMetricsGrouped[type]] === "left"
    );
    const spacingLeft = hasOnlyLeftSideSeries ? GRAPH_EXTRA_SPACING : 0;

    return [0, spacingLeft, 0, spacingRight];
  }, [JSON.stringify(selectedSeries)]);

  const columnSelect = (
    <ColumnSelect
      allMetrics={allMetrics}
      baseMetrics={baseMetrics}
      buttonIcon="series-add"
      buttonLabel="Series"
      changeColumns={changeSeries}
      changeGroupsStatus={changeGroupsStatus}
      columnLabels={seriesSelectorLabels}
      columns={series}
      disabled={!isEmpty(selectedSeries) && isLoading}
      groupStatuses={groupStatuses}
      showLegend
    />
  );

  const cabinSelect = (
    <CabinClassSelect
      cabinClasses={cabinClasses}
      changeCabinClass={changeCabinClass}
      className="mx-2"
      disabled={isLoading}
      selectedCabinClass={cabinClass}
    />
  );

  const graphProps = {
    ...props,
    allMetrics,
    changeDateOption,
    changeSelectedRange,
    chartSpacing,
    columnLabels,
    customYAxis: {
      lacGroup: {
        allowDecimals: false,
        labels: {
          formatter: ({ isFirst, value }) => {
            // display every second label, even or odd based on max lac rank
            return !isFirst && value % 2 === data.maxLacRank % 2 ? value : null;
          }
        },
        reversed: true,
        softMax: data.maxLacRank,
        tickAmount: data.maxLacRank ? data.maxLacRank + 1 : undefined,
        tickInterval: data.maxLacRank ? 1 : undefined
      }
    },
    displayGraphMenu,
    isMiles,
    isNdo,
    maxGrouped: [{ sources: ["competitorFareGroup", "sellingFareGroup"], target: "revenuePerBookingGroup" }],
    mergedGroups: [
      ["pricePercentileGroup", "loadFactorGroup"],
      ["competitorFareGroup", "sellingFareGroup"],
      ["yieldGroup", "raskGroup"]
    ],
    metricPositions,
    percentGroup: ["pricePercentileGroup", "loadFactorGroup"],
    today,
    toggleGraphMenu,
    tooltipLabels
  };

  return (
    <Module
      className="h-100"
      minHeight={isLoading || isGraphTimelineVisible ? 385 : 365}
      subtitle={subtitle}
      title="Build Curves"
      titleTools={
        <div className="d-flex">
          <GraphResetZoom
            changeSelectedRange={changeSelectedRange}
            isBuildCurvesZoomed={isBuildCurvesZoomed}
            isGraphTimelineVisible={isGraphTimelineVisible}
            isLoading={isLoading}
            range={range}
          />
          {cabinSelect}
          {columnSelect}
        </div>
      }
      {...moduleProps}
    >
      <StyledOpacity isTranslucent={isTranslucent}>
        <div className="w-100 position-relative">
          <BuildCurvesStyles {...graphProps} />
        </div>
      </StyledOpacity>
    </Module>
  );
}

export default observer(BuildCurves);
