import { Colors, IconName, NonIdealState } from "@blueprintjs/core";
import styled from "@emotion/styled";
import clsx from "clsx";
import React from "react";

const Overlay = styled("div")`
  border-radius: 4px;
  border: 1px solid ${Colors.LIGHT_GRAY2};
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  transition: background-color 0.3s, opacity 0.3s, max-height 0s 0.3s;
  position: absolute;
  z-index: 6;
  background: white;
  width: calc(100% - 8px);
  left: 4px;

  &.isSibling {
    width: calc(100% - 4px);
  }

  &.isResizing {
    opacity: 1;
    max-height: 100%;
    transition: opacity 0.3s;

    & + * {
      overflow: hidden;
    }
  }

  &.no-data {
    padding: 32px 32px 64px;
    position: static;
    margin-bottom: -32px;
  }
`;

type Props = {
  icon: IconName;
  isSibling?: boolean;
  isResizing?: boolean;
};

function ResizableOverlay({ icon, isSibling, isResizing }: Props) {
  const dynamicClassNames = clsx({ isResizing, isSibling });

  return (
    <Overlay className={`h-100 d-flex justify-content-center align-content-center ${dynamicClassNames}`}>
      {isResizing && <NonIdealState className="text-center" icon={icon} />}
    </Overlay>
  );
}

export default ResizableOverlay;
