import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";

import Row from "shared/components/Row/Row";
import { useStores } from "store/Store";
import DateFormatControl from "./DateFormatControl";
import DateDelimiterControl from "./DateDelimiterControl";
import { SystemSettingsDescription, SystemSettingsSectionHeader } from "./SystemSettingsUI";

type Props = {
  dateDelimiter: string,
  dateFormat: string,
  onChange(): void
};

function SystemSettingsDate(props: Props) {
  const { onChange, dateFormat, dateDelimiter } = props;
  const { systemSettingsStore } = useStores();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    systemSettingsStore
      .getDateFormat()
      .then(response => {
        props.onChange({ dateDelimiter: response.dateDelimiter, dateFormat: response.dateFormat });
      })
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <>
      <SystemSettingsSectionHeader className="mt-4">Date Format</SystemSettingsSectionHeader>
      <Row>
        <div className="col-6">
          <SystemSettingsDescription className="m-0">
            Date format will be applied to all dates shown on all views including filters and data export.
          </SystemSettingsDescription>
        </div>
        <div className="col-6 d-flex-column justify-content-end align-items-start">
          <DateFormatControl dateFormat={dateFormat} isLoading={isLoading} onChange={onChange} />
          <DateDelimiterControl dateDelimiter={dateDelimiter} isLoading={isLoading} onChange={onChange} />
        </div>
      </Row>
    </>
  );
}

export default observer(SystemSettingsDate);
