import { Classes, Popover, Position } from "@blueprintjs/core";
import styled from "@emotion/styled";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { Pagination } from "types/DataTable.types";
import PageSelectorPopoverContent from "./PageSelectorPopoverContent";
import { StyledPageSelectorButton } from "./StyledPageSelectorButton";

const StyledGotoPageButton = styled(StyledPageSelectorButton)`
  min-width: 130px;

  .${Classes.POPOVER_OPEN} & {
    .bp3-icon {
      transform: rotate(180deg);
    }
  }
`;

type Props = {
  pagination: Pagination;
  gotoPage: (pageSize: Pagination["pageSize"]) => void;
};

function DataTablePageSelector({ pagination, gotoPage }: Props) {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  return (
    <Popover
      captureDismiss
      content={
        <PageSelectorPopoverContent
          gotoPage={gotoPage}
          pageCount={pagination.pageCount}
          setIsPopoverOpen={setIsPopoverOpen}
        />
      }
      data-testid={"parent"}
      disabled={pagination.pageCount <= 1}
      isOpen={isPopoverOpen}
      minimal
      onInteraction={setIsPopoverOpen}
      position={Position.TOP}
    >
      <StyledGotoPageButton
        disabled={pagination.pageCount <= 1}
        minimal
        onClick={() => setIsPopoverOpen(true)}
        rightIcon="caret-down"
      >
        Page {pagination.pageIndex + 1} of {pagination.pageCount}
      </StyledGotoPageButton>
    </Popover>
  );
}

export default observer(DataTablePageSelector);
