import React, { useState } from "react";
import { observer } from "mobx-react";
import { Button, Colors, Icon, Menu, MenuItem, Popover, Position } from "@blueprintjs/core";
import pluralize from "pluralize";
import styled from "@emotion/styled";

const StyledMenu = styled(Menu)`
  max-height: 215px !important;
  width: 105px;
`;

const StyledApplyButton = styled(MenuItem)`
  border-top: 1px solid ${Colors.LIGHT_GRAY2};
  border-radius: 0;
  bottom: 2px;
  left: 0;
  right: 0;
  padding-top: 7px;
`;

type Props = {
  changeBuildPeriod: (value: number) => void;
  disabled: boolean;
  xDayBuild: number;
};

const buildPeriodsList = new Array(30).fill(null).map((value, index) => index + 1);

function BuildPeriodSelect(props: Props) {
  const { changeBuildPeriod, disabled, xDayBuild } = props;
  const [selectedValue, setSelectedValue] = useState(xDayBuild);

  const items = buildPeriodsList.map(item => (
    <MenuItem
      key={item}
      labelElement={item === selectedValue && <Icon icon="tick" />}
      onClick={() => setSelectedValue(item)}
      shouldDismissPopover={false}
      text={pluralize("day", item, true)}
    />
  ));

  const menu = (
    <StyledMenu className="mb-5" data-testid="build-period-menu">
      {items}
      <StyledApplyButton
        className="position-fixed"
        text={
          <Button fill intent="primary" onClick={() => changeBuildPeriod(selectedValue)}>
            Apply
          </Button>
        }
      />
    </StyledMenu>
  );

  const button = (
    <Button
      className="mr-2"
      data-testid="build-period-selector"
      disabled={disabled}
      onClick={() => setSelectedValue(xDayBuild)}
    >
      <span>{pluralize("day", xDayBuild, true)}</span>
      <Icon className="ml-3" icon="caret-down" />
    </Button>
  );

  return (
    <Popover content={menu} disabled={disabled} minimal position={Position.BOTTOM_LEFT}>
      {button}
    </Popover>
  );
}

export default observer(BuildPeriodSelect);
