import { action, computed, makeObservable, observable } from "mobx";

import partnerConfig, { ApiConfig, env, partner } from "../../config/config";
import api from "services/Api";
import { RUV } from "./App.types";

import Auth from "../../models/Auth.model";

export enum AuthStatus {
  AUTHORIZED = "AUTHORIZED",
  ERROR = "ERROR",
  INIT = "INIT",
  LOADING = "LOADING",
  UNAUTHORIZED = "UNAUTHORIZED"
}

export class AppStore {
  @observable status: AuthStatus = AuthStatus.INIT;
  @observable environment: string;
  @observable partner: string;

  @observable ruv: RUV;

  @observable config: ApiConfig;
  @observable auth?: Auth;

  constructor() {
    makeObservable(this);
    this.environment = env || process.env.NODE_ENV;
    this.partner = partner;
  }

  @action
  init() {
    this.status = AuthStatus.LOADING;

    if (!window.crypto) {
      this.status = AuthStatus.ERROR;
      return;
    }

    if (partnerConfig) {
      this.config = partnerConfig;
      this.auth = new Auth(this.config);

      this.auth
        .initLock()
        .then(() => {
          this.auth
            .fetchAccessToken()
            .then(() => {
              api.init(this.config, this.auth.accessToken, () => this.auth.logOut());
              this.status = AuthStatus.AUTHORIZED;
            })
            .catch(() => {
              this.status = AuthStatus.UNAUTHORIZED;
            });
        })
        .catch(() => {
          this.status = AuthStatus.ERROR;
        });
    } else {
      this.status = AuthStatus.ERROR;
    }
  }

  @action
  logIn() {
    this.status = AuthStatus.LOADING;
    this.auth
      .authorizeWithPopup()
      .then(() => {
        api.init(this.config, this.auth.accessToken);
        this.auth.isLoginViaPopup = true;
        this.status = AuthStatus.AUTHORIZED;
      })
      .catch(() => {
        this.status = AuthStatus.UNAUTHORIZED;
      });
  }

  @action
  logInWithToken(accessToken: string) {
    this.status = AuthStatus.LOADING;
    this.auth
      .authorizeWithToken(accessToken)
      .then(() => {
        api.init(this.config, this.auth.accessToken);
        this.status = AuthStatus.AUTHORIZED;
      })
      .catch(() => {
        this.status = AuthStatus.UNAUTHORIZED;
      });
  }

  @action.bound
  logOut() {
    this.status = AuthStatus.LOADING;
    this.auth
      .logOut()
      .then(() => {
        this.status = AuthStatus.UNAUTHORIZED;
      })
      .catch(() => {
        this.status = AuthStatus.ERROR;
      });
  }

  @action
  resetPassword(email: string) {
    api.init(this.config);
    api.resetPassword(email);
  }

  @action
  getRUV() {
    return api.getRUV().then(({ data }) => {
      this.ruv = data;
    });
  }

  @computed
  get isUninitialized(): boolean {
    return this.status === AuthStatus.INIT;
  }

  @computed
  get userId(): number {
    return this.auth?.user?.id;
  }

  @computed
  get isLoading(): boolean {
    return this.status === AuthStatus.LOADING;
  }

  @computed
  get isError(): boolean {
    return this.status === AuthStatus.ERROR;
  }

  @computed
  get isLoggedIn(): boolean {
    return this.status === AuthStatus.AUTHORIZED;
  }

  @computed
  get isLoggedOut(): boolean {
    return this.status === AuthStatus.UNAUTHORIZED;
  }
}
