import React, { useLayoutEffect, useRef } from "react";
import { observer } from "mobx-react";
import { Button } from "@blueprintjs/core";

import FilterGroups from "shared/components/Sidebar/FilterGroups/FilterGroups";
import SearchInput from "shared/components/SearchInput/SearchInput";
import Sidebar from "shared/components/Sidebar/Sidebar";
import { useStores } from "store/Store";
import focusOnFilter from "shared/helpers/focusOnFilter/focusOnFilter";
import { CirrusDefaultMarketStore } from "models/Cirrus/CirrusDefaultMarket.model";
import { CirrusODDatesStore } from "models/Cirrus/CirrusODDates.model";
import { Metric } from "modules/Explore/metrics/metrics";

type Props = {
  metrics: Metric[];
  cirrusStoreContext: CirrusDefaultMarketStore | CirrusODDatesStore;
};

function CirrusSidebar({ metrics, cirrusStoreContext }: Props) {
  const { systemSettingsStore } = useStores();
  const { page, setSidebarFilterQuery, setSidebarOpen, submitSearchForm, decoratedPage } = cirrusStoreContext;
  const { isMiles } = systemSettingsStore;

  const { filterKey, filterQuery, isOpen } = page.sidebar;
  const sidebarRef = useRef(null);

  useLayoutEffect(() => {
    focusOnFilter(filterKey, filterQuery, sidebarRef);
  }, [filterKey, filterQuery]);

  return (
    <Sidebar
      ref={sidebarRef}
      footer={<Button fill intent="primary" onClick={submitSearchForm} text="Apply" />}
      isOpen={isOpen}
      setOpen={value => setSidebarOpen(value)}
    >
      <SearchInput onChange={setSidebarFilterQuery} value={filterQuery} />
      <div className="p-3">
        <FilterGroups filterGroups={metrics} isMiles={isMiles} pageContext={decoratedPage} withoutCollapsing />
      </div>
    </Sidebar>
  );
}

export default observer(CirrusSidebar);
