import React from "react";
import { Button, NonIdealState } from "@blueprintjs/core";

export const InitStatus = () => <div className="m-4" />;

type ErrorStatusType = {
  fetchData?: Function;
  pageIndex?: number;
  pageSize?: number;
};

export const ErrorStatus = ({ fetchData, pageIndex, pageSize }: ErrorStatusType) => (
  <div className="m-4 flex-grow-1">
    <NonIdealState
      action={
        <Button icon="refresh" onClick={() => fetchData && fetchData({ pageIndex, pageSize }, { saveOptions: false })}>
          Retry Now
        </Button>
      }
      description="Please retry if possible. If this problem reoccurs, please contact FLYR."
      icon="issue"
      title="Error getting data"
    />
  </div>
);

export const EmptyDataStatus = () => (
  <div className="m-4 flex-grow-1">
    <NonIdealState
      description="Please change your search criteria."
      icon="geosearch"
      title="No data matching filters"
    />
  </div>
);
