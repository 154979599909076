import React, { useLayoutEffect, useRef } from "react";
import { observer } from "mobx-react";
import { Button } from "@blueprintjs/core";

import FilterGroups from "shared/components/Sidebar/FilterGroups/FilterGroups";
import SearchInput from "shared/components/SearchInput/SearchInput";
import Sidebar from "shared/components/Sidebar/Sidebar";
import MarketMappingsSidebarGroup from "./MarketMappingsSidebarGroup";
import { useStores } from "store/Store";
import focusOnFilter from "shared/helpers/focusOnFilter/focusOnFilter";

export const marketMappingsMetrics = [
  {
    Component: MarketMappingsSidebarGroup,
    filters: [{ key: "rtMarkets" }, { key: "analystId" }, { key: "regionId" }, { key: "subregionId" }],
    key: "marketMappings",
    name: "Market Mappings"
  }
];

function MarketMappingsSidebar() {
  const { marketMappingsStore, systemSettingsStore, usersStore } = useStores();
  const { decoratedPage, setSidebarFilterQuery, setSidebarOpen, sidebar, submitSearchForm } = marketMappingsStore;
  const { filterKey, filterQuery, isOpen } = sidebar;
  const { isMiles } = systemSettingsStore;

  const sidebarRef = useRef(null);

  useLayoutEffect(() => {
    focusOnFilter(filterKey, filterQuery, sidebarRef);
  }, [filterKey, filterQuery]);

  return (
    <Sidebar
      ref={sidebarRef}
      footer={<Button fill intent="primary" onClick={submitSearchForm} text="Apply" />}
      isOpen={isOpen}
      setOpen={value => setSidebarOpen(value)}
    >
      <SearchInput onChange={setSidebarFilterQuery} value={filterQuery} />
      <div className="p-3">
        <FilterGroups
          analystList={usersStore.analystList}
          filterGroups={marketMappingsMetrics}
          isMiles={isMiles}
          pageContext={decoratedPage}
          withoutCollapsing
        />
      </div>
    </Sidebar>
  );
}

export default observer(MarketMappingsSidebar);
