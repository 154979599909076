import React, { useEffect, useMemo } from "react";
import pluralize from "pluralize";
import styled from "@emotion/styled";
import { Intent, Tooltip } from "@blueprintjs/core";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import { format, parseISO } from "date-fns";

import { AppToaster } from "services/Toaster";
import columnTextWidths from "shared/helpers/columnTextWidths/columnTextWidths";
import DataTable from "shared/components/DataTable/DataTable";
import HeatmapModule from "./HeatmapModule";
import HeatmapTableCell from "./HeatmapTableCell";
import metricLabels from "shared/metricLabels/metricLabels";
import { Cell } from "types/react-table.types";
import { columnWidths } from "modules/FlightsTable/FlightsTable";
import { extraMetrics, metricsTypes } from "modules/App/constants";
import { StyledCol } from "./Dashboard.utils";
import { tabCountReachedMessage } from "modules/App/messages";
import { useStores } from "store/Store";

const StyledLink = styled("div")`
  cursor: pointer;
  font-weight: 500;
`;

const StyledHeatmapCol = styled(StyledCol)`
  div[data-testid="table-header-depWeek-clickable"] {
    cursor: not-allowed;
  }

  [data-testid*="table-header-depDay"]:not([data-testid="table-header-depDayOfWeekGroup"]) {
    justify-content: center;
  }

  [data-testid*="data-table-cell-depDay"] {
    padding: 0;

    .bp3-popover-wrapper,
    .bp3-popover-target {
      height: 100%;
      width: 100%;
    }
  }
`;

const getRowIdCallback = (row: any, aggregations) =>
  aggregations
    .filter(aggregationKey => aggregationKey !== "numberOfFlights")
    .map(aggregation => row[aggregation])
    .join("_");

const getColumnLabelDescription = (isMiles, xDayBuild) => {
  const columnLabels = {
    ...extraMetrics,
    ...metricLabels({ isMiles, withUnits: true, xDayBuild }),
    depMonth: "Dep Month",
    flightCount: "Count"
  };

  const columnDescriptions = metricLabels({
    isMiles,
    longVersion: true,
    xDayBuild
  });
  return { columnDescriptions, columnLabels };
};

const createAnalysisLinkColumn = (canCreateTab: boolean, addNewTab: Function, computedDateFormat: string) => {
  const onClick = row => () =>
    canCreateTab
      ? addNewTab(row.original)
      : AppToaster.show({ intent: Intent.WARNING, message: tabCountReachedMessage });

  const cell = {
    accessor: "depWeek-clickable",
    Cell: ({ row }: Cell) => (
      <Tooltip
        className="d-flex justify-content-center align-items-center h-100"
        content={`Click to explore ${pluralize("flight", row.original.numberOfFlightsOriginal, true)}`}
      >
        <StyledLink onClick={onClick(row)}>{format(parseISO(row.original.depWeek), computedDateFormat)}</StyledLink>
      </Tooltip>
    ),
    Header: () => <div className="test">Date</div>,
    width: 80
  };

  return {
    accessor: "depWeek",
    columns: [cell],
    Header: "Week",
    sticky: false,
    width: 80
  };
};

const tabNewConfig = (
  tab: {
    id: string;
    applied: {
      filters: {};
    };
    flightsTable: {
      aggregations: string[];
    };
    filters: {};
    label: string;
  },
  { depWeek, isMyMarkets, userId }: { depWeek: string; isMyMarkets: string; userId: number }
) => {
  const newTab = tab;
  const newFilters = {
    analystId: isMyMarkets ? [userId] : [],
    cabinClass: [],
    depWeek: [depWeek]
  };

  newTab.applied.filters = newFilters;
  newTab.filters = newFilters;
  newTab.flightsTable.aggregations = ["depWeek", "rtMarket"];
  newTab.label = `Week of ${depWeek}`;
  return newTab;
};

const fixedColumnWidths = {
  depDayFriday: 22,
  depDayMonday: 22,
  depDayOfWeekGroup: 154,
  depDaySaturday: 22,
  depDaySunday: 22,
  depDayThursday: 22,
  depDayTuesday: 22,
  depDayWednesday: 22,
  depWeek: 80,
  "depWeek-clickable": 80
};

const newColumnWidth = {
  ...columnWidths,
  ...fixedColumnWidths
};

const fixesColumnMaxWidth = () => {
  const columns = {};
  Object.entries(fixedColumnWidths).forEach(([key, value]) => {
    columns[key] = { maxWidth: value };
  });
  return columns;
};

function HeatmapTable() {
  const {
    analysisMappingsStore,
    appStore,
    dashboardHeatmapStore,
    dashboardStore,
    tabsStore,
    systemSettingsStore
  } = useStores();
  const history = useHistory();

  const { activePage, isLoaded, isMyMarkets } = dashboardStore;
  const { getHeatmapData, heatmap = {}, status, refetchTableData, xDayBuild, numberOfFlights } = dashboardHeatmapStore;
  const { addTab, canCreateTab, defaultNewTab } = tabsStore;
  const { userId } = appStore;
  const { isAnalystDone } = analysisMappingsStore;
  const { computedDateFormat, isMiles } = systemSettingsStore;

  useEffect(() => {
    if (isLoaded && isAnalystDone) {
      getHeatmapData();
    }
  }, [activePage, getHeatmapData, isAnalystDone, isLoaded]);

  const { columnLabels, columnDescriptions } = getColumnLabelDescription(isMiles, xDayBuild);
  const newColumnDescriptions = {
    ...columnDescriptions,
    depDayFriday: "Dep Day of Week Friday",
    depDayMonday: "Dep Day of Week Monday",
    depDaySaturday: "Dep Day of Week Saturday",
    depDaySunday: "Dep Day of Week Sunday",
    depDayThursday: "Dep Day of Week Thursday",
    depDayTuesday: "Dep Day of Week Tuesday",
    depDayWednesday: "Dep Day of Week Wednesday"
  };

  const columnTextWidth = useMemo(() => columnTextWidths(columnLabels), [columnLabels]);

  const baseMetrics = heatmap?.columns || [];
  const isEmpty = heatmap?.data?.length === 0;

  const renderTableCell = (props: { value: { depDate: string; numberOfFlights: number } }) => (
    <HeatmapTableCell data={props.value} />
  );

  const addNewTab = (row: { depDayMonday: { depDate: string }; depWeek: string }) => {
    const depWeek = row.depWeek;

    const newTab = tabNewConfig(defaultNewTab(), { depWeek, isMyMarkets, userId });

    addTab(newTab);
    history.push(`/analysis/${newTab.id}/explore`);
  };

  const columnSortType = baseMetrics
    .flatMap(([, columns]) => columns)
    .reduce(
      (acc, curr) => {
        const newAcc = acc;
        newAcc[curr] = null;
        return newAcc;
      },
      { "depWeek-clickable": null }
    );

  const headerExtraStyles = fixesColumnMaxWidth();

  const tableProps = {
    ...heatmap,
    baseMetrics,
    canSetPageSize: false,
    cellRenderers: {
      ...metricsTypes,
      depDayFriday: renderTableCell,
      depDayMonday: renderTableCell,
      depDaySaturday: renderTableCell,
      depDaySunday: renderTableCell,
      depDayThursday: renderTableCell,
      depDayTuesday: renderTableCell,
      depDayWednesday: renderTableCell
    },
    columnDescriptions: newColumnDescriptions,
    columnLabels,
    columns: [...baseMetrics],
    columnSortType,
    columnTextWidth,
    columnWidths: newColumnWidth,
    extraCellStyles: headerExtraStyles,
    extraColumn: () => createAnalysisLinkColumn(canCreateTab, addNewTab, computedDateFormat),
    extraHeaderStyles: headerExtraStyles,
    fetchData: refetchTableData,
    getRowIdCallback: row => getRowIdCallback(row, heatmap.aggregations),
    sortBy: { direction: "asc", field: "depWeek-clickable" },
    status,
    title: "Heatmap"
  };

  return (
    <StyledHeatmapCol className="col-8 mh-0">
      <HeatmapModule isEmpty={isEmpty} numberOfFlights={numberOfFlights} status={status}>
        <DataTable {...tableProps} />
      </HeatmapModule>
    </StyledHeatmapCol>
  );
}

export default observer(HeatmapTable);
