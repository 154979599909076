import { Colors, Icon } from "@blueprintjs/core";
import styled from "@emotion/styled";
import React from "react";
import { HeaderGroup } from "types/react-table.types";

import HeaderContextMenu from "../HeaderContextMenu/HeaderContextMenu";
import { DataTableFilters, StickyColumn } from "../DataTable.types";

const StyledTableHeaderCell = styled("th")<{ depth: number; isSorted: boolean }>`
  position: sticky;
  z-index: 1;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  justify-content: space-between;
  align-items: center;
  font-weight: ${({ depth, isSorted }) => {
    if (isSorted) return 600;
    return depth ? 400 : 500;
  }};
  background: ${({ depth }) => (depth ? Colors.LIGHT_GRAY5 : Colors.LIGHT_GRAY4)};

  .context-menu {
    opacity: 0;
    width: 0;
    transition: opacity 0.1s linear;
  }

  &:hover {
    .filter--used {
      display: none;
    }
    .context-menu {
      margin-right: -0.5rem;
      opacity: 1;
      width: 30px;
    }
  }

  button {
    height: 24px;
    min-height: 24px;
    svg {
      width: 14px;
      height: 14px;
    }
    span.bp3-button-text {
      height: 24px;
      align-self: center;
      line-height: 24px;
    }
  }
`;
const StyledHeaderLabel = styled.div`
  min-width: 0;
`;

type Props = {
  aggregationsDisabled: boolean;
  appliedFilters: string[];
  columnDescriptions: { [key: string]: string };
  columnSortType?: { [key: string]: string | null };
  extraHeaderStyles: {};
  filters: DataTableFilters;
  gotoPage: Function;
  headerGroups: HeaderGroup[];
  headerMenuLabels?: {
    hideColumn: (parentId: string) => string;
  };
  initialFilters?: DataTableFilters;
  isAdjustable: boolean;
  nonFilterableColumns: string[];
  nonHideableColumns: string[];
  onColumnFilter?: Function;
  onColumnHide: Function;
  sortingEnabled: boolean;
  toggleFixedColumn: Function;
};

function DataTableHeader(props: Props) {
  const {
    aggregationsDisabled,
    appliedFilters = [],
    columnDescriptions,
    columnSortType = {},
    extraHeaderStyles = {},
    gotoPage,
    headerGroups,
    headerMenuLabels,
    isAdjustable,
    nonFilterableColumns,
    nonHideableColumns,
    onColumnFilter,
    onColumnHide,
    sortingEnabled,
    toggleFixedColumn
  } = props;

  const isColumnUsed = (columnId: string) => appliedFilters.includes(columnId);

  const isColumnSortable = column => {
    const isGroupHeader = column.columns;
    const isLink = column.id.includes("Link");
    const nonSortableColumns = ["checkbox", "expander"];
    return !isGroupHeader && !isLink && !nonSortableColumns.includes(column.id) && columnSortType[column.id] !== null;
  };

  const tableHeader = headerGroups.map((headerGroup: HeaderGroup<{ originalId?: string }>) => {
    const headerCells = headerGroup.headers.map(column => {
      const isSortable = sortingEnabled && isColumnSortable(column);
      const icon = column.isSortedDesc ? "arrow-up" : "arrow-down";
      const parentId = column.parent ? column.parent.id : "";
      const columnsWithoutContextMenu = aggregationsDisabled ? ["selection", "aggregations"] : ["selection"];
      const columnsWithoutContextMenuWithAggregations = ["selection", "aggregations"];
      const isParentFrozenToRightSide = column.parent ? (column.parent as StickyColumn).sticky === "right" : false;

      /* eslint-disable sort-keys */
      const columnId = column.originalId || column.id;
      const headerCellProps = {
        depth: column.depth,
        isSorted: column.isSorted,
        width: column.width,
        className: column.className,
        ...column.getHeaderProps(),
        style: {
          ...column.getHeaderProps().style,
          ...(extraHeaderStyles[columnId] || {})
        },
        title: undefined
      };
      /* eslint-enable sort-keys */

      const sortProps = isSortable ? column.getSortByToggleProps() : undefined;
      const isFrozen = column.sticky;
      const isGroupColumn = !column.depth;
      const isHideable =
        isAdjustable &&
        !isParentFrozenToRightSide &&
        !columnsWithoutContextMenu.includes(parentId) &&
        !columnsWithoutContextMenu.includes(columnId) &&
        !nonHideableColumns.includes(columnId);

      const isFreezable =
        isAdjustable &&
        column.sticky !== "right" &&
        !columnsWithoutContextMenuWithAggregations.includes(column.originalId);
      const isFilterable = !nonFilterableColumns.includes(columnId);

      const isFilterUsed = isColumnUsed(columnId);

      const contextMenuProps = {
        columnId,
        isFilterUsed,
        isFrozenColumn: isFrozen,
        isGroupColumn,
        ...(headerMenuLabels && {
          headerMenuLabels: {
            hideColumn: headerMenuLabels.hideColumn(parentId)
          }
        }),
        ...(isFilterable && { onColumnFilter }),
        ...(isFreezable && {
          onColumnFreeze: () => toggleFixedColumn(columnId)
        }),
        ...(isHideable && {
          onColumnHide: () => onColumnHide(columnId, parentId)
        }),
        ...(isSortable && {
          onColumnSort: isDesc => column.toggleSortBy(isDesc)
        })
      };

      return (
        <StyledTableHeaderCell {...headerCellProps}>
          <StyledHeaderLabel
            {...sortProps}
            className="flex-grow-1 d-flex align-items-center"
            data-testid={`table-header-${columnId}`}
            onClick={e => {
              if (sortProps && sortProps.onClick) {
                gotoPage(0);
                sortProps.onClick(e);
              }
            }}
            title={columnDescriptions[column.id]}
          >
            {column.isSorted && <Icon className="mr-1" icon={icon} iconSize={12} />}
            {column.render("Header")}
            {isFilterUsed && <Icon className="ml-auto filter--used" color={Colors.GRAY2} icon="filter" iconSize={12} />}
          </StyledHeaderLabel>
          <HeaderContextMenu {...contextMenuProps} />
        </StyledTableHeaderCell>
      );
    });

    return <tr {...headerGroup.getHeaderGroupProps()}>{headerCells}</tr>;
  });

  return <>{tableHeader}</>;
}

export default DataTableHeader;
