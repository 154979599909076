export const DASHBOARD_VIEWS = [
  {
    key: "my-markets",
    label: "My Markets"
  },
  {
    key: "network",
    label: "Network"
  }
];

export const TOP_BAR_TOOLTIP_LABELS = {
  disabled: "You don’t have any markets assigned",
  "my-markets": "Review only markets assigned to you",
  network: "Review all markets in the network"
};
