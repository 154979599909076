import { format, parseISO } from "date-fns";
import { observer } from "mobx-react";
import React, { useMemo } from "react";

import { daysOfWeek, extraMetrics } from "../../App/constants";
import metricLabels from "shared/metricLabels/metricLabels";
import { filterOrder } from "../../Explore/metrics/metrics";
import { Tab } from "models/Tab/Tab.model";
import { FILTERS_INIT } from "models/Tab/Tab.utils";
import { useStores } from "store/Store";

import TagsList from "shared/components/TagsList/TagsList";

type Props = {
  tab: Tab;
};

const UNASSIGNED = "Unassigned";
const UNDEFINED = "Undefined";

const AnalysisTagsList = ({ tab }: Props) => {
  const {
    appStore,
    capacityStore,
    analysisMappingsStore,
    influenceHistoryStore,
    influenceStore,
    regionsStore,
    systemSettingsStore
  } = useStores();
  const { analystUsers = [] } = analysisMappingsStore;
  const { computedDateFormat, computedDateMonthFormat, isMiles } = systemSettingsStore;
  const currentUser = appStore?.auth?.user;

  const filterLabels = {
    ...metricLabels({ isMiles, withParent: true, withUnits: true, xDayBuild: tab.xDayBuild }),
    ...extraMetrics,
    sellingFareDiffToCirrusCompFare: "Fare Δ to Fare Match",
    sellingFareDiffToCirrusCompFarePercentile: "Fare Δ to Fare Match",
    sellingFareDiffToDepDateCompFare: "Fare Δ to Fare Dep Date",
    sellingFareDiffToDepDateCompFarePercentile: "Fare Δ to Fare Dep Date",
    sellingFareDiffToTimePeriodCompFare: "Fare Δ to Fare Time",
    sellingFareDiffToTimePeriodCompFarePercentile: "Fare Δ to Fare Time"
  };
  const additionalAnalystUsers = useMemo(() => {
    const newAnalystUsers = {
      null: UNASSIGNED
    };

    [...analystUsers, ...influenceHistoryStore.mappings.creators].filter(Boolean).forEach(({ value, label }) => {
      newAnalystUsers[value] = label;
    });
    if (currentUser?.id && !newAnalystUsers[currentUser.id]) {
      newAnalystUsers[currentUser.id] = currentUser.name;
    }
    return newAnalystUsers;
  }, [JSON.stringify(analystUsers), currentUser?.id]);

  const onFilterValueClick = (filterId: string) => {
    tab.setSidebarOpen(true);
    tab.setSidebarFilterQuery(filterLabels[filterId], filterId);
  };

  const isPreviewActive = influenceStore.isPreviewActive || capacityStore.isPreviewActive;
  const conditionalProps = !isPreviewActive
    ? {
        onFilterRemove: tab.clearSearchParam,
        onFilterToggle: tab.toggleFilter,
        onFilterValueClick,
        onFilterValueToggle: tab.toggleFilterValue,
        onValueRemove: tab.removeFilterValue
      }
    : {};

  const valueRenderers = {
    depDow: value => daysOfWeek?.[value]?.short,
    depMonth: value => value && format(parseISO(value), computedDateMonthFormat),
    depWeek: value => value && format(parseISO(value), computedDateFormat),
    fusionrmStatus: value => (value ? "On" : "Off"),
    quarter: value => `${value.substring(0, 4)} ${value.substring(4)}`,
    regionId: value => regionsStore.regionsById[value] || UNDEFINED,
    subregionId: value => regionsStore.subregionsById[value] || UNDEFINED
  };

  return (
    <TagsList
      {...conditionalProps}
      additionalTagLabels={additionalAnalystUsers}
      disabled={!tab.filtersEnabled}
      disabledFilters={tab.disabledFilters}
      filterOrder={filterOrder}
      initialFilters={FILTERS_INIT}
      isDataLoading={tab.isTabLoading}
      onFilterToggle={tab.toggleFilter}
      params={tab.allAppliedFilters}
      valueRenderers={valueRenderers}
      xDayBuild={tab.xDayBuild}
    />
  );
};

export default observer(AnalysisTagsList);
