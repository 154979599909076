import React from "react";
import styled from "@emotion/styled";
import { Colors } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { HEATMAP_THRESHOLD_COLORS } from "modules/App/constants";
import { getPivotThresholdColor } from "shared/helpers/getPivotThresholdColor/getPivotThresholdColor";
import DataTableCellValue from "shared/components/DataTable/DataTableCellValue/DataTableCellValue";
import { CellType } from "types/DataTable.types";

interface StyledPivotCell {
  color: string;
}

const PivotCell = styled.div<StyledPivotCell>`
  background-color: ${({ color }) => color || "inherit"};
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  padding: 0.2rem 0.25rem;

  div {
    color: ${Colors}.DARK_GRAY1;
    font-size: 12px;
    font-style: normal;
    font-weight: normal;
    line-height: 1.25;
    text-align: right;
  }
`;

type Props = {
  boundaryValue: number | null;
  type: CellType;
  value: number;
};

function PivotTableCell({ boundaryValue = null, type, value }: Props) {
  const dataSelectedExists = value !== null;

  const thresholdColor = dataSelectedExists
    ? getPivotThresholdColor(value, boundaryValue)
    : HEATMAP_THRESHOLD_COLORS.DISABLE;

  const cell = <DataTableCellValue options={{ noBackground: true }} type={type} value={value} />;
  if (type === CellType.String) {
    return cell;
  }
  return <PivotCell color={thresholdColor}>{cell}</PivotCell>;
}

export default observer(PivotTableCell);
