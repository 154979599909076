import styled from "@emotion/styled";
import { Button, ButtonGroup, Colors } from "@blueprintjs/core";
import { rgba } from "polished";

const omitMenuActiveProp = prop => prop !== "menuIsActive";

type TypeStyledGraphMenu = {
  leftPositionMenu: number;
  isMenuOptionOpen: boolean;
};

type TypeStyledButton = {
  menuIsActive: boolean;
};

export const StyledGraphMenuContainer = styled("div")<TypeStyledGraphMenu>`
  left: ${({ leftPositionMenu }) => `${leftPositionMenu - 1}px`};
  position: absolute;
  top: -1px;
  z-index: ${({ isMenuOptionOpen }) => (isMenuOptionOpen ? 4 : 1)};
`;

export const StyledGraphMenu = styled("div")`
  border: 1px solid ${Colors.LIGHT_GRAY1};
`;

export const StyledButtonGroup = styled(ButtonGroup, { shouldForwardProp: omitMenuActiveProp })<TypeStyledButton>`
  max-height: ${({ menuIsActive }) => (menuIsActive ? "300px" : 0)};
  overflow: hidden;
  transition: max-height 0.3s;

  .bp3-popover-target {
    background-color: ${Colors.WHITE};
  }

  .bp3-button {
    background-color: ${Colors.WHITE};
    background-image: none;
    border-radius: 0 !important;
    border: none !important;
    box-shadow: none !important;
    margin-bottom: 0 !important;
    z-index: 1 !important;

    &:hover {
      background-color: ${rgba(Colors.GRAY4, 0.3)} !important;
    }
  }

  .bp3-icon {
    color: ${Colors.GRAY1};
  }

  .bp3-popover-open {
    .bp3-button {
      background-color: ${Colors.WHITE};
    }
    .bp3-icon {
      color: ${Colors.BLUE4};
    }
  }
`;

export const StyledButton = styled(Button, {
  shouldForwardProp: omitMenuActiveProp
})<TypeStyledButton>`
  background-color: ${Colors.WHITE} !important;
  border-radius: 0;
  box-shadow: none !important;
  height: 28px;
  width: 40px;
  z-index: 1;

  .bp3-icon {
    color: ${({ menuIsActive }) => (menuIsActive ? Colors.LIGHT_GRAY1 : Colors.BLUE4)};
    transform: ${({ menuIsActive }) => `scaleY(${menuIsActive ? -1 : 1})`};
    transition: transform 0.3s, color 0.3s;
  }
`;
