import React from "react";
import styled from "@emotion/styled";
import { Colors, Intent, Menu, Popover, PopoverInteractionKind, Position } from "@blueprintjs/core";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import { format, parseISO } from "date-fns";

import { AppToaster } from "services/Toaster";
import { tabCountReachedMessage } from "modules/App/messages";
import { useStores } from "store/Store";
import isNumber from "shared/helpers/isNumber/isNumber";
import { heatmapMetrics } from "models/Dashboard/DashboardHeatmapStore.model";
import { HEATMAP_THRESHOLD_COLORS } from "modules/App/constants";
import { getHeatmapThresholdColor } from "modules/DashboardHeatmap/getHeatmapThresholdColor/getHeatmapThresholdColor";

const StyledPopoverContent = styled(Menu)`
  width: auto !important;
  font-size: 12px;
  li,
  span {
    line-height: 1.25;
  }
  &.bp3-menu {
    width: 300px;
  }
  .list-unstyled li {
    list-style: none;
  }
`;

interface StyledHeatmapCellTypes {
  isDataExists: boolean;
}

const StyledHeatmapCell = styled("div")<StyledHeatmapCellTypes>`
  background-color: ${({ color }) => color};
  cursor: ${({ isDataExists }) => (isDataExists ? "pointer" : "auto")};
  height: 100%;
  width: 100%;
`;

interface StyledHeatmapCellListItemTypes {
  isHighlighted: boolean;
}

interface StyledHeatmapValueTypes {
  label: string;
  value: number;
}

const StyledHeatmapCellListItem = styled("li")<StyledHeatmapCellListItemTypes>`
  font-weight: ${({ isHighlighted }) => {
    return isHighlighted ? "700" : "normal";
  }};
`;

const StyledHeatmapValue = styled("span")<StyledHeatmapValueTypes>`
  color: ${({ label, value }) => {
    if (label !== "xDayRevenuePotential" && value > 0) return Colors.GREEN4;
    if (label !== "xDayRevenuePotential" && value < 0) return Colors.RED4;
    return Colors.DARK_GRAY1;
  }};
`;

type Props = {
  data: {
    depDate: string;
    numberOfFlights: number;
  };
};

const tabNewConfig = (
  tab: {
    applied: {
      filters: {};
    };
    flightsTable: {
      aggregations: string[];
    };
    filters: {};
    id: string;
    label: string;
  },
  { isMyMarkets, userId, depDate }: { depDate: string; isMyMarkets: boolean; userId: number }
) => {
  const newTab = tab;
  const newFilters = {
    analystId: isMyMarkets ? [userId] : [],
    cabinClass: [],
    depDate: { end: depDate, start: depDate }
  };

  newTab.applied.filters = newFilters;
  newTab.filters = newFilters;
  newTab.flightsTable.aggregations = ["depDate", "rtMarket"];
  newTab.label = `Day of ${depDate}`;
  return newTab;
};

function HeatmapTableCell({ data }: Props) {
  const { appStore, dashboardStore, dashboardHeatmapStore, systemSettingsStore, tabsStore } = useStores();
  const { isMyMarkets } = dashboardStore;
  const { selectedMetric, selectedMetricBoundary } = dashboardHeatmapStore;
  const { addTab, canCreateTab, defaultNewTab } = tabsStore;
  const { userId } = appStore;
  const { computedDateFormat = "yyyy-MM-dd" } = systemSettingsStore;

  const history = useHistory();

  const dataSelectedExists = data && data[selectedMetric] !== null;

  const thresholdColor = dataSelectedExists
    ? getHeatmapThresholdColor(data[selectedMetric], selectedMetricBoundary[selectedMetric])
    : HEATMAP_THRESHOLD_COLORS.DISABLE;

  const addNewTab = () => {
    const { depDate } = data;
    const newTab = tabNewConfig(defaultNewTab(), { depDate, isMyMarkets, userId });

    addTab(newTab);
    history.push(`/analysis/${newTab.id}/explore`);
  };

  const onClick = () =>
    canCreateTab ? addNewTab() : AppToaster.show({ intent: Intent.WARNING, message: tabCountReachedMessage });

  const formatHintValue = (dataValue: any, label: string) => {
    if (!isNumber(dataValue)) {
      return "--";
    }
    if (label === "Final Revenue") {
      return dataValue.toLocaleString("ja-JP", {
        currency: "USD",
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
        style: "currency"
      });
    }
    return `${dataValue}%`;
  };

  const detailsPopover = data ? (
    <StyledPopoverContent className="pt-2 pb-1 px-2">
      <ul className="my-0 p-0 list-unstyled">
        <div className="d-flex justify-content-between mb-2">
          <strong>
            {format(parseISO(data.depDate), "EEE")} {format(parseISO(data.depDate), computedDateFormat)}
          </strong>
          <span className="ml-1">· {data.numberOfFlights} flights</span>
        </div>
        {heatmapMetrics.map(metricKey => {
          const { label, value } = metricKey;

          return (
            <StyledHeatmapCellListItem
              key={`heatmapCell-${value}`}
              className="d-flex justify-content-between pb-1"
              isHighlighted={value === selectedMetric}
            >
              {label}
              <StyledHeatmapValue className="ml-2" label={value} value={data[value]}>
                {isNumber(data[value]) ? formatHintValue(data[value], label) : "--"}
              </StyledHeatmapValue>
            </StyledHeatmapCellListItem>
          );
        })}
      </ul>
    </StyledPopoverContent>
  ) : null;

  return data ? (
    <Popover
      data-testid="heatmap-item-details"
      fill
      hoverCloseDelay={0}
      hoverOpenDelay={1000}
      interactionKind={PopoverInteractionKind.HOVER}
      position={Position.TOP_LEFT}
      targetTagName="div"
    >
      <StyledHeatmapCell
        color={thresholdColor}
        data-testid="heatmap-dep-date-item"
        isDataExists={dataSelectedExists}
        onClick={() => dataSelectedExists && onClick()}
      />
      {detailsPopover}
    </Popover>
  ) : (
    <StyledHeatmapCell color={thresholdColor} data-testid="heatmap-dep-date-item" isDataExists={false} />
  );
}

export default observer(HeatmapTableCell);
