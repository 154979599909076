// @flow

import React from "react";
import { observer } from "mobx-react";
import { Spinner } from "@blueprintjs/core";
import pluralize from "pluralize";

import { Status } from "modules/App/Status";
import { Tab } from "models/Tab/Tab.model";

type Props = {
  flightsCount: $PropertyType<Tab, "flightsCount">,
  flightsTable: $PropertyType<Tab, "flightsTable">,
  options: { showSelected: boolean, showTotal: boolean },
  prefix: ?string,
  selectedRowsNumberOfFlights: $PropertyType<Tab, "selectedRowsNumberOfFlights">
};

function NumberOfFlights(props: Props) {
  const {
    flightsCount,
    flightsTable,
    options = { showRows: true, showSelected: true, showTotal: true },
    prefix = "",
    selectedRowsNumberOfFlights = 0
  } = props;
  const { numberOfRows, totalNumberOfFlights } = flightsCount;

  function renderFlights() {
    const { selectedRows = [] } = flightsTable;
    const selectedRowsSize = selectedRows?.length || 0;

    if (selectedRowsSize > 0 && numberOfRows) {
      const rowsInfo = pluralize("row", selectedRowsSize, true);
      const flightsInfo = pluralize("flight", selectedRowsNumberOfFlights, true);

      return `${flightsInfo} ${options.showRows ? `in ${rowsInfo}` : ""} selected`;
    }

    return options.showRows ? pluralize("row", numberOfRows, true) : null;
  }

  if (flightsTable.status === Status.LOADING || flightsCount.status === Status.LOADING)
    return <Spinner className="mr-2 flight-count-info-spinner" size={15} />;

  if (numberOfRows >= 0 && totalNumberOfFlights >= 0) {
    const flightTotalFlights = pluralize("flight", totalNumberOfFlights, true);

    const text = [
      options.showSelected ? renderFlights() : null,
      options.showTotal ? `${flightTotalFlights} in total` : null
    ]
      .filter(Boolean)
      .join(" · ");

    return (
      <span className="mr-2" data-testid="flight-count-info" title={text}>
        {prefix}
        {text}
      </span>
    );
  }

  return null;
}

export default observer(NumberOfFlights);
