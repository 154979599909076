import { action, computed, makeObservable, observable } from "mobx";
import { Intent } from "@blueprintjs/core";
import { AxiosPromise } from "axios";

import api, { isCancel } from "services/Api";
import removeEmptyFilters from "shared/helpers/removeEmptyFilters/removeEmptyFilters";
import type { FlightsParams, FlightsResponse, PageContextWithOptionalFilters } from "types/Flights.types";
import type { RootStore } from "modules/App/Root.model";
import { AppToaster } from "services/Toaster";
import { FILTERS_INIT } from "../Tab/Tab.utils";
import { Status } from "modules/App/Status";

interface FlightsParamsTypeWithCustomFilters extends Omit<FlightsParams, "filters"> {
  filters: PageContextWithOptionalFilters;
}

export class DashboardWatchlistStore {
  @observable data: {
    apiIdentifier?: string;
    data?: {
      numberOfFlights?: number;
    };
    id?: number;
    label?: string;
    numberOfFlights?: number;
    status?: Status;
    type?: string;
    view?: FlightsParamsTypeWithCustomFilters;
  }[] = [];
  @observable progressValue = 0;
  @observable selectedMetric = "finalRevenueExpectedPercentDiffToBaseline";
  @observable status: Status = Status.INIT;
  @observable xDayBuild: number = 7;
  @observable filterWatchlist: boolean = true;

  rootStore: RootStore;

  constructor(rootStore: Object) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  @action.bound
  toggleFilterWatchlist() {
    this.filterWatchlist = !this.filterWatchlist;
  }

  @action.bound
  resetWatchlist() {
    this.data = [];
    this.status = Status.INIT;
  }

  @action.bound
  refetchWatchlistItem(item): AxiosPromise<FlightsResponse> {
    const { id, label } = item;

    this.status = Status.LOADING;
    this.data = this.data.filter(dataRecord => dataRecord.apiIdentifier !== item.apiIdentifier);

    return api
      .getFlights(item.view, "watchlist", `template-${label}-${id}`)
      .then(response => {
        this.data = [
          ...this.data,
          {
            apiIdentifier: item.apiIdentifier,
            data: { ...(response?.data?.rows?.[0] || {}) },
            id: item.id,
            label: item.label,
            status: Status.DONE,
            type: item.type,
            view: item.view
          }
        ];
        this.status = Status.DONE;
        return response;
      })
      .catch(thrown => {
        if (isCancel(thrown)) {
          return;
        }
        this.data = [
          ...this.data,
          {
            apiIdentifier: item.apiIdentifier,
            data: {},
            id: item.id,
            label: item.label,
            status: Status.ERROR,
            type: item.type,
            view: item.view
          }
        ];
        return thrown;
      });
  }

  @action
  getWatchlistItem(
    item: { type: string; id: number; label: string; view: { conditionalFilters: []; filters: object } },
    dashboardPage: string
  ): AxiosPromise<FlightsResponse> | undefined {
    const { id, label, view: params } = item;

    const fetchParams: FlightsParamsTypeWithCustomFilters = {
      aggregations: [],
      columns: [
        "numberOfFlights",
        "finalRevenueExpectedPercentDiffToBaseline",
        "xDayFinalRevenueExpectedBuildPercentDiffToBaseline",
        "raskDiffToBaseline",
        "xDayRaskBuildDiffToBaseline",
        "diffToElb",
        "xDayLoadFactorBuildDiffToBaseline",
        "influenceImpact",
        "xDayInfluenceImpactBuild"
      ],
      conditionalFilters: params.conditionalFilters,
      filters: removeEmptyFilters(params.filters, FILTERS_INIT),
      pagination: {
        offset: 0,
        size: 1
      },
      sortBy: { direction: "desc", field: this.selectedMetric },
      xDayBuild: this.xDayBuild
    };

    const currentUserId = this.rootStore?.appStore?.auth?.user?.id;

    const newAnalystId = fetchParams.filters.analystId || [];
    const analystHasCurrentUser = newAnalystId.findIndex(userId => userId === -1);

    if (analystHasCurrentUser !== -1) {
      newAnalystId[analystHasCurrentUser] = currentUserId;
    }

    const apiFinalParams =
      this.rootStore.dashboardStore.activePage === "network"
        ? fetchParams
        : {
            ...fetchParams,
            filters: {
              ...fetchParams.filters,
              analystId: newAnalystId ? [...new Set([...newAnalystId, currentUserId])] : [currentUserId]
            }
          };

    if (dashboardPage !== this.rootStore.dashboardStore.activePage) {
      return;
    }

    return api
      .getFlights(apiFinalParams, "watchlist", `template-${label}-${id}`)
      .then(response => {
        this.data = [
          ...this.data,
          {
            apiIdentifier: `template-${label}-${id}`,
            data: { ...response.data.rows[0] },
            id: item.id,
            label: item.label,
            status: Status.DONE,
            type: item.type,
            view: apiFinalParams
          }
        ];
        if (this.data.length === this.analysesCount) {
          AppToaster.show({
            intent: Intent.SUCCESS,
            message: "Watchlist data loaded successfully"
          });
        }
        return response;
      })
      .catch(thrown => {
        if (isCancel(thrown)) {
          return;
        }
        this.data = [
          ...this.data,
          {
            apiIdentifier: `template-${label}-${id}`,
            data: {},
            id: item.id,
            label: item.label,
            status: Status.ERROR,
            type: item.type,
            view: apiFinalParams
          }
        ];
        return thrown;
      });
  }

  @action.bound
  getWatchlistData() {
    const { savedAnalyses, teamAnalyses, systemAnalyses } = this.rootStore.templatesStore;
    const allTemplates = [
      ...savedAnalyses.map(item => ({ ...item, type: "savedAnalysis" })),
      ...teamAnalyses.map(item => ({ ...item, type: "teamAnalysis" })),
      ...systemAnalyses.map(item => ({ ...item, type: "systemAnalysis" }))
    ].sort((a, b) => a.id - b.id);
    const delay = (ms = 70) => new Promise(r => setTimeout(r, ms));

    const currentRoute = this.rootStore.dashboardStore.activePage;

    const getWatchlistItems = async items => {
      this.data = [];
      this.status = Status.LOADING;
      for (let index = 0; index < items.length; index = 1 + index) {
        if (window.location.pathname !== "/dashboard") {
          this.resetWatchlist();
          break;
        }
        if (this.rootStore.dashboardStore.activePage !== currentRoute) {
          break;
        }
        // https://eslint.org/docs/rules/no-await-in-loop
        // Loops may be used to prevent your code from sending an excessive amount of requests in parallel.
        // In such cases it makes sense to use await within a loop
        // eslint-disable-next-line no-await-in-loop
        await delay();
        // eslint-disable-next-line no-await-in-loop
        const res = await this.getWatchlistItem(items[index], currentRoute);
        if (res && index === items.length - 1) {
          this.status = Status.DONE;
        }
      }
    };
    if (this.status === Status.INIT) {
      getWatchlistItems(allTemplates);
    }
  }

  @action.bound
  changeSelectedMetric(metric: string) {
    if (!metric) {
      this.selectedMetric = "diffToElb";
      return;
    }
    this.selectedMetric = metric;
  }

  @computed
  get analysesCount() {
    const { savedAnalyses, systemAnalyses, teamAnalyses } = this.rootStore.templatesStore;
    const allTemplates = [...savedAnalyses, ...teamAnalyses, ...systemAnalyses];
    return allTemplates.length;
  }

  @computed
  get currentAnalysesCount() {
    if (this.status === Status.DONE || this.status === Status.ERROR) {
      return this.data.filter(item => item?.data?.numberOfFlights).length;
    }
    return 0;
  }
}
