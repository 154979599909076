import cloneDeep from "lodash.clonedeep";
import difference from "lodash.difference";
import isEmpty from "lodash.isempty";
import pluralize from "pluralize";
import union from "lodash.union";
import { action, computed, makeObservable, observable, toJS } from "mobx";
import { Intent } from "@blueprintjs/core";

import api from "services/Api";
import getEnabledFilters from "shared/helpers/getEnabledFilters/getEnabledFilters";
import removeValueInFilter from "shared/helpers/removeValueInFilter/removeValueInFilter";
import toggleFilter from "shared/helpers/toggleFilter/toggleFilter";
import toggleFilterValue from "shared/helpers/toggleFilterValue/toggleFilterValue";
import { AppToaster } from "services/Toaster";
import { Status } from "modules/App/Status";
import { TableTypes, MarketParams } from "types/MarketMappings.types";
import syncDisabledFilters from "shared/helpers/syncDisabledFilters/syncDisabledFilters";

export const FILTERS_INIT = {
  analystId: [],
  regionId: [],
  rtMarkets: [],
  subregionId: []
};

export class MarketMappingsStore {
  @observable
  filters = { ...FILTERS_INIT };

  @observable
  disabledFilters = {};

  @observable
  filtersEnabled = true;

  @observable
  status = Status.INIT;

  @observable
  rtMarkets = [];

  @observable
  sidebar = {
    filterKey: "",
    filterQuery: "",
    isOpen: false
  };

  @observable
  searchParams = { ...FILTERS_INIT };

  @observable
  table: TableTypes = {
    columns: ["rtMarket", "analystName", "regionName", "subregionName"],
    data: [],
    selectedRows: observable.array([]),
    status: Status.INIT
  };

  constructor() {
    makeObservable(this);
  }

  @action.bound
  setSidebarOpen(isOpen) {
    this.sidebar.isOpen = isOpen;
    if (!isOpen) {
      this.sidebar.filterQuery = "";
      this.sidebar.filterKey = "";
    }
  }

  @action.bound
  toggleFiltersEnabled() {
    this.filtersEnabled = !this.filtersEnabled;
    this.getMarkets();
  }

  @action.bound
  setSidebarFilterQuery(filterQuery, filterKey) {
    const { sidebar } = this;

    sidebar.filterQuery = filterQuery;
    sidebar.filterKey = filterKey;
  }

  @action getMarketList() {
    const params = {
      sortBy: {
        direction: "asc",
        field: "market"
      }
    };

    api
      .getMarkets(params)
      .then(({ data }) => {
        this.rtMarkets = data.rows.map(row => row.rtMarket);
        this.status = Status.DONE;
      })
      .catch(() => {
        this.status = Status.ERROR;
      });
  }

  @action getMarkets() {
    const { table } = this;
    table.status = Status.LOADING;

    const params = {
      filters: this.normalizedFilters,
      sortBy: {
        direction: "asc",
        field: "market"
      }
    };

    api
      .getMarkets(params)
      .then(({ data }) => {
        table.data = data.rows;
        table.status = Status.DONE;
      })
      .catch(() => {
        table.status = Status.ERROR;
      });
  }

  @action
  changeFilter(name: string, value: any) {
    this.filters[name] = value;
  }

  @action.bound
  resetSearchParam(filterKey: string) {
    this.filters[filterKey] = FILTERS_INIT[filterKey];
    if (this.disabledFilters[filterKey]) {
      delete this.disabledFilters[filterKey];
    }
    this.submitSearchForm();
  }

  @action.bound
  removeFilterValue(filterKey: string, valueToRemove: string) {
    const { disabledFilters, filters } = this;
    removeValueInFilter(filterKey, valueToRemove, disabledFilters, filters, FILTERS_INIT[filterKey]);
    this.submitSearchForm();
  }

  @action.bound
  shiftToggleRows(selectedRow: number, clickedRow: number) {
    const { data, selectedRows } = this.table;
    const isToggleOn = selectedRows.includes(clickedRow);
    const tableIdRows = data.map((row, i) => i);
    const sliceIndexes = [tableIdRows.indexOf(selectedRow), tableIdRows.indexOf(clickedRow)].sort((a, b) => a - b);
    const actionRows = tableIdRows.slice(sliceIndexes[0], sliceIndexes[1] + 1);

    this.table.selectedRows = !isToggleOn ? union(selectedRows, actionRows) : difference(selectedRows, actionRows);
  }

  @action.bound
  changeSelectedRows(selectedRows: number[]) {
    this.table.selectedRows.replace(selectedRows);
  }

  @action.bound
  submitSearchForm() {
    this.searchParams = cloneDeep(toJS(this.filters));

    this.syncDisabledFilters();
    this.getMarkets();
    this.sidebar.isOpen = false;
    this.sidebar.filterQuery = "";
    this.table.selectedRows.clear();
  }

  @action.bound
  syncDisabledFilters() {
    syncDisabledFilters(this.disabledFilters, this.searchParams, FILTERS_INIT);
  }

  @action.bound
  toggleFilter(filterKey: string) {
    toggleFilter(filterKey, this.filters, this.disabledFilters);

    this.getMarkets();
  }

  @action.bound
  toggleFilterValue(filterKey: string, filterValueToToggle: string | number) {
    toggleFilterValue(filterKey, filterValueToToggle, this.disabledFilters);

    this.getMarkets();
  }

  @action
  updateMarkets(params: MarketParams) {
    const { table } = this;
    const { analyst, region, subRegion } = params;
    const { label: analystName, value: analystId } = analyst;
    const { value: regionId, label: regionName } = region;
    const { value: subregionId, label: subRegionName } = subRegion;

    const selectedMarkets = table.selectedRows.map(selectedIndex => table.data[selectedIndex].rtMarket);
    const patchData = {
      ...(!isEmpty(table.selectedRows) && {
        rtMarkets: selectedMarkets
      }),
      ...(analystName && { analystId }),
      ...(regionName && { regionId }),
      ...(subRegionName && { subregionId })
    };
    api.updateMarkets(patchData).then(() => {
      const toastMsg =
        table.selectedRows.length === 1
          ? `1 Market Mapping has been updated`
          : `${table.selectedRows.length} Market Mappings have been updated`;
      AppToaster.show({
        intent: Intent.SUCCESS,
        message: toastMsg
      });
      this.table.selectedRows.clear();
      this.getMarkets();
    });
  }

  @action
  resetParams() {
    this.searchParams = { ...FILTERS_INIT };
  }

  @computed
  get isLoading() {
    const { table, status } = this;
    return table.status === Status.LOADING || status === Status.LOADING;
  }

  @computed
  get isLoaded() {
    const { status } = this;
    return status === Status.DONE;
  }

  @computed
  get isError() {
    const { table } = this;
    return table.status === Status.ERROR;
  }

  @computed
  get marketCount() {
    const { table } = this;
    return pluralize("Market", table.selectedRows.length, true);
  }

  @computed
  get decoratedPage() {
    const { changeFilter } = this;
    return {
      ...this,
      changeFilter
    };
  }

  @computed
  get normalizedFilters() {
    return getEnabledFilters(this.searchParams, this.disabledFilters, FILTERS_INIT, this.filtersEnabled);
  }
}
