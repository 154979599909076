import { AxiosResponse } from "axios";
import { action, computed, makeObservable, observable, toJS } from "mobx";
import isEmpty from "lodash.isempty";

import { Status } from "modules/App/Status";
import type { GetFlightsData } from "modules/FlightsTable/FlightsTable.types";
import type { FlightEntry, FlightsParams, FlightsResponse } from "types/Flights.types";
import type { TableSortType } from "types/Table.types";
import api, { isCancel } from "services/Api";

export class PivotTable {
  @observable data: FlightEntry[] = [];
  @observable footerData: object[] = [];
  @observable dataCount = {
    numberOfRows: 0,
    status: Status.INIT,
    totalNumberOfFlights: 0
  };
  @observable fixedColumns = {};
  @observable lastUpdated: string;

  @observable leftAggregation: string = "rtMarket";
  @observable rightAggregation: string = "depMonth";
  @observable selectedMetric: string = "xDayRevenuePotential";

  @observable boundaryValue: number = 0;
  @observable sortBy: TableSortType = { direction: "asc", field: this.leftAggregation };
  @observable status: Status = Status.INIT;

  constructor(pivotTable?: any) {
    makeObservable(this);
    if (pivotTable) {
      const pivotTableToMerge = pivotTable.toJSON ? pivotTable.toJSON() : pivotTable;
      Object.assign(this, pivotTableToMerge);
    }
  }

  @action.bound
  changeMetric(metricValue: string) {
    this.sortBy = { direction: "asc", field: this.leftAggregation };
    this.selectedMetric = metricValue;
  }

  @action.bound
  sortedChange(sortConfig, params) {
    const { desc, id: sortedMetric } = sortConfig[0];
    this.sortBy = {
      direction: desc ? "desc" : "asc",
      field: sortedMetric
    };
    if (
      sortedMetric === this.selectedMetric ||
      sortedMetric === this.leftAggregation ||
      sortedMetric === "xDayRevenuePotential"
    ) {
      this.fetchPivotTableData(params);
    } else {
      this.fetchDetailedPivotTableData(params);
    }
  }

  @action.bound
  swapAggregation() {
    const leftValue = this.leftAggregation;
    const rightValue = this.rightAggregation;

    this.sortBy = { direction: "asc", field: rightValue };

    this.leftAggregation = rightValue;
    this.rightAggregation = leftValue;
  }

  @action.bound
  changeAggregation(type: string, agg: string) {
    this.sortBy = { direction: "asc", field: this.leftAggregation };
    if (
      (type === "leftAggregation" && this.rightAggregation === agg) ||
      (type === "rightAggregation" && this.leftAggregation === agg)
    ) {
      this.swapAggregation();
    } else {
      this[type] = agg;
    }
  }

  @action
  createAggregationFilter(aggregationFilter: any[] | object, rows: any[] = []) {
    const sortedAggr = rows.map(el => el[this.leftAggregation]).sort();

    return Array.isArray(aggregationFilter) && !isEmpty(aggregationFilter)
      ? [...new Set([...aggregationFilter, ...sortedAggr])]
      : sortedAggr;
  }

  @action.bound
  fetchDetailedPivotTableData(
    params: GetFlightsData,
    apiIdentifier: string = "getPivotTable"
  ): Promise<void | AxiosResponse<FlightsResponse>> {
    const { conditionalFilters, filters, tabId, xDayBuild = 7 } = params;
    let sortingArray: object[] = [];
    this.status = Status.LOADING;

    this.data = [];
    this.footerData = [];
    this.dataCount = {
      numberOfRows: 0,
      status: Status.LOADING,
      totalNumberOfFlights: 0
    };

    const baseParams = {
      aggregations: [this.leftAggregation],
      conditionalFilters,
      filters,
      pagination: {
        offset: 0,
        size: 9999
      },
      sortBy: {
        direction: this.sortBy.direction,
        field: this.sortBy.field === this.leftAggregation ? this.leftAggregation : this.selectedMetric
      },
      xDayBuild
    };

    const sortedParams = {
      ...baseParams,
      columns: [this.leftAggregation],
      filters: {
        ...filters,
        ...this.extraFiltersFromSort
      }
    };

    const fetchFirstColumnParams: FlightsParams = {
      ...baseParams,
      columns: [this.leftAggregation]
    };

    const columnParams: FlightsParams = {
      ...baseParams,
      aggregations: [this.rightAggregation],
      columns: [this.rightAggregation, this.selectedMetric],
      pagination: {
        offset: 0,
        size: 9999
      },
      sortBy: {
        direction: this.sortBy.direction,
        field: this.selectedMetric
      },
      xDayBuild
    };

    const summaryParams: FlightsParams = {
      ...baseParams,
      columns: [this.leftAggregation, this.selectedMetric],
      filters,
      pagination: {
        offset: 0,
        size: 9999
      },
      sortBy: {
        direction: this.sortBy.direction,
        field: this.selectedMetric
      }
    };

    const dataPromise = api.getFlights(fetchFirstColumnParams, tabId, "getPivotTableColumns");
    const countPromise = api.getFlightsCount(baseParams, tabId, "getPivotTableCount");
    const columnSummaryPromise = api.getFlights(columnParams, tabId, "getColumnsSummaryPivot");
    const summaryRowPromise = api.getFlights(summaryParams, tabId, "getRowSummaryPivot");
    const sortedPromise = api.getFlights(sortedParams, tabId, "getPivotTableSorted");
    // Fetch First column values (left aggregation)
    return Promise.all([dataPromise, countPromise, columnSummaryPromise, summaryRowPromise, sortedPromise])
      .then(([dataResponse, countResponse, columnSummaryResponse, summaryRowResponse, sortedResponse]) => {
        const { rows } = dataResponse.data;
        const { numberOfRows, totalNumberOfFlights } = countResponse.data;
        const { rows: columnsSummaryRows } = columnSummaryResponse.data;
        const { rows: rowsSummary } = summaryRowResponse.data;
        const { rows: sortedRows } = sortedResponse.data;

        sortingArray = sortedRows.map(el => el[this.leftAggregation]);

        this.dataCount = {
          numberOfRows,
          status: Status.DONE,
          totalNumberOfFlights
        };

        this.footerData = [
          columnsSummaryRows.reduce((acc, item) => {
            return {
              ...acc,
              [item[this.rightAggregation]]: item[this.selectedMetric],
              [this.leftAggregation]: ""
            };
          }, {})
        ];

        // Extremes API
        const minimumValueParams: FlightsParams = {
          aggregations: [this.leftAggregation, this.rightAggregation],
          columns: [this.leftAggregation, this.rightAggregation, this.selectedMetric],
          conditionalFilters,
          filters,
          pagination: {
            offset: 0,
            size: 1
          },
          sortBy: {
            direction: "asc",
            field: this.selectedMetric
          },
          xDayBuild
        };

        const maximumValueParams: FlightsParams = {
          aggregations: [this.leftAggregation, this.rightAggregation],
          columns: [this.leftAggregation, this.rightAggregation, this.selectedMetric],
          conditionalFilters,
          filters,
          pagination: {
            offset: 0,
            size: 1
          },
          sortBy: {
            direction: "desc",
            field: this.selectedMetric
          },
          xDayBuild
        };

        const minimumPromise = api.getFlights(minimumValueParams, tabId, "minPivot");
        const maximumPromise = api.getFlights(maximumValueParams, tabId, "maxPivot");

        const fetchValuesParams: FlightsParams = {
          aggregations: [this.leftAggregation, this.rightAggregation],
          columns: [this.leftAggregation, this.rightAggregation, this.selectedMetric],
          conditionalFilters,
          filters: {
            ...filters,
            [this.leftAggregation]: this.createAggregationFilter(filters[this.leftAggregation], rows)
          },
          pagination: {
            offset: 0,
            size: 99999
          },
          sortBy: {
            direction: this.sortBy.direction,
            field: this.selectedMetric
          },
          xDayBuild
        };
        const columnValuesPromise = api.getFlights(fetchValuesParams, tabId, "valuesPivot");

        Promise.all([columnValuesPromise, minimumPromise, maximumPromise])
          .then(responses => {
            const [valuesPivotResponse, minResponse, maxResponse] = responses;

            this.boundaryValue =
              !isEmpty(minResponse.data.rows) && !isEmpty(maxResponse.data.rows)
                ? Math.max(
                    Math.abs(minResponse.data.rows[0][this.selectedMetric]),
                    Math.abs(maxResponse.data.rows[0][this.selectedMetric])
                  )
                : 0;
            const { rows, lastUpdated } = valuesPivotResponse.data;
            const pivotTableData = rows.reduce((accumulator, currentValue) => {
              const populatedItem = accumulator.find(item => {
                return item[this.leftAggregation] === currentValue[this.leftAggregation];
              });

              if (!populatedItem) {
                // @ts-ignore
                accumulator.push({
                  [this.leftAggregation]: currentValue[this.leftAggregation],
                  [currentValue[this.rightAggregation]]: currentValue[this.selectedMetric]
                });
                return accumulator;
              }
              return accumulator.map((item: object) => {
                if (item && item[this.leftAggregation] === currentValue[this.leftAggregation]) {
                  return {
                    ...item,
                    [currentValue[this.rightAggregation]]: currentValue[this.selectedMetric]
                  };
                } else {
                  return item;
                }
              });
            }, []);

            // @ts-ignore
            const allData = pivotTableData.map(el => {
              return {
                ...el,
                [this.selectedMetric]: rowsSummary.find(row => el[this.leftAggregation] === row[this.leftAggregation])
                  ? // @ts-ignore
                    rowsSummary.find(row => el[this.leftAggregation] === row[this.leftAggregation])[this.selectedMetric]
                  : null
              };
            });

            // @ts-ignore
            this.data = [
              ...allData
                .filter(el => sortingArray.indexOf(el[this.leftAggregation]) > -1)
                .sort(
                  (a, b) =>
                    sortingArray.indexOf(a[this.leftAggregation]) - sortingArray.indexOf(b[this.leftAggregation])
                ),
              ...allData.filter(el => sortingArray.indexOf(el[this.leftAggregation]) === -1)
            ];

            this.lastUpdated = lastUpdated;
            this.status = Status.DONE;
          })
          .catch(thrown => {
            if (isCancel(thrown)) {
              this.status = Status.LOADING;
              return;
            }
            this.status = Status.ERROR;
          });
        return;
      })
      .catch(thrown => {
        if (isCancel(thrown)) {
          this.status = Status.LOADING;
          return;
        }
        this.status = Status.ERROR;
      });
  }

  @action.bound
  fetchPivotTableData(
    params: GetFlightsData,
    apiIdentifier: string = "getPivotTable"
  ): Promise<void | AxiosResponse<FlightsResponse>> {
    const { conditionalFilters, filters, tabId, xDayBuild = 7 } = params;
    let sortingArray: object[] = [];
    this.status = Status.LOADING;

    this.data = [];
    this.footerData = [];
    this.dataCount = {
      numberOfRows: 0,
      status: Status.LOADING,
      totalNumberOfFlights: 0
    };

    const baseParams = {
      aggregations: [this.leftAggregation],
      conditionalFilters,
      filters,
      pagination: {
        offset: 0,
        size: 20
      },
      sortBy: {
        direction: this.sortBy.direction,
        field: this.sortBy.field === this.leftAggregation ? this.leftAggregation : this.selectedMetric
      },
      xDayBuild
    };

    const fetchFirstColumnParams: FlightsParams = {
      ...baseParams,
      columns: [this.leftAggregation]
    };

    const columnParams: FlightsParams = {
      ...baseParams,
      aggregations: [this.rightAggregation],
      columns: [this.rightAggregation, this.selectedMetric],
      pagination: {
        offset: 0,
        size: 9999
      },
      sortBy: {
        direction: this.sortBy.direction,
        field: this.selectedMetric
      },
      xDayBuild
    };

    const summaryParams: FlightsParams = {
      ...baseParams,
      columns: [this.leftAggregation, this.selectedMetric],
      filters,
      pagination: {
        offset: 0,
        size: 9999
      },
      sortBy: {
        direction: this.sortBy.direction,
        field: this.selectedMetric
      }
    };

    const dataPromise = api.getFlights(fetchFirstColumnParams, tabId, "getPivotTableColumns");
    const countPromise = api.getFlightsCount(baseParams, tabId, "getPivotTableCount");
    const columnSummaryPromise = api.getFlights(columnParams, tabId, "getColumnsSummaryPivot");
    const summaryRowPromise = api.getFlights(summaryParams, tabId, "getRowSummaryPivot");
    // Fetch First column values (left aggregation)
    return Promise.all([dataPromise, countPromise, columnSummaryPromise, summaryRowPromise])
      .then(([dataResponse, countResponse, columnSummaryResponse, summaryRowResponse]) => {
        const { rows } = dataResponse.data;
        const { numberOfRows, totalNumberOfFlights } = countResponse.data;
        const { rows: columnsSummaryRows } = columnSummaryResponse.data;
        const { rows: rowsSummary } = summaryRowResponse.data;

        sortingArray = rows.map(el => el[this.leftAggregation]);

        this.dataCount = {
          numberOfRows,
          status: Status.DONE,
          totalNumberOfFlights
        };

        this.footerData = [
          columnsSummaryRows.reduce((acc, item) => {
            return {
              ...acc,
              [item[this.rightAggregation]]: item[this.selectedMetric],
              [this.leftAggregation]: ""
            };
          }, {})
        ];

        // Extremes API
        const minimumValueParams: FlightsParams = {
          aggregations: [this.leftAggregation, this.rightAggregation],
          columns: [this.leftAggregation, this.rightAggregation, this.selectedMetric],
          conditionalFilters,
          filters,
          pagination: {
            offset: 0,
            size: 1
          },
          sortBy: {
            direction: "asc",
            field: this.selectedMetric
          },
          xDayBuild
        };

        const maximumValueParams: FlightsParams = {
          aggregations: [this.leftAggregation, this.rightAggregation],
          columns: [this.leftAggregation, this.rightAggregation, this.selectedMetric],
          conditionalFilters,
          filters,
          pagination: {
            offset: 0,
            size: 1
          },
          sortBy: {
            direction: "desc",
            field: this.selectedMetric
          },
          xDayBuild
        };

        const minimumPromise = api.getFlights(minimumValueParams, tabId, "minPivot");
        const maximumPromise = api.getFlights(maximumValueParams, tabId, "maxPivot");

        const fetchValuesParams: FlightsParams = {
          aggregations: [this.leftAggregation, this.rightAggregation],
          columns: [this.leftAggregation, this.rightAggregation, this.selectedMetric],
          conditionalFilters,
          filters: {
            ...filters,
            [this.leftAggregation]: this.createAggregationFilter(filters[this.leftAggregation], rows)
          },
          pagination: {
            offset: 0,
            size: 99999
          },
          sortBy: {
            direction: this.sortBy.direction,
            field: this.selectedMetric
          },
          xDayBuild
        };
        const columnValuesPromise = api.getFlights(fetchValuesParams, tabId, "valuesPivot");

        Promise.all([columnValuesPromise, minimumPromise, maximumPromise])
          .then(responses => {
            const [valuesPivotResponse, minResponse, maxResponse] = responses;

            this.boundaryValue =
              !isEmpty(minResponse.data.rows) && !isEmpty(maxResponse.data.rows)
                ? Math.max(
                    Math.abs(minResponse.data.rows[0][this.selectedMetric]),
                    Math.abs(maxResponse.data.rows[0][this.selectedMetric])
                  )
                : 0;
            const { rows, lastUpdated } = valuesPivotResponse.data;
            const pivotTableData = rows.reduce((accumulator, currentValue) => {
              const populatedItem = accumulator.find(item => {
                return item[this.leftAggregation] === currentValue[this.leftAggregation];
              });

              if (!populatedItem) {
                // @ts-ignore
                accumulator.push({
                  [this.leftAggregation]: currentValue[this.leftAggregation],
                  [currentValue[this.rightAggregation]]: currentValue[this.selectedMetric]
                });
                return accumulator;
              }
              return accumulator.map((item: object) => {
                if (item && item[this.leftAggregation] === currentValue[this.leftAggregation]) {
                  return {
                    ...item,
                    [currentValue[this.rightAggregation]]: currentValue[this.selectedMetric]
                  };
                } else {
                  return item;
                }
              });
            }, []);

            // @ts-ignore
            this.data = pivotTableData
              .map(el => {
                return {
                  ...el,
                  [this.selectedMetric]: rowsSummary.find(row => el[this.leftAggregation] === row[this.leftAggregation])
                    ? // @ts-ignore
                      rowsSummary.find(row => el[this.leftAggregation] === row[this.leftAggregation])[
                        this.selectedMetric
                      ]
                    : null
                };
              })
              .slice()
              .sort(
                (a, b) => sortingArray.indexOf(a[this.leftAggregation]) - sortingArray.indexOf(b[this.leftAggregation])
              );

            this.lastUpdated = lastUpdated;
            this.status = Status.DONE;
          })
          .catch(thrown => {
            if (isCancel(thrown)) {
              this.status = Status.LOADING;
              return;
            }
            this.status = Status.ERROR;
          });
        return;
      })
      .catch(thrown => {
        if (isCancel(thrown)) {
          this.status = Status.LOADING;
          return;
        }
        this.status = Status.ERROR;
      });
  }

  @action.bound
  fetchMorePivotTableData(params: GetFlightsData): Promise<void | AxiosResponse<FlightsResponse>> {
    const { conditionalFilters, filters, tabId, xDayBuild = 7 } = params;
    let sortingArray: string[] = [];

    const baseParams = {
      aggregations: [this.leftAggregation],
      conditionalFilters,
      filters,
      pagination: {
        offset: this.data.length,
        size:
          this.dataCount.numberOfRows > 20 && this.dataCount.numberOfRows < this.data.length + 20
            ? this.dataCount.numberOfRows - this.data.length + 1
            : 20
      },
      sortBy: {
        direction: this.sortBy.direction,
        field: this.sortBy.field === this.leftAggregation ? this.leftAggregation : this.selectedMetric
      },
      xDayBuild
    };

    const fetchFirstColumnParams: FlightsParams = {
      ...baseParams,
      columns: [this.leftAggregation]
    };

    const summaryParams: FlightsParams = {
      ...baseParams,
      columns: [this.leftAggregation, this.selectedMetric],
      pagination: {
        offset: 0,
        size: 9999
      },
      sortBy: {
        direction: this.sortBy.direction,
        field: this.selectedMetric
      }
    };

    const dataPromise = api.getFlights(fetchFirstColumnParams, tabId, "getPivotTableColumns");
    const countPromise = api.getFlightsCount(baseParams, tabId, "getPivotTableCount");
    const summaryRowPromise = api.getFlights(summaryParams, tabId, "getRowSummaryPivot");
    // Fetch First column values (left aggregation)
    return Promise.all([dataPromise, countPromise, summaryRowPromise])
      .then(([dataResponse, countResponse, summaryRowResponse]) => {
        const { rows } = dataResponse.data;
        const { numberOfRows, totalNumberOfFlights } = countResponse.data;
        const { rows: rowsSummary } = summaryRowResponse.data;
        sortingArray = rows.map(el => el[this.leftAggregation]);
        this.dataCount = {
          numberOfRows,
          status: Status.DONE,
          totalNumberOfFlights
        };

        const fetchValuesParams: FlightsParams = {
          aggregations: [this.leftAggregation, this.rightAggregation],
          columns: [this.leftAggregation, this.rightAggregation, this.selectedMetric],
          conditionalFilters,
          filters: {
            ...filters,
            [this.leftAggregation]: this.createAggregationFilter(filters[this.leftAggregation], rows)
          },
          pagination: {
            offset: 0,
            size: 99999
          },
          sortBy: {
            direction: this.sortBy.direction,
            field: this.sortBy.field === this.leftAggregation ? this.leftAggregation : this.selectedMetric
          },
          xDayBuild
        };
        const columnValuesPromise = api.getFlights(fetchValuesParams, tabId, "valuesPivot");

        Promise.all([columnValuesPromise])
          .then(responses => {
            const [valuesPivotResponse] = responses;

            const { rows, lastUpdated } = valuesPivotResponse.data;
            const pivotTableData = rows.reduce((accumulator, currentValue) => {
              const populatedItem = accumulator.find(item => {
                return item[this.leftAggregation] === currentValue[this.leftAggregation];
              });

              if (!populatedItem) {
                // @ts-ignore
                accumulator.push({
                  [this.leftAggregation]: currentValue[this.leftAggregation],
                  [currentValue[this.rightAggregation]]: currentValue[this.selectedMetric]
                });
                return accumulator;
              }
              return accumulator.map((item: object) => {
                if (item && item[this.leftAggregation] === currentValue[this.leftAggregation]) {
                  return {
                    ...item,
                    [currentValue[this.rightAggregation]]: currentValue[this.selectedMetric]
                  };
                } else {
                  return item;
                }
              });
            }, []);

            const sortedRowsLeftColumns = [...this.data.map(el => el[this.leftAggregation]), ...sortingArray];

            const sumOfData = [
              ...this.data,
              ...pivotTableData
                .map(el => ({
                  ...el,
                  [this.selectedMetric]: rowsSummary.find(row => el[this.leftAggregation] === row[this.leftAggregation])
                    ? // @ts-ignore
                      rowsSummary.find(row => el[this.leftAggregation] === row[this.leftAggregation])[
                        this.selectedMetric
                      ]
                    : null
                }))
                .slice()
                .sort(
                  (a, b) =>
                    sortedRowsLeftColumns.indexOf(a[this.leftAggregation]) -
                    sortedRowsLeftColumns.indexOf(b[this.leftAggregation])
                )
            ];

            // @ts-ignore
            this.data = sumOfData;
            this.lastUpdated = lastUpdated;
            this.status = Status.DONE;
          })
          .catch(thrown => {
            if (isCancel(thrown)) {
              this.status = Status.LOADING;
              return;
            }
            this.status = Status.ERROR;
          });
        return;
      })
      .catch(thrown => {
        if (isCancel(thrown)) {
          this.status = Status.LOADING;
          return;
        }
        this.status = Status.ERROR;
      });
  }

  toJSON(): PivotTable {
    return toJS(this);
  }

  @computed
  get extraFiltersFromSort() {
    if (this.sortBy.field === "null") {
      return {
        [this.rightAggregation]: [null]
      };
    }
    return this.sortBy.field === this.leftAggregation ||
      this.sortBy.field === this.selectedMetric ||
      this.sortBy.field === "xDayRevenuePotential"
      ? {}
      : {
          [this.rightAggregation]: [!isNaN(Number(this.sortBy.field)) ? Number(this.sortBy.field) : this.sortBy.field]
        };
  }
}
