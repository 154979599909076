import React from "react";
import { Redirect } from "react-router-dom";

import Share from "components/Share.js";
import RootStore from "modules/App/Root.model.js";
import AdminTools, { ADMIN_TOOLS_ROUTES } from "views/AdminTools";
import Analysis from "views/Analysis";
import Dashboard from "views/Dashboard";
import EditAnalysis from "views/EditAnalysis";
import EventsManagement from "views/EventsManagement";
import InfluenceEdit from "modules/Influence/InfluenceEdit/InfluenceEdit";
import InfluenceHistory from "modules/InfluenceHistory/InfluenceHistory";
import Users from "views/Users";
import { ApplicationRoute } from "./routes.types";

const baseRoutes: ApplicationRoute[] = [
  {
    component: Dashboard,
    path: "/dashboard"
  },
  {
    component: EditAnalysis,
    path: "/editing-team-analysis"
  },
  {
    component: EventsManagement,
    path: "/events-management"
  },
  {
    component: Analysis,
    path: "/analysis/:tabId/explore"
  },
  {
    component: Analysis,
    path: "/analysis/:tabId/influence"
  },
  {
    component: Analysis,
    path: "/analysis/:tabId/capacity"
  },
  {
    component: InfluenceEdit,
    path: "/influence/:influenceId/edit"
  },
  {
    component: InfluenceHistory,
    path: "/influence-history"
  },

  {
    component: Share,
    path: "/share/:shareId"
  },
  {
    path: "/user-management",
    render: (rootStore: RootStore) => (rootStore.appStore.auth.isAdmin ? <Users /> : <Redirect to="/dashboard" />)
  },
  {
    path: ADMIN_TOOLS_ROUTES,
    render: (rootStore: RootStore) => (rootStore.appStore.auth.isAdmin ? <AdminTools /> : <Redirect to="/dashboard" />)
  },
  {
    path: "/",
    render: () => <Redirect to="/dashboard" />
  }
];

export default baseRoutes;
