// @flow

import React, { useState } from "react";
import { observer } from "mobx-react";
import { Button, Classes, ControlGroup, Dialog, FormGroup, HTMLSelect, Intent } from "@blueprintjs/core";
import styled from "@emotion/styled";
import passwordGenerator from "generate-password";
import { useStores } from "../../store/Store";

const FormGroupStyled = styled("div")`
  .bp3-form-group {
    .bp3-label {
      min-width: 150px;
    }
    .bp3-form-content {
      flex-grow: 1;
    }
  }
`;

const DialogFooter = styled("div")`
  box-shadow: 0 -1px 0 rgba(16, 22, 26, 0.15);
`;

type Props = {
  userGroups: object[],
  addUser: (user: object) => void
};

function AddUserModal(props: Props) {
  const { modalStore } = useStores();
  const { userGroups = [], addUser } = props;
  const { data: users } = modalStore;

  const rolesOptions = userGroups.map(group => group.name);

  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");
  const [role, setRole] = useState(rolesOptions[0]);
  const usersMailList = users?.map(user => user.email);
  const userExists = usersMailList?.includes(email);

  const submitForm = (event?: Event) => {
    if (event) {
      event.preventDefault();
    }
    modalStore.closeModal();

    const password = passwordGenerator.generate({
      length: 16,
      lowercase: true,
      numbers: true,
      strict: true,
      symbols: true,
      uppercase: true
    });
    addUser({
      email,
      group: role,
      name: fullName,
      password,
      tabs: []
    });
  };

  const onEmailChange = event => {
    const value = event.target.value || "";
    setEmail(value.toLowerCase());
  };

  const emailHelperText = userExists ? "Email is already in use" : "";
  const emailIntent = userExists ? Intent.DANGER : Intent.NONE;

  return (
    <>
      <Dialog
        className="p-0"
        isOpen={modalStore.modalActive === "addUser"}
        onClose={modalStore.closeModal}
        title="Invite User"
      >
        <form className="p-0" data-testid="add-user-modal" onSubmit={(event: Event) => submitForm(event)}>
          <div className={`${Classes.DIALOG_BODY} m-0 p-3`}>
            <FormGroupStyled className="d-flex flex-column w-100">
              <FormGroup className="font-weight-bold mb-4" label="Full name" labelFor="name-input">
                <input
                  autoFocus
                  className={`w-100 ${Classes.INPUT}`}
                  id="name-input"
                  onChange={e => setFullName(e.target.value)}
                  placeholder="Enter full name"
                  required
                  type="text"
                  value={fullName}
                />
              </FormGroup>
              <FormGroup
                className="font-weight-bold mb-4"
                helperText={emailHelperText}
                intent={emailIntent}
                label="Email"
                labelFor="email-input"
              >
                <input
                  className={`w-100 ${Classes.INPUT}`}
                  id="email-input"
                  onChange={onEmailChange}
                  placeholder="Enter email"
                  required
                  type="email"
                  value={email}
                />
              </FormGroup>
              <FormGroup className="font-weight-bold mb-4" label="Role" labelFor="role-input">
                <ControlGroup>
                  <HTMLSelect
                    onChange={e => setRole(e.currentTarget.value)}
                    options={rolesOptions}
                    required
                    value={role}
                  />
                </ControlGroup>
              </FormGroup>
            </FormGroupStyled>
          </div>
          <DialogFooter className={`${Classes.DIALOG_FOOTER} mx-0 px-3 py-2`}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button data-testid="invite-user-button" disabled={userExists} intent="primary" type="submit">
                Invite
              </Button>
            </div>
          </DialogFooter>
        </form>
      </Dialog>
    </>
  );
}

export default observer(AddUserModal);
