import { CapacityAdjustmentData, CapacityItem } from "./Capacity.types";

export const capacityItemsList: CapacityItem[] = [
  { key: "overbooking", name: "Manual Overbooking" },
  { disabled: true, key: "liddedCap", name: "Lidded Capacity Adjustment" }
];

export const STATE_INIT = {
  step: 1,
  type: ""
};

export const DATA_INIT: CapacityAdjustmentData = {
  base: { aggregated: undefined, rows: undefined },
  preview: { aggregated: undefined, rows: undefined }
};
