import React, { useLayoutEffect, useRef } from "react";
import { observer } from "mobx-react";
import { useStores } from "store/Store";

import FilterGroups from "shared/components/Sidebar/FilterGroups/FilterGroups";
import SearchInput from "shared/components/SearchInput/SearchInput";
import Sidebar from "shared/components/Sidebar/Sidebar";
import { influenceHistoryMetrics } from "modules/InfluenceHistory/influenceHistoryMetrics/influenceHistoryMetrics";
import focusOnFilter from "shared/helpers/focusOnFilter/focusOnFilter";
import SidebarApplyButton from "shared/components/Sidebar/SidebarApplyButton/SidebarApplyButton";
import { FILTERS_INIT } from "modules/InfluenceHistory/InfluenceHistory.utils";
import InfluenceHistoryMarketFilters from "../InfluenceHistoryMarketFilters/InfluenceHistoryMarketFilters";
import InfluenceHistoryFlightFilters from "../InfluenceHistoryFlightFilters/InfluenceHistoryFlightFilters";
import InfluenceHistoryDepartureDateFilters from "../InfluenceHistoryDepartureDateFilters/InfluenceHistoryDepartureDateFilters";
import InfluenceHistoryInfluenceFilters from "../InfluenceHistoryInfluenceFilters/InfluenceHistoryInfluenceFilters";

const componentsMap = {
  "dep-date": InfluenceHistoryDepartureDateFilters,
  flight: InfluenceHistoryFlightFilters,
  influence: InfluenceHistoryInfluenceFilters,
  market: InfluenceHistoryMarketFilters
};

function InfluenceHistorySidebar() {
  const { analysisMappingsStore, influenceHistoryStore, systemSettingsStore } = useStores();
  const { computedDateFormat, computedDateMonthFormat, isMiles } = systemSettingsStore;

  const { decoratedPage, page, setSidebarFilterQuery, setSidebarOpen, submitSearchForm } = influenceHistoryStore;
  const { isOpen, filterQuery, filterKey } = page.sidebar;

  const sidebarRef = useRef(null);

  useLayoutEffect(() => {
    focusOnFilter(filterKey, filterQuery, sidebarRef);
  }, [filterKey, filterQuery]);

  const onSubmit = () => {
    setSidebarOpen(false);
    setSidebarFilterQuery("");
    submitSearchForm();
  };

  const groupsWithComponents = influenceHistoryMetrics.map(metricGroup => {
    return { ...metricGroup, Component: componentsMap[metricGroup.key] };
  });

  return (
    <Sidebar
      ref={sidebarRef}
      footer={<SidebarApplyButton initialFilters={FILTERS_INIT} onSubmit={onSubmit} pageContext={decoratedPage} />}
      isOpen={isOpen}
      setOpen={(value: boolean) => setSidebarOpen(value)}
    >
      <SearchInput onChange={setSidebarFilterQuery} value={filterQuery} />
      <FilterGroups
        computedDateFormat={computedDateFormat}
        computedDateMonthFormat={computedDateMonthFormat}
        filterGroups={groupsWithComponents}
        filterQuery={filterQuery}
        isMiles={isMiles}
        mappings={analysisMappingsStore}
        pageContext={decoratedPage}
      />
    </Sidebar>
  );
}

export default observer(InfluenceHistorySidebar);
