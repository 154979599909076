// @flow

import React, { useEffect, useRef, forwardRef } from "react";

type Props = {
  indeterminate: boolean
};

const IndeterminateCheckbox = forwardRef(({ indeterminate, ...rest }: Props, ref) => {
  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return <input ref={resolvedRef} type="checkbox" {...rest} data-testid="row-checkbox" />;
});

export default IndeterminateCheckbox;
