import pluralize from "pluralize";

type Props = {
  type: string;
  value: number | string;
};

export const valueWithLimits = ({ value }: Props) => {
  if (Array.isArray(value)) {
    const valueSize = value.length;

    return pluralize("limit", valueSize, true);
  }
  return `${value}`;
};
