import React from "react";
import { Button } from "@blueprintjs/core";
import { observer } from "mobx-react";

const GraphResetZoom = ({ isBuildCurvesZoomed, changeSelectedRange, range, isLoading, isGraphTimelineVisible }) => {
  const isResetZoomButtonVisible = !isLoading && isBuildCurvesZoomed && isGraphTimelineVisible;

  const onClick = () => changeSelectedRange(range);

  return isResetZoomButtonVisible && <Button data-testid="reset-zoom-button" onClick={onClick} text="Reset Zoom" />;
};

export default observer(GraphResetZoom);
