// @flow

import React, { useMemo } from "react";
import styled from "@emotion/styled";
import { Button, Switch, Tag } from "@blueprintjs/core";
import { css, Global } from "@emotion/react";
import { format, parseISO } from "date-fns";
import { observer } from "mobx-react";
import pluralize from "pluralize";

import columnTextWidths from "shared/helpers/columnTextWidths/columnTextWidths";
import DataTable from "shared/components/DataTable/DataTable";
import Module from "shared/components/Module/Module";
import { categories } from "modules/Events/categories";
import { Status } from "modules/App/Status";
import { useStores } from "store/Store";
import ListCellRenderer from "shared/components/ListCellRenderer/ListCellRenderer.tsx";

const StyledSwitch = styled(Switch)`
  margin-bottom: 0;
`;

const formatDate = (date, afterDate, dateFormat, extraFormat = "") =>
  date && format(parseISO(`${date}${afterDate}`), `${dateFormat}${extraFormat}`);

export const columnLabels = {
  categoryName: "Category",
  endDate: "End date",
  id: "ID",
  locations: "Location(s)",
  name: "Name",
  nonWorkingDay: "Public Holiday",
  provenances: "Provenance(s)",
  startDate: "Start date",
  updatedBy: "Analyst",
  updatedOn: "Last modified"
};

const extraTagColors = Object.values(categories).map(
  category => `.color-${category.key} { background-color: ${category.color} };`
);

function EventsManagementTable() {
  const { appStore, eventsManagementStore, systemSettingsStore, modalStore } = useStores();
  const { computedDateFormat } = systemSettingsStore;
  const { user = {} } = appStore.auth;
  const { deleteEvents, page } = eventsManagementStore;
  const { data, selectedRows, status, pagination } = page.eventsTable;

  const isUserAllowedToModify = ["Admin", "Analyst"].includes(user.group);
  const eventsCountText = pluralize("event", selectedRows.length, true);
  const eventsText = page.upcomingEvents ? "upcoming event" : "event";
  const isLoaded = status === Status.DONE;
  const subtitle = isLoaded ? pluralize(eventsText, pagination.totalRows, true) : null;

  const switchButton = (
    <StyledSwitch
      checked={page.upcomingEvents}
      className="mr-2"
      data-testid="switch-events"
      onChange={eventsManagementStore.toggleUpcomingEvents}
    >
      Show upcoming events only
    </StyledSwitch>
  );

  const deleteEventButton = (
    <Button
      className="mr-2"
      data-testid="delete-events-button"
      disabled={!selectedRows.length}
      icon="trash"
      onClick={() => modalStore.setModal("deleteEvents", { deleteEvents, selectedRows })}
    >
      Delete ({eventsCountText})
    </Button>
  );

  const editEventButton = (
    <Button
      className="mr-2"
      data-testid="edit-event-button"
      disabled={selectedRows.length !== 1}
      icon="edit"
      onClick={() => modalStore.setModal("editEvent")}
    >
      Edit Event
    </Button>
  );

  const addEventButton = (
    <Button
      className="mr-2"
      data-testid="add-events-button"
      icon="plus"
      intent="primary"
      onClick={() => modalStore.setModal("addEvent")}
    >
      Add Event
    </Button>
  );

  const disabledRows = [];

  const renderLocations = (
    value: { countries: Object[] | null, metros: Object[] | null, airports: string[] | null } | null
  ) => {
    if (value === null) return "Global";
    const { countries = [], metros = [], airports = [] } = value;

    const sortedCountries = countries.map(country => country.code).sort();
    const sortedMetros = metros.map(metro => metro.code).sort();
    const sortedAirports = airports.slice().sort();
    const locations = [...sortedCountries, ...sortedMetros, ...sortedAirports];

    return <ListCellRenderer list={locations} />;
  };

  const renderTitleActions = () =>
    isUserAllowedToModify &&
    isLoaded && (
      <div className="d-flex align-items-center">
        {switchButton}
        {editEventButton}
        {deleteEventButton}
        {addEventButton}
      </div>
    );

  const columnTextWidth = useMemo(() => columnTextWidths(columnLabels), []);

  const tableProps = {
    ...page.eventsTable,
    cellRenderers: {
      // eslint-disable-next-line react/prop-types
      categoryName: ({ row: { original } }: Cell) => (
        <Tag className={`color-${categories[original?.categoryId]?.key}`}>{original?.categoryName}</Tag>
      ),
      endDate: ({ value }) => formatDate(value, "T00:00:00", computedDateFormat),
      id: ({ value }) => `E-${value}`,
      locations: ({ value }) => renderLocations(value),
      nonWorkingDay: ({ value }) => (value ? `Yes` : `No`),
      provenances: ({ value }) => renderLocations(value),
      startDate: ({ value }) => formatDate(value, "T00:00:00", computedDateFormat),
      updatedOn: ({ value }) => formatDate(value, "Z", computedDateFormat, " HH:mm:ss")
    },
    columnAccessors: {
      endDate: "dateRange.end",
      startDate: "dateRange.start"
    },
    columnConfig: {
      columnsAligned: { id: "left" }
    },
    columnLabels,
    columnSortType: {
      id: null,
      locations: null,
      provenances: null
    },
    columnTextWidth,
    columnWidths: { id: 60, locations: 128, provenances: 128 },
    computedDateFormat,
    deleteEvents: params => eventsManagementStore.deleteEvents(params),
    disabledRows,
    fetchData: params => eventsManagementStore.getEventsData(params),
    isGroupColumn: true,
    isUserAllowedToModify,
    onRowToggle: (selectedRows: Array<string>) => eventsManagementStore.updateSelectedRows(selectedRows),
    onShiftToggle: eventsManagementStore.shiftToggleRows,
    setModal: modalStore.setModal
  };

  return (
    <Module
      childrenClassName="d-flex flex-column h-100"
      className="events-management-table flex-shrink-1"
      minHeight={data.length > 7 ? 315 : 0}
      subtitle={[subtitle && `· ${subtitle}`]}
      title="Holidays & Special Events"
      titleTools={renderTitleActions()}
    >
      <Global
        styles={css`
          .events-management-table thead tr:first-of-type {
            display: none !important;
          }
          td[data-testid="data-table-cell-categoryName"] {
            padding-bottom: 1px !important;
            padding-top: 1px !important;
          }
          ${extraTagColors}
        `}
      />
      <DataTable {...tableProps} />
    </Module>
  );
}

export default observer(EventsManagementTable);
