import cloneDeep from "lodash.clonedeep";
import { action, computed, makeObservable, observable, toJS } from "mobx";
import { Intent } from "@blueprintjs/core";
import keyBy from "lodash.keyby";
import isEqual from "lodash.isequal";
import prepareStructure from "shared/helpers/prepareStructure/prepareStructure";

import type { RootStore } from "modules/App/Root.model";
import { Tab } from "./Tab/Tab.model";
import { filterPersistentTab, TAB_INIT, createTabForTemplate, TabStruct } from "./Tab/Tab.utils";
import { MAX_TAB_COUNT } from "modules/App/constants";
import api from "services/Api";
import { AppToaster } from "services/Toaster";
import copyToClipboard from "shared/helpers/copyToClipboard/copyToClipboard";
import type { SharedAnalysis } from "types/Tab.types";
import { NavigationBlocker } from "shared/helpers/navigationBlocker/navigationBlocker";

const generateRandomId = () => Math.random().toString(36).substr(2, 9);

// eslint-disable-next-line no-restricted-globals
const generateShareLink = shareId => `${location.protocol}//${location.host}/share/${shareId}`;

const DEFAULT_CABIN_CLASS = ["Y"];

const isTemplateNotChanged = (currentView, initialTemplateView) => {
  const conditionalFiltersIsEqual = isEqual(
    toJS(currentView.applied.conditionalFilters),
    toJS(initialTemplateView.applied.conditionalFilters)
  );
  const filtersIsEqual = isEqual(toJS(currentView.applied.filters), toJS(initialTemplateView.applied.filters));
  const cabinClass = isEqual(currentView.buildCurves.cabinClass, initialTemplateView.buildCurves.cabinClass);
  const groupStatuses = isEqual(currentView.buildCurves.groupStatuses, initialTemplateView.buildCurves.groupStatuses);
  const series = isEqual(
    currentView.buildCurves.series.filter(([, value]) => value.length),
    initialTemplateView.buildCurves.series.filter(([, value]) => value.length)
  );
  const aggregations = isEqual(currentView.flightsTable.aggregations, initialTemplateView.flightsTable.aggregations);
  const columns = isEqual(
    currentView.flightsTable.columns.filter(([, key]) => key.length),
    initialTemplateView.flightsTable.columns.filter(([, key]) => key.length)
  );
  const sortBy = isEqual(currentView.flightsTable.sortBy, initialTemplateView.flightsTable.sortBy);
  const fixedColumns = isEqual(currentView.flightsTable.fixedColumns, initialTemplateView.flightsTable.fixedColumns);
  const xDayBuild = isEqual(currentView.xDayBuild, initialTemplateView.xDayBuild);
  const analystMapping = isEqual(currentView.analystMappingEnabled, initialTemplateView.analystMappingEnabled);

  return (
    analystMapping &&
    aggregations &&
    cabinClass &&
    columns &&
    conditionalFiltersIsEqual &&
    filtersIsEqual &&
    fixedColumns &&
    groupStatuses &&
    series &&
    sortBy &&
    xDayBuild
  );
};

export class TabsStore {
  @observable
  tabs: Tab[] = [];

  rootStore: RootStore;

  constructor(rootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  get todayDate() {
    return this.rootStore.timeStore?.todayDate;
  }

  getTab(tabId: string): Tab {
    return this.tabs.find(tab => tab.id === tabId);
  }

  @action
  initTabs(tabs: Array<TabStruct>) {
    this.tabs = tabs.filter(tab => !tab.editAnalysis).map(tab => new Tab(this, tab));
  }

  @action.bound
  defaultNewTab() {
    const filtersState = this.createFiltersState();

    return {
      ...cloneDeep(TAB_INIT),
      ...filtersState,
      id: generateRandomId(),
      label: `Analysis ${this.tabs.length + 1}`
    };
  }

  @action.bound
  addTab(customConfig = {}) {
    if (!this.canCreateTab) return;

    const newTab = {
      ...this.defaultNewTab(),
      ...customConfig
    };

    this.tabs.push(new Tab(this, newTab));
    this.saveTabs();
  }

  @action
  saveAsTeamAnalysis(tabId) {
    const tab = this.tabsById[tabId];
    const {
      departureDateExtremes,
      duplicatedFromSavedId,
      id,
      label,
      parentId,
      parentType,
      rootStore,
      savedViewId,
      sidebar,
      tabsStore,
      ...template
    } = filterPersistentTab(tab);

    api
      .createTeamAnalysis({ label, template })
      .then(({ data }) => {
        AppToaster.show({
          intent: Intent.SUCCESS,
          message: `'${data.label}' successfully saved as team analysis`
        });
        this.rootStore.templatesStore.getAllTemplates();
      })
      .catch(error => {
        if (error?.response?.status === 409) {
          const message = `Team analysis with name '${tab.label}' already exists`;
          AppToaster.show({ intent: "danger", message });
        }
      });
  }

  @action.bound
  duplicateTab(tabId: string) {
    const tab = this.tabsById[tabId];
    if (!tab) return;

    const newTab = {
      ...tab.toJSON(),
      duplicatedFromSavedId: tab.parentId,
      id: generateRandomId(),
      label: `Copy of ${tab.label}`,
      parentId: null
    };

    const insertIndex = this.tabs.indexOf(tab) + 1;
    this.tabs.splice(insertIndex, 0, new Tab(this, newTab));
    this.saveTabs();
  }

  @action.bound
  createTabFromSavedAnalysis(mySavedAnalysis: Object, type: string) {
    if (!this.canCreateTab) return;

    const { id, label, view } = toJS(mySavedAnalysis);
    const savedAnalysisView = {
      ...view,
      parentId: id,
      parentType: type
    };
    const filtersState = this.createFiltersState(savedAnalysisView);

    const normalizedTab = prepareStructure({
      object: {
        ...savedAnalysisView,
        ...filtersState,
        id: generateRandomId(),
        label
      },
      template: TAB_INIT
    });

    const newTab = filterPersistentTab(normalizedTab);

    this.tabs.push(new Tab(this, newTab));
    this.saveTabs();
  }

  @action
  createTabFromSavedAnalysisForEditing(mySavedAnalysis: Object, type: string) {
    const { id, label, view } = toJS(mySavedAnalysis);
    const newTab = filterPersistentTab({
      ...view,
      editAnalysis: true,
      id,
      label,
      parentId: id,
      parentType: type
    });
    this.tabs.push(new Tab(this, newTab));
  }

  @action
  createTabFromSharedAnalysis(analysis: SharedAnalysis) {
    if (!this.canCreateTab) return;

    const newTab = {
      ...cloneDeep(TAB_INIT),
      ...analysis,
      conditionalFilters: analysis.applied.conditionalFilters,
      filters: analysis.applied.filters,
      id: generateRandomId(),
      label: `Analysis ${this.tabs.length + 1}`
    };

    this.tabs.push(new Tab(this, newTab));
    this.saveTabs();
  }

  @action
  removeTab(tabId: string) {
    this.tabs = this.tabs.filter(tab => tab.id !== tabId);
    this.saveTabs();
  }

  @action
  removeEditAnalysisTab() {
    const tabs = this.tabs.filter(tab => !tab.editAnalysis);
    this.tabs = tabs;
    this.rootStore.appStore.auth.user.tabs = tabs;
  }

  @action
  saveTabs() {
    this.rootStore.appStore.auth.editCurrentUser({
      tabs: this.tabs.filter(tab => !tab.editAnalysis).map(filterPersistentTab)
    });
  }

  @action
  flushTabs() {
    this.tabs.forEach(tab => tab.flushTab());
  }

  @computed
  get lastTab() {
    return this.tabs[this.tabs.length - 1];
  }

  @computed
  get lastTabId() {
    return this.lastTab.id;
  }

  @computed
  get tabsById() {
    return keyBy(this.tabs, "id");
  }

  createFiltersState(template: ?TabStruct) {
    const { userId } = this.rootStore.appStore;
    const isAnalystWithMarkets = Boolean(this.rootStore.analysisMappingsStore.analystGroupedByUserId[userId]);

    const filters = {
      ...(template ? template.filters : TAB_INIT.filters),
      ...(!template && { cabinClass: DEFAULT_CABIN_CLASS })
    };
    const conditionalFilters = template ? template.conditionalFilters : TAB_INIT.conditionalFilters;

    const { analystMappingEnabled, parentType, parentId } = template || TAB_INIT;
    const isTabWithDefaultCurrentUserId = filters.analystId?.includes(-1);

    const isTabCreatedFromScratch = parentType === null && parentId === null && isAnalystWithMarkets;
    const isTabCreatedByTeamAnalysis = ["team"].includes(parentType) && parentId && analystMappingEnabled;

    if (isTabWithDefaultCurrentUserId || isTabCreatedFromScratch || isTabCreatedByTeamAnalysis) {
      filters.analystId = [userId];
    }

    return {
      applied: { conditionalFilters, filters },
      conditionalFilters,
      filters
    };
  }

  createSharedAnalysis(tab: Tab): SharedAnalysis {
    return {
      applied: tab.applied,
      buildCurves: {
        series: tab.buildCurves.series
      },
      disabledFilters: tab.disabledFilters,
      filtersEnabled: tab.filtersEnabled,
      flightsTable: {
        aggregations: tab.flightsTable.aggregations,
        columns: tab.flightsTable.columns,
        fixedColumns: tab.flightsTable.fixedColumns,
        groupStatuses: tab.flightsTable.groupStatuses,
        pagination: {
          pageSize: tab.flightsTable.pagination.pageSize
        },
        sortBy: tab.flightsTable.sortBy
      },
      pivotTable: tab.pivotTable,
      sidebar: tab.sidebar
    };
  }

  @action
  share(tabId: string) {
    const tab = this.getTab(tabId);
    const analysis = this.createSharedAnalysis(tab);

    return api.shareAnalysis(analysis).then(response => {
      const shareLink = generateShareLink(response.data.shareId);

      copyToClipboard(shareLink);

      AppToaster.show({
        intent: Intent.SUCCESS,
        message: `The link has been copied to the clipboard`
      });
    });
  }

  @action
  getSharedAnalysis(shareId: string) {
    return api.getSharedAnalysis(shareId);
  }

  get canCreateTab() {
    return this.tabs.length < MAX_TAB_COUNT;
  }

  @action
  createTabForInfluenceEdit(influenceId: string) {
    const newTab = {
      ...cloneDeep(TAB_INIT),
      applied: {
        filters: {
          adjustmentId: [influenceId]
        }
      },
      editInfluence: true,
      id: influenceId,
      label: influenceId,
      state: {
        dirty: true
      }
    };

    const influenceTab = this.getTab(influenceId);

    if (!influenceTab) {
      this.tabs.push(new Tab(this, newTab));
    }
  }

  @computed
  get isEditingTemplate() {
    return this.lastTab?.editAnalysis;
  }

  @computed
  get isEditingTemplateChanged() {
    if (this.lastTab?.editAnalysis) {
      const { label } = this.lastTab;
      const tabTemplate = createTabForTemplate(this.lastTab);

      const {
        label: initialTemplateLabel,
        view: initialTemplateView
      } = this.rootStore.templatesStore.teamAnalyses.find(({ id }) => id === this.lastTabId);
      const initialView = createTabForTemplate(prepareStructure({ object: initialTemplateView, template: TAB_INIT }));

      const labelsAreEqual = label === initialTemplateLabel;
      return !(isTemplateNotChanged(tabTemplate, initialView) && labelsAreEqual);
    }
    return false;
  }

  shouldConfirmLeavingTab(shouldBlock: boolean, callback: Function) {
    if (shouldBlock) {
      this.rootStore.modalStore.setModal("confirmation", {
        action() {
          NavigationBlocker.unblock();
          callback();
        },
        buttonText: "Quit",
        header: "You have unsaved changes",
        text: "Switching tabs will discard all unsaved changes. You won’t be able to restore your work.",
        title: "Quit without saving?"
      });
    } else {
      callback();
    }
  }
}
