import { Card, ControlGroup, Icon } from "@blueprintjs/core";
import { observer } from "mobx-react";
import React from "react";
import Row from "shared/components/Row/Row";
import { StyledButtonIcon, StyledInfluenceInput } from "modules/Influence/InfluenceStyles";
import { useStores } from "../../../store/Store";

function CapacityStepSet() {
  const { capacityStore } = useStores();

  const onValueChange = (numberValue: number, stringValue: string) => {
    const value = numberValue.toString() !== stringValue || stringValue === "" ? stringValue : numberValue;
    capacityStore.changeCapacityValue(value);
  };

  return (
    <>
      <Card className="p-3 mb-2">
        <p className="bp3-text-muted bp3-text-small">
          Overbooking defines number of ticketed seats on all selected flights.
          <a href="https://help.cirrus.ai/en/articles/5560848-influence-manual-overbooking">Read more</a>
        </p>
        <Row>
          <div className="col-6">
            <h4 className="m-0 mb-2">Overbooking</h4>
            <ControlGroup className="align-items-center">
              <StyledButtonIcon disabled={capacityStore.isAdjustmentLoading}>
                <Icon icon="percentage" />
              </StyledButtonIcon>
              <StyledInfluenceInput
                clampValueOnBlur
                data-testid="capacity-percent-input"
                disabled={capacityStore.isAdjustmentLoading}
                fill
                max={100}
                min={0}
                onValueChange={onValueChange}
                placeholder="0"
                value={capacityStore.capacityValue}
              />
            </ControlGroup>
          </div>
        </Row>
      </Card>
    </>
  );
}

export default observer(CapacityStepSet);
