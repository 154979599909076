// @flow

import { action, computed, makeObservable, observable, runInAction } from "mobx";
import { computedFn } from "mobx-utils";
import isEmpty from "lodash.isempty";
import uniqBy from "lodash.uniqby";
import keyBy from "lodash.keyby";
import uniq from "lodash.uniq";

import api from "services/Api";
import type { PageContextFilters } from "types/Flights.types";
import type { RootStore } from "modules/App/Root.model";
import { changeUserSortOrder } from "shared/helpers/changeUserSortOrder/changeUserSortOrder";
import { LazyValue } from "shared/components/LazyValue/LazyValue";
import { Status } from "modules/App/Status";

export const getUniqueAndSortedOWMarkets = (flights: Array<string>) => {
  return uniq(flights.map(flight => flight.owMarket)).sort();
};

const naturalSort = (a, b) => a.localeCompare(b, undefined, { numeric: true });

const flipMarket = (market: string): string => `${market.slice(3)}${market.slice(0, 3)}`;

const prepareFlightNumbers = (flights: Array = []): Array<string> => {
  const flightNumbers = flights.flatMap(flight => flight.flightNumbers);
  return uniq(flightNumbers).sort(naturalSort);
};

const uniqAndSort = array => uniq(array).sort();

const toOrigin = market => market.slice(0, 3);

const toDestination = market => market.slice(3);

export class AnalysisMappingsStore {
  @observable status = Status.INIT;
  @observable analystStatus = Status.INIT;
  @observable data = {};
  @observable analystData = {};

  allFlightNumbers = new LazyValue<Array<string>>(() => prepareFlightNumbers(this.data.flights));
  rootStore: RootStore;

  constructor(rootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  @action
  getMappings() {
    this.status = Status.LOADING;
    this.allFlightNumbers.reset();

    return api
      .getExploreMappings()
      .then(response => {
        runInAction(() => {
          this.data = response.data;
          this.status = Status.DONE;
        });
      })
      .catch(() => {
        this.status = Status.ERROR;
      });
  }

  @action.bound
  getMarketAnalyst() {
    this.analystStatus = Status.LOADING;
    return api
      .getMarkets()
      .then(response => {
        this.analystData = response.data;
        this.analystStatus = Status.DONE;
        return response.data;
      })
      .catch(() => {
        this.analystStatus = Status.ERROR;
      });
  }

  @action
  getFlightNumbers = computedFn((filters: PageContextFilters | null): Array<string> => {
    const { flights = [] } = this.data;
    const { owMarket = [], rtMarket = [], origin = [], destination = [] } = { ...filters };
    const filtersEmpty = [owMarket, rtMarket, origin, destination].every(isEmpty);

    if (filters === null) {
      return this.allFlightNumbers.value;
    }

    if (filtersEmpty) {
      return [];
    }

    const matchers = this.createFlightMatchers(filters);
    const filteredFlights = flights.filter(({ owMarket }) => matchers.some(matcher => matcher(owMarket)));

    return prepareFlightNumbers(filteredFlights);
  }, true);

  getRoundTripMarkets = computedFn((filters: PageContextFilters | null): string[] => {
    const allMarkets = this.analystGroupedRtMarkets;
    const { rows = [] } = this.analystData;
    const { owMarket = [], origin = [], destination = [], regionId = [], subregionId = [] } = { ...filters };
    const allFiltersEmpty = [owMarket, origin, destination, regionId, subregionId].every(isEmpty);

    if (filters === null) {
      return allMarkets;
    }

    if (allFiltersEmpty) {
      return [];
    }

    const allowedMarkets = owMarket.flatMap(market => [market, flipMarket(market)]);

    const byOwMarkets = allMarkets.filter(market => allowedMarkets.includes(market));
    const byOrigins = allMarkets.filter(market => origin.some(origin => market.includes(origin)));
    const byDestinations = allMarkets.filter(market => destination.some(destination => market.includes(destination)));

    const regionMatchingMappings = rows.filter(mapping => regionId.includes(mapping.regionId));
    const byRegions = allMarkets.filter(market => regionMatchingMappings.some(mapping => mapping.rtMarket === market));

    const subRegionMatchingMappings = rows.filter(mapping => subregionId.includes(mapping.subregionId));
    const bySubregions = allMarkets.filter(market =>
      subRegionMatchingMappings.some(mapping => mapping.rtMarket === market)
    );

    const rtMarkets = [...byOwMarkets, ...byOrigins, ...byDestinations, ...byRegions, ...bySubregions];

    return uniqAndSort(rtMarkets);
  }, true);

  getOneWayMarkets = computedFn((filters: PageContextFilters | null): string[] => {
    const { flights = [] } = this.data;
    const { rows = [] } = this.analystData;
    const { rtMarket = [], origin = [], destination = [], regionId = [], subregionId = [] } = { ...filters };

    const allMarkets = getUniqueAndSortedOWMarkets(flights);
    const allFiltersEmpty = [rtMarket, origin, destination, regionId, subregionId].every(isEmpty);

    if (filters === null) {
      return allMarkets;
    }

    if (allFiltersEmpty) {
      return [];
    }

    const allowedMarkets = rtMarket.flatMap(market => [market, flipMarket(market)]);

    const byRtMarkets = allMarkets.filter(market => allowedMarkets.includes(market));
    const byOrigins = allMarkets.filter(market => origin.some(origin => toOrigin(market).includes(origin)));
    const byDestinations = allMarkets.filter(market =>
      destination.some(destination => toDestination(market).includes(destination))
    );

    const regionMatchingMappings = rows.filter(mapping => regionId.includes(mapping.regionId));
    const byRegions = allMarkets.filter(market =>
      regionMatchingMappings.some(mapping => mapping.rtMarket === market || flipMarket(mapping.rtMarket) === market)
    );

    const subRegionMatchingMappings = rows.filter(mapping => subregionId.includes(mapping.subregionId));
    const bySubregions = allMarkets.filter(market =>
      subRegionMatchingMappings.some(mapping => mapping.rtMarket === market || flipMarket(mapping.rtMarket) === market)
    );

    const owMarkets = [...byRtMarkets, ...byOrigins, ...byDestinations, ...byRegions, ...bySubregions];

    return uniqAndSort(owMarkets);
  }, true);

  getOrigins = computedFn((filters: PageContextFilters | null): string[] => {
    const { flights = [] } = this.data;
    const { rows = [] } = this.analystData;

    const { rtMarket = [], owMarket = [], destination = [], regionId = [], subregionId = [] } = { ...filters };

    if (filters === null) {
      const allFlights = flights.map(flight => toDestination(flight.owMarket));
      return uniqAndSort(allFlights);
    }

    const byDestinations = flights.filter(flight => destination.includes(toDestination(flight.owMarket)));
    const byOwMarkets = flights.filter(flight => owMarket.includes(flight.owMarket));
    const byRtMarkets = this.getFlightsByMarkets(rtMarket, owMarket);
    const regionMatchingMappings = rows.filter(mapping => regionId.includes(mapping.regionId));
    const byRegions = flights.filter(flight =>
      regionMatchingMappings.some(mapping => mapping.rtMarket === flight.owMarket)
    );

    const subRegionMatchingMappings = rows.filter(mapping => subregionId.includes(mapping.subregionId));

    const bySubregions = flights.filter(flight =>
      subRegionMatchingMappings.some(mapping => mapping.rtMarket === flight.owMarket)
    );

    const origins = [
      ...byDestinations.map(flight => toOrigin(flight.owMarket)),
      ...byOwMarkets.map(flight => toOrigin(flight.owMarket)),
      ...byRtMarkets.map(toOrigin),
      ...byRegions.map(flight => toOrigin(flight.owMarket)),
      ...bySubregions.map(flight => toOrigin(flight.owMarket))
    ];

    return uniqAndSort(origins);
  }, true);

  getDestinations = computedFn((filters: PageContextFilters | null): string[] => {
    const { flights = [] } = this.data;
    const { rows = [] } = this.analystData;

    const { rtMarket = [], owMarket = [], origin = [], regionId = [], subregionId = [] } = { ...filters };

    if (filters === null) {
      const allFlights = flights.map(flight => toOrigin(flight.owMarket));
      return uniqAndSort(allFlights);
    }

    const byOrigin = flights.filter(flight => origin.includes(toOrigin(flight.owMarket)));
    const byOwMarkets = flights.filter(flight => owMarket.includes(flight.owMarket));
    const byRtMarkets = this.getFlightsByMarkets(rtMarket, owMarket);

    const regionMatchingMappings = rows.filter(mapping => regionId.includes(mapping.regionId));
    const byRegions = flights.filter(flight =>
      regionMatchingMappings.some(mapping => mapping.rtMarket === flight.owMarket)
    );

    const subRegionMatchingMappings = rows.filter(mapping => subregionId.includes(mapping.subregionId));

    const bySubregions = flights.filter(flight =>
      subRegionMatchingMappings.some(mapping => mapping.rtMarket === flight.owMarket)
    );

    const destinations = [
      ...byOrigin.map(flight => toDestination(flight.owMarket)),
      ...byOwMarkets.map(flight => toDestination(flight.owMarket)),
      ...byRtMarkets.map(toDestination),
      ...byRegions.map(flight => toDestination(flight.owMarket)),
      ...bySubregions.map(flight => toDestination(flight.owMarket))
    ];

    return uniqAndSort(destinations);
  }, true);

  getCabinClasses = computedFn(() => {
    const { cabinClassMappings = [] } = this.data;

    return cabinClassMappings.flatMap(({ cabinClass }) => cabinClass).filter(Boolean);
  });

  getBookingClassesByCabinClass = computedFn((cabinClasses: string[] | null) => {
    if (cabinClasses === null) {
      return this.getBookingClasses();
    }
    return this.getBookingClasses(cabinClasses);
  });

  getBookingClasses(selectedCabinClasses: string[]) {
    return this.data.cabinClassMappings
      ?.flatMap(cabinClassMapping => {
        if (!selectedCabinClasses || selectedCabinClasses.includes(cabinClassMapping.cabinClass)) {
          return cabinClassMapping.bookingClasses;
        }
        return [];
      })
      .filter(Boolean);
  }

  @computed
  get isLoading() {
    return this.status === Status.LOADING;
  }

  @computed
  get isError() {
    return this.status === Status.ERROR;
  }

  @computed
  get analystUsers() {
    const analystData = this.analystData && this.analystData.rows;
    if (!analystData) {
      return [];
    }

    const uniqUsers = uniqBy(analystData, "analystId").map(({ analystId: value, analystName: label }) => ({
      label: label === null ? "Unassigned" : label,
      value
    }));
    const undefinedUsers = uniqUsers.find(user => user.value === null) || {
      label: "Unassigned",
      value: null
    };

    const sortedUsers = uniqUsers
      .filter(user => user.value !== null)
      .sort((user1, user2) => user1.label.localeCompare(user2.label));

    const currentUserId = this.rootStore?.appStore?.userId;

    return changeUserSortOrder([undefinedUsers, ...sortedUsers], currentUserId);
  }

  @computed
  get isAnalystLoading() {
    return this.analystStatus === Status.LOADING;
  }

  @computed
  get isAnalystDone() {
    return this.analystStatus === Status.DONE;
  }

  @computed
  get analystGroupedByUserId() {
    return keyBy(this.analystUsers, "value");
  }

  @computed
  get analystGroupedRtMarkets() {
    const analystData = this.analystData && this.analystData.rows;

    if (!analystData) {
      return [];
    }

    return uniqBy(analystData, "rtMarket")
      .map(user => user.rtMarket)
      .filter(user => user !== null)
      .sort();
  }

  @computed
  get quarterItems() {
    if (!this.data.quarter) {
      return [];
    }

    return this.data.quarter.map(singleQuarter => {
      return {
        label: `${singleQuarter.substring(0, 4)} ${singleQuarter.substring(4)}`,
        value: singleQuarter
      };
    });
  }

  getFlightsByMarkets(rtMarket, owMarket) {
    const { flights = [] } = this.data;
    const allMarkets = getUniqueAndSortedOWMarkets(flights);
    const allowedMarkets = rtMarket.flatMap(market => [market, flipMarket(market)]);

    return allMarkets
      .filter(market => allowedMarkets.includes(market))
      .filter(market => !owMarket.includes(flipMarket(market)));
  }

  createFlightMatchers(filters) {
    const { owMarket = [], rtMarket = [], origin = [], destination = [] } = { ...filters };
    const matchesOwMarkets = market => owMarket.includes(market);
    const matchesRtMarkets = market => rtMarket.includes(market) || rtMarket.includes(flipMarket(market));
    const matchesOrigin = market => origin.includes(toOrigin(market));
    const matchesDestination = market => destination.includes(toDestination(market));

    const matchesOriginAndDestination = market => matchesOrigin(market) && matchesDestination(market);
    const matchesOnlyOrigin = market => matchesOrigin(market) && isEmpty(destination);
    const matchesOnlyDestination = market => matchesDestination(market) && isEmpty(origin);

    return [matchesOwMarkets, matchesRtMarkets, matchesOnlyOrigin, matchesOnlyDestination, matchesOriginAndDestination];
  }
}
