import { action, computed, makeObservable, observable } from "mobx";
import cloneDeep from "lodash.clonedeep";

import { AdjustmentState, AdjustmentType, CapacityAdjustmentData, AdjustmentLoadingStatus } from "./Capacity.types";
import { DATA_INIT, STATE_INIT } from "./Capacity.utils";

export class CapacityStore {
  @observable adjustmentComment: string = "";
  @observable inputValue;
  @observable data: CapacityAdjustmentData = cloneDeep(DATA_INIT);
  @observable status: AdjustmentLoadingStatus = AdjustmentLoadingStatus.INIT;
  @observable state: AdjustmentState = cloneDeep(STATE_INIT);

  constructor() {
    makeObservable(this);
  }

  @action.bound
  changeStep(step: number) {
    this.state.step += step;
    if (this.state.step === 1) {
      this.resetAdjustment();
    }
  }

  @action.bound
  changeCapacityValue(value: number | string) {
    this.inputValue = value;
  }

  @action.bound
  changeAdjustmentComment(comment: string) {
    this.adjustmentComment = comment;
  }

  @action.bound
  startAdjustment(adjustmentType: AdjustmentType) {
    this.state = { step: 2, type: adjustmentType };
  }

  @action.bound
  resetAdjustment() {
    this.state = cloneDeep(STATE_INIT);
    this.data = cloneDeep(DATA_INIT);
    this.status = AdjustmentLoadingStatus.INIT;
    this.inputValue = undefined;
  }

  @action
  getBasePreview() {
    this.status = AdjustmentLoadingStatus.LOADING_BASE;
    // TODO connect endpoint
    setTimeout(() => {
      this.data.base = {
        aggregated: {
          overbookingPercent: 300,
          overbookingSeats: 200,
          sellableCapacity: 100
        }
      };
      this.status = AdjustmentLoadingStatus.DONE_BASE;
    }, 500);
  }

  @computed
  get canContinueAdjustment() {
    switch (this.state.step) {
      case 2: {
        return this.inputValue !== undefined && this.inputValue !== "";
      }
      case 3: {
        return this.adjustmentComment !== "";
      }
      default:
        return false;
    }
  }

  @computed
  get isPreviewActive() {
    return this.state.step > 1;
  }

  @computed
  get formattedValue(): string {
    return this.state.type === "overbooking" ? `${this.inputValue}%` : this.inputValue;
  }

  @computed
  get isAdjustmentLoading() {
    return this.status.includes(AdjustmentLoadingStatus.LOADING);
  }
}
