import { observer } from "mobx-react";
import React, { useEffect } from "react";

import { useCurrentTab } from "shared/helpers/useCurrentTab/useCurrentTab";
import { useStores } from "store/Store";
import Module from "shared/components/Module/Module";
import NumberOfFlights from "shared/components/NumberOfFlights/NumberOfFlights";
import CapacityControlNavigation from "../CapacityControlNavigation/CapacityControlNavigation";
import CapacityStepSave from "../CapacityStepSave/CapacityStepSave";
import CapacityStepSelection from "../CapacityStepSelection/CapacityStepSelection";
import CapacityStepSet from "../CapacityStepSet/CapacityStepSet";

function CapacityControls() {
  const { capacityStore } = useStores();
  const tab = useCurrentTab();

  useEffect(() => {
    return () => {
      capacityStore.resetAdjustment();
    };
  }, []);

  return (
    <Module
      childrenClassName="flex-grow-1"
      className="h-100"
      footer={<CapacityControlNavigation />}
      subtitle={[
        <NumberOfFlights
          flightsCount={tab.flightsCount}
          flightsTable={tab.flightsTable}
          options={{ showSelected: true }}
          selectedRowsNumberOfFlights={tab.selectedRowsNumberOfFlights}
        />
      ]}
      title="Capacity Controls"
    >
      <div className="p-2">
        {capacityStore.state.step === 1 && <CapacityStepSelection />}
        {capacityStore.state.step === 2 && <CapacityStepSet />}
        {capacityStore.state.step === 3 && <CapacityStepSave />}
      </div>
    </Module>
  );
}

export default observer(CapacityControls);
