import React from "react";
import { AnchorButton, Popover, PopoverInteractionKind, Position } from "@blueprintjs/core";
import { observer } from "mobx-react";
import groupBy from "lodash.groupby";
import isEmpty from "lodash.isempty";
import styled from "@emotion/styled";
import pluralize from "pluralize";

import StatusIndicator from "shared/components/StatusIndicator/StatusIndicator";
import { useStores } from "store/Store";
import { CirrusDefaultMarketStore } from "models/Cirrus/CirrusDefaultMarket.model";
import { CirrusODDatesStore } from "models/Cirrus/CirrusODDates.model";

const values = ["on", "off"] as const;

type StatusValues = typeof values;

type StatusButtonProps = {
  status: StatusValues[number];
};

const ButtonLabelStyled = styled("span")`
  color: #000 !important;
`;

const statusesMap = {
  off: "disabled",
  on: "enabled"
};

type Props = {
  cirrusStoreContext: CirrusDefaultMarketStore | CirrusODDatesStore;
  noFiltersAllowed?: boolean;
  unit?: string;
};

function CirrusOnOffButtons({ cirrusStoreContext, noFiltersAllowed = false, unit = "OD Date" }: Props) {
  const { modalStore } = useStores();
  const { page } = cirrusStoreContext;

  const disabled = noFiltersAllowed ? false : isEmpty(cirrusStoreContext.normalizedFilters);

  const { dataStats, selectedRows } = page.table;
  const nothingIsSelected = isEmpty(selectedRows);

  const groupedFlights = groupBy(selectedRows, flightId => (flightId.includes("_on") ? "on" : "off"));

  const flightsCountByStatus = (status: string, switchStatus?: boolean) => {
    let accessor = status;
    if (switchStatus) {
      accessor = status === "on" ? "off" : "on";
    }
    if (nothingIsSelected) {
      return disabled ? 0 : dataStats[statusesMap[accessor]];
    }
    return groupedFlights[accessor] ? groupedFlights[accessor].length : 0;
  };

  const shouldBeDisabled = status => {
    if (flightsCountByStatus(status, true) === 0) {
      return true;
    }
    if (nothingIsSelected) {
      return disabled;
    }
    return false;
  };

  const shouldHavePopover = status => {
    return disabled && nothingIsSelected && flightsCountByStatus(status, true) === 0;
  };

  const showConfirmationModal = (status: "on" | "off") => {
    const changeStatusAction = () => {
      const newStatus = status === "on";
      if (!isEmpty(page.table.selectedRows)) {
        const rowIds = page.table.selectedRows.map(rowId => Number(rowId?.split("_")[0])).filter(Boolean);
        cirrusStoreContext.modifyStatusByIds(rowIds, newStatus);
      } else {
        cirrusStoreContext.modifyStatus(newStatus);
      }
    };

    const flightsNumber = flightsCountByStatus(status, true);
    const modalConfig = {
      action: changeStatusAction,
      buttonText: "Confirm",
      header: (
        <span>
          You are going to change the Cirrus Status to {status.toUpperCase()} for {pluralize(unit, flightsNumber, true)}{" "}
          {pluralize("record", flightsNumber)}.
        </span>
      ),
      text: "Please confirm this action.",
      title: "Cirrus Status change"
    };

    modalStore.setModal("confirmation", modalConfig);
  };

  const StatusButton = (props: StatusButtonProps) => {
    const { status } = props;

    const switchText = nothingIsSelected ? `Turn all ${status}` : `Turn ${status}`;
    const flightsCountText = `(${pluralize(unit, flightsCountByStatus(status, true), true)})`;

    return (
      <AnchorButton
        key={`turn-${status}-button`}
        className="ml-2"
        data-testid={`turn-${status}-button`}
        disabled={shouldBeDisabled(status)}
        onClick={() => showConfirmationModal(status)}
      >
        <StatusIndicator
          isActive={status === "on"}
          text={
            <ButtonLabelStyled>
              <strong>{switchText}</strong> {flightsCountText}
            </ButtonLabelStyled>
          }
        />
      </AnchorButton>
    );
  };

  return (
    <>
      {values.map(status => {
        if (shouldHavePopover(status)) {
          return (
            <Popover
              key={`button-popover-${status}`}
              content={<div>Apply filters to enable bulk functionality.</div>}
              hoverCloseDelay={0}
              hoverOpenDelay={0}
              interactionKind={PopoverInteractionKind.HOVER_TARGET_ONLY}
              popoverClassName="bp3-popover-content-sizing"
              position={Position.BOTTOM}
            >
              <StatusButton status={status} />
            </Popover>
          );
        }
        return <StatusButton key={`status-button-${status}`} status={status} />;
      })}
    </>
  );
}

export default observer(CirrusOnOffButtons);
