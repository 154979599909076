import React, { useEffect } from "react";
import { observer } from "mobx-react";

import SidebarSingleSelect from "shared/components/Sidebar/SidebarSingleSelect/SidebarSingleSelect";
import { useStores } from "store/Store";
import { OnChange, SelectItem } from "types/MarketMappings.types";

type Props = {
  onChange: OnChange;
  selectedRegion: SelectItem | null;
  selectedSubregion: SelectItem | null;
};

const unassignedItem = { label: "Unassigned", value: null };

function MarketMappingsRegionSelect(props: Props) {
  const { onChange, selectedRegion, selectedSubregion } = props;
  const { regionsStore } = useStores();
  const regionsToSelect = regionsStore.getRegionsBySubregionId();

  const regionItems = [
    unassignedItem,
    ...regionsToSelect.map(({ regionId, regionName }) => ({
      label: regionName,
      value: regionId
    }))
  ];

  useEffect(() => {
    if (selectedSubregion?.value) {
      const regionToSelect = regionsStore.getRegionsBySubregionId(selectedSubregion.value)?.[0];
      onChange({ label: regionToSelect.regionName, value: regionToSelect.regionId });
    }
  }, [selectedSubregion]);

  return (
    <div className="d-flex">
      <SidebarSingleSelect
        items={regionItems}
        selectedItem={selectedRegion}
        selectItem={item => onChange(item)}
        title="Region"
      />
    </div>
  );
}

export default observer(MarketMappingsRegionSelect);
