import React from "react";
import clsx from "clsx";
import { AnchorButton, Tooltip } from "@blueprintjs/core";
import { observer } from "mobx-react";
import isEmpty from "lodash.isempty";

import { useStores } from "store/Store";
import { TOP_BAR_TOOLTIP_LABELS } from "models/Dashboard/Dashboard.utils";

type Props = {
  view: {
    key: string;
    label: string;
  };
};

function SwitchButton({ view: { key, label } }: Props) {
  const { dashboardStore } = useStores();
  const { assignedMarkets } = dashboardStore;

  const disabled = isEmpty(assignedMarkets) && key === "my-markets";
  const tooltipText = disabled ? TOP_BAR_TOOLTIP_LABELS.disabled : TOP_BAR_TOOLTIP_LABELS[key];

  const isActive = dashboardStore.activePage === key;

  const changePage = page => () => dashboardStore.changePage(page);

  return (
    <Tooltip key={key} boundary="viewport" content={tooltipText} hoverOpenDelay={1000}>
      <AnchorButton
        className={clsx(
          {
            "bp3-active": isActive
          },
          "d-flex align-items-center bp3-button"
        )}
        data-testid={`dashboard-top-bar-${key}`}
        disabled={disabled}
        onClick={changePage(key)}
      >
        {label}
      </AnchorButton>
    </Tooltip>
  );
}

export default observer(SwitchButton);
