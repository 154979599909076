import React from "react";
import { ButtonGroup } from "@blueprintjs/core";

import SwitchButton from "./SwitchButton";
import { DASHBOARD_VIEWS } from "models/Dashboard/Dashboard.utils";

function SwitchButtons() {
  return (
    <ButtonGroup className="mr-2">
      {DASHBOARD_VIEWS.map(view => (
        <SwitchButton key={view.key} view={view} />
      ))}
    </ButtonGroup>
  );
}

export default SwitchButtons;
