import { Icon, Colors } from "@blueprintjs/core";
import styled from "@emotion/styled";
import isEmpty from "lodash.isempty";
import { observer } from "mobx-react";
import React from "react";
import { NdoInputValueWithValidation } from "../PriceLimits/PriceLimits.types";

import { errorMessages } from "../PriceLimits/validation";

const StyledMinMaxError = styled("div")`
  color: ${Colors.RED3};
`;

type Props = {
  inputValueWithValidation: NdoInputValueWithValidation;
  errorFields: string[];
};

function PriceLimitsErrorMessage({ inputValueWithValidation, errorFields }: Props) {
  const { endIsHigher, endNdoOverlaps, startNdoOverlaps, startNdoIsTooHigh } = inputValueWithValidation;
  const isOverlapping = endNdoOverlaps || startNdoOverlaps;
  const isStartTooHigh = !isOverlapping && startNdoIsTooHigh;

  if (!(isOverlapping || isStartTooHigh || endIsHigher) && isEmpty(errorFields)) {
    return null;
  }

  let message = errorMessages.overlappingNdoRanges;

  if (isStartTooHigh) {
    message = errorMessages.NDORangesExceeds500;
  }
  if (endIsHigher) {
    message = errorMessages.endIsHigher;
  }
  if (errorFields.length) {
    message = errorMessages.contradictedValues;
  }

  return (
    <StyledMinMaxError
      className="d-flex flex-row bp3-intent-danger bp3-text-small mt-3"
      data-testid="minmax-limits-info-error"
    >
      <Icon className="mr-1" icon="error" />
      {message}
    </StyledMinMaxError>
  );
}

export default observer(PriceLimitsErrorMessage);
