import { makeObservable, observable, action } from "mobx";

import { Status } from "modules/App/Status";
import api from "services/Api";

export class CapacityHistoryStore {
  @observable status: Status = Status.INIT;
  @observable table = {
    data: [],
    pagination: { pageCount: 1, pageIndex: 0, pageSize: 25, totalRows: 0 },
    sortBy: { direction: "desc", field: "appliedDatetime" }
  };

  constructor() {
    makeObservable(this);
  }

  @action.bound
  getCapacityHistoryData() {
    this.status = Status.LOADING;
    api.getCapacityHistoryData().then(({ data }) => {
      this.table.data = data;
      this.status = Status.DONE;
    });
  }
}
