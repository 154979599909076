import { Colors, Intent, Tag } from "@blueprintjs/core";
import styled from "@emotion/styled";
import clsx from "clsx";
import React from "react";

import SidebarSelect from "shared/components/Sidebar/SidebarSelect/SidebarSelect";

const StyledSelect = styled(SidebarSelect)`
  &.is-provenance-type-off .bp3-tag {
    background-color: ${Colors.LIGHT_GRAY2};
    color: ${Colors.GRAY3};
  }
`;

const StyledChips = styled(Tag)`
  background-color: ${Colors.LIGHT_GRAY3} !important;
  margin-right: 7px;
`;

export const selectTextDictionary = {
  airport: { placeholder: "airports", title: "Airport" },
  country: { placeholder: "countries", title: "Country" },
  metro: { placeholder: "metro", title: "Metro" }
};

const filterItems = (query, item) => {
  const normalizedQuery = query.toLowerCase();
  const normalizedTitle = `${item.label}${item.value}`.toLowerCase();

  return normalizedTitle.includes(normalizedQuery);
};

const sortItems = (items, listProps) => {
  const query = listProps.query.toUpperCase();
  items.sort((a, b) => {
    if (query === a.value && query !== b.value) {
      return -1;
    }
    if (query !== a.value && query === b.value) {
      return 1;
    }
    return 0;
  });
};

const renderTagName = ({
  items,
  isCollection,
  selectedItems,
  tag,
  withIATACode
}: {
  isCollection: boolean;
  items: Array<{ label: string; value: string }> | any[];
  selectedItems: Array<{ label: string; value: string; code: string } | any>;
  tag: string;
  withIATACode?: boolean;
}) => {
  if (isCollection) {
    const availableItem = items.find(item => (item?.value || item) === tag);
    const legacyItem = selectedItems
      .filter(item => item?.code || item === tag)
      .map(item => ({ label: item.name || item, value: item.code || item }));

    const foundItem: any = availableItem || legacyItem.pop() || {};
    return (
      <>
        {withIATACode && <StyledChips minimal>{foundItem.value}</StyledChips>}
        {foundItem.label}
      </>
    );
  }

  return tag;
};

const renderSelect = ({
  conflictList,
  isDisabled,
  isDisabledGlobally,
  items,
  obsoletePlaces,
  onChange,
  parent,
  selectedItems,
  type,
  withIATACode,
  withTitle = true
}: {
  conflictList?: number[];
  isDisabled?: boolean;
  isDisabledGlobally?: boolean;
  items: string[] | Array<{ label: string; value: string }> | any[];
  obsoletePlaces: string[];
  onChange: Function;
  parent: string;
  selectedItems: string[] | any[];
  type: string;
  withIATACode?: boolean;
  withTitle?: boolean;
}) => {
  const title = withTitle && selectTextDictionary[type]?.title;
  const isCollection = items?.every(item => typeof item === "object" && !item.groupItems);

  const selectedItemsCode =
    selectedItems?.length && typeof selectedItems[0] === "object" && selectedItems[0] !== null
      ? selectedItems.map(item => item.code)
      : selectedItems;

  const getTagIntent = (node: React.ReactNode, index: number): Object => {
    const value = selectedItemsCode[index];
    const isObsolete = obsoletePlaces?.includes(value);
    const isConflict = conflictList?.includes(index);

    return {
      intent: isConflict || isObsolete ? Intent.WARNING : Intent.NONE
    };
  };

  const itemRenderer = (item: any | string, itemProps: any) => {
    const { handleClick, query, modifiers } = itemProps;
    const text = typeof item === "object" ? item.label : item;
    const key = typeof item === "object" ? item.value : item;
    const iataCode = withIATACode && typeof item === "object" && item.value;

    const queriedCode =
      withIATACode && query
        ? iataCode.replace(new RegExp(query, "ui"), match => `<strong>${match}</strong>`)
        : iataCode;

    const queriedText = query ? text.replace(new RegExp(query, "ui"), match => `<strong>${match}</strong>`) : text;

    const itemClassName = clsx("bp3-menu-item", {
      "bp3-active bp3-intent-primary": modifiers.active
    });

    return (
      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
      <li key={key} className={itemClassName} onClick={handleClick}>
        {iataCode && (
          <Tag>
            {/* eslint-disable-next-line react/no-danger */}
            <span dangerouslySetInnerHTML={{ __html: queriedCode }} />
          </Tag>
        )}

        <div
          className="bp3-text-overflow-ellipsis bp3-fill"
          dangerouslySetInnerHTML={{ __html: queriedText }} // eslint-disable-line react/no-danger
        />
      </li>
    );
  };

  return (
    <StyledSelect
      className={isDisabled ? "is-provenance-type-off" : ""}
      dataTestId={`event-select-${parent}-${type}`}
      filterItems={filterItems}
      fuzzySearch
      itemRenderer={itemRenderer}
      items={items}
      onChange={onChange}
      placeholder={`Search ${selectTextDictionary[type]?.placeholder}`}
      popoverProps={{
        boundary: "viewport",
        popoverClassName: "-event-modal"
      }}
      portalClassName={`event-select-${parent}-${type}`}
      renderTagName={tag => renderTagName({ isCollection, items, selectedItems, tag, withIATACode })}
      selectedItems={selectedItemsCode}
      sortFn={sortItems}
      tagInputProps={{
        disabled: isDisabledGlobally,
        inputProps: { autoComplete: "one-time-code" }, // hack for chrome autofill https://stackoverflow.com/a/65773709
        tagProps: getTagIntent
      }}
      title={title}
    />
  );
};

export default renderSelect;
