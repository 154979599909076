import styled from "@emotion/styled";
import { Button, Colors, Menu, MenuItem } from "@blueprintjs/core";
import { css, Global } from "@emotion/react";
import React from "react";

export const StyledMenu = styled(Menu)`
  max-width: 180px;
  max-height: 350px !important;
`;

export const SelectButton = styled(Button)`
  max-width: 130px;
  width: auto;
  .bp3-button-text {
    display: flex;
    flex: 1 1 auto;
    justify-content: space-between;
  }
  .bp3-icon {
    margin: 0 0 0 4px !important;
  }
  .bp3-button-label {
    display: inline-block;
    vertical-align: middle;
    max-width: 90px;
  }
`;

export const ApplyButton = styled(MenuItem)`
  bottom: 5px;
  left: 5px;
  right: 5px;
`;

export const selectStyle = (
  <Global
    styles={css`
      .bp3-popover {
        background-color: ${Colors.WHITE};
      }
      .bp3-popover .bp3-popover-content {
        height: auto !important;
      }
    `}
  />
);
