import React from "react";
import { observer } from "mobx-react";
import styled from "@emotion/styled";
import { Colors, Spinner } from "@blueprintjs/core";

import DefaultTopBar from "shared/components/TopBar/TopBar";
import SwitchButtons from "./SwitchButtons";
import formatLastUpdatedText from "shared/helpers/formatLastUpdatedText/formatLastUpdatedText";
import { useStores } from "store/Store";

const StyledLastUpdated = styled("div")`
  color: ${Colors.GRAY1};
  font-size: 12px;
  line-height: 0.94;
`;

function TopBar() {
  const { dashboardStore, systemSettingsStore } = useStores();

  const { isLoading, lastUpdated } = dashboardStore;
  const { computedDateFormat = "yyyy-MM-dd" } = systemSettingsStore;

  const lastUpdatedText = !isLoading && lastUpdated ? formatLastUpdatedText(lastUpdated, computedDateFormat) : null;

  return (
    <DefaultTopBar>
      {isLoading ? <Spinner size={18} /> : <SwitchButtons />}
      {lastUpdatedText && <StyledLastUpdated>{lastUpdatedText}</StyledLastUpdated>}
    </DefaultTopBar>
  );
}

export default observer(TopBar);
