// @flow

import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { Spinner } from "@blueprintjs/core";

import EventsManagementSidebar from "components/EventsManagement/EventsManagementSidebar";
import EventsManagementTable from "components/EventsManagement/EventsManagementTable";
import EventsManagementTopBar from "components/EventsManagement/EventsManagementTopBar";
import StyledScrollableContainer from "shared/components/StyledScrollableContainer/StyledScrollableContainer";
import { Status } from "modules/App/Status";
import { useStores } from "store/Store";

function EventsManagement() {
  const { eventsManagementStore } = useStores();
  const { eventsMappings } = eventsManagementStore;

  useEffect(() => {
    eventsManagementStore.getEventsData();
    eventsManagementStore.getEventMappings();
  }, []);

  if (eventsMappings.status === Status.INIT) {
    return <div />;
  }

  if (eventsMappings.status === Status.LOADING) {
    return (
      <div className="m-5">
        <Spinner />
      </div>
    );
  }

  return (
    <StyledScrollableContainer className="d-flex flex-column">
      <EventsManagementTopBar />
      <EventsManagementSidebar />
      <div className="d-flex flex-column flex-shrink-1 px-2 pt-2 mh-0">
        <EventsManagementTable />
      </div>
    </StyledScrollableContainer>
  );
}

export default observer(EventsManagement);
