import { InfluenceHistoryFilters, InfluenceHistoryMappings } from "./InfluenceHistory.types";
import { Status } from "modules/App/Status";

export const FILTERS_INIT: InfluenceHistoryFilters = {
  adjustmentIds: [],
  cabinClass: [],
  createdOn: {
    end: null,
    start: null
  },
  depDate: {
    end: null,
    start: null
  },
  depDow: [],
  depMonth: [],
  depTime: {
    end: "23:59",
    start: "00:00"
  },
  depTimeBucket: [],
  depWeek: [],
  flightNumber: [],
  ndo: {
    end: 0,
    start: 360
  },
  owMarket: [],
  quarter: [],
  rtMarket: [],
  status: [],
  type: [],
  updatedOn: {
    end: null,
    start: null
  },
  userIds: []
};

export const PAGE_INIT = {
  applied: {
    filters: { status: ["active"] }
  },
  disabledFilters: {},
  filters: { ...FILTERS_INIT, status: ["active"] },
  filtersEnabled: true,
  historyTable: {
    columns: [
      [
        "influence",
        [
          "influenceId",
          "status",
          "type",
          "value",
          "updatedOn",
          "analystName",
          "comment",
          "numberOfFlights",
          "minDepDate",
          "maxDepDate",
          "owMarkets"
        ]
      ]
    ],
    data: [],
    pagination: {
      pageCount: 1,
      pageIndex: 0,
      pageSize: 25,
      totalRows: 0
    },
    selectedRows: [],
    sortBy: {
      direction: "desc",
      field: "updatedOn"
    },
    status: Status.INIT
  },
  influenceTimeline: {
    isCollapsed: true
  },
  sidebar: {
    filterKey: "",
    filterQuery: "",
    isOpen: false
  },
  status: Status.INIT
};

export const MAPPINGS_INIT: InfluenceHistoryMappings = {
  apInfluenceIds: [],
  creators: [],
  csInfluenceIds: [],
  minmaxInfluenceIds: [],
  percentInfluenceIds: [],
  status: Status.INIT
};
