import { ApplicationConfig } from "./config.types";
import routesP2P from "./routes/p2p";

const config: ApplicationConfig = {
  environmentRoutes: routesP2P,
  slots: {
    // eg: seatAllocations: SeatAllocations
  }
};

export default config;
