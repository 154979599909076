import React, { useLayoutEffect, useRef } from "react";
import { observer } from "mobx-react";

import FilterGroups from "shared/components/Sidebar/FilterGroups/FilterGroups";
import focusOnFilter from "shared/helpers/focusOnFilter/focusOnFilter";
import SearchInput from "shared/components/SearchInput/SearchInput";
import Sidebar from "shared/components/Sidebar/Sidebar";
import SidebarApplyButton from "shared/components/Sidebar/SidebarApplyButton/SidebarApplyButton";
import { eventsManagementMetrics } from "modules/Events/eventsManagementMetrics";
import { useStores } from "store/Store";
import { FILTERS_INIT } from "models/EventsManagement/EventsManagement.utils";
import EventsMarketFilters from "components/EventsManagement/EventsMarketFilters";
import EventFilters from "components/EventsManagement/EventFilters";

const componentsMap = {
  event: EventFilters,
  market: EventsMarketFilters
};

function EventsManagementSidebar() {
  const { analysisMappingsStore, eventsManagementStore, systemSettingsStore } = useStores();
  const { decoratedPage, page, setSidebarFilterQuery, setSidebarOpen, submitSearchForm } = eventsManagementStore;
  const { computedDateFormat, computedDateMonthFormat, isMiles } = systemSettingsStore;
  const { filterKey, filterQuery, isOpen } = page.sidebar;
  const sidebarRef = useRef(null);

  useLayoutEffect(() => {
    focusOnFilter(filterKey, filterQuery, sidebarRef);
  }, [filterKey, filterQuery]);

  const onSubmit = () => {
    setSidebarOpen(false);
    setSidebarFilterQuery("");
    submitSearchForm();
  };

  const groupsWithComponents = eventsManagementMetrics.map(metricGroup => {
    return { ...metricGroup, Component: componentsMap[metricGroup.key] };
  });

  return (
    <Sidebar
      ref={sidebarRef}
      footer={<SidebarApplyButton initialFilters={FILTERS_INIT} onSubmit={onSubmit} pageContext={decoratedPage} />}
      isOpen={isOpen}
      setOpen={value => setSidebarOpen(value)}
    >
      <SearchInput onChange={setSidebarFilterQuery} value={filterQuery} />
      <FilterGroups
        computedDateFormat={computedDateFormat}
        computedDateMonthFormat={computedDateMonthFormat}
        filterGroups={groupsWithComponents}
        filterQuery={filterQuery}
        isMiles={isMiles}
        mappings={analysisMappingsStore}
        pageContext={decoratedPage}
      />
    </Sidebar>
  );
}

export default observer(EventsManagementSidebar);
