import React, { useState } from "react";
import styled from "@emotion/styled";

import SidebarNDOSelect from "shared/components/Sidebar/SidebarNDOSelect/SidebarNDOSelect";

const StyledSidebarNDOSelect = styled(SidebarNDOSelect)`
  margin-bottom: 0 !important;
  padding: 5px;
`;

type TypesRangeNdoSelection = {
  getExtremes(): ReturnType<() => { dataMax: number; dataMin: number; min: number; max: number }>;
  setRange: Function;
};

export const RangeNdoSelection = ({ getExtremes, setRange }: TypesRangeNdoSelection) => {
  const [extremes, setExtremes] = useState(getExtremes() || {}); // fix for getExtremes propagation bug

  const values = { end: extremes.max, start: extremes.min };

  return (
    <StyledSidebarNDOSelect
      max={extremes.dataMax}
      min={extremes.dataMin}
      onChange={({ start, end }) => {
        setRange(end, start);
        setExtremes({ ...extremes, max: start, min: end });
      }}
      values={values}
    />
  );
};
