export default function getRowId(aggregations: Array<string> = [], dataRow: Object = {}) {
  return aggregations
    .map(aggr => {
      if (!dataRow[aggr]) {
        return "";
      }
      return `${dataRow[aggr]}`;
    })
    .join("_");
}
