// @flow

import { AxiosPromise } from "axios";
import cloneDeep from "lodash.clonedeep";
import uniq from "lodash.uniq";
import { action, computed, makeObservable, observable, toJS } from "mobx";

import api, { isCancel } from "services/Api";
import getRowObjectIds from "shared/helpers/getRowObjectIds/getRowObjectIds";
import { Status } from "../App/Status";
import type {
  FixedColumns,
  FlightTableColumn,
  GetFlightsCountData,
  GetFlightsData,
  GroupStatus
} from "./FlightsTable.types";
import type { Pagination } from "stypes/DataTable.types";
import type { FlightEntry, FlightsParams, FlightsResponse, FlightTableParams } from "stypes/Flights.types";
import type { TableSortType } from "stypes/Table.types";
import { columnsToRequest, groupStatuses, initColumns } from "../FlightsTable/FlightsTable.utils";
import canEnableMetric from "../FlightsTable/canEnableMetric/canEnableMetric";

export class FlightsTable {
  @observable aggregations: string[] = ["rtMarket", "depMonth"];
  @observable columns: FlightTableColumn[] = initColumns;
  @observable data: FlightEntry[] = [];
  @observable fixedColumns: FixedColumns = { aggregations: "left" };
  @observable groupStatuses: GroupStatus[] = groupStatuses;
  @observable influenceImpactGroupColumns: string[];
  @observable lastUpdated: string;
  @observable pagination: Pagination = { pageCount: 1, pageIndex: 0, pageSize: 25, totalRows: 0 };
  @observable selectedRows: string[] = [];
  @observable showOnlySelected: boolean = false;
  @observable sortBy: TableSortType = { direction: "asc", field: "xDayRevenuePotential" };
  @observable status: typeof Status = Status.INIT;

  constructor(flightsTable?: FlightsTable) {
    makeObservable(this);
    if (flightsTable) {
      const flightsTableToMerge = flightsTable.toJSON ? flightsTable.toJSON() : flightsTable;
      Object.assign(this, flightsTableToMerge);
    }
  }

  @action
  fetchFlightsData(params: GetFlightsData, apiIdentifier: string = "getFlights"): AxiosPromise<FlightsResponse> {
    const { conditionalFilters, extraColumns = [], extraParams, filters, tabId, xDayBuild } = params;
    const { pageIndex = 0, pageSize = this.pagination.pageSize, sortBy = this.sortBy } = params || {};

    this.status = Status.LOADING;
    this.pagination.pageSize = pageSize;
    this.pagination.pageIndex = pageIndex;
    this.sortBy = sortBy;

    const fetchParams: FlightsParams = {
      aggregations: this.aggregations,
      columns: [...columnsToRequest(this), ...extraColumns],
      conditionalFilters,
      filters,
      pagination: {
        offset: pageIndex * pageSize,
        size: pageSize
      },
      sortBy,
      xDayBuild,
      ...(this.showOnlySelected && {
        rowIds: getRowObjectIds(this.aggregations, this.selectedRows)
      }),
      ...(extraParams && extraParams)
    };

    return api
      .getFlights(fetchParams, (tabId: string), apiIdentifier)
      .then(response => {
        const { rows, lastUpdated } = response.data;

        this.lastUpdated = lastUpdated;
        this.data = rows;
        this.status = Status.DONE;

        return response;
      })
      .catch(thrown => {
        if (isCancel(thrown)) {
          this.status = Status.LOADING;
          return;
        }
        this.status = Status.ERROR;
      });
  }

  @action
  fetchFlightsCountData(params: GetFlightsCountData, identifier: ?string) {
    const {
      conditionalFilters,
      extraParams,
      filters,
      pageIndex = 0,
      pageSize = this.pagination.pageSize,
      sortBy = this.sortBy,
      tabId,
      xDayBuild
    } = params;

    const fetchParams: FlightTableParams = {
      aggregations: this.aggregations,
      conditionalFilters,
      filters,
      pagination: {
        offset: pageIndex * pageSize,
        size: pageSize
      },
      sortBy,
      xDayBuild,
      ...(this.showOnlySelected && {
        rowIds: getRowObjectIds(this.aggregations, this.selectedRows)
      }),
      ...(extraParams && extraParams)
    };

    return api.getFlightsCount(fetchParams, tabId, identifier).then(response => {
      const { numberOfRows } = response.data;

      const pageCount = Math.ceil(numberOfRows / pageSize);

      this.pagination.totalRows = numberOfRows;
      this.pagination.pageCount = pageCount;

      return response.data;
    });
  }

  @action
  toggleFixedColumn(column: string) {
    if (this.fixedColumns[column] === "left") {
      delete this.fixedColumns[column];
    } else {
      this.fixedColumns[column] = "left";
    }
  }

  @action
  resetFixedColumns(initialFixedColumns: FixedColumns) {
    this.fixedColumns = cloneDeep(initialFixedColumns);
  }

  @action
  flushData() {
    this.status = Status.INIT;
    this.data = [];
    this.showOnlySelected = false;
  }

  @action
  toggleShowOnlySelected() {
    this.showOnlySelected = !this.showOnlySelected;
  }

  @action
  hideAggregation(columnId: string) {
    this.aggregations = this.aggregations.filter(aggregation => aggregation !== columnId);
  }

  @action
  hideColumn(columnId: string) {
    const allColumns = this.columns.flatMap(([groupId, metrics]) => [groupId, ...metrics.flatMap(metric => metric)]);
    this.columns = allColumns
      .filter(metric => metric !== columnId)
      .reduce((accumulator, currentValue) => {
        const isGroup = this.columns.find(([group]) => group === currentValue);

        if (isGroup) {
          return [...accumulator, [currentValue, []]];
        }

        const parentNode = this.columns.find(([, metrics]) => {
          return metrics.includes(currentValue);
        });

        if (!parentNode) {
          return accumulator;
        }
        return accumulator.map(([groupTitle, metrics]) =>
          groupTitle === parentNode[0] ? [groupTitle, [...metrics, currentValue]] : [groupTitle, metrics]
        );
      }, []);
  }

  @action
  changeFlightsTableParams(key, items, options = { refetch: true, skipSave: false }) {
    Object.assign(this, {
      ...(options.refetch && { status: Status.LOADING }),
      [key]: items
    });

    if (key === "aggregations") {
      this.columns = this.columns.map(([groupName, groupColumns]) => {
        const enabledColumns = groupColumns.filter(column => canEnableMetric(this.aggregations, column));
        return [groupName, enabledColumns];
      });
      this.showOnlySelected = false;
    }
  }

  toJSON(): FlightsTable {
    return toJS(this);
  }

  @computed
  get isOnlyOneCabinClassSelected(): boolean {
    if (!this.aggregations.includes("cabinClass")) {
      return false;
    }
    const rowObjectIds = getRowObjectIds(this.aggregations, this.selectedRows);
    const selectedCabinClasses = uniq(rowObjectIds.map(id => id.cabinClass));
    return selectedCabinClasses.length === 1;
  }

  @computed
  get isLoading(): boolean {
    return this.status === Status.LOADING;
  }

  @computed
  get isError(): boolean {
    return this.status === Status.ERROR;
  }
}
