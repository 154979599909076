import isEmpty from "lodash.isempty";

const prepareEventObject = (event: {
  isLocationGlobal?: boolean;
  isProvenanceGlobal: boolean;
  locations: {} | null;
  name: string;
  nonWorkingDay: boolean;
  provenances: any | null;
  provenanceType: any;
}) => {
  const {
    isLocationGlobal,
    locations,
    name = "",
    nonWorkingDay = false,
    isProvenanceGlobal,
    provenances,
    provenanceType,
    ...rest
  } = event;
  const isProvenancesExists = provenances && !isEmpty(Object.values(provenances).flat());
  const provenanceTypeCallback = isProvenancesExists
    ? Object.entries(provenances)
        .filter(([, value]: [any, any[]]) => value.length)
        ?.flat()?.[0]
    : "countries";

  return {
    ...rest,
    isLocationGlobal: typeof isLocationGlobal === "boolean" ? isLocationGlobal : !locations,
    isProvenanceGlobal: typeof isProvenanceGlobal === "boolean" ? isProvenanceGlobal : !provenances,
    locations: {
      airports: [],
      countries: [],
      metros: [],
      ...(locations && locations)
    },
    name,
    nonWorkingDay,
    provenances: {
      airports: [],
      countries: [],
      metros: [],
      ...(provenances && provenances)
    },
    provenanceType: provenanceType || provenanceTypeCallback
  };
};

export default prepareEventObject;
