import { useEffect, useState } from "react";
import { useStores } from "store/Store";

import { Status } from "modules/App/Status";

export const useApplicationInitializer = () => {
  const {
    analysisMappingsStore,
    appStore,
    influenceHistoryStore,
    regionsStore,
    templatesStore,
    timeStore,
    systemSettingsStore
  } = useStores();

  const [dataStatus, setDataStatus] = useState(Status.LOADING);

  useEffect(() => {
    if (appStore.isLoggedIn) {
      appStore.getRUV().finally(() => {
        // .finally() is on purpose, load all data on success or on error

        analysisMappingsStore
          .getMarketAnalyst()
          .then(() =>
            Promise.all([
              analysisMappingsStore.getMappings(),
              appStore.auth.getCurrentUser(),
              influenceHistoryStore.getMappings(),
              regionsStore.getRegionsData(),
              systemSettingsStore.getDateFormat(),
              systemSettingsStore.getDistanceUnit(),
              templatesStore.getAllTemplates(),
              timeStore.getKPIsTimestamp()
            ])
          )
          .then(() => {
            setDataStatus(Status.DONE);
          })
          .catch(() => {
            setDataStatus(Status.ERROR);
          });
      });
    }
  }, []);

  return dataStatus;
};
