export const groupTitles = {
  departure: "Departure",
  other: "Additional",
  route: "Route"
};

export const groups = ["route", "departure", "other"];

export const items = [
  {
    group: "route",
    label: "Region",
    value: "regionId"
  },
  {
    group: "route",
    label: "Subregion",
    value: "subregionId"
  },
  {
    group: "route",
    label: "RT Market",
    value: "rtMarket"
  },
  {
    group: "route",
    label: "OW Market",
    value: "owMarket"
  },
  {
    group: "route",
    label: "Origin",
    value: "origin"
  },
  {
    group: "route",
    label: "Destination",
    value: "destination"
  },
  {
    group: "departure",
    label: "Quarter",
    value: "quarter"
  },
  {
    group: "departure",
    label: "Month",
    value: "depMonth"
  },
  {
    group: "departure",
    label: "Week",
    value: "depWeek"
  },
  {
    group: "departure",
    label: "Day of Week",
    value: "depDow"
  },
  {
    group: "departure",
    label: "Date",
    value: "depDate"
  },
  {
    group: "other",
    label: "Airline",
    value: "airline"
  },
  {
    group: "other",
    label: "Flight Number",
    value: "flightNumber"
  },
  {
    group: "other",
    label: "Analyst",
    value: "analystId"
  },
  {
    group: "other",
    label: "Cabin Class",
    value: "cabinClass"
  },
  {
    group: "other",
    label: "Dep Time Bucket",
    value: "depTimeBucket"
  }
];

export const isOptionVisible = (filterName: string, filterKey: string, filterQuery: string): boolean =>
  `${filterName} ${filterKey}`.toLowerCase().includes(filterQuery.toLowerCase());
