export const eventsManagementMetrics = [
  {
    Component: null, // Component is injected in sidebar component
    filters: [{ key: "owMarket" }, { key: "rtMarket" }, { key: "origin" }, { key: "destination" }],
    key: "market",
    name: "Market"
  },
  {
    Component: null,
    filters: [{ key: "categoryIds" }, { key: "dateRange" }, { key: "userIds" }],
    key: "event",
    name: "Event"
  }
];

export const filterOrder = eventsManagementMetrics.map(({ filters }) => filters.map(({ key }) => key)).flat();
