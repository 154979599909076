// @flow

import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "@emotion/styled";
import { Button, Colors } from "@blueprintjs/core";
import type { Tab } from "models/Tab/Tab.model";
import { AppToaster } from "services/Toaster";
import { compareDateFiltersToNow } from "shared/helpers/compareDateFiltersToNow/compareDateFiltersToNow";
import { Status } from "modules/App/Status";
import { useStores } from "store/Store";
import InactiveTabContent from "./InactiveTabContent";
import ActiveTabContent from "./ActiveTabContent";

const ExtraColors = {
  WHITE_33: `${Colors.WHITE}33`,
  WHITE_99: `${Colors.WHITE}99`
};

const StyledTab = styled("div")`
  background: ${({ active }) => (active ? Colors.WHITE : ExtraColors.WHITE_33)};
  border-radius: 2px 2px 0 0;
  color: ${({ active }) => (active ? Colors.DARK_GRAY4 : ExtraColors.WHITE_99)};
  flex: 1 1 100%;
  font-size: 14px;
  height: 30px;
  line-height: 16px;
  max-width: 160px;
  min-width: 80px;

  a {
    font-weight: ${({ active }) => (active ? "700" : "500")};
    color: currentColor;
    height: 30px;
  }

  .bp3-icon-cross svg {
    fill: currentColor;
  }
  .tab-options {
    svg {
      fill: ${Colors.DARK_GRAY4};
    }
  }
`;

type Props = {
  getPreviousTabId: ?Function,
  isActive: boolean,
  tab: Tab
};

function HeaderTab(props: Props) {
  const { getPreviousTabId, isActive, tab } = props;

  const { templatesStore, tabsStore } = useStores();
  const { tabs } = tabsStore;
  const tabsCount = tabs.length;

  const location = useLocation();
  const history = useHistory();

  const isActiveTab = isActive || location.pathname.split("/").includes(tab.id);

  useEffect(() => {
    const isCompletedStatus = [Status.DONE, Status.ERROR].includes(tab.flightsTable.status);
    if (isActiveTab && isCompletedStatus) {
      const { depDate, depWeek, depMonth, quarter } = tab.applied.filters;
      const isFilterInPast = compareDateFiltersToNow(depDate, depWeek, depMonth, quarter);
      if (isFilterInPast) {
        AppToaster.show({
          intent: "warning",
          message: "Some pre-defined departure dates are in the past"
        });
      }
    }
  }, [isActiveTab, tab.flightsTable.status]);

  const isUnsaved = templatesStore.checkTabUnsaved(tab.id) && !tab.isEditMode;

  const defaultTooltipContent = tab.label ? (
    <span>{`${tab.label}${isUnsaved ? " (unsaved changes)" : ""}`}</span>
  ) : null;

  const previousTabId = getPreviousTabId && getPreviousTabId();
  const TabContentComponent = isActiveTab ? ActiveTabContent : InactiveTabContent;

  return (
    <StyledTab active={isActiveTab} className="d-flex align-items-center mr-2" data-testid="navigation-tab">
      <TabContentComponent tab={tab} tooltipContent={defaultTooltipContent} />
      {!tab.isEditingTemplate && (
        <Button
          data-testid="remove-tab"
          icon="cross"
          minimal
          onClick={() => {
            tabsStore.shouldConfirmLeavingTab(tab.state?.dirty, () => {
              tabsStore.removeTab(tab.id);
              if (tabsCount === 1) {
                localStorage.removeItem("activeTab");
                history.push("/dashboard");
              } else if (isActiveTab && previousTabId) {
                history.push(`/analysis/${previousTabId}/explore`);
              }
            });
          }}
        />
      )}
    </StyledTab>
  );
}

export default observer(HeaderTab);
