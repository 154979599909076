import { HEATMAP_THRESHOLD_COLORS } from "../../App/constants";

export const getHeatmapThresholdColor = (value: number, boundary: number | null) => {
  if (value === 0 || boundary === null) {
    return HEATMAP_THRESHOLD_COLORS.NEUTRAL;
  }
  if (Math.abs(value) >= boundary * 0.9) {
    return value > 0 ? HEATMAP_THRESHOLD_COLORS.BEST : HEATMAP_THRESHOLD_COLORS.WORST;
  }
  if (Math.abs(value) >= boundary * 0.7) {
    return value > 0 ? HEATMAP_THRESHOLD_COLORS.BETTER : HEATMAP_THRESHOLD_COLORS.WORSE;
  }
  if (Math.abs(value) <= boundary * 0.7) {
    return value > 0 ? HEATMAP_THRESHOLD_COLORS.GOOD : HEATMAP_THRESHOLD_COLORS.BAD;
  }
  return HEATMAP_THRESHOLD_COLORS.DISABLE;
};
