import React, { useContext } from "react";
import { observer } from "mobx-react";
import isEmpty from "lodash.isempty";
import difference from "lodash.difference";
import isEqual from "lodash.isequal";

import filterNamesMap, { filterNamesOverrides } from "modules/Explore/filterNames";
import ChipValue from "../ChipValue/ChipValue";
import ChipContent from "../ChipContent/ChipContent";
import filterTitleValue from "../../../helpers/filterTitleValue/filterTitleValue";
import Tag from "../Tag/Tag";
import TagFilterToggle from "../TagFilterToggle/TagFilterToggle";
import TagsListContext from "../TagsListContext/TagsListContext";
import styled from "@emotion/styled";

const StyledTagContent = styled("span")<{ showFilterToggle: boolean }>`
  display: grid;
  grid-template-columns: ${({ showFilterToggle }) => (showFilterToggle ? "15px auto 20px auto" : "auto 20px auto")};
  width: fit-content;
`;

type Props = {
  name: string;
  disabled: boolean;
  value: any;
  inline?: boolean;
};

function TagsListItem(props: Props) {
  const { name, disabled, inline = true, value } = props;
  const {
    additionalTagLabels,
    computedDateFormat,
    disabledFilters,
    onFilterRemove,
    onFilterToggle,
    onValueRemove,
    isMiles,
    xDayBuild
  } = useContext(TagsListContext);

  const isDisabledFiltersDefined = "disabledFilters" !== undefined;
  const valueIsArray = Array.isArray(value);

  const isConditionalFilter =
    valueIsArray &&
    value.every(singleValue => singleValue !== null && typeof singleValue === "object" && "func" in singleValue);
  const filterNames = filterNamesMap(isMiles, additionalTagLabels, xDayBuild);
  const filterName = filterNamesOverrides[name] || filterNames[name] || name;

  const filterValue = isConditionalFilter ? value.map((_, index) => index) : value;
  const isRemovable = [onFilterRemove, onValueRemove].every(callback => typeof callback === "function");

  const isFilterDisabled = valueIsArray
    ? isEmpty(difference(filterValue, disabledFilters?.[name]))
    : isEqual(filterValue, disabledFilters?.[name]);

  const shouldShowFilterToggle = isRemovable && isDisabledFiltersDefined && typeof onFilterToggle === "function";

  const tagTitle = `${filterName}: ${filterTitleValue(
    additionalTagLabels?.[value] ? additionalTagLabels[value] : value,
    name,
    additionalTagLabels,
    computedDateFormat
  )}`;

  const conditionalProps = {
    ...(isRemovable && { onRemove: () => onFilterRemove && onFilterRemove(name) })
  };

  return (
    <Tag
      {...conditionalProps}
      disabled={disabled || isFilterDisabled}
      inline={inline}
      popoverContent={<ChipContent name={name} title={filterName} value={value} />}
    >
      <StyledTagContent data-testid="tag-content" showFilterToggle={shouldShowFilterToggle}>
        {shouldShowFilterToggle && (
          <TagFilterToggle disabled={isFilterDisabled} onToggle={() => onFilterToggle && onFilterToggle(name)} />
        )}
        <span className="text-truncate" title={tagTitle}>
          {filterName}
        </span>
        <span className="font-weight-normal mx-1 text-center"> &middot; </span>
        <ChipValue name={name} value={value} />
      </StyledTagContent>
    </Tag>
  );
}

export default observer(TagsListItem);
