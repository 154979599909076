import isEmpty from "lodash.isempty";
import { FilterKey } from "types/Tab.types";

export type Sidebar = {
  filterKey?: string;
  filterQuery: string;
};

const isSidebarFilterVisible = (filterName: string, filterKey: FilterKey, sidebar: Sidebar): boolean => {
  if (!isEmpty(sidebar.filterKey)) {
    return sidebar.filterKey === filterKey;
  }
  return filterName.toLowerCase().includes(sidebar.filterQuery.toLowerCase());
};

export default isSidebarFilterVisible;
