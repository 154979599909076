import { observer } from "mobx-react";
import React from "react";

import { useStores } from "store/Store";
import CapacityStepSelectionHelpText from "../CapacityStepSelectionHelpText/CapacityStepSelectionHelpText";
import ControlButton from "components/ControlButton/ControlButton";
import { capacityItemsList } from "../Capacity.utils";
import { useCurrentTab } from "shared/helpers/useCurrentTab/useCurrentTab";

function CapacityStepSelection() {
  const { capacityStore } = useStores();
  const tab = useCurrentTab();

  return (
    <div data-testid="capacity-control-selection">
      {capacityItemsList.map(capacityItem => (
        <ControlButton
          key={capacityItem.name}
          disabled={capacityItem.disabled || !tab.canStartCapacityAdjustment}
          onClick={() => capacityStore.startAdjustment(capacityItem.key)}
        >
          {capacityItem.name}
        </ControlButton>
      ))}
      <p className="bp3-text-muted bp3-text-small mt-2 d-flex flex-column">
        <CapacityStepSelectionHelpText />
        <a
          href="https://help.cirrus.ai/en/articles/5560848-influence-manual-overbooking"
          rel="noopener noreferrer"
          target="_blank"
        >
          Read more about Capacity Adjustments
        </a>
      </p>
    </div>
  );
}

export default observer(CapacityStepSelection);
