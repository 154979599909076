import { influenceTypeNames } from "../../Influence/influenceConstants";
import { valueWithLimits } from "../valueWithLimits/valueWithLimits";

export const normalizeInfluenceHistoryRow = row => {
  const type = influenceTypeNames[row.type] || row.type;
  const typeKey = row.type;

  return {
    ...row,
    numberOfFlights: row.status === "deleted" ? row.numberOfFlights : row.numberOfFutureFlights,
    type,
    typeKey,
    valueText: valueWithLimits(row)
  };
};
