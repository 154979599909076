import React from "react";
import { AnchorButton, Intent } from "@blueprintjs/core";
import styled from "@emotion/styled";

const StyledAnchorButton = styled(AnchorButton)`
  font-size: inherit;
  padding: 0 !important;
  min-height: auto !important;
`;

type Props = {
  children: string | React.ReactElement;
  onClick: () => void;
};

function TextButton(props: Props) {
  const { children, onClick } = props;

  return (
    <StyledAnchorButton intent={Intent.PRIMARY} minimal onClick={onClick} small>
      {children}
    </StyledAnchorButton>
  );
}

export default TextButton;
