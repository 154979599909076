import styled from "@emotion/styled";
import clsx from "clsx";
import { Spinner } from "@blueprintjs/core";
import React from "react";

const LoadingOverlay = styled("div")`
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  transition: background-color 0.3s, opacity 0.3s, max-height 0s 0.3s;
  position: absolute;
  z-index: 6;
  background: rgba(255, 255, 255, 0.8);

  &.loading {
    opacity: 1;
    max-height: 100%;
    transition: opacity 0.3s;
  }

  &.no-data {
    padding: 32px 32px 64px;
    position: static;
    margin-bottom: -32px;
  }
`;

type Props = {
  loading: boolean,
  isEmpty: boolean
};

function DataTableLoadingOverlay({ loading, isEmpty }: Props) {
  const dynamicClassNames = clsx({ loading, "no-data": isEmpty });

  return (
    <LoadingOverlay
      className={`w-100 h-100 d-flex justify-content-center align-content-center ${dynamicClassNames}`}
      data-testid="data-table-loading-overlay"
    >
      {loading && <Spinner />}
    </LoadingOverlay>
  );
}

export default DataTableLoadingOverlay;
