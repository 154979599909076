import { Tab } from "models/Tab/Tab.model.js";
import { synchronizedCollapses } from "models/Tab/Tab.utils";
import { MultipleActions, SingleAction } from "../ModuleContextActions/ModuleContextActions";

export const createResizeContextActions = (
  isCollapsed: boolean,
  onChange: (collapsed: boolean) => void
): SingleAction | MultipleActions => {
  if (isCollapsed) {
    return {
      icon: "maximize",
      onClick: () => onChange(!isCollapsed)
    };
  }

  return [
    {
      icon: "minimize",
      onClick: () => onChange(!isCollapsed),
      text: "Collapse"
    }
  ];
};

export const getAffectedModules = (moduleKey: string, tab: Tab) => {
  return synchronizedCollapses
    .filter(modules => modules.includes(moduleKey))
    .flat()
    .filter(key => moduleKey !== key)
    .filter(key => tab.preferences?.modules[key])
    .map(key => tab.preferences?.modules[key]);
};

export const allSiblingsCollapsed = (moduleKey: string, tab: Tab) => {
  const syncModules = synchronizedCollapses.filter(modules => modules.includes(moduleKey)).flat();
  const affectedModules = syncModules
    .filter(key => moduleKey !== key)
    .filter(key => tab.preferences?.modules[key])
    .map(key => tab.preferences?.modules[key]);

  return affectedModules?.length === syncModules.length - 1 && affectedModules.every(module => module.isCollapsed);
};

export const allModuleCollapsedInSync = (moduleKey: string, tab: Tab) => {
  return allSiblingsCollapsed(moduleKey, tab) && tab.preferences?.modules[moduleKey]?.isCollapsed;
};
