import { Colors } from "@blueprintjs/core";
import { CellType } from "types/DataTable.types";

export const daysOfWeek = [
  null,
  { long: "Monday", numeric: 1, short: "Mo" },
  { long: "Tuesday", numeric: 2, short: "Tu" },
  { long: "Wednesday", numeric: 3, short: "We" },
  { long: "Thursday", numeric: 4, short: "Th" },
  { long: "Friday", numeric: 5, short: "Fr" },
  { long: "Saturday", numeric: 6, short: "Sa" },
  { long: "Sunday", numeric: 7, short: "Su" }
];

export const extraMetrics = {
  aggregations: "Aggregation",
  airline: "Airline",
  analyst: "Analyst",
  analystId: "Analyst",
  cabinClass: "Cabin",
  category: "Category",
  categoryIds: "Category",
  cirrusStatus: "Cirrus",
  dateRange: "Date range",
  departureDayOfWeek: "Departure Day Of Week",
  depDate: "Departure Date",
  depDow: "Departure Day Of Week",
  depMonth: "Departure Month",
  depTime: "Departure Time",
  depTimeBucket: "Departure Time Bucket",
  depWeek: "Departure Week",
  destination: "Destination",
  distance: "Distance",
  flight: "Flight",
  flightNumber: "Flight Number",
  fusionrmStatus: "Cirrus",
  minmaxAdjustmentMax: "Max Limit",
  minmaxAdjustmentMin: "Min Limit",
  ndo: "Number of Days to Departure",
  numberOfFlights: "Flight Count",
  odPairs: "OW Market",
  origin: "Origin",
  owMarket: "OW Market",
  quarter: "Quarter",
  region: "Region",
  regionId: "Region",
  rtMarket: "RT Market",
  rtMarkets: "RT Market",
  subregion: "Subregion",
  subregionId: "Subregion"
};

/* eslint-disable sort-keys */
export const metricsTypes = {
  depDate: CellType.Date,
  depWeek: CellType.Date,
  depMonth: CellType.DateMonth,
  depDow: CellType.DayWeekLong,
  quarter: CellType.Quarter,
  distance: CellType.Integer,

  xDayRevenuePotential: CellType.Currency,
  finalRevenueExpected: CellType.Currency,
  finalRevenueBaseline: CellType.Currency,
  xDayFinalRevenueExpected: CellType.Currency,

  finalRaskExpected: CellType.CurrencyPrecise,
  finalRaskBaseline: CellType.CurrencyPrecise,
  xDayFinalRaskExpected: CellType.CurrencyPrecise,
  xDayFinalRaskBuildExpected: CellType.Percent,

  rask: CellType.CurrencyPrecise,
  raskBaseline: CellType.CurrencyPrecise,
  raskExpected: CellType.CurrencyPrecise,
  raskDiffToBaseline: CellType.PercentRelative,
  xDayRask: CellType.CurrencyPrecise,
  xDayRaskBaseline: CellType.CurrencyPrecise,
  xDayRaskDiffToBaseline: CellType.PercentRelative,
  xDayRaskBuild: CellType.CurrencyPrecise,
  xDayRaskBuildBaseline: CellType.CurrencyPrecise,
  xDayRaskBuildDiffToBaseline: CellType.PercentRelative,

  revenue: CellType.Currency,
  revenueBaseline: CellType.Currency,
  revenueExpected: CellType.Currency,
  diffToErb: CellType.Currency,
  xDayRevenue: CellType.Currency,
  xDayRevenueBaseline: CellType.Currency,
  xDayRevenueDiffToBaseline: CellType.Currency,
  xDayRevenueBuildDiff: CellType.Currency,
  xDayRevenueBuildBaseline: CellType.Currency,
  xDayRevenueBuildDiffToBaseline: CellType.Currency,

  finalRevenuePerBookingExpected: CellType.Currency,
  xDayFinalRevenuePerBookingExpected: CellType.Currency,
  xDayFinalRevenuePerBookingBuildExpected: CellType.Currency,
  finalRevenuePerBookingBaseline: CellType.Currency,

  revenuePerBooking: CellType.Currency,
  revenuePerBookingBaseline: CellType.Currency,
  revenuePerBookingDiffToBaseline: CellType.PercentRelative,
  xDayRevenuePerBooking: CellType.Currency,
  xDayRevenuePerBookingBaseline: CellType.Currency,
  xDayRevenuePerBookingDiffToBaseline: CellType.PercentRelative,
  xDayRevenuePerBookingBuild: CellType.Currency,
  xDayRevenuePerBookingBuildBaseline: CellType.Currency,
  xDayRevenuePerBookingBuildDiffToBaseline: CellType.PercentRelative,

  finalLoadFactorBaseline: CellType.Percent,
  finalLoadFactorExpected: CellType.Percent,
  xDayFinalLoadFactorExpected: CellType.Percent,
  xDayFinalLoadFactorBuildExpected: CellType.Percent,

  loadFactor: CellType.Percent,
  loadFactorBaseline: CellType.Percent,
  loadFactorExpected: CellType.Percent,
  diffToElb: CellType.PercentRelative,
  xDayLoadFactor: CellType.Percent,
  xDayLoadFactorBaseline: CellType.Percent,
  xDayLoadFactorDiffToBaseline: CellType.PercentRelative,
  xDayLoadFactorBuild: CellType.PercentRelative,
  xDayLoadFactorBuildBaseline: CellType.PercentRelative,
  xDayLoadFactorBuildDiffToBaseline: CellType.PercentRelative,

  yield: CellType.CurrencyPrecise,
  yieldBaseline: CellType.CurrencyPrecise,
  yieldDiffToBaseline: CellType.PercentRelative,
  xDayYield: CellType.CurrencyPrecise,
  xDayYieldBaseline: CellType.CurrencyPrecise,
  xDayYieldDiffToBaseline: CellType.PercentRelative,
  xDayYieldBuild: CellType.CurrencyPrecise,
  xDayYieldBuildBaseline: CellType.CurrencyPrecise,
  xDayYieldBuildDiffToBaseline: CellType.PercentRelative,

  finalYieldExpected: CellType.CurrencyPrecise,
  xDayFinalYieldExpected: CellType.CurrencyPrecise,
  xDayFinalYieldBuildExpected: CellType.PercentRelative,
  finalYieldBaseline: CellType.CurrencyPrecise,

  sellingPrice: CellType.Currency,
  xDaySellingPrice: CellType.Currency,
  sellingPriceFrm: CellType.Currency,
  xDaySellingPriceFrm: CellType.Currency,
  xDayPriceBuildDiff: CellType.Currency,

  pricePercentile: CellType.Percent,
  xDayPricePercentile: CellType.Percent,
  pricePercentileFrm: CellType.Percent,
  xDayPricePercentileFrm: CellType.Percent,
  xDayPricePercentileBuild: CellType.Percent,

  compFareCirrusMatchFare: CellType.Currency,
  compFareTimeMatchFare: CellType.Currency,
  compFareDateMatchFare: CellType.Currency,
  sellingFareDiffToCirrusCompFarePercentile: CellType.PercentRelative,
  sellingFareDiffToCirrusCompFare: CellType.Currency,
  sellingFareDiffToTimePeriodCompFarePercentile: CellType.PercentRelative,
  sellingFareDiffToTimePeriodCompFare: CellType.Currency,
  sellingFareDiffToDepDateCompFarePercentile: CellType.PercentRelative,
  sellingFareDiffToDepDateCompFare: CellType.Currency,

  priceAdjustment: CellType.PercentRelative,
  absolutePriceAdjustment: CellType.Currency,
  minmaxAdjustmentMax: CellType.Currency,
  minmaxAdjustmentMin: CellType.Currency,
  influenceNumberOfImpactedFlights: CellType.Influence,
  influencePriceAdjustment: CellType.InfluencePercent,
  influenceAbsolutePriceAdjustment: CellType.InfluenceCurrency,
  influenceSellingPrice: CellType.InfluenceCurrency,
  influencePricePercentile: CellType.Influence,
  influenceLacRank: CellType.Influence,
  influenceMinmaxAdjustmentMin: CellType.InfluenceCurrency,
  influenceMinmaxAdjustmentMax: CellType.InfluenceCurrency,
  influenceCompSensitivity: CellType.Influence,
  xDayInfluenceImpactBuild: CellType.PercentRelative,
  influenceSellingFareDiffToCirrusCompFare: CellType.InfluenceCurrency
};

export const metricGroupTypes = {
  competitorFareGroup: CellType.Currency,
  lacGroup: CellType.Influence,
  loadFactorGroup: CellType.Percent,
  pricePercentileGroup: CellType.Percent,
  raskGroup: CellType.CurrencyPrecise,
  revenueGroup: CellType.Currency,
  revenuePerBookingGroup: CellType.CurrencyPrecise,
  sellingFareGroup: CellType.Currency,
  yieldGroup: CellType.CurrencyPrecise
};
/* eslint-enable sort-keys */

export const metricUnitTypes = {
  currency: "$",
  "currency-precise": "$",
  percent: "%",
  "percent-relative": "%"
};

export const dashStylesGraph = ["Solid", "ShortDot", "Dash", "DashDot"];
export const dashStylesCSS = ["solid", "dotted", "dashed", "dashdotted"];

export const isCypress = window.Cypress;
export const isLocalhost = window.location.hostname === "localhost";

export const mockApiEnv = {
  apiUrl: "https://fusionrm-xx-staging.flyrlabs.com/acp-remake/",
  clientId: "foobar",
  domain: "cirrus-xx-staging.us.auth0.com"
};

export const MAX_TAB_COUNT = 10;

export const HEATMAP_THRESHOLD_COLORS = {
  BAD: "#FFFCBA",
  BEST: "#64D6A7",
  BETTER: "#B0ECB5",
  DISABLE: Colors.LIGHT_GRAY4,
  GOOD: "#E8F8B6",
  NEUTRAL: Colors.WHITE,
  WORSE: "#FFDD96",
  WORST: "#FF8F8F"
};

export const PIVOT_THRESHOLD_COLORS = {
  DISABLE: Colors.LIGHT_GRAY4,
  NEGATIVE100: "#FF8F8F",
  NEGATIVE20: "#FFF1F1",
  NEGATIVE40: "#FFDCDC",
  NEGATIVE60: "#FFC7C7",
  NEGATIVE80: "#FFABAB",
  NEUTRAL: Colors.WHITE,
  POSITIVE100: "#44C28D",
  POSITIVE20: "#E8F8F1",
  POSITIVE40: "#C4ECDB",
  POSITIVE60: "#A1E1C6",
  POSITIVE80: "#73D1AA"
};
