import cloneDeep from "lodash.clonedeep";
import difference from "lodash.difference";
import isEmpty from "lodash.isempty";
import isEqual from "lodash.isequal";
import keyBy from "lodash.keyby";
import union from "lodash.union";
import { action, computed, makeObservable, observable, toJS, when } from "mobx";
import { changeUserSortOrder } from "shared/helpers/changeUserSortOrder/changeUserSortOrder";
import getEnabledFilters from "shared/helpers/getEnabledFilters/getEnabledFilters";
import removeValueInFilter from "shared/helpers/removeValueInFilter/removeValueInFilter";
import toggleFilter from "shared/helpers/toggleFilter/toggleFilter";
import toggleFilterValue from "shared/helpers/toggleFilterValue/toggleFilterValue";
import { INFLUENCES_LIST, influenceTypeNames } from "modules/Influence/influenceConstants";
import prepareStructure from "shared/helpers/prepareStructure/prepareStructure";
import { Status } from "modules/App/Status";

import api, { isCancel } from "services/Api";
import type { RootStore } from "modules/App/Root.model";
import { InfluenceHistoryFilters, InfluenceHistoryMappings } from "./InfluenceHistory.types";
import { PAGE_INIT, FILTERS_INIT, MAPPINGS_INIT } from "./InfluenceHistory.utils";
import { InfluenceId } from "types/Influence.types";
import syncDisabledFilters from "shared/helpers/syncDisabledFilters/syncDisabledFilters";
import { FetchParams } from "shared/components/DataTable/DataTable.types";
import { valueWithLimits } from "modules/InfluenceHistory/valueWithLimits/valueWithLimits";
import filterCountsByGroup from "shared/helpers/filterCountsByGroup/filterCountsByGroup";
import { influenceHistoryMetrics } from "modules/InfluenceHistory/influenceHistoryMetrics/influenceHistoryMetrics";
import { normalizeInfluenceHistoryRow } from "./normalizeInfluenceHistoryRow/normalizeInfluenceHistoryRow";

const typeAllowingToSortByValue = ["PA"];

export class InfluenceHistoryStore {
  @observable page = cloneDeep(PAGE_INIT);
  @observable mappings: InfluenceHistoryMappings = cloneDeep(MAPPINGS_INIT);
  rootStore: RootStore;

  constructor(rootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
    this.init();
  }

  setDefaultSorting() {
    this.page.historyTable.sortBy = PAGE_INIT.historyTable.sortBy;
  }

  @action.bound
  init() {
    when(() => this.rootStore.appStore.auth?.user).then(() => {
      const { influenceHistory } = this.rootStore.appStore.auth.user;
      const newPageStructure = cloneDeep(prepareStructure({ object: influenceHistory, template: PAGE_INIT }));

      this.page = {
        ...newPageStructure,
        applied: {
          filters: {
            ...newPageStructure.filters,
            ...newPageStructure.applied.filters
          }
        },
        status: Status.DONE
      };
    });
  }

  @action.bound
  toggleFiltersEnabled() {
    this.page.filtersEnabled = !this.page.filtersEnabled;
    this.getInfluenceHistoryData();
  }

  @action.bound
  toggleFilter(filterKey: string) {
    toggleFilter(filterKey, this.page.filters, this.page.disabledFilters);
    this.getInfluenceHistoryData();
  }

  @action.bound
  toggleFilterValue(filterKey: string, filterValueToToggle: string | number) {
    toggleFilterValue(filterKey, filterValueToToggle, this.page.disabledFilters);

    this.getInfluenceHistoryData();
  }

  @action
  setTimelineCollapsed(isCollapsed: boolean) {
    this.page.influenceTimeline.isCollapsed = isCollapsed;
    this.saveView();
  }

  @action
  getInfluenceHistoryData(params?: FetchParams) {
    const { historyTable } = this.page;
    const { pageIndex = 0, pageSize = historyTable.pagination.pageSize, sortBy = historyTable.sortBy } = params || {};

    historyTable.status = Status.LOADING;
    historyTable.pagination.pageSize = pageSize;
    historyTable.pagination.pageIndex = pageIndex;
    historyTable.sortBy = sortBy;

    api
      .getInfluenceHistory({
        filters: this.normalizedFilters,
        pagination: {
          offset: pageIndex * pageSize,
          size: pageSize
        },
        sortBy
      })
      .then(({ data }) => {
        const { rows, pagination } = data;
        const pageCount = Math.ceil(pagination.totalRows / pageSize);

        historyTable.data = rows.map(normalizeInfluenceHistoryRow);
        historyTable.pagination.totalRows = pagination.totalRows;
        historyTable.pagination.pageCount = pageCount;
        historyTable.status = Status.DONE;
      })
      .catch(thrown => {
        if (isCancel(thrown)) {
          historyTable.status = Status.LOADING;
          return;
        }
        historyTable.status = Status.ERROR;
      });
  }

  @action.bound
  getSingleInfluenceHistory(influenceId: InfluenceId) {
    const { historyTable } = this.page;

    return api
      .getSingleInfluenceHistory(influenceId)
      .then(({ data }) => {
        historyTable.data = historyTable.data.map(row => {
          if (row.influenceId === influenceId) {
            data.history.shift();
            const subRows = data.history.map((subRow, index) => ({
              ...subRow,
              influenceId: `${influenceId}.${index}`,
              type: influenceTypeNames[row.type] || row.type,
              typeKey: row.typeKey,
              valueText: valueWithLimits(subRow)
            }));

            return {
              ...row,
              subRows
            };
          }
          return { ...row };
        });
      })
      .catch(() => {
        historyTable.status = Status.ERROR;
      });
  }

  @action
  getMappings() {
    this.mappings.status = Status.LOADING;

    api
      .getInfluenceHistoryMappings()
      .then(({ data }) => {
        const creators = data.creators.map(({ userId, name, email }) => ({
          email,
          label: name,
          value: userId
        }));
        const currentUserId = this.rootStore?.appStore?.userId;

        this.mappings = {
          ...data,
          creators: changeUserSortOrder(creators, currentUserId)
        };
        this.mappings.status = Status.DONE;
      })
      .catch(() => {
        this.mappings.status = Status.ERROR;
      });
  }

  @computed
  get influenceIds(): InfluenceId[] {
    const { apInfluenceIds, csInfluenceIds, minmaxInfluenceIds, percentInfluenceIds } = this.mappings;
    return ([
      ...apInfluenceIds,
      ...csInfluenceIds,
      ...minmaxInfluenceIds,
      ...percentInfluenceIds
    ] as InfluenceId[]).sort();
  }

  @computed
  get matchingInfluenceIds(): InfluenceId[] {
    const { apInfluenceIds, csInfluenceIds, minmaxInfluenceIds, percentInfluenceIds } = this.mappings;
    const selectedTypes = this.page.filters.type;
    if (isEmpty(selectedTypes)) {
      return [];
    }
    return ([
      ...(selectedTypes.includes("AP") ? apInfluenceIds : []),
      ...(selectedTypes.includes("CS") ? csInfluenceIds : []),
      ...(selectedTypes.includes("MM") ? minmaxInfluenceIds : []),
      ...(selectedTypes.includes("PA") ? percentInfluenceIds : [])
    ] as InfluenceId[]).sort();
  }

  @action
  deleteInfluences(params: { influenceIds: InfluenceId[]; comment: string }) {
    const { influenceIds, comment } = params;
    this.page.historyTable.status = Status.LOADING;
    api.deleteInfluences({ comment, influenceIds }).then(() => this.getInfluenceHistoryData());
  }

  @action.bound
  submitSearchForm() {
    const { page } = this;
    if (!isEqual(page.filters.type, typeAllowingToSortByValue)) {
      this.setDefaultSorting();
    }
    page.applied.filters = cloneDeep(toJS(page.filters));

    this.syncDisabledFilters();
    this.saveView();
    this.getInfluenceHistoryData();

    this.page.historyTable.selectedRows.clear();
  }

  @action.bound
  syncDisabledFilters() {
    syncDisabledFilters(this.page.disabledFilters, this.page.applied.filters, FILTERS_INIT);
  }

  @action.bound
  clearSearchParam(name: string) {
    const { page } = this;

    if (name in page.filters) {
      page.filters[name] = FILTERS_INIT[name];
      delete page.disabledFilters[name];
    }

    this.submitSearchForm();
  }

  @action.bound
  shiftToggleRows(selectedRow: string, clickedRow: string) {
    const { data, selectedRows } = this.page.historyTable;
    const isToggleOn = selectedRows.includes(clickedRow);
    const tableIdRows = data.filter(row => row.status === "active").map(row => row.influenceId);
    const sliceIndexes = [tableIdRows.indexOf(selectedRow), tableIdRows.indexOf(clickedRow)].sort((a, b) => a - b);
    const actionRows = tableIdRows.slice(sliceIndexes[0], sliceIndexes[1] + 1);

    this.page.historyTable.selectedRows = !isToggleOn
      ? union(selectedRows, actionRows)
      : difference(selectedRows, actionRows);
  }

  @action
  updateSelectedRows(selectedRows: string[]) {
    this.page.historyTable.selectedRows.replace(selectedRows);
  }

  @action
  saveView() {
    this.rootStore.appStore.auth.editCurrentUser({
      influenceHistory: this.pageToSave
    });
  }

  @action.bound
  changeFilter(filterKey: string, filterValue: any) {
    const { filters } = this.page;
    filters[filterKey] = filterValue;
  }

  @action.bound
  replaceFilters(newFilters: Partial<InfluenceHistoryFilters>) {
    this.page.filters = newFilters;
    this.page.applied.filters = newFilters;

    this.getInfluenceHistoryData();
  }

  @action.bound
  removeFilterValue(filterKey: string, valueToRemove: string) {
    const { disabledFilters, filters } = this.page;
    removeValueInFilter(filterKey, valueToRemove, disabledFilters, filters, FILTERS_INIT[filterKey]);
    this.submitSearchForm();
  }

  @action.bound
  setSidebarOpen(isOpen: boolean) {
    const { sidebar } = this.page;
    sidebar.isOpen = isOpen;
    if (!isOpen) {
      sidebar.filterQuery = "";
      sidebar.filterKey = null;
    }
  }

  @action.bound
  setSidebarFilterQuery(filterQuery: string, filterKey?: string) {
    const { sidebar } = this.page;
    sidebar.filterQuery = filterQuery;
    sidebar.filterKey = filterKey;
  }

  @action.bound
  changeStatus(status: Status) {
    this.page.historyTable.status = status;
  }

  @computed
  get decoratedPage() {
    const { changeFilter, filterCountsByGroup } = this;
    return {
      ...this.page,
      changeFilter,
      filterCountsByGroup
    };
  }

  @computed
  get pageToSave() {
    return {
      ...this.page,
      disabledFilters: {},
      historyTable: {
        ...PAGE_INIT.historyTable,
        sortBy: this.page.historyTable.sortBy
      }
    };
  }

  @computed
  get sortable() {
    const { filters } = this.page.applied;
    const oneFilterSelected = filters.type?.length === 1;
    const isSortable = filters.type?.every(type => INFLUENCES_LIST.find(i => i.type === type)?.sortable);

    return oneFilterSelected && isSortable;
  }

  @computed
  get isDataLoading() {
    return this.page.historyTable.status === Status.LOADING;
  }

  @computed
  get normalizedFilters() {
    return getEnabledFilters(
      this.page.applied.filters,
      this.page.disabledFilters,
      FILTERS_INIT,
      this.page.filtersEnabled
    );
  }

  @computed
  get creatorsGroupedByUserId() {
    return keyBy(this.mappings.creators, "value");
  }

  @computed
  get filterCountsByGroup() {
    const { filters, applied, disabledFilters } = this.page;
    return filterCountsByGroup(influenceHistoryMetrics, filters, applied.filters, disabledFilters, FILTERS_INIT);
  }
}
