import isEmpty from "lodash.isempty";

const displayConflictErrors = (
  { name, dateRange }: { name?: string; dateRange: { end?: string; start?: string } },
  clonedModal: { name?: string; dateRange: { end?: string; start?: string } }
) => {
  const erroredNameIsChanged = !isEmpty(clonedModal) && name && clonedModal.name === name;
  const erroredDateRangeIsChanged =
    !isEmpty(clonedModal) &&
    dateRange?.start &&
    dateRange?.end &&
    clonedModal.dateRange?.start === dateRange?.start &&
    clonedModal.dateRange?.end === dateRange?.end;
  return erroredNameIsChanged && erroredDateRangeIsChanged;
};

export default displayConflictErrors;
