import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { HandleStyles, Resizable } from "re-resizable";

export const resizableHandleStyles: HandleStyles = {
  bottom: {
    bottom: 0,
    height: 8,
    zIndex: 1
  },
  right: {
    right: -4,
    width: 8,
    zIndex: 1
  }
};

export const collapsedStyles = css`
  &.collapsed:not(.allCollapsed) {
    width: auto !important;
    min-width: 0 !important;
    flex-basis: 58px !important;
    flex-grow: 0;

    [data-testid^="module-title"] {
      flex: 1;
      flex-direction: column-reverse;
      padding: 9px 10px !important;
      justify-content: space-between;

      > h3 {
        transform-origin: left center;
        transform: translateX(50%) translateY(2px) translateX(3px) rotate(270deg);
        white-space: nowrap;
      }
    }
  }

  &.collapsed.allCollapsed {
    width: auto !important;
    min-width: 0 !important;
    flex-grow: 1;
    flex-basis: 0 !important;

    [data-testid^="module-title"] {
      justify-content: space-between;
    }
  }
`;

// @ts-ignore
export const StyledResizable = styled(Resizable)`
  padding-left: 0.25rem;
  padding-right: 0.25rem;

  ${collapsedStyles};
`;

type StyledResizableFillSpaceProps = {
  defaultHeight?: number;
  minHeight?: number;
  minWidth?: number;
  width?: number | string;
};

export const StyledResizableFillSpace = styled("div")<StyledResizableFillSpaceProps>`
  flex-grow: 1;
  padding-left: 0.25rem;
  position: relative;
  flex-basis: 100px;
  height: ${({ defaultHeight }) => (defaultHeight ? `${defaultHeight}px` : "100%")};
  min-height: ${({ minHeight }) => (minHeight ? `${minHeight}px` : 0)};
  min-width: ${({ minWidth }) => (minWidth ? `${minWidth}px` : 0)};
  width: ${({ width }) => width};

  ${collapsedStyles};
`;
