import React from "react";
import styled from "@emotion/styled";
import { Select } from "@blueprintjs/select";
import { Button, Colors, NonIdealState, Spinner } from "@blueprintjs/core";

import { Status } from "modules/App/Status";

export const StyledCol = styled("div")`
  max-height: 100%;
  div[data-testid="module-expanded"] {
    min-height: 0;
  }
  div[data-testid="module-content"] {
    max-height: 100%;
  }
`;

export const StyledSelect = styled(Select)`
  .bp3-button {
    background: linear-gradient(180deg, rgba(${Colors.WHITE}, 0.8) 0%, rgba(${Colors.WHITE}, 8e-5) 100%),
      ${Colors.LIGHT_GRAY5};
    border-radius: 3px;
    border: 1px solid rgba(${Colors.BLACK}, 0.2);
    box-shadow: 0 0 0 rgb(${Colors.BLACK} / 15%), inset 0px -1px 1px rgb(${Colors.BLACK} / 10%);
    font-size: 14px;
    margin: 0;
    max-width: 130px;
    width: auto;
    padding: 0 8px;
  }

  .bp3-button-text {
    display: flex;
    flex: 1 1 auto;
    justify-content: space-between;

    .bp3-button-label {
      display: inline-block;
      vertical-align: middle;
      max-width: 90px;
    }
  }
  .bp3-icon {
    margin: 0 0 0 4px !important;
  }
`;

type WidgetStatusProps = {
  children?: React.ReactElement | null;
  isEmpty?: boolean;
  status?: Status;
  type: string;
};

export const WidgetStatusRender = ({ status, type = "watchlist", children, isEmpty }: WidgetStatusProps) => {
  let content;

  switch (status) {
    case Status.INIT:
    default:
      content = (
        <NonIdealState
          className="text-center d-flex align-items-center"
          data-testid={`${type}-init`}
          icon="list-detail-view"
        />
      );
      break;
    case Status.LOADING:
      content = children;
      break;
    case Status.DONE:
    case Status.ERROR:
      if (isEmpty) {
        content = (
          <div className="m-5" data-testid={`${type}-is-empty`}>
            <NonIdealState icon="geosearch" title="No data in this view" />
          </div>
        );
        break;
      }
      content = children;
      break;
  }
  return content;
};

type WidgetExtractedStatusProps = {
  children?: React.ReactElement | null;
  isEmpty?: boolean;
  status?: Status;
  type: string;
};

export const WidgetExtractedStatusRender = ({
  children,
  isEmpty,
  status,
  type = "heatmap"
}: WidgetExtractedStatusProps) => {
  let content;

  switch (status) {
    case Status.INIT:
    default:
      content = <NonIdealState className={`text-center d-flex align-items-center ${type}-init`} icon="heat-grid" />;
      break;
    case Status.LOADING:
      content = (
        <div className="w-100" data-testid={`${type}-loading`}>
          <Spinner className="p-4" />
        </div>
      );
      break;
    case Status.ERROR:
      content = (
        <div className="my-5 w-100" data-testid={`${type}-error`}>
          <NonIdealState
            action={
              <Button icon="refresh" onClick={() => {}}>
                Retry Now
              </Button>
            }
            description="Please retry if possible. If this problem reoccurs, please contact FLYR."
            icon="issue"
            title="Error getting data"
          />
        </div>
      );
      break;
    case Status.DONE:
      if (isEmpty) {
        content = (
          <div className="m-5" data-testid={`${type}-is-empty`}>
            <NonIdealState icon="geosearch" title="No data in this view" />
          </div>
        );
        break;
      }
      content = children;
      break;
  }

  return content;
};
