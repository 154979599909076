// @flow
import React from "react";
import { observer } from "mobx-react";
import { Radio, RadioGroup } from "@blueprintjs/core";

import { useStores } from "store/Store";
import renderSelect from "modules/Events/renderSelect/renderSelect";

function EventsManagementProvenanceSelects() {
  const { eventsManagementStore } = useStores();
  const { airports, changeField, countries, metros, page, isSuggestModalLoading } = eventsManagementStore;
  const { modalEvent } = page;
  const { provenances, provenanceType } = modalEvent;

  return (
    <div>
      <RadioGroup
        onChange={({ currentTarget }) => changeField("provenanceType", currentTarget.value)}
        selectedValue={provenanceType}
      >
        <Radio
          data-testid="event-radio-provenances-country"
          disabled={isSuggestModalLoading}
          label="Country"
          value="countries"
        />
        {renderSelect({
          isDisabled: provenanceType !== "countries",
          isDisabledGlobally: isSuggestModalLoading,
          items: countries,
          onChange: value => changeField("countries", value, "provenances"),
          parent: "provenances",
          selectedItems: provenances?.countries,
          type: "country",
          withIATACode: true,
          withTitle: false
        })}

        <Radio
          data-testid="event-radio-provenances-metro"
          disabled={isSuggestModalLoading}
          label="Metro"
          value="metros"
        />
        {renderSelect({
          isDisabled: provenanceType !== "metros",
          isDisabledGlobally: isSuggestModalLoading,
          items: metros,
          onChange: value => changeField("metros", value, "provenances"),
          parent: "provenances",
          selectedItems: provenances?.metros,
          type: "metro",
          withIATACode: true,
          withTitle: false
        })}

        <Radio
          data-testid="event-radio-provenances-airport"
          disabled={isSuggestModalLoading}
          label="Airport"
          value="airports"
        />
        {renderSelect({
          isDisabled: provenanceType !== "airports",
          isDisabledGlobally: isSuggestModalLoading,
          items: airports,
          onChange: value => changeField("airports", value, "provenances"),
          parent: "provenances",
          selectedItems: provenances?.airports,
          type: "airport",
          withTitle: false
        })}
      </RadioGroup>
    </div>
  );
}

export default observer(EventsManagementProvenanceSelects);
