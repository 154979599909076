import pick from "lodash.pick";

import removeEmptyFilters from "../removeEmptyFilters/removeEmptyFilters";
import countUpdatedFilters from "../countUpdatedFilters/countUpdatedFilters";

function filterCountsByGroup(filterGroups, filters, appliedFilters, disabledFilters, initialFilters) {
  const notEmptyAppliedFilters = removeEmptyFilters(appliedFilters, initialFilters);
  const notEmptyFilters = removeEmptyFilters(filters, initialFilters);

  return filterGroups.reduce(
    (result, filterGroup) => {
      const { key: groupKey, filters } = filterGroup;
      const filterKeys = filters.map(({ key }) => key);

      const disabledFiltersToCount = pick(disabledFilters, filterKeys);
      const appliedFiltersToCount = pick(notEmptyAppliedFilters, filterKeys);
      const filtersToCount = pick(notEmptyFilters, filterKeys);

      result.applied[groupKey] = Object.keys(appliedFiltersToCount).length;
      result.disabled[groupKey] = Object.keys(disabledFiltersToCount).length;
      result.modified[groupKey] = countUpdatedFilters(filtersToCount, appliedFiltersToCount);

      return result;
    },
    { applied: {}, disabled: {}, modified: {} }
  );
}

export default filterCountsByGroup;
