import React, { useState } from "react";
import isEmpty from "lodash.isempty";
import { Button, Checkbox, Icon, InputGroup, MenuDivider, MenuItem, Popover, Position } from "@blueprintjs/core";
import { observer } from "mobx-react";

import SidebarFilter from "shared/components/Sidebar/SidebarFilter/SidebarFilter";
import { groups, groupTitles, isOptionVisible, items } from "./Select.utils";
import { ApplyButton, StyledMenu } from "./Select.styles";

function AggregationSelect({ aggregations = [], changeAggregations, disabled = false }) {
  const [aggregationsState, setAggregationsState] = useState<string[]>([...aggregations]);
  const [filterQuery, setFilterQuery] = useState("");

  const addAggregation = (name: string) => {
    const flatItems = items.map(item => item.value);
    setAggregationsState(
      [...aggregationsState, name].sort((a, b) => (flatItems.indexOf(a) > flatItems.indexOf(b) ? 1 : -1))
    );
  };

  const removeAggregation = (name: string) => setAggregationsState(aggregationsState.filter(aggr => aggr !== name));

  const mapItem = (item: { label: string; value: string }) => {
    const isSelected = aggregationsState.includes(item.value);
    const checkbox = (
      <Checkbox
        checked={isSelected}
        className="m-0 py-1"
        label={item.label}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          event.target.checked ? addAggregation(item.value) : removeAggregation(item.value)
        }
      />
    );

    return (
      <SidebarFilter key={item.value}>
        <MenuItem
          className="p-0"
          data-testid={`checkbox-${item.value}`}
          shouldDismissPopover={false}
          text={<div className="px-2">{checkbox}</div>}
        />
      </SidebarFilter>
    );
  };

  const applyChanges = () => {
    setFilterQuery("");
    changeAggregations(aggregationsState);
  };

  const itemGroups = groups.map(group => {
    const filteredItems = items.filter(item =>
      isEmpty(filterQuery)
        ? item.group === group
        : item.group === group && isOptionVisible(`${item.group} ${item.label.toLowerCase()}`, item.value, filterQuery)
    );

    return (
      !isEmpty(filteredItems.filter(Boolean)) && (
        <React.Fragment key={group}>
          <MenuDivider key={group} title={groupTitles[group]} />
          {filteredItems.map(mapItem)}
        </React.Fragment>
      )
    );
  });

  const menu = (
    <StyledMenu className="mb-5" data-testid="aggregation-menu">
      <InputGroup
        autoComplete="off"
        autoFocus
        className="m-1 mb-2"
        data-testid="search-aggregations"
        id="text-input"
        leftIcon="search"
        onChange={({ target }) => setFilterQuery(target.value.toLowerCase())}
        placeholder="Search..."
        rightElement={filterQuery.length ? <Button icon="cross" minimal onClick={() => setFilterQuery("")} /> : <div />}
        value={filterQuery}
      />
      {isEmpty(itemGroups.filter(Boolean)) ? <div className="p-2">No results found.</div> : itemGroups}
      <ApplyButton
        className="position-fixed"
        text={
          <Button fill intent="primary" onClick={() => applyChanges()}>
            Apply
          </Button>
        }
      />
    </StyledMenu>
  );

  const button = (
    <Button
      className="d-flex"
      data-testid="aggregations-select"
      disabled={disabled}
      icon="layout-hierarchy"
      onClick={() => setAggregationsState([...aggregations])}
    >
      <span>
        Aggregations: <strong>{aggregations && aggregations.length}</strong>
      </span>
      <Icon className="ml-3" icon="caret-down" />
    </Button>
  );

  return (
    <Popover content={menu} disabled={disabled} minimal position={Position.BOTTOM}>
      {button}
    </Popover>
  );
}

export default observer(AggregationSelect);
