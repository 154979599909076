import React from "react";
import { Colors, Spinner } from "@blueprintjs/core";
import styled from "@emotion/styled";

const StyledSpinnerWrapper = styled("div")`
  background-color: ${Colors.WHITE};
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1000;
`;

const GraphOverlaySpinner = () => {
  return (
    <StyledSpinnerWrapper className="align-items-center d-flex h-100 justify-content-center position-absolute w-100 build-curves-overlay-spinner">
      <Spinner />
    </StyledSpinnerWrapper>
  );
};

export default GraphOverlaySpinner;
