import { Button } from "@blueprintjs/core";
import cloneDeep from "lodash.clonedeep";
import { observer } from "mobx-react";
import React from "react";

import { PRICE_LIMITS_INIT } from "modules/Influence/Influence.model";
import { useStores } from "store/Store";
import { PriceLimit } from "./PriceLimits.types";

import PriceLimitsForm from "./PriceLimitsForm";
import PriceLimitsSummary from "./PriceLimitsSummary";

type Props = {
  readOnly: boolean;
};

function PriceLimits(props: Props) {
  const { readOnly } = props;
  const { influenceStore } = useStores();
  const { parameters } = influenceStore;
  const priceLimits: PriceLimit[] = parameters.inputValue;
  const submittedPriceLimits: PriceLimit[] = parameters.value;

  const renderPanels = () =>
    submittedPriceLimits.map((priceLimit: PriceLimit, index: number) => (
      <PriceLimitsSummary key={`price-limit-${index}`} index={index} submittedPriceLimits={submittedPriceLimits} />
    ));

  const renderForms = () =>
    priceLimits.map((priceLimit: PriceLimit, index: number) => (
      <PriceLimitsForm key={`price-limit-${index}`} index={index} />
    ));

  return (
    <>
      {readOnly ? renderPanels() : renderForms()}
      {!readOnly && (
        <Button
          className="mb-2"
          icon="plus"
          intent="primary"
          minimal
          onClick={() => {
            priceLimits.push(cloneDeep(PRICE_LIMITS_INIT));
            submittedPriceLimits.push(cloneDeep(PRICE_LIMITS_INIT));
          }}
        >
          Add Price Limit
        </Button>
      )}
    </>
  );
}

export default observer(PriceLimits);
