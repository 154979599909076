import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";

import { SystemSettingsDescription, SystemSettingsSectionHeader } from "./SystemSettingsUI";
import { useStores } from "store/Store";
import CirrusDefaultStatusControl from "./CirrusDefaultStatusControl";
import Row from "shared/components/Row/Row";

type Props = {
  cirrusDefaultStatus: boolean,
  onChange: Function
};

function SystemSettingsCirrusStatus(props: Props) {
  const { onChange, cirrusDefaultStatus } = props;
  const { systemSettingsStore } = useStores();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    systemSettingsStore
      .getCirrusDefaultStatus()
      .then(response => onChange({ cirrusDefaultStatus: response.cirrusDefaultStatus }))
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <>
      <SystemSettingsSectionHeader className="mt-4">Cirrus On/Off Default</SystemSettingsSectionHeader>
      <Row>
        <div className="col-6">
          <SystemSettingsDescription className="m-0">
            Enable or disable Cirrus automatic pricing on any new flights by default.
          </SystemSettingsDescription>
        </div>
        <div className="col-6 d-flex justify-content-end align-items-start">
          <CirrusDefaultStatusControl
            cirrusDefaultStatus={cirrusDefaultStatus}
            isLoading={isLoading}
            onChange={onChange}
          />
        </div>
      </Row>
    </>
  );
}

export default observer(SystemSettingsCirrusStatus);
