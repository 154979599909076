// @flow

import React from "react";
import { observer } from "mobx-react";
import { Classes, Colors, Drawer } from "@blueprintjs/core";
import styled from "@emotion/styled";

const StyledBodyContainer = styled("div")`
  ${({ footer }) => footer && `margin-bottom: 63px !important`};
`;

const StyledFooterContainer = styled("div")`
  background-color: ${Colors.WHITE};
  border-top: 1px solid ${Colors.LIGHT_GRAY3};
  bottom: 0;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 7;
`;

type Props = {
  children: ?Element,
  footer: ?Element,
  isOpen: boolean,
  positionTop: number,
  setOpen: Function
};

const Sidebar = React.forwardRef((props: Props, ref) => {
  const { children = null, footer = null, isOpen = false, positionTop = 88, setOpen } = props;

  return (
    <Drawer
      hasBackdrop={false}
      isOpen={isOpen}
      lazy={false}
      onClose={() => setOpen(false)}
      size="280px"
      style={{ top: positionTop }}
    >
      <div ref={ref} className={Classes.DRAWER_BODY} data-testid="sidebar">
        <StyledBodyContainer className={`${Classes.DIALOG_BODY} m-0`} footer={footer}>
          {children}
        </StyledBodyContainer>
      </div>
      {footer ? <StyledFooterContainer className="p-3"> {footer} </StyledFooterContainer> : null}
    </Drawer>
  );
});

export default observer(Sidebar);
