// @flow

import { replaceAllBy } from "./constants";

type ShortVersionProps = {
  group: Object
};

export default function metricShortName(props: ShortVersionProps) {
  const { group } = props;
  const newGroup = group;

  Object.entries(group).forEach((props: Object) => {
    const [key: string, value: string] = props;

    const replaces = {
      Bld: "bld build",
      "Load Factor": "lf load factor",
      Δ: "delta diff"
    };

    newGroup[key] = replaceAllBy(value, replaces).toLowerCase();
  });

  return newGroup;
}
