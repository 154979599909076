import React from "react";
import styled from "@emotion/styled";
import { Button, Card, Colors, Intent, Spinner } from "@blueprintjs/core";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";

import { useStores } from "store/Store";
import TextButton from "shared/components/TextButton/TextButton";

const CardStyled = styled(Card)`
  border: 1px solid ${Colors.LIGHT_GRAY2};
  box-shadow: none;
`;

type Props = {
  finishInfluence: () => void;
};

function InfluenceControlSummary(props: Props) {
  const { finishInfluence } = props;
  const { influenceStore, influenceHistoryStore } = useStores();
  const history = useHistory();

  const { data, status } = influenceStore;

  const navigateToInfluenceHistory = () => {
    influenceHistoryStore.replaceFilters({ adjustmentIds: [data.influenceId] });
    history.push(`/influence-history`);
  };

  function renderContent() {
    if (status === "LOADING") {
      return <Spinner className="m-1" />;
    }
    if (status === "ERROR") {
      return <h3>Saving influence failed</h3>;
    }
    return (
      <h3 className="d-flex" data-testid="influence-control-summary-saved-influence">
        <span className="mr-1">Influence saved as</span>
        <TextButton onClick={navigateToInfluenceHistory}>{data.influenceId}</TextButton>
      </h3>
    );
  }

  return (
    <div className="w-100 h-100 p-2" data-testid="influence-control-summary">
      <CardStyled className="h-100 p-2 d-flex flex-column align-items-center justify-content-center">
        {renderContent()}
        <Button
          className="m-2"
          data-testid="influence-control-summary-return-btn"
          intent={Intent.PRIMARY}
          onClick={finishInfluence}
        >
          Return to controls
        </Button>
      </CardStyled>
    </div>
  );
}

export default observer(InfluenceControlSummary);
