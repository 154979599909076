import isEmpty from "lodash.isempty";
import isEqual from "lodash.isequal";

const removeEmptyFilters = (filters: object, initialFilters: object) => {
  const normalizedFilters = { ...filters };

  Object.entries(normalizedFilters).forEach(([filterKey, filterValue]: [string, any]) => {
    if (Array.isArray(filterValue)) {
      if (isEmpty(filterValue) || isEqual(filterValue, initialFilters[filterKey])) {
        delete normalizedFilters[filterKey];
      }
    } else if (filterValue && typeof filterValue === "object") {
      if ((filterValue.start == null && filterValue.end == null) || isEqual(filterValue, initialFilters[filterKey])) {
        delete normalizedFilters[filterKey];
      }
    } else if (typeof filterValue === "undefined") {
      delete normalizedFilters[filterKey];
    }
  });

  return normalizedFilters;
};

export default removeEmptyFilters;
