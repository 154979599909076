import { NonIdealState } from "@blueprintjs/core";
import { observer } from "mobx-react";
import React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { ReactComponent as SeatAddIcon } from "../assets/seat-add.svg";

import AnalysisSidebar from "modules/Analysis/AnalysisSidebar/AnalysisSidebar";
import AnalysisSubpage from "modules/Analysis/AnalysisSubpage/AnalysisSubpage";
import AnalysisTopBar from "modules/Analysis/AnalysisTopBar/AnalysisTopBar";
import ApplicationErrorInformation from "modules/App/ApplicationErrorInformation/ApplicationErrorInformation";
import ErrorBoundary from "shared/components/ErrorBoundary/ErrorBoundary";
import FlightsTable from "modules/FlightsTable/FlightsTable";
import Resizable from "shared/components/Resizable/Resizable.component";
import StyledScrollableContainer from "shared/components/StyledScrollableContainer/StyledScrollableContainer";
import TopBar from "shared/components/TopBar/TopBar";
import isEnv from "shared/helpers/isEnv/isEnv";
import { TAB_INIT } from "models/Tab/Tab.utils";
import { useStores } from "store/Store";
import { AnalysisSubpages } from "modules/App/App.types";

const errorFallback = (
  <>
    <TopBar />
    <ApplicationErrorInformation />
  </>
);

type Props = {
  tabId: string;
};

function AnalysisContent({ tabId }: Props) {
  const route = useRouteMatch();
  const { templatesStore, tabsStore } = useStores();
  const history = useHistory();
  const tab = tabsStore.tabsById[tabId];
  const subpage = route.url.split("/").pop() as typeof AnalysisSubpages[number];

  if (!tab) {
    return (
      <NonIdealState
        className="mb-4 pb-3 flex-grow-1"
        description="It looks this tab was removed. If you want to share tab please use Import/Export Tab option"
        icon="disable"
        title="Tab not found"
      />
    );
  }

  const componentFromMySavedAnalysis = templatesStore.getComponent(tab.parentId, tab.parentType, "flightsTable");
  const baseMetrics = componentFromMySavedAnalysis
    ? componentFromMySavedAnalysis.columns
    : TAB_INIT.flightsTable.columns;

  const flightsTableContextActions =
    isEnv(["staging", "development", "test"]) && subpage !== "capacity"
      ? [
          {
            icon: <SeatAddIcon />,
            onClick: () => history.push(`/analysis/${tab.id}/capacity`),
            text: "Capacity Controls"
          }
        ]
      : null;

  return (
    <ErrorBoundary key={tab.id} fallback={errorFallback}>
      <AnalysisTopBar tabId={tab.id} />
      <AnalysisSidebar submitSearchForm={() => tab.submitSearchForm()} tabId={tab.id} />
      <StyledScrollableContainer key={tab.id} className="d-flex flex-shrink-1 flex-column px-2 pt-2 mb-2">
        {tab && <AnalysisSubpage subpage={subpage} tabId={tab.id} />}
        <Resizable icon="th-list" minHeight={340} moduleKey={`${subpage}FlightsTable`} tab={tab}>
          <FlightsTable
            baseMetrics={baseMetrics}
            moduleProps={{ contextActions: flightsTableContextActions }}
            subpage={subpage}
            tabId={tab.id}
          />
        </Resizable>
      </StyledScrollableContainer>
    </ErrorBoundary>
  );
}

export default observer(AnalysisContent);
