// @flow

import React from "react";
import { observer } from "mobx-react";
import { Intent, Menu } from "@blueprintjs/core";
import { toJS } from "mobx";

import { AppToaster } from "services/Toaster";
import SaveAsTemplateMenuItem from "./SaveAsTeamAnalysisMenuItem";
import ShareMenuItem from "./ShareMenuItem";
import { isLocalhost } from "modules/App/constants";
import { useStores } from "store/Store";

type Props = {
  inputRef: Object,
  isMatchingMySavedAnalysis: boolean,
  onSave: Function,
  setDuplicateName: Function,
  setFocusedInput: Function,
  setIsPopoverOpen: Function,
  tab: Tab
};

function TabMenu(props: Props) {
  const {
    inputRef,
    isMatchingMySavedAnalysis,
    onSave,
    setDuplicateName,
    setFocusedInput,
    setIsPopoverOpen,
    tab
  } = props;
  const { appStore, tabsStore, templatesStore } = useStores();

  const { duplicateTab, tabs } = tabsStore;
  const { savedAnalyses, isMaxMySavedAnalyses } = templatesStore;

  const tabsCount = tabs.length;
  const isUnsaved = templatesStore.checkTabUnsaved(tab.id);
  const isAlreadyUsedLabel = savedAnalyses
    .filter(view => view.id !== tab.parentId)
    .map(view => view.label)
    .includes(tab.label);

  const forceRenameLabel = (type: "saved" | "team") => {
    setFocusedInput(true);
    setDuplicateName({ match: true, show: true, type });
    inputRef.current && inputRef.current.focus();
  };

  const onSaveAnalysis = () => {
    if (tab.hasDefaultLabel) {
      return forceRenameLabel("saved");
    }

    setDuplicateName({ match: false, show: false });
    return onSave(tab);
  };

  const onCreateView = () => {
    if (isMaxMySavedAnalyses) {
      return AppToaster.show({
        intent: Intent.DANGER,
        message: "Limit of 50 Saved Analyses is reached"
      });
    }
    if (isMatchingMySavedAnalysis || tab.hasDefaultLabel) {
      return forceRenameLabel("saved");
    }

    setDuplicateName({ match: false, show: false });
    return onSave(tab);
  };

  return (
    <Menu>
      <Menu.Item disabled={tabsCount === 10} icon="duplicate" onClick={() => duplicateTab(tab.id)} text="Duplicate" />
      {tab.isMySavedAnalysis ? (
        <Menu.Item
          data-testid="patch-save-view"
          disabled={!isUnsaved || isAlreadyUsedLabel}
          icon={isUnsaved ? "floppy-disk" : "tick"}
          onClick={onSaveAnalysis}
          text={isUnsaved ? "Save" : "Saved"}
        />
      ) : (
        <Menu.Item
          data-testid="create-save-view"
          disabled={isAlreadyUsedLabel}
          icon="floppy-disk"
          onClick={onCreateView}
          text="Save"
        />
      )}
      {appStore.auth.isAdmin && <SaveAsTemplateMenuItem forceRenameLabel={forceRenameLabel} tab={tab} />}
      <ShareMenuItem closePopover={() => setIsPopoverOpen(false)} tabId={tab.id} />
      {isLocalhost && (
        <Menu.Item
          icon="code"
          onClick={() => console.log(toJS(tab))} // eslint-disable-line no-console
          text="Log to console"
        />
      )}
    </Menu>
  );
}

export default observer(TabMenu);
