import React from "react";
import { endOfToday, startOfToday, subYears } from "date-fns";
import { observer } from "mobx-react";
import difference from "lodash.difference";

import ButtonSelector from "shared/components/ButtonSelector/ButtonSelector";
import SidebarDateRangeSelect from "shared/components/Sidebar/SidebarDateRangeSelect/SidebarDateRangeSelect";
import SidebarFilter from "shared/components/Sidebar/SidebarFilter/SidebarFilter";
import SidebarSelect from "shared/components/Sidebar/SidebarSelect/SidebarSelect";
import { INFLUENCES_LIST, influenceStatusNames } from "modules/Influence/influenceConstants";
import { Status } from "modules/App/Status";
import isSidebarFilterVisible from "shared/helpers/isSidebarFilterVisible/isSidebarFilterVisible";
import { useStores } from "store/Store";

import type { Tab } from "models/Tab/Tab.model";

type Props = {
  computedDateFormat: string;
  pageContext: Tab;
};

const namesToOptions = (names: object = {}) =>
  Object.entries(names).map(([key, value]) => ({
    label: value,
    value: key
  }));

const filterItems = (query, item, index, exactMatch) => {
  const normalizedQuery = query.toLowerCase();
  const normalizedTitle = item.label.toLowerCase();
  const normalizedEmail = item.email.toLowerCase();

  if (exactMatch) {
    return normalizedTitle === normalizedQuery;
  }

  const regexpPrefix = "";
  return (
    normalizedTitle.match(new RegExp(`${regexpPrefix}${normalizedQuery}`)) ||
    normalizedEmail.match(new RegExp(`${regexpPrefix}${normalizedQuery}`))
  );
};

function InfluenceHistoryInfluenceFilters(props: Props) {
  const { computedDateFormat, pageContext } = props;
  const { disabledFilters, filters, changeFilter, sidebar } = pageContext;

  const { influenceHistoryStore } = useStores();
  const { status, creators } = influenceHistoryStore.mappings;

  const types = INFLUENCES_LIST.map(influence => ({
    label: influence.name,
    value: influence.type
  }));
  const statuses = namesToOptions(influenceStatusNames);

  const minDate = subYears(startOfToday(), 1);
  const maxDate = endOfToday();

  const { matchingInfluenceIds } = influenceHistoryStore;
  const otherInfluenceIds = difference(influenceHistoryStore.influenceIds, matchingInfluenceIds);

  return (
    <>
      <SidebarFilter
        isTemporarilyDisabled={"type" in disabledFilters}
        isVisible={isSidebarFilterVisible("type of influence", "type", sidebar)}
      >
        <SidebarSelect
          fuzzySearch
          items={types}
          onChange={(filterValue: string[]) => changeFilter("type", filterValue)}
          selectedItems={filters.type}
          title="Type of Influence"
        />
      </SidebarFilter>
      <SidebarFilter
        isTemporarilyDisabled={"adjustmentIds" in disabledFilters}
        isVisible={isSidebarFilterVisible("influence id", "adjustmentIds", sidebar)}
      >
        <SidebarSelect
          fuzzySearch
          items={[
            { groupItems: matchingInfluenceIds, label: "Matching pre-selected" },
            { groupItems: otherInfluenceIds, label: "All influences" }
          ]}
          onChange={(filterValue: string[]) => changeFilter("adjustmentIds", filterValue)}
          selectedItems={filters.adjustmentIds}
          title="Influence ID"
        />
      </SidebarFilter>
      <SidebarFilter
        isTemporarilyDisabled={"status" in disabledFilters}
        isVisible={isSidebarFilterVisible("influence status", "status", sidebar)}
      >
        <ButtonSelector
          items={statuses}
          onSelect={filterValue => changeFilter("status", filterValue)}
          selectedItems={filters.status}
          title="Influence Status"
        />
      </SidebarFilter>
      <SidebarFilter
        isTemporarilyDisabled={"createdOn" in disabledFilters}
        isVisible={isSidebarFilterVisible("influences created on", "createdOn", sidebar)}
      >
        <SidebarDateRangeSelect
          computedDateFormat={computedDateFormat}
          dates={filters.createdOn}
          maxDate={maxDate}
          minDate={minDate}
          onChange={filterValue => changeFilter("createdOn", filterValue)}
          title="Influences created on"
        />
      </SidebarFilter>
      <SidebarFilter
        isTemporarilyDisabled={"updatedOn" in disabledFilters}
        isVisible={isSidebarFilterVisible("influences modified on", "updatedOn", sidebar)}
      >
        <SidebarDateRangeSelect
          computedDateFormat={computedDateFormat}
          dates={filters.updatedOn}
          maxDate={maxDate}
          minDate={minDate}
          onChange={filterValue => changeFilter("updatedOn", filterValue)}
          title="Influences modified on"
        />
      </SidebarFilter>
      <SidebarFilter
        isTemporarilyDisabled={"userIds" in disabledFilters}
        isVisible={isSidebarFilterVisible("analyst", "userIds", sidebar)}
      >
        <SidebarSelect
          filterItems={filterItems}
          fuzzySearch
          isLoading={status === Status.LOADING}
          items={creators}
          onChange={filterValue => changeFilter("userIds", filterValue)}
          selectedItems={filters.userIds}
          title="Analyst"
          withAutoSeparatedItems
        />
      </SidebarFilter>
    </>
  );
}

export default observer(InfluenceHistoryInfluenceFilters);
