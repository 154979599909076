import { Intent } from "@blueprintjs/core";
import { action, computed, makeObservable, observable } from "mobx";
import { AxiosPromise } from "axios";

import type { FlightsResponse } from "types/Flights.types";
import type { RootStore } from "modules/App/Root.model";
import { AppToaster } from "services/Toaster";
import { FlightsTable } from "modules/FlightsTable/FlightsTable.model";
import { initColumns } from "./DashboardMarkets.utils";
import { isCancel } from "services/Api";
import { Status } from "modules/App/Status";
import { FlightCountResponse } from "types/Flights.types";

export const fetchingMarketsProps = {
  aggregations: ["rtMarket"],
  pagination: { pageCount: 1, pageIndex: 0, pageSize: 20, totalRows: 0 },
  sortBy: {
    direction: "asc",
    field: "finalRevenueExpectedPercentDiffToBaseline"
  },
  xDayBuild: 7
};
const sortByPageIndex = (a, b) => (a.pageIndex > b.pageIndex ? 1 : -1);

export const newDataWithResponse = (
  existingData: { data: []; pageIndex: number; status: Status }[],
  pageIndex: number = 0,
  status: Status,
  data: [] = []
) => {
  const existingDataCopy = [...existingData];
  const existingIndex = existingData.length && existingData.findIndex(pieceData => pieceData.pageIndex === pageIndex);

  if (existingIndex !== -1) {
    existingDataCopy[existingIndex] = {
      data,
      pageIndex: pageIndex || 0,
      status
    };
    return existingDataCopy.concat().sort(sortByPageIndex);
  }

  return [
    ...existingDataCopy,
    {
      data,
      pageIndex: pageIndex || 0,
      status
    }
  ]
    .concat()
    .sort(sortByPageIndex);
};

const getColumns = selectedMetric => [initColumns.find(column => column[1].includes(selectedMetric))] || initColumns;

export class DashboardMarketsStore {
  @observable pageIndex = 0;
  @observable progressValue = 0;
  @observable data: { data: []; pageIndex: number; status: Status }[] = [];
  @observable status: Status = Status.INIT;

  @observable selectedMetric = "finalRevenueExpectedPercentDiffToBaseline";
  @observable selectedCabinClass = ["Y"];
  @observable flightsCount: {
    numberOfRows?: number;
    status: Status;
    totalNumberOfFlights?: number;
  } = {
    numberOfRows: undefined,
    status: Status.INIT,
    totalNumberOfFlights: undefined
  };

  rootStore: RootStore;
  table: FlightsTable;

  constructor(rootStore: RootStore, newMarket?: object) {
    makeObservable(this);
    const seed = {
      ...newMarket,
      table: new FlightsTable({
        ...fetchingMarketsProps,
        apiIdentifier: "dashboardMarkets",
        columns: getColumns(this.selectedMetric),
        fixedColumns: {},
        selectedRows: null,
        sortBy: {
          direction: fetchingMarketsProps.sortBy.direction,
          field: this.selectedMetric
        }
      })
    };

    Object.assign(this, seed);

    this.rootStore = rootStore;
    this.table = seed.table;
  }

  @action.bound
  getFlightsData(params: { pageIndex?: number; pageSize?: number } = {}): AxiosPromise<FlightsResponse> {
    const flightsDataParams = {
      filters: {
        analystId: this.rootStore.dashboardStore.isMyMarkets ? this.rootStore.dashboardStore.analystFilterId : [],
        cabinClass: this.selectedCabinClass
      },
      ...fetchingMarketsProps,
      sortBy: {
        direction: fetchingMarketsProps.sortBy.direction,
        field: this.selectedMetric
      },
      ...params
    };

    this.status = Status.LOADING;

    this.table.columns = getColumns(this.selectedMetric);

    return this.table
      .fetchFlightsData(flightsDataParams, "dashboard-markets")
      .then(response => {
        this.data = newDataWithResponse(this.data, params?.pageIndex || 0, Status.DONE, response.data.rows) || [];

        this.status = Status.DONE;

        if (this.isFetchedAllData) {
          AppToaster.show({
            intent: Intent.SUCCESS,
            message: "Markets data loaded successfully"
          });
        }
      })
      .catch(thrown => {
        if (isCancel(thrown)) {
          this.status = Status.LOADING;
          return;
        }
        this.data = newDataWithResponse(this.data, params?.pageIndex || 0, Status.ERROR, []);

        this.status = Status.ERROR;
      });
  }

  @action.bound
  getFlightsCountData(params: object = {}): AxiosPromise<FlightCountResponse> {
    const { flightsCount, rootStore } = this;

    flightsCount.status = Status.LOADING;
    flightsCount.numberOfRows = undefined;
    flightsCount.totalNumberOfFlights = undefined;

    const flightsCountParams = {
      filters: {
        analystId: rootStore.dashboardStore.isMyMarkets ? rootStore.dashboardStore.analystFilterId : [],
        cabinClass: this.selectedCabinClass
      },
      ...fetchingMarketsProps,
      sortBy: {
        direction: fetchingMarketsProps.sortBy.direction,
        field: this.selectedMetric
      },
      ...params
    };

    return this.table
      .fetchFlightsCountData(flightsCountParams, "dashboard-markets-count")
      .then(data => {
        const { numberOfRows, totalNumberOfFlights } = data;
        flightsCount.numberOfRows = numberOfRows;
        flightsCount.totalNumberOfFlights = totalNumberOfFlights;
        flightsCount.status = Status.DONE;

        if (numberOfRows > 0) {
          this.getFlightsData(params);
        }
      })
      .catch(thrown => {
        if (isCancel(thrown)) {
          flightsCount.status = Status.LOADING;
          return;
        }
        flightsCount.status = Status.ERROR;
      });
  }

  @action.bound
  resetMarkets() {
    this.pageIndex = fetchingMarketsProps.pagination.pageIndex;
    this.data = [];
    this.status = Status.INIT;

    this.table.data = [];
    this.table.status = Status.INIT;
    this.table.pagination = fetchingMarketsProps.pagination;
  }

  @action.bound
  changeSelectedMetric(
    metric: string = "finalRevenueExpectedPercentDiffToBaseline",
    pageSize: number = fetchingMarketsProps.pagination.pageSize
  ) {
    if (metric !== this.selectedMetric) {
      const sortBy = {
        direction: fetchingMarketsProps.sortBy.direction,
        field: metric
      };
      this.resetMarkets();

      this.selectedMetric = metric;
      this.table.sortBy = sortBy;
      this.getFlightsCountData({ pageSize, pagination: { ...fetchingMarketsProps.pagination, pageSize }, sortBy });
    }
  }

  @action.bound
  changeSelectedCabinClassMetric(
    cabinClass: string = "Y",
    pageSize: number = fetchingMarketsProps.pagination.pageSize
  ) {
    if (!this.selectedCabinClass.includes(cabinClass)) {
      this.resetMarkets();
      this.selectedCabinClass = [cabinClass];
      this.getFlightsCountData({ pageSize, pagination: { ...fetchingMarketsProps.pagination, pageSize } });
    }
  }

  @computed
  get isError(): boolean {
    return this.status === Status.ERROR;
  }

  @computed
  get isLoaded(): boolean {
    return this.status === Status.DONE;
  }

  @computed
  get isLoading(): boolean {
    return this.status === Status.LOADING;
  }

  @computed
  get sizeData() {
    return this.data.reduce((current, pieceData) => pieceData.data.length + current, 0);
  }

  @computed
  get isFetchedAllData() {
    return this.sizeData === this.flightsCount.numberOfRows;
  }
}
