import React from "react";
import clsx from "clsx";
import pluralize from "pluralize";
import styled from "@emotion/styled";

import { Spinner } from "@blueprintjs/core";
import { observer } from "mobx-react";
import Module from "shared/components/Module/Module";
import ModuleTypeSelect from "./ModuleTypeSelect";
import { Status } from "modules/App/Status";
import { useStores } from "store/Store";
import ColorTooltip from "shared/components/ColorTooltip/ColorTooltip";
import { WidgetExtractedStatusRender } from "./Dashboard.utils";
import { heatmapMetrics } from "models/Dashboard/DashboardHeatmapStore.model";
import { ReactComponent as HeatmapLegend } from "../../assets/heatmap-legend.svg";

const StyledSvg = styled(HeatmapLegend)`
  margin-top: 19px;
  width: 100%;
`;

type Props = {
  children?: React.ReactElement | null;
  isEmpty: boolean;
  numberOfFlights: number;
  status: Status;
};

function HeatmapModule({ children = null, status, numberOfFlights, isEmpty }: Props) {
  const { dashboardHeatmapStore } = useStores();

  const { selectedMetric, changeSelectedMetric } = dashboardHeatmapStore;

  const isLoading = status === Status.LOADING;
  const isDone = status === Status.DONE;

  const withCenteredText = (isEmpty && isDone) || !isDone;

  const flightsSubtitle =
    (isLoading && [<Spinner className="mb-1" size={16} />]) ||
    (isDone && [
      <ColorTooltip href="https://help.cirrus.ai/en/articles/5379749-calendar-heatmap">
        <StyledSvg />
      </ColorTooltip>,
      ` · ${pluralize("flight", numberOfFlights, true)}`
    ]) ||
    [];

  return (
    <div className={clsx("d-flex flex-column flex-shrink-1 mh-0 h-100")}>
      <Module
        childrenClassName="flex-grow-1 overflow-auto d-flex flex-column h-100"
        className={clsx("flex-shrink-1 ", { "h-100": withCenteredText, "h-auto": !withCenteredText })}
        minHeight={0}
        subtitle={flightsSubtitle}
        title="Calendar"
        titleTools={
          <ModuleTypeSelect onChange={changeSelectedMetric} selected={selectedMetric} selectOptions={heatmapMetrics} />
        }
      >
        <div
          className={clsx("d-flex flex-column h-100 mh-100", {
            "justify-content-center": withCenteredText
          })}
          data-testid="calendar-heatmap-table"
        >
          <WidgetExtractedStatusRender isEmpty={isEmpty} status={status} type="heatmap">
            {children}
          </WidgetExtractedStatusRender>
        </div>
      </Module>
    </div>
  );
}

export default observer(HeatmapModule);
