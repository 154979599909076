import { Button, Intent } from "@blueprintjs/core";
import styled from "@emotion/styled";
import clsx from "clsx";
import { observer } from "mobx-react";

import { HIGHEST_START_NDO, influenceTypeNames } from "modules/Influence/influenceConstants";
import { minMaxOverlaps } from "modules/Influence/minMaxOverlaps/minMaxOverlaps";
import React from "react";
import Row from "shared/components/Row/Row";
import { useStores } from "store/Store";
import { InfluenceStatus } from "../Influence.model";

import { StyledCard, StyledHeaderTitle } from "../InfluenceStyles";
import NdoControl from "../NdoControl/NdoControl";
import PriceControl from "../PriceControl/PriceControl";
import PriceLimitsErrorMessage from "../PriceLimitsErrorMessage/PriceLimitsErrorMessage";
import { PriceLimit } from "./PriceLimits.types";
import { extractErrorFields } from "./validation";

const StyledMinMaxLabel = styled("strong")`
  text-align: right;
  width: 35px;
`;

type Props = {
  index: number;
};

const minMaxStartEnd = values => [
  ...values.map(value => {
    if (value.endNdo > value.startNdo) {
      return { ...value, endIsHigher: true };
    }
    if (value.startNdo > HIGHEST_START_NDO) {
      return { ...value, startNdoIsTooHigh: true };
    }
    return value;
  })
];

function PriceLimitsForm(props: Props) {
  const { index } = props;
  const { influenceStore } = useStores();
  const { status, parameters } = influenceStore;
  const priceLimits: PriceLimit[] = parameters.inputValue;
  const submittedPriceLimits: PriceLimit[] = parameters.value;

  const inputValueWithValidation = minMaxStartEnd(minMaxOverlaps(priceLimits));
  const isLoading = status.includes(InfluenceStatus.LOADING);
  const errorFields = extractErrorFields(influenceStore.validationErrors, index);
  
  return (
    <StyledCard className="p-3 mb-2" data-testid="price-limits-form">
      <Row className="mb-2">
        <div className="col-8">
          <StyledHeaderTitle>
            {influenceTypeNames.MM} {inputValueWithValidation.length > 1 && `#${index + 1}`}
          </StyledHeaderTitle>
        </div>
        <div className="col-4">
          <div className="d-flex">
            <Button
              className={clsx(["ml-auto", inputValueWithValidation.length === 1 && "invisible"])}
              data-testid="influence-price-limits-delete"
              disabled={isLoading}
              icon="trash"
              minimal
              onClick={() => {
                priceLimits.splice(index, 1);
                submittedPriceLimits.splice(index, 1);
              }}
              small
            >
              Delete
            </Button>
          </div>
        </div>
      </Row>
      <Row>
        <div className="col-5">
          <div className="d-flex flex-column mr-2 bp3-text-muted bp3-text-small" data-testid="minmax-limits-info">
            <span>Adding or removing limits will be applied to all selected flights.</span>
            <a
              className="mt-1"
              href="https://help.cirrus.ai/en/articles/4550260-influence-min-max-price-limits"
              rel="noopener noreferrer"
              target="_blank"
            >
              Read more
            </a>
          </div>
          <PriceLimitsErrorMessage
            errorFields={errorFields}
            inputValueWithValidation={inputValueWithValidation[index]}
          />
        </div>
        <div className="col-7">
          <div className="align-items-center flex-column" data-testid="influence-price-limits-value">
            <div className="d-flex align-items-center mb-2">
              <StyledMinMaxLabel className="mr-2 flex-shrink-0">Min</StyledMinMaxLabel>
              <PriceControl
                fieldName="min"
                index={index}
                intent={errorFields.includes("min") ? Intent.DANGER : Intent.NONE}
                value={inputValueWithValidation[index].min}
              />
            </div>
            <div className="d-flex align-items-center mb-2">
              <StyledMinMaxLabel className="mr-2 flex-shrink-0">Max</StyledMinMaxLabel>
              <PriceControl
                fieldName="max"
                index={index}
                intent={errorFields.includes("max") ? Intent.DANGER : Intent.NONE}
                value={inputValueWithValidation[index].max}
              />
            </div>
            <div className="d-flex align-items-center mb-2">
              <StyledMinMaxLabel className="mr-2 flex-shrink-0">Start</StyledMinMaxLabel>
              <NdoControl fieldName="startNdo" index={index} inputValueWithValidation={inputValueWithValidation[index]} />
            </div>
            <div className="d-flex align-items-center">
              <StyledMinMaxLabel className="mr-2 flex-shrink-0">End</StyledMinMaxLabel>
              <NdoControl fieldName="endNdo" index={index} inputValueWithValidation={inputValueWithValidation[index]} />
            </div>
          </div>
        </div>
      </Row>
    </StyledCard>
  );
}

export default observer(PriceLimitsForm);
