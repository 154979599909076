import React from "react";
import { Colors, Switch } from "@blueprintjs/core";
import ColumnWrapper from "../ColumnWrapper/ColumnWrapper";
import styled from "@emotion/styled";

const StyledFormHeader = styled("h4")`
  margin-bottom: 14px;
`;

const StyledGlobalInfo = styled("div")`
  color: ${Colors.GRAY4};
`;

export const renderToggleForm = ({
  isChecked,
  name,
  renderSelects,
  toggleFunction,
  type
}: {
  isChecked: boolean;
  name: string;
  renderSelects: React.ReactNode;
  toggleFunction: Function;
  type: string;
}) => (
  <ColumnWrapper type={type}>
    <StyledFormHeader className="mt-0">{name}</StyledFormHeader>
    <Switch
      checked={isChecked}
      className="mb-3"
      data-testid={`events-${type}-global-switch`}
      label="Global"
      onChange={() => toggleFunction(!isChecked)}
    />
    {!isChecked && renderSelects}
    {isChecked && <StyledGlobalInfo>Global events impact all airports in the network.</StyledGlobalInfo>}
  </ColumnWrapper>
);

export default renderToggleForm;
