import { observer } from "mobx-react";
import React, { useMemo } from "react";

import { Status } from "modules/App/Status";

import Module from "shared/components/Module/Module";
import DataTable from "shared/components/DataTable/DataTable";
import { SortingBy } from "shared/components/DataTable/DataTable.types";
import columnTextWidths from "shared/helpers/columnTextWidths/columnTextWidths";
import { useStores } from "store/Store";
import { columnLabels, columns } from "./CapacityHistory.constants";

function CapacityHistoryTable() {
  const { capacityHistoryStore, systemSettingsStore } = useStores();
  const { computedDateFormat } = systemSettingsStore;
  const { status, table } = capacityHistoryStore;
  const { data, pagination, sortBy } = table;

  const isLoaded = status === Status.DONE;
  const subtitle = isLoaded ? `${table.pagination.totalRows} rows` : null;

  const columnTextWidth = useMemo(() => columnTextWidths(columnLabels), []);

  return (
    <Module
      childrenClassName="d-flex flex-grow-1"
      subtitle={[subtitle && `· ${subtitle}`]}
      title="Capacity Adjustment History"
    >
      <DataTable
        columnLabels={columnLabels}
        columns={columns}
        columnTextWidth={columnTextWidth}
        computedDateFormat={computedDateFormat}
        data={data}
        fetchData={capacityHistoryStore.getCapacityHistoryData}
        pagination={pagination}
        rowIdAccessor="overbookingIdentifier"
        sortBy={sortBy}
        sortingBy={SortingBy.Backend}
        status={status}
      />
    </Module>
  );
}

export default observer(CapacityHistoryTable);
