export enum StatusType {
  DONE = "DONE",
  ERROR = "ERROR",
  INIT = "INIT",
  LOADING = "LOADING",
  OUTDATED = "OUTDATED"
}

export enum Status {
  DONE = StatusType.DONE,
  ERROR = StatusType.ERROR,
  INIT = StatusType.INIT,
  LOADING = StatusType.LOADING,
  OUTDATED = StatusType.OUTDATED
}
