import React from "react";
import { observer } from "mobx-react";
import { Spinner } from "@blueprintjs/core";

import FilterInteractionsWrapper from "shared/components/FilterInteractionsWrapper/FilterInteractionsWrapper";
import TopBar from "shared/components/TopBar/TopBar";
import { useStores } from "store/Store";
import MarketMappingsTagsList from "./MarketMappingsTagsList";

function MarketMappingsTopBar() {
  const { marketMappingsStore, regionsStore, usersStore } = useStores();
  const { setSidebarOpen } = marketMappingsStore;

  const isLoading = marketMappingsStore.isLoading || usersStore.isLoadingUsers || regionsStore.isLoading;

  return (
    <TopBar>
      {usersStore.isLoadingUsers ? <Spinner size={24} /> : <MarketMappingsTagsList />}
      <FilterInteractionsWrapper
        buttonProps={{
          className: "ml-auto",
          loading: isLoading,
          onClick: setSidebarOpen,
          testId: "filters-button"
        }}
        filterSwitchProps={{
          checked: marketMappingsStore.filtersEnabled,
          onChange: marketMappingsStore.toggleFiltersEnabled
        }}
      />
    </TopBar>
  );
}

export default observer(MarketMappingsTopBar);
