import React from "react";
import { observer } from "mobx-react";
import { Button, Intent } from "@blueprintjs/core";
import styled from "@emotion/styled";
import { format, parseISO } from "date-fns";
import pluralize from "pluralize";
import { SortingBy } from "shared/components/DataTable/DataTable.types";

import Module from "shared/components/Module/Module";
import DataTable from "shared/components/DataTable/DataTable";
import { Status } from "modules/App/Status";
import formatLastUpdatedText from "shared/helpers/formatLastUpdatedText/formatLastUpdatedText";
import { useStores } from "store/Store";
import isNumber from "shared/helpers/isNumber/isNumber";

const StyledRegionsModule = styled(Module)`
  max-width: 850px;

  thead tr:first-of-type {
    display: none !important;
  }
`;

function RegionsTable() {
  const { modalStore, regionsStore, systemSettingsStore } = useStores();
  const { changeSelectedRows, shiftToggleRows, table } = regionsStore;
  const { status, data, selectedRows } = table;
  const { computedDateFormat } = systemSettingsStore;

  const isLoading = status === Status.LOADING;

  const deleteItems = () => {
    const regionIds = selectedRows.filter(isNumber);
    const subregionsId = selectedRows.filter(id => typeof id === "string").map(id => Number(id.split(".").pop()));

    regionsStore.deleteItems({
      regionId: regionIds.length ? regionIds : undefined,
      subregionId: subregionsId.length ? subregionsId : undefined
    });
  };

  const showDeleteDialog = () => {
    modalStore.setModal("confirmation", {
      action: () => deleteItems(),
      buttonText: "Delete",
      header: "Confirm action",
      text: "All assigned to these items Markets will be unassigned.",
      title: `Delete (${pluralize("item", selectedRows.length, true)})`
    });
  };

  const editRegionDialog = () => {
    const [regionId] = selectedRows;

    if (typeof regionId === "number") {
      modalStore.setModal(
        "edit-regions",
        data.find(row => row.regionId === regionId)
      );
    } else if (regionId && regionId.includes(".")) {
      const [parentIndex, childIndex] = regionId.split(".").map(value => Number(value));
      const region = data.find(row => row.regionId === parentIndex);

      modalStore.setModal(
        "edit-regions",
        region.subRows && region.subRows.find(subrow => subrow.subregionId === childIndex)
      );
    }
  };

  const titleTools = (
    <div className="d-flex ml-2">
      <Button
        className="mr-2"
        disabled={isLoading || selectedRows.length !== 1}
        icon="edit"
        onClick={() => editRegionDialog()}
      >
        Edit item
      </Button>
      <Button
        className="mr-2"
        disabled={isLoading || selectedRows.length === 0}
        icon="trash"
        onClick={showDeleteDialog}
      >
        Delete ({pluralize("item", selectedRows.length, true)})
      </Button>
      <Button
        disabled={isLoading}
        icon="plus"
        intent={Intent.PRIMARY}
        onClick={() => modalStore.setModal("add-regions")}
      >
        Add item
      </Button>
    </div>
  );

  const columnLabels = {
    marketCount: "Number of Markets",
    regionName: "Region Name",
    subregionsNumber: "Subregions"
  };

  const columnSortType = {
    regionName: (rowA, rowB, id) => {
      const a = rowA.values[id];
      const b = rowB.values[id];
      return a && a.localeCompare(b);
    },
    subregionsNumber: (rowA, rowB) => {
      const a = rowA.original.subregionName || rowA.original.subRows.length;
      const b = rowB.original.subregionName || rowB.original.subRows.length;

      return a > b ? -1 : 1;
    }
  };

  const cellRenderers = {
    createdOn: ({ value }) => format(parseISO(value), `${computedDateFormat} HH:mm:ss`),
    marketCount: ({ row }) => {
      const { subregionId, subregionMarketCount, subregionsMarketCount, regionMarketCount } = row.original;
      return subregionId ? subregionMarketCount : subregionsMarketCount + regionMarketCount;
    },
    subregionsNumber: ({ row }) => row.original.subregionName || row.original.subRows.length,
    updatedOn: ({ value }) => format(parseISO(value), `${computedDateFormat} HH:mm:ss`)
  };

  const lastUpdated = data
    .map(row => row.updatedOn)
    .sort()
    .pop();
  const lastUpdatedText = lastUpdated ? formatLastUpdatedText(lastUpdated, computedDateFormat) : "";
  const subtitle = [pluralize("region", data.length, true), lastUpdatedText].filter(Boolean).join(" · ");

  return (
    <StyledRegionsModule
      childrenClassName="h-100"
      data-testid="regions-table"
      minHeight={0}
      subtitle={!isLoading ? [subtitle] : undefined}
      title="Regions & Subregions"
      titleTools={titleTools}
    >
      <DataTable
        {...table}
        cellRenderers={cellRenderers}
        columnLabels={columnLabels}
        columnSortType={columnSortType}
        columnWidths={{ regionName: 150 }}
        expandableRows
        onRowToggle={changeSelectedRows}
        onShiftToggle={shiftToggleRows}
        rowIdAccessor="regionId"
        selectableSubRows
        showPagination={false}
        sortBy={{
          direction: "asc",
          field: "regionName"
        }}
        sortingBy={SortingBy.Frontend}
      />
    </StyledRegionsModule>
  );
}

export default observer(RegionsTable);
