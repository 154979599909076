import React from "react";
import clsx from "clsx";
import styled from "@emotion/styled";
import { Direction } from "re-resizable/lib/resizer";
import { Enable, Resizable } from "re-resizable";

import { resizableHandleStyles } from "../Resizable.styles";
import { Tab } from "models/Tab/Tab.model";

type Props = {
  children: React.ReactNode;
  collapsed?: boolean;
  defaultHeight?: number | boolean;
  defaultWidth?: number;
  enable: Enable;
  maxHeight?: number | string;
  minHeight?: number;
  minWidth?: number;
  moduleKey: string;
  tab: typeof Tab;
};

// @ts-ignore
export const StyledResizableRow = styled(Resizable)`
  &.collapsed {
    height: auto !important;
    min-height: auto !important;
  }
`;

function ResizableRow({
  children,
  collapsed,
  defaultHeight,
  defaultWidth,
  enable,
  maxHeight,
  minHeight,
  minWidth,
  moduleKey,
  tab
}: Props) {
  const onResizeStop = (event: MouseEvent | TouchEvent, direction: Direction, elementRef: HTMLElement) => {
    tab.setModuleState(moduleKey, { height: elementRef.offsetHeight, isResizing: false });
  };

  return (
    <StyledResizableRow
      bounds="parent"
      boundsByDirection
      className={`pb-2 flex-nowrap w-100 d-flex ${clsx({ collapsed })}`}
      defaultSize={{
        height: defaultHeight ? `${defaultHeight}px` : "auto",
        width: defaultWidth ? `${(defaultWidth / 12) * 100}%` : "auto"
      }}
      enable={collapsed ? {} : enable}
      handleStyles={resizableHandleStyles}
      maxHeight={maxHeight}
      minHeight={minHeight}
      minWidth={minWidth ? `${(minWidth / 12) * 100}%` : undefined}
      onResizeStart={() => tab.setModuleState(moduleKey, { isResizing: true })}
      onResizeStop={onResizeStop}
    >
      {children}
    </StyledResizableRow>
  );
}

export default ResizableRow;
