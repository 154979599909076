import React, { useEffect } from "react";
import { Redirect, useParams } from "react-router-dom";
import { observer } from "mobx-react";

import AnalysisTopBarWithSidebar from "modules/Analysis/AnalysisTopBar/AnalysisTopBar";
import FlightsTable from "modules/FlightsTable/FlightsTable";
import StyledScrollableContainer from "shared/components/StyledScrollableContainer/StyledScrollableContainer";
import { TAB_INIT } from "models/Tab/Tab.utils";
import { useStores } from "store/Store";
import Influence from "modules/Influence/Influence";
import { AppToaster, Intent } from "services/Toaster";
import { useNavigationBlocker } from "shared/helpers/useNavigationBlocker/useNavigationBlocker";

const notifications = {
  influenceEditModeHasBeenClosed: {
    intent: Intent.WARNING,
    message: "Edit influence tab has been closed without saving"
  },
  someFlightsAlreadyDeparted: {
    intent: Intent.WARNING,
    message:
      "Some flights initially included in the influence have already departed, all changes will be applied to not departed flights only",
    timeout: 5000
  },
  userInEditMode: {
    intent: Intent.PRIMARY,
    message:
      "You are in Influence Edit mode. Navigation, changes in filters, metrics and aggregations are temporarily disabled"
  }
};

const adjustValue = influence => {
  if (influence.typeKey === "MM") {
    return influence.value.map(({ ndo, ...value }) => ({
      ...value,
      endNdo: ndo.end,
      startNdo: ndo.start
    }));
  }
  if (influence.typeKey === "AP") {
    return influence.value.value;
  }
  return influence.value;
};

function InfluenceEdit() {
  const { influenceId } = useParams<{ influenceId: string }>();
  const { influenceStore, influenceHistoryStore, tabsStore } = useStores();
  const tab = tabsStore.tabsById[influenceId];

  useNavigationBlocker(tab?.state?.dirty);

  useEffect(() => {
    if (tab) {
      const influence = influenceHistoryStore.page.historyTable.data.find(row => row.influenceId === influenceId);
      const value = adjustValue(influence);
      influenceStore.updateState({ influenceStep: 2, influenceType: influence.typeKey });
      influenceStore.updateParameters({ inputValue: value, value });

      AppToaster.show(notifications.userInEditMode);

      if (influence.numberOfFutureFlights < influence.numberOfFlights) {
        AppToaster.show(notifications.someFlightsAlreadyDeparted);
      }
    }
    return () => {
      tabsStore.removeTab(influenceId);
      influenceHistoryStore.updateSelectedRows([]);
      AppToaster.show(notifications.influenceEditModeHasBeenClosed);
    };
  }, [typeof tab !== "undefined"]);

  if (!tab) {
    return <Redirect to="/" />;
  }

  return (
    <>
      <AnalysisTopBarWithSidebar tabId={tab.id} />
      <StyledScrollableContainer key={tab.id} className="d-flex flex-shrink-1 flex-column px-2 pt-2 mb-2">
        <Influence tabId={tab.id} />
        <FlightsTable baseMetrics={TAB_INIT.flightsTable.columns} subpage="influence" tabId={tab.id} />
      </StyledScrollableContainer>
    </>
  );
}

export default observer(InfluenceEdit);
