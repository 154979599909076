// @flow

import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { Spinner } from "@blueprintjs/core";

import { useStores } from "store/Store";

import ErrorBoundary from "shared/components/ErrorBoundary/ErrorBoundary";
import Module from "shared/components/Module/Module";
import Row from "shared/components/Row/Row";
import TopBar from "shared/components/TopBar/TopBar";
import UserDetails from "components/Users/UserDetails";
import UsersTable from "components/Users/UsersTable";
import StyledScrollableContainer from "shared/components/StyledScrollableContainer/StyledScrollableContainer";
import { Status } from "modules/App/Status";

function Users() {
  const { usersStore, appStore } = useStores();
  const { usersList, userGroups } = usersStore;
  const user = appStore.auth && appStore.auth.user;

  useEffect(() => {
    usersStore.fetchUserGroups();
  }, []);

  if (userGroups.status === Status.LOADING) {
    return (
      <div className="m-5">
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <TopBar />
      <StyledScrollableContainer className="d-flex flex-column flex-shrink-1 p-2" data-testid="users-view">
        <Row className="h-100">
          <div className="col-4" data-testid="your-profile-card">
            {usersList.currentUserStatus === Status.LOADING ? (
              <Module title="Your Profile">
                <div className="m-5">
                  <Spinner />
                </div>
              </Module>
            ) : (
              <UserDetails
                key={user}
                editUser={(userId, metadata) => {
                  usersStore.editCurrentUser(metadata.name, appStore.auth.editCurrentUserName);
                }}
                title="Your Profile"
                user={user}
                userGroups={userGroups.data}
              />
            )}
          </div>
          <div className="col-8 h-100" data-testid="users-table">
            <ErrorBoundary>
              <UsersTable />
            </ErrorBoundary>
          </div>
        </Row>
      </StyledScrollableContainer>
    </>
  );
}

export default observer(Users);
