import React from "react";
import { AnchorButton } from "@blueprintjs/core";
import { observer } from "mobx-react";

type Props = {
  children: string;
  disabled: boolean;
  onClick: () => void;
};

function ControlButton(props: Props) {
  const { children, disabled, onClick } = props;

  return (
    <AnchorButton
      className="justify-content-between mb-2 w-100"
      data-testid="control-button"
      disabled={disabled}
      intent="primary"
      large
      onClick={onClick}
      outlined
      rightIcon="chevron-right"
    >
      {children}
    </AnchorButton>
  );
}

export default observer(ControlButton);
