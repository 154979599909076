// @flow

import { action, computed, makeObservable, observable } from "mobx";
import isEmpty from "lodash.isempty";
import api from "services/Api";

// "x" is set to be able to replace it with its delimiter at the later stage
export const dateOptions = [
  { label: "YYYYMMDD", value: `YYYYxMMxDD` },
  { label: "YYMMDD", value: `YYxMMxDD` },
  { label: "DDMMYYYY", value: `DDxMMxYYYY` },
  { label: "DDMMYY", value: `DDxMMxYY` },
  { label: "MMDDYYYY", value: `MMxDDxYYYY` },
  { label: "MMDDYY", value: `MMxDDxYY` }
];

export const delimiterOptions = [
  { label: "-", value: "-" },
  { label: "/", value: "/" },
  { label: ".", value: "." },
  { label: "none", value: "" }
];

export class SystemSettingsStore {
  @observable distanceUnit = "";
  @observable dateFormat = "";
  @observable dateDelimiter = "";
  @observable cirrusDefaultStatus = true;

  constructor() {
    makeObservable(this);
  }

  @action getDistanceUnit() {
    return api.getDistanceUnit().then(response => {
      this.distanceUnit = response.data.value;
      return response.data.value;
    });
  }

  @action setDistanceUnit(unit: string) {
    api.setDistanceUnit(unit).then(() => {
      this.distanceUnit = unit;
    });
  }

  @action getDateFormat() {
    return api.getDateFormat().then(response => {
      const date = response.data.value;
      const withoutDelimiters = date.replace(/[^DMY]/g, "");
      const [dateDelimiter = ""] = date.replace(/[DMY]/g, "");
      const dateFormat = dateOptions.find(option => option.label === withoutDelimiters).value;

      this.dateFormat = dateFormat;
      this.dateDelimiter = dateDelimiter;

      return { dateDelimiter, dateFormat };
    });
  }

  @action setDateFormat({ dateDelimiter, dateFormat }) {
    const delimiter = dateDelimiter || "";
    const date = dateFormat.replace(/x/g, delimiter);

    api.setDateFormat(date).then(() => {
      this.dateFormat = dateFormat;
      this.dateDelimiter = dateDelimiter;
    });
  }

  hasInitialStateChanged(unitsState) {
    const changes = this.compareInitialStateWith(unitsState);

    return !isEmpty(Object.values(changes).filter(Boolean));
  }

  @action saveAll(settingsState) {
    const { distanceUnit, dateFormat, dateDelimiter, cirrusDefaultStatus } = settingsState;
    const changes = this.compareInitialStateWith(settingsState);

    return Promise.all([
      changes.distance && this.setDistanceUnit(distanceUnit),
      changes.date && this.setDateFormat({ dateDelimiter, dateFormat }),
      changes.cirrusDefaultStatus && this.setCirrusDefaultStatus(cirrusDefaultStatus)
    ]);
  }

  compareInitialStateWith(settingsState) {
    const {
      cirrusDefaultStatus = this.cirrusDefaultStatus,
      distanceUnit = this.distanceUnit,
      dateFormat = this.dateFormat,
      dateDelimiter = this.dateDelimiter
    } = settingsState;

    const hasDistanceChanged = distanceUnit !== this.distanceUnit;
    const hasDateChanged = dateFormat !== this.dateFormat || dateDelimiter !== this.dateDelimiter;
    const hasCirrusStatusChanged = cirrusDefaultStatus !== this.cirrusDefaultStatus;

    return {
      cirrusDefaultStatus: hasCirrusStatusChanged,
      date: hasDateChanged,
      distance: hasDistanceChanged
    };
  }

  @computed
  get computedDateFormat() {
    if (this.dateFormat) {
      const parsedFormat = this.dateFormat;
      return parsedFormat.toLowerCase().replace(/m/g, "M").replace(/x/g, this.dateDelimiter);
    }

    return "yyyy-MM-dd";
  }

  @computed
  get computedDateMonthFormat() {
    if (this.dateFormat) {
      const parsedFormat = this.dateFormat;
      return parsedFormat
        .toLowerCase()
        .replace(/xdd/g, "")
        .replace(/ddx/g, "")
        .replace(/m/g, "M")
        .replace(/x/g, this.dateDelimiter);
    }

    return "yyyy-MM";
  }

  @action
  getCirrusDefaultStatus() {
    return api.getCirrusDefaultStatus().then(response => {
      const cirrusDefaultStatus = response.data.value;
      this.cirrusDefaultStatus = cirrusDefaultStatus;

      return { cirrusDefaultStatus };
    });
  }

  @action
  setCirrusDefaultStatus(status: boolean) {
    return api.setCirrusDefaultStatus(status).then(() => {
      this.cirrusDefaultStatus = status;
    });
  }

  @computed
  get isMiles() {
    return this.distanceUnit === "mi";
  }
}
