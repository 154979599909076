// @flow

import React, { useEffect } from "react";
import { observer } from "mobx-react";

import { useStores } from "store/Store";
import Watchlist from "./Watchlist";
import { StyledCol } from "./Dashboard.utils";
import WatchlistModule from "./WatchlistModule";

function WatchlistContainer() {
  const { analysisMappingsStore, dashboardStore, dashboardWatchlistStore } = useStores();
  const { activePage, isLoaded } = dashboardStore;
  const { data, getWatchlistData, resetWatchlist, status } = dashboardWatchlistStore;
  const { isAnalystDone } = analysisMappingsStore;

  useEffect(() => {
    return () => {
      resetWatchlist();
    };
  }, [activePage, resetWatchlist]);

  useEffect(() => {
    if (isLoaded && isAnalystDone) {
      getWatchlistData();
    }
  }, [activePage, isAnalystDone, isLoaded, getWatchlistData]);

  const isEmpty = !data.length;

  return (
    <StyledCol className="col-4 mh-0">
      <WatchlistModule isEmpty={isEmpty} status={status}>
        <Watchlist />
      </WatchlistModule>
    </StyledCol>
  );
}

export default observer(WatchlistContainer);
