/* eslint-disable sort-keys */

const characterMap = {
  " ": 4,
  "(": 5,
  ")": 5,
  "+": 8,
  "-": 6,
  ".": 4,
  "/": 4,
  0: 8,
  1: 6,
  2: 8,
  3: 8,
  4: 8,
  5: 8,
  6: 8,
  7: 7,
  8: 8,
  9: 8,
  ":": 4,
  "@": 12,
  "%": 11,
  $: 8,
  ",": 4,
  A: 9,
  B: 9,
  C: 9,
  D: 10,
  E: 8,
  F: 7,
  G: 10,
  H: 10,
  I: 3,
  J: 7,
  K: 9,
  L: 7,
  M: 12,
  N: 10,
  O: 10,
  P: 8,
  Q: 10,
  R: 9,
  S: 8,
  T: 8,
  U: 10,
  V: 9,
  W: 13,
  X: 9,
  Y: 9,
  Z: 9,
  a: 7,
  b: 8,
  c: 7,
  d: 8,
  e: 7,
  f: 4,
  g: 8,
  h: 8,
  i: 3,
  j: 3,
  k: 7,
  l: 3,
  m: 12,
  n: 8,
  o: 8,
  p: 8,
  q: 8,
  r: 5,
  s: 7,
  t: 4,
  u: 8,
  v: 7,
  w: 10,
  x: 7,
  y: 7,
  z: 7
};

export const sumWidthOfChars = (text: string = "", padding = 0) => {
  return [...`${text}`].reduce((count, char) => {
    return count + (characterMap[char] || 9);
  }, padding);
};

const calculateRenderTypePadding = (type, value) => {
  const valueLength = `${value}`.length;
  const decimalSeparatorCount = Math.ceil(valueLength / 3) - 1;

  switch (type) {
    case "currency":
      return decimalSeparatorCount * characterMap[","] + characterMap.$;
    default:
      return 0;
  }
};

const getColumnWidth = args => {
  const { accessor, data, fixedWidth, headerWidth = 62, padding = 8, renderType } = args;

  if (fixedWidth) {
    return fixedWidth;
  }

  const maxWidth = 600;
  const minWidth = 50;
  const cellWidth = Math.max(
    ...data.map(row => {
      const currentValue = row[accessor];
      if (typeof currentValue === "object" && currentValue !== null) {
        return Object.keys(currentValue).reduce((accumulator, key) => {
          if (!currentValue[key]) {
            return accumulator;
          }

          const valueLength = sumWidthOfChars(currentValue[key]);
          return accumulator + valueLength;
        }, 1);
      }
      const typePadding = calculateRenderTypePadding(renderType, currentValue);

      return sumWidthOfChars(currentValue || "", typePadding);
    }),
    headerWidth,
    minWidth
  );

  return Math.min(maxWidth, cellWidth + padding);
};

export default getColumnWidth;
