import { Button } from "@blueprintjs/core";
import { observer } from "mobx-react";
import React from "react";
import { Cell } from "react-table";

import { Status } from "modules/App/Status";
import { useStores } from "store/Store";

import DataTable from "shared/components/DataTable/DataTable";
import { SortingBy } from "shared/components/DataTable/DataTable.types";
import Module from "shared/components/Module/Module";
import StatusIndicator from "shared/components/StatusIndicator/StatusIndicator";

function UsersTable() {
  const { modalStore, usersStore } = useStores();
  const { columns, data, pagination, status } = usersStore.usersList;

  const isLoading = status === Status.LOADING;

  const columnLabels = {
    email: "Email",
    emailVerified: "Verified",
    group: "Role",
    name: "Name"
  };

  const sortBy = {
    direction: "asc",
    field: "name"
  };

  const columnSortType = {
    emailVerified: (rowA, rowB, id) => {
      const a = rowA.values[id];
      const b = rowB.values[id];

      return a > b ? -1 : 1;
    },
    name: (rowA, rowB, id) => {
      const a = rowA.values[id];
      const b = rowB.values[id];
      return a.localeCompare(b);
    }
  };

  const cellRenderers = {
    emailVerified: ({ value }: Cell) => <StatusIndicator isActive={value} text={value ? "Yes" : "No"} />,
    group: ({ value }) => value || "-"
  };

  const addUserButton = (
    <Button
      disabled={isLoading}
      icon="plus"
      onClick={e => {
        e.stopPropagation();
        modalStore.setModal("addUser", data);
      }}
      text="Invite User"
    />
  );

  return (
    <Module
      childrenClassName="h-100 overflow-auto"
      className="mh-100"
      minHeight={0}
      title="Users"
      titleTools={addUserButton}
    >
      <DataTable
        cellRenderers={cellRenderers}
        columnLabels={columnLabels}
        columns={columns}
        columnSortType={columnSortType}
        data={data}
        fetchData={usersStore.fetchUsers}
        onRowClick={row => modalStore.setModal("editUser", row.original)}
        pagination={pagination}
        rowIdAccessor="userId"
        showPagination={false}
        sortBy={sortBy}
        sortingBy={SortingBy.Frontend}
        status={status}
      />
    </Module>
  );
}

export default observer(UsersTable);
