import { observable, action, computed, makeObservable } from "mobx";

export class ThemeStore {
  @observable
  style = "light";

  constructor() {
    makeObservable(this);
  }

  @action
  setTheme(newTheme: string) {
    this.style = newTheme;
  }

  @computed
  get themeClass() {
    return `theme-${this.style}`;
  }
}
