import React, { useState } from "react";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import { Intent, Tooltip } from "@blueprintjs/core";
import isEmpty from "lodash.isempty";
import { UseTableRowProps } from "react-table";

import { AppToaster } from "services/Toaster";
import LinkCell, { StyledAnchorButton } from "shared/components/DataTable/LinkCell/LinkCell";
import { useStores } from "store/Store";
import { AnalysisSubpages } from "modules/App/App.types";

// @TODO: implement proper row type
type FlightsTableRow = UseTableRowProps<{ adjustmentExists: boolean }> & { isSelected: boolean };

type Props = {
  getRowInfluenceIds: Function;
  row: FlightsTableRow;
  subpage: typeof AnalysisSubpages[number];
};

function InfluenceHistoryLinkCell(props: Props) {
  const { row, subpage, getRowInfluenceIds } = props;
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();
  const { influenceHistoryStore } = useStores();

  const onCellClick = () => {
    setIsLoading(true);
    getRowInfluenceIds(row?.id).then((influenceList: string[] = []) => {
      history.push(`/influence-history`);
      if (!isEmpty(influenceList)) {
        influenceHistoryStore.replaceFilters({ adjustmentIds: [...influenceList] });
      } else {
        AppToaster.show({
          intent: Intent.PRIMARY,
          message: "There are no influences on the selected flights."
        });
      }
    });
  };

  const isAdjustmentExist = row.original.adjustmentExists === true;
  const isLinkDisplayed = isAdjustmentExist && (subpage === "explore" || (subpage === "influence" && row.isSelected));

  const tooltipText = isLinkDisplayed
    ? "View all influences for this collection of flight(s)"
    : "This collection of flights does not have any influences";

  return (
    <Tooltip
      className="d-flex justify-content-center align-items-center h-100"
      content={tooltipText}
      hoverOpenDelay={1000}
      targetClassName="justify-content-center"
    >
      {isLinkDisplayed ? (
        <LinkCell isLoading={isLoading} onClick={onCellClick} />
      ) : (
        <StyledAnchorButton disabled={true} />
      )}
    </Tooltip>
  );
}

export default observer(InfluenceHistoryLinkCell);
