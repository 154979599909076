import React from "react";
import { observer } from "mobx-react";
import { useTable } from "react-table";

import { TableColumn } from "../DataTable.types";
import DataTableRows from "../DataTableRows/DataTableRows";
import { Cell, Row } from "types/react-table.types";
import { CellType } from "types/DataTable.types";

type Props = {
  cellRenderers?: { [key: string]: CellType | ((cell: Cell) => React.Component) };
  columnConfig?: { columnsWithoutFixedNumber: string[]; columnsAligned: { [key: string]: string } };
  columns: TableColumn[];
  data: object[];
  extraCellStyles: object;
  prepareRow: (row: Row) => void;
};

function DataTableFixedFooter({ cellRenderers, columns, columnConfig, data, extraCellStyles, prepareRow }: Props) {
  const { rows } = useTable({
    columns,
    data
  });

  return (
    <DataTableRows
      cellRenderers={cellRenderers}
      columnConfig={columnConfig}
      extraCellStyles={extraCellStyles}
      prepareRow={prepareRow}
      rows={rows}
    />
  );
}
export default observer(DataTableFixedFooter);
