import React from "react";
import { observer } from "mobx-react";
import DisabledFilterIndicator from "../../DisabledFilterIndicator/DisabledFilterIndicator";
import clsx from "clsx";

type Props = {
  children: React.ReactElement | null;
  isTemporarilyDisabled?: boolean;
  isVisible?: boolean;
};

function SidebarFilter(props: Props) {
  const { isVisible = true, isTemporarilyDisabled = false, children = null } = props;
  const classNames = clsx("position-relative", isVisible ? "visible-filter" : "d-none");

  return (
    <div className={classNames} data-testid="sidebar-filter">
      {isTemporarilyDisabled && <DisabledFilterIndicator data-testid="disabled-indicator" />}
      {children}
    </div>
  );
}

export default observer(SidebarFilter);
