import React from "react";
import { observer } from "mobx-react";
import pick from "lodash.pick";
import difference from "lodash.difference";

import SidebarSelect from "shared/components/Sidebar/SidebarSelect/SidebarSelect";
import SidebarFilter from "shared/components/Sidebar/SidebarFilter/SidebarFilter";
import isSidebarFilterVisible from "shared/helpers/isSidebarFilterVisible/isSidebarFilterVisible";
import { RegionsStore } from "models/Regions.model";
import getGroupItemsForFilter from "shared/helpers/getGroupItemsForFilter/getGroupItemsForFilter";

import type { AnalysisMappingsStore } from "models/AnalysisMappings.model";
import type { Tab } from "models/Tab/Tab.model";

type Props = {
  mappings: AnalysisMappingsStore;
  pageContext: Tab;
  regions: RegionsStore;
};

function MarketFilters(props: Props) {
  const { mappings, pageContext, regions } = props;

  const { disabledFilters, filters, changeFilter, sidebar } = pageContext;
  const { getRoundTripMarkets, getOneWayMarkets, getOrigins, getDestinations } = mappings;

  const rtMarketFilters = pick(filters, ["owMarket", "rtMarket", "origin", "destination", "regionId", "subregionId"]);
  const owMarketFilters = pick(filters, ["rtMarket", "origin", "destination", "regionId", "subregionId"]);
  const originFilters = pick(filters, ["rtMarket", "owMarket", "destination", "regionId", "subregionId"]);
  const destinationFilters = pick(filters, ["rtMarket", "owMarket", "origin", "regionId", "subregionId"]);
  const regionFilters = pick(filters, ["subregionId"]);
  const subregionFilters = pick(filters, ["regionId"]);

  const matchingGroupLabel = "Matching pre-selected";

  const [rtMarkets, matchingRtMarkets] = getGroupItemsForFilter(rtMarketFilters, getRoundTripMarkets);
  const [owMarkets, matchingOwMarkets] = getGroupItemsForFilter(owMarketFilters, getOneWayMarkets);
  const [origins, matchingOrigins] = getGroupItemsForFilter(originFilters, getOrigins);
  const [destinations, matchingDestinations] = getGroupItemsForFilter(destinationFilters, getDestinations);

  const matchingRegions = regions.getRegionsFromSelectedSubregions(regionFilters, "subregionId");
  const otherRegions = difference(regions.regionsForFilter, matchingRegions);

  const matchingSubregions = regions.getSubregionsFromSelectedRegions(subregionFilters, "regionId");
  const otherSubregions = difference(regions.subregionsForFilter, matchingSubregions);

  return (
    <>
      <SidebarFilter
        isTemporarilyDisabled={"regionId" in disabledFilters}
        isVisible={isSidebarFilterVisible("market region", "regionId", sidebar)}
      >
        <SidebarSelect
          fuzzySearch
          items={[
            { groupItems: matchingRegions, label: matchingGroupLabel },
            { groupItems: otherRegions, label: "All regions" }
          ]}
          onChange={filterValue => changeFilter("regionId", filterValue)}
          selectedItems={filters.regionId}
          title="Region"
          withAutoSeparatedItems
        />
      </SidebarFilter>
      <SidebarFilter
        isTemporarilyDisabled={"subregionId" in disabledFilters}
        isVisible={isSidebarFilterVisible("market subregion", "subregionId", sidebar)}
      >
        <SidebarSelect
          fuzzySearch
          items={[
            { groupItems: matchingSubregions, label: matchingGroupLabel },
            { groupItems: otherSubregions, label: "All subregions" }
          ]}
          onChange={filterValue => changeFilter("subregionId", filterValue)}
          selectedItems={filters.subregionId}
          title="Subregion"
          withAutoSeparatedItems
        />
      </SidebarFilter>
      <SidebarFilter
        isTemporarilyDisabled={"rtMarket" in disabledFilters}
        isVisible={isSidebarFilterVisible("market rt market", "rtMarket", sidebar)}
      >
        <SidebarSelect
          fuzzySearch
          items={[
            { groupItems: matchingRtMarkets, label: matchingGroupLabel },
            { groupItems: rtMarkets, label: "All markets" }
          ]}
          onChange={filterValue => changeFilter("rtMarket", filterValue)}
          selectedItems={filters.rtMarket}
          title="RT Market"
          withAutoSeparatedItems
        />
      </SidebarFilter>
      <SidebarFilter
        isTemporarilyDisabled={"owMarket" in disabledFilters}
        isVisible={isSidebarFilterVisible("market ow market", "owMarket", sidebar)}
      >
        <SidebarSelect
          fuzzySearch
          items={[
            { groupItems: matchingOwMarkets, label: matchingGroupLabel },
            { groupItems: owMarkets, label: "All markets" }
          ]}
          onChange={filterValue => changeFilter("owMarket", filterValue)}
          selectedItems={filters.owMarket}
          title="OW Market"
          withAutoSeparatedItems
        />
      </SidebarFilter>
      <SidebarFilter
        isTemporarilyDisabled={"origin" in disabledFilters}
        isVisible={isSidebarFilterVisible("market origin", "origin", sidebar)}
      >
        <SidebarSelect
          fuzzySearch
          items={[
            { groupItems: matchingOrigins, label: matchingGroupLabel },
            { groupItems: origins, label: "All airports" }
          ]}
          onChange={filterValue => changeFilter("origin", filterValue)}
          selectedItems={filters.origin}
          title="Origin"
          withAutoSeparatedItems
        />
      </SidebarFilter>
      <SidebarFilter
        isTemporarilyDisabled={"destination" in disabledFilters}
        isVisible={isSidebarFilterVisible("market destination", "destination", sidebar)}
      >
        <SidebarSelect
          fuzzySearch
          items={[
            { groupItems: matchingDestinations, label: matchingGroupLabel },
            { groupItems: destinations, label: "All airports" }
          ]}
          onChange={filterValue => changeFilter("destination", filterValue)}
          selectedItems={filters.destination}
          title="Destination"
          withAutoSeparatedItems
        />
      </SidebarFilter>
    </>
  );
}
export default observer(MarketFilters);
