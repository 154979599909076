export type Environment = "development" | "staging" | "integration" | "production" | "test";

type RUVEnabled = {
  ruvEnabled: true;
  revenueUpliftTotal: number;
  revenueUpliftPercent: number;
  period: number;
  observedDate: string; // yyyy-mm-dd
  bqCreatedAt: string; // yyyy-mm-ddThh:mm:ssZ"
};

type RUVExpired = {
  // older than 48h
  ruvEnabled: true;
  observedDate: string; // yyyy-mm-dd
  bqCreatedAt: string; // yyyy-mm-ddThh:mm:ssZ"
};

type RUVDisabled = {
  ruvEnabled: false;
};

export enum Mode {
  P2P = "P2P",
  Network = "NETWORK"
}

export type RUV = RUVEnabled | RUVExpired | RUVDisabled;

export const AnalysisSubpages = ["explore", "influence", "capacity"] as const;
