import { PageContextFilters } from "types/Flights.types";
import { Status } from "modules/App/Status";
import { BaseEventsFields, SuggestedEvents, TypesEventsMappings } from "./EventsManagement.types";

export const INIT_EVENT_BASE_FIELDS: BaseEventsFields = {
  isLocationGlobal: true,
  isProvenanceGlobal: true,
  name: "",
  nonWorkingDay: false
};

export const INIT_SUGGESTED_EVENT: SuggestedEvents = {
  data: [],
  extraEvents: 0,
  status: Status.INIT
};

export const FILTERS_INIT: Partial<PageContextFilters> = {
  categoryIds: [],
  dateRange: {
    end: "",
    start: ""
  },
  destination: [],
  origin: [],
  owMarket: [],
  rtMarket: [],
  userIds: []
};

export const PAGE_INIT = {
  applied: {
    filters: { ...FILTERS_INIT }
  },
  clonedModalEvent: {},
  disabledFilters: {},
  eventsTable: {
    columns: [
      [
        "events",
        [
          "id",
          "name",
          "categoryName",
          "nonWorkingDay",
          "startDate",
          "endDate",
          "locations",
          "provenances",
          "updatedBy",
          "updatedOn"
        ]
      ]
    ],
    data: [],
    pagination: {
      pageCount: 1,
      pageIndex: 0,
      pageSize: 25,
      totalRows: 0
    },
    selectedRows: [],
    sortBy: {
      direction: "asc",
      field: "startDate"
    },
    status: Status.INIT
  },
  filters: { ...FILTERS_INIT },
  filtersEnabled: true,
  modalEvent: { ...INIT_EVENT_BASE_FIELDS },
  modalSuggestStatus: Status.INIT,
  selectedEvent: {},
  sidebar: {
    filterKey: "",
    filterQuery: "",
    isOpen: false
  },
  suggestedEvents: { ...INIT_SUGGESTED_EVENT },
  upcomingEvents: true
};

export const EVENTS_MAPPINGS_INIT: TypesEventsMappings = {
  data: {} as TypesEventsMappings["data"],
  status: Status.INIT
};
