import { observer } from "mobx-react";
import React from "react";

import Row from "shared/components/Row/Row";
import CapacityControls from "./CapacityControls/CapacityControls";
import CapacityHistoryTable from "modules/CapacityHistory/CapacityHistoryTable";

function Capacity() {
  return (
    <Row className="mb-2">
      <div className="col-7">
        <CapacityHistoryTable />
      </div>
      <div className="col-5">
        <CapacityControls />
      </div>
    </Row>
  );
}

export default observer(Capacity);
