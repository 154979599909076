// @flow

import React from "react";
import { observer } from "mobx-react";
import pick from "lodash.pick";

import { useStores } from "store/Store";
import SidebarSelect from "shared/components/Sidebar/SidebarSelect/SidebarSelect";
import SidebarFilter from "shared/components/Sidebar/SidebarFilter/SidebarFilter";
import isSidebarFilterVisible from "shared/helpers/isSidebarFilterVisible/isSidebarFilterVisible";
import getGroupItemsForFilter from "shared/helpers/getGroupItemsForFilter/getGroupItemsForFilter";

type Props = {
  pageContext: Object
};

function EventsMarketFilters(props: Props) {
  const { pageContext } = props;
  const { disabledFilters, filters, changeFilter, sidebar } = pageContext;
  const { analysisMappingsStore } = useStores();
  const { getRoundTripMarkets, getOneWayMarkets, getOrigins, getDestinations } = analysisMappingsStore;

  const roundTripFilters = pick(filters, ["owMarket", "rtMarket", "origin", "destination"]);
  const oneWayFilters = pick(filters, ["rtMarket", "origin", "destination"]);
  const originFilters = pick(filters, ["rtMarket", "owMarket", "destination"]);
  const destinationFilters = pick(filters, ["rtMarket", "owMarket", "origin"]);

  const matchingGroupLabel = "Matching pre-selected";

  const [rtMarkets, matchingRtMarkets] = getGroupItemsForFilter(roundTripFilters, getRoundTripMarkets);
  const [owMarkets, matchingOwMarkets] = getGroupItemsForFilter(oneWayFilters, getOneWayMarkets);
  const [origins, matchingOrigins] = getGroupItemsForFilter(originFilters, getOrigins);
  const [destinations, matchingDestinations] = getGroupItemsForFilter(destinationFilters, getDestinations);

  return (
    <>
      <SidebarFilter
        isTemporarilyDisabled={"rtMarket" in disabledFilters}
        isVisible={isSidebarFilterVisible("market rt market", "rtMarket", sidebar)}
      >
        <SidebarSelect
          fuzzySearch
          items={[
            { groupItems: matchingRtMarkets, label: matchingGroupLabel },
            { groupItems: rtMarkets, label: "All markets" }
          ]}
          onChange={filterValue => changeFilter("rtMarket", filterValue)}
          selectedItems={filters.rtMarket}
          title="RT Market"
          withAutoSeparatedItems
        />
      </SidebarFilter>
      <SidebarFilter
        isTemporarilyDisabled={"owMarket" in disabledFilters}
        isVisible={isSidebarFilterVisible("market ow market", "owMarket", sidebar)}
      >
        <SidebarSelect
          fuzzySearch
          items={[
            { groupItems: matchingOwMarkets, label: matchingGroupLabel },
            { groupItems: owMarkets, label: "All markets" }
          ]}
          onChange={filterValue => changeFilter("owMarket", filterValue)}
          selectedItems={filters.owMarket}
          title="OW Market"
          withAutoSeparatedItems
        />
      </SidebarFilter>
      <SidebarFilter
        isTemporarilyDisabled={"origin" in disabledFilters}
        isVisible={isSidebarFilterVisible("market origin", "origin", sidebar)}
        name="market origin"
      >
        <SidebarSelect
          fuzzySearch
          items={[
            { groupItems: matchingOrigins, label: matchingGroupLabel },
            { groupItems: origins, label: "All airports" }
          ]}
          onChange={filterValue => changeFilter("origin", filterValue)}
          selectedItems={filters.origin}
          title="Origin"
          withAutoSeparatedItems
        />
      </SidebarFilter>
      <SidebarFilter
        isTemporarilyDisabled={"destination" in disabledFilters}
        isVisible={isSidebarFilterVisible("market destination", "destination", sidebar)}
      >
        <SidebarSelect
          fuzzySearch
          items={[
            { groupItems: matchingDestinations, label: matchingGroupLabel },
            { groupItems: destinations, label: "All airports" }
          ]}
          onChange={filterValue => changeFilter("destination", filterValue)}
          selectedItems={filters.destination}
          title="Destination"
          withAutoSeparatedItems
        />
      </SidebarFilter>
    </>
  );
}
export default observer(EventsMarketFilters);
