// @flow

import React, { useState } from "react";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import styled from "@emotion/styled";
import { AnchorButton, Button, Colors, Icon, Intent, Spinner } from "@blueprintjs/core";

import HeaderTabs from "./HeaderTabs";
import { useStores } from "store/Store";

const StyledTemplateEditTitle = styled("span")`
  color: ${Colors.WHITE}99;
  cursor: default;
`;

const StyledReadMoreButton = styled(AnchorButton)`
  background: none !important;

  &:focus {
    outline: none;
  }
`;

const StyledIcon = styled(Icon)`
  svg:hover {
    fill: ${Colors.GRAY2};
  }
`;

function TeamAnalysisEditHeader() {
  const { tabsStore, modalStore, templatesStore } = useStores();
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();

  const tabs = tabsStore.tabs.filter(tab => !tab.editAnalysis);

  const rightIcon = isLoading ? <Spinner size={12} /> : undefined;

  const lastTabPath = tabs.length > 0 ? `/analysis/${tabs[tabs.length - 1].id}/influence` : "/";

  const saveTemplate = () => {
    const tab = tabsStore.lastTab;
    setLoading(true);

    templatesStore.editTeamAnalysis(tab).then(
      () => {
        tabsStore.removeEditAnalysisTab();
        tabsStore.saveTabs();
        history.push(lastTabPath);
      },
      () => {
        setLoading(false);
      }
    );
  };

  const closeModal = () => {
    tabsStore.removeEditAnalysisTab();
    modalStore.closeModal();
    history.push(lastTabPath);
  };

  const cancelEditingDialog = () => {
    if (!isLoading) {
      if (tabsStore.isEditingTemplateChanged) {
        modalStore.setModal("confirmation", {
          action: closeModal,
          buttonText: "Quit",
          header: "You have unsaved changes",
          text: "Cancelling will discard all unsaved changes. You won’t be able to restore your work.",
          title: "Quit without saving?"
        });
      } else closeModal();
    }
  };

  return (
    <>
      <div className="ml-2 mr-2 d-flex align-items-center">
        <StyledTemplateEditTitle>Team Analysis Edit Mode </StyledTemplateEditTitle>
        <StyledReadMoreButton
          aria-label="Read more"
          href="https://help.cirrus.ai/en/articles/5203999-managing-team-analyses"
          minimal
          rel="noopener noreferrer"
          target="_blank"
        >
          <StyledIcon color={Colors.GRAY3} icon="help" />
        </StyledReadMoreButton>
      </div>

      <HeaderTabs className="flex-grow-1" isEditingTemplate />

      <Button
        className="align-self-center mr-2"
        data-testid="edit-analysis-cancel"
        intent={Intent.NONE}
        onClick={cancelEditingDialog}
      >
        Cancel
      </Button>

      <Button
        className="align-self-center"
        data-testid="edit-analysis-save"
        disabled={isLoading || !tabsStore.isEditingTemplateChanged}
        intent={Intent.PRIMARY}
        onClick={saveTemplate}
        rightIcon={rightIcon}
      >
        Save
      </Button>
    </>
  );
}

export default observer(TeamAnalysisEditHeader);
