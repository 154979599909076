// @flow

import React, { useEffect } from "react";
import { NonIdealState } from "@blueprintjs/core";
import { observer } from "mobx-react";
import styled from "@emotion/styled";

import DashboardTopBar from "components/Dashboard/TopBar";
import HeatmapTable from "components/Dashboard/HeatmapTable";
import MarketMonthsTable from "components/Dashboard/MarketMonthsTable";
import MarketsModuleContainer from "components/Dashboard/MarketsModuleContainer";
import Module from "shared/components/Module/Module";
import Row from "shared/components/Row/Row";
import WatchlistContainer from "components/Dashboard/WatchlistContainer";
import { useStores } from "store/Store";

const StyledRow = styled(Row)`
  max-height: 50%;
  min-height: 315px;
`;

const StyledDashboardRowContainer = styled("div")`
  @media only screen and (min-height: 790px) {
    min-height: 0; // fix sidebar
  }
`;

const marketMonthsSkeleton = (
  <div className="flex-grow-1">
    <Module childrenClassName="placeholder-center" className="h-100" title="Market-Months">
      <NonIdealState className="text-center d-flex align-items-center" icon="th" />
    </Module>
  </div>
);

function Dashboard() {
  const { dashboardStore } = useStores();
  const { getAssignedMarkets, isLoaded } = dashboardStore;

  useEffect(() => {
    if (!isLoaded) {
      getAssignedMarkets();
    }
  }, [getAssignedMarkets, isLoaded]);

  const marketMonthsTable = isLoaded ? <MarketMonthsTable /> : marketMonthsSkeleton;

  return (
    <>
      <DashboardTopBar />

      <StyledDashboardRowContainer className="p-2 d-flex flex-column flex-grow-1 overflow-auto mh-100">
        <Row className="flex-grow-1 mh-0">
          <div className="col-9 d-flex flex-column h-100">
            <StyledRow className="mb-2 flex-grow-1">
              <>
                <HeatmapTable />
                <WatchlistContainer />
              </>
            </StyledRow>
            {marketMonthsTable}
          </div>
          <MarketsModuleContainer />
        </Row>
      </StyledDashboardRowContainer>
    </>
  );
}

export default observer(Dashboard);
