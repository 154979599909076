import styled from "@emotion/styled";

export const StyledBaseCell = styled("td")<{ isStriked?: boolean }>`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #5c7080 !important;
  ${({ isStriked }) => isStriked && "text-decoration: line-through"};
`;

export const StyledPreviewCell = styled(StyledBaseCell)`
  text-decoration: none;
  font-weight: bold;
  color: #a854a8 !important;
`;
