import isEqual from "lodash.isequal";

export const countUpdatedFilters = (currentFilters: object, appliedFilters: object): number => {
  return Object.keys(currentFilters).reduce((counter, key) => {
    const isChanged = !isEqual(currentFilters[key], appliedFilters[key]);
    if (isChanged) {
      return counter + 1;
    }

    return counter;
  }, 0);
};

export default countUpdatedFilters;
