function toggleFilter(filterKey: string, filters: object, disabledFilters: object) {
  const isFilterDisabled = filterKey in disabledFilters;

  if (isFilterDisabled) {
    delete disabledFilters[filterKey];
  } else {
    disabledFilters[filterKey] = filters[filterKey];
  }
}

export default toggleFilter;
