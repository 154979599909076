// @flow

import React from "react";
import { observer } from "mobx-react";
import { Select } from "@blueprintjs/select";
import { Button, MenuItem } from "@blueprintjs/core";
import isEmpty from "lodash.isempty";

type Props = {
  cabinClasses: Array<string>,
  changeCabinClass: Function,
  className: string,
  disabled: boolean,
  prefix: ?string,
  selectedCabinClass: string
};

function CabinClassSelect(props: Props) {
  const {
    cabinClasses = [],
    changeCabinClass,
    className = "",
    disabled,
    selectedCabinClass,
    prefix = "Cabin: "
  } = props;

  const isDisabled = disabled || isEmpty(cabinClasses);

  const button = (
    <Button
      data-testid="cabin-class-selector"
      disabled={isDisabled}
      rightIcon="caret-down"
      text={
        <span>
          {prefix}
          <strong>{selectedCabinClass}</strong>
        </span>
      }
    />
  );

  return (
    <Select
      className={className}
      disabled={isDisabled}
      filterable={false}
      itemRenderer={(item, itemProps) => <MenuItem key={item} onClick={itemProps.handleClick} text={item} />}
      items={cabinClasses}
      onItemSelect={selection => changeCabinClass(selection)}
      popoverProps={{ minimal: true }}
    >
      {button}
    </Select>
  );
}

export default observer(CabinClassSelect);
