// @flow

import { action, computed, makeObservable, observable } from "mobx";
import union from "lodash.union";
import difference from "lodash.difference";
import uniq from "lodash.uniq";
import { computedFn } from "mobx-utils";

import api from "services/Api";
import { Status } from "modules/App/Status";
import type { RootStore } from "modules/App/Root.model";
import type { PageContextFilters } from "types/Flights.types";

type ValueRenderer = {
  [id: string | null]: string
};

const uniqAndSort = array => uniq(array).sort();

type SubregionEntry = {
  createdOn: string,
  subregionId: number,
  subregionName: string,
  subregionMarketCount: number,
  updatedOn: string
};

export type RegionEntry = {
  regionId: number,
  regionName: string,
  regionMarketCount: number,
  subregionsMarketCount: number,
  createdOn: string,
  updatedOn: string,
  subregions: SubregionEntry[]
};

type Subregion = {
  regionId: $PropertyType<RegionEntry, "id">,
  subregionId: $PropertyType<SubregionEntry, "subregionId">,
  subregionName: $PropertyType<SubregionEntry, "subregionName">
};

type ListItem = {
  value: number,
  label: string
};

type RegionListItem = ListItem & {
  subregionIds?: number[]
};

type SubregionListItem = ListItem & {
  regionId?: number
};

export class RegionsStore {
  @observable table = {
    columns: ["regionName", "subregionsNumber", "marketCount"],
    data: [],
    selectedRows: [],
    status: Status.INIT
  };

  @observable regions: RegionEntry[] = [];
  @observable subregions: Subregion[] = [];
  @observable status: Status = Status.INIT;

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  @action.bound
  getRegionsData() {
    this.status = Status.LOADING;
    this.table.status = Status.LOADING;
    this.changeSelectedRows([]);
    api
      .getRegionsSubregions()
      .then(response => {
        this.regions = response.data;
        this.subregions = response.data.flatMap(({ regionId, subregions }) =>
          subregions.map(({ subregionId, subregionName }) => ({ regionId, subregionId, subregionName }))
        );

        this.table.data = response.data.map((region: RegionEntry) => {
          const { subregions = [], ...rest } = region;
          const subRows = subregions.map(item => ({
            ...item,
            regionId: `${region.regionId}.${item.subregionId}` // generate id for subrow
          }));
          return { subRows, ...rest };
        });
        this.status = Status.DONE;
        this.table.status = Status.DONE;
      })
      .catch(() => {
        this.status = Status.ERROR;
        this.table.status = Status.ERROR;
      });
  }

  getRegionsFromSelectedSubregions = computedFn((filters: PageContextFilters | null, filterKey: string) => {
    if (filters === null) {
      return this.regionsForFilter;
    }

    const containsSubregionId = region =>
      region.subregionIds?.some(subregionId => filters?.[filterKey].includes(subregionId));

    return this.regionsForFilter.filter(containsSubregionId);
  });

  getSubregionsFromSelectedRegions = computedFn((filters: PageContextFilters | null, filterKey: string) => {
    if (filters === null) {
      return this.subregionsForFilter;
    }
    return this.subregionsForFilter.filter(({ regionId }) => filters?.[filterKey]?.includes(regionId));
  });

  @action
  getRegionsBySubregionId(subregionId: ?number): Array<string> {
    if (!subregionId) {
      return this.regions;
    }

    return this.regions.filter(({ subregions }) => {
      const subregionIds = subregions.map(subregion => subregion.subregionId);
      return subregionIds.includes(subregionId);
    });
  }

  @action
  getSubregionsByRegionId(regionId: number | null | undefined): Array<string> {
    if (regionId === null) {
      return [];
    }

    if (regionId === undefined) {
      return this.subregions;
    }

    return this.subregions.filter((subregion: Subregion) => subregion.regionId === regionId);
  }

  @action.bound
  shiftToggleRows(selectedRow: string, clickedRow: string) {
    const { data, selectedRows } = this.table;
    const isToggleOn = selectedRows.includes(clickedRow);
    const tableIdRows = data.map(row => row.id);
    const sliceIndexes = [tableIdRows.indexOf(selectedRow), tableIdRows.indexOf(clickedRow)].sort((a, b) => a - b);
    const actionRows = tableIdRows.slice(sliceIndexes[0], sliceIndexes[1] + 1);

    this.table.selectedRows = !isToggleOn ? union(selectedRows, actionRows) : difference(selectedRows, actionRows);
  }

  @action.bound
  changeSelectedRows(selectedRows: Array<Object>) {
    this.table.selectedRows.replace(selectedRows);
  }

  @action.bound
  createRegion(name: string) {
    this.table.status = Status.LOADING;
    api
      .addRegion(name)
      .then(() => this.getRegionsData())
      .catch(() => this.getRegionsData());
  }

  @action.bound
  createSubregion(name: string, regionId: number) {
    this.table.status = Status.LOADING;
    api
      .addSubregion(regionId, name)
      .then(() => this.getRegionsData())
      .catch(() => this.getRegionsData());
  }

  @action.bound
  editRegion(regionId: number, data: { regionName: string }) {
    this.table.status = Status.LOADING;
    api
      .editRegion(regionId, data)
      .then(() => this.getRegionsData())
      .catch(() => this.getRegionsData());
  }

  @action.bound
  editSubregion(subregionId: number, data: { regionId: ?number, subregionName: ?string }) {
    this.table.status = Status.LOADING;
    api
      .editSubregion(subregionId, data)
      .then(() => this.getRegionsData())
      .catch(() => this.getRegionsData());
  }

  @action.bound
  deleteItems(data: { regionId: ?Array<number>, subregionId: ?Array<number> }) {
    this.table.status = Status.LOADING;
    const { regionId, subregionId } = data;
    api
      .deleteRegionSubregion({ regionId, subregionId })
      .then(() => this.getRegionsData())
      .catch(() => this.getRegionsData());
  }

  @computed
  get flatRows(): Array<Object> {
    return this.table.data.flatMap(row => [row, ...(row.subRows || [])]);
  }

  @computed
  get existingRegionNames(): Array<string> {
    return this.flatRows.map(row => row.regionName || row.subregionName);
  }

  @computed
  get isLoading(): boolean {
    return this.table.status === Status.LOADING;
  }

  @computed
  get regionsById(): ValueRenderer {
    return this.regions.reduce(
      (acc, currentValue) => {
        return { ...acc, [currentValue.regionId]: currentValue.regionName };
      },
      { null: "Unassigned" }
    );
  }

  @computed
  get subregionsById(): ValueRenderer {
    return this.subregions.reduce(
      (acc, currentValue) => {
        return { ...acc, [currentValue.subregionId]: currentValue.subregionName };
      },
      { null: "Unassigned" }
    );
  }

  @computed
  get regionsForFilter(): RegionListItem {
    return [
      { label: "Unassigned", value: null },
      ...uniqAndSort(
        this.regions.map(({ regionId, regionName, subregions }: RegionEntry) => ({
          label: regionName,
          subregionIds: subregions.map((subregion: Subregion) => subregion.subregionId),
          value: regionId
        }))
      )
    ];
  }

  @computed
  get subregionsForFilter(): SubregionListItem {
    return [
      { label: "Unassigned", value: null },
      ...uniqAndSort(
        this.subregions.map(({ regionId, subregionId: value, subregionName: label }) => ({ label, regionId, value }))
      )
    ];
  }

  @computed
  get isRegionsLoading() {
    return this.status === Status.LOADING;
  }
}
