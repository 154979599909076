// @flow

import React, { useEffect } from "react";
import { observer } from "mobx-react";

import ErrorBoundary from "shared/components/ErrorBoundary/ErrorBoundary";
import MarketMappingsSidebar from "components/MarketMappings/MarketMappingsSidebar";
import MarketMappingsTopBar from "components/MarketMappings/MarketMappingsTopBar";
import MarketMappingsTable from "components/MarketMappings/MarketMappingsTable";
import StyledScrollableContainer from "shared/components/StyledScrollableContainer/StyledScrollableContainer";
import { useStores } from "store/Store";

function MarketMappings() {
  const { marketMappingsStore, regionsStore, usersStore } = useStores();

  useEffect(() => {
    marketMappingsStore.getMarkets();
    marketMappingsStore.getMarketList();
    usersStore.fetchUsers();
    regionsStore.getRegionsData();
  }, []);

  return (
    <StyledScrollableContainer className="d-flex flex-column">
      <MarketMappingsTopBar />
      <MarketMappingsSidebar />

      <div className="p-2 d-flex flex-column flex-shrink-1 mh-0" data-testid="markets-mappings-table">
        <ErrorBoundary>
          <MarketMappingsTable />
        </ErrorBoundary>
      </div>
    </StyledScrollableContainer>
  );
}

export default observer(MarketMappings);
