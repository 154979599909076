const FLOAT_NUMBER_REGEX = /^[-+]?([1-9]\.)?[0-9]+e[-]?[1-9][0-9]*$/;

const getPrecision = number => {
  let arr = number.split("e");
  const exponent = Math.abs(arr[1]);

  let precision = Number(exponent);
  arr = arr[0].split(".");

  if (arr[1]) {
    precision += arr[1].length;
  }

  return precision;
};

export function formatPreciseNumber(number) {
  if (typeof number !== "string" && String(number).match(FLOAT_NUMBER_REGEX)) {
    return number.toFixed(getPrecision(String(number)));
  }
  return number;
}
