export const minMaxOverlaps = values => {
  const newValues = [
    ...values.map(val => {
      // eslint-disable-next-line
      const { endNdoOverlaps, startNdoOverlaps, ...newVal } = val;
      return newVal;
    })
  ];
  newValues.forEach((value, index) => {
    if (index < newValues.length) {
      const valuesWithoutActive = [...newValues.slice(0, index), ...newValues.slice(index + 1)];
      if (valuesWithoutActive.some(d => value.startNdo >= d.startNdo && value.endNdo <= d.endNdo)) {
        newValues[index].endNdoOverlaps = true;
        newValues[index].startNdoOverlaps = true;
      }
      if (valuesWithoutActive.some(d => value.startNdo <= d.startNdo && value.startNdo >= d.endNdo)) {
        newValues[index].startNdoOverlaps = true;
      }
      if (valuesWithoutActive.some(d => value.endNdo >= d.endNdo && value.endNdo <= d.startNdo)) {
        newValues[index].endNdoOverlaps = true;
      }
    }
  });
  return newValues;
};
