import { Button, Colors } from "@blueprintjs/core";
import styled from "@emotion/styled";

export const StyledPageSelectorButton = styled(Button)`
  border: 1px solid ${Colors.LIGHT_GRAY2};
  border-bottom: none;
  border-top: none;
  border-radius: 0;
  font-size: 12px;

  .bp3-icon {
    color: ${Colors.GRAY2};
  }

  &.bp3-disabled {
    background-color: ${Colors.LIGHT_GRAY5} !important;
    color: ${Colors.DARK_GRAY4} !important;

    .bp3-icon {
      color: ${Colors.GRAY5} !important;
    }
  }
`;
