import React from "react";
import { observer } from "mobx-react";

import { AnalysisMappingsStore } from "models/AnalysisMappings.model";
import isSidebarFilterVisible from "shared/helpers/isSidebarFilterVisible/isSidebarFilterVisible";
import SidebarSelect from "shared/components/Sidebar/SidebarSelect/SidebarSelect";
import SidebarFilter from "shared/components/Sidebar/SidebarFilter/SidebarFilter";
import SidebarNDOSelect from "shared/components/Sidebar/SidebarNDOSelect/SidebarNDOSelect";
import SidebarDateRangeSelect from "shared/components/Sidebar/SidebarDateRangeSelect/SidebarDateRangeSelect";
import SidebarDaySelect from "shared/components/Sidebar/SidebarDaySelect/SidebarDaySelect";
import { Tab } from "models/Tab/Tab.model";

type Props = {
  computedDateFormat: string;
  computedDateMonthFormat: string;
  mappings: AnalysisMappingsStore;
  pageContext: Tab;
};

function DepartureDateFilters(props: Props) {
  const { computedDateFormat, computedDateMonthFormat, mappings, pageContext } = props;
  const { filters, changeFilter, disabledFilters, sidebar } = pageContext;

  return (
    <>
      <SidebarFilter
        isTemporarilyDisabled={"ndo" in disabledFilters}
        isVisible={isSidebarFilterVisible("number of days to departure", "ndo", sidebar)}
      >
        <SidebarNDOSelect
          onChange={filterValue => changeFilter("ndo", filterValue)}
          title="Number of Days to Departure"
          values={filters.ndo}
        />
      </SidebarFilter>
      <SidebarFilter
        isTemporarilyDisabled={"quarter" in disabledFilters}
        isVisible={isSidebarFilterVisible("departure quarter", "quarter", sidebar)}
      >
        <SidebarSelect
          fuzzySearch
          items={mappings.quarterItems}
          onChange={filterValue => changeFilter("quarter", filterValue)}
          selectedItems={filters.quarter}
          title="Quarter"
          withAutoSeparatedItems
        />
      </SidebarFilter>
      <SidebarFilter
        isTemporarilyDisabled={"depMonth" in disabledFilters}
        isVisible={isSidebarFilterVisible("departure month", "depMonth", sidebar)}
      >
        <SidebarSelect
          computedDateFormat={computedDateFormat}
          computedDateMonthFormat={computedDateMonthFormat}
          fuzzySearch
          items={mappings.data.depMonth}
          onChange={filterValue => changeFilter("depMonth", filterValue)}
          selectedItems={filters.depMonth}
          title="Departure Month"
          withAutoSeparatedItems
        />
      </SidebarFilter>
      <SidebarFilter
        isTemporarilyDisabled={"depWeek" in disabledFilters}
        isVisible={isSidebarFilterVisible("departure week", "depWeek", sidebar)}
      >
        <SidebarSelect
          computedDateFormat={computedDateFormat}
          fuzzySearch
          items={mappings.data.depWeek}
          onChange={filterValue => changeFilter("depWeek", filterValue)}
          selectedItems={filters.depWeek}
          title="Departure Week"
          withAutoSeparatedItems
        />
      </SidebarFilter>
      <SidebarFilter
        isTemporarilyDisabled={"depDate" in disabledFilters}
        isVisible={isSidebarFilterVisible("departure date", "depDate", sidebar)}
      >
        <SidebarDateRangeSelect
          computedDateFormat={computedDateFormat}
          dates={filters.depDate}
          onChange={filterValue => changeFilter("depDate", filterValue)}
          title="Departure Date"
        />
      </SidebarFilter>
      <SidebarFilter
        isTemporarilyDisabled={"depDow" in disabledFilters}
        isVisible={isSidebarFilterVisible("departure day of week", "depDow", sidebar)}
      >
        <SidebarDaySelect
          onChange={filterValue =>
            changeFilter(
              "depDow",
              filterValue.sort((a, b) => a - b)
            )
          }
          selectedItems={filters.depDow}
          title="Departure Day of Week"
        />
      </SidebarFilter>
    </>
  );
}

export default observer(DepartureDateFilters);
