import { Button, Icon, Intent } from "@blueprintjs/core";
import { observer } from "mobx-react";
import React from "react";

import { useStores } from "../../../store/Store";
import { InfluenceStatus } from "../Influence.model";
import { StyledButtonIcon, StyledInfluenceInput } from "../InfluenceStyles";

type Props = {
  value: number | null;
  fieldName: string;
  intent: Intent;
  index: number;
};

function PriceControl({ value, intent, fieldName, index }: Props) {
  const { influenceStore } = useStores();
  const { status, updateMinMaxValue } = influenceStore;
  const isLoading = status.includes(InfluenceStatus.LOADING);
  const onValueChange = (value) => updateMinMaxValue(index, fieldName, value);
  
  if (value === null) {
    return (
      <>
        <Button
          className="flex-grow-1 flex-shrink-0 mr-2"
          disabled={isLoading}
          icon="plus"
          onClick={() => onValueChange(undefined)}
        >
          Add
        </Button>
        <Button
          className="flex-grow-1 flex-shrink-0"
          disabled={isLoading}
          icon="reset"
          onClick={() => onValueChange(-1)}
        >
          Remove
        </Button>
      </>
    );
  }

  if (value === -1) {
    return (
      <>
        <span className="ml-2 pl-2 flex-grow-1">Remove Limits</span>
        <Button className="ml-2" disabled={isLoading} icon="cross" minimal onClick={() => onValueChange(null)} />
      </>
    );
  }
  
  return (
    <>
      <StyledButtonIcon disabled={isLoading}>
        <Icon icon="dollar" />
      </StyledButtonIcon>
      <StyledInfluenceInput
        allowNumericCharactersOnly
        autoFocus
        clampValueOnBlur
        className="flex-grow-1"
        data-testid="influence-price-limits-input"
        disabled={isLoading}
        fill={true}
        intent={intent}
        min={0}
        onValueChange={onValueChange}
        value={value}
      />
      <Button className="ml-2" disabled={isLoading} icon="cross" minimal onClick={() => onValueChange(null)} />
    </>
  );
}

export default observer(PriceControl);
