// @flow

import React, { useRef } from "react";
import clsx from "clsx";
import isEmpty from "lodash.isempty";
import kebabCase from "lodash.kebabcase";
import styled from "@emotion/styled";
import { Position, Menu, Spinner, MenuItem } from "@blueprintjs/core";
import { observer } from "mobx-react";
import { Suggest } from "@blueprintjs/select";
import { useDebouncedCallback } from "use-debounce";

import EventManagementNameTooltip from "./EventManagementNameTooltip";
import { Status } from "modules/App/Status";
import { eventItemRenderer } from "./eventItemRenderer";
import { useStores } from "store/Store";

const SingleSuggest = styled(Suggest)`
  input {
    padding-right: 30px;
  }
`;

const StyledMenu = styled(Menu)`
  min-height: 80px;
`;

type Props = {
  dataTestid: string,
  inputProps: ?Object,
  onSelectItem: Function,
  title: string
};

function EventsAutocomplete(props: Props) {
  const {
    eventsManagementStore: {
      activeSuggestionType,
      changeField,
      clearExistingEvents,
      getExistingEvents,
      isSuggestModalLoading,
      page: { modalEvent = {}, suggestedEvents = {} }
    }
  } = useStores();
  const inputRef = useRef();

  const { title = "", onSelectItem, inputProps = {}, dataTestid = "events-autocomplete" } = props;
  const { status, data, extraEvents } = suggestedEvents;
  const { name } = modalEvent;

  const isLoading = status === Status.LOADING;

  const inputValueRenderer = (value: Object | string) => {
    if (isEmpty(name) && isEmpty(value)) {
      return null;
    }

    if (name) {
      return name;
    }

    if (value.label) {
      return value.label;
    }

    return (data.find(item => item.value === value) || {}).label;
  };

  const popoverClassName = clsx("sidebar-select -event-modal", kebabCase(`${title}-select`));

  const getNewSuggestedEvents = value => {
    if (value?.length > 1) {
      getExistingEvents({ name: value }, "name");
    } else {
      clearExistingEvents();
    }
  };

  const debouncedOnchange = useDebouncedCallback(getNewSuggestedEvents, 500, { maxWait: 2000 });

  const onInputFocus = event => {
    const target = event.target;

    if (target?.value) {
      getNewSuggestedEvents(target?.value);

      // fix auto selecting text on blueprint suggest
      setTimeout(() => {
        const valueLength = target?.value?.length || 0;
        target.focus();
        target.setSelectionRange(valueLength, valueLength);
      }, 1);
    }
  };

  const itemListRenderer = listProps => {
    const { renderItem, filteredItems } = listProps;

    if (isEmpty(filteredItems) || isLoading) {
      return null;
    }

    const itemsList = filteredItems.map(renderItem).filter(item => item != null);

    return (
      <StyledMenu ulRef={listProps.itemsParentRef}>
        {itemsList}
        {extraEvents > 0 && <MenuItem key="info-item" disabled text={`${extraEvents} more events`} />}
      </StyledMenu>
    );
  };

  const isAutocompleteLoading = isLoading && activeSuggestionType === "name";

  return (
    <div className="mb-3" data-testid={dataTestid}>
      <h6 className="bp3-heading">{title}</h6>
      <EventManagementNameTooltip maxLength={inputProps.maxLength} name={name}>
        <SingleSuggest
          fill
          inputProps={{
            disabled: isSuggestModalLoading,
            onFocus: onInputFocus,
            placeholder: "Enter event name",
            ref: inputRef,
            rightElement: isAutocompleteLoading && (
              <Spinner className="mr-2" data-testid="event-suggest-spinner" size={15} />
            ),
            ...inputProps
          }}
          inputValueRenderer={inputValueRenderer}
          itemListRenderer={itemListRenderer}
          itemRenderer={eventItemRenderer}
          items={data}
          onItemSelect={item => onSelectItem(item)}
          onQueryChange={value => {
            changeField("name", value);
            debouncedOnchange.callback(value);
          }}
          popoverProps={{
            boundary: "viewport",
            minimal: true,
            modifiers: {
              offset: 100
            },
            popoverClassName,
            position: Position.BOTTOM
          }}
          query={name}
        />
      </EventManagementNameTooltip>
    </div>
  );
}

export default observer(EventsAutocomplete);
