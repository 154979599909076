import clsx from "clsx";
import isEmpty from "lodash.isempty";
import { Axis, AxisOptions, Series, SeriesLineOptions } from "highcharts";
import { Colors } from "@blueprintjs/core";
import { endOfDay, isAfter, isWithinInterval, toDate } from "date-fns";

import isNumber from "shared/helpers/isNumber/isNumber";
import { RefObjectForHighchartsReact, chartAxesTypes } from "./Graph.types";

const TOOLTIP_VERTICAL_SPACING = 18;
const TOOLTIP_HORIZONTAL_SPACING = 12;
const LABEL_TOOLTIP_CLASS = "highcharts-tooltip-label";

const steps = 11;

export const chartOptionsConfig = {
  chart: {
    alignTicks: true,
    animation: false,
    backgroundColor: "transparent",
    ignoreHiddenSeries: true,
    marginBottom: 0,
    panKey: "shift",
    panning: {
      enabled: true
    },
    style: { fontFamily: "inherit" },
    zoomType: "x"
  },
  credits: {
    enabled: false
  },
  legend: {
    enabled: false
  },
  navigator: {
    height: 30,
    xAxis: {
      labels: {
        x: -12
      },
      margin: 0
    }
  },
  plotOptions: {
    line: {
      animation: false,
      connectNulls: true,
      marker: { enabled: false, radius: 2.5, symbol: "circle" },
      states: { hover: { lineWidth: 2 } }
    },
    series: {
      states: {
        hover: { enabled: true },
        inactive: { opacity: 1 }
      }
    }
  },
  rangeSelector: {
    enabled: false,
    selected: 1
  },
  scrollbar: {
    enabled: false,
    margin: 0
  }
};

const isToday = (timestamp, today) => isWithinInterval(timestamp, { end: endOfDay(today), start: toDate(today) });
const isForecasted = (timestamp, today) => isAfter(timestamp, today);
export const proportionNumber = (number: number, max: number, isReversed) => {
  const yIsNumber = isNumber(number);
  if (!yIsNumber) {
    return null;
  }

  if (isReversed) {
    return ((max - number) / max) * 100;
  }
  return (number / max) * 100;
};

export const generateClassName = (timestamp, today) => {
  if (isToday(timestamp, today)) return "";
  return isForecasted(timestamp, today) ? "forecasted" : "past";
};

export const Y_AXIS_BASE = {
  className: "",
  crosshair: { color: Colors.GRAY2, dashStyle: "Dash", snap: false, width: 1 },
  endOnTick: false,
  gridLineColor: Colors.LIGHT_GRAY3,
  groupName: [],
  labels: {
    align: "right",
    margin: 0,
    padding: 0,
    style: { color: Colors.GRAY2, fontSize: "11px", zIndex: 0 },
    x: -6, // margin on right side, between numbers and border/chart
    y: 4
  },
  lineColor: Colors.LIGHT_GRAY1,
  lineWidth: 1,
  margin: 0,
  min: null,
  softMin: 0,
  startOnTick: false,
  tickAmount: steps + 1,
  tickLength: 3,
  tickWidth: 1,
  title: {
    align: "high",
    enabled: true,
    margin: 8, // margin between title and numbers
    useHTML: true
  }
};

export const removeTooltip = () => {
  const tooltips = document.getElementsByClassName(LABEL_TOOLTIP_CLASS);
  if (tooltips.length) {
    Array.from(tooltips).forEach(tooltip => {
      tooltip.remove();
    });
  }
};

export const createLabelTooltip = (chart, elementPosition, label, isOpposite = false) => {
  removeTooltip(); // bug with resizing charts

  const wrapper = document.createElement("div");
  wrapper.setAttribute("class", LABEL_TOOLTIP_CLASS);
  const y = elementPosition.y + TOOLTIP_VERTICAL_SPACING;
  wrapper.style.top = `${y}px`;

  if (isOpposite) {
    const x = window.innerWidth - elementPosition.right + TOOLTIP_HORIZONTAL_SPACING;
    wrapper.style.right = `${x}px`;
  } else {
    const x = elementPosition.x + TOOLTIP_HORIZONTAL_SPACING;
    wrapper.style.left = `${x}px`;
  }

  wrapper.insertAdjacentHTML("beforeend", `<div class='${LABEL_TOOLTIP_CLASS}__title' title='${label}'>${label}</div>`);
  document.body.appendChild(wrapper);
};

export const joinAxes = (yAxis: { groupName: string[]; title: { text: string } }[], mergedGroups: Array<string[]>) => {
  const flatMergedGroups = mergedGroups.flat();
  const newGroups = yAxis.filter(({ groupName }) => groupName && !flatMergedGroups.includes(groupName[0]));
  const inMergedGroup = yAxis.filter(({ groupName }) => groupName && flatMergedGroups.includes(groupName[0]));

  mergedGroups
    .map(group => inMergedGroup.filter(({ groupName }) => groupName && group.includes(groupName[0])))
    .filter(group => group.length)
    .forEach(group => {
      const groupName = group.flatMap(axis => axis.groupName);
      const title = {
        ...Y_AXIS_BASE.title,
        text: group.map(axis => axis.title.text).join(" ")
      };
      newGroups.unshift({ ...group[0], groupName, title });
    });
  return newGroups;
};

export const removeTrailingZero = (axis: {
  number: { label: { textStr?: string; textSetter: Function } };
  ticks: { [key: number]: { label: { textStr: string; textSetter: Function } } };
}) => {
  const series = Object.values(axis?.ticks)
    .map(tick => tick.label?.textStr)
    .filter(a => Boolean(a) && a !== "0");

  const seriesHasTrailingZero =
    !isEmpty(series) && series.every(label => typeof label === "string" && label.match(/(.0)(K|M|$)$/));

  if (seriesHasTrailingZero) {
    Object.values(axis.ticks).forEach(tick => {
      const tickLabel = tick.label?.textStr;
      if (tickLabel && tickLabel !== "0") {
        tick.label.textSetter(tickLabel.replace(".0", ""));
      }
    });
  }
};

export const setMaxForGroups = (
  chart: { axes: chartAxesTypes },
  maxGrouped: Array<{ sources: string[]; target: string }>
) => {
  const includesGroupName = (axis, groupName) => axis.coll === "yAxis" && axis.options.groupName?.includes(groupName);
  const chartAxes = chart.axes;

  maxGrouped.forEach(({ target, sources }) => {
    const allAxisInGroup = [target, ...sources];
    const maxPerGroup: { max: number; index: number }[] = [];

    allAxisInGroup.forEach(theAxis =>
      chartAxes.forEach(axis => {
        const groupIndex = chartAxes.findIndex(axis => includesGroupName(axis, theAxis));
        const axisBySeriesName = includesGroupName(axis, theAxis);

        if (axisBySeriesName && groupIndex !== -1) {
          maxPerGroup.push({ index: groupIndex, max: axis.getExtremes().max });
        }
      })
    );

    if (!isEmpty(maxPerGroup)) {
      const groupMaxes = maxPerGroup.map(group => group.max);
      const maxNumberSeries = Math.max(...groupMaxes, 0);

      maxPerGroup.forEach(groupAxis => {
        chartAxes[groupAxis.index].setExtremes(chartAxes[groupAxis.index].min, maxNumberSeries);
      });
    }
  });
};

export const getMaxSeries = (chart: RefObjectForHighchartsReact["chart"]) => {
  const series = {};
  if (chart?.yAxis) {
    (chart?.yAxis as (Axis & { bottom: number })[])
      .filter(axis => axis?.bottom !== 0)
      .forEach(axis =>
        (axis?.options as AxisOptions & { groupName: string[] })?.groupName?.forEach(name => (series[name] = axis.max))
      );
  }
  return series;
};

export const navigatorDataPerGroup = (
  chart: RefObjectForHighchartsReact["chart"],
  getActiveSeriesGroups: (string | string[])[][],
  maxSeries: { [key: string]: number },
  data: [{ [key: string]: [] }],
  reversedAxis: string[]
) => {
  getActiveSeriesGroups.forEach(group => {
    const [groupName, groupMetrics] = group as [string, string[]];
    const groupMax = maxSeries[groupName];
    const isSeriesReversed = reversedAxis.includes(groupName);

    Array.isArray(groupMetrics) &&
      groupMetrics.forEach(metricName => {
        if (!data[metricName] || !isNumber(groupMax) || !chart?.navigator?.series) {
          return;
        }

        const seriesIndex = (chart.navigator.series as Series[]).findIndex(
          navigatorSeries => (navigatorSeries.options as SeriesLineOptions & { key: string }).key === metricName
        );

        if (seriesIndex >= 0) {
          const graphData = [...(chart?.navigator?.series?.[seriesIndex]?.data || {})];
          const newGraphData = graphData.map(dataProps => proportionNumber(dataProps.y, groupMax, isSeriesReversed));
          chart.navigator.series[seriesIndex].setData(newGraphData, false);
        }
      });
  });
};

export const transformNavigatorData = (
  seriesData: { x: number; y: number }[],
  isSeriesReversed: boolean,
  groupMax?: number
) => {
  return (
    groupMax &&
    isNumber(groupMax) &&
    seriesData.map(({ x, y }) => ({ x, y: proportionNumber(y, groupMax, isSeriesReversed) }))
  );
};

export const setChartSize = (chart: RefObjectForHighchartsReact["chart"]) => {
  const container = chart.container?.closest("[data-testid=module-expanded]");

  if (container?.getBoundingClientRect) {
    const { height, width } = container.getBoundingClientRect();

    if (height > 0 && width > 0) {
      chart.setSize(width, height);
    }
  }
};

export const addExtraClassName = (yAxis: { className: string; groupName: string[] }[]) => {
  const newAxisWithSpace = [...yAxis];

  if (newAxisWithSpace.length === 1) {
    const firstSeriesClassName = newAxisWithSpace[0].className;
    newAxisWithSpace[0].className = clsx("highcharts-yaxis--first-alone", {
      [firstSeriesClassName]: firstSeriesClassName
    });
  }

  return newAxisWithSpace;
};
