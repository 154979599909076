const NORMAL = 1;
const MILLIONS = 1.0e6;
const THOUSANDS = 1.0e3;

const maxNumbersAfterDot = (paddedTicks, numberOfThousands = NORMAL) =>
  paddedTicks.reduce((currentValue = 0, value) => {
    const number = Math.abs(Number(value));
    let dotNumber;
    if (number >= numberOfThousands) {
      const diffNumber = number / numberOfThousands;

      if (diffNumber === 1) {
        dotNumber = "1";
      } else {
        dotNumber = diffNumber.toString().split(".")[1];
      }
    } else if (numberOfThousands === NORMAL) {
      dotNumber = number.toString().split(".")[1];
    }
    const sizeDotNumbers = dotNumber ? dotNumber.length : 0;
    return currentValue > Number(sizeDotNumbers) ? currentValue : Number(sizeDotNumbers);
  });

export const numberAdjustedByRest = (params: { axis: { paddedTicks: any[] }; value: any }) => {
  const {
    axis: { paddedTicks },
    value
  } = params;

  let numberOfThousands = NORMAL;
  let numberAfterDot = 0;
  let numberThousandsText = "";

  const number = Math.abs(Number(value));
  const firstLegendNumber = paddedTicks[0];
  const lastLegendNumber = paddedTicks[paddedTicks.length - 1];

  if (firstLegendNumber >= MILLIONS || lastLegendNumber >= MILLIONS) {
    numberOfThousands = MILLIONS;
    numberAfterDot = maxNumbersAfterDot(paddedTicks, MILLIONS);
    numberThousandsText = "M";
  } else if (firstLegendNumber >= THOUSANDS || lastLegendNumber >= THOUSANDS) {
    numberOfThousands = THOUSANDS;
    numberAfterDot = maxNumbersAfterDot(paddedTicks, THOUSANDS);
    numberThousandsText = "K";
  } else {
    numberAfterDot = maxNumbersAfterDot(paddedTicks, NORMAL);
  }
  if (number === 0) {
    return `0${numberThousandsText}`;
  }
  return `${(number / numberOfThousands).toFixed(numberAfterDot)}${numberThousandsText}`;
};
