import { Button, Menu, MenuDivider, MenuItem, Popover, Position } from "@blueprintjs/core";
import styled from "@emotion/styled";
import React, { useState } from "react";

type Props = {
  columnId: string;
  isFrozenColumn: boolean;
  isGroupColumn: boolean;
  headerMenuLabels?: any;
  onColumnHide?: () => void;
  onColumnFreeze?: React.ComponentProps<typeof MenuItem>["onClick"];
  onColumnFilter?: Function;
  onColumnSort?: (isDesc: any) => any;
};

const StyledContextMenuButton = styled(Button)`
  transform: rotate(90deg);
`;

const StyledPopoverWrapper = styled("div")<{ isOpen: boolean }>`
  ${({ isOpen }) =>
    isOpen &&
    `opacity: 1!important;
     margin-right: -0.5rem;
     width: 30px!important`};

  button {
    border-radius: 0;
    padding: 0;
    outline: none;

    &:hover,
    &:focus {
      background: none !important;
    }
  }
`;

function HeaderContextMenu(props: Props) {
  const {
    columnId,
    headerMenuLabels,
    isFrozenColumn,
    isGroupColumn,
    onColumnFilter,
    onColumnFreeze,
    onColumnHide,
    onColumnSort
  } = props;
  const [isOpen, setIsOpen] = useState(false);

  const hideColumn = () => {
    setIsOpen(false);
    onColumnHide && onColumnHide();
  };

  const renderGroupMenuContent = () => {
    if ([onColumnFreeze, onColumnHide].every(fn => !fn)) {
      return null;
    }

    const freezeText = isFrozenColumn ? "Unfreeze Section" : "Freeze Section";
    return (
      <Menu>
        {onColumnFreeze && <MenuItem icon="snowflake" onClick={onColumnFreeze} text={freezeText} />}
        {onColumnHide && <MenuItem icon="eye-off" onClick={hideColumn} text="Hide Section" />}
      </Menu>
    );
  };

  const renderMenuContent = () => {
    if ([onColumnSort, onColumnHide].every(fn => !fn)) {
      return null;
    }

    const showDivider = onColumnSort && (onColumnHide || onColumnFilter);
    return (
      <Menu>
        {onColumnSort && <MenuItem icon="sort-asc" onClick={() => onColumnSort(false)} text="Sort Ascending" />}
        {onColumnSort && <MenuItem icon="sort-desc" onClick={() => onColumnSort(true)} text="Sort Descending" />}
        {showDivider && <MenuDivider />}
        {onColumnHide && (
          <MenuItem icon="eye-off" onClick={hideColumn} text={headerMenuLabels?.hideColumn || "Hide column"} />
        )}
        {onColumnFilter && <MenuItem icon="filter" onClick={() => onColumnFilter(columnId)} text="Filter by column" />}
      </Menu>
    );
  };

  const handleInteraction = nextOpenState => {
    setIsOpen(nextOpenState);
  };
  const popoverContent = isGroupColumn ? renderGroupMenuContent() : renderMenuContent();

  if (!popoverContent) {
    return null;
  }

  return (
    <StyledPopoverWrapper className="context-menu" isOpen={isOpen}>
      <Popover
        content={popoverContent}
        isOpen={isOpen}
        onInteraction={state => handleInteraction(state)}
        position={Position.BOTTOM_RIGHT}
      >
        <StyledContextMenuButton
          className="ml-auto"
          data-testid="context-menu"
          icon="more"
          minimal
          onClick={() => setIsOpen(true)}
        />
      </Popover>
    </StyledPopoverWrapper>
  );
}

export default HeaderContextMenu;
