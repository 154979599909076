import React from "react";
import isEmpty from "lodash.isempty";
import { AnchorButton } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { useStores } from "store/Store";

function MarketMappingsButton() {
  const { marketMappingsStore, modalStore } = useStores();

  return (
    <AnchorButton
      key="market-mappings-button"
      className="ml-5"
      data-testid="market-mappings-button"
      disabled={isEmpty(marketMappingsStore.table.selectedRows)}
      onClick={() => modalStore.setModal("market-mappings")}
    >
      Update Mappings ({marketMappingsStore.marketCount})
    </AnchorButton>
  );
}

export default observer(MarketMappingsButton);
