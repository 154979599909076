import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { format, parse, getTime, isAfter, isBefore } from "date-fns";

import SidebarDateRangeSelect from "shared/components/Sidebar/SidebarDateRangeSelect/SidebarDateRangeSelect";
import { useStores } from "store/Store";

const DATE_FORMAT = "yyyy-MM-dd";

const formatDateToTime = (date: string) => getTime(parse(date, DATE_FORMAT, new Date()));

type TypesRangeDatesSelection = {
  getExtremes(): ReturnType<() => { dataMax: number; dataMin: number; min: number; max: number }>;
  setRange: Function;
};

const StyledSidebarDateRangeSelect = styled(SidebarDateRangeSelect)`
  margin-bottom: 0 !important;
  padding: 5px;

  .bp3-input {
    width: 126px;
    max-width: 100%;
  }
`;

export const RangeDatesSelection = ({ getExtremes, setRange }: TypesRangeDatesSelection) => {
  const { systemSettingsStore } = useStores();
  const { computedDateFormat } = systemSettingsStore;
  const extremes = getExtremes();

  const today = new Date();
  const minDate = extremes.min ? new Date(extremes.min) : today;
  const maxDate = extremes.max ? new Date(extremes.max) : today;
  const minExtremumDate = extremes.dataMin ? new Date(extremes.dataMin) : today;
  const maxExtremumDate = extremes.dataMax ? new Date(extremes.dataMax) : today;

  const [startDate, setStartDate] = useState(minDate && format(minDate, DATE_FORMAT));
  const [endDate, setEndDate] = useState(maxDate && format(maxDate, DATE_FORMAT));

  useEffect(() => {
    setStartDate(minDate && format(minDate, DATE_FORMAT));
    setEndDate(maxDate && format(maxDate, DATE_FORMAT));
  }, [extremes.min, extremes.max]);

  return (
    <StyledSidebarDateRangeSelect
      computedDateFormat={computedDateFormat}
      dates={{ end: endDate, start: startDate }}
      maxDate={maxExtremumDate}
      minDate={minExtremumDate}
      onChange={filterValue => {
        let formattedStartDate = filterValue.start && formatDateToTime(filterValue.start);
        let formattedEndDate = filterValue.end && formatDateToTime(filterValue.end);

        const selectedMinIsAfterMinRange = isBefore(formattedStartDate, extremes.dataMin);
        const selectedMinIsAfterMaxRange = isAfter(formattedStartDate, extremes.dataMax);

        if (selectedMinIsAfterMinRange || selectedMinIsAfterMaxRange) {
          formattedStartDate = extremes.dataMin;
        }

        const selectedMaxIsAfterMaxRange = isAfter(formattedEndDate, extremes.dataMax);
        const selectedMaxIsBeforeMinRange = isBefore(formattedEndDate, extremes.dataMin);
        if (selectedMaxIsAfterMaxRange || selectedMaxIsBeforeMinRange) {
          formattedEndDate = extremes.dataMax;
        }

        setStartDate(formattedStartDate && format(formattedStartDate, DATE_FORMAT));
        setEndDate(formattedEndDate && format(formattedEndDate, DATE_FORMAT));
        setRange(formattedStartDate, formattedEndDate);
      }}
      withResetDates={false}
    />
  );
};
