import CirrusFilters from "./CirrusFilters";

import { Metric } from "modules/Explore/metrics/metrics";

export const cirrusDefaultMarketMetrics: Metric[] = [
  {
    Component: CirrusFilters,
    filters: [{ key: "owMarket" }, { key: "cabinClass" }, { key: "cirrusStatus" }],
    key: "cirrus",
    name: "Cirrus"
  }
];
