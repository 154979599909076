import { Colors } from "@blueprintjs/core";

export const metricsColors = {
  competitorFareGroup: "TURQUOISE",
  lacGroup: "VIOLET",
  loadFactorGroup: "GREEN",
  pricePercentileGroup: "INDIGO",
  raskGroup: "VERMILION",
  revenueGroup: "GOLD",
  revenuePerBookingGroup: "SEPIA",
  sellingFareGroup: "LIME",
  yieldGroup: "COBALT"
};

export function getMetricsColor(group: string, index: number = 0) {
  const colorName = metricsColors[group] || "GRAY";
  const colorVariant = (index % 3) + 3;

  const color = `${colorName}${colorVariant}`;
  return Colors[color];
}
