import React from "react";
import { observer } from "mobx-react";
import isEqual from "lodash.isequal";
import pluralize from "pluralize";
import { Button } from "@blueprintjs/core";

import countAppliedFilters from "../../../helpers/countAppliedFilters/countAppliedFilters";
import groupConditionalFilters from "../../../helpers/groupConditionalFilters/groupConditionalFilters";
import { Tab } from "models/Tab/Tab.model";
import { InfluenceHistoryFilters } from "modules/InfluenceHistory/InfluenceHistory.types";

type Props = {
  onSubmit: () => void;
  pageContext: Tab;
  initialFilters: InfluenceHistoryFilters | object;
};

const MAX_FILTERS_COUNT = 50;

function SidebarApplyButton(props: Props) {
  const { onSubmit, pageContext = { applied: {} }, initialFilters } = props;
  const searchParamsApplied = Object.keys(pageContext).includes("searchParams");

  const appliedFilters = {
    ...pageContext.applied.filters,
    ...groupConditionalFilters(pageContext.applied.conditionalFilters)
  };

  const appliedEntries = Object.keys(appliedFilters).reduce(
    (obj, key) => ({
      ...obj,
      [key]: appliedFilters[key]
    }),
    {}
  );

  const pageFilters = {
    applied: {
      conditionalFilters: searchParamsApplied ? {} : pageContext.applied && pageContext.applied.conditionalFilters,
      filters: searchParamsApplied ? pageContext.searchParams : pageContext.applied.filters
    },
    conditionalFilters: pageContext.conditionalFilters || {},
    filters: pageContext.filters
  };

  const currentFilters = {
    ...pageFilters.filters,
    ...groupConditionalFilters(pageFilters.conditionalFilters)
  };

  const currentEntries = Object.keys(currentFilters).reduce(
    (obj, key) => ({
      ...obj,
      [key]: currentFilters[key]
    }),
    {}
  );

  const isDisabled = isEqual(appliedEntries, currentEntries);
  const numberOfChanges = countAppliedFilters(initialFilters, currentEntries);

  if (numberOfChanges > MAX_FILTERS_COUNT) {
    return <Button disabled fill intent="primary" text="Max filters reached" />;
  }

  return (
    <Button
      disabled={isDisabled}
      fill
      intent="primary"
      onClick={onSubmit}
      text={`Apply (${pluralize("filter", numberOfChanges, true)})`}
    />
  );
}

export default observer(SidebarApplyButton);
