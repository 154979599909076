// @flow

import React from "react";
import clsx from "clsx";
import styled from "@emotion/styled";
import isEmpty from "lodash.isempty";
import { Button, Colors, Icon, MenuItem, Position, Menu } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { StyledSelect } from "./Dashboard.utils";
import { selectStyle } from "components/Select/Select.styles";

const options = [
  { label: "Final Revenue", value: "finalRevenueExpectedPercentDiffToBaseline" },
  { label: "Revenue", value: "raskDiffToBaseline" },
  { label: "Load Factor", value: "diffToElb" },
  { label: "Influence", value: "influenceImpact" }
];

const StyledIcon = styled(Icon)`
  color: ${Colors.GRAY2} !important;
  margin: 0 5px 0 12px;
`;

const StyledMenuItem = styled(MenuItem)`
  color: ${Colors.DARK_GRAY4} !important;

  &.bp3-active {
    background-color: ${Colors.LIGHT_GRAY4} !important;
  }
`;

type Props = {
  buttonClassName?: string;
  className?: string;
  customId?: string;
  onChange: Function;
  selected: string;
  selectOptions?: { group?: string; label: string; value: string }[];
};

function ModuleTypeSelect({
  buttonClassName = "",
  className = "",
  customId = "metric-type-selector",
  onChange,
  selected,
  selectOptions = options
}: Props) {
  const activeGroup = selectOptions?.find(item => item.value === selected)?.group;
  const activeLabel = selectOptions?.find(item => item.value === selected)?.label;

  const button = activeLabel && (
    <Button
      className={buttonClassName}
      data-testid={customId}
      rightIcon="caret-down"
      text={
        <span className="text-truncate bp3-button-label">
          {activeGroup} {activeLabel}
        </span>
      }
    />
  );

  const itemRenderer = (item, itemProps) => {
    const isActiveItem = item.value === selected;
    return (
      <StyledMenuItem
        key={`${item.group}-${item.label}`}
        active={isActiveItem}
        onClick={itemProps.handleClick}
        text={
          <div className="d-flex justify-content-between align-items-center">
            <span
              className={`text-truncate ${clsx({
                "font-weight-bold": isActiveItem
              })}`}
            >
              {item.group} {item.label}
            </span>
            {isActiveItem && <StyledIcon icon="tick" />}
          </div>
        }
      />
    );
  };

  const itemListRenderer = ({ items, query, renderItem }) => {
    const itemsList = items
      .filter(metric => {
        return `${metric.group} ${metric.label} ${metric.value}`.toLowerCase().includes(query.toLowerCase());
      })
      .map(renderItem);
    return <Menu>{isEmpty(itemsList) ? <div className="px-2"> No results found.</div> : itemsList}</Menu>;
  };

  return (
    <>
      {selectStyle}
      <StyledSelect
        className={className}
        filterable={true}
        inputProps={{ className: "m-2" }}
        itemListRenderer={itemListRenderer}
        itemRenderer={itemRenderer}
        items={selectOptions}
        onItemSelect={({ value }) => onChange(value)}
        popoverProps={{
          interactionKind: "click",
          minimal: true,
          position: Position.BOTTOM_RIGHT
        }}
      >
        {button}
      </StyledSelect>
    </>
  );
}

export default observer(ModuleTypeSelect);
