import { InfluenceType } from "types/Influence.types";

const commonRows = [
  { key: "numberOfImpactedFlights" },
  { key: "sellingPrice" },
  { key: "pricePercentile" },
  { key: "lacRank" }
];

type InfluenceRow = {
  key: string;
};

export type Influence = {
  isPreview: boolean;
  name: string;
  rows: InfluenceRow[];
  type: InfluenceType;
  sortable: boolean;
};

const priceValueAdjustment: Influence = {
  isPreview: true,
  name: "Price Adjustment ($)",
  rows: [...commonRows, { key: "absolutePriceAdjustment" }],
  sortable: true,
  type: "AP"
};

const pricePercentAdjustment: Influence = {
  isPreview: true,
  name: "Price Adjustment (%)",
  rows: [...commonRows, { key: "priceAdjustment" }],
  sortable: true,
  type: "PA"
};

const competitorSensitivityAdjustment: Influence = {
  isPreview: true,
  name: "Competitor Sensitivity",
  rows: [...commonRows, { key: "compSensitivity" }, { key: "sellingFareDiffToCirrusCompFare" }],
  sortable: true,
  type: "CS"
};

const priceLimitsAdjustment: Influence = {
  isPreview: true,
  name: "Price Limits (Min/Max)",
  rows: [...commonRows, { key: "minmaxAdjustmentMin" }, { key: "minmaxAdjustmentMax" }],
  sortable: false,
  type: "MM"
};

export const INFLUENCES_LIST: Influence[] = [
  competitorSensitivityAdjustment,
  pricePercentAdjustment,
  priceValueAdjustment,
  priceLimitsAdjustment
];

export const influenceTypeNames = Object.fromEntries(INFLUENCES_LIST.map(({ type, name }) => [type, name]));

/* eslint-disable sort-keys */
export const influenceStatusNames = {
  active: "Active",
  expired: "Expired",
  deleted: "Deleted"
};
/* eslint-enable sort-keys */

export const HIGHEST_START_NDO = 500;
