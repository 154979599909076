import React, { useEffect, useRef } from "react";
import { observer } from "mobx-react";

import InfluenceControlTable from "modules/Influence/InfluenceControlTable/InfluenceControlTable";
import InfluenceControlForm from "modules/Influence/InfluenceControlForm/InfluenceControlForm";
import { useStores } from "store/Store";
import AdjustmentControlDescription from "shared/components/AdjustmentControlDescription/AdjustmentControlDescription";

function InfluenceControlSave() {
  const { influenceStore } = useStores();
  const { data, selectedInfluence, state, status } = influenceStore;
  const { isPreview, rows } = selectedInfluence;

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current && state.influenceStep === 3) {
      containerRef.current.querySelector("textarea")?.focus();
    }
  }, [state.influenceStep]);

  return (
    <div ref={containerRef} className="p-2" data-testid="influence-control-save">
      <AdjustmentControlDescription
        adjustmentName="influence"
        onChange={comment => influenceStore.updateParameters({ comment })}
        value={influenceStore.parameters.comment}
      />
      <InfluenceControlForm readOnly />
      {isPreview ? <InfluenceControlTable data={data} rows={rows} status={status} /> : null}
    </div>
  );
}

export default observer(InfluenceControlSave);
