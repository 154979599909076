import styled from "@emotion/styled";
import { observer } from "mobx-react";
import React from "react";

const StyledNavigation = styled("div")`
  min-height: 48px;

  & > * {
    flex: 1;
  }
`;

type Props = {
  previous: React.ReactNode;
  next: React.ReactNode;
  step: number;
  count?: number;
};

function ControlNavigation({ previous, next, count = 3, step }: Props) {
  return (
    <StyledNavigation className="d-flex justify-content-between align-items-center flex-shrink-0 p-2">
      {step !== 1 && previous}
      <div className="bp3-text-small text-center" data-testid="navigation-step-number">
        Step {Math.min(count, step)} of {count}
      </div>
      {step !== 1 && next}
    </StyledNavigation>
  );
}

export default observer(ControlNavigation);
