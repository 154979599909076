import React, { useEffect } from "react";
import { observer } from "mobx-react";

import { useStores } from "store/Store";
import CirrusSidebar from "components/Cirrus/CirrusSidebar";
import CirrusTopBar from "components/Cirrus/CirrusTopBar";
import ErrorBoundary from "shared/components/ErrorBoundary/ErrorBoundary";
import StyledScrollableContainer from "shared/components/StyledScrollableContainer/StyledScrollableContainer";
import CirrusDefaultMarketTable from "components/Cirrus/CirrusDefaultMarketTable";
import { cirrusDefaultMarketMetrics } from "components/Cirrus/cirrusDefaultMarketMetrics";

function CirrusDefaultMarket() {
  const { cirrusDefaultMarketStore, cirrusMappingsStore } = useStores();

  useEffect(() => {
    cirrusMappingsStore.getMappings();
  }, []);

  return (
    <StyledScrollableContainer className="d-flex flex-column">
      <CirrusTopBar cirrusStoreContext={cirrusDefaultMarketStore} />
      <CirrusSidebar cirrusStoreContext={cirrusDefaultMarketStore} metrics={cirrusDefaultMarketMetrics} />
      <div className="p-2 d-flex flex-column flex-shrink-1 mh-0" data-testid="cirrus-on-off-table">
        <ErrorBoundary>
          <CirrusDefaultMarketTable />
        </ErrorBoundary>
      </div>
    </StyledScrollableContainer>
  );
}

export default observer(CirrusDefaultMarket);
