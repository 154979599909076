import React from "react";
import { observer } from "mobx-react";

import SidebarSelect from "shared/components/Sidebar/SidebarSelect/SidebarSelect";
import SidebarFilter from "shared/components/Sidebar/SidebarFilter/SidebarFilter";
import isSidebarFilterVisible from "shared/helpers/isSidebarFilterVisible/isSidebarFilterVisible";

import type { AnalysisMappingsStore } from "models/AnalysisMappings.model";
import type { Tab } from "models/Tab/Tab.model";

type Props = {
  mappings: AnalysisMappingsStore;
  pageContext: Tab;
};

function InfluenceHistoryMarketFilters(props: Props) {
  const { mappings, pageContext } = props;
  const { disabledFilters, filters, changeFilter, sidebar } = pageContext;

  const owMarkets = mappings.getOneWayMarkets(null);

  return (
    <>
      <SidebarFilter
        isTemporarilyDisabled={"owMarket" in disabledFilters}
        isVisible={isSidebarFilterVisible("ow market one-way market", "owMarket", sidebar)}
      >
        <SidebarSelect
          fuzzySearch
          items={owMarkets}
          onChange={filterValue => changeFilter("owMarket", filterValue)}
          selectedItems={filters.owMarket}
          title="OW Market"
          withAutoSeparatedItems
        />
      </SidebarFilter>
    </>
  );
}

export default observer(InfluenceHistoryMarketFilters);
