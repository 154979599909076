import isEmpty from "lodash.isempty";
import { Colors, Spinner } from "@blueprintjs/core";
import styled from "@emotion/styled";
import isEqual from "lodash.isequal";
import { observer } from "mobx-react";
import InfiniteScroll from "react-infinite-scroll-component";
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import {
  useExpanded,
  useFlexLayout,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
  UseTableOptions
} from "react-table";
import { useSticky } from "react-table-sticky";
import { ThBoldTextWidth } from "../../helpers/columnTextWidths/columnTextWidths";
import { getActiveFiltersFromFilters } from "../../helpers/getActiveFiltersFromFilters/getActiveFiltersFromFilters";
import getColumnWidth from "../../helpers/getColumnWidth/getColumnWidth";
import { isMacOs } from "../../helpers/isMacOs/isMacOs";
import { Status } from "modules/App/Status";
import { usePrevious } from "../../helpers/usePrevious/usePrevious";
import { Cell, Row } from "types/react-table.types";
import IndeterminateCheckbox from "../IndeterminateCheckbox/IndeterminateCheckbox";
import {
  ColumnsConfig,
  DataTableFilters,
  SortingBy,
  StickyColumn,
  TableColumn,
  TablePagination
} from "./DataTable.types";

import DataTableHeader from "./DataTableHeader/DataTableHeader";
import DataTableLoadingOverlay from "./DataTableLoadingOverlay/DataTableLoadingOverlay";
import DataTablePagination from "./DataTablePagination/DataTablePagination";
import DataTableRows from "./DataTableRows/DataTableRows";
import { defaultExpanderRenderer } from "./DataTable.utils";
import { EmptyDataStatus, ErrorStatus, InitStatus } from "./DataTableStatuses/DataTableStatuses";
import { StyledDataTableContainer, StyledDataTable, StyledTfoot } from "./DataTable.styles";
import DataTableFixedFooter from "./DataTableFixedFooter/DataTableFixedFooter";
import { metricsTypes } from "../../../modules/App/constants";

const flattenRows = row => [row, ...(row.subRows || [])];

const StyledInfiniteScroll = styled(InfiniteScroll)`
  & > div + div > li:first-of-type {
    border-top: 1px solid ${Colors.LIGHT_GRAY2}; // fix border after fetching next page
  }
  &.infinite-scroll-component {
    overflow: inherit !important;
  }
`;

type Props = {
  aggregationsDisabled: boolean;
  canSetPageSize?: boolean;
  cellRenderers?: object;
  expanderRenderer?: (row: Row) => React.ReactNode;
  columnAccessors?: object;
  columnConfig?: ColumnsConfig;
  columnDescriptions?: { [key: string]: string };
  columnLabels?: object;
  columns: TableColumn[];
  columnSortType?: { [key: string]: string | null };
  columnTextWidth?: object;
  columnWidths?: object;
  customDataFlow?: boolean;
  data: object[];
  dataCount: {
    numberOfRows: number | undefined;
    status: boolean;
  };
  disabledRows: string[] | number[];
  expandableRows: boolean; // requires valid rowIdAccessor
  extraColumn?: Function;
  extraHeaderStyles?: object;
  extraCellStyles?: object;
  fetchData: Function;
  fetchMoreData: Function;
  filters?: DataTableFilters;
  fixedColumns?: { expand: boolean; selection: boolean };
  footerData: object[];
  getRowIdCallback?: UseTableOptions<any>["getRowId"];
  headerMenuLabels?: {
    hideColumn: (parentId: string) => string;
  };
  hideColumn: Function;
  infinityScroll: boolean;
  initialFilters?: DataTableFilters;
  isAdjustable: boolean;
  isPreviewActive: boolean;
  nonFilterableColumns: string[];
  nonHideableColumns: string[];
  onColumnFilter?: Function;
  onColumnHide: Function;
  onRowClick?: (row: Row) => void;
  onRowToggle?: Function;
  onShiftToggle?: Function;
  onSortedChange?: Function;
  pagination: TablePagination;
  rowIdAccessor: string;
  selectableSubRows: boolean;
  selectedRows?: string[];
  showPagination: boolean;
  sortingBy: SortingBy | null;
  sortingEnabled: boolean;
  sortBy: {
    field: string;
    direction: string;
  };
  status: string;
  title: string;
  toggleFixedColumn: Function;
};

const ARROW_SIZE = 16;
const FILTER_SIZE = 8;

function DataTable(props: Props & Row & Cell) {
  const {
    aggregationsDisabled = false,
    canSetPageSize = true,
    cellRenderers = {},
    columnAccessors = {},
    columnConfig = {},
    columnDescriptions = {},
    columnLabels = {},
    columnSortType = {},
    columnTextWidth = {},
    columnWidths = {},
    customDataFlow = false,
    data = [],
    dataCount = {
      numberOfRows: 0,
      status: Status.INIT
    },
    disabledRows = [],
    expandableRows = false,
    expanderRenderer = defaultExpanderRenderer,
    extraCellStyles = {},
    extraColumn = null,
    extraHeaderStyles = {},
    fetchData = null,
    fetchMoreData = null,
    filters = {} as DataTableFilters,
    fixedColumns = {},
    footerData = [],
    getRowIdCallback,
    headerMenuLabels,
    infinityScroll = false,
    initialFilters = {} as DataTableFilters,
    isAdjustable = false,
    isPreviewActive = false,
    nonFilterableColumns = [],
    nonHideableColumns = [],
    onColumnFilter = null,
    onColumnHide,
    onRowClick = null,
    onRowToggle,
    onShiftToggle,
    onSortedChange,
    pagination = {},
    rowIdAccessor = "id",
    selectableSubRows = false,
    selectedRows = null,
    showPagination = true,
    sortingBy = SortingBy.Backend,
    sortingEnabled = true,
    sortBy = {},
    status,
    title = "",
    toggleFixedColumn
  } = props;

  const [lastSelectedRow, setLastSelectedRow] = useState("");
  const appliedFilters = getActiveFiltersFromFilters(filters, initialFilters);
  const isColumnUsed = (columnId: string) => appliedFilters.includes(columnId);

  const getAccessor = name => {
    return columnAccessors && columnAccessors[name] ? columnAccessors[name] : name;
  };

  const createColumn = (column, depth = 0, parentColumn?, columnIndex?): TableColumn => {
    let accessor,
      accessorName,
      columns,
      dynamicRender,
      id,
      sortType = "alphanumeric";

    if (Array.isArray(column)) {
      const firstColumnName = column[0];
      accessor = getAccessor(firstColumnName);
      accessorName = firstColumnName;
      id = firstColumnName;

      columns = column[1].map((subcolumn, index) => createColumn(subcolumn, depth + 1, column, index));
    } else {
      accessor = getAccessor(column);
      accessorName = column;
      id = column;
      sortType = columnSortType[column] || "alphanumeric";
    }
    const headerText = columnLabels[accessorName] || "";

    const Header = <div className="text-truncate">{headerText}</div>;

    const isFixed = !depth && fixedColumns[accessorName];
    const sticky = isFixed ? fixedColumns[accessorName] : undefined;
    const isActiveColumn = sortBy.field === accessorName;
    let textWidth = columnWidths[accessorName] || columnTextWidth[accessorName];

    if (isActiveColumn) {
      textWidth = ThBoldTextWidth(headerText);
    }
    const customRender = cellRenderers && cellRenderers[column];
    const isCustomRendererFunction = typeof customRender === "function";
    const isPivotTable = title === "Pivot Table";
    if (isPivotTable) {
      const dynamicColumn = props.columns[0] !== accessor;
      dynamicRender = dynamicColumn ? metricsTypes[props.columns[props.columns.length - 1]] : "";
    }
    let size = getColumnWidth({
      accessor,
      data: isEmpty(footerData) ? data : [...data, ...footerData],
      fixedWidth: columnWidths[accessorName],
      headerWidth: textWidth,
      ...(!isCustomRendererFunction && { renderType: customRender }),
      ...(isPivotTable && { renderType: dynamicRender })
    });

    if (parentColumn) {
      const parentColumnTitle = parentColumn[0];
      const parentColumnChildren = parentColumn[1];
      const isLast = parentColumnChildren.length - 1 === columnIndex;

      const groupParentWidth = columnWidths[accessorName] || ThBoldTextWidth(columnLabels[parentColumnTitle]);
      columnTextWidth[parentColumnTitle] = groupParentWidth;

      const wholeGroupWidth = parentColumnChildren.reduce(
        (summed, columnNameInParent) => summed + columnTextWidth[columnNameInParent],
        0
      );

      if (groupParentWidth > wholeGroupWidth && isLast) {
        size = size + groupParentWidth - wholeGroupWidth;
      }
    }

    let width = size;
    if (isColumnUsed(accessorName)) {
      width += FILTER_SIZE;
    }
    if (isActiveColumn) {
      width += ARROW_SIZE;
    }
    return { accessor, columns, Header, id, sortType, sticky, width };
  };

  const selectRow = (row: Row) => {
    const rowNumberOfFlights = (row.values && row.values.numberOfFlights) || 0;
    onRowToggle && onRowToggle([...selectedRows, row.id], { [row.id]: rowNumberOfFlights });
  };

  const deselectRow = (row: Row) => {
    const elementIndex = selectedRows.indexOf(row.id);
    const copiedSelectedRows = selectedRows.slice(0);
    copiedSelectedRows.splice(elementIndex, 1);

    onRowToggle && onRowToggle(copiedSelectedRows, { [row.id]: undefined });
  };

  const toggleRow = (event, row: Row) => {
    if (event.shiftKey && lastSelectedRow) {
      onShiftToggle && onShiftToggle(lastSelectedRow, row.id);
    } else if (selectedRows.includes(row.id)) {
      deselectRow(row);
    } else {
      selectRow(row);
    }
    setLastSelectedRow(row.id);
  };

  const isSelectableRow = (row: Row) => (selectableSubRows || !row.depth) && !disabledRows.includes(row.id);

  const isEveryRowSelected = (rows: Row[]) => {
    if (selectedRows) {
      return rows
        .flatMap(flattenRows)
        .filter(isSelectableRow)
        .every(row => selectedRows.includes(row.id));
    }
  };

  const isAnyRowSelected = (rows: Row[]) => {
    if (selectedRows) {
      if (isEveryRowSelected(rows)) {
        return false;
      }
      return rows.flatMap(flattenRows).some(row => selectedRows.includes(row.id));
    }
  };

  const toggleAllRows = (rows: Row[]) => {
    const flatRows = rows.flatMap(flattenRows);
    if (selectedRows) {
      if (isEveryRowSelected(rows)) {
        flatRows.forEach(row => {
          if (isSelectableRow(row)) {
            deselectRow(row);
          }
        });
      } else {
        flatRows.forEach(row => {
          if (isSelectableRow(row) && !selectedRows.includes(row.id)) {
            selectRow(row);
          }
        });
      }
    }
  };

  const createExpanderColumn = () => {
    const expanderCell = {
      accessor: "expander",
      Cell: ({ row }: Cell) => expanderRenderer({ ...row }),
      className: "flex-grow-0",
      Header: "",
      width: 30
    };
    return {
      accessor: "expand",
      className: "flex-grow-0",
      columns: [expanderCell],
      Header: "",
      sticky: fixedColumns && fixedColumns.expand
    };
  };

  const createCheckboxColumn = () => {
    const checkboxCell = {
      accessor: "checkbox",
      Cell: ({ row }: Cell) =>
        (selectableSubRows || !row.depth) && selectedRows ? (
          <div className="d-flex justify-content-center align-items-center h-100">
            <IndeterminateCheckbox
              checked={selectedRows.includes(row.id)}
              disabled={isPreviewActive || disabledRows.includes(row.id)}
              onClick={event => toggleRow(event, row)}
              readOnly
            />
          </div>
        ) : null,
      className: "flex-grow-0",
      Header: ({ page: rows }: Cell) => (
        <div className="d-flex justify-content-center align-items-center h-100 flex-grow-1">
          <IndeterminateCheckbox
            checked={!isPreviewActive && isEveryRowSelected(rows)}
            disabled={isPreviewActive}
            indeterminate={isAnyRowSelected(rows)}
            onChange={() => toggleAllRows(rows)}
          />
        </div>
      ),
      width: 30
    };
    return {
      accessor: "selection",
      className: "flex-grow-0",
      columns: [checkboxCell],
      Header: "",
      sticky: fixedColumns && fixedColumns.selection
    };
  };

  const createColumns = (): TableColumn[] => {
    const array: TableColumn[] = [];
    if (isEmpty(data)) {
      return array;
    }

    if (expandableRows) {
      array.push(createExpanderColumn());
    }
    if (selectedRows) {
      array.push(createCheckboxColumn());
    }
    if (extraColumn) {
      array.push(extraColumn());
    }
    array.push(...props.columns.map(column => createColumn(column, 0)));
    return array.sort(column => ((column as StickyColumn).sticky === "right" ? 1 : 0));
  };

  const columns = useMemo(() => createColumns(), [lastSelectedRow, props.columns, props.data]);

  // if expandableRows === true, rowIdAccessor must be valid
  const getRowId = useCallback((row: Row, relativeIndex: number) => row[rowIdAccessor] || relativeIndex, [
    lastSelectedRow,
    rowIdAccessor
  ]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page: rows,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setSortBy,
    state: { pageIndex, pageSize, sortBy: currentSort }
  } = useTable(
    {
      autoResetExpanded: false,
      columns,
      data,
      disableMultiSort: true,
      disableSortBy: !sortingBy,
      disableSortRemove: true,
      getRowId: getRowIdCallback || getRowId,
      initialState: {
        pageIndex: pagination.pageIndex,
        pageSize: pagination.pageSize,
        selectedRowIds: selectedRows ? selectedRows.reduce((result, rowId) => ({ ...result, [rowId]: true }), {}) : [],
        sortBy: sortBy ? [{ desc: sortBy.direction === "desc", id: sortBy.field }] : []
      },
      manualPagination: true,
      manualSortBy: sortingBy === SortingBy.Backend,
      pageCount: pagination.pageCount
    },
    useFlexLayout,
    useSticky,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );
  const isInitialized = useRef(false);
  const isBackendSort = sortingBy === SortingBy.Backend;
  const previousSorting = usePrevious(currentSort);

  useLayoutEffect(() => {
    if (!isInitialized.current) {
      // fetch data on first mount if there is no data
      if (!customDataFlow && fetchData && status !== Status.LOADING && !data.length) {
        fetchData({ pageIndex, pageSize }, { saveOptions: false });
      }
      isInitialized.current = true;
      return;
    }

    if (customDataFlow && onSortedChange && !isEqual(previousSorting, currentSort)) {
      onSortedChange(currentSort);
    }

    // fetch data on deps change
    if (!customDataFlow && fetchData) {
      const sortBy = isBackendSort
        ? {
            direction: currentSort[0].desc ? "desc" : "asc",
            field: currentSort[0].id
          }
        : undefined;

      if (onSortedChange && !isEqual(previousSorting, currentSort)) {
        onSortedChange(currentSort);
      }

      fetchData({ pageIndex, pageSize, sortBy }, { saveOptions: !!onSortedChange });
    }
  }, [customDataFlow, pageIndex, pageSize, isBackendSort && currentSort[0].id, isBackendSort && currentSort[0].desc]);

  useEffect(() => {
    if (sortingBy) {
      setSortBy([{ desc: sortBy.direction === "desc", id: sortBy.field }]);
    }
  }, [sortBy.field, sortBy.direction]);

  if (status === Status.INIT) {
    return <InitStatus />;
  }

  if (status === Status.ERROR) {
    return <ErrorStatus fetchData={fetchData} pageIndex={pageIndex} pageSize={pageSize} />;
  }

  if (status === Status.DONE && !rows.length && !pagination.totalRows) {
    return <EmptyDataStatus />;
  }

  const headerProps = {
    aggregationsDisabled,
    appliedFilters,
    columnDescriptions,
    columnSortType,
    extraHeaderStyles,
    filters,
    gotoPage,
    headerGroups,
    headerMenuLabels,
    initialFilters,
    isAdjustable,
    nonFilterableColumns,
    nonHideableColumns,
    onColumnFilter,
    onColumnHide,
    sortingEnabled,
    toggleFixedColumn
  };

  const paginationProps = {
    canNextPage,
    canPreviousPage,
    canSetPageSize,
    gotoPage,
    isPreviewActive,
    nextPage,
    pagination,
    previousPage,
    setPageSize
  };

  const isFooter = !isEmpty(footerData) && data.length === dataCount.numberOfRows;

  const isMac = isMacOs();
  const tableId = `${title.split("").filter(Boolean).join("")}-infinity-list`;
  return (
    <div className="d-flex flex-column flex-grow-1 mw-100 mh-100 position-relative">
      <DataTableLoadingOverlay isEmpty={isEmpty(data)} loading={status === Status.LOADING} />
      <StyledDataTableContainer id={tableId}>
        <StyledInfiniteScroll
          dataLength={data.length}
          hasMore={infinityScroll && data.length < dataCount.numberOfRows}
          loader={<Spinner className="p-1" size={12} />}
          next={fetchMoreData}
          scrollableTarget={tableId}
        >
          <StyledDataTable
            className="table d-flex flex-column"
            isMac={isMac}
            showPagination={showPagination}
            {...getTableProps()}
          >
            <thead className="header">
              <DataTableHeader {...headerProps} />
            </thead>
            <tbody className="body" {...getTableBodyProps()}>
              <DataTableRows
                cellRenderers={cellRenderers}
                columnConfig={columnConfig}
                extraCellStyles={extraCellStyles}
                onRowClick={onRowClick}
                prepareRow={prepareRow}
                rows={rows}
                selectedRows={selectedRows}
              />
            </tbody>
            {isFooter ? (
              <StyledTfoot>
                <DataTableFixedFooter
                  cellRenderers={cellRenderers}
                  columnConfig={columnConfig}
                  columns={columns}
                  data={footerData}
                  extraCellStyles={extraCellStyles}
                  prepareRow={prepareRow}
                />
              </StyledTfoot>
            ) : null}
          </StyledDataTable>
        </StyledInfiniteScroll>
      </StyledDataTableContainer>
      {showPagination ? <DataTablePagination {...paginationProps} /> : null}
    </div>
  );
}

export default observer(DataTable);
