import React from "react";
import styled from "@emotion/styled";

type LogoTab = {
  active: boolean;
  background: string;
};

const StyledLogoTab = styled("div")<LogoTab>`
  border-radius: 2px 2px 0 0;
  padding: 0 5px;
  height: 30px;
  background-color: ${({ active, background }) => (active ? "white" : background || "#ffffff33")};
`;

type Props = {
  active?: boolean;
  background?: string;
  children?: React.ReactNode;
};

function HeaderLogo(props: Props) {
  const { active = false, background = "" } = props;
  return (
    <StyledLogoTab
      active={active}
      background={background}
      className="d-flex justify-content-center align-items-center mr-2"
    >
      {props.children}
    </StyledLogoTab>
  );
}

export default HeaderLogo;
