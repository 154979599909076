import styled from "@emotion/styled";

import Graph from "shared/Graph/Graph";
import { Colors } from "@blueprintjs/core";

type StyledOpacityTypes = {
  isTranslucent: boolean;
};

export const StyledOpacity = styled("div")<StyledOpacityTypes>`
  ${({ isTranslucent }) => isTranslucent && `opacity: 0.5`};
  .highcharts-xaxis .highcharts-axis-line {
    display: none;
  }
`;

export const BuildCurvesStyles = styled(Graph)`
  // legend metric - yaxis
  .highcharts-item-figure {
    display: inline-block;
    height: 8px;
    margin-left: 7px;
    width: 8px;
  }
  .highcharts-axis-title {
    text-align: right;
  }
  .highcharts-axis-title,
  .highcharts-axis-even {
    align-items: center;
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 0;
  }
  .highcharts-axis-even {
    transform: rotate(180deg);
  }
  .highcharts-yaxis-grid.y-axis-reversed path:last-of-type,
  .highcharts-yaxis-grid:not(.y-axis-reversed) path:first-of-type {
    stroke: ${Colors.LIGHT_GRAY1} !important;
  }

  .highcharts-axis-series-legend {
    margin-left: 7px;
  }
`;
