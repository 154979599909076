import React from "react";
import { observer } from "mobx-react";

import { useStores } from "store/Store";
import CirrusTagList from "./CirrusTagList";
import FilterInteractionsWrapper from "shared/components/FilterInteractionsWrapper/FilterInteractionsWrapper";
import TopBar from "shared/components/TopBar/TopBar";
import SwitchButtons from "shared/components/SwitchButtons/SwitchButtons";
import { CirrusDefaultMarketStore } from "models/Cirrus/CirrusDefaultMarket.model";
import { CirrusODDatesStore } from "models/Cirrus/CirrusODDates.model";
import isEnv from "shared/helpers/isEnv/isEnv";

type Props = {
  cirrusStoreContext: CirrusDefaultMarketStore | CirrusODDatesStore;
};

function CirrusTopBar({ cirrusStoreContext }: Props) {
  const { cirrusMappingsStore } = useStores();
  const { page, setSidebarOpen, toggleFiltersEnabled } = cirrusStoreContext;

  return (
    <TopBar>
      {isEnv(["development", "staging"]) && (
        <SwitchButtons
          buttons={[
            { label: "OD Dates Cirrus Status", url: "/cirrus-status/od-dates" },
            { label: "Default Market Cirrus Status", url: "/cirrus-status/default-markets" }
          ]}
        />
      )}
      <CirrusTagList cirrusStoreContext={cirrusStoreContext} />
      <FilterInteractionsWrapper
        buttonProps={{
          className: "ml-auto",
          isLoading: cirrusMappingsStore.isLoading,
          onClick: setSidebarOpen,
          testId: "filters-button"
        }}
        filterSwitchProps={{ checked: page.filtersEnabled, onChange: toggleFiltersEnabled }}
      />
    </TopBar>
  );
}

export default observer(CirrusTopBar);
