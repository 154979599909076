import React from "react";
import { observer } from "mobx-react";
import { metricsTypes, metricUnitTypes } from "../../App/constants";

import SidebarFilter from "shared/components/Sidebar/SidebarFilter/SidebarFilter";
import SidebarConditionalFilter from "shared/components/Sidebar/SidebarConditionalFilter/SidebarConditionalFilter";
import isSidebarFilterVisible from "shared/helpers/isSidebarFilterVisible/isSidebarFilterVisible";
import { useStores } from "store/Store";
import { MetricFilter } from "../../Explore/metrics/metrics";
import { Tab } from "models/Tab/Tab.model";
import { FilterKey } from "types/Tab.types";

type Props = {
  filters: MetricFilter[];
  labels: { titleLabels: object; filterLabels: object };
  pageContext: Tab;
};

function ConditionalFiltersGroup(props: Props) {
  const { pageContext, labels, filters } = props;
  const rootStore = useStores();

  const {
    addCondition,
    changeFilter,
    changeConditionalFilter,
    conditionalFilters,
    deleteCondition,
    disabledFilters,
    sidebar
  } = pageContext;
  const { titleLabels, filterLabels } = labels;

  if (!filters) {
    return null;
  }

  const renderConditionalFilter = ({ key, ...props }) => (
    <SidebarConditionalFilter
      {...props}
      conditionalFilters={conditionalFilters.filter(condition => condition.name === key)}
      inputHint={metricUnitTypes[metricsTypes[key]]}
      name={key}
      onAddCondition={addCondition}
      onChangeCondition={changeConditionalFilter}
      onDeleteCondition={deleteCondition}
      title={titleLabels[key]}
    />
  );

  const renderCustomRenderer = ({ renderer, key }: MetricFilter) => {
    if (!renderer || !renderer.Component) {
      return null;
    }
    const { Component, items, props, selectedItemProp = "selectedItems" } = renderer;

    const componentProps = {
      ...props,
      ...{ items: items ? items(rootStore, pageContext) : undefined },
      computedDateFormat: rootStore.systemSettingsStore.computedDateFormat, // used by SidebarDateRangeSelect
      [selectedItemProp]: pageContext.filters[key]
    };

    return (
      <Component
        {...componentProps}
        onChange={(value: string[] | object) => changeFilter(key, value)}
        title={titleLabels[key]}
      />
    );
  };

  const isVisible = (key: FilterKey) => isSidebarFilterVisible(filterLabels[key], key, sidebar);

  return (
    <>
      {filters.map(filter => (
        <SidebarFilter
          key={filter.key}
          isTemporarilyDisabled={disabledFilters[filter.key]}
          isVisible={isVisible(filter.key)}
        >
          {filter.renderer ? renderCustomRenderer(filter) : renderConditionalFilter(filter)}
        </SidebarFilter>
      ))}
    </>
  );
}

export default observer(ConditionalFiltersGroup);
