import { format, parseISO } from "date-fns";

const formatLastUpdatedText = (lastUpdated: string, computedDateFormat: string = "yyyy-MM-dd") => {
  if (!lastUpdated) {
    return "";
  }
  return `Last updated on ${format(parseISO(lastUpdated), `${computedDateFormat} HH:mm`)}`;
};

export default formatLastUpdatedText;
