import { Switch } from "@blueprintjs/core";
import React from "react";
import { observer } from "mobx-react";
import isEqual from "lodash.isequal";
import isEmpty from "lodash.isempty";

import { useStores } from "store/Store";

import AnalysisTagsList from "../AnalysisTagsList/AnalysisTagsList";
import BuildPeriodSelect from "components/BuildPeriodSelect";
import FilterInteractionsWrapper from "shared/components/FilterInteractionsWrapper/FilterInteractionsWrapper";
import SwitchButtons from "shared/components/SwitchButtons/SwitchButtons";
import TopBar from "shared/components/TopBar/TopBar";
import { FILTERS_INIT } from "models/Tab/Tab.utils";

type Props = {
  tabId: string;
};

function AnalysisTopBar({ tabId }: Props) {
  const { analysisMappingsStore, capacityStore, influenceStore, regionsStore, tabsStore } = useStores();
  const tab = tabsStore.tabsById[tabId];

  const {
    analystMappingEnabled,
    changeBuildPeriod,
    applied,
    filtersEnabled,
    isEditingInfluence,
    isEditingTemplate,
    setSidebarOpen,
    toggleAnalystMapping,
    toggleFiltersEnabled,
    xDayBuild
  } = tab;

  const isPreviewActive = influenceStore.isPreviewActive || capacityStore.isPreviewActive;

  const isSidebarInteractionsDisabled = isPreviewActive || analysisMappingsStore.isError;
  const isFilterSwitchDisabled = isEqual(applied.filters, FILTERS_INIT) && isEmpty(applied.conditionalFilters);

  const isLoading = analysisMappingsStore.isLoading || regionsStore.isLoading;
  const mappingToggleStatusText = analystMappingEnabled ? "ON" : "OFF";
  const isSwitchButtonsVisible = !isEditingTemplate && !isEditingInfluence;

  return (
    <TopBar>
      {isEditingTemplate && (
        <Switch
          checked={analystMappingEnabled}
          className="mr-2 mb-0"
          label={`Analyst filter ${mappingToggleStatusText}`}
          onChange={toggleAnalystMapping}
        />
      )}
      {isSwitchButtonsVisible && (
        <SwitchButtons
          buttons={[
            { label: "Explore", url: `/analysis/${tab.id}/explore` },
            { label: "Influence", url: `/analysis/${tab.id}/influence` }
          ]}
        />
      )}

      {!isEditingInfluence && (
        <BuildPeriodSelect changeBuildPeriod={changeBuildPeriod} disabled={isLoading} xDayBuild={xDayBuild} />
      )}

      {tab.applied && <AnalysisTagsList tab={tab} />}
      <FilterInteractionsWrapper
        buttonProps={{
          disabled: isSidebarInteractionsDisabled,
          isLoading,
          onClick: setSidebarOpen,
          testId: "sidebar-button"
        }}
        filterSwitchProps={{
          checked: filtersEnabled,
          disabled: isSidebarInteractionsDisabled || isFilterSwitchDisabled,
          onChange: toggleFiltersEnabled
        }}
        withSwitchButton={isSwitchButtonsVisible}
      />
    </TopBar>
  );
}

export default observer(AnalysisTopBar);
