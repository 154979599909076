// @flow

import React, { useState } from "react";
import { observer } from "mobx-react";
import { Button, Classes, ControlGroup, Dialog, FormGroup, HTMLSelect, InputGroup } from "@blueprintjs/core";
import styled from "@emotion/styled";

import { useStores } from "store/Store";

const DialogFooter = styled("div")`
  box-shadow: 0 -1px 0 rgba(16, 22, 26, 0.15);
  padding-left: 0.75rem;
  padding-right: 0.75rem;
`;

function EditUserModal() {
  const { appStore, modalStore, usersStore } = useStores();

  const currentUserId = appStore.userId;
  const rolesOptions = usersStore.userGroups.data.map(group => group.name);
  const { group: userRole, name: userFullName, email: userEmail, id: userId } = modalStore.data;

  const [name, setName] = useState(userFullName);
  const [email, setEmail] = useState(userEmail);
  const [role, setRole] = useState(userRole);

  const isSelfEdit = currentUserId === userId;

  const editUser = () => {
    isSelfEdit
      ? usersStore.editCurrentUser(name, appStore.auth.editCurrentUserName)
      : usersStore.editUser(userId, { group: role, name });
  };

  const showDeleteConfirmation = () => {
    const config = {
      action: () => usersStore.deleteUser(userId),
      buttonIcon: "trash",
      buttonText: "Delete User",
      text: `You are about to delete ${name}. Deleting this user will revoke their access to the Cirrus UI and remove all of their user data and history from the system database. Any analyst influences or system-wide setting they have made will remain.`,
      title: "Delete User Confirmation"
    };
    modalStore.setModal("confirmation", config);
  };

  return (
    <Dialog
      className="p-0"
      isOpen={modalStore.modalActive === "editUser"}
      onClose={modalStore.closeModal}
      title="Edit User"
    >
      <div className={`${Classes.DIALOG_BODY} m-0 p-3`} data-testid="em-user-details">
        <div className="d-flex flex-column w-100">
          <FormGroup className="font-weight-bold mb-4" label="Email" labelFor="em-email-input-details">
            <InputGroup
              disabled
              id="em-email-input-details"
              onChange={e => setEmail(e.target.value)}
              type="email"
              value={email}
            />
          </FormGroup>
          <FormGroup className="font-weight-bold mb-4" label="Full Name" labelFor="em-full-name-input-details">
            <InputGroup id="em-full-name-input-details" onChange={e => setName(e.target.value)} value={name} />
          </FormGroup>
          <FormGroup className="font-weight-bold mb-1" label="Role" labelFor="em-role-input">
            <ControlGroup>
              <HTMLSelect
                className="w-50 p-0"
                disabled={isSelfEdit}
                id="em-role-input"
                onChange={e => setRole(e.currentTarget.value)}
                options={rolesOptions}
                value={role}
              />
            </ControlGroup>
          </FormGroup>
        </div>
      </div>

      <DialogFooter className={`${Classes.DIALOG_FOOTER} mx-0 py-2`}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          {!isSelfEdit && (
            <Button className="m-0" icon="trash" minimal onClick={() => showDeleteConfirmation()} text="Delete User" />
          )}
          <Button
            className="ml-auto px-3"
            data-testid="em-user-button"
            intent="primary"
            onClick={() => {
              editUser();
              modalStore.closeModal();
            }}
            text="Save"
          />
        </div>
      </DialogFooter>
    </Dialog>
  );
}

export default observer(EditUserModal);
