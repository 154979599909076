import { action, computed, makeObservable, observable } from "mobx";
import isEmpty from "lodash.isempty";

import api from "services/Api";
import type { RootStore } from "modules/App/Root.model";
import { Status } from "modules/App/Status";

export class DashboardStore {
  @observable assignedMarkets: string[] = [];
  @observable status: Status = Status.INIT;
  @observable activePage: string = "my-markets";
  @observable lastUpdated: string = "";

  rootStore: RootStore;

  constructor(rootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  @action.bound
  reset() {
    this.lastUpdated = "";
    this.status = Status.INIT;
  }

  @action
  changePage(page) {
    this.activePage = page;
  }

  @action
  setLastUpdated(lastUpdated: string) {
    this.lastUpdated = lastUpdated;
  }

  @action.bound
  getAssignedMarkets() {
    this.status = Status.LOADING;
    api
      .getMarkets({
        filters: {
          analystId: this.analystFilterId
        }
      })
      .then(({ data }) => {
        if (isEmpty(this.analystFilterId)) {
          this.activePage = "network";
          this.assignedMarkets = [];
        } else {
          this.assignedMarkets = data.rows.reduce(
            (acc, currentValue) => [...new Set([...acc, currentValue.rtMarket])],
            []
          );
          this.activePage = "my-markets";

          if (isEmpty(this.assignedMarkets)) {
            this.activePage = "network";
          }
        }
        this.status = Status.DONE;
      })
      .catch(() => {
        this.assignedMarkets = [];
        this.status = Status.ERROR;
      });
  }

  @computed
  get isError(): boolean {
    return this.status === Status.ERROR;
  }

  @computed
  get isLoaded(): boolean {
    return this.status === Status.DONE;
  }

  @computed
  get isLoading(): boolean {
    return this.status === Status.LOADING;
  }

  @computed
  get isMyMarkets(): boolean {
    return this.activePage === "my-markets";
  }

  @computed
  get analystFilterId() {
    const {
      appStore: { userId }
    } = this.rootStore;

    return userId ? [userId] : [];
  }
}
