import React from "react";
import { observer } from "mobx-react";
import { Button, Classes, Colors, Dialog } from "@blueprintjs/core";
import styled from "@emotion/styled";
import pluralize from "pluralize";
import { useStores } from "../store/Store";

export const DialogFooter = styled("div")`
  box-shadow: 0 -1px 0 ${Colors.BLACK}26;
`;

function DeleteEventsModal() {
  const { modalStore } = useStores();
  const { deleteEvents, selectedRows = [] } = modalStore.data;

  const deleteCountText = pluralize("event", selectedRows.length, true);
  const pluralConfirmationText = selectedRows.length === 1 ? "this event" : "these events";

  return (
    <Dialog
      className="p-0"
      isOpen={modalStore.modalActive === "deleteEvents"}
      onClose={modalStore.closeModal}
      title={`Delete (${deleteCountText})`}
    >
      <div className={`${Classes.DIALOG_BODY} m-0 p-3`} data-testid="delete-events-modal">
        <div className="mb-2 font-weight-bold bp3-fill">{`Are you sure you want to delete ${pluralConfirmationText}?`}</div>
        <div className="mb-4">You won’t be able to restore your work.</div>
      </div>

      <DialogFooter className={`${Classes.DIALOG_FOOTER} mx-0 px-3 py-2`}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button onClick={modalStore.closeModal} type="button">
            Cancel
          </Button>
          <Button
            data-testid="delete-events-button"
            intent="primary"
            onClick={() => {
              deleteEvents({
                eventIds: selectedRows
              });
              modalStore.closeModal();
            }}
          >
            Delete
          </Button>
        </div>
      </DialogFooter>
    </Dialog>
  );
}

export default observer(DeleteEventsModal);
