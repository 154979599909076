// @flow

import React from "react";
import { observer } from "mobx-react";
import { Colors, Icon } from "@blueprintjs/core";
import styled from "@emotion/styled";

const StyledWarnings = styled("div")`
  color: ${Colors.ORANGE3};
  display: flex;
  font-size: 12px;
  line-height: 1.33;

  .bp3-icon {
    position: relative;
    top: 2px;
  }
`;

const StyledErrors = styled(StyledWarnings)`
  color: ${Colors.RED3};
`;

const INFORMATION_TYPE = {
  error: StyledErrors,
  warning: StyledWarnings
};

type Props = {
  link: string,
  text: string,
  type: "error" | "warning"
};

function EventWarning(props: Props) {
  const { link, text, type } = props;

  const Warning = INFORMATION_TYPE[type];

  return (
    <Warning className="mt-3">
      <Icon className="mr-2" icon="error" iconSize={12} />
      <div>
        <div>{text}</div>
        {link && (
          <a className="d-inline-block mt-1" href={link} rel="noopener noreferrer" target="_blank">
            Read more
          </a>
        )}
      </div>
    </Warning>
  );
}

export default observer(EventWarning);
