import React, { ReactElement } from "react";
import { Button, Popover, Menu, Icon, IconName, MenuItem } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { RangeDatesSelection } from "../RangeDatesSelection/RangeDatesSelection";
import { RangeNdoSelection } from "../RangeNdoSelection/RangeNdoSelection";
import { StyledGraphMenuContainer, StyledGraphMenu, StyledButtonGroup, StyledButton } from "./GraphMenu.styles";

type Props = {
  changeDateOption: (isNdo: boolean) => void;
  chartConfig: { xAxis: { getExtremes: () => void; setExtremes: (min: number, max: number) => void } | {}[] };
  displayGraphMenu: boolean;
  isMenuOptionOpen: boolean;
  isNdo: boolean;
  leftPositionMenu: number;
  setIsMenuOptionOpen: (isMenuOptionOpen: boolean) => void;
  toggleGraphMenu: (menuIsActive: boolean) => void;
};

const renderButton = (iconName: IconName, content: ReactElement<{}>, testId: string, toggleMenu: Function) => (
  <Popover
    content={content}
    data-testId={testId}
    onClosing={() => toggleMenu(false)}
    onOpening={() => toggleMenu(true)}
    placement="right"
  >
    <Button icon={iconName} />
  </Popover>
);

const GraphMenu = ({
  changeDateOption,
  chartConfig,
  displayGraphMenu,
  isNdo,
  leftPositionMenu,
  isMenuOptionOpen,
  setIsMenuOptionOpen,
  toggleGraphMenu
}: Props) => {
  const menuIsActive = displayGraphMenu || displayGraphMenu === null;
  const getExtremes = () => chartConfig?.xAxis?.[0]?.getExtremes() || {};
  const setRange = (min: number, max: number) => chartConfig?.xAxis?.[0]?.setExtremes(min, max);

  const toggleMenu = () => toggleGraphMenu(!menuIsActive);

  const chartTypeSelect = (
    <Menu>
      <MenuItem
        labelElement={!isNdo && <Icon icon="tick" />}
        onClick={() => isNdo && changeDateOption(false)}
        text="Observation Dates"
      />
      <MenuItem
        labelElement={isNdo && <Icon icon="tick" />}
        onClick={() => !isNdo && changeDateOption(true)}
        text="NDO"
      />
    </Menu>
  );

  const rangeProps = {
    getExtremes,
    setRange
  };
  const rangeTypeSelect = isNdo ? <RangeNdoSelection {...rangeProps} /> : <RangeDatesSelection {...rangeProps} />;

  return (
    <StyledGraphMenuContainer isMenuOptionOpen={isMenuOptionOpen} leftPositionMenu={leftPositionMenu}>
      <StyledGraphMenu className="d-flex flex-column">
        <StyledButtonGroup menuIsActive={menuIsActive} vertical>
          {renderButton("timeline-line-chart", chartTypeSelect, "graph-x-axis-type-selector", setIsMenuOptionOpen)}
          {renderButton("calendar", rangeTypeSelect, "graph-x-axis-range-selector", setIsMenuOptionOpen)}
        </StyledButtonGroup>
        <StyledButton
          data-testid="graph-menu-toggle"
          icon="chevron-down"
          menuIsActive={menuIsActive}
          onClick={toggleMenu}
        />
      </StyledGraphMenu>
    </StyledGraphMenuContainer>
  );
};

export default observer(GraphMenu);
