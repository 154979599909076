import styled from "@emotion/styled";
import useResizeObserver from "@react-hook/resize-observer";
import React, { useRef } from "react";

type Props = {
  onChange: (width: number, height: number) => void;
  children: React.ReactNode;
};

const StyledContainer = styled("div")`
  width: 100%;
  height: 100%;
`;

function ResizeElementObserver({ onChange, children }: Props) {
  const ref = useRef(document.createElement("div"));

  useResizeObserver(ref.current, entry =>
    entry.borderBoxSize.forEach(({ inlineSize: width, blockSize: height }) => onChange(width, height))
  );
  return <StyledContainer ref={ref}>{children} </StyledContainer>;
}

export default ResizeElementObserver;
