// @flow

import React, { useRef, useState } from "react";
import { Button, Colors, Icon, Popover, PopoverInteractionKind, Position, Tooltip } from "@blueprintjs/core";
import { observer } from "mobx-react";
import clsx from "clsx";
import UnsavedTabIndicator from "./UnsavedTabIndicator";
import TabMenu from "./TabMenu";
import { defaultTabNameRegex } from "models/Tab/Tab.model";
import { useStores } from "store/Store";
import TabLabelInput from "./TabLabelInput";
import { TabStruct } from "models/Tab/Tab.utils";

type Props = {
  tab: TabStruct,
  tooltipContent: string
};

function ActiveTabContent(props: Props) {
  const { tab, tooltipContent } = props;
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isFocusedInput, setFocusedInput] = useState(false);
  const [isDuplicateName, setDuplicateName] = useState({ match: false, show: false, type: undefined });
  const inputRef = useRef();

  const { templatesStore, tabsStore } = useStores();
  const { savedAnalyses } = templatesStore;

  const onSave = () => {
    if (isDuplicateName.type === "team") {
      tabsStore.saveAsTeamAnalysis(tab.id);
      return;
    }

    if (tab.parentId && savedAnalyses.find(view => view.id === tab.parentId)) {
      templatesStore.patchView(tab.parentId, tab);
    } else {
      templatesStore.addView(tab);
    }
  };

  const tabClassNames = clsx("ml-2 text-truncate d-block", {
    "mr-2": tab.isEditMode
  });

  const checkSaveViewExistence = (label: string) =>
    savedAnalyses.find(analysis => analysis.label === label) || label.match(defaultTabNameRegex);

  const isMatchingMySavedAnalysis = checkSaveViewExistence(tab.label);

  if (isFocusedInput) {
    return (
      <>
        <UnsavedTabIndicator tab={tab} />
        <TabLabelInput
          inputRef={inputRef}
          isDuplicateName={isDuplicateName}
          onSave={onSave}
          setDuplicateName={setDuplicateName}
          setFocusedInput={setFocusedInput}
          tab={tab}
        />
      </>
    );
  }

  return (
    <>
      <UnsavedTabIndicator tab={tab} />
      <Tooltip
        boundary="viewport"
        className="d-block text-truncate flex-grow-1"
        content={tooltipContent}
        hoverOpenDelay={1000}
        modifiers={{ arrow: false }}
        position={Position.BOTTOM_LEFT}
        targetClassName="d-block"
      >
        <span
          className={tabClassNames}
          data-testid="tab-label-trigger"
          onClick={() => !tab.isEditingInfluence && setFocusedInput(true)}
        >
          {tab.isEditMode && <Icon className="mr-1" color={Colors.GRAY2} icon="edit" />}
          {tab.label}
        </span>
      </Tooltip>
      {!tab.isEditMode && (
        <Popover
          boundary="viewport"
          content={
            <TabMenu
              inputRef={inputRef}
              isMatchingMySavedAnalysis={isMatchingMySavedAnalysis}
              onSave={onSave}
              setDuplicateName={setDuplicateName}
              setFocusedInput={setFocusedInput}
              setIsPopoverOpen={setIsPopoverOpen}
              tab={tab}
            />
          }
          interactionKind={PopoverInteractionKind.CLICK}
          isOpen={isPopoverOpen}
          onInteraction={setIsPopoverOpen}
          position={Position.BOTTOM}
        >
          <Button className="tab-options" data-testid="tab-options" icon="chevron-down" minimal />
        </Popover>
      )}
    </>
  );
}

export default observer(ActiveTabContent);
