import formatValueWithUnit from "shared/helpers/formatValueWithUnit/formatValueWithUnit";
import isNumber from "shared/helpers/isNumber/isNumber";

const minMaxValue = value => {
  return value === -1 ? "DEL" : `${formatValueWithUnit(value, "currency")}`;
};

export default function minMaxDataTableCell(singleValue: { min: number | null; max: number | null }) {
  const min = isNumber(singleValue.min) ? minMaxValue(singleValue.min) : "--";
  const max = isNumber(singleValue.max) ? minMaxValue(singleValue.max) : "--";

  return `(${min}/${max})`;
}
