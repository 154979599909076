import { Button, IconName, Menu, Popover } from "@blueprintjs/core";
import styled from "@emotion/styled";
import React, { useState } from "react";
import kebabCase from "lodash.kebabcase";

const StyledButton = styled(Button)`
  transform: rotate(90deg);
`;

export type ModuleContextAction = {
  text: string;
  onClick: () => void;
  icon: IconName | JSX.Element;
  context?: {
    [key: string]: any;
  };
};

export type SingleAction = Omit<ModuleContextAction, "text">;
export type MultipleActions = ModuleContextAction[];

type Props = {
  actions: SingleAction | MultipleActions;
};

function ModuleContextActions({ actions }: Props) {
  const [isOpen, setIsOpen] = useState(false);

  if (!Array.isArray(actions)) {
    return (
      <StyledButton
        className="d-flex"
        data-testid="module-context-single-action"
        icon={actions.icon}
        minimal
        onClick={actions.onClick}
      />
    );
  }

  const actionsMenu = (
    <Menu>
      {actions.map((action: ModuleContextAction) => (
        <Menu.Item
          key={`module-context-${action.text}`}
          data-testid={`module-context-action-${kebabCase(action.text)}`}
          disabled={action.context?.disabled}
          icon={action.icon}
          onClick={action.onClick}
          text={action.text}
        />
      ))}
    </Menu>
  );

  return (
    <Popover content={actionsMenu} isOpen={isOpen} onInteraction={state => setIsOpen(state)} position="bottom-right">
      <StyledButton className="d-flex" data-testid="module-context-menu" icon="more" minimal />
    </Popover>
  );
}

export default ModuleContextActions;
