import { Button, Intent } from "@blueprintjs/core";
import { observer } from "mobx-react";
import React from "react";
import { useStores } from "store/Store";
import ControlNavigation from "components/ControlNavigation/ControlNavigation";

function CapacityControlNavigation() {
  const { capacityStore } = useStores();
  const { state, changeStep } = capacityStore;

  const previousSlot = (
    <div>
      <Button intent={Intent.PRIMARY} minimal onClick={() => changeStep(-1)}>
        Back
      </Button>
    </div>
  );

  const nextSlot = (
    <div className="d-flex justify-content-end">
      <Button disabled={!capacityStore.canContinueAdjustment} intent={Intent.PRIMARY} onClick={() => changeStep(1)}>
        Next
      </Button>
    </div>
  );

  return <ControlNavigation next={nextSlot} previous={previousSlot} step={state.step} />;
}

export default observer(CapacityControlNavigation);
