import React from "react";
import { Icon, MenuItem } from "@blueprintjs/core";
import clsx from "clsx";
import { observer } from "mobx-react";

type Props = {
  item: {
    label: string,
    value: any
  },
  itemProps: {
    handleClick: Function
  },
  selected: any
};

function SystemSettingsMenuItem({ item, itemProps, selected, ...rest }: Props) {
  const isSelected = selected === item.value;

  return (
    <MenuItem
      key={item.label}
      className={clsx({ "bp3-active": isSelected })}
      labelElement={isSelected ? <Icon icon="tick" /> : null}
      onClick={itemProps.handleClick}
      text={item.label}
      {...rest}
    />
  );
}

export default observer(SystemSettingsMenuItem);
