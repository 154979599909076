// @flow

import { makeAutoObservable } from "mobx";
import { AnalysisMappingsStore } from "../../models/AnalysisMappings.model";
import { AppStore } from "./App.model";
import { CapacityHistoryStore } from "../CapacityHistory/CapacityHistory.model";
import { CapacityStore } from "../Capacity/Capacity.model";
import { CirrusMappingsStore } from "../../models/Cirrus/CirrusMappings.model";
import { CirrusDefaultMarketStore } from "../../models/Cirrus/CirrusDefaultMarket.model";
import { CirrusODDatesStore } from "../../models/Cirrus/CirrusODDates.model";
import { DashboardHeatmapStore } from "../../models/Dashboard/DashboardHeatmapStore.model";
import { DashboardMarketMonthMetricsStore } from "../../models/Dashboard/DashboardMarketMonthMetrics.model";
import { DashboardMarketsStore } from "../../models/Dashboard/DashboardMarkets.model";
import { DashboardStore } from "../../models/Dashboard/Dashboard.model";
import { DashboardWatchlistStore } from "../../models/Dashboard/DashboardWatchlist.model";
import { EventsManagementStore } from "../../models/EventsManagement/EventsManagement.model";
import { InfluenceHistoryStore } from "../InfluenceHistory/InfluenceHistory.model";
import { InfluenceStore } from "../Influence/Influence.model";
import { MarketMappingsStore } from "../../models/MarketMappings.model";
import { ModalStore } from "../../models/Modal.model";
import { RegionsStore } from "../../models/Regions.model";
import { TemplatesStore } from "../Templates/Templates.model";
import { SystemSettingsStore } from "../../models/SystemSettings.model";
import { TabsStore } from "../../models/Tabs.model";
import { ThemeStore } from "../../models/Theme.model";
import { TimeStore } from "../../models/Time.model";
import { UsersStore } from "../../models/Users.model";

export class RootStore {
  constructor() {
    makeAutoObservable(this);
  }

  analysisMappingsStore = new AnalysisMappingsStore(this);
  appStore = new AppStore();
  capacityHistoryStore = new CapacityHistoryStore();
  capacityStore = new CapacityStore();
  cirrusMappingsStore = new CirrusMappingsStore(this);
  cirrusDefaultMarketStore = new CirrusDefaultMarketStore();
  cirrusODDatesStore = new CirrusODDatesStore();
  dashboardHeatmapStore = new DashboardHeatmapStore(this);
  dashboardMarketMonthMetricsStore = new DashboardMarketMonthMetricsStore(this);
  dashboardWatchlistStore = new DashboardWatchlistStore(this);
  dashboardMarketsStore = new DashboardMarketsStore(this);
  dashboardStore = new DashboardStore(this);
  eventsManagementStore = new EventsManagementStore(this);
  influenceHistoryStore = new InfluenceHistoryStore(this);
  influenceStore = new InfluenceStore();
  marketMappingsStore = new MarketMappingsStore();
  modalStore = new ModalStore(this);
  regionsStore = new RegionsStore(this);
  systemSettingsStore = new SystemSettingsStore(this);
  tabsStore = new TabsStore(this);
  templatesStore = new TemplatesStore(this);
  themeStore = new ThemeStore(this);
  timeStore = new TimeStore(this);
  usersStore = new UsersStore(this);
}
