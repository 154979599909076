import React from "react";
import { observer } from "mobx-react";

import styled from "@emotion/styled";
import { Button, Colors, Icon, Menu, Popover, Position, Tooltip } from "@blueprintjs/core";
import { Link, useHistory } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import { toJS } from "mobx";

import { useStores } from "store/Store";
import HeaderTabs from "./HeaderTabs";
import HeaderLogo from "modules/App/HeaderLogo/HeaderLogo";
import { ReactComponent as LogoIcon } from "../assets/logo-header.svg";
import { isCypress, isLocalhost } from "modules/App/constants";
import { ReactComponent as FlyrLogo } from "../assets/flyr-logotype.svg";

const StyledCopyrightText = styled("span")`
  color: ${Colors.DARK_GRAY5};
  font-size: 10px;
  line-height: 1;
`;

const StyledMenu = styled(Menu)`
  width: 200px;
`;

const StyledSettingsButton = styled(Button)`
  border-radius: 2px 2px 0 0;
  &:focus {
    outline: none;
    box-shadow: 0 0 2px 2px ${Colors.BLUE3}99 !important;
  }
`;

function DefaultHeader({ isRoot }: { isRoot: boolean }) {
  const rootStore = useStores();
  const { appStore, templatesStore } = rootStore;
  const { isAdmin } = appStore.auth;

  const history = useHistory();
  const { show: showIntercom } = useIntercom();

  const menu = (
    <StyledMenu className="p-0 pt-1">
      <Menu.Item
        className="px-3 py-1"
        data-testid="influence-history-link"
        icon={<Icon className="mr-2" icon="gantt-chart" />}
        onClick={() => history.push("/influence-history")}
        text="Influence History"
      />
      <Menu.Item
        className="px-3 py-1"
        data-testid="events-link"
        icon={<Icon className="mr-2" icon="timeline-events" />}
        onClick={() => history.push("/events-management")}
        text="Events Management"
      />
      {isAdmin && (
        <>
          <Menu.Item
            className="px-3 py-1"
            data-testid="user-management-link"
            icon={<Icon className="mr-2" icon="people" />}
            onClick={() => history.push("/user-management")}
            text="User Management"
          />
          <Menu.Item
            className="px-3 py-1"
            data-testid="settings-link"
            icon={<Icon className="mr-2" icon="wrench" />}
            onClick={() => history.push("/system-settings")}
            text="Admin Tools"
          />
        </>
      )}
      <Menu.Divider className="mx-3" />
      <Menu.Item
        className="px-3 py-1"
        data-testid="logout-link"
        icon={<Icon className="mr-2" icon="power" />}
        onClick={appStore.logOut}
        text="Log Out"
      />

      <div className="d-flex justify-content-between align-items-baseline pb-2 px-3" data-testid="copyright">
        <StyledCopyrightText>© 2021 - FLYR, INC.</StyledCopyrightText>
        <FlyrLogo className="ml-auto" height="18" />
      </div>
    </StyledMenu>
  );

  return (
    <>
      <HeaderLogo active={isRoot}>
        <Link className="navbar-brand pt-1" data-testid="dashboard-link" to="/dashboard">
          <LogoIcon />
        </Link>
      </HeaderLogo>

      <HeaderTabs />

      <Tooltip boundary="viewport" content="Browse Saved Analyses and available Templates" hoverOpenDelay={1000}>
        <StyledSettingsButton
          className="mr-1"
          data-testid="templates-sidebar-btn"
          icon={<Icon color={Colors.GOLD5} icon="bookmark" />}
          minimal
          onClick={templatesStore.toggleSidebar}
        />
      </Tooltip>

      {isLocalhost ? (
        <StyledSettingsButton
          className="mr-1"
          icon={<Icon className="opacity-50" color={Colors.WHITE} icon="code" />}
          minimal
          onClick={() =>
            // eslint-disable-next-line no-console
            console.log(toJS(rootStore))
          }
          title="Log store to console"
        />
      ) : null}

      {!isCypress ? (
        <StyledSettingsButton
          className="mr-1"
          data-testid="help-link"
          icon={<Icon className="opacity-50" color={Colors.WHITE} icon="help" />}
          minimal
          onClick={showIntercom}
          title="Get help"
        />
      ) : null}

      <div className="mr-1" data-testid="user-menu">
        <Popover content={menu} position={Position.BOTTOM_RIGHT}>
          <StyledSettingsButton icon={<Icon className="opacity-50" color={Colors.WHITE} icon="cog" />} minimal />
        </Popover>
      </div>
    </>
  );
}
export default observer(DefaultHeader);
