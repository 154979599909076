import React from "react";
import { observer } from "mobx-react";
import { Slider } from "@blueprintjs/core";

import { InfluenceStatus } from "modules/Influence/Influence.model";
import { influenceTypeNames } from "modules/Influence/influenceConstants";
import { StyledCard, StyledHeaderTitle } from "modules/Influence/InfluenceStyles";

import Row from "shared/components/Row/Row";
import { useStores } from "store/Store";

type Props = {
  readOnly: boolean;
};

function CompSensForm(props: Props) {
  const { readOnly } = props;
  const { influenceStore } = useStores();
  const { parameters, updateParameters, status } = influenceStore;
  const isLoading = status.includes(InfluenceStatus.LOADING);

  if (readOnly) {
    return (
      <StyledCard className="mb-2">
        <Row className="align-items-center">
          <div className="col-6">
            <StyledHeaderTitle>{influenceTypeNames.CS}</StyledHeaderTitle>
          </div>
          <div className="col-6 text-right" data-testid="influence-competitorSensitivity-value">
            {parameters.value}
          </div>
        </Row>
      </StyledCard>
    );
  }

  return (
    <StyledCard className="p-3 mb-2">
      <StyledHeaderTitle>{influenceTypeNames.CS}</StyledHeaderTitle>
      <p className="bp3-text-muted bp3-text-small">
        Adjust the model’s outputs reactiveness towards competitors prices
      </p>
      <Row>
        <div className="col-6 text-right">
          <strong className="mr-2">Sensitivity</strong>
        </div>
        <div className="col-6">
          <Slider
            disabled={isLoading}
            labelRenderer={(value, options) => {
              const shouldRenderValue = value % 2 === 0 || options?.isHandleTooltip;
              return shouldRenderValue ? value.toString() : "•";
            }}
            onChange={value => updateParameters({ inputValue: value })}
            value={parameters.inputValue}
          />
        </div>
      </Row>
    </StyledCard>
  );
}

export default observer(CompSensForm);
