import { Column, UsePaginationState, UseSortByState } from "react-table";
import { PageContextFilters } from "types/Flights.types";
import { Condition } from "types/Sidebar.types";

export type StickyColumn = Column & { sticky?: "left" | "right" };

export type TableColumn = Column | StickyColumn;

export type Formatter = {
  format: (value: number) => string;
};

export type DataTableFilters = {
  conditionalFilters?: Condition[];
  filters: PageContextFilters;
};

export enum SortingBy {
  Backend = "backend",
  Frontend = "frontend"
}

export type ColumnsConfig = { columnsWithoutFixedNumber: string[]; columnsAligned: string[] };

export type TablePagination = { pageCount: number; pageIndex: number; pageSize: number; totalRows: number };

export type FetchParams = Partial<UsePaginationState<{}> & UseSortByState<{}>>;
