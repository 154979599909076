import { Button } from "@blueprintjs/core";
import ControlNavigation from "components/ControlNavigation/ControlNavigation";
import isEmpty from "lodash.isempty";
import isEqual from "lodash.isequal";
import { observer } from "mobx-react";
import React from "react";
import { InfluenceType, PriceLimitsInfluenceParams } from "types/Influence.types";
import { HIGHEST_START_NDO } from "modules/Influence/influenceConstants";
import isNumber from "shared/helpers/isNumber/isNumber";
import { minMaxOverlaps } from "modules/Influence/minMaxOverlaps/minMaxOverlaps";

import { influenceSteps } from "modules/Influence/Influence.model";
import { Tab } from "models/Tab/Tab.model";
import { useStores } from "store/Store";
import InfluenceNextButton from "modules/Influence/InfluenceNextButton/InfluenceNextButton";

type Props = {
  getInfluencePreview: (
    type: InfluenceType,
    inputValue: number | null | PriceLimitsInfluenceParams[],
    params: { isBaseValue?: boolean }
  ) => void;
  saveInfluence: () => void;
  updateInfluence: () => void;
  tab: Tab;
};

function InfluenceControlNavigation(props: Props) {
  const { getInfluencePreview, saveInfluence, tab, updateInfluence } = props;

  const { influenceStore } = useStores();
  const { changeStep, parameters, state } = influenceStore;

  const { influenceStep } = state;
  const { SELECTION, SET, SUMMARY } = influenceSteps;
  const { inputValue, value } = parameters;

  const isInputValue = () => {
    if (state.influenceType === "CS") {
      return true;
    }

    if (isNumber(inputValue)) {
      return inputValue !== 0;
    }

    if (Array.isArray(inputValue)) {
      const valuesCheck = minMaxOverlaps(inputValue).map(element => {
        const values = [element.min, element.max];
        const ndoValues = [element.startNdo, element.endNdo];
        const ndoOverlaps = [element.startNdoOverlaps, element.endNdoOverlaps];

        // both NDOs are mandatory - start should be bigger
        if (ndoValues.some(value => !isNumber(value)) || element.startNdo < element.endNdo) {
          return false;
        }

        if (element.startNdo > HIGHEST_START_NDO) {
          return false;
        }

        if (ndoOverlaps.some(value => !!value)) {
          return false;
        }

        // both positive values - max should be bigger or equal
        if (values.every(value => isNumber(value) && value >= 0)) {
          return element.max >= element.min;
        }

        if (!isEmpty(influenceStore.validationErrors)) {
          return !isEqual(value, inputValue) && values.some(isNumber);
        }

        // at least one should be number
        return values.some(isNumber);
      });

      return valuesCheck.every(Boolean);
    }
    return false;
  };

  const resetInfluence = () => {
    influenceStore.resetSteps();
    tab.resetInfluence();
  };

  const isInfluenceEdit = influenceStep === SET && tab.editInfluence;
  const isPrevButtonHidden = [SELECTION, SUMMARY].includes(influenceStep) || isInfluenceEdit;
  const isNextButtonHidden = [SELECTION, SUMMARY].includes(influenceStep);
  const handlePrevButtonClick = () => (influenceStep === SET ? resetInfluence() : changeStep(-1));

  const previousSlot = (
    <div data-testid="influence-step-back-button">
      {!isPrevButtonHidden && (
        <Button intent="primary" minimal onClick={handlePrevButtonClick}>
          Back
        </Button>
      )}
    </div>
  );

  const nextSlot = (
    <div className="d-flex justify-content-end" data-testid="influence-step-next-button">
      {!isNextButtonHidden && (
        <InfluenceNextButton
          disabled={!isInputValue()}
          getInfluencePreview={getInfluencePreview}
          saveInfluence={saveInfluence}
          tab={tab}
          updateInfluence={updateInfluence}
        />
      )}
    </div>
  );

  return <ControlNavigation next={nextSlot} previous={previousSlot} step={influenceStep} />;
}

export default observer(InfluenceControlNavigation);
