import { Intent } from "@blueprintjs/core";
import * as Sentry from "@sentry/react";
import axios, { AxiosInstance, AxiosPromise } from "axios";
import { setupCache } from "axios-cache-adapter";

import { ApiConfig } from "../config/config";
import { isCypress, mockApiEnv } from "modules/App/constants";
import { baseInfluenceImpactGroup } from "shared/metricLabels/constants";
import { skipCustomParams } from "shared/helpers/skipCustomParams/skipCustomParams";
import { RegionEntry } from "models/Regions.model";
import { RUV } from "modules/App/App.types";

import { FlightCountResponse, FlightsResponse, FlightTableParams } from "types/Flights.types";
import { PriceLimitsPreviewInfluence, PreviewInfluence, InfluenceId } from "types/Influence.types";
import {
  AbsoluteAdjustmentEditInfluence,
  CompSensAdjustmentEditInfluence,
  InfluencePreviewResponse,
  PercentAdjustmentEditInfluence,
  PriceLimitsEditInfluence
} from "types/Influence.types";
import { InfluenceHistoryResponse, SingleInfluenceHistoryResponse } from "types/InfluenceHistory.types";
import { SharedAnalysis } from "types/Tab.types";
import { AppToaster } from "./Toaster";

export const isCancel = axios.isCancel;

const flightsSkippedColumns = Object.keys(baseInfluenceImpactGroup());

class API {
  api: AxiosInstance;
  cancelTokens: object;

  init(env: ApiConfig, authToken?: string, onUnauthorized?: Function) {
    const cache = setupCache({ exclude: { methods: ["put", "patch", "delete"] }, maxAge: 0 });
    this.api = axios.create({
      baseURL: env.apiUrl
    });
    this.cancelTokens = {};

    // Overriden by AxiosMockAdapter
    if (env?.clientId !== mockApiEnv.clientId) {
      this.api.defaults.adapter = cache.adapter;
    }

    this.api.defaults.headers.common.Authorization = `Bearer ${authToken}`;
    if (isCypress) {
      this.api.interceptors.request.use(
        function (config) {
          if (
            config.method?.match(/POST|PUT|DELETE/) &&
            config.url?.match(/influences/) &&
            !config.url?.match(/preview/)
          ) {
            const tempURL = new URL(config.url, config.baseURL);
            tempURL.searchParams.append("e2e-testing", "true");
            config.url = tempURL.pathname + tempURL.search;
          }
          return config;
        },
        error => Promise.reject(error)
      );
    }

    this.api.interceptors.response.use(
      response => response,
      error => {
        if (error.response) {
          switch (error.response.status) {
            case 400:
            case 500: {
              AppToaster.show({
                intent: Intent.DANGER,
                message: "Something went wrong. Please contact support"
              });
              return Promise.reject(error);
            }
            case 401:
              if (typeof onUnauthorized === "function") {
                onUnauthorized();
              }
              AppToaster.show({
                intent: Intent.DANGER,
                message: "Authorization failed. Please refresh the page and login"
              });
              return Promise.reject(error);
            case 403:
              return AppToaster.show({
                intent: Intent.DANGER,
                message: "You don't have permission to access this resource"
              });
            case 404:
              AppToaster.show({
                intent: Intent.DANGER,
                message: "Requested resource was not found"
              });
              return Promise.reject(error);
            case 409:
              return Promise.reject(error);
            case 502:
            case 504:
              return AppToaster.show({
                intent: Intent.DANGER,
                message: "Server could not complete request. Try again later"
              });
            default:
              return;
          }
        }

        const { config } = error;
        if (config != null) {
          // Errors without response, i.e. from requests that didn't reach backend
          // Known example: Network Error
          const method = String(config.method).toUpperCase();
          const path = config.url;
          const message = `${method} ${path} ${error.message || "No message"}`;

          Sentry.addBreadcrumb({ level: Sentry.Severity.Error, message });
        }

        return Promise.reject(error);
      }
    );
  }

  cancelPreviousCall(name: string, groupId: number | string) {
    const CancelToken = axios.CancelToken;

    if (!this.cancelTokens[groupId]) {
      this.cancelTokens[groupId] = {};
    }

    if (this.cancelTokens[groupId][name]) {
      this.cancelTokens[groupId][name].cancel();
    }

    this.cancelTokens[groupId][name] = CancelToken.source();

    return { cancelToken: this.cancelTokens[groupId][name].token };
  }

  getRUV(): AxiosPromise<RUV> {
    return this.api.get("/ruv-metrics");
  }

  getCirrusStatus(params: object) {
    return this.api.post(`/cirrus-statuses/get`, params);
  }

  switchCirrusStatus(params: object, cirrusStatus: boolean) {
    return this.api.put("/cirrus-statuses", {
      ...params,
      update: { cirrusStatus }
    });
  }

  getDistanceUnit() {
    return this.api.get("/registers/distance_unit");
  }

  setDistanceUnit(unit: string) {
    return this.api.put("/registers/distance_unit", { value: unit });
  }

  getDateFormat() {
    const { cancelToken } = this.cancelPreviousCall("getDateFormat", 1);
    return this.api.get("/registers/date-format", cancelToken);
  }

  setDateFormat(dateFormat: string) {
    return this.api.put("/registers/date-format", { value: dateFormat });
  }

  getCirrusMappings() {
    return this.api.get("/cirrus-statuses/mappings");
  }

  getMarkets(params) {
    return this.api.post("/markets/config/mappings/get", params);
  }

  updateMarkets(data) {
    return this.api.put("/markets/config/mappings", data);
  }

  getUsers() {
    return this.api.get("/users");
  }

  getUserGroups() {
    return this.api.get("/groups");
  }

  addUser(user: object) {
    return this.api.post("/users", user);
  }

  getCurrentUser() {
    return this.api.get("/users/me");
  }

  editCurrentUser(metadata: object) {
    return this.api.patch(`/users/me`, metadata);
  }

  editUser(userId: number, metadata: object) {
    return this.api.patch(`/users/${userId}`, metadata);
  }

  deleteUser(userId: number) {
    return this.api.delete(`/users/${userId}`);
  }

  resetPassword(email: string) {
    return this.api.post("/users/reset-password", { email });
  }

  getExploreMappings() {
    return this.api.get("/explore/mappings");
  }

  deleteTeamAnalyses(templateId: number) {
    return this.api.delete(`/team-templates/${templateId}`);
  }

  getFlights(params: object, tabId: number | string, identifier: string = "getFlights"): AxiosPromise<FlightsResponse> {
    const { cancelToken } = this.cancelPreviousCall(identifier, tabId);

    const validParams = skipCustomParams(params, { columns: flightsSkippedColumns });

    if (validParams?.sortBy?.field === "analystId") {
      validParams.sortBy.field = "analystName";
    }
    return this.api.post("/explore/metrics/get", params, { cancelToken });
  }

  getFlightsCount(
    params: FlightTableParams,
    tabId: number | string,
    identifier: string = "getFlightsCount"
  ): AxiosPromise<FlightCountResponse> {
    const { cancelToken } = this.cancelPreviousCall(identifier, tabId);
    return this.api.post("/explore/metrics/count/get", params, { cancelToken });
  }

  getForecastedBuildCurves(params: object, tabId: number) {
    const { cancelToken } = this.cancelPreviousCall("getForecastedBuildCurves", tabId);
    return this.api.post("/explore/curve/forecast/get", params, { cancelToken });
  }

  getBuildCurves(params: object, tabId: number) {
    const { cancelToken } = this.cancelPreviousCall("getBuildCurves", tabId);
    return this.api.post("/explore/curve/get", params, { cancelToken });
  }

  getKPIsTimestamp() {
    return this.api.get("/explore/metrics/kpis-timestamp");
  }

  getMySavedAnalyses() {
    return this.api.get("/saved-views");
  }

  addMySavedAnalysis(view: object) {
    return this.api.post("/saved-views", view);
  }

  patchMySavedAnalysis(viewId: number, view: object) {
    return this.api.put(`/saved-views/${viewId}`, view);
  }

  removeMySavedAnalysis(viewId: string) {
    return this.api.delete(`/saved-views/${viewId}`);
  }

  getSystemAnalyses() {
    return this.api.get("/system-templates");
  }

  createTeamAnalysis(template: object) {
    return this.api.post("/team-templates", template);
  }

  editTeamAnalysis(templateId: number, data: object) {
    return this.api.patch(`/team-templates/${templateId}`, data);
  }

  getTeamAnalyses() {
    return this.api.get("/team-templates");
  }

  /* Competitor Sensitivity (CS) */

  previewCompSensInfluence(influence: PreviewInfluence): AxiosPromise<InfluencePreviewResponse> {
    const { cancelToken } = this.cancelPreviousCall("preview", "influence");
    return this.api.post("influences/cs-adj/preview", influence, { cancelToken });
  }

  saveCompetitorSensitivityInfluence(influence: object) {
    return this.api.post("/influences/cs-adj/", influence);
  }

  previewCompSensInfluenceEdit(
    influence: CompSensAdjustmentEditInfluence,
    params: { influenceId: string }
  ): AxiosPromise<InfluencePreviewResponse> {
    const { cancelToken } = this.cancelPreviousCall("preview-edit", "influence");
    return this.api.put(`influences/cs-adj/${params.influenceId}/preview`, influence, { cancelToken });
  }

  updateCompetitorSensitivityInfluence(influence, params) {
    const { cancelToken } = this.cancelPreviousCall("update-cs", "influence");
    return this.api.put(`influences/cs-adj/${params.influenceId}`, influence, {
      cancelToken
    });
  }

  /* Relative Price Adjustment (PA) */

  previewPercentInfluence(influence: PreviewInfluence): AxiosPromise<InfluencePreviewResponse> {
    const { cancelToken } = this.cancelPreviousCall("preview", "influence");
    return this.api.post("influences/percent-adj/preview", influence, { cancelToken });
  }

  savePercentInfluence(influence: object) {
    return this.api.post("/influences/percent-adj/", influence);
  }

  previewPercentInfluenceEdit(
    influence: PercentAdjustmentEditInfluence,
    params: { influenceId: string }
  ): AxiosPromise<InfluencePreviewResponse> {
    const { cancelToken } = this.cancelPreviousCall("preview-edit", "influence");
    return this.api.put(`influences/percent-adj/${params.influenceId}/preview`, influence, { cancelToken });
  }

  updatePercentInfluence(influence, params) {
    const { cancelToken } = this.cancelPreviousCall("update-percent", "influence");
    return this.api.put(`influences/percent-adj/${params.influenceId}`, influence, {
      cancelToken
    });
  }

  /* Absolute Price Adjustment (AP) */

  previewAbsolutePriceInfluence(influence: PreviewInfluence): AxiosPromise<InfluencePreviewResponse> {
    const { cancelToken } = this.cancelPreviousCall("preview", "influence");
    return this.api.post("influences/ap-adj/preview", influence, { cancelToken });
  }

  saveAbsoluteInfluence(influence: object) {
    return this.api.post("/influences/ap-adj/", influence);
  }

  previewAbsolutePriceInfluenceEdit(
    influence: AbsoluteAdjustmentEditInfluence,
    params: { influenceId: string }
  ): AxiosPromise<InfluencePreviewResponse> {
    const { cancelToken } = this.cancelPreviousCall("preview-edit", "influence");
    return this.api.put(`influences/ap-adj/${params.influenceId}/preview`, influence, { cancelToken });
  }

  updateAbsolutePriceInfluence(influence, params: { influenceId: string }) {
    const { cancelToken } = this.cancelPreviousCall("update-percent", "influence");
    return this.api.put(`influences/ap-adj/${params.influenceId}`, influence, {
      cancelToken
    });
  }

  /* Min/Max Price Limits (MM) */

  previewPriceLimitsInfluence(influence: PriceLimitsPreviewInfluence): AxiosPromise<InfluencePreviewResponse> {
    const { cancelToken } = this.cancelPreviousCall("preview", "influence");
    return this.api.post("influences/minmax-adj/preview", influence, { cancelToken });
  }

  savePriceLimitsInfluence(influence: object) {
    return this.api.post("/influences/minmax-adj/", influence);
  }

  previewPriceLimitsInfluenceEdit(
    influence: PriceLimitsEditInfluence,
    params: { influenceId: string }
  ): AxiosPromise<InfluencePreviewResponse> {
    const { cancelToken } = this.cancelPreviousCall("preview-edit", "influence");

    return this.api.put(`influences/minmax-adj/${params.influenceId}/preview`, influence, { cancelToken });
  }

  updateMinMaxInfluence(influence, params: { influenceId: string }) {
    const { cancelToken } = this.cancelPreviousCall("update-minmax", "influence");
    return this.api.put(`influences/minmax-adj/${params.influenceId}`, influence, {
      cancelToken
    });
  }

  /* Influence History */

  getInfluenceHistory(params: object): AxiosPromise<InfluenceHistoryResponse> {
    const { cancelToken } = this.cancelPreviousCall("get", "influenceHistory");

    return this.api.post("/influence-history/get", params, { cancelToken });
  }

  getSingleInfluenceHistory(influenceId: InfluenceId): AxiosPromise<SingleInfluenceHistoryResponse> {
    return this.api.get(`/influence-history/${influenceId}`);
  }

  getInfluenceHistoryMappings() {
    return this.api.get("/influence-history/mappings");
  }

  getRowInfluenceIds(params: object) {
    return this.api.post("/influence-history/row-to-influence-ids/get", params);
  }

  deleteInfluences(params: object) {
    return this.api.delete("/influences", { data: params });
  }

  getEventsManagementEvents(params: object) {
    return this.api.post("/events/get", params);
  }

  getEventsMappings() {
    return this.api.get("/events/mappings");
  }

  getEventById(id) {
    return this.api.get(`/events/${id}`);
  }

  addEventManagement(params: object) {
    return this.api.post("/events", params);
  }

  editEventManagement(eventId: number, params: object) {
    return this.api.put(`/events/${eventId}`, params);
  }

  deleteEventManagement(data: { eventIds: Array<number> }) {
    return this.api.delete("/events", { data });
  }

  getExistingEvents(data: { name: string } | { dateRange: { start: string; end: string } }) {
    return this.api.post("/events/autocomplete/get", data);
  }

  getCapacityHistoryData() {
    return new Promise(resolve =>
      setTimeout(
        () =>
          resolve({
            data: []
          }),
        2000
      )
    );
  }

  getRegionsSubregions(): AxiosPromise<RegionEntry[]> {
    return this.api.get("/markets/config/region-subregion");
  }

  addRegion(regionName: string) {
    return this.api.post("/markets/config/region", { regionName });
  }

  editRegion(regionId: number, data: { regionName: string }) {
    return this.api.put(`/markets/config/region/${regionId}/`, data);
  }

  addSubregion(regionId: number, subregionName: string) {
    return this.api.post("/markets/config/subregion", { regionId, subregionName });
  }

  editSubregion(subregionId: number, data: { regionId?: number; subregionName?: string }) {
    return this.api.patch(`/markets/config/subregion/${subregionId}/`, data);
  }

  deleteRegionSubregion(data: { regionId?: number[]; subregionId?: number[] }) {
    return this.api.delete("/markets/config/region-subregion", { data });
  }

  shareAnalysis(analysis): AxiosPromise<{ createdOn: string; shareId: string }> {
    return this.api.post("/share-analysis", { analysis }, { cache: { maxAge: 10 * 60 * 1000 } });
  }

  getSharedAnalysis(shareId: string): AxiosPromise<{ createdOn: string; analysis: SharedAnalysis }> {
    return this.api.get(`/share-analysis/${shareId}`);
  }

  getCirrusDefaultStatus(): AxiosPromise<{ value: boolean }> {
    return this.api.get("/registers/default-cirrus-status");
  }

  setCirrusDefaultStatus(status: boolean): AxiosPromise<{ value: boolean }> {
    return this.api.put("/registers/default-cirrus-status", { value: status });
  }
}

const api = new API();

export default api;
