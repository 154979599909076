export type CapacityData = { aggregated?: object; rows?: object[] };

export type CapacityAdjustmentData = {
  base: CapacityData;
  preview: CapacityData;
};

export type AdjustmentType = "overbooking" | "liddedCap";

export type CapacityItem = {
  key: AdjustmentType;
  name: string;
  disabled?: boolean;
};
export type AdjustmentState = {
  step: number;
  type: AdjustmentType;
};

export enum AdjustmentLoadingStatus {
  DONE = "DONE",
  DONE_BASE = "DONE_BASE",
  DONE_PREVIEW = "DONE_PREVIEW",
  ERROR = "ERROR",
  ERROR_BASE = "ERROR_BASE",
  ERROR_PREVIEW = "ERROR_PREVIEW",
  INIT = "INIT",
  LOADING = "LOADING",
  LOADING_BASE = "LOADING_BASE",
  LOADING_PREVIEW = "LOADING_PREVIEW"
}
