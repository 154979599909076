import ActiveFilterCounter from "../../ActiveFilterCounter/ActiveFilterCounter";
import React from "react";
import { observer } from "mobx-react";
import styled from "@emotion/styled";

const StyledFilterCountersContainer = styled("span")`
  span:first-of-type span span {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }

  span:last-of-type span span {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }
`;

type Props = {
  pageContext: any;
  groupKey: string;
};

function FilterCounters(props: Props) {
  const { pageContext, groupKey } = props;

  return (
    <StyledFilterCountersContainer>
      <ActiveFilterCounter
        count={pageContext.filterCountsByGroup.applied[groupKey]}
        tooltipText="Applied filters"
        type="applied"
      />
      <ActiveFilterCounter count={pageContext.filterCountsByGroup.modified[groupKey]} />
      <ActiveFilterCounter
        count={pageContext.filterCountsByGroup.disabled[groupKey]}
        tooltipText="Disabled filters"
        type="disabled"
      />
    </StyledFilterCountersContainer>
  );
}

export default observer(FilterCounters);
