import React from "react";
import cloneDeep from "lodash.clonedeep";
import { observer } from "mobx-react";
import { useStores } from "store/Store";
import { PRICE_LIMITS_INIT } from "modules/Influence/Influence.model";
import ControlButton from "components/ControlButton/ControlButton";
import { InfluenceType, PriceLimitsInfluenceParams } from "types/Influence.types";

type Props = {
  getInfluencePreview: (
    type: InfluenceType,
    inputValue: number | null | PriceLimitsInfluenceParams[],
    params: { isBaseValue?: boolean }
  ) => void;
  disabled: boolean;
  noRowsSelected: boolean;
};

function InfluenceControlSelection(props: Props) {
  const { disabled, getInfluencePreview, noRowsSelected } = props;
  const { influenceStore } = useStores();
  const { influencesList, updateState, updateParameters } = influenceStore;

  const setInfluenceType = (type: InfluenceType) => {
    updateState({ influenceStep: 2, influenceType: type });
    // `selectedInfluence` getter is not updated at this point
    const influenceOptions = influencesList.find(item => item.type === type) || {};
    if (influenceOptions.isPreview) {
      getInfluencePreview(type, 0, { isBaseValue: true });
    }

    if (influenceOptions.type === "MM") {
      updateParameters({
        inputValue: [cloneDeep(PRICE_LIMITS_INIT)],
        value: [cloneDeep(PRICE_LIMITS_INIT)]
      });
    }
  };

  const buttons = influencesList.map(influence => {
    const { type, name } = influence;

    return (
      <ControlButton key={type} disabled={disabled || noRowsSelected} onClick={() => setInfluenceType(type)}>
        {name}
      </ControlButton>
    );
  });
  const helpText = noRowsSelected
    ? "Please, select rows in the table below to start an influence"
    : "Influences applied in the order they are presented above";

  return (
    <div className="p-2" data-testid="influence-control-selection">
      {buttons}
      <p className="bp3-text-muted bp3-text-small mt-2 d-flex flex-column">
        {helpText}
        <a href="https://help.cirrus.ai/en/articles/4284566-influence" rel="noopener noreferrer" target="_blank">
          Read more about influences
        </a>
      </p>
    </div>
  );
}

export default observer(InfluenceControlSelection);
