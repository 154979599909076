import React, { useEffect, useMemo } from "react";
import styled from "@emotion/styled";
import { Colors, Icon, Tooltip } from "@blueprintjs/core";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import isEmpty from "lodash.isempty";
import clsx from "clsx";

import columnTextWidths from "shared/helpers/columnTextWidths/columnTextWidths";
import DataTable from "shared/components/DataTable/DataTable";
import LinkCell from "shared/components/DataTable/LinkCell/LinkCell";
import metricLabels from "shared/metricLabels/metricLabels";
import Module from "shared/components/Module/Module";
import NumberOfFlights from "shared/components/NumberOfFlights/NumberOfFlights";
import { extraMetrics, metricsTypes } from "modules/App/constants";
import mergeContextualColumns from "modules/FlightsTable/mergeContextualColumns/mergeContextualColumns";
import { Row, Cell } from "types/react-table.types";
import { useStores } from "store/Store";

const getRowIdCallback = (row: Row, aggregations) =>
  aggregations
    .filter(aggregationKey => aggregationKey !== "numberOfFlights")
    .map(aggregation => row[aggregation])
    .join("_");

const getColumnLabelDescription = (isMiles, xDayBuild) => {
  const columnLabels = {
    ...extraMetrics,
    ...metricLabels({ isMiles, withUnits: true, xDayBuild }),
    depMonth: "Dep Month",
    flightCount: "Flt Count"
  };

  const columnDescriptions = metricLabels({
    isMiles,
    longVersion: true,
    xDayBuild
  });
  return { columnDescriptions, columnLabels };
};

const StyledEmptyStateText = styled("div")`
  font-size: 18px;
  font-weight: 500;
`;

const StyledEmptyStateLink = styled("a")`
  font-weight: 500;
`;

const StyledTableWrapper = styled("div")`
  max-height: 50%;

  &.-with-minimum-height {
    min-height: 315px;
  }
`;

const StyledTableContainer = styled("div")`
  td[data-testid="data-table-cell-analysisLink"] {
    padding: 0 !important;

    a {
      border-radius: 0;
      min-height: 100% !important;
      min-width: 100% !important;
    }

    .bp3-popover-target {
      display: flex;
      height: 100%;
      width: 100%;
    }
  }
`;

const createAnalysisLinkColumn = (canCreateTab: boolean, addNewTab: Function, { row }: Cell) => {
  return (
    <Tooltip
      className="d-flex justify-content-center align-items-center h-100"
      content={canCreateTab ? "Explore all flights in this row" : "Maximum tab count reached"}
      hoverOpenDelay={1000}
    >
      <LinkCell disabled={!canCreateTab} onClick={() => addNewTab(row.values)} />
    </Tooltip>
  );
};

const emptyState = (
  <div className="d-flex flex-column justify-content-center align-items-center h-100 py-4">
    <Icon className="mb-3" color={Colors.GRAY4} icon="updated" iconSize={60} />
    <StyledEmptyStateText className="my-1">All Markets are performing well</StyledEmptyStateText>
    <StyledEmptyStateLink
      className="d-inline-block"
      href="https://help.cirrus.ai/en/articles/4249668-dashboard-future-capability"
      rel="noopener noreferrer"
      target="_blank"
    >
      Read More about Markets in this widget
    </StyledEmptyStateLink>
  </div>
);

const tabNewConfig = (
  tab: {
    applied: {
      filters: {};
    };
    flightsTable: {
      aggregations: string[];
    };
    filters: {};
    id: string;
    label: string;
  },
  {
    depMonth,
    isMyMarkets,
    userId,
    rtMarket
  }: { depMonth: string; isMyMarkets: boolean; userId: number; rtMarket: string }
) => {
  const newTab = tab;

  const newFilters = {
    analystId: isMyMarkets ? [userId] : [],
    depMonth: [depMonth],
    rtMarket: [rtMarket]
  };

  newTab.applied.filters = newFilters;
  newTab.filters = newFilters;
  newTab.flightsTable.aggregations = ["owMarket", "depWeek"];
  newTab.label = `${rtMarket}/${depMonth}`;
  return newTab;
};

function MarketMonthsTable() {
  const { appStore, dashboardStore, dashboardMarketMonthMetricsStore, tabsStore, systemSettingsStore } = useStores();
  const { activePage, isLoaded: dashboardIsLoaded, isMyMarkets } = dashboardStore;
  const history = useHistory();

  const {
    flightsCount,
    isError,
    isLoaded,
    isLoading,
    refetchTableData,
    table,
    xDayBuild
  } = dashboardMarketMonthMetricsStore;

  useEffect(() => {
    if (dashboardIsLoaded) {
      refetchTableData({});
    }
  }, [activePage, refetchTableData, dashboardIsLoaded]);

  const { addTab, canCreateTab, defaultNewTab } = tabsStore;
  const { aggregations, data, influenceImpactGroupColumns, pagination } = table;
  const { userId } = appStore;
  const { isMiles } = systemSettingsStore;

  const baseMetrics = table.columns;

  const { columnLabels, columnDescriptions } = getColumnLabelDescription(isMiles, xDayBuild);

  const columns = useMemo(() => mergeContextualColumns(table.columns, influenceImpactGroupColumns), [
    influenceImpactGroupColumns,
    table.columns
  ]);

  const columnTextWidth = useMemo(() => columnTextWidths(columnLabels), [columnLabels]);

  const onSortedChange = currentSort => {
    pagination.pageIndex = 0;

    if (currentSort) {
      table.sortBy = {
        direction: currentSort[0].desc ? "desc" : "asc",
        field: currentSort[0].id
      };
    }
  };

  const addNewTab = ({ depMonth, rtMarket }: { depMonth: string; rtMarket: string }) => {
    const newTab = tabNewConfig(defaultNewTab(), {
      depMonth,
      isMyMarkets,
      rtMarket,
      userId
    });

    addTab(newTab);
    history.push(`/analysis/${newTab.id}/explore`);
  };

  const tableProps = {
    ...table,
    baseMetrics,
    canSetPageSize: false,
    cellRenderers: {
      ...metricsTypes,
      analysisLink: props => createAnalysisLinkColumn(canCreateTab, addNewTab, props)
    },
    columnDescriptions,
    columnLabels,
    columns: [["link", ["analysisLink"]], ["aggregations", [...aggregations, "numberOfFlights"]], ...columns],
    columnTextWidth,
    columnWidths: { analysisLink: 24 },
    fetchData: refetchTableData,
    getRowIdCallback: row => getRowIdCallback(row, aggregations),
    onSortedChange,
    selectedRowsNumberOfFlights: 0,
    title: "Markets"
  };

  const extraSubContent = (
    <NumberOfFlights
      flightsCount={flightsCount}
      flightsTable={table}
      options={{ showRows: true, showSelected: true, showTotal: false }}
      prefix=" · "
    />
  );

  const marketMonthsTable = isEmpty(data) && isLoaded ? emptyState : <DataTable {...tableProps} />;

  return (
    <StyledTableWrapper
      className={clsx("d-flex flex-column flex-shrink-1 mh-0", { "-with-minimum-height": data?.length >= 7 })}
    >
      <Module
        childrenClassName={clsx({ "h-100": !(isLoading || isError) })}
        className="flex-shrink-1"
        minHeight={0}
        subtitle={[extraSubContent]}
        title="Market-Months"
      >
        <StyledTableContainer className="d-flex flex-column h-100" data-testid="market-months-table">
          {marketMonthsTable}
        </StyledTableContainer>
      </Module>
    </StyledTableWrapper>
  );
}

export default observer(MarketMonthsTable);
