import React from "react";
import isEmpty from "lodash.isempty";
import { observer } from "mobx-react";

import BuildCurves from "../BuildCurves";
import NumberOfFlights from "shared/components/NumberOfFlights/NumberOfFlights";
import { createResizeContextActions } from "shared/components/Resizable/Resizable.utils";
import { TAB_INIT } from "models/Tab/Tab.utils";
import { TabPreferences } from "types/Tab.types";
import { useStores } from "store/Store";

type Props = {
  tabId: string;
};

function ExploreBuildCurves(props: Props) {
  const { tabId } = props;
  const { analysisMappingsStore, templatesStore, tabsStore, systemSettingsStore } = useStores();
  const tab = tabsStore.tabsById[tabId];

  const { isMiles } = systemSettingsStore;
  const modulePreferences: TabPreferences["modules"] = tab.preferences?.modules || {};
  const componentFromMySavedAnalysis = templatesStore.getComponent(tab.parentId, tab.parentType, "buildCurves");
  const baseMetrics = componentFromMySavedAnalysis ? componentFromMySavedAnalysis.series : TAB_INIT.buildCurves.series;

  const cabinClasses = !isEmpty(tab.applied.filters.cabinClass)
    ? tab.applied.filters.cabinClass
    : analysisMappingsStore.getCabinClasses();

  const subtitle = [
    <NumberOfFlights
      flightsCount={tab.flightsCount}
      flightsTable={tab.flightsTable}
      selectedRowsNumberOfFlights={tab.selectedRowsNumberOfFlights}
    />
  ];

  const contextActions = createResizeContextActions(!!modulePreferences?.buildCurves?.isCollapsed, isCollapsed =>
    tab.setModuleState("buildCurves", { isCollapsed })
  );

  return (
    <BuildCurves
      {...tab.buildCurves}
      key={modulePreferences?.exploreTopRow?.isResizing}
      analysisMappingsStore={analysisMappingsStore}
      baseMetrics={baseMetrics}
      cabinClasses={cabinClasses}
      changeCabinClass={(cabinClass: string) => tab.changeBuildCurvesClass(cabinClass)}
      changeDateOption={(option: boolean) => tab.changeDateOption(option)}
      changeGroupsStatus={(items: { isOpen: boolean; label: string }[]) =>
        tab.changeBuildCurvesParams("groupStatuses", items, { refetch: false, skipSave: true })
      }
      changeSelectedRange={range => tab.changeSelectedRange(range)}
      changeSeries={(series: string[]) => tab.changeSeries(series)}
      displayGraphMenu={tab.displayGraphMenu}
      fetchData={() => tab.getBuildCurvesData()}
      flightsTableSelectedRows={tab.flightsTable.selectedRows}
      isBuildCurvesZoomed={tab.isBuildCurvesZoomed}
      isMiles={isMiles}
      modulePreferences={modulePreferences}
      moduleProps={{ contextActions, isCollapsed: modulePreferences?.buildCurves?.isCollapsed }}
      reversedAxis={["lacGroup"]}
      subtitle={subtitle}
      tab={tab}
      today={tab.getToday()}
      toggleGraphMenu={(isVisible: boolean) => tab.toggleGraphMenu(isVisible)}
    />
  );
}

export default observer(ExploreBuildCurves);
