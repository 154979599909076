// @flow

import cloneDeep from "lodash.clonedeep";
import difference from "lodash.difference";
import isEmpty from "lodash.isempty";
import isEqual from "lodash.isequal";
import keyBy from "lodash.keyby";
import omit from "lodash.omit";
import union from "lodash.union";
import { action, computed, makeObservable, observable, toJS } from "mobx";
import { Intent } from "@blueprintjs/core";

import api from "services/Api";
import getEnabledFilters from "shared/helpers/getEnabledFilters/getEnabledFilters";
import type { RootStore } from "modules/App/Root.model";
import { AppToaster } from "services/Toaster";
import { changeUserSortOrder } from "shared/helpers/changeUserSortOrder/changeUserSortOrder";
import prepareEventObject from "modules/Events/prepareEventObject/prepareEventObject";
import getPlaceCodes from "modules/Events/getPlaceCodes/getPlaceCodes";
import { Status } from "modules/App/Status";
import removeValueInFilter from "shared/helpers/removeValueInFilter/removeValueInFilter";
import toggleFilterValue from "shared/helpers/toggleFilterValue/toggleFilterValue";
import toggleFilter from "shared/helpers/toggleFilter/toggleFilter";
import syncDisabledFilters from "shared/helpers/syncDisabledFilters/syncDisabledFilters";
import { eventsManagementMetrics } from "modules/Events/eventsManagementMetrics";
import filterCountsByGroup from "shared/helpers/filterCountsByGroup/filterCountsByGroup";
import { EVENTS_MAPPINGS_INIT, FILTERS_INIT, INIT_EVENT_BASE_FIELDS, PAGE_INIT } from "./EventsManagement.utils";

const sortLabel = (a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase());

type FilterValue = string | number | { start: string, end: string };

const filterItemsWithConflicts = (items = [], indexes) => items.filter((value, index) => !indexes.includes(index));
const reorganizeEventsAutosuggestion = (rows = [], type = "") =>
  rows.map(({ id, name, ...item }) => ({ ...item, label: name, type, value: id }));
const sumExtraMatches = data =>
  Object.values(data)
    .map(b => b?.moreMatches?.value || 0)
    .reduce((acc, val) => acc + val, 0);

const fieldMap = {
  categoryName: "category",
  endDate: "dateRange.end",
  startDate: "dateRange.start"
};

export class EventsManagementStore {
  @observable page = cloneDeep(PAGE_INIT);
  @observable eventsMappings = cloneDeep(EVENTS_MAPPINGS_INIT);
  @observable activeSuggestionType = "";

  rootStore: RootStore;

  constructor(rootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  setDefaultSorting() {
    this.page.eventsTable.sortBy = PAGE_INIT.eventsTable.sortBy;
  }

  prepareSelectItem(item: { id: string, name: string }): { label: string, value: string } {
    return { label: item.name, value: item.id };
  }

  @action.bound
  toggleFilter(filterKey: string) {
    toggleFilter(filterKey, this.page.filters, this.page.disabledFilters);
    this.getEventsData();
  }

  @action.bound
  toggleFilterValue(filterKey: string, filterValueToToggle: string | number) {
    toggleFilterValue(filterKey, filterValueToToggle, this.page.disabledFilters);

    this.getEventsData();
  }

  @action.bound
  initEventModal(isAddingEvent: boolean) {
    this.page.modalSuggestStatus = Status.INIT;
    this.page.modalEvent = prepareEventObject(isAddingEvent ? this.page.modalEvent : this.page.selectedEvent);
  }

  @action.bound
  toggleUpcomingEvents() {
    this.page.upcomingEvents = !this.page.upcomingEvents;
    this.getEventsData();
  }

  @action.bound
  clearEvent() {
    this.page.modalEvent = {};
    this.page.clonedModalEvent = {};
    this.clearExistingEvents();
  }

  @action
  getEventsData(params: Object) {
    const { eventsTable, upcomingEvents } = this.page;
    const { pageIndex = 0, pageSize = eventsTable.pagination.pageSize, sortBy = eventsTable.sortBy } = params || {};

    eventsTable.status = Status.LOADING;
    eventsTable.pagination.pageSize = pageSize;
    eventsTable.pagination.pageIndex = pageIndex;
    eventsTable.sortBy = sortBy;
    this.page.clonedModalEvent = {};
    this.page.modalEvent = { ...INIT_EVENT_BASE_FIELDS };

    api
      .getEventsManagementEvents({
        filters: this.normalizedFilters,
        pagination: {
          offset: pageIndex * pageSize,
          size: pageSize
        },
        sortBy: {
          ...sortBy,
          field: fieldMap[sortBy.field] || sortBy.field
        },
        upcomingEvents
      })
      .then(({ data }) => {
        const { rows, pagination } = data;
        const pageCount = Math.ceil(pagination.totalRows / pageSize);

        eventsTable.data = rows;
        eventsTable.pagination.totalRows = pagination.totalRows;
        eventsTable.pagination.pageCount = pageCount;
        eventsTable.status = Status.DONE;
      })
      .catch(() => {
        eventsTable.status = Status.ERROR;
      });
  }

  @action.bound
  deleteEvents(data: { eventIds: Array<number> }) {
    this.page.eventsTable.status = Status.LOADING;

    api.deleteEventManagement(data).then(() => {
      this.page.eventsTable.selectedRows.clear();
      this.getEventsData();
    });
  }

  @action.bound
  submitEvent(params: Object, id?: number) {
    this.page.eventsTable.status = Status.LOADING;
    const newParams = { ...params };

    if (newParams?.locations?.airports) {
      newParams.locations.airports = filterItemsWithConflicts(
        newParams.locations.airports,
        this.conflictedLocationsAirport
      );
    }

    if (newParams?.locations?.metros) {
      newParams.locations.metros = filterItemsWithConflicts(newParams.locations.metros, this.conflictedLocationsMetros);
    }

    const endpoint = id ? api.editEventManagement(id, newParams) : api.addEventManagement(newParams);

    return endpoint
      .then(() => {
        this.page.eventsTable.selectedRows.clear();
        this.page.selectedEvent = {};
        this.page.clonedModalEvent = {};
        this.clearExistingEvents();
        return this.getEventsData();
      })
      .catch(error => {
        if (error.response.status === 409) {
          this.page.clonedModalEvent = params;
          this.page.eventsTable.status = Status.DONE;
          return Promise.reject("Error name and dateRange conflicts");
        }

        this.page.eventsTable.status = Status.ERROR;
        return error;
      });
  }

  @action
  getEventMappings() {
    this.eventsMappings.status = Status.LOADING;
    api.getEventsMappings().then(response => {
      this.eventsMappings.data = response.data;
      this.eventsMappings.status = Status.DONE;
    });
  }

  @action.bound
  getEventById(id: number) {
    this.page.modalSuggestStatus = Status.LOADING;

    api.getEventById(id).then(({ data }) => {
      const { id, dateRange, updatedBy, updatedOn, ...restData } = data;

      this.setEventModalWithSuggestedEvent(restData);
      this.page.modalSuggestStatus = Status.DONE;
    });
  }

  @action.bound
  clearExistingEvents() {
    this.page.suggestedEvents.data.clear();
  }

  @action.bound
  getExistingEvents(params: { name: string } | { dateRange: { start: string, end: string } }, type: string) {
    this.activeSuggestionType = type;
    this.page.suggestedEvents.status = Status.LOADING;
    api
      .getExistingEvents(params)
      .then(({ data }) => {
        if (isEmpty(data.future?.rows) && isEmpty(data.past?.rows)) {
          this.page.suggestedEvents.data = [];
        }
        this.page.suggestedEvents.status = Status.LOADING;

        const pastEvents = (data.past && reorganizeEventsAutosuggestion(data.past.rows, "past")) || [];
        const futureEvents = (data.future && reorganizeEventsAutosuggestion(data.future.rows, "future")) || [];
        this.page.suggestedEvents.data = [...pastEvents, ...futureEvents].sort(
          (a, b) => new Date(b.dateRange.start) - new Date(a.dateRange.start)
        );
        this.page.suggestedEvents.extraEvents = sumExtraMatches(data);
        this.page.suggestedEvents.status = Status.DONE;
      })
      .catch(() => {
        AppToaster.show({
          intent: Intent.DANGER,
          message: "Something went wrong and event data cannot be pre-populated"
        });
        this.page.suggestedEvents.status = Status.ERROR;
      });
  }

  @action.bound
  shiftToggleRows(selectedRow: string, clickedRow: string) {
    const { data, selectedRows } = this.page.eventsTable;
    const isToggleOn = selectedRows.includes(clickedRow);
    const tableIdRows = data.map(row => row.id);
    const sliceIndexes = [tableIdRows.indexOf(selectedRow), tableIdRows.indexOf(clickedRow)].sort((a, b) => a - b);
    const actionRows = tableIdRows.slice(sliceIndexes[0], sliceIndexes[1] + 1);

    this.page.eventsTable.selectedRows = !isToggleOn
      ? union(selectedRows, actionRows)
      : difference(selectedRows, actionRows);
  }

  @action.bound
  setEventModalWithSuggestedEvent(newModalProps) {
    const newEvent = {
      ...omit(toJS(this.page.modalEvent), ["provenanceType", "isLocationGlobal", "isProvenanceGlobal"]),
      ...toJS(newModalProps)
    };
    this.page.modalEvent = prepareEventObject(newEvent);
  }

  @action.bound
  submitSearchForm() {
    const { page } = this;

    page.applied.filters = cloneDeep(toJS(page.filters));
    this.syncDisabledFilters();
    this.getEventsData();

    this.page.eventsTable.selectedRows.clear();
  }

  @action.bound
  syncDisabledFilters() {
    syncDisabledFilters(this.page.disabledFilters, this.page.applied.filters, FILTERS_INIT);
  }

  @action.bound
  clearSearchParam(name: string) {
    const { page } = this;

    if (name in page.filters) {
      page.filters[name] = FILTERS_INIT[name];
    }

    delete page.disabledFilters[name];

    this.submitSearchForm();
  }

  @action
  updateSelectedRows(selectedRows: Array<string>) {
    this.page.eventsTable.selectedRows.replace(selectedRows);
    this.page.selectedEvent =
      selectedRows.length === 1 ? this.page.eventsTable.data.find(event => event.id === selectedRows[0]) : {};
  }

  @action.bound
  changeFilter(filterKey: string, filterValue: FilterValue) {
    const { filters } = this.page;
    filters[filterKey] = filterValue;
  }

  @action.bound
  removeFilterValue(filterKey: string, valueToRemove: string) {
    const { disabledFilters, filters } = this.page;
    removeValueInFilter(filterKey, valueToRemove, disabledFilters, filters, FILTERS_INIT[filterKey]);
    this.submitSearchForm();
  }

  @action.bound
  setSidebarOpen(isOpen: boolean) {
    const { sidebar } = this.page;
    sidebar.isOpen = isOpen;
    if (!isOpen) {
      sidebar.filterQuery = "";
      sidebar.filterKey = null;
    }
  }

  @action.bound
  setSidebarFilterQuery(filterQuery: string, filterKey?: string) {
    const { sidebar } = this.page;
    sidebar.filterQuery = filterQuery;
    sidebar.filterKey = filterKey;
  }

  @action.bound
  changeField(fieldKey: string, fieldValue: string | number | Array<number> | Array<string>, parentKey: string) {
    const { modalEvent } = this.page;
    if (parentKey) {
      if (!modalEvent[parentKey]) {
        modalEvent[parentKey] = { [fieldKey]: fieldValue };
      } else {
        modalEvent[parentKey][fieldKey] = fieldValue;
      }
    } else {
      modalEvent[fieldKey] = fieldValue;
    }
  }

  @computed
  get countries(): Array<{ label: string, value: string }> {
    return this.eventsMappings.data.countries
      ?.map(({ name: label, code: value }) => ({ label, value }))
      .sort(sortLabel);
  }

  @computed
  get metros(): Object[] {
    return this.eventsMappings.data.countries
      ?.flatMap(country =>
        country?.metros?.map(({ name: label, code: value }) => ({ country: country.code, label, value }))
      )
      .sort(sortLabel);
  }

  @computed
  get airports(): Object[] {
    return this.eventsMappings.data.countries
      ?.flatMap(country =>
        country?.metros?.flatMap(metro =>
          metro.airports.map(airport => ({
            country: country.code,
            label: airport,
            metro: metro.code,
            value: airport
          }))
        )
      )
      .sort(sortLabel);
  }

  @computed
  get decoratedPage() {
    const { changeFilter, filterCountsByGroup } = this;
    return {
      ...this.page,
      changeFilter,
      filterCountsByGroup
    };
  }

  @computed
  get eventCategories() {
    if (isEmpty(this.eventsMappings.data)) {
      return [];
    }

    return this.eventsMappings.data.categories.map(this.prepareSelectItem);
  }

  @computed
  get eventCategoriesById() {
    return keyBy(this.eventCategories, "value");
  }

  @computed
  get eventAnalysts() {
    const analysts = this.eventsMappings.data.users.map(this.prepareSelectItem);
    const currentUserId = this.rootStore?.appStore?.userId;

    return changeUserSortOrder(analysts, currentUserId);
  }

  @computed
  get isEventEqual() {
    return isEqual(prepareEventObject(toJS(this.page.selectedEvent)), prepareEventObject(toJS(this.page.modalEvent)));
  }

  @computed
  get metrosGroupedByCode() {
    return keyBy(this.metros, "value");
  }

  @computed
  get airportGroupedByCode() {
    return keyBy(this.airports, "value");
  }

  @computed
  get obsoletePlaces(): Object {
    const { locations = {}, provenances = {} } = this.page.modalEvent;
    const createSetByType = (type: string) => {
      const locationsByType = locations ? locations[type] || [] : [];
      const provenancesByType = provenances ? provenances[type] || [] : [];
      return new Set([...locationsByType, ...provenancesByType].map(item => item.code || item));
    };

    const addedAirports = Array.from(createSetByType("airports"));
    const addedCountries = Array.from(createSetByType("countries"));
    const addedMetros = Array.from(createSetByType("metros"));

    const availableAirports = this.airports?.map(item => item.value) || [];
    const availableCountries = this.countries?.map(item => item.value) || [];
    const availableMetros = this.metros?.map(item => item.value) || [];

    return {
      airports: addedAirports.filter(item => !availableAirports.includes(item)),
      countries: addedCountries.filter(item => !availableCountries.includes(item)),
      metros: addedMetros.filter(item => !availableMetros.includes(item))
    };
  }

  @computed
  get conflictedLocationsMetros(): number[] {
    const { locations } = this.page.modalEvent;

    if (isEmpty(locations?.metros) || isEmpty(locations?.countries)) {
      return [];
    }

    const conflicts = [];
    const countries = getPlaceCodes(locations.countries);
    locations.metros.forEach((metro, index) => {
      const metroCode = typeof metro === "string" ? metro : metro.code;
      if (countries.includes(this.metrosGroupedByCode[metroCode]?.country)) {
        conflicts.push(index);
      }
    });
    return conflicts;
  }

  @computed
  get conflictedLocationsAirport(): number[] {
    const { locations } = this.page.modalEvent;

    if (
      isEmpty(locations?.airports) ||
      (!isEmpty(locations?.airports) && isEmpty(locations?.metros) && isEmpty(locations?.countries))
    ) {
      return [];
    }

    const conflicts = [];
    const metros = getPlaceCodes(locations.metros);
    const countries = getPlaceCodes(locations.countries);
    locations.airports.forEach((airport, index) => {
      const airportCode = typeof airport === "string" ? airport : airport.code;
      if (
        (!isEmpty(locations?.countries) && countries.includes(this.airportGroupedByCode[airportCode]?.country)) ||
        (!isEmpty(locations?.metros) && metros.includes(this.airportGroupedByCode[airportCode]?.metro))
      ) {
        conflicts.push(index);
      }
    });
    return conflicts;
  }

  @computed
  get isConflicted(): boolean {
    return !isEmpty(this.conflictedLocationsMetros) || !isEmpty(this.conflictedLocationsAirport);
  }

  @computed
  get isSuggestModalLoading(): boolean {
    return this.page.modalSuggestStatus === Status.LOADING;
  }

  @computed
  get isDataLoading(): boolean {
    return this.page.eventsTable.status === Status.LOADING;
  }

  @computed
  get normalizedFilters() {
    return getEnabledFilters(this.page.applied.filters, this.page.disabledFilters, FILTERS_INIT);
  }

  @computed
  get filterCountsByGroup() {
    const { filters, applied, disabledFilters } = this.page;
    return filterCountsByGroup(eventsManagementMetrics, filters, applied.filters, disabledFilters, FILTERS_INIT);
  }
}
