import { useStores } from "store/Store";
import React, { useState } from "react";
import { Icon, Spinner } from "@blueprintjs/core";
import isEmpty from "lodash.isempty";

import { Row } from "types/react-table.types";
import { observer } from "mobx-react";

type Props = {
  row: Row;
};

function InfluenceHistoryRowExpander(props: Props) {
  const { row } = props;
  const { influenceHistoryStore } = useStores();
  const [isLoading, setIsLoading] = useState(false);

  const expanderProps = row.getToggleRowExpandedProps();

  const handleExpand = () => {
    if (!row.isExpanded && isEmpty(row.subRows)) {
      setIsLoading(true);
      influenceHistoryStore.getSingleInfluenceHistory(row.id).finally(() => {
        setIsLoading(false);
      });
    }

    expanderProps.onClick();
  };

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <Spinner data-testid="row-expander-spinner" size={12} />
      </div>
    );
  }

  return row.depth === 0 && row.original.historyExists ? (
    <div className="d-flex justify-content-center align-items-center h-100" {...expanderProps} onClick={handleExpand}>
      <Icon data-testid="expander-icon" icon={`chevron-${row.isExpanded ? "down" : "right"}`} />
    </div>
  ) : null;
}

export default observer(InfluenceHistoryRowExpander);
