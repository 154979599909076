import { NonIdealState, Spinner } from "@blueprintjs/core";
import styled from "@emotion/styled";
import * as Sentry from "@sentry/react";
import clsx from "clsx";
import LogRocket from "logrocket";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import ReactHeap from "reactjs-heap";

import ApplicationErrorInformation from "./ApplicationErrorInformation/ApplicationErrorInformation";
import AuthFooter from "./AuthFooter/AuthFooter";
import ErrorBoundary from "shared/components/ErrorBoundary/ErrorBoundary";
import TemplatesSidebar from "modules/Templates/TemplatesSidebar/TemplatesSidebar";
import { useConfig } from "config/ApplicationConfigContext";

import { env, HEAP_ID, partner } from "config/config";
import baseRoutes from "config/routes/base";
import { isCypress, isLocalhost } from "./constants";
import isEnv from "shared/helpers/isEnv/isEnv";
import { Status } from "./Status";
import { useApplicationInitializer } from "shared/helpers/useApplicationInitializer/useApplicationInitializer";
import { useStores } from "store/Store";
import { RUV } from "./App.types";
import Header from "containers/Header";
import Modal from "containers/Modal";

export const StyledApp = styled("div")`
  height: 100%;
`;

function Application() {
  const rootStore = useStores();
  const { appStore, modalStore, themeStore } = rootStore;
  const { boot: bootIntercom } = useIntercom();
  const { environmentRoutes } = useConfig();

  const dataStatus = useApplicationInitializer();

  useEffect(() => {
    const ruv: RUV = appStore.ruv;

    if (ruv) {
      const shouldShowRUVModal = ruv.ruvEnabled && appStore.auth.isLoginViaPopup;
      const isRUVDataAvailable = "revenueUpliftTotal" in ruv;

      if (shouldShowRUVModal && isRUVDataAvailable && modalStore.modalActive !== "revenueUplift") {
        modalStore.setModal("revenueUplift");
      }
    }
  }, [appStore.ruv?.ruvEnabled, appStore.auth.isLoginViaPopup]);

  const configureVendors = () => {
    if (!isLocalhost) {
      const { group, email, name } = appStore.auth.user;
      if (isEnv(["staging", "integration", "production"])) {
        // sentry
        Sentry.configureScope(scope => {
          scope.setTag("group", group);
          scope.setUser({ email, name });
        });
        if (!isCypress) {
          // logrocket
          LogRocket.identify(email, { email, env, group, name, partner });
        }
      }
      if (isEnv(["integration", "production"])) {
        // heap
        ReactHeap.initialize(HEAP_ID[env]);
        if (window.heap) {
          window.heap.identify(email);
          window.heap.addUserProperties({ email, env, group, name, partner });
        }
      }
      if (!isCypress) {
        // intercom
        bootIntercom({ email, name });
      }
    }
  };

  const loadingState = (
    <StyledApp className={clsx("d-flex", themeStore.themeClass)}>
      <NonIdealState
        description={<span className="bp3-text-muted bp3-text-small">Loading user preferences</span>}
        icon={<Spinner />}
      />
      <AuthFooter />
    </StyledApp>
  );

  const errorState = (
    <StyledApp className={clsx("d-flex", themeStore.themeClass)}>
      <div className="d-flex flex-column align-items-center justify-content-center flex-grow-1">
        <ApplicationErrorInformation />
      </div>
    </StyledApp>
  );

  const app = (
    <StyledApp className={`d-flex flex-column ${themeStore.themeClass}`}>
      <Router>
        <Header />
        <TemplatesSidebar />
        <Switch>
          {baseRoutes.concat(environmentRoutes).map((route, index) => {
            if ("render" in route) {
              return <Route key={index} path={route.path} render={() => route.render(rootStore)} />;
            }
            return <Route key={index} component={route.component} path={route.path} />;
          })}
        </Switch>
      </Router>
    </StyledApp>
  );

  const renderApp = () => {
    if (dataStatus === Status.LOADING) {
      return loadingState;
    }
    if (dataStatus === Status.ERROR) {
      return errorState;
    }
    configureVendors();
    return app;
  };

  return (
    <ErrorBoundary fallback={errorState}>
      <Modal />
      {renderApp()}
    </ErrorBoundary>
  );
}

export default observer(Application);
