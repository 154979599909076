import { Intent } from "@blueprintjs/core";
import cloneDeep from "lodash.clonedeep";
import isEmpty from "lodash.isempty";
import { toJS, when } from "mobx";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { InfluencePreviewParams, InfluenceType, PriceLimitsInfluenceParams } from "types/Influence.types";
import getRowObjectIds from "shared/helpers/getRowObjectIds/getRowObjectIds";
import isNumber from "shared/helpers/isNumber/isNumber";
import { NavigationBlocker } from "shared/helpers/navigationBlocker/navigationBlocker";
import { Status } from "modules/App/Status";
import { InfluenceStatus, influenceSteps } from "modules/Influence/Influence.model";

import { Tab } from "models/Tab/Tab.model";
import { AppToaster } from "services/Toaster";
import { useStores } from "store/Store";
import Module from "shared/components/Module/Module";
import NumberOfFlights from "shared/components/NumberOfFlights/NumberOfFlights";

import InfluenceControlNavigation from "modules/Influence/InfluenceControlNavigation/InfluenceControlNavigation";
import InfluenceControlSave from "modules/Influence/InfluenceControlSave/InfluenceControlSave";
import InfluenceControlSelection from "modules/Influence/InfluenceControlSelection/InfluenceControlSelection";
import InfluenceControlSet from "modules/Influence/InfluenceControlSet/InfluenceControlSet";
import InfluenceControlSummary from "modules/Influence/InfluenceControlSummary/InfluenceControlSummary";

const MAXIMUM_INFLUENCE_FLIGHTS_COUNT = 10000;

type Props = {
  tab: Tab;
  moduleProps?: object;
};

function InfluenceModule(props: Props) {
  const { tab, moduleProps } = props;
  const { flightsTable = {}, xDayBuild } = tab;
  const { aggregations = [], selectedRows = [] } = flightsTable;

  const history = useHistory();
  const { influenceId } = useParams<{ influenceId: string }>();
  const { influenceStore, influenceHistoryStore } = useStores();
  const { parameters, previewInfluence, selectedInfluence, state, updateParameters } = influenceStore;
  const { influenceStep } = state;

  const { SELECTION, SET, SAVE, SUMMARY } = influenceSteps;
  const isFlightsCountExceeded = tab.selectedRowsNumberOfFlights > MAXIMUM_INFLUENCE_FLIGHTS_COUNT;

  const influenceParams = {
    ...tab.getNormalizedFilters,
    rowIds: getRowObjectIds(aggregations, selectedRows),
    xDayBuild
  };

  const finishInfluence = () => {
    influenceStore.resetSteps();
    tab.finishInfluence();
  };

  useEffect(() => {
    if (isFlightsCountExceeded) {
      AppToaster.show({
        intent: Intent.WARNING,
        message: `Number of flights selected exceeds ${MAXIMUM_INFLUENCE_FLIGHTS_COUNT}. Influence can’t be created.`
      });
    }
  }, [isFlightsCountExceeded]);

  const validateFlightsTable = () => {
    if (influenceStore.metricsLastUpdated !== tab.flightsTable.lastUpdated) {
      tab.refetchFlightsTableData();
    }
  };

  const getInfluencePreview = async (
    type: InfluenceType,
    inputValue: number | null | PriceLimitsInfluenceParams[],
    params: { isBaseValue?: boolean }
  ) => {
    if (inputValue !== null) {
      updateParameters({ value: cloneDeep(toJS(inputValue)) });
    }

    const pagination = { offset: 0, size: flightsTable.pagination.totalRows };
    const { sortBy } = flightsTable;
    const { isBaseValue } = params || {};
    const previewParams: InfluencePreviewParams = { ...params };

    if (tab.isEditingInfluence) {
      previewParams.influenceId = influenceId.split("-")[1];
    }

    // preview price limits
    if (type === "MM") {
      await previewInfluence(
        "MM",
        {
          ...(!tab.isEditingInfluence && influenceParams),
          adjustments: isBaseValue
            ? undefined
            : (inputValue as PriceLimitsInfluenceParams[]).map(element => ({
                max: isNumber(element.max) ? element.max : null,
                min: isNumber(element.min) ? element.min : null,
                ndo: { end: element.endNdo, start: element.startNdo }
              })),
          aggregations,
          pagination,
          sortBy,
          xDayBuild
        },
        previewParams
      );
      validateFlightsTable();
      return;
    }

    // preview other types
    await previewInfluence(
      type,
      {
        ...(!tab.isEditingInfluence && influenceParams),
        aggregations,
        pagination,
        sortBy,
        value: isBaseValue ? undefined : inputValue,
        xDayBuild
      },
      previewParams
    );

    validateFlightsTable();
  };

  useEffect(() => {
    if (tab.isEditingInfluence && tab.flightsTable.pagination.totalRows > 0) {
      getInfluencePreview(selectedInfluence.type, null, { isBaseValue: true });
    }
  }, [tab.isEditingInfluence, tab.flightsTable.pagination.totalRows]);

  const saveInfluence = () => {
    const { comment, value } = parameters;

    // save price limits
    if (state.influenceType === "MM") {
      influenceStore.saveInfluence("MM", {
        ...influenceParams,
        adjustments: value.map(element => ({
          max: isNumber(element.max) ? element.max : null,
          min: isNumber(element.min) ? element.min : null,
          ndo: { end: element.endNdo, start: element.startNdo }
        })),
        comment
      });
      return;
    }

    // save other types
    influenceStore.saveInfluence(state.influenceType, {
      ...influenceParams,
      comment,
      value
    });

    when(() => influenceStore.status === InfluenceStatus.DONE).then(() => {
      influenceHistoryStore.changeStatus(Status.OUTDATED);
    });
  };

  const updateInfluence = () => {
    const { comment, value } = parameters;

    const onSuccess = () => {
      NavigationBlocker.unblock();
      history.push("/influence-history");

      AppToaster.show({ intent: Intent.SUCCESS, message: `Influence ${influenceId} has been updated.` });
    };

    if (state.influenceType === "MM") {
      const payload = {
        adjustments: value.map(element => ({
          max: isNumber(element.max) ? element.max : null,
          min: isNumber(element.min) ? element.min : null,
          ndo: { end: element.endNdo, start: element.startNdo }
        })),
        comment
      };

      return influenceStore.updateInfluence(influenceId, payload).then(onSuccess);
    }

    return influenceStore.updateInfluence(influenceId, { comment, value }).then(onSuccess);
  };

  const renderContent = () => {
    switch (influenceStep) {
      case SELECTION:
      default: {
        const isDone = flightsTable.status === Status.DONE;
        const isFlightTableReady = isDone && !isEmpty(flightsTable.data);

        return (
          <InfluenceControlSelection
            disabled={isFlightsCountExceeded || !isFlightTableReady}
            getInfluencePreview={getInfluencePreview}
            noRowsSelected={isEmpty(selectedRows)}
          />
        );
      }
      case SET:
      case SAVE: {
        const InfluenceControlComponent = influenceStep === SET ? InfluenceControlSet : InfluenceControlSave;

        return <InfluenceControlComponent />;
      }
      case SUMMARY: {
        return <InfluenceControlSummary finishInfluence={finishInfluence} />;
      }
    }
  };

  const subtitle = [
    <NumberOfFlights
      flightsCount={tab.flightsCount}
      flightsTable={tab.flightsTable}
      options={{ showSelected: true }}
      selectedRowsNumberOfFlights={tab.selectedRowsNumberOfFlights}
    />
  ];

  const footer = (
    <InfluenceControlNavigation
      getInfluencePreview={getInfluencePreview}
      saveInfluence={saveInfluence}
      tab={tab}
      updateInfluence={updateInfluence}
    />
  );

  return (
    <Module
      childrenClassName="flex-grow-1 overflow-auto"
      className="h-100"
      footer={footer}
      subtitle={subtitle}
      title="Influence Controls"
      {...moduleProps}
    >
      {renderContent()}
    </Module>
  );
}

export default observer(InfluenceModule);
