import { Intent } from "@blueprintjs/core";
import { observer } from "mobx-react";
import React from "react";
import kebabCase from "lodash.kebabcase";

import { useStores } from "../../../store/Store";
import { InfluenceStatus } from "../Influence.model";
import { StyledButtonIcon, StyledInfluenceInput } from "../InfluenceStyles";
import { NdoInputValueWithValidation } from "../PriceLimits/PriceLimits.types";

type Props = {
  inputValueWithValidation: NdoInputValueWithValidation;
  fieldName: "startNdo" | "endNdo";
  index: number;
};

function NdoControl({ fieldName, inputValueWithValidation, index }: Props) {
  const { influenceStore } = useStores();
  const { status, updateMinMaxValue } = influenceStore;
  const isLoading = status.includes(InfluenceStatus.LOADING);
  const { endIsHigher, endNdoOverlaps, startNdoOverlaps, startNdoIsTooHigh } = inputValueWithValidation;
  const isOverlapping = endNdoOverlaps || startNdoOverlaps;
  const isStartTooHigh = !isOverlapping && startNdoIsTooHigh;
  const onValueChange = value => updateMinMaxValue(index, fieldName, value);

  const intentNdoInput = field =>
    endIsHigher ||
    (field === "startNdo" && isStartTooHigh) ||
    (field === "startNdo" && startNdoOverlaps) ||
    (field === "endNdo" && endNdoOverlaps)
      ? Intent.DANGER
      : Intent.NONE;

  return (
    <>
      <StyledButtonIcon disabled={isLoading}>NDO</StyledButtonIcon>
      <StyledInfluenceInput
        allowNumericCharactersOnly
        autoFocus
        clampValueOnBlur
        className="flex-grow-1"
        data-testid={`influence-price-limits-${kebabCase(fieldName)}`}
        disabled={isLoading}
        fill
        intent={intentNdoInput(fieldName)}
        min={0}
        onValueChange={onValueChange}
        value={inputValueWithValidation[fieldName]}
      />
    </>
  );
}

export default observer(NdoControl);
