import { extraMetrics } from "../App/constants";
import metricLabels from "shared/metricLabels/metricLabels";

const influenceHistory = {
  adjustmentIds: "Infl ID",
  analystName: "Analyst",
  createdOn: "Infl Created On",
  status: "Infl Status",
  type: "Infl Type",
  updatedOn: "Infl Modified On",
  userIds: "Analyst"
};

export const filterNamesOverrides = {
  absolutePriceAdjustment: "Infl Price Adj. $",
  adjustmentId: "Influence Id",
  aircraftCapacity: "Cap",
  netBookings: "Bkgs Net Act",
  netBookingsBaseline: "Bkgs Net Base",
  netBookingsDiffToBaseline: "Bkgs Net Δ to Base",
  priceAdjustment: "Infl Price Adj. %",
  sellableCapacity: "Cap Sell",
  xDayAircraftCapacity: "XD Cap",
  xDayAircraftCapacityBuild: "Cap XD Bld",
  xDayNetBookings: "Bkgs XD Net Act",
  xDayNetBookingsBaseline: "Bkgs XD Net Base",
  xDayNetBookingsBuild: "Bkgs XD Bld Net Act",
  xDayNetBookingsBuildBaseline: "Bkgs XD Bld Net Base",
  xDayNetBookingsBuildDiffToBaseline: "Bkgs XD Bld Δ to Base",
  xDayNetBookingsDiffToBaseline: "Bkgs XD Net Δ to Base",
  xDaySellableCapacity: "XD Cap Sell",
  xDaySellableCapacityBuild: "Cap Sell XD Bld"
};

export default function FilterNamesMap(isMiles: boolean, additionalOptions = {}, xDayBuild?: number): Object {
  const columnLabels = {
    ...extraMetrics,
    ...metricLabels({ isMiles, shortVersionByGroup: true, xDayBuild })
  };

  return {
    ...columnLabels,
    departureDayOfWeek: "Dep DOW",
    depDate: "Dep Date",
    depDow: "Dep DOW",
    depMonth: "Dep Month",
    depTime: "Dep Time",
    depTimeBucket: "Dep Time Bucket",
    depWeek: "Dep Week",
    destination: "Dest",
    distance: "Dist",
    flightNumber: "Flt Num",
    ndo: "NDO",
    numberOfFlights: "Flt Count",
    ...influenceHistory,
    ...additionalOptions
  };
}
