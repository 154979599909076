import React, { useEffect, useMemo } from "react";
import { observer } from "mobx-react";
import isEmpty from "lodash.isempty";
import { Spinner } from "@blueprintjs/core";
import styled from "@emotion/styled";

import PivotTableTools from "./PivotTableTools";
import { daysOfWeek, extraMetrics, metricsTypes } from "modules/App/constants";
import { FILTERS_INIT } from "models/Tab/Tab.utils";
import { Tab } from "models/Tab/Tab.model";
import { Status } from "modules/App/Status";
import PivotTableCell from "./PivotTableCell";
import { useStores } from "store/Store";
import { TabPreferences } from "types/Tab.types";
import { ReactComponent as PivotLegend } from "../../assets/pivot-legend.svg";

import { SortingBy } from "shared/components/DataTable/DataTable.types";
import DataTable from "shared/components/DataTable/DataTable";
import Module from "shared/components/Module/Module";
import ColorTooltip from "shared/components/ColorTooltip/ColorTooltip";
import { createResizeContextActions } from "shared/components/Resizable/Resizable.utils";
import metricLabels from "shared/metricLabels/metricLabels";
import { ThBoldTextWidth } from "shared/helpers/columnTextWidths/columnTextWidths";
import getEnabledFilters from "shared/helpers/getEnabledFilters/getEnabledFilters";

const StyledSvg = styled(PivotLegend)`
  width: 100%;
`;

const StyledDataTableContainer = styled("div")`
  tr:hover td * {
    background-color: #fff4db !important;
  }
`;

type Props = {
  param: any;
  tab: Tab;
};

function PivotTable(props: Props) {
  const { tab } = props;
  const { getNormalizedFilters, pivotTable, xDayBuild } = tab;
  const {
    boundaryValue,
    changeAggregation,
    changeMetric,
    data,
    dataCount,
    fetchDetailedPivotTableData,
    fetchPivotTableData,
    fetchMorePivotTableData,
    footerData,
    leftAggregation,
    rightAggregation,
    selectedMetric,
    sortBy,
    sortedChange,
    status
  } = pivotTable;
  const { analysisMappingsStore, regionsStore, tabsStore, systemSettingsStore } = useStores();
  const { analystData } = analysisMappingsStore;
  const { regions, subregions } = regionsStore;
  const modulePreferences: TabPreferences["modules"] = tab.preferences?.modules || {};
  const { isMiles } = systemSettingsStore;

  const summaryLabel = metricLabels({ isMiles, withUnits: true, xDayBuild })[selectedMetric];

  useEffect(() => {
    const { conditionalFilters, filters } = getNormalizedFilters;

    if (
      sortBy.field === selectedMetric ||
      sortBy.field === leftAggregation ||
      sortBy.field === "xDayRevenuePotential"
    ) {
      fetchPivotTableData({
        conditionalFilters,
        filters,
        xDayBuild
      });
    } else {
      fetchDetailedPivotTableData({
        conditionalFilters,
        filters,
        xDayBuild
      });
    }
    tabsStore.saveTabs();
  }, [leftAggregation, rightAggregation, selectedMetric, xDayBuild, tab.filters, tab.conditionalFilters]);

  const customValue = (columnName: string, aggr: string) => {
    const isNotEmptyColumn = columnName !== "null";
    const emptyColumnName = "Unassigned";
    switch (aggr) {
      case "regionId":
        return isNotEmptyColumn
          ? regions.find(region => Number(columnName) === region.regionId)?.regionName
          : emptyColumnName;

      case "subregionId":
        return isNotEmptyColumn
          ? subregions.find(subregion => Number(columnName) === subregion.subregionId)?.subregionName
          : emptyColumnName;

      case "depDow":
        return daysOfWeek.find(day => day?.numeric === Number(columnName))?.short;

      case "analystId":
        return isNotEmptyColumn
          ? analystData.rows.find(analyst => analyst.analystId === Number(columnName))?.analystName
          : emptyColumnName;
      default:
        return columnName;
    }
  };

  const generateColumns = () => {
    if (isEmpty(data)) {
      return [];
    }
    const standardColumns = [...new Set(data.map(item => Object.keys(item)).flat())].filter(
      el => el !== selectedMetric && el !== leftAggregation
    );
    if (rightAggregation === "depTimeBucket") {
      const timeBucketOrder = ["Morning", "Afternoon", "Evening", "Late night"];
      standardColumns.sort(
        (columnA: string, columnB: string) => timeBucketOrder.indexOf(columnA) - timeBucketOrder.indexOf(columnB)
      );
    } else {
      standardColumns.sort();
    }
    return [leftAggregation, ...standardColumns, selectedMetric];
  };

  const columns = generateColumns();

  const generateDynamicHeaderWidths = useMemo(
    () =>
      columns.reduce((acc, item) => {
        const text = item === selectedMetric ? summaryLabel : item;
        return {
          ...acc,
          [item]: ThBoldTextWidth(text) + 8
        };
      }, {}),
    [columns]
  );

  const transformedData = data => {
    switch (selectedMetric) {
      case "lastModifiedInfluenceAnalystId":
        return data.map(el => {
          return Object.keys(el).reduce((acc, item: string) => {
            return {
              ...acc,
              [item]:
                item === leftAggregation
                  ? el[item]
                  : analystData.rows.find(analyst => analyst.analystId === Number(el[item]))?.analystName
            };
          }, {});
        });
      default:
        return data;
    }
  };

  const generateCellRenderers = () =>
    columns.reduce((acc: object, item: string) => {
      const cellType = metricsTypes[selectedMetric];
      return {
        ...acc,
        [item]: ({ column, value }) => {
          if (column.id === selectedMetric) {
            return <PivotTableCell boundaryValue={null} type={cellType} value={value} />;
          }
          if (column.id === leftAggregation) {
            return customValue(String(value), leftAggregation);
          }
          return <PivotTableCell boundaryValue={boundaryValue} type={cellType} value={value} />;
        }
      };
    }, {});

  const columnLabels = {
    // @ts-ignore
    ...columns.reduce((acc: object, item: string) => {
      return {
        ...acc,
        [item]: customValue(item, rightAggregation)
      };
    }, {}),
    ...extraMetrics,
    ...metricLabels({ isMiles, withUnits: true, xDayBuild }),
    departureDayOfWeek: "Dep DOW",
    depDate: "Dep Date",
    depDow: "Dep DOW",
    depMonth: "Dep Month",
    depTime: "Dep Time",
    depTimeBucket: "Dep Time Bucket",
    depWeek: "Dep Week",
    destination: "Dest",
    distance: "Dist",
    flightCount: "Flt Count",
    flightNumber: "Flight Num",
    influenceHistoryLink: null,
    ndo: "NDO",
    numberOfFlights: "Flt Count",
    [selectedMetric]: summaryLabel
  };

  // const sortingType = status === Status.DONE && data.length === dataCount.numberOfRows;

  const params = {
    conditionalFilters: tab.conditionalFilters,
    filters: getEnabledFilters(tab.filters, {}, FILTERS_INIT),
    xDayBuild
  };
  const tableProps = {
    ...PivotTable,
    aggregationsDisabled: false,
    cellRenderers: generateCellRenderers(),
    columnConfig: {
      columnsAligned: { [leftAggregation]: "left" }
    },
    columnLabels,
    columns,
    columnTextWidth: generateDynamicHeaderWidths,
    customDataFlow: true,
    data: transformedData(data),
    dataCount,
    fetchData: fetchPivotTableData,
    fetchMoreData: () => fetchMorePivotTableData(params),
    filters: tab.applied,
    fixedColumns: {
      [leftAggregation]: "left",
      [selectedMetric]: "right"
    },
    footerData: transformedData(footerData),
    infinityScroll: true,
    initialFilters: FILTERS_INIT,
    onSortedChange: sortConfig => sortedChange(sortConfig, params),
    showPagination: false,
    sortBy,
    sortingBy: SortingBy.Backend,
    sortingEnabled: true,
    status,
    title: "Pivot Table"
  };

  const dataCountLegend =
    dataCount.status === Status.DONE && status === Status.DONE ? (
      <span>{`${data.length} of ${data.length ? dataCount.numberOfRows : 0} rows · ${
        columns.length > 2 ? columns.length - 2 : columns.length
      } columns`}</span>
    ) : (
      <Spinner size={12} />
    );

  const contextActions = createResizeContextActions(!!modulePreferences?.pivotTable?.isCollapsed, isCollapsed =>
    tab.setModuleState("pivotTable", { isCollapsed })
  );

  const tooltipText =
    "Pivot cells are colored based on the currently available values following the color scale presented below. The “border values” for the scale present the extremums of the entire range of days.";

  return (
    <div className="d-flex flex-column flex-grow-1 mh-0 flex-grow-1 h-100">
      <Module
        childrenClassName="d-flex flex-column h-100"
        className="flex-grow-1"
        contextActions={contextActions}
        isCollapsed={modulePreferences?.pivotTable?.isCollapsed}
        minHeight={315}
        subtitle={[
          <ColorTooltip href="https://help.cirrus.ai/en/articles/5554432-pivot-table" text={tooltipText}>
            <StyledSvg />
          </ColorTooltip>,
          dataCountLegend
        ]}
        title="Pivot Table"
        titleTools={
          <PivotTableTools
            changeAggregation={changeAggregation}
            changeMetric={changeMetric}
            leftAggregation={leftAggregation}
            rightAggregation={rightAggregation}
            selectedMetric={selectedMetric}
            xDayBuild={xDayBuild}
          />
        }
      >
        <StyledDataTableContainer className="d-flex flex-column h-100 justify-content-center" data-testid="pivot-table">
          <DataTable {...tableProps} />
        </StyledDataTableContainer>
      </Module>
    </div>
  );
}

export default observer(PivotTable);
