import { Card } from "@blueprintjs/core";
import { observer } from "mobx-react";
import React from "react";

import { useStores } from "../../../store/Store";
import AdjustmentControlDescription from "shared/components/AdjustmentControlDescription/AdjustmentControlDescription";

function CapacityStepSave() {
  const { capacityStore } = useStores();

  return (
    <>
      <AdjustmentControlDescription
        adjustmentName="capacity"
        onChange={capacityStore.changeAdjustmentComment}
        value={capacityStore.adjustmentComment}
      />
      <Card className="p-3 mb-2 d-flex justify-content-between font-weight-bold">
        <div data-test-id="adjustment-summary-label">Overbooking Value (%) Override</div>
        <div data-test-id="adjustment-summary-value">{capacityStore.formattedValue}</div>
      </Card>
    </>
  );
}

export default observer(CapacityStepSave);
