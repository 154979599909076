import React from "react";
import { observer } from "mobx-react";
import { Card, Colors, TextArea } from "@blueprintjs/core";
import styled from "@emotion/styled";

type Props = {
  adjustmentName: string;
  onChange: (description: string) => void;
  value: string;
};

const CardStyled = styled(Card)`
  border: 1px solid ${Colors.LIGHT_GRAY2};
  box-shadow: none;
`;

const HeaderTitle = styled("p")`
  line-height: 16px;
  letter-spacing: 0.5px;
`;

function AdjustmentControlDescription(props: Props) {
  const { adjustmentName, onChange, value } = props;

  return (
    <CardStyled className="p-3 mb-2">
      <HeaderTitle>
        <strong>Description of changes</strong> (required)
      </HeaderTitle>
      <TextArea
        className="mb-2 bp3-fill bp3-large"
        data-testid={`${adjustmentName}-control-textarea`}
        growVertically
        onChange={e => onChange(e.target.value)}
        value={value}
      />
    </CardStyled>
  );
}

export default observer(AdjustmentControlDescription);
