import isEmpty from "lodash.isempty";
import isEqual from "lodash.isequal";
import intersection from "lodash.intersection";

function syncDisabledFilters(disabledFilters: object, filters: object, initFilters: object) {
  Object.keys(disabledFilters).forEach(filterKey => {
    const filterValue = filters[filterKey];
    if (isEmpty(filterValue) || isEqual(filterValue, initFilters[filterKey])) {
      delete disabledFilters[filterKey];
      return;
    }
    if (Array.isArray(filterValue)) {
      const newDisabledFilters = intersection(disabledFilters[filterKey], filterValue);
      if (isEmpty(newDisabledFilters)) {
        delete disabledFilters[filterKey];
      } else {
        disabledFilters[filterKey] = newDisabledFilters;
      }
    }
  });
}

export default syncDisabledFilters;
