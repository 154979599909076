import React from "react";
import { observer } from "mobx-react";
import clsx from "clsx";
import { Cell } from "react-table";
import styled from "@emotion/styled";

import isNumber from "../../../helpers/isNumber/isNumber";
import DataTableCellValue from "../DataTableCellValue/DataTableCellValue";
import { CellType } from "types/DataTable.types";

const StyledTableCell = styled("td")`
  background: inherit;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

type Props = {
  cell: Cell;
  columnConfig?: { columnsWithoutFixedNumber: string[]; columnsAligned: { [key: string]: string } };
  customRenderer?: CellType | ((cell: Cell) => React.Component);
  extraStyles: object;
};

function DataTableCell(props: Props) {
  const { cell, columnConfig, customRenderer, extraStyles } = props;
  const cellColumnId = cell?.column?.id;
  const withFixedNumber = !columnConfig?.columnsWithoutFixedNumber?.includes(cellColumnId);
  const alignment = columnConfig?.columnsAligned?.[cellColumnId];
  const value = customRenderer ? cell.value : cell.render("Cell");
  const cellProps = cell.getCellProps();
  const columnId = cellProps ? cellProps.key.toString().split("_").slice(-1).join() : "";

  const content =
    customRenderer && typeof customRenderer === "function" ? (
      customRenderer(cell)
    ) : (
      <DataTableCellValue type={customRenderer} value={value} withFixedNumber={withFixedNumber} />
    );

  const contentType = content?.props?.type;
  const isPercent = ["percent", "percent-relative"].includes(contentType);
  const isInteger = contentType === "integer";

  const isPercentValue = isNumber(cell.value) || isPercent || isInteger;
  const fixedWidthColumns = ["checkbox", "expander"];

  return (
    <StyledTableCell
      {...cellProps}
      className={clsx({
        "data-table-cell-type-link": cellColumnId.includes("Link"),
        "flex-grow-0": fixedWidthColumns.includes(columnId),
        "text-right": alignment === "right" || (alignment === undefined && isPercentValue),
        [`text-${alignment}`]: alignment
      })}
      data-testid={`data-table-cell-${columnId}`}
      style={{ ...cellProps.style, ...(extraStyles || {}) }}
    >
      {content}
    </StyledTableCell>
  );
}

export default observer(DataTableCell);
