import { Icon } from "@blueprintjs/core";
import React from "react";
import { Row } from "react-table";

export const defaultExpanderRenderer = (row: Row) =>
  row.canExpand ? (
    <div className="d-flex justify-content-center align-items-center h-100" {...row.getToggleRowExpandedProps()}>
      <Icon icon={`chevron-${row.isExpanded ? "down" : "right"}`} />
    </div>
  ) : null;
