import getActiveFilters from "../getActiveFilters/getActiveFilters";
import groupConditionalFilters from "../groupConditionalFilters/groupConditionalFilters";
import { DataTableFilters } from "../../components/DataTable/DataTable.types";

export function getActiveFiltersFromFilters(
  { conditionalFilters = [], filters }: DataTableFilters,
  initialFilters: DataTableFilters
) {
  const groupedFilters = Object.entries({
    ...filters,
    ...groupConditionalFilters(conditionalFilters)
  });

  return getActiveFilters(groupedFilters, initialFilters).map(([name]) => name);
}
