import { ButtonGroup, Classes, Colors, Icon, MenuItem } from "@blueprintjs/core";
import { Select } from "@blueprintjs/select";
import styled from "@emotion/styled";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { ReactComponent as CaretMostLeftIcon } from "../../../../assets/caret-most-left.svg";
import { ReactComponent as CaretMostRightIcon } from "../../../../assets/caret-most-right.svg";
import { isMacOs } from "../../../helpers/isMacOs/isMacOs";
import { Pagination } from "types/DataTable.types";
import PageSelector from "./DataTablePageSelector";
import { StyledPageSelectorButton } from "./StyledPageSelectorButton";

const StyledPagination = styled("div")`
  background-color: #fff;
  border: 1px solid ${Colors.LIGHT_GRAY2};
  display: flex;
  justify-content: center;
  margin: auto -1px -1px -1px;
  position: relative;
`;

const StyledPageSizeSelect = styled(Select)`
  position: absolute;
  right: 0;
  top: 0;
  min-width: 150px;

  .${Classes.POPOVER_OPEN} {
    .bp3-button {
      background-color: #d8e1e6;
    }

    .bp3-icon {
      transform: rotate(180deg);
    }
  }
`;

const StyledPageSizeButton = styled(StyledPageSelectorButton)`
  border-right: none;
`;

export const PAGE_SIZES = [25, 50, 75, 100];

type Props = {
  canNextPage: boolean;
  canPreviousPage: boolean;
  canSetPageSize: boolean;
  gotoPage: (pageSize: Pagination["pageSize"]) => void;
  isPreviewActive: boolean;
  nextPage: (event: React.MouseEvent<HTMLButtonElement>) => void;
  pagination: Pagination;
  previousPage: (event: React.MouseEvent<HTMLButtonElement>) => void;
  setPageSize: (pageSize: Pagination["pageSize"]) => void;
};

function DataTablePagination(props: Props) {
  const {
    canNextPage,
    canPreviousPage,
    canSetPageSize = true,
    gotoPage,
    isPreviewActive = false,
    nextPage,
    pagination,
    previousPage,
    setPageSize
  } = props;
  const { pageSize, pageCount } = pagination;

  useEffect(() => {
    gotoPage(pagination.pageIndex);
  }, [gotoPage, pagination]);

  // scrollbar fix for mac os
  const spacing = isMacOs() ? "" : "mt-2";

  return (
    <StyledPagination className={spacing} data-testid="table-pagination">
      <ButtonGroup>
        <StyledPageSelectorButton
          data-testid="first-page-button"
          disabled={!canPreviousPage}
          icon={
            <span className="bp3-icon">
              <CaretMostLeftIcon />
            </span>
          }
          minimal
          onClick={() => gotoPage(0)}
        />
        <StyledPageSelectorButton
          data-testid="previous-page-button"
          disabled={!canPreviousPage}
          icon="caret-left"
          minimal
          onClick={previousPage}
        />

        <PageSelector gotoPage={gotoPage} pagination={pagination} />

        <StyledPageSelectorButton
          data-testid="next-page-button"
          disabled={!canNextPage}
          icon="caret-right"
          minimal
          onClick={nextPage}
        />
        <StyledPageSelectorButton
          data-testid="last-page-button"
          disabled={!canNextPage}
          icon={
            <span className="bp3-icon">
              <CaretMostRightIcon />
            </span>
          }
          minimal
          onClick={() => gotoPage(pageCount - 1)}
        />
      </ButtonGroup>

      {canSetPageSize && (
        <StyledPageSizeSelect
          disabled={isPreviewActive}
          filterable={false}
          itemRenderer={(item: number, itemProps) => (
            <MenuItem
              key={item}
              labelElement={item === pageSize && <Icon icon="tick" />}
              onClick={itemProps.handleClick}
              text={item}
            />
          )}
          items={PAGE_SIZES}
          onItemSelect={setPageSize}
          popoverProps={{
            minimal: true,
            modifiers: {
              hide: { enabled: false },
              preventOverflow: { enabled: false }
            },
            popoverClassName: "page-size-popover"
          }}
        >
          <StyledPageSizeButton
            data-testid="page-size-button"
            disabled={isPreviewActive}
            minimal
            rightIcon="caret-down"
          >
            Rows per page: {pageSize}
          </StyledPageSizeButton>
        </StyledPageSizeSelect>
      )}
    </StyledPagination>
  );
}

export default observer(DataTablePagination);
