import React, { useMemo } from "react";
import { observer } from "mobx-react";

import TagsList from "shared/components/TagsList/TagsList";
import { FILTERS_INIT } from "models/EventsManagement/EventsManagement.utils";
import { filterOrder } from "modules/Events/eventsManagementMetrics";
import { useStores } from "store/Store";
import { extraMetrics } from "modules/App/constants";

function EventsManagementTagList() {
  const { eventsManagementStore } = useStores();
  const {
    clearSearchParam,
    eventAnalysts,
    eventCategories,
    page,
    removeFilterValue,
    setSidebarFilterQuery,
    setSidebarOpen
  } = eventsManagementStore;

  const analystLabels = useMemo(() => {
    return eventAnalysts.reduce((acc, analyst) => {
      acc[analyst.value] = analyst.label;
      return acc;
    }, {});
  }, [eventAnalysts]);

  const categoryLabels = useMemo(() => {
    return eventCategories.reduce((acc, category) => {
      acc[category.value] = category.label;
      return acc;
    }, {});
  }, [eventCategories]);

  const onFilterValueClick = (filterId: string) => {
    setSidebarOpen(true);
    setSidebarFilterQuery(extraMetrics[filterId], filterId);
  };

  return (
    <TagsList
      additionalTagLabels={{ ...analystLabels, ...categoryLabels }}
      disabled={!page.filtersEnabled}
      disabledFilters={page.disabledFilters}
      filterOrder={filterOrder}
      initialFilters={FILTERS_INIT}
      isDataLoading={eventsManagementStore.isDataLoading}
      onFilterRemove={clearSearchParam}
      onFilterToggle={eventsManagementStore.toggleFilter}
      onFilterValueClick={onFilterValueClick}
      onFilterValueToggle={eventsManagementStore.toggleFilterValue}
      onValueRemove={removeFilterValue}
      params={page.applied.filters}
    />
  );
}

export default observer(EventsManagementTagList);
