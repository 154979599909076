import { Button, InputGroup, Intent, Tooltip } from "@blueprintjs/core";
import styled from "@emotion/styled";
import { observer } from "mobx-react";
import React, { useState } from "react";

import { Pagination } from "types/DataTable.types";

const StyledGotoPageForm = styled("form")`
  width: 130px;
`;

const normalizeValue = (text: string, max: number): number | undefined => {
  const pageValue = parseInt(text, 10);
  const pageIndex = pageValue - 1;

  if (Number.isNaN(pageIndex) || pageIndex < 0 || pageIndex >= max) {
    return undefined;
  }

  return pageIndex;
};

type Props = {
  gotoPage: (pageSize: Pagination["pageSize"]) => void;
  pageCount: Pagination["pageCount"];
  setIsPopoverOpen: (isOpen: boolean) => void;
};

function PageSelectorPopoverContent({ gotoPage, pageCount, setIsPopoverOpen }: Props) {
  const [gotoValue, setGotoValue] = useState("");
  const [isValidationError, setIsValidationError] = useState(false);

  const gotoPageIndex = normalizeValue(gotoValue, pageCount);
  const isTooltipVisible = isValidationError && gotoPageIndex == null;

  const handleSubmit = event => {
    event.preventDefault();
    if (gotoPageIndex != null) {
      gotoPage(gotoPageIndex);
      setIsValidationError(false);
      setIsPopoverOpen(false);
    } else {
      setIsValidationError(true);
      setIsPopoverOpen(true);
    }
  };

  return (
    <Tooltip content={`Must be a number between 1 & ${pageCount}`} isOpen={isTooltipVisible}>
      <StyledGotoPageForm className="d-flex p-1" onSubmit={handleSubmit}>
        <InputGroup
          autoFocus
          data-testid="page-input"
          onChange={({ target }) => setGotoValue(target.value)}
          placeholder="Page..."
          value={gotoValue}
        />
        <Button className="ml-1" data-testid="page-input-confirmation" intent={Intent.PRIMARY} type="submit">
          Go
        </Button>
      </StyledGotoPageForm>
    </Tooltip>
  );
}

export default observer(PageSelectorPopoverContent);
