import React from "react";
import clsx from "clsx";
import { observer } from "mobx-react";
import {
  addYears,
  endOfYear,
  parse,
  format,
  isValid,
  toDate,
  parseISO,
  startOfToday,
  addMinutes,
  startOfDay,
  endOfDay
} from "date-fns";
import { Button, Spinner } from "@blueprintjs/core";
import { DateRangeInput } from "@blueprintjs/datetime";
import styled from "@emotion/styled";

const StyledDateRange = styled("div")`
  position: relative;
  .bp3-control-group > * {
    flex-shrink: 1;
  }
  .bp3-input {
    padding: 0 5px;
  }
`;

const StyledSpinner = styled(Spinner)`
  position: absolute;
  right: 0px;
  z-index: 2;
`;

type Props = {
  className?: string;
  computedDateFormat: string;
  dates: { end: string | null; start: string | null };
  extraProps?: object;
  isDateTime?: boolean;
  isLoading?: boolean;
  maxDate?: Date;
  minDate?: Date;
  onChange: Function;
  popoverProps?: object;
  title?: string;
  withResetDates?: boolean;
};

function SidebarDateSelect(props: Props) {
  const {
    className,
    computedDateFormat,
    dates = { end: null, start: null },
    extraProps = {},
    isDateTime = false,
    isLoading = false,
    maxDate = addYears(endOfYear(new Date()), 5),
    minDate = startOfToday(),
    onChange,
    popoverProps = {},
    title = "",
    withResetDates = true
  } = props;

  const onDateTimeChange = ([start, end]: [Date, Date]) => {
    const datePattern = "yyyy-MM-dd'T'HH:mm:ss'Z'";
    const adjustStartDate = (date: Date) => addMinutes(startOfDay(date), date.getTimezoneOffset());
    const adjustEndDate = (date: Date) => addMinutes(endOfDay(date), date.getTimezoneOffset());

    onChange({
      end: end && isValid(end) && format(adjustEndDate(end), datePattern),
      start: start && isValid(start) && format(adjustStartDate(start), datePattern)
    });
  };

  const onDateChange = ([start, end]: [Date, Date]) => {
    onChange({
      end: end && isValid(end) && format(end, "yyyy-MM-dd"),
      start: start && isValid(start) && format(start, "yyyy-MM-dd")
    });
  };

  const resetDates = () => {
    onChange({ end: null, start: null });
  };

  const calendarPopoverProps = {
    ...popoverProps,
    onClose: () => {
      if (dates.start && !dates.end) {
        onChange({ end: dates.start, start: dates.start });
      }
      if (!dates.start && dates.end) {
        onChange({ end: dates.end, start: dates.end });
      }
    }
  };

  return (
    <StyledDateRange className={clsx("d-flex flex-column mb-3", className)} data-testid="date-range-input">
      {title && <h6 className="bp3-heading">{title}</h6>}
      <div className="d-flex align-items-center">
        <DateRangeInput
          allowSingleDayRange
          dayPickerProps={{ firstDayOfWeek: 1 }}
          formatDate={(date: Date) => format(date, computedDateFormat)}
          maxDate={maxDate}
          minDate={minDate}
          onChange={isDateTime ? onDateTimeChange : onDateChange}
          parseDate={(date: string) => toDate(parse(date, computedDateFormat, new Date()))}
          popoverProps={calendarPopoverProps}
          shortcuts={false}
          value={[dates.start ? parseISO(dates.start) : null, dates.end ? parseISO(dates.end) : null]}
          {...extraProps}
        />

        {isLoading && <StyledSpinner className="mr-2" data-testid="event-suggest-spinner" size={15} />}
        {withResetDates && <Button data-testid="date-range-reset-dates" icon="cross" minimal onClick={resetDates} />}
      </div>
    </StyledDateRange>
  );
}

export default observer(SidebarDateSelect);
