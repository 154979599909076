import isEmpty from "lodash.isempty";

const initGroupStatus = (tab, initTab) => {
  const { buildCurves = initTab.buildCurves, flightsTable = initTab.flightsTable } = tab;
  const { groupStatuses: initCurvesGroupStatuses } = buildCurves;
  const { groupStatuses: initTableGroupStatuses } = flightsTable;

  const curvesGroupStatuses = initCurvesGroupStatuses.map(({ label }) => ({
    isOpen: !isEmpty(
      buildCurves.series &&
        buildCurves.series.filter(([groupTitle, metrics]) => label === groupTitle && !isEmpty(metrics))
    ),
    label
  }));
  const tableGroupStatuses = initTableGroupStatuses.map(({ label }) => ({
    isOpen: !isEmpty(
      flightsTable.columns &&
        flightsTable.columns.filter(([groupTitle, metrics]) => label === groupTitle && !isEmpty(metrics))
    ),
    label
  }));
  return {
    ...tab,
    buildCurves: {
      ...tab.buildCurves,
      groupStatuses: curvesGroupStatuses
    },
    flightsTable: {
      ...tab.flightsTable,
      groupStatuses: tableGroupStatuses
    }
  };
};

export default initGroupStatus;
