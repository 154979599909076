import { Colors } from "@blueprintjs/core";
import styled from "@emotion/styled";
import clsx from "clsx";
import kebabCase from "lodash.kebabcase";
import { observer } from "mobx-react";
import React, { MutableRefObject } from "react";

import ModuleContextActions from "../ModuleContextActions/ModuleContextActions";

const StyledModule = styled("div")<{ isCollapsed?: Props["isCollapsed"]; minHeight: number }>`
  background-color: ${Colors.WHITE};
  border-radius: 4px;
  border: 1px solid ${Colors.LIGHT_GRAY2};
  min-height: ${({ isCollapsed, minHeight }) => (!isCollapsed && minHeight ? `${minHeight}px` : 0)};
`;

const StyledHeader = styled("div")<{ isCollapsed?: Props["isCollapsed"] }>`
  border-bottom: ${({ isCollapsed }) => `1px solid ${isCollapsed ? "transparent" : Colors.LIGHT_GRAY2}`};
  color: ${Colors.DARK_GRAY4};
  flex: 0 0 48px;

  & > h3 {
    margin: 0 0.5rem 0 0;
  }
`;

const StyledChildren = styled("div")`
  min-height: 0;

  &.placeholder-center {
    align-items: center;
    display: flex;
    height: 100%;
  }
`;

const StyledFooter = styled("div")`
  border-top: 1px solid ${Colors.LIGHT_GRAY2};
`;

type Props = {
  children?: React.ReactNode;
  childrenClassName?: string;
  className?: string;
  contextActions?: React.ComponentProps<typeof ModuleContextActions>["actions"];
  footer?: React.ReactNode;
  footerClassName?: string;
  forwardedRef?: MutableRefObject<object>;
  headerClassName?: string;
  isCollapsed?: boolean;
  minHeight?: number;
  subtitle?: (string | React.ReactNode)[];
  title: string | React.ReactNode;
  titleTools?: React.ReactNode;
};

function Module(props: Props) {
  const {
    children = null,
    childrenClassName = "",
    className = "",
    contextActions = null,
    footer = null,
    footerClassName = "",
    headerClassName = "",
    isCollapsed,
    minHeight = 315,
    subtitle = [],
    title = "",
    titleTools = null
  } = props;
  const collapsible = Boolean(isCollapsed);

  const subTitles =
    subtitle &&
    Array.isArray(subtitle) &&
    subtitle.filter(Boolean).map((element: React.ReactNode, index) => (
      <span key={`${title}-subtitle-${index}`} className="bp3-text-muted bp3-text-small mt-1 text-truncate">
        {element}
      </span>
    ));

  return (
    <StyledModule
      className={`d-flex flex-column ${className}`}
      data-testid={`module-${kebabCase(title)}`}
      isCollapsed={isCollapsed}
      minHeight={minHeight}
    >
      <StyledHeader
        className={clsx("pr-2 pl-3 d-flex align-items-center", headerClassName)}
        data-testid={`module-title-${kebabCase(title)}`}
        isCollapsed={isCollapsed}
      >
        <h3 className="flex-shrink-0">{title}</h3>
        {!isCollapsed && (
          <>
            {subTitles}
            <div className={clsx("ml-auto flex-shrink-0", { "mr-2": contextActions })}>{titleTools}</div>
          </>
        )}
        {contextActions && <ModuleContextActions actions={contextActions} />}
      </StyledHeader>
      <div
        className={
          collapsible && isCollapsed ? "d-none" : "d-flex flex-column justify-content-center flex-grow-1 h-100 mh-0"
        }
        data-testid={isCollapsed ? "module-collapsed" : "module-expanded"}
      >
        {children && (collapsible ? !isCollapsed : true) ? (
          <StyledChildren className={childrenClassName} data-testid="module-content">
            {children}
          </StyledChildren>
        ) : null}
        {footer ? (
          <StyledFooter className={footerClassName} data-testid="module-footer">
            {footer}
          </StyledFooter>
        ) : null}
      </div>
    </StyledModule>
  );
}

export default observer(Module);
