import styled from "@emotion/styled";
import { Colors } from "@blueprintjs/core";

type Props = {
  width?: number;
  color?: string;
};

const StyledChipCounter = styled("span")<Props>`
  background: ${Colors.LIGHT_GRAY4};
  color: ${({ color }) => color && color};
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.2;
  margin: 0 1px;
  min-width: ${({ width }) => `${width || 18}px`};
  padding: 0.1rem 0.3rem;
  text-align: center;

  &.pill {
    background: ${Colors.BLUE5}66;
    color: ${Colors.BLUE3};
    border-radius: 2px;
  }
`;

export default StyledChipCounter;
