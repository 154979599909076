import React from "react";
import { observer } from "mobx-react";

import { startOfToday, subYears } from "date-fns";
import SidebarFilter from "shared/components/Sidebar/SidebarFilter/SidebarFilter";
import isSidebarFilterVisible from "shared/helpers/isSidebarFilterVisible/isSidebarFilterVisible";
import SidebarSelect from "shared/components/Sidebar/SidebarSelect/SidebarSelect";
import SidebarDateRangeSelect from "shared/components/Sidebar/SidebarDateRangeSelect/SidebarDateRangeSelect";
import { useStores } from "store/Store";

type Props = {
  pageContext: Object
};

function EventFilters(props: Props) {
  const { pageContext } = props;
  const { changeFilter, disabledFilters, filters, sidebar } = pageContext;
  const { eventsManagementStore, systemSettingsStore } = useStores();

  return (
    <>
      <SidebarFilter
        isTemporarilyDisabled={"categoryIds" in disabledFilters}
        isVisible={isSidebarFilterVisible("event category", "categoryIds", sidebar)}
      >
        <SidebarSelect
          fuzzySearch
          items={eventsManagementStore.eventCategories}
          onChange={filterValue => changeFilter("categoryIds", filterValue)}
          selectedItems={filters.categoryIds}
          title="Category"
          withAutoSeparatedItems
        />
      </SidebarFilter>
      <SidebarFilter
        isTemporarilyDisabled={"dateRange" in disabledFilters}
        isVisible={isSidebarFilterVisible("event date range", "dateRange", sidebar)}
      >
        <SidebarDateRangeSelect
          computedDateFormat={systemSettingsStore.computedDateFormat}
          dates={filters.dateRange}
          minDate={subYears(startOfToday(), 20)}
          onChange={filterValue => changeFilter("dateRange", filterValue)}
          title="Date range"
        />
      </SidebarFilter>
      <SidebarFilter
        isTemporarilyDisabled={"userIds" in disabledFilters}
        isVisible={isSidebarFilterVisible("analyst", "userIds", sidebar)}
      >
        <SidebarSelect
          fuzzySearch
          items={eventsManagementStore.eventAnalysts}
          onChange={filterValue => changeFilter("userIds", filterValue)}
          selectedItems={filters.userIds}
          title="Analyst"
          withAutoSeparatedItems
        />
      </SidebarFilter>
    </>
  );
}

export default observer(EventFilters);
