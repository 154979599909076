import React, { useState } from "react";
import { observer } from "mobx-react";
import { Button } from "@blueprintjs/core";

import TopBar from "shared/components/TopBar/TopBar";
import SystemSettingsDistance from "components/SystemSettings/SystemSettingsDistance";
import SystemSettingsDate from "components/SystemSettings/SystemSettingsDate";
import { useStores } from "store/Store";
import Module from "shared/components/Module/Module";
import { SystemSettingsDescription } from "components/SystemSettings/SystemSettingsUI";
import SystemSettingsCirrusDefaultStatus from "components/SystemSettings/SystemSettingsCirrusStatus";

function SystemSettings() {
  const { systemSettingsStore, modalStore, tabsStore } = useStores();
  const [unitsState, setSettingsState] = useState({});

  const showChangeConfirmation = () => {
    const config = {
      action: () => {
        tabsStore.flushTabs();
        systemSettingsStore.saveAll(unitsState);
      },
      buttonIcon: "floppy-disk",
      buttonText: "Save",
      text: "You are going to change your system units. Please, confirm this action",
      title: "Confirm Unit Change"
    };
    modalStore.setModal("confirmation", config);
  };
  const updateSettingsState = newState => setSettingsState(state => ({ ...state, ...newState }));

  return (
    <div data-testid="system-settings">
      <TopBar />
      <Module
        childrenClassName="mx-3 my-4"
        className="min-h-50 w-50 m-2"
        footer={
          <Button
            className="px-3"
            disabled={!systemSettingsStore.hasInitialStateChanged(unitsState)}
            intent="primary"
            onClick={showChangeConfirmation}
          >
            Save
          </Button>
        }
        footerClassName="p-2 px-3 text-right"
        headerClassName="px-3"
        minHeight={296}
        title="System Settings"
      >
        <SystemSettingsDescription className="m-0">
          System settings will take effect for all users in your organization.
        </SystemSettingsDescription>
        <SystemSettingsDistance distanceUnit={unitsState.distanceUnit} onChange={updateSettingsState} />
        <SystemSettingsDate
          dateDelimiter={unitsState.dateDelimiter}
          dateFormat={unitsState.dateFormat}
          onChange={updateSettingsState}
        />
        <SystemSettingsCirrusDefaultStatus
          cirrusDefaultStatus={unitsState.cirrusDefaultStatus}
          onChange={updateSettingsState}
        />
      </Module>
    </div>
  );
}

export default observer(SystemSettings);
