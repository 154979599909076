import isEmpty from "lodash.isempty";
import omit from "lodash.omit";
import { toJS } from "mobx";
import { buildCurvesMetrics } from "modules/Explore/metrics/metrics";
import { Status } from "modules/App/Status";
import { FlightsTable } from "modules/FlightsTable/FlightsTable.model";
import { Tab } from "../Tab/Tab.model";
import { PivotTable } from "../PivotTable/PivotTable.model";

export const DAYS_BACK = 30;
export const DAYS_FORWARD = 30;

export const FILTERS_INIT = {
  adjustmentId: [],
  airline: [],
  analystId: [],
  cabinClass: [],
  compFareCirrusMatchAirline: [],
  compFareDateMatchAirline: [],
  compFareTimeMatchAirline: [],
  depDate: {
    end: null,
    start: null
  },
  depDow: [],
  depMonth: [],
  depTime: {
    end: "23:59",
    start: "00:00"
  },
  depTimeBucket: [],
  depWeek: [],
  destination: [],
  flightNumber: [],
  fusionrmStatus: undefined,
  lacRbd: [],
  lacRbdFrm: [],
  lastModifiedInfluenceAnalystId: [],
  lastModifiedInfluenceDate: {
    end: null,
    start: null
  },
  ndo: {
    end: 0,
    start: 360
  },
  origin: [],
  owMarket: [],
  quarter: [],
  regionId: [],
  rtMarket: [],
  subregionId: [],
  xDayLacRbd: [],
  xDayLacRbdFrm: []
};

export const synchronizedCollapses = [
  ["buildCurves", "pivotTable"],
  ["seatAllocations", "priceDistribution", "influenceControls"],
  ["exploreFlightsTable"]
];

export const initSeries = [
  ["raskGroup", ["rask", "raskBaseline"]],
  ["loadFactorGroup", ["loadFactor", "loadFactorBaseline"]],
  ["pricePercentileGroup", ["pricePercentile"]]
];

export const forecastedSeries = ["raskExpected", "revenueExpected", "loadFactorExpected"];
export const PURE_TAB = {
  analystMappingEnabled: true,
  applied: {
    conditionalFilters: [],
    filters: { ...FILTERS_INIT }
  },
  buildCurves: {
    cabinClass: [],
    data: {},
    groupStatuses: [],
    isCollapsed: false,
    isNdo: false,
    range: { end: undefined, start: undefined },
    selectedRange: { end: undefined, start: undefined },
    series: [],
    status: Status.INIT
  },
  conditionalFilters: [],
  departureDateExtremes: {
    max: undefined,
    min: undefined,
    status: Status.INIT
  },
  disabledFilters: {},
  displayGraphMenu: true,
  distanceUnit: undefined,
  duplicatedFromSavedId: null,
  editAnalysis: false,
  editInfluence: false,
  filters: { ...FILTERS_INIT },
  filtersEnabled: true,
  flightsCount: {
    numberOfRows: undefined,
    selectedRowsNumberOfFlights: {},
    status: Status.INIT,
    totalNumberOfFlights: undefined
  },
  parentId: null,
  parentType: null,
  preferences: null,
  savedViewId: null,
  sidebar: {
    filterKey: null,
    filterQuery: "",
    isOpen: false
  },
  state: {
    dirty: false
  },
  xDayBuild: 7
};

export const TAB_INIT = {
  ...PURE_TAB,
  buildCurves: {
    ...PURE_TAB.buildCurves,
    cabinClass: ["Y"],
    groupStatuses: buildCurvesMetrics.map(([label]) => ({
      isOpen: !isEmpty(initSeries.filter(([groupTitle, metrics]) => label === groupTitle && !isEmpty(metrics))),
      label
    })),
    series: initSeries
  },
  flightsTable: new FlightsTable().toJSON(),
  id: "",
  label: "Analysis 1",
  pivotTable: new PivotTable().toJSON()
};
export const TabStruct = typeof TAB_INIT;

export const filterPersistentTab = (tab: typeof Tab): typeof Tab => ({
  ...(tab.toJSON ? tab.toJSON() : tab),
  buildCurves: {
    ...tab.buildCurves,
    data: {},
    range: { end: undefined, start: undefined },
    selectedRange: { end: undefined, start: undefined },
    status: Status.INIT
  },
  conditionalFilters: tab.applied.conditionalFilters,
  departureDateExtremes: TAB_INIT.departureDateExtremes,
  disabledFilters: {},
  distanceUnit: TAB_INIT.distanceUnit,
  filters: tab.applied.filters,
  filtersEnabled: true,
  flightsCount: TAB_INIT.flightsCount,
  flightsTable: {
    ...(tab.flightsTable?.toJSON ? tab.flightsTable.toJSON() : tab.flightsTable),
    data: [],
    influenceImpactGroupColumns: undefined,
    lastUpdated: undefined,
    pagination: {
      ...TAB_INIT.flightsTable.pagination,
      pageSize: tab.flightsTable?.pagination?.pageSize
    },
    selectedRows: [],
    showOnlySelected: TAB_INIT.flightsTable.showOnlySelected,
    status: Status.INIT
  },
  pivotTable: omit(tab.pivotTable, ["isCollapsed"])
});

export const createTabForTemplate = (source: Tab) =>
  omit(toJS(source), [
    "departureDateExtremes",
    "duplicatedFromSavedId",
    "editAnalysis",
    "id",
    "label",
    "parentId",
    "parentType",
    "rootStore",
    "savedViewId",
    "sidebar",
    "tabsStore"
  ]);
