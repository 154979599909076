import { format, getTime, parseISO } from "date-fns";

import numberFormat from "../numberFormat/numberFormat";

export default function formatValueWithUnit(value: any, typeFilter: string, dateFormats?: object) {
  switch (typeFilter) {
    case "currency": {
      const numberFormatter = numberFormat(0, 0);
      return `$${numberFormatter.format(value)}`;
    }
    case "currency-precise": {
      const numberPreciseFormatter = numberFormat(3, 3);
      return `$${numberPreciseFormatter.format(value)}`;
    }
    case "date":
    case "date-month":
    case "departure-week":
    case "departure-month": {
      return value && dateFormats && format(getTime(parseISO(value)), dateFormats[typeFilter]);
    }
    case "percent": {
      const percentFormatter = numberFormat(undefined, 1, "percent");
      return percentFormatter.format(value * 0.01);
    }
    case "percent-relative": {
      const percentRelativeFormatter = numberFormat(undefined, 1, "percent");
      return percentRelativeFormatter.format(value * 0.01);
    }
    default:
      return value;
  }
}
