import React from "react";
import { observer } from "mobx-react";
import { NavLink } from "react-router-dom";
import { ButtonGroup } from "@blueprintjs/core";

type SwitchButton = {
  label: string;
  url: string;
};

type Props = {
  buttons: SwitchButton[];
};

function SwitchButtons(props: Props) {
  const { buttons } = props;

  return (
    <ButtonGroup className="mr-2">
      {buttons.map(button => {
        return (
          <NavLink
            key={button.label}
            activeClassName="bp3-active"
            className="d-flex align-items-center bp3-button"
            to={button.url}
          >
            {button.label}
          </NavLink>
        );
      })}
    </ButtonGroup>
  );
}

export default observer(SwitchButtons);
