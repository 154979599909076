import React from "react";
import { observer } from "mobx-react";
import { Button, Classes, Dialog } from "@blueprintjs/core";
import styled from "@emotion/styled";

import AddRegionsModal from "components/Regions/AddRegionsModal";
import AddUserModal from "components/Users/AddUserModal";
import DeleteEventsModal from "components/DeleteEventsModal";
import DeleteInfluenceModal from "modules/InfluenceHistory/DeleteInfluenceModal/DeleteInfluenceModal";
import EditRegionsModal from "components/Regions/EditRegionsModal";
import EditUserModal from "components/Users/EditUserModal";
import EventsManagementModal from "components/EventsManagement/EventsManagementModal";
import MarketMappingsModal from "components/MarketMappings/MarketMappingsModal";
import RevenueUpliftModal from "modules/App/RUV/RevenueUpliftModal";
import { useStores } from "store/Store";

const DialogFooter = styled("div")`
  box-shadow: 0 -1px 0 rgba(16, 22, 26, 0.15);
`;

const Modal = () => {
  const { modalStore, usersStore, eventsManagementStore } = useStores();

  switch (modalStore.modalActive) {
    case "addUser":
      return <AddUserModal addUser={usersStore.addUser} modal={modalStore} userGroups={usersStore.userGroups.data} />;
    case "confirmation":
      return (
        <Dialog
          className="p-0"
          isCloseButtonShown={false}
          isOpen={modalStore.modalActive === "confirmation"}
          onClose={modalStore.closeModal}
          title={modalStore.data.title}
        >
          <div data-testid="confirmation-modal">
            <div className={`${Classes.DIALOG_BODY} mx-0 my-1 px-3 py-2`}>
              {modalStore.data.header ? <p className="font-weight-bold">{modalStore.data.header}</p> : null}
              <span className="mt-2 bp3-text-muted bp3-text-small">{modalStore.data.text}</span>
            </div>
            <DialogFooter className={`${Classes.DIALOG_FOOTER} m-0 px-3 py-2`}>
              <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <Button
                  data-testid="confirmation-modal-cancel-btn"
                  onClick={() => {
                    modalStore.data.cancelAction ? modalStore.data.cancelAction() : modalStore.closeModal();
                  }}
                  type="button"
                >
                  Cancel
                </Button>
                <Button
                  data-testid="confirmation-modal-delete-btn"
                  intent="primary"
                  onClick={() => {
                    modalStore.data.action && modalStore.data.action();
                    modalStore.closeModal();
                  }}
                >
                  {modalStore.data.buttonText}
                </Button>
              </div>
            </DialogFooter>
          </div>
        </Dialog>
      );
    case "deleteInfluence":
      return <DeleteInfluenceModal />;
    case "editUser":
      return <EditUserModal />;
    case "market-mappings":
      return <MarketMappingsModal />;
    case "add-regions":
      return <AddRegionsModal />;
    case "edit-regions":
      return <EditRegionsModal />;
    case "addEvent":
      return <EventsManagementModal />;
    case "editEvent":
      return <EventsManagementModal event={eventsManagementStore.selectedEvent} />;
    case "deleteEvents":
      return <DeleteEventsModal />;
    case "revenueUplift":
      return <RevenueUpliftModal />;
    default:
      if (modalStore.modalActive) {
        // eslint-disable-next-line
        console.warn(`Unknown modal "${modalStore.modalActive}"`);
      }
      return null;
  }
};

export default observer(Modal);
