import React from "react";
import isEmpty from "lodash.isempty";
import { Colors, Icon, Menu, MenuDivider, MenuItem, Position } from "@blueprintjs/core";
import { observer } from "mobx-react";
import styled from "@emotion/styled";

import { extraMetrics } from "modules/App/constants";
import { groups, groupTitles, items } from "./Select.utils";
import { SelectButton, selectStyle } from "./Select.styles";
import { StyledSelect } from "../Dashboard/Dashboard.utils";

const StyledMenuItem = styled(MenuItem)`
  color: ${Colors.DARK_GRAY4} !important;

  &.bp3-active {
    background-color: ${Colors.LIGHT_GRAY4} !important;
  }
`;

type Props = {
  aggregations: string[];
  disabled?: boolean;
  onSelect: (aggregation: string) => void;
};

function AggregationSingleSelect({ aggregations = [""], disabled = false, onSelect }: Props) {
  const itemRenderer = (item: { label: string; value: string }, itemProps) => {
    const isActive = aggregations[0] === item.value;
    return (
      <StyledMenuItem
        key={`${item.label}${item.value}`}
        active={isActive}
        onClick={itemProps.handleClick}
        shouldDismissPopover={true}
        text={
          <div className="px-2">
            {aggregations[0] === item.value ? (
              <div className="d-flex align-items-center justify-content-between">
                <span className="font-weight-bold">{item.label} </span>
                <Icon color={Colors.GRAY2} icon="tick" />
              </div>
            ) : (
              <div> {item.label} </div>
            )}
          </div>
        }
      />
    );
  };

  const itemListRenderer = ({ items, query, renderItem }) => {
    const filteredItems = items.filter(metric => {
      return `${metric.group} ${metric.label} ${metric.value}`.toLowerCase().includes(query.toLowerCase());
    });
    const groupedItemList = groupName => {
      const filteredByGroup = filteredItems.filter(metric => metric.group.toLowerCase() === groupName);
      const result = filteredByGroup.map(item => renderItem(item));
      return (
        <React.Fragment key={groupName}>
          {!isEmpty(result) ? <MenuDivider key={groupName} title={groupTitles[groupName]} /> : null}
          {result}
        </React.Fragment>
      );
    };

    const groupedItems = groups.map(group => groupedItemList(group));
    return <Menu>{isEmpty(filteredItems) ? <div className="px-2"> No results found.</div> : <>{groupedItems}</>}</Menu>;
  };

  const button = (
    <SelectButton
      data-testid="aggregations-select"
      disabled={disabled}
      text={
        <>
          <span className="text-truncate bp3-button-label">{aggregations && extraMetrics[aggregations[0]]}</span>
          <Icon className="ml-3" icon="caret-down" />
        </>
      }
    />
  );

  return (
    <>
      {selectStyle}
      <StyledSelect
        filterable={true}
        inputProps={{ className: "m-2" }}
        // @ts-ignore
        itemListRenderer={itemListRenderer}
        itemRenderer={itemRenderer}
        items={items}
        onItemSelect={({ value }) => onSelect(value)}
        popoverProps={{
          interactionKind: "click",
          minimal: true,
          position: Position.BOTTOM_RIGHT
        }}
      >
        {button}
      </StyledSelect>
    </>
  );
}

export default observer(AggregationSingleSelect);
