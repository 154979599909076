import React from "react";
import { observer } from "mobx-react";
import { Colors, Tooltip } from "@blueprintjs/core";

import ChipCounter from "../ChipCounter/ChipCounter";
type FilterState = "modified" | "applied" | "disabled";

type Props = {
  type?: FilterState;
  tooltipText?: string;
  count: number;
};

const typeToColorMap = {
  applied: Colors.BLUE3,
  disabled: Colors.GRAY2,
  modified: Colors.DARK_GRAY5
};

function ActiveFilterCounter(props: Props) {
  const { type = "modified", tooltipText = "Updated filters", count } = props;

  const tooltipModifiers = { arrow: { enabled: false } };

  return count > 0 ? (
    <Tooltip content={tooltipText} hoverOpenDelay={1000} modifiers={tooltipModifiers}>
      <ChipCounter color={typeToColorMap[type]}>{count}</ChipCounter>
    </Tooltip>
  ) : null;
}

export default observer(ActiveFilterCounter);
