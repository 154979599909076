import CirrusFilters from "./CirrusFilters";

import { Metric } from "modules/Explore/metrics/metrics";

export const cirrusODDatesMetrics: Metric[] = [
  {
    Component: CirrusFilters,
    filters: [
      { key: "owMarket" },
      { key: "cabinClass" },
      { key: "depDow" },
      { key: "depDate" },
      { key: "cirrusStatus" }
    ],
    key: "cirrus",
    name: "Cirrus"
  }
];
