import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Redirect, useHistory, useRouteMatch } from "react-router-dom";
import { Intent, NonIdealState } from "@blueprintjs/core";

import { useStores } from "store/Store";
import { AppToaster } from "services/Toaster";
import { tabCountReachedMessage } from "modules/App/messages";

function Share() {
  const route = useRouteMatch();
  const history = useHistory();
  const { tabsStore } = useStores();
  const [isTabNotFoundOrExpired, setIsTabNotFoundOrExpired] = useState(false);
  const [tabCountReached, setTabCountReached] = useState(false);
  const { shareId } = route.params;

  useEffect(() => {
    tabsStore
      .getSharedAnalysis(shareId)
      .then(response => {
        const { analysis } = response.data;

        if (tabsStore.canCreateTab) {
          tabsStore.createTabFromSharedAnalysis(analysis);
          history.push(`/analysis/${tabsStore.lastTabId}/explore`);
        } else {
          setTabCountReached(true);
          AppToaster.show({ intent: Intent.WARNING, message: tabCountReachedMessage });
        }
      })
      .catch(error => {
        if (error?.response?.status === 404) {
          setIsTabNotFoundOrExpired(true);
        }
      });
  }, [shareId, tabsStore, history]);

  if (isTabNotFoundOrExpired) {
    return (
      <NonIdealState
        className="mb-4 pb-3 flex-grow-1"
        description="It looks like this tab does not exist anymore. If you want to share tab please use 'Copy shared link' Tab option"
        icon="disable"
        title="Tab is not found"
      />
    );
  }

  if (tabCountReached) {
    return <Redirect to="/" />;
  }

  return null;
}

export default observer(Share);
