import { observer } from "mobx-react";
import React from "react";
import Explore from "views/Explore";
import Influence from "modules/Influence/Influence";
import Capacity from "../../Capacity/Capacity";

type Props = {
  subpage: string;
  tabId: string;
};

function AnalysisSubpage({ subpage, tabId }: Props) {
  switch (subpage) {
    case "influence":
      return <Influence tabId={tabId} />;
    case "capacity":
      return <Capacity />;
    case "explore":
    default:
      return <Explore tabId={tabId} />;
  }
}

export default observer(AnalysisSubpage);
