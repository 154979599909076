import React from "react";
import { Button, Colors, Icon, Tooltip } from "@blueprintjs/core";
import { observer } from "mobx-react";
import styled from "@emotion/styled";

import isNumber from "shared/helpers/isNumber/isNumber";
import { Status } from "modules/App/Status";

const StyledListItem = styled("li")`
  cursor: pointer;
  height: 60px;
  list-style: none;
  padding: 13px 0;
  & + li {
    border-top: 1px solid ${Colors.LIGHT_GRAY2};
  }
`;

const StyledListItemLabel = styled("div")`
  color: ${Colors.DARK_GRAY1};
  font-weight: bold;
  font-size: 12px;
  line-height: 1.25;
`;

const StyledListItemCount = styled("div")`
  color: ${Colors.GRAY1};
  font-weight: normal;
  font-size: 12px;
  line-height: 1.25;
`;

const StyledMetricIndicator = styled(Icon)`
  vertical-align: middle;
`;

const DOUBLE_DASH = "--";

export const buildData = (selectedMetric, data) => {
  if (selectedMetric === "finalRevenueExpectedPercentDiffToBaseline") {
    return data.xDayFinalRevenueExpectedBuildPercentDiffToBaseline;
  }
  if (selectedMetric === "raskDiffToBaseline") {
    return data.xDayRaskBuildDiffToBaseline;
  }
  if (selectedMetric === "diffToElb") {
    return data.xDayLoadFactorBuildDiffToBaseline;
  }
  if (selectedMetric === "influenceImpact") {
    return data.xDayInfluenceImpactBuild;
  }
};

export const buildCell = (selectedMetric, data) => {
  if (!buildData(selectedMetric, data) || buildData(selectedMetric, data) === 0) {
    return null;
  }

  const isPositive = buildData(selectedMetric, data) > 0;
  const icon = isPositive ? "symbol-triangle-up" : "symbol-triangle-down";
  const color = isPositive ? Colors.GREEN4 : Colors.RED4;

  return <StyledMetricIndicator className="mr-1" color={color} icon={icon} iconSize={10} />;
};

export const RenderItemContent = ({ selectedMetric, data }: { selectedMetric: string; data: {} }) => {
  const firstNumber = data[selectedMetric];
  const isNumericData = isNumber(firstNumber);
  const percentFirstNumber = isNumericData && "%";

  const secondIndicator = buildCell(selectedMetric, data);
  const secondNumber = buildData(selectedMetric, data);
  const isSecondDataExists = secondNumber || secondNumber === 0;
  const percentSecondNumber = isSecondDataExists && "%";

  const dataPerSelectedMetric = isNumericData ? `${firstNumber} ${percentFirstNumber}` : DOUBLE_DASH;
  const secondDataPerSelectedMetric = isSecondDataExists ? (
    <>
      {secondIndicator}
      {secondNumber} {percentSecondNumber}
    </>
  ) : (
    DOUBLE_DASH
  );

  return (
    <>
      <div className="flex-column" data-testid="item-selected-data-metric">
        {dataPerSelectedMetric}
      </div>
      <div data-testid="item-second-selected-data-metric">{secondDataPerSelectedMetric}</div>
    </>
  );
};

type Props = {
  children: React.ReactElement;
  onClick: Function;
  refetchItem?: Function;
  status: Status;
  subtitle: string;
  title: React.ReactElement | string;
};

function ModuleItem({ title, subtitle, refetchItem, children, status, onClick }: Props) {
  const isSuccess = status === Status.DONE;
  const isError = status === Status.ERROR;

  const headerContent = (
    <>
      <StyledListItemLabel className="text-truncate">{title}</StyledListItemLabel>
      {isError ? null : <StyledListItemCount className="pt-1">{subtitle}</StyledListItemCount>}
    </>
  );

  return (
    <StyledListItem className="mx-2">
      <div className="d-flex" onClick={() => onClick()}>
        <div className="col-8 px-2">
          <div className="flex-column">{headerContent}</div>
        </div>
        <div className="col-4 px-2 text-right">
          <div className="flex-column">
            {isError ? (
              <div onClick={e => e.stopPropagation()}>
                <Tooltip className="px-2" content="Error getting data" hoverOpenDelay={1000}>
                  <Icon color={Colors.GOLD4} data-testid="warning-icon" icon="warning-sign" />
                </Tooltip>
                <Button icon="refresh" minimal onClick={() => refetchItem && refetchItem()} />
              </div>
            ) : null}
            {isSuccess ? children : null}
          </div>
        </div>
      </div>
    </StyledListItem>
  );
}

export default observer(ModuleItem);
