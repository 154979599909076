import isEmpty from "lodash.isempty";
import unionBy from "lodash.unionby";
import isNumber from "../isNumber/isNumber";

import { exploreMetrics, buildCurvesMetrics } from "modules/Explore/metrics/metrics";

const keys = {
  columns: Object.fromEntries(
    exploreMetrics.map(([key, filters]: [string, any[]]) => [key, filters.map(filter => filter.key)])
  ),
  series: Object.fromEntries(
    buildCurvesMetrics.map(([key, filters]: [string, any[]]) => [key, filters.map(filter => filter.key)])
  )
};

const getDuplications = (source, arrayToCheck) => source.filter(name => arrayToCheck[1].includes(name));

export const createDoubleArraysByTemplates = (
  templateValue: (string | string[])[] | any,
  arrayToCheck: (string | string[])[],
  key: string
) => {
  const templatesGroupedByParent = Object.fromEntries(templateValue);
  const newArray: any = [];
  const matchedDictionary = keys[key];

  arrayToCheck.forEach(arrayParent => {
    if (matchedDictionary) {
      const selectGroupFromTemplate = templatesGroupedByParent[arrayParent[0]];
      const selectGroupFromGlobal = matchedDictionary[arrayParent[0]];

      if (selectGroupFromGlobal) {
        // parent with child exist in global
        if (Array.isArray(arrayParent?.[1])) {
          const duplicatedValues = getDuplications(selectGroupFromGlobal, arrayParent); // common part of global and array to check

          if (!isEmpty(duplicatedValues)) {
            newArray.push([arrayParent[0], duplicatedValues]);
          } else {
            const duplicatedValues = selectGroupFromTemplate
              ? getDuplications(selectGroupFromTemplate, arrayParent)
              : [];
            newArray.push([arrayParent[0], duplicatedValues]);
          }
        } else {
          newArray.push([arrayParent[0], selectGroupFromTemplate || []]);
        }
      } else if (selectGroupFromTemplate) {
        // parent with child exist in template
        const duplicatedValues = Array.isArray(arrayParent?.[1])
          ? getDuplications(selectGroupFromTemplate, arrayParent)
          : []; // common part of template and array to check
        newArray.push([arrayParent[0], duplicatedValues]);
      }
    } else {
      newArray.push(arrayParent);
    }
  });

  if (isEmpty(newArray)) {
    return templateValue;
  }

  return newArray;
};

export const checkDoubleArrays = (templateValue: any, object: any, key: string) => {
  const isSameType = typeof object[key] === typeof templateValue;

  if (isSameType && Array.isArray(templateValue) && Array.isArray(templateValue[0])) {
    if (isEmpty(object?.[key])) {
      return templateValue;
    }
    return createDoubleArraysByTemplates(templateValue, object[key], key);
  }

  return isSameType ? object[key] : templateValue;
};

export default function prepareStructure(args) {
  const { template = {}, object = {}, keysToMerge = [], arraysToMerge = [] } = args;
  const newObject = {};

  if (template === null) {
    return null;
  }
  if (isEmpty(template)) {
    return { ...object };
  }

  Object.entries(template).forEach(([key, templateValue]) => {
    const arrayObjectToMerge = arraysToMerge.find(array => array.key === key);
    const valueExists = object && object[key];

    if (
      // is object
      typeof templateValue === "object" &&
      templateValue !== null &&
      !Array.isArray(templateValue)
    ) {
      if (keysToMerge.includes(key)) {
        newObject[key] = { ...templateValue, ...(object ? object[key] : {}) };
      } else {
        newObject[key] = prepareStructure({
          arraysToMerge,
          keysToMerge,
          object: object && object[key],
          template: templateValue
        });
      }
    } else if (object && typeof object[key] === "boolean") {
      // is boolean
      newObject[key] = object[key];
    } else if (isNumber(valueExists)) {
      // is number
      newObject[key] = valueExists;
    } else if (arrayObjectToMerge) {
      // is array that should be merged
      const value = object && object[key] ? object[key] : [];
      newObject[key] = unionBy(value, templateValue, arrayObjectToMerge.identifier);
    } else {
      // not matching types cases
      // eslint-disable-next-line no-lonely-if
      if (!valueExists) {
        newObject[key] = templateValue;
      } else if (templateValue == null) {
        newObject[key] = object[key];
      } else {
        newObject[key] = checkDoubleArrays(templateValue, object, key);
      }
    }

    // legacy templates with end > start => reverse order
    if (
      key === "ndo" &&
      isNumber(object[key]?.start) &&
      isNumber(object[key]?.end) &&
      object[key].end > object[key].start
    ) {
      newObject[key] = {
        end: object[key].start,
        start: object[key].end
      };
    }
  });
  return newObject;
}
