import React from "react";
import { observer } from "mobx-react";
import pick from "lodash.pick";
import difference from "lodash.difference";

import SidebarSelect from "shared/components/Sidebar/SidebarSelect/SidebarSelect";
import SidebarFilter from "shared/components/Sidebar/SidebarFilter/SidebarFilter";
import { useStores } from "store/Store";
import isSidebarFilterVisible from "shared/helpers/isSidebarFilterVisible/isSidebarFilterVisible";
import { DisabledFilters } from "types/Tab.types";

type Sidebar = {
  filterKey?: string;
  filterQuery: string;
};

type MarketMappingFilters = {
  analystId: (number | null)[];
  regionId: number[];
  rtMarkets: string[];
  subregionId: (number | null)[];
};

type Props = {
  analystList: { label: string; value: null | number }[];
  pageContext: {
    disabledFilters: DisabledFilters;
    changeFilter: (filterKey: string, filterValue: string[]) => void;
    filters: MarketMappingFilters;
    sidebar: Sidebar;
  };
};

function MarketMappingsSidebarGroup(props: Props) {
  const { analystList = [], pageContext } = props;
  const { marketMappingsStore, regionsStore } = useStores();

  const { disabledFilters, filters, sidebar } = pageContext;

  const regionFilters = pick(filters, ["subregionId"]);
  const subregionFilters = pick(filters, ["regionId"]);
  const matchingRegions = regionsStore.getRegionsFromSelectedSubregions(regionFilters, "subregionId");
  const regions = difference(regionsStore.regionsForFilter, matchingRegions);

  const matchingSubregions = regionsStore.getSubregionsFromSelectedRegions(subregionFilters, "regionId");
  const subregions = difference(regionsStore.subregionsForFilter, matchingSubregions);

  return (
    <>
      <SidebarFilter
        isTemporarilyDisabled={"rtMarkets" in disabledFilters}
        isVisible={isSidebarFilterVisible("rt markets", "rtMarkets", sidebar)}
      >
        <SidebarSelect
          fuzzySearch
          items={marketMappingsStore.rtMarkets}
          onChange={filterValue => pageContext.changeFilter("rtMarkets", filterValue)}
          selectedItems={filters.rtMarkets}
          title="RT Market"
          withAutoSeparatedItems
        />
      </SidebarFilter>
      <SidebarFilter
        isTemporarilyDisabled={"analystId" in disabledFilters}
        isVisible={isSidebarFilterVisible("analystId", "analystId", sidebar)}
      >
        <SidebarSelect
          items={analystList}
          onChange={filterValue => pageContext.changeFilter("analystId", filterValue)}
          selectedItems={filters.analystId}
          title="Analyst"
          withAutoSeparatedItems
        />
      </SidebarFilter>
      <SidebarFilter
        isTemporarilyDisabled={"regionId" in disabledFilters}
        isVisible={isSidebarFilterVisible("regionId", "regionId", sidebar)}
      >
        <SidebarSelect
          fuzzySearch
          items={[
            { groupItems: matchingRegions, label: "Matching pre-selected" },
            { groupItems: regions, label: "All regions" }
          ]}
          onChange={filterValue => pageContext.changeFilter("regionId", filterValue)}
          selectedItems={filters.regionId}
          title="Region"
          withAutoSeparatedItems
        />
      </SidebarFilter>
      <SidebarFilter
        isTemporarilyDisabled={"subregionId" in disabledFilters}
        isVisible={isSidebarFilterVisible("subregionId", "subregionId", sidebar)}
      >
        <SidebarSelect
          fuzzySearch
          items={[
            { groupItems: matchingSubregions, label: "Matching pre-selected" },
            { groupItems: subregions, label: "All subregions" }
          ]}
          onChange={filterValue => pageContext.changeFilter("subregionId", filterValue)}
          selectedItems={filters.subregionId}
          title="Subregion"
          withAutoSeparatedItems
        />
      </SidebarFilter>
    </>
  );
}

export default observer(MarketMappingsSidebarGroup);
