import React from "react";
import { observer } from "mobx-react";
import { ControlGroup } from "@blueprintjs/core";
import { useStores } from "store/Store";
import formatValueWithUnit from "shared/helpers/formatValueWithUnit/formatValueWithUnit";
import { InfluenceStatus } from "modules/Influence/Influence.model";
import {
  StyledButtonIcon,
  StyledCard,
  StyledHeaderTitle,
  StyledInfluenceInput
} from "modules/Influence/InfluenceStyles";
import Row from "shared/components/Row/Row";
import { influenceTypeNames } from "modules/Influence/influenceConstants";

type Props = {
  readOnly: boolean;
};

function AbsolutePriceAdjustmentForm(props: Props) {
  const { readOnly } = props;
  const { influenceStore } = useStores();
  const { parameters, status, updateInputValue } = influenceStore;
  const { inputValue, value } = parameters;

  const isLoading = status.includes(InfluenceStatus.LOADING);

  return (
    <StyledCard className="p-3 mb-2">
      <Row className="align-items-center">
        <div className="col-5">
          <StyledHeaderTitle>{influenceTypeNames.AP}</StyledHeaderTitle>
        </div>
        <div className="col-7 pl-0">
          <ControlGroup className="align-items-center" data-testid="influence-absolute-value">
            <strong className="mr-2">Adj. </strong>
            {readOnly ? (
              <div className="ml-auto">{formatValueWithUnit(value, "currency")}</div>
            ) : (
              <>
                <StyledButtonIcon disabled={isLoading}>$</StyledButtonIcon>
                <StyledInfluenceInput
                  allowNumericCharactersOnly
                  clampValueOnBlur
                  data-testid="influence-absolute-input"
                  disabled={isLoading}
                  fill
                  max={10000000}
                  min={-10000000}
                  onValueChange={updateInputValue}
                  placeholder="0"
                  value={inputValue}
                />
              </>
            )}
          </ControlGroup>
        </div>
      </Row>
    </StyledCard>
  );
}

export default observer(AbsolutePriceAdjustmentForm);
