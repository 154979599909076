import React from "react";
import { observer } from "mobx-react";
import { Colors, ProgressBar } from "@blueprintjs/core";
import styled from "@emotion/styled";

const StyledProgressBarContainer = styled("div")`
  z-index: 1;
`;

const StyledProgressBar = styled(ProgressBar)`
  &.bp3-progress-bar {
    border-radius: 0;
    height: 6px;
    left: 0;
    position: absolute;
    right: 0;
    top: 0px;
    width: auto;

    .bp3-progress-meter {
      border-radius: 0 !important;
    }
  }
`;

const StyledProgressStatus = styled("div")`
  color: ${Colors.BLACK};
  font-size: 6px;
  position: absolute;
  right: 8px;
  text-align: center;
  top: -1px;
  width: 100%;
`;

type Props = {
  value: number;
};

function WatchlistProgressBar(props: Props) {
  const { value = 0 } = props;

  return (
    <StyledProgressBarContainer className="position-relative">
      <StyledProgressBar animate={false} intent="primary" stripes={false} value={value} />
      <StyledProgressStatus>{(value * 100).toFixed(0)}%</StyledProgressStatus>
    </StyledProgressBarContainer>
  );
}

export default observer(WatchlistProgressBar);
