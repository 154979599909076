import React from "react";
import { observer } from "mobx-react";
import { ErrorBoundary as SentryErrorBoundary } from "@sentry/react";

import ApplicationErrorInformation from "modules/App/ApplicationErrorInformation/ApplicationErrorInformation";
import Module from "../Module/Module";

const defaultFallback = (
  <Module
    childrenClassName="placeholder-center"
    className="h-100"
    title={<span className="opacity-0">Application Error</span>}
  >
    <ApplicationErrorInformation />
  </Module>
);

type Props = {
  children?: React.ReactElement | React.ReactElement[];
  fallback?: React.ReactElement;
};

function ErrorBoundary({ children, fallback }: Props) {
  return <SentryErrorBoundary fallback={fallback || defaultFallback}>{children}</SentryErrorBoundary>;
}

export default observer(ErrorBoundary);
