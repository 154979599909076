import removeEmptyFilters from "../removeEmptyFilters/removeEmptyFilters";

const getEnabledFilters = (
  filterSource: object,
  disabledFiltersSource: object,
  initialFilters: object,
  filtersEnabled = true
) => {
  const enabledFilters = Object.entries(filterSource).reduce((result, [filterKey, filterValue]) => {
    if (!filtersEnabled) {
      return initialFilters;
    }

    if (!(filterKey in disabledFiltersSource)) {
      return { ...result, [filterKey]: filterValue };
    }

    if (!Array.isArray(filterValue)) {
      return result;
    }

    const enabledFilterValues = filterValue.filter(fv => !disabledFiltersSource[filterKey].includes(fv));
    return { ...result, [filterKey]: enabledFilterValues };
  }, {});

  return removeEmptyFilters(enabledFilters, initialFilters);
};

export default getEnabledFilters;
