import React, { useRef } from "react";
import { IconName } from "@blueprintjs/core";
import clsx from "clsx";
import { observer } from "mobx-react";
import { Enable } from "re-resizable";
import { Direction } from "re-resizable/lib/resizer";
import isEnv from "../../helpers/isEnv/isEnv";
import { Tab } from "models/Tab/Tab.model";

import { resizableHandleStyles, StyledResizable, StyledResizableFillSpace } from "./Resizable.styles";
import { allModuleCollapsedInSync, allSiblingsCollapsed, getAffectedModules } from "./Resizable.utils";
import ResizableCollapseTrigger from "./ResizableCollapseTrigger/ResizableCollapseTrigger";
import ResizableOverlay from "./ResizableOverlay/ResizableOverlay";

type Props = {
  children: React.ReactNode;
  collapseOn?: "right" | "left";
  defaultHeight?: number;
  defaultPercentWidth?: number;
  enable?: Enable;
  icon: IconName;
  maxWidth?: string | number;
  minHeight?: number;
  minWidth?: number;
  moduleKey: string;
  tab: typeof Tab;
};

function Resizable({
  children,
  collapseOn,
  defaultHeight,
  defaultPercentWidth,
  enable,
  icon,
  maxWidth,
  minHeight,
  minWidth,
  moduleKey,
  tab
}: Props) {
  const preferences = tab.preferences?.modules[moduleKey] || {};
  const resizeElementRef = useRef(null);

  const allOtherCollapsed = allSiblingsCollapsed(moduleKey, tab);
  const allSyncCollapsed = allModuleCollapsedInSync(moduleKey, tab);

  const collapseTriggerElement = isEnv(["staging", "development", "test"]) && collapseOn && (
    <ResizableCollapseTrigger
      isResizingModules={tab.isResizingModules}
      onCollapse={() => tab.setModuleState(moduleKey, { isCollapsed: true })}
      onExpand={() => tab.setModuleState(moduleKey, { isCollapsed: false })}
      position={collapseOn}
    />
  );

  if (!enable) {
    return (
      <StyledResizableFillSpace
        ref={resizeElementRef}
        className={clsx({ allCollapsed: allSyncCollapsed, collapsed: preferences?.isCollapsed })}
        defaultHeight={defaultHeight}
        minHeight={minHeight}
        minWidth={minWidth}
        width={defaultPercentWidth && !preferences?.isCollapsed ? `${defaultPercentWidth}%` : "auto"}
      >
        <ResizableOverlay icon={icon} isResizing={tab.isResizingModules && !preferences?.isCollapsed} isSibling />
        {children}
        {collapseTriggerElement}
      </StyledResizableFillSpace>
    );
  }

  const onResizeStop = (event: MouseEvent | TouchEvent, direction: Direction, elementRef: HTMLElement) => {
    const parent = elementRef?.offsetParent;
    const width = elementRef.offsetWidth;

    if (parent) {
      tab.setModuleState(moduleKey, { isResizing: false, width: (width / parent.clientWidth) * 100 });
    }
  };

  const getDefaultWidth = () => {
    if (preferences?.width) {
      return `${preferences.width}%`;
    }

    return defaultPercentWidth && !preferences?.isCollapsed ? `${defaultPercentWidth}%` : "auto";
  };

  const defaultCollapseWidth = 58;
  const affectedModules = getAffectedModules(moduleKey, tab);

  return (
    <StyledResizable
      bounds="parent"
      boundsByDirection
      className={clsx({
        allCollapsed: allSyncCollapsed,
        collapsed: preferences?.isCollapsed,
        "flex-grow-1": allOtherCollapsed
      })}
      defaultSize={{ height: defaultHeight ? `${defaultHeight}px` : "auto", width: getDefaultWidth() }}
      enable={preferences?.isCollapsed || allOtherCollapsed ? {} : enable}
      handleStyles={resizableHandleStyles}
      maxWidth={
        preferences?.isCollapsed || allOtherCollapsed
          ? `calc(100% - ${affectedModules.length * defaultCollapseWidth}px`
          : maxWidth
      }
      minWidth={minWidth && !preferences?.isCollapsed ? `${minWidth - 1}px` : undefined}
      onResizeStart={() => tab.setModuleState(moduleKey, { isResizing: true })}
      onResizeStop={onResizeStop}
    >
      {icon && <ResizableOverlay icon={icon} isResizing={tab.isResizingModules && !preferences?.isCollapsed} />}
      {children}
      {collapseTriggerElement}
    </StyledResizable>
  );
}

export default observer(Resizable);
