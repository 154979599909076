import React from "react";
import { observer } from "mobx-react";

import FilterInteractionsWrapper from "shared/components/FilterInteractionsWrapper/FilterInteractionsWrapper";
import InfluenceHistoryTagList from "../InfluenceHistoryTagList/InfluenceHistoryTagList";
import TopBar from "shared/components/TopBar/TopBar";
import { useStores } from "store/Store";

function InfluenceHistoryTopBar() {
  const { analysisMappingsStore, influenceHistoryStore } = useStores();
  const { isLoading } = analysisMappingsStore;
  const { page, setSidebarOpen, toggleFiltersEnabled } = influenceHistoryStore;

  return (
    <TopBar>
      {page.applied && <InfluenceHistoryTagList />}
      <FilterInteractionsWrapper
        buttonProps={{
          className: "ml-auto",
          isLoading,
          onClick: setSidebarOpen,
          testId: "sidebar-button"
        }}
        filterSwitchProps={{ checked: page.filtersEnabled, onChange: toggleFiltersEnabled }}
      />
    </TopBar>
  );
}

export default observer(InfluenceHistoryTopBar);
