// @flow

import { action, computed, makeObservable, observable } from "mobx";
import { Intent } from "@blueprintjs/core";
import isEmpty from "lodash.isempty";
import cloneDeep from "lodash.clonedeep";
import isEqual from "lodash.isequal";
import merge from "lodash.merge";

import api, { isCancel } from "services/Api";
import { AppToaster } from "services/Toaster";
import type { RootStore } from "modules/App/Root.model";
import { Tab } from "../../models/Tab/Tab.model";
import { createTabForTemplate, filterPersistentTab, PURE_TAB } from "../../models/Tab/Tab.utils";
import { Status } from "modules/App/Status";

export type MySavedAnalysis = {
  id: number;
  userId: number;
  label: string;
  view: Tab;
};
const forcedColumnsGroupKey = "influenceGroup";

export const tabToMySavedAnalysis = (tab: Tab) => {
  const { id, label, parentId, parentType, ...view } = filterPersistentTab(tab);

  return { label, view };
};

const overrideForcedColumns = (columns: (string | string[])[], values: string[]) =>
  columns.map(([columnHeaderKey, columnKeys]) => {
    if (columnHeaderKey === forcedColumnsGroupKey) {
      return [columnHeaderKey, values];
    }
    return [columnHeaderKey, columnKeys];
  });

const replaceByCurrentUser = (analystId, currentUserId) => analystId.map(user => (user === -1 ? currentUserId : user));

export const templateToMySavedAnalysis = ({ template, ...args }, currentUserId): MySavedAnalysis => {
  const newTemplate = cloneDeep(template);
  if (newTemplate.applied?.filters?.analystId && currentUserId) {
    newTemplate.applied.filters.analystId = replaceByCurrentUser(newTemplate.applied.filters.analystId, currentUserId);
  }
  if (newTemplate.filters?.analystId && currentUserId) {
    newTemplate.filters.analystId = replaceByCurrentUser(newTemplate.filters.analystId, currentUserId);
  }

  return {
    view: newTemplate,
    ...args
  };
};

export const dataWithoutTemporaryParams = (data: Object, options: { overrideValue: string[] } = {}) => {
  const { overrideValue = [] } = options;
  const newData = data;

  delete newData.view.buildCurves.groupStatuses;
  delete newData.view.flightsCount;

  const columns = !isEmpty(overrideValue)
    ? overrideForcedColumns(newData.view.flightsTable.columns, overrideValue)
    : newData.view.flightsTable.columns;
  const series = newData.view.buildCurves.series.filter(([, value]) => value.length);
  return {
    ...newData,
    view: {
      ...newData.view,
      buildCurves: { ...newData.view.buildCurves, range: {}, selectedRange: {}, series },
      departureDateExtremes: {},
      flightsTable: {
        ...newData.view.flightsTable,
        columns: columns.filter(([, value]) => value.length),
        groupStatuses: []
      }
    }
  };
};

export class TemplatesStore {
  @observable isOpen: boolean = false;
  @observable savedAnalyses: MySavedAnalysis[] = [];
  @observable systemAnalyses: MySavedAnalysis[] = [];
  @observable teamAnalyses: MySavedAnalysis[] = [];
  @observable status = Status.INIT;

  rootStore: RootStore;

  constructor(rootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  @action
  deleteTeamAnalyses(templateId: number, label: string) {
    return api
      .deleteTeamAnalyses(templateId)
      .then(() => {
        this.getAllTemplates();
        AppToaster.show({
          intent: Intent.PRIMARY,
          message: `Analysis "${label}" has been deleted`
        });
      })
      .catch(() => {
        this.status = Status.ERROR;
      });
  }

  @action.bound
  editTeamAnalysis(tab: Object) {
    const tabToSave = filterPersistentTab(tab);
    const { id, label } = tabToSave;

    const template = createTabForTemplate(tabToSave);

    this.status = Status.LOADING;

    return api
      .editTeamAnalysis(id, { label, template })
      .then(() => {
        AppToaster.show({
          intent: Intent.SUCCESS,
          message: `'${label}' has been saved`
        });
        return this.getAllTemplates();
      })
      .catch(error => {
        if (error.response.status === 409) {
          const errorMessage = `Team analyses with label '${label}' already exists`;
          AppToaster.show({
            intent: Intent.DANGER,
            message: errorMessage
          });
          this.status = Status.ERROR;
          return Promise.reject(errorMessage);
        }

        AppToaster.show({
          intent: Intent.DANGER,
          message: `'${label}' could not be saved`
        });
        this.status = Status.ERROR;
        return error;
      });
  }

  @action
  getAllTemplates() {
    this.status = Status.LOADING;
    const savedAnalysesPromise = api.getMySavedAnalyses();
    const systemAnalysesPromise = api.getSystemAnalyses();
    const teamAnalysesPromise = api.getTeamAnalyses();
    const promiseArray = [savedAnalysesPromise, systemAnalysesPromise, teamAnalysesPromise];

    return Promise.all(promiseArray)
      .then(([savedAnalysesResponse, systemAnalysesResponse, teamAnalysesResponse]) => {
        const { data: savedAnalysesData = [] } = savedAnalysesResponse;
        const { data: systemAnalysesData = [] } = systemAnalysesResponse;
        const currentUserId = this.rootStore?.appStore?.auth?.user?.id;
        const { data: teamAnalysesData = [] } = teamAnalysesResponse;

        this.savedAnalyses = savedAnalysesData;
        // @TODO: for v2 simplify logic for templates when responses on BE are aligned

        this.systemAnalyses = systemAnalysesData.rows.map(row => templateToMySavedAnalysis(row, currentUserId));
        this.teamAnalyses = teamAnalysesData.rows.map(templateToMySavedAnalysis);
        this.status = Status.DONE;
      })
      .catch(thrown => {
        if (isCancel(thrown)) {
          this.status = Status.LOADING;
          return;
        }
        AppToaster.show({
          intent: Intent.DANGER,
          message: `Could not load templates`
        });
        this.savedAnalyses.clear();
        this.systemAnalyses.clear();
        this.teamAnalyses.clear();
        this.status = Status.ERROR;
      });
  }

  @action
  getMySavedAnalyses(): MySavedAnalysis[] {
    this.status = Status.LOADING;
    return api
      .getMySavedAnalyses()
      .then(({ data }) => {
        this.status = Status.DONE;
        this.savedAnalyses = data;
      })
      .catch(() => {
        this.status = Status.ERROR;
      });
  }

  @action.bound
  addView(tab: Tab) {
    const view = tabToMySavedAnalysis(tab);
    return api
      .addMySavedAnalysis(view)
      .then(({ data }) => {
        return this.getMySavedAnalyses().then(() => {
          AppToaster.show({
            intent: Intent.PRIMARY,
            message: `${view.label} has been saved`
          });

          const mySavedAnalysisId = data.id;
          if (mySavedAnalysisId) {
            tab.patchMySavedAnalysisId(mySavedAnalysisId);
          }
        });
      })
      .catch(() => {
        AppToaster.show({
          intent: Intent.DANGER,
          message: `${view.label} could not be saved`
        });
        this.status = Status.ERROR;
      });
  }

  @action.bound
  patchView(viewId: number, tabData: Object) {
    const view = tabToMySavedAnalysis(tabData);
    api
      .patchMySavedAnalysis(viewId, view)
      .then(() => {
        this.getMySavedAnalyses();
        AppToaster.show({
          intent: Intent.PRIMARY,
          message: `${view.label} has been saved`
        });
      })
      .catch(() => {
        AppToaster.show({
          intent: Intent.DANGER,
          message: `${view.label} could not be saved`
        });
        this.status = Status.ERROR;
      });
  }

  @action
  removeView(viewId: number, label: string) {
    return api
      .removeMySavedAnalysis(viewId)
      .then(() => {
        this.getMySavedAnalyses();
        AppToaster.show({
          intent: Intent.PRIMARY,
          message: `${label} has been deleted`
        });
        this.removeObsoleteMySavedAnalysisId(viewId);
      })
      .catch(() => {
        this.status = Status.ERROR;
      });
  }

  @action.bound
  toggleSidebar() {
    this.isOpen = !this.isOpen;
  }

  @action
  getTemplateById = (viewId: number, templateType = "saved", legacyId): MySavedAnalysis => {
    if (!viewId) return undefined;
    if (legacyId) {
      return this.savedAnalyses.find(view => view.id === legacyId);
    }

    let mySavedAnalyses;
    switch (templateType) {
      case "saved":
      default:
        mySavedAnalyses = this.savedAnalyses;
        break;
      case "system":
        mySavedAnalyses = this.systemAnalyses;
        break;
      case "team":
        mySavedAnalyses = this.teamAnalyses;
        break;
    }
    return mySavedAnalyses.find(view => view.id === viewId);
  };

  @action
  getComponent = (viewId: string, parentType: string, componentName: string): Object => {
    const mySavedAnalysis = this.getTemplateById(viewId, parentType);
    if (!mySavedAnalysis || !mySavedAnalysis.view[componentName]) {
      return undefined;
    }

    return mySavedAnalysis.view[componentName];
  };

  @computed
  get isMaxMySavedAnalyses(): boolean {
    return this.savedAnalyses.length === 50;
  }

  checkTabUnsaved = (tabId: string): boolean => {
    const { tabsStore } = this.rootStore;
    const tab = tabsStore.getTab(tabId);

    if (!tab) return false;

    const mySavedAnalysis = this.getTemplateById(tab.parentId, "saved", tab.savedViewId);

    // tab based not on saved view – always return as unsaved
    if (!mySavedAnalysis) return true;

    const forcedColumn = mySavedAnalysis.view.flightsTable.columns.find(
      ([groupKey]) => groupKey === forcedColumnsGroupKey
    );

    const forcedColumnKeys = forcedColumn ? forcedColumn[1] : [];
    const mySavedView = merge({}, PURE_TAB, mySavedAnalysis.view);

    const mySavedViewWithoutTemporaryParams = dataWithoutTemporaryParams({
      label: mySavedAnalysis.label,
      view: filterPersistentTab(mySavedView)
    });

    const { view, label } = tabToMySavedAnalysis(tab);
    const viewWithMergedTabParams = merge({}, PURE_TAB, view);

    const viewWithoutUselessParams = dataWithoutTemporaryParams(
      {
        label,
        view: viewWithMergedTabParams
      },
      {
        overrideValue: forcedColumnKeys
      }
    );

    return !isEqual(mySavedViewWithoutTemporaryParams, viewWithoutUselessParams);
  };

  removeObsoleteMySavedAnalysisId(viewId: number) {
    this.rootStore.tabsStore.tabs
      .filter((tab: Tab) => tab.parentId === viewId)
      .forEach((tab: Tab) => tab.patchMySavedAnalysisId(null));
  }
}
