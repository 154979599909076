import React from "react";
import { observer } from "mobx-react";

import SidebarSelect from "shared/components/Sidebar/SidebarSelect/SidebarSelect";
import SidebarFilter from "shared/components/Sidebar/SidebarFilter/SidebarFilter";
import SidebarNDOSelect from "shared/components/Sidebar/SidebarNDOSelect/SidebarNDOSelect";
import SidebarDateRangeSelect from "shared/components/Sidebar/SidebarDateRangeSelect/SidebarDateRangeSelect";
import SidebarDaySelect from "shared/components/Sidebar/SidebarDaySelect/SidebarDaySelect";
import isSidebarFilterVisible from "shared/helpers/isSidebarFilterVisible/isSidebarFilterVisible";

import type { AnalysisMappingsStore } from "models/AnalysisMappings.model";
import type { Tab } from "models/Tab/Tab.model";

type Props = {
  computedDateFormat: string;
  computedDateMonthFormat: string;
  mappings: AnalysisMappingsStore;
  pageContext: Tab;
};

function InfluenceHistoryDepartureDateFilters(props: Props) {
  const { computedDateFormat, computedDateMonthFormat, mappings, pageContext } = props;
  const { changeFilter, disabledFilters, filters, sidebar } = pageContext;
  const { data: { quarter = [] } = {} } = mappings;

  const quarters = quarter.map(singleQuarter => {
    return {
      label: `${singleQuarter.substring(0, 4)} ${singleQuarter.substring(4)}`,
      value: singleQuarter
    };
  });

  return (
    <>
      <SidebarFilter
        isTemporarilyDisabled={"ndo" in disabledFilters}
        isVisible={isSidebarFilterVisible("number of days to departure", "ndo", sidebar)}
      >
        <SidebarNDOSelect
          onChange={filterValue => changeFilter("ndo", filterValue)}
          title="Number of Days to Departure"
          values={filters.ndo}
        />
      </SidebarFilter>
      <SidebarFilter
        isTemporarilyDisabled={"quarter" in disabledFilters}
        isVisible={isSidebarFilterVisible("departure quarter", "quarter", sidebar)}
      >
        <SidebarSelect
          fuzzySearch
          items={quarters}
          onChange={filterValue => changeFilter("quarter", filterValue)}
          selectedItems={filters.quarter}
          title="Quarter"
          withAutoSeparatedItems
        />
      </SidebarFilter>
      <SidebarFilter
        isTemporarilyDisabled={"depMonth" in disabledFilters}
        isVisible={isSidebarFilterVisible("departure month", "depMonth", sidebar)}
      >
        <SidebarSelect
          computedDateFormat={computedDateFormat}
          computedDateMonthFormat={computedDateMonthFormat}
          fuzzySearch
          items={mappings.data.depMonth}
          onChange={filterValue => changeFilter("depMonth", filterValue)}
          selectedItems={filters.depMonth}
          title="Departure Month"
          withAutoSeparatedItems
        />
      </SidebarFilter>
      <SidebarFilter
        isTemporarilyDisabled={"depWeek" in disabledFilters}
        isVisible={isSidebarFilterVisible("departure week", "depWeek", sidebar)}
      >
        <SidebarSelect
          computedDateFormat={computedDateFormat}
          computedDateMonthFormat={computedDateMonthFormat}
          fuzzySearch
          items={mappings.data.depWeek}
          onChange={filterValue => changeFilter("depWeek", filterValue)}
          selectedItems={filters.depWeek}
          title="Departure Week"
          withAutoSeparatedItems
        />
      </SidebarFilter>
      <SidebarFilter
        isTemporarilyDisabled={"depDate" in disabledFilters}
        isVisible={isSidebarFilterVisible("departure date", "depDate", sidebar)}
      >
        <SidebarDateRangeSelect
          computedDateFormat={computedDateFormat}
          dates={filters.depDate}
          onChange={filterValue => changeFilter("depDate", filterValue)}
          title="Departure Date"
        />
      </SidebarFilter>
      <SidebarFilter
        isTemporarilyDisabled={"depDow" in disabledFilters}
        isVisible={isSidebarFilterVisible("departure day of week", "depDow", sidebar)}
      >
        <SidebarDaySelect
          onChange={filterValue => changeFilter("depDow", filterValue)}
          selectedItems={filters.depDow}
          title="Departure Day of Week"
        />
      </SidebarFilter>
    </>
  );
}

export default observer(InfluenceHistoryDepartureDateFilters);
