import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useDebounce } from "use-debounce";
import { Colors, Spinner } from "@blueprintjs/core";

import styled from "@emotion/styled";
import SearchInput from "shared/components/SearchInput/SearchInput";
import Sidebar from "shared/components/Sidebar/Sidebar";
import { useStores } from "store/Store";
import TemplatesList from "../TemplatesList/TemplatesList";
import { Status } from "modules/App/Status";

const StyledTemplatesListContainer = styled("div")`
  &:last-child {
    border-bottom: 1px solid ${Colors.LIGHT_GRAY2};
  }
`;

function TemplatesSidebar() {
  const [filterQuery, setFilterQuery] = useState("");
  const { templatesStore } = useStores();
  const [activeFilter] = useDebounce(filterQuery.toLowerCase(), 400);
  const { isOpen } = templatesStore;

  useEffect(() => {
    if (!isOpen) {
      setFilterQuery("");
    }
  }, [isOpen]);

  const lists =
    templatesStore.status === Status.LOADING ? (
      <Spinner className="mt-3" />
    ) : (
      <StyledTemplatesListContainer>
        <TemplatesList filterQuery={activeFilter} icon="pin" templateKey="savedAnalyses" type="My Saved Analyses" />
        <TemplatesList filterQuery={activeFilter} icon="people" templateKey="teamAnalyses" type="Team Analyses" />
        <TemplatesList filterQuery={activeFilter} icon="cog" templateKey="systemAnalyses" type="System Analyses" />
      </StyledTemplatesListContainer>
    );
  return (
    <Sidebar isOpen={isOpen} positionTop={40} setOpen={templatesStore.toggleSidebar}>
      <SearchInput onChange={setFilterQuery} placeholder="Search analyses…" value={filterQuery} />
      {lists}
    </Sidebar>
  );
}

export default observer(TemplatesSidebar);
