import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import { Button, Colors, Menu, Popover, Position, Spinner } from "@blueprintjs/core";
import styled from "@emotion/styled";
import kebabCase from "lodash.kebabcase";
import pluralize from "pluralize";
import isEmpty from "lodash.isempty";

import { useStores } from "store/Store";
import filterNamesMap from "modules/Explore/filterNames";
import HeaderTab from "components/Tabs/HeaderTab";
import { Status } from "modules/App/Status";
import { MAX_TAB_COUNT } from "modules/App/constants";

const StyledTab = styled("div")`
  min-width: 0;
`;

const StyledMenuTopSummary = styled("div")`
  color: ${Colors.GRAY1};
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 16px;
  margin-left: 10px;
`;

const StyledTabLimitMenu = styled(Menu)`
  padding: 18px 20px;
  width: 300px !important;
`;

const StyledNewMySavedAnalysisRow = styled("li")`
  b {
    color: #30404d;
  }
`;

const StyledNewMySavedAnalysisRmBtn = styled(Button)`
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
`;

const MySavedAnalysesList = styled("ul")`
  max-height: 252px;
  overflow-y: auto;
`;

const StyledTabLimitHeader = styled("p")`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.3px;
`;

const StyledNavMenu = styled(Menu)`
  &.bp3-menu {
    max-width: 405px;
    overflow: hidden;
    padding: 0;
    width: 405px;
  }
`;

const StyledMenuTop = styled("div")`
  border-bottom: 1px solid rgba(16, 22, 26, 0.15);
`;

const StyledNavPopoverButton = styled(Button)`
  &.bp3-add-tab {
    border-radius: 2px 2px 0 0;
    border: 1px solid transparent;
    border-bottom: none;
    padding: 0 3px;
    height: 30px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    background: #ffffff33;

    .bp3-icon-cross svg {
      fill: #ffffff99;
    }

    &:hover {
      background: #ffffff33;
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 2px 2px ${Colors.BLUE3}99 !important;
    }
  }
`;

const StyledAddTemplateButton = styled(Button)`
  width: 140px;
`;

const StyledAddTabButton = styled(Button)`
  width: 95px;
`;

type Props = {
  isEditingTemplate?: boolean;
};

function HeaderTabs({ isEditingTemplate }: Props) {
  const history = useHistory();
  const [navPopoverStatus, setNavPopoverStatus] = useState(false);
  const { appStore, modalStore, systemSettingsStore, templatesStore, tabsStore } = useStores();
  const { isMiles } = systemSettingsStore;

  const filterNames = filterNamesMap(isMiles);

  const { auth = {} } = appStore;
  const { user } = auth;

  const { savedAnalyses = [], status } = templatesStore;

  const { tabs, lastTab } = tabsStore;
  const tabsCount = tabs.length;

  useEffect(() => {
    if (!isEditingTemplate) {
      const { tabs: metaDataTabs = [] } = user;
      tabsStore.initTabs(metaDataTabs);
    }
  }, [user, tabsStore, isEditingTemplate]);

  const headerTabsRender = (tab, index) => (
    <HeaderTab
      key={tab?.id}
      getPreviousTabId={() => {
        if (tabs.length > 1 && !isEditingTemplate) {
          if (!tabs[index - 1]) return tabs[index + 1].id;
          return tabs[index - 1].id;
        }
      }}
      isActive={isEditingTemplate}
      tab={tab}
    />
  );

  const tabsList =
    isEditingTemplate && !isEmpty(tabs)
      ? headerTabsRender(lastTab, undefined)
      : tabs.filter(tab => !tab.editAnalysis).map(headerTabsRender);

  const showDeleteConfirmation = (id: string, label: string) => {
    const config = {
      action: () => templatesStore.removeView(id, label),
      buttonText: "Delete",
      header: "Are you sure you want to delete this analysis?",
      text: "You won’t be able to restore your work.",
      title: "Delete Analysis"
    };
    modalStore.setModal("confirmation", config);
  };

  const sortedViews = savedAnalyses.slice().sort((viewA, viewB) => viewA.label - viewB.label);

  const navMenu =
    tabsCount < MAX_TAB_COUNT ? (
      <StyledNavMenu>
        <StyledMenuTop className="row m-0 py-1">
          <StyledMenuTopSummary className="pl-2 align-self-center">
            {pluralize("SAVED ANALYSIS", savedAnalyses.length, true)}
          </StyledMenuTopSummary>
          <StyledAddTemplateButton
            className="ml-auto"
            icon="add"
            intent="primary"
            minimal
            onClick={() => {
              tabsStore.shouldConfirmLeavingTab(lastTab?.state?.dirty, () => {
                templatesStore.toggleSidebar();
              });
              setNavPopoverStatus(false);
            }}
          >
            From Template
          </StyledAddTemplateButton>
          <StyledAddTabButton
            className="ml-auto mr-2"
            data-testid="add-tab-button"
            icon="add"
            intent="primary"
            minimal
            onClick={() => {
              tabsStore.shouldConfirmLeavingTab(lastTab?.state?.dirty, () => {
                tabsStore.addTab();
                history.push(`/analysis/${tabsStore.lastTabId}/explore`);
              });
              setNavPopoverStatus(false);
            }}
          >
            Analysis
          </StyledAddTabButton>
        </StyledMenuTop>
        {status === Status.LOADING ? <Spinner className="p-2" /> : null}
        {savedAnalyses.length && status !== Status.LOADING ? (
          <MySavedAnalysesList className="bp3-list-unstyled py-2">
            {sortedViews.map(mySavedAnalysis => {
              const { id, label, view } = mySavedAnalysis;
              const { flightsTable } = view;
              return (
                <StyledNewMySavedAnalysisRow key={id} className="d-flex position-relative row m-0 px-2">
                  <Button
                    className="col justify-content-start pr-5"
                    data-testid={`saved-view-${kebabCase(label)}`}
                    minimal
                    onClick={() => {
                      tabsStore.shouldConfirmLeavingTab(lastTab?.state?.dirty, () => {
                        tabsStore.createTabFromSavedAnalysis(mySavedAnalysis, "saved");
                        history.push(`/analysis/${tabsStore.lastTabId}/explore`);
                      });
                      setNavPopoverStatus(false);
                    }}
                  >
                    <div>
                      <strong className="text-break">{label}</strong>
                    </div>
                    <div className="mt-1">
                      {flightsTable.aggregations && flightsTable.aggregations.length ? (
                        <span>
                          {flightsTable.aggregations.map(aggregation => filterNames[aggregation]).join(" / ")}
                        </span>
                      ) : null}
                    </div>
                  </Button>
                  <StyledNewMySavedAnalysisRmBtn
                    className="col-auto ml-auto"
                    data-testid="remove-saved-analysis"
                    icon="trash"
                    minimal
                    onClick={() => showDeleteConfirmation(id, label)}
                  />
                </StyledNewMySavedAnalysisRow>
              );
            })}
          </MySavedAnalysesList>
        ) : null}
      </StyledNavMenu>
    ) : (
      <StyledTabLimitMenu>
        <div>
          <StyledTabLimitHeader>Maximum tab count reached</StyledTabLimitHeader>
          <p className="mb-0">To create a new tab or load a saved view, close one of your existing tabs.</p>
        </div>
      </StyledTabLimitMenu>
    );

  const navButton = (
    <StyledNavPopoverButton
      className="justify-content-center align-items-center bp3-add-tab"
      data-testid="add-tab-popover-button"
      icon="plus"
      onClick={e => {
        e.stopPropagation();
        setNavPopoverStatus(!navPopoverStatus);
      }}
      outlined
    />
  );

  const navPopover = (
    <Popover
      boundary="viewport"
      content={navMenu}
      isOpen={navPopoverStatus}
      onInteraction={state => setNavPopoverStatus(state)}
      position={Position.BOTTOM}
      target={navButton}
    />
  );

  return (
    <React.Fragment>
      <StyledTab className="d-flex flex-grow-1 mr-2 overflow-auto">
        {tabsList}
        {!isEditingTemplate && navPopover}
      </StyledTab>
    </React.Fragment>
  );
}

export default observer(HeaderTabs);
