import styled from "@emotion/styled";
import React from "react";
import ChipCounter from "../ChipCounter/ChipCounter";

const StyledCounter = styled(ChipCounter)`
  border-radius: 2px;
`;

type Props = {
  list: string[];
  showCounter?: boolean;
};

function ListCellRenderer(props: Props) {
  const { list, showCounter = false } = props;
  const listTitle = props.list.slice().sort().join(", ");

  return (
    <span title={listTitle}>
      {showCounter && (
        <StyledCounter className="ml-0 mr-1" width={22}>
          {list.length}
        </StyledCounter>
      )}
      {list.join(", ")}
    </span>
  );
}

export default ListCellRenderer;
