import { NonIdealState, Spinner } from "@blueprintjs/core";
import { observer } from "mobx-react";
import React, { useEffect } from "react";

import ErrorBoundary from "shared/components/ErrorBoundary/ErrorBoundary";
import InfluenceHistorySidebar from "modules/InfluenceHistory/InfluenceHistorySidebar/InfluenceHistorySidebar";
import InfluenceHistoryTable from "modules/InfluenceHistory/InfluenceHistoryTable/InfluenceHistoryTable";
import InfluenceHistoryTopBar from "modules/InfluenceHistory/InfluenceHistoryTopBar/InfluenceHistoryTopBar";
import Module from "shared/components/Module/Module";
import { createResizeContextActions } from "shared/components/Resizable/Resizable.utils";
import StyledScrollableContainer from "shared/components/StyledScrollableContainer/StyledScrollableContainer";
import { Status } from "modules/App/Status";
import { useStores } from "store/Store";

function InfluenceHistory() {
  const { influenceHistoryStore } = useStores();
  const { page, mappings } = influenceHistoryStore;

  useEffect(() => {
    if (page.historyTable.status === Status.OUTDATED) {
      influenceHistoryStore.getInfluenceHistoryData();
    }
  }, [page.historyTable.status]);

  if (page.status === Status.INIT) {
    return <div />;
  }

  if (page.status === Status.LOADING || mappings.status === Status.LOADING) {
    return (
      <div className="m-5">
        <Spinner />
      </div>
    );
  }

  const contextActions = createResizeContextActions(page.influenceTimeline.isCollapsed, isCollapsed =>
    influenceHistoryStore.setTimelineCollapsed(isCollapsed)
  );

  return (
    <StyledScrollableContainer className="d-flex flex-column">
      <InfluenceHistoryTopBar />
      <InfluenceHistorySidebar />
      <div className="d-flex flex-column flex-shrink-1 px-2 pt-2 mh-0">
        <Module
          childrenClassName="placeholder-center"
          className="mb-2 flex-shrink-0"
          contextActions={contextActions}
          headerClassName="justify-content-between"
          isCollapsed={page.influenceTimeline.isCollapsed}
          title="Influence Timeline"
        >
          <NonIdealState className="py-5 text-center d-flex align-items-center" icon="timeline-line-chart" />
        </Module>
        <div className="flex-shrink-1 d-flex flex-column mh-0" data-testid="influence-history-table">
          <ErrorBoundary>
            <InfluenceHistoryTable />
          </ErrorBoundary>
        </div>
      </div>
    </StyledScrollableContainer>
  );
}

export default observer(InfluenceHistory);
