import React from "react";
import { observer } from "mobx-react";
import { AnchorButton, Position, Tooltip } from "@blueprintjs/core";
import { useHistory } from "react-router-dom";

import { useStores } from "store/Store";

function EditInfluenceButton() {
  const { influenceHistoryStore, tabsStore } = useStores();
  const history = useHistory();
  const { selectedRows = [] } = influenceHistoryStore.page.historyTable;

  const handleClick = () => {
    const [influenceId] = selectedRows;
    tabsStore.createTabForInfluenceEdit(influenceId);

    history.push(`/influence/${influenceId}/edit`);
  };

  return (
    <Tooltip
      content="Make sure only one Influence is selected in the table below to enable Edit option"
      disabled={selectedRows.length === 1}
      hoverOpenDelay={0}
      position={Position.BOTTOM}
    >
      <AnchorButton
        className="mr-2"
        data-testid="edit-influence-button"
        disabled={selectedRows.length !== 1}
        icon="edit"
        onClick={handleClick}
      >
        Edit Influence
      </AnchorButton>
    </Tooltip>
  );
}

export default observer(EditInfluenceButton);
