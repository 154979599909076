import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import cloneDeep from "lodash.clonedeep";
import clsx from "clsx";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import intersection from "lodash.intersection";
import isEmpty from "lodash.isempty";
import styled from "@emotion/styled";
import { addDays, differenceInDays, format, getTime, startOfToday } from "date-fns";
import { Button, Colors, NonIdealState, Spinner } from "@blueprintjs/core";
import { css, Global } from "@emotion/react";
import { observer } from "mobx-react";
import { renderToString } from "react-dom/server";
import { useDebouncedCallback } from "use-debounce";

import formatValueWithUnit from "shared/helpers/formatValueWithUnit/formatValueWithUnit";
import GraphMenu from "./GraphMenu/GraphMenu";
import GraphOverlaySpinner from "./GraphOverlaySpinner/GraphOverlaySpinner";
import isNumber from "shared/helpers/isNumber/isNumber";
import ResizeElementObserver from "shared/components/Resizable/ResizeElementObserver/ResizeElementObserver";
import SeriesLegend from "shared/components/SeriesLegend/SeriesLegend";
import { dashStylesGraph, metricGroupTypes, metricsTypes, metricUnitTypes } from "modules/App/constants";
import { getMetricsColor } from "modules/BuildCurves/getMetricsColor/metricsColors";
import { GraphTypes, RefObjectForHighchartsReact } from "./Graph.types";
import { numberAdjustedByRest } from "shared/helpers/numberAdjustedByRest/numberAdjustedByRest";
import { Status } from "modules/App/Status";
import { usePrevious } from "shared/helpers/usePrevious/usePrevious";
import { useStores } from "store/Store";
import {
  addExtraClassName,
  chartOptionsConfig,
  createLabelTooltip,
  generateClassName,
  getMaxSeries,
  joinAxes,
  navigatorDataPerGroup,
  removeTooltip,
  removeTrailingZero,
  setChartSize,
  setMaxForGroups,
  transformNavigatorData,
  Y_AXIS_BASE
} from "shared/Graph/Graph.utils";

const oneDay = 24 * 3600 * 1000;

if (process.env.NODE_ENV === "test") {
  // https://api.highcharts.com/class-reference/Highcharts#.useSerialIds
  Highcharts.useSerialIds(true); // eslint-disable-line
}

const StyledSeriesLabel = styled("div")`
  line-height: 1;
  margin: 8px 10px 8px 0;

  .highcharts-yaxis--first-alone & {
    margin-bottom: 20px !important;
  }
`;

Highcharts.setOptions({ time: { useUTC: false } });

function Graph(props: GraphTypes) {
  const {
    allMetrics = [],
    changeDateOption,
    changeSelectedRange,
    chartSpacing = [0, 0, 0, 0],
    className = "",
    columnLabels = {},
    customYAxis = {},
    data = {},
    displayGraphMenu,
    fetchData,
    isNdo,
    maxGrouped = [],
    mergedGroups = [],
    metricPositions = {},
    percentGroup = [],
    range,
    reversedAxis = [],
    selectedRange,
    series = [],
    status,
    today = getTime(startOfToday()),
    toggleGraphMenu,
    tooltipLabels = columnLabels || {},
    isMiles
  } = props;

  const { systemSettingsStore } = useStores();
  const { computedDateFormat, dateDelimiter } = systemSettingsStore;
  const [leftPositionMenu, setLeftPositionMenu] = useState(0);
  const [graphKey, setGraphKey] = useState(0);
  const [updateGraphAfterUpdate, setUpdatingGraphAfterUpdate]: [boolean | null, Function] = useState(true);
  const [chartConfig, setChartConfig] = useState({ xAxis: [{}] });
  const [isMenuOptionOpen, setIsMenuOptionOpen] = useState(false);
  const isInitialized = useRef(false);
  const chartRef = useRef<RefObjectForHighchartsReact>(null);

  const newTooltips = cloneDeep(tooltipLabels);

  const previousTransformToMile = usePrevious(isMiles);
  const previousChartRef = usePrevious(chartRef.current);

  const debouncedSettingMenuPosition = useDebouncedCallback(
    chartConfig => setLeftPositionMenu(chartConfig.plotLeft || 0),
    500,
    { maxWait: 1000 }
  );

  const maxSeries = useMemo(() => {
    if (chartRef?.current?.chart) {
      return getMaxSeries(chartRef.current.chart) || {};
    }
    return {};
  }, [chartRef?.current?.chart]);

  const getActiveSeriesGroups: (string | string[])[][] = useMemo(
    () => series.filter(group => group[1] && group[1].length),
    [series]
  );

  const setMaxSeries = (chart: RefObjectForHighchartsReact["chart"]) => {
    setUpdatingGraphAfterUpdate(true);

    if (chart.yAxis) {
      navigatorDataPerGroup(chart, getActiveSeriesGroups, maxSeries, data, reversedAxis);
    }

    setGraphKey(prevState => prevState + 1);

    setUpdatingGraphAfterUpdate(false);
  };

  useEffect(() => {
    if (!previousChartRef && chartRef.current) {
      setMaxSeries(chartRef.current.chart);
    }
  }, [previousChartRef, chartRef.current]);

  // label are removed because it duplicates group name
  newTooltips.sellingPrice = "";
  newTooltips.pricePercentile = "";

  useLayoutEffect(() => {
    if (!isInitialized.current) {
      // fetch data on first mount if there is no data
      if (fetchData && status !== Status.LOADING && !Object.keys(data).length) {
        fetchData();
      }
      isInitialized.current = true;
      return;
    }

    // fetch data on deps change - for now unused
    if (fetchData) {
      fetchData();
    }
  }, []);

  useEffect(() => {
    if (
      previousTransformToMile !== null &&
      previousTransformToMile !== isMiles &&
      chartRef.current &&
      chartRef?.current?.chart?.yAxis
    ) {
      chartRef.current.chart.yAxis.forEach(yaxis => {
        if (
          chartRef?.current?.chart &&
          (yaxis?.options?.title?.text?.includes("RASM") || yaxis?.options?.title?.text?.includes("RASK"))
        ) {
          setGraphKey(graphKey + 1);
        }
      });
    }
  }, [isMiles]);

  const selectedSeries = series.map(group => group[1]).flat();
  if (isEmpty(selectedSeries)) {
    return (
      <div className="m-5 w-100">
        <NonIdealState icon="geosearch" title="No series selected" />
      </div>
    );
  }

  if (status === Status.INIT) {
    return <div className="m-5" />;
  }

  if (status === Status.LOADING) {
    return (
      <div className="w-100">
        <Spinner />
      </div>
    );
  }

  if (status === Status.ERROR) {
    return (
      <div className="my-5 w-100">
        <NonIdealState
          action={
            <Button icon="refresh" onClick={() => fetchData && fetchData()}>
              Retry Now
            </Button>
          }
          description="Please retry if possible. If this problem reoccurs, please contact FLYR."
          icon="issue"
          title="Error getting data"
        />
      </div>
    );
  }

  const isEmptyXAxis = isEmpty(isNdo ? data.ndo : data.date);
  if (isEmptyXAxis) {
    return (
      <div className="w-100">
        <NonIdealState
          description="Please change your search criteria."
          icon="geosearch"
          title="No data matching filters"
        />
      </div>
    );
  }

  const getSeriesIndex = (groupName: string, metricName: string) => {
    const group = allMetrics.find(group => group[0] === groupName);

    if (group && !isEmpty(group) && Array.isArray(group[1])) {
      const groupMetrics = group[1].map(metric => metric.key);
      return groupMetrics.indexOf(metricName);
    }
    return null;
  };

  function xAxisNdoFormatter(value: number) {
    return value;
  }

  function xAxisDateFormatter(value) {
    // convert milliseconds time to 30/8
    if (isNumber(value)) {
      const isDayFirst = computedDateFormat.toLowerCase().indexOf("d") < computedDateFormat.toLowerCase().indexOf("m");
      const formattedDate = format(new Date(value), isDayFirst ? `d${dateDelimiter}M` : `M${dateDelimiter}d`);
      const formattedFullDate = format(
        new Date(value),
        isDayFirst ? `d${dateDelimiter}M${dateDelimiter}yy` : `yy${dateDelimiter}M${dateDelimiter}d`
      );

      return `<span data-full-date="${formattedFullDate}">${formattedDate}</span>`;
    }

    return value;
  }

  // used when selecting range via chart
  function afterSetExtremes() {
    if (!selectedRange || !changeSelectedRange) {
      return;
    }

    setTimeout(() => {
      const { dataMin, dataMax, min, max } = this;
      const { start: startRange, end: endRange } = range;
      let newStart;
      let newEnd;

      if (isNdo) {
        newStart = dataMax - Math.round(max) + dataMin;
        newEnd = dataMax - Math.round(min) + dataMin;
      } else {
        newStart =
          startRange && startRange > differenceInDays(new Date(min), today)
            ? startRange
            : differenceInDays(new Date(min), today);
        newEnd =
          endRange && endRange < differenceInDays(new Date(max), today)
            ? endRange
            : differenceInDays(new Date(max), today);
      }

      changeSelectedRange({ end: Math.max(newStart, newEnd), start: Math.min(newStart, newEnd) });
    });
  }

  const generateTodayLine = () => {
    const dataToday = isNdo ? data.todayNdo : getTime(data.today);
    return [
      {
        className: "today-plot-line",
        color: `${Colors.BLUE4}80`, // 50% opacity
        dashStyle: "dash",
        value: dataToday,
        width: 1,
        zIndex: 2
      }
    ];
  };

  const generateXAxis = () => {
    return {
      crosshair: { color: Colors.GRAY2, dashStyle: "dash", width: 1 },
      events: { afterSetExtremes },
      gridLineColor: Colors.LIGHT_GRAY3,
      gridLineWidth: 1,
      labels: {
        align: "center",
        autoRotation: false,
        formatter: (val: { value: number }) => (isNdo ? xAxisNdoFormatter(val.value) : xAxisDateFormatter(val.value)),
        style: { color: Colors.GRAY2, fontSize: "11px", whiteSpace: "nowrap" },
        useHTML: true,
        y: 17
      },
      lineWidth: 1,
      minRange: isNdo ? 5 : 4 * oneDay,
      minTickInterval: isNdo ? 1 : oneDay,
      offset: 0,
      ordinal: true,
      plotLines: generateTodayLine(),
      reversed: isNdo,
      startOnTick: false,
      tickPixelInterval: 80,
      tickWidth: 0,
      title: {
        align: "low",
        offset: 0,
        rotation: 0,
        style: { color: Colors.GRAY2, fontSize: "10px", fontWeight: 500 },
        text: "",
        x: 0,
        y: 0
      },
      type: isNdo ? "linear" : "datetime",
      ...(isNdo ? { max: Math.max(...data.ndo), min: Math.min(...data.ndo) } : {})
    };
  };

  const formatYAxisLabel = (
    params: {
      axis: { paddedTicks: []; defaultLabelFormatter: { call: Function } };
      isFirst: boolean;
      isLast: boolean;
      value: number | string;
    },
    groupName: string,
    reversed: boolean
  ) => {
    if ((reversed && !params.isFirst) || (!reversed && !params.isLast)) {
      const { value } = params;

      if (value && typeof params.value === "number") {
        return numberAdjustedByRest(params);
      }
      return params.axis.defaultLabelFormatter.call(params);
    }

    return undefined;
  };

  const getIsLacGridActive = () => {
    const activeGroups = getActiveSeriesGroups.map(group => group[0]);

    // LAC grid is active when LAC is active and percentage series are not
    // in other words - grid priority is: percentage, LAC, all the rest
    return activeGroups.includes("lacGroup") && !intersection(percentGroup, activeGroups).length;
  };

  const generateYAxisScale = (groupName: string) => {
    const isLacGridActive = getIsLacGridActive();

    if (groupName === "lacGroup") {
      return {
        gridLineWidth: isLacGridActive ? 1 : 0
      };
    }
    if (percentGroup.includes(groupName)) {
      return {
        gridLineWidth: isLacGridActive ? 0 : 1,
        isPercentScale: true,
        max: 110
      };
    }

    return { gridLineWidth: isLacGridActive ? 0 : 1 };
  };

  const generateYAxisTitle = (groupName: string, groupMetrics: string[]) => {
    const squares = groupMetrics.map(metricName => (
      <SeriesLegend
        key={groupName}
        className="highcharts-axis-series-legend"
        group={groupName}
        index={getSeriesIndex(groupName, metricName)}
        legendTitle={columnLabels[metricName]}
      />
    ));

    const groupUnit = metricUnitTypes[metricGroupTypes[groupName]];
    const seriesTitle = `${columnLabels[groupName]} ${groupUnit || ""}`;

    return renderToString(
      <StyledSeriesLabel>
        {seriesTitle}
        {squares}
      </StyledSeriesLabel>
    );
  };

  const generateYAxis = (groupName: string, groupMetrics: string[]) => {
    const text = generateYAxisTitle(groupName, groupMetrics);
    const customProps = customYAxis[groupName] || {};

    const { reversed = false, labels = {}, ...otherCustomProps } = customProps;
    const className = clsx({
      "y-axis-reversed": reversed,
      [Y_AXIS_BASE.className]: Y_AXIS_BASE.className
    });

    return {
      ...Y_AXIS_BASE,
      ...generateYAxisScale(groupName),
      className,
      groupName: [groupName], // same as above
      labels: {
        ...Y_AXIS_BASE.labels,
        formatter: params => formatYAxisLabel(params, groupName, reversed),
        ...labels
      },
      reversed,
      title: { ...Y_AXIS_BASE.title, text },
      ...otherCustomProps
    };
  };

  const rotateEvenAxes = (yAxis: { groupName: string[] }[]) => {
    const newYAxis = cloneDeep(yAxis);

    /* eslint-disable no-param-reassign */
    newYAxis.forEach((axis, index) => {
      const groupPosition = metricPositions[axis.groupName[0]];
      const isOpposite = typeof groupPosition === "string" ? groupPosition === "right" : index % 2;

      axis.opposite = isOpposite;

      if (isOpposite) {
        axis.labels = {
          ...axis.labels,
          align: "left",
          x: 6 // margin between chart and number on right side
        };
        axis.title = {
          ...axis.title,
          text: `<div class="highcharts-axis-even">${axis.title.text}</div>`
        };
      }
      /* eslint-enable no-param-reassign */
    });

    return newYAxis;
  };

  const generateSeries = () => {
    const graphSeries: {
      color: string;
      dashStyle: string;
      data: [];
      key: string;
      name: string;
      navigatorOptions: {};
      parent: string;
      showInNavigator: boolean;
      yAxis: number;
    }[] = [];

    // generate and optimize y-axes
    const allYAxis: { groupName: string[]; title: { text: string } }[] = getActiveSeriesGroups
      .map(group => {
        const [groupName, groupMetrics] = group as [string, string[]];

        const groupMetricsWithData =
          (Array.isArray(groupMetrics) && groupMetrics.filter(metricName => data[metricName])) || [];

        return !isEmpty(groupMetricsWithData) ? generateYAxis(groupName, groupMetricsWithData) : undefined;
      })
      .filter(Boolean);

    const yAxis = (allYAxis && addExtraClassName(rotateEvenAxes(joinAxes(allYAxis, mergedGroups)))) || [];

    // generate series
    getActiveSeriesGroups.forEach(group => {
      const [groupName, groupMetrics] = group as [string, string[]];

      const groupNameYAxis = yAxis.find(axis => axis.groupName.includes(groupName));
      const axisIndex = groupNameYAxis ? yAxis.indexOf(groupNameYAxis) : -1;

      Array.isArray(groupMetrics) &&
        axisIndex !== -1 &&
        groupMetrics.forEach(metricName => {
          if (!data[metricName]) {
            return;
          }

          const index = getSeriesIndex(groupName, metricName) || 0;
          const color = getMetricsColor(groupName, index);
          const dataSeries = data[metricName].map(([timestamp, y]) => ({
            className: isNdo ? data[metricName] : generateClassName(timestamp, today),
            x: timestamp,
            y
          }));

          const graphData = isNdo ? dataSeries.slice().reverse() : dataSeries;
          const isSeriesReversed = reversedAxis.includes(groupName);

          graphSeries.push({
            color,
            dashStyle: dashStylesGraph[index],
            data: graphData,
            key: metricName,
            name: columnLabels[metricName] || metricName,
            navigatorOptions: {
              // unify values to be relative 0-100% because for navigator to be readable and useful we align it to each one per axis scale
              data: transformNavigatorData(graphData, isSeriesReversed, maxSeries[groupName]),
              type: "line"
            },
            parent: groupName,
            showInNavigator: true,
            yAxis: axisIndex
          });
        });
    });

    return {
      graphSeries,
      yAxis
    };
  };

  const { graphSeries, yAxis } = generateSeries();

  if (isEmpty(yAxis) || graphSeries.every(axis => isEmpty(axis.data))) {
    return (
      <div className="w-100">
        <NonIdealState
          description="Please change your search or series criteria."
          icon="geosearch"
          title="No data matching"
        />
      </div>
    );
  }

  function tooltipFormatter() {
    const { points } = this;

    if (!points.length) return "";

    const xValue = points[0].x;

    const yValues = points.map(point => {
      const series: { key: string; parent: string } = graphSeries[point.series.index];
      const typeFilter = metricsTypes[series.key];

      const parentLabel = newTooltips[series.parent];
      const label = newTooltips[series.key];

      return (
        <div key={series.key} className="d-flex align-items-center justify-content-between mb-1">
          <div>
            <SeriesLegend
              className="mr-1"
              group={series.parent}
              index={getSeriesIndex(series.parent, series.key)}
              size={12}
            />
            <span className="mr-2">
              {parentLabel} {label ? `(${label})` : ""}
            </span>
          </div>
          <span className="font-weight-bold">{formatValueWithUnit(point.y, typeFilter)}</span>
        </div>
      );
    });

    return renderToString(
      <div className="mx-2">
        <div className="mb-2">
          {isNdo
            ? `NDO: ${xValue} ${xValue === data.todayNdo ? "(today)" : ""}`
            : `${format(xValue, computedDateFormat)} ${xValue === getTime(data.today) ? "(today)" : ""}`}
        </div>
        <div>{yValues}</div>
      </div>
    );
  }

  const generateTooltip = () => {
    return {
      animation: false,
      enabled: true,
      formatter: tooltipFormatter,
      outside: true,
      shared: true,
      split: false,
      style: { border: 0, color: "white", zIndex: 8 },
      useHTML: true
    };
  };

  const setMenuVisibility = (visibility: boolean) => {
    setIsMenuOptionOpen(visibility);

    if (chartRef?.current?.chart?.tooltip) {
      // @ts-ignore
      const newTooltipConfig = chartRef.current.chart.tooltip.options.userOptions;
      newTooltipConfig.enabled = !visibility;

      chartRef.current.chart.tooltip.update(newTooltipConfig);
    }
  };

  const chartTooltips = chart => {
    chart.yAxis.forEach(yAxis => {
      const title = yAxis.axisTitle;
      const elements: { addEventListener: Function; getBoundingClientRect: Function }[] =
        (title && title.element && title.element.getElementsByClassName("highcharts-axis-series-legend")) || [];
      const isOpposite = yAxis.opposite;

      Array.from(elements).forEach((element, index) => {
        const elementPosition = element.getBoundingClientRect();
        element.addEventListener("mouseover", () => {
          const {
            options: { key, parent }
          } = yAxis.series[index];
          const labelTitle = `${tooltipLabels[parent]} (${tooltipLabels[key]})`;

          createLabelTooltip(chart, elementPosition, labelTitle, isOpposite);
        });
        element.addEventListener("mouseout", removeTooltip);
      });

      removeTrailingZero(yAxis);
    });
  };

  const adjustTime = (numberOfDays: number) => getTime(addDays(today, numberOfDays));

  const adjustExtremes = chart => {
    if (chart && selectedRange && selectedRange.start !== undefined && selectedRange.end !== undefined) {
      const { xAxis } = chart;
      if (isNdo) {
        const ndoMax = Math.max(...data.ndo);
        const ndoMin = Math.min(...data.ndo);
        xAxis[0].setExtremes(
          Math.min(Math.abs(ndoMax - selectedRange.start) + ndoMin, Math.abs(ndoMax - selectedRange.end) + ndoMin),
          Math.max(Math.abs(ndoMax - selectedRange.start) + ndoMin, Math.abs(ndoMax - selectedRange.end) + ndoMin)
        );
      } else {
        xAxis[0].setExtremes(adjustTime(selectedRange.start), adjustTime(selectedRange.end));
      }
    }
  };

  adjustExtremes(chartRef.current?.chart);

  const chartOptions = {
    ...chartOptionsConfig,
    chart: {
      ...chartOptionsConfig.chart,
      events: {
        load() {
          chartTooltips(this);
          setChartSize(this);
        },
        redraw() {
          chartTooltips(this);
          debouncedSettingMenuPosition.callback(this);
        },
        resize() {
          chartTooltips(this);
        }
      },
      spacing: chartSpacing
    },
    navigator: {
      ...chartOptionsConfig.navigator,
      xAxis: {
        ...chartOptionsConfig.navigator.xAxis,
        labels: {
          ...chartOptionsConfig.navigator.xAxis.labels,
          formatter: (val: { value: number }) => (isNdo ? xAxisNdoFormatter(val.value) : xAxisDateFormatter(val.value))
        },
        reversed: isNdo
      }
    },
    series: graphSeries,
    tooltip: generateTooltip(),
    xAxis: generateXAxis(),
    yAxis
  };

  const callbackHighcharts = chart => {
    setMaxForGroups(chart, maxGrouped);
    setLeftPositionMenu(chart.plotLeft || 0);
    setChartConfig(chart);
  };

  return (
    <div key={graphKey} className={className}>
      <Global
        styles={css`
          // tooltip
          .highcharts-tooltip-label,
          .highcharts-tooltip .highcharts-tooltip-box:first-of-type {
            display: block;
            border-radius: 3px;
            box-shadow: 0 0 0 ${Colors.BLACK}1A, 0 2px 4px ${Colors.BLACK}33, 0 8px 24px ${Colors.BLACK}33;
          }

          .highcharts-tooltip-label {
            background-color: ${Colors.DARK_GRAY5}E6;
            padding: 4px 8px;
            position: absolute;
            z-index: 4;
            &__title {
              color: ${Colors.WHITE};
              font-size: 10px;
              font-weight: 400;
            }
          }

          .highcharts-tooltip {
            cursor: default;
            pointer-events: none;
            transition: stroke 150ms;
            white-space: nowrap;

            .highcharts-tooltip-box {
              display: none;
            }
            .highcharts-tooltip-box:first-of-type {
              fill: ${Colors.DARK_GRAY5};
              fill-opacity: 0.9;
              stroke-width: 1px;
            }
          }

          .highcharts-reset-zoom {
            display: none;
          }
        `}
      />

      {updateGraphAfterUpdate && <GraphOverlaySpinner />}

      <ResizeElementObserver onChange={width => chartRef?.current?.chart.setSize(width, undefined, false)}>
        <HighchartsReact
          ref={chartRef}
          allowChartUpdate={false}
          callback={callbackHighcharts}
          constructorType="stockChart"
          highcharts={Highcharts}
          options={chartOptions}
        />
        <GraphMenu
          changeDateOption={changeDateOption}
          chartConfig={chartConfig}
          displayGraphMenu={displayGraphMenu}
          isMenuOptionOpen={isMenuOptionOpen}
          isNdo={isNdo}
          leftPositionMenu={leftPositionMenu}
          setIsMenuOptionOpen={setMenuVisibility}
          toggleGraphMenu={toggleGraphMenu}
        />
      </ResizeElementObserver>
    </div>
  );
}

export default observer(Graph);
