import React from "react";
import { observer } from "mobx-react";

import { useStores } from "store/Store";
import getGroupItemsForFilter from "shared/helpers/getGroupItemsForFilter/getGroupItemsForFilter";
import isSidebarFilterVisible from "shared/helpers/isSidebarFilterVisible/isSidebarFilterVisible";
import ButtonSelector from "shared/components/ButtonSelector/ButtonSelector";
import SidebarFilter from "shared/components/Sidebar/SidebarFilter/SidebarFilter";
import SidebarDaySelect from "shared/components/Sidebar/SidebarDaySelect/SidebarDaySelect";
import SidebarDateRangeSelect from "shared/components/Sidebar/SidebarDateRangeSelect/SidebarDateRangeSelect";
import SidebarHourSelect from "shared/components/Sidebar/SidebarHourSelect/SidebarHourSelect";
import SidebarSelect from "shared/components/Sidebar/SidebarSelect/SidebarSelect";
import { Tab } from "models/Tab/Tab.model";
import { MetricFilter } from "modules/Explore/metrics/metrics";

type Props = {
  filters: MetricFilter[];
  pageContext: Tab;
};

function CirrusFilters(props: Props) {
  const { pageContext } = props;
  const { systemSettingsStore, cirrusMappingsStore } = useStores();
  const { computedDateFormat } = systemSettingsStore;
  const { cabinClasses, getFlightNumbers } = cirrusMappingsStore;
  const { changeFilter, disabledFilters, filters, sidebar } = pageContext;

  const [flightNumbers, matchingFlightNumbers] = getGroupItemsForFilter(filters.owMarket, getFlightNumbers);
  const filterKeys = props.filters.map(({ key }) => key);

  return (
    <>
      {filterKeys.includes("owMarket") && (
        <SidebarFilter
          isTemporarilyDisabled={"owMarket" in disabledFilters}
          isVisible={isSidebarFilterVisible("ow market one-way market", "owMarket", sidebar)}
        >
          <SidebarSelect
            items={[{ groupItems: cirrusMappingsStore.owMarkets, label: "All Markets" }]}
            onChange={filterValue => changeFilter("owMarket", filterValue)}
            selectedItems={filters.owMarket}
            title="OW Market"
            withAutoSeparatedItems
          />
        </SidebarFilter>
      )}
      {filterKeys.includes("flightNumber") && (
        <SidebarFilter
          isTemporarilyDisabled={"flightNumber" in disabledFilters}
          isVisible={isSidebarFilterVisible("flight number", "flightNumber", sidebar)}
        >
          <SidebarSelect
            fuzzySearch
            items={[
              { groupItems: matchingFlightNumbers, label: "Matching pre-selected" },
              { groupItems: flightNumbers, label: "All flights" }
            ]}
            onChange={filterValue => changeFilter("flightNumber", filterValue)}
            selectedItems={filters.flightNumber}
            title="Flight Number"
          />
        </SidebarFilter>
      )}
      {filterKeys.includes("depTime") && (
        <SidebarFilter
          isTemporarilyDisabled={"depTime" in disabledFilters}
          isVisible={isSidebarFilterVisible("departure time", "depTime", sidebar)}
        >
          <SidebarHourSelect
            hours={filters.depTime}
            onChange={filterValue => changeFilter("depTime", filterValue)}
            title="Departure Time"
          />
        </SidebarFilter>
      )}
      {filterKeys.includes("cabinClass") && (
        <SidebarFilter
          isTemporarilyDisabled={"cabinClass" in disabledFilters}
          isVisible={isSidebarFilterVisible("cabin class", "cabinClass", sidebar)}
        >
          <ButtonSelector
            items={cabinClasses}
            onSelect={filterValue => changeFilter("cabinClass", filterValue)}
            selectedItems={filters.cabinClass}
            title="Cabin Class"
          />
        </SidebarFilter>
      )}
      {filterKeys.includes("depDow") && (
        <SidebarFilter
          isTemporarilyDisabled={"depDow" in disabledFilters}
          isVisible={isSidebarFilterVisible("departure day of week", "depDow", sidebar)}
        >
          <SidebarDaySelect
            onChange={filterValue => changeFilter("depDow", filterValue)}
            selectedItems={filters.depDow}
            title="Departure Day of Week"
          />
        </SidebarFilter>
      )}

      {filterKeys.includes("depDate") && (
        <SidebarFilter
          isTemporarilyDisabled={"depDate" in disabledFilters}
          isVisible={isSidebarFilterVisible("departure date", "depDate", sidebar)}
        >
          <SidebarDateRangeSelect
            computedDateFormat={computedDateFormat}
            dates={filters.depDate}
            onChange={filterValue => changeFilter("depDate", filterValue)}
            title="Departure Date"
          />
        </SidebarFilter>
      )}
      {filterKeys.includes("cirrusStatus") && (
        <SidebarFilter
          isTemporarilyDisabled={"cirrusStatus" in disabledFilters}
          isVisible={isSidebarFilterVisible("cirrus on/off", "cirrusStatus", sidebar)}
        >
          <ButtonSelector
            isMultiple={false}
            items={[
              { label: "On", value: true },
              { label: "Off", value: false }
            ]}
            onSelect={filterValue => changeFilter("cirrusStatus", filterValue)}
            selectedItems={[filters.cirrusStatus]}
            title="Cirrus On/Off"
          />
        </SidebarFilter>
      )}
    </>
  );
}

export default observer(CirrusFilters);
