import { format, getTime, parseISO } from "date-fns";
import { observer } from "mobx-react";
import { Cell } from "react-table";
import { daysOfWeek } from "modules/App/constants";
import isNumber from "../../../helpers/isNumber/isNumber";

import numberFormat from "../../../helpers/numberFormat/numberFormat";
import { useStores } from "store/Store";
import { CellType } from "types/DataTable.types";
import {
  renderBasePreview,
  renderCurrency,
  renderMinMax,
  renderPercent
} from "../DataTableCellRenderers/DataTableCellRenders";

const numberFormatter = numberFormat(0, 0);
const numberPreciseFormatter = numberFormat(3, 3);
const percentFormatter = numberFormat(undefined, 1, "percent");
const percentRelativeFormatter = numberFormat(undefined, 1, "percent", "exceptZero");

type Props = {
  options?: Cell["value"];
  type?: CellType;
  value: any;
  withFixedNumber?: boolean;
};

function DataTableCellValue(props: Props) {
  const { type, value = "", options = {}, withFixedNumber = false } = props;
  const { systemSettingsStore } = useStores();
  const { computedDateFormat, computedDateMonthFormat } = systemSettingsStore;

  if (type && type.includes("influence")) {
    // influence, influence-currency, influence-percent
    return renderBasePreview(value, type);
  }

  switch (type) {
    case CellType.Date: {
      return value && format(getTime(parseISO(value)), computedDateFormat);
    }
    case CellType.DateMonth: {
      return value && format(getTime(parseISO(value)), computedDateMonthFormat);
    }
    case CellType.Integer: {
      return Math.round(value);
    }
    case CellType.DayWeekLong: {
      if (!isNumber(value)) {
        return value;
      }

      return daysOfWeek[value] && daysOfWeek[value]?.long;
    }
    case CellType.Quarter: {
      return `${value.substring(0, 4)} ${value.substring(4)}`;
    }
    case CellType.Currency: {
      return renderCurrency(value, CellType.Currency, numberFormatter);
    }
    case CellType.CurrencyPrecise: {
      return renderCurrency(value, CellType.CurrencyPrecise, numberPreciseFormatter);
    }
    case CellType.Percent: {
      return renderPercent(value, CellType.Percent, percentFormatter, withFixedNumber, options);
    }
    case CellType.PercentRelative: {
      return renderPercent(value, CellType.PercentRelative, percentRelativeFormatter, withFixedNumber, options);
    }
    case CellType.MinMax: {
      return renderMinMax(value, options.withLimits);
    }
    case CellType.String:
    default: {
      return value;
    }
  }
}

export default observer(DataTableCellValue);
