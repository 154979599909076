// @flow

import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import styled from "@emotion/styled";

import { useStores } from "store/Store";

const StyledDot = styled("span")`
  display: inline-block;
  text-decoration: none;
`;

type Props = {
  tab: Tab
};

function UnsavedTabIndicator({ tab }: Props) {
  const { templatesStore } = useStores();
  const { status, savedAnalyses, systemAnalyses, teamAnalyses, checkTabUnsaved } = templatesStore;
  const [isUnsaved, setUnsaved] = useState(false);

  const stringifiedSavedAnalyses = JSON.stringify(savedAnalyses);
  const stringifiedSystemAnalyses = JSON.stringify(systemAnalyses);
  const stringifiedTeamAnalyses = JSON.stringify(teamAnalyses);
  const stringifiedTab = JSON.stringify(tab);

  useEffect(() => {
    setUnsaved(checkTabUnsaved(tab.id));
  }, [
    checkTabUnsaved,
    status,
    stringifiedSavedAnalyses,
    stringifiedSystemAnalyses,
    stringifiedTab,
    stringifiedTeamAnalyses,
    tab.id
  ]);

  const isEditMode = tab.isEditingTemplate || tab.isEditingInfluence;

  if (isEditMode || !isUnsaved) {
    return null;
  }

  return (
    <StyledDot className="ml-2" data-testid="tab-unsaved-indicator">
      •
    </StyledDot>
  );
}

export default observer(UnsavedTabIndicator);
