// @flow

import React, { RefObject } from "react";
import { observer } from "mobx-react";
import clsx from "clsx";
import pluralize from "pluralize";
import styled from "@emotion/styled";

import Module from "shared/components/Module/Module";
import ModuleTypeSelect from "./ModuleTypeSelect";
import { Status } from "modules/App/Status";
import { useStores } from "store/Store";
import { WidgetExtractedStatusRender } from "./Dashboard.utils";

const StyledMarketsModule = styled("div")`
  &.-center-module {
    [data-testid="markets-module-loading"],
    [data-testid="markets-module-error"],
    [data-testid="markets-module-is-empty"] {
      display: flex;
      height: 100%;
      justify-content: center;
    }
  }
`;

type Props = {
  children: React.ReactElement | null;
  forwardedRef: RefObject<any>;
};

function MarketsModule(props: Props) {
  const { children = null, forwardedRef } = props;
  const { dashboardMarketsStore, analysisMappingsStore } = useStores();
  const {
    changeSelectedCabinClassMetric,
    changeSelectedMetric,
    flightsCount,
    selectedCabinClass,
    selectedMetric,
    sizeData
  } = dashboardMarketsStore;

  const isEmpty = flightsCount.numberOfRows === 0;
  const isFlightsCountDone = flightsCount.status === Status.DONE;
  const isFlightsCountError = flightsCount.status === Status.ERROR;
  const isUndefined = flightsCount.numberOfRows === undefined;

  const withVerticalCenter = (isEmpty && isFlightsCountDone) || isUndefined || isFlightsCountError;
  const withCenteredText = (isEmpty && isFlightsCountDone) || !isFlightsCountDone;
  const cabinClasses = analysisMappingsStore.getCabinClasses().map(value => ({ label: value, value }));

  const fetchWithNewDataSize = (onChange: Function, type: string) => {
    const isBiggerDevice = forwardedRef.current?.clientHeight && forwardedRef.current.clientHeight > 1200;
    onChange(type, isBiggerDevice ? 40 : undefined);
  };

  const selectors = (
    <>
      <ModuleTypeSelect
        buttonClassName="font-weight-bold"
        className="mx-2 markets-module-cabin-select"
        customId="cabin-class-selector"
        onChange={type => fetchWithNewDataSize(changeSelectedCabinClassMetric, type)}
        selected={selectedCabinClass[0]}
        selectOptions={cabinClasses}
      />
      <ModuleTypeSelect
        className="markets-module-type-select"
        onChange={type => fetchWithNewDataSize(changeSelectedMetric, type)}
        selected={selectedMetric}
      />
    </>
  );

  return (
    <StyledMarketsModule
      ref={forwardedRef}
      className={clsx("d-flex flex-column flex-shrink-1 mh-0 h-100", { "-center-module": withVerticalCenter })}
    >
      <Module
        childrenClassName="d-flex flex-column h-100"
        className={clsx("flex-shrink-1 ", { "h-100": withCenteredText, "h-auto": !withCenteredText })}
        minHeight={0}
        subtitle={
          isFlightsCountDone ? [`· ${sizeData} of ${pluralize("market", flightsCount.numberOfRows, true)}`] : []
        }
        title="Markets"
        titleTools={selectors}
      >
        <WidgetExtractedStatusRender isEmpty={isEmpty} status={flightsCount.status} type="markets-module">
          {children}
        </WidgetExtractedStatusRender>
      </Module>
    </StyledMarketsModule>
  );
}

export default observer(MarketsModule);
