import { useParams } from "react-router-dom";
import { useStores } from "store/Store";
import { Tab } from "models/Tab/Tab.model";

export function useCurrentTab(): Tab {
  const { tabId } = useParams<{ tabId: string }>();
  const { tabsStore } = useStores();

  return tabsStore.tabsById[tabId];
}
