import styled from "@emotion/styled";
import { Colors } from "@blueprintjs/core";
import { observer } from "mobx-react";
import { Row } from "react-table";
import React, { ReactElement } from "react";

const StyledTableRow = styled("tr")<{ isSelected: boolean; isNested: boolean }>`
  background: ${({ isSelected, isNested }) => {
    if (isSelected) return "#E1F1FD";
    if (isNested) return Colors.LIGHT_GRAY5;
    return Colors.WHITE;
  }};

  &:hover {
    background: #fff4db;
    cursor: ${({ onClick }) => (onClick ? "pointer" : "normal")};
  }
`;

type Props = {
  children: ReactElement;
  row: Row;
  onRowClick?: (row: Row) => void;
  isSelected: boolean;
  isNested: boolean;
};

function DataTableRow(props: Props) {
  const { children, row, onRowClick, isSelected, isNested } = props;

  const additionalRowProps = {
    ...row.getRowProps(),
    ...(isNested && { className: "nested" })
  };

  return (
    <StyledTableRow
      data-testid="data-table-row"
      isNested={isNested}
      isSelected={isSelected}
      onClick={onRowClick ? () => onRowClick(row) : undefined}
      {...additionalRowProps}
    >
      {children}
    </StyledTableRow>
  );
}

export default observer(DataTableRow);
