import { NonIdealState } from "@blueprintjs/core";
import { observer } from "mobx-react";
import React from "react";

import ErrorBoundary from "shared/components/ErrorBoundary/ErrorBoundary";
import ExploreBuildCurves from "modules/BuildCurves/ExploreBuildCurves/ExploreBuildCurves";
import PivotTable from "components/PivotTable/PivotTable";
import Resizable from "shared/components/Resizable/Resizable.component";
import { createResizeContextActions } from "shared/components/Resizable/Resizable.utils";
import ResizableRow from "shared/components/Resizable/ResizableRow/ResizableRow.component";

import { useStores } from "store/Store";
import { TabPreferences } from "types/Tab.types.js";

type Props = {
  tabId: string;
};

function Explore(props: Props) {
  const { tabId } = props;
  const { tabsStore } = useStores();
  const tab = tabsStore.tabsById[tabId];
  const minWidgetWidth = 580;
  const minFlightsTableHeight = 340;

  const modulePreferences: TabPreferences["modules"] = tab.preferences?.modules || {};
  const contextActions = createResizeContextActions(!!modulePreferences?.pivotTable?.isCollapsed, isCollapsed =>
    tab.setModuleState("pivotTable", { isCollapsed })
  );

  return (
    <ResizableRow
      collapsed={modulePreferences?.buildCurves?.isCollapsed && modulePreferences.pivotTable?.isCollapsed}
      defaultHeight={modulePreferences?.exploreTopRow?.height || 394}
      enable={{ bottom: true }}
      maxHeight={`calc(100% - ${minFlightsTableHeight}px)`}
      minHeight={394}
      moduleKey="exploreTopRow"
      tab={tab}
    >
      <Resizable
        collapseOn="left"
        defaultPercentWidth={50}
        enable={{ right: true }}
        icon="pivot-table"
        maxWidth={`calc(100% - ${minWidgetWidth}px)`}
        minWidth={minWidgetWidth}
        moduleKey="pivotTable"
        tab={tab}
      >
        {
          // @ts-ignore
          <PivotTable
            contextActions={contextActions}
            isCollapsed={modulePreferences?.pivotTable?.isCollapsed}
            minHeight={0}
            tab={tab}
            title="Pivot Table"
          >
            <NonIdealState className="text-center" icon="pivot-table" />
          </PivotTable>
        }
      </Resizable>
      <Resizable collapseOn="right" icon="chart" minWidth={minWidgetWidth} moduleKey="buildCurves" tab={tab}>
        <ErrorBoundary>
          <ExploreBuildCurves tabId={tabId} />
        </ErrorBoundary>
      </Resizable>
    </ResizableRow>
  );
}

export default observer(Explore);
