import React, { useState } from "react";
import { observer } from "mobx-react";
import { Button, Classes, Dialog, TextArea } from "@blueprintjs/core";
import styled from "@emotion/styled";
import { useStores } from "../../../store/Store";

export const DialogFooter = styled("div")`
  box-shadow: 0 -1px 0 rgba(16, 22, 26, 0.15);
`;

function DeleteInfluenceModal() {
  const { modalStore } = useStores();
  const { deleteInfluences, selectedRows = [] } = modalStore.data;

  const [deleteComment, setDeleteComment] = useState("");

  const influencesCountText = selectedRows.length === 1 ? "Influence" : "Influences";

  return (
    <Dialog
      className="p-0"
      isOpen={modalStore.modalActive === "deleteInfluence"}
      onClose={modalStore.closeModal}
      title={`Confirm Deleting ${influencesCountText}`}
    >
      <div className={`${Classes.DIALOG_BODY} m-0 p-3`} data-testid="em-user-details">
        <div className="d-flex flex-column w-100">
          <span className="mb-2">
            <span className="font-weight-bold">Comment</span> (required)
          </span>
          <TextArea
            autoFocus
            data-testid="delete-influence-comment"
            growVertically
            onChange={({ target }) => setDeleteComment(target.value)}
            value={deleteComment}
          />
          <span className="mt-2 bp3-text-muted bp3-text-small">
            You are going to delete {selectedRows.length} {influencesCountText}.
          </span>
        </div>
      </div>

      <DialogFooter className={`${Classes.DIALOG_FOOTER} mx-0 px-3 py-2`}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button onClick={modalStore.closeModal} type="button">
            Cancel
          </Button>
          <Button
            data-testid="delete-influence-button"
            disabled={!deleteComment.length}
            intent="primary"
            onClick={() => {
              deleteInfluences({
                comment: deleteComment,
                influenceIds: [...selectedRows]
              });
              selectedRows.length = 0;
              modalStore.closeModal();
            }}
          >
            Delete {influencesCountText}
          </Button>
        </div>
      </DialogFooter>
    </Dialog>
  );
}

export default observer(DeleteInfluenceModal);
