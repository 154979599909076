import styled from "@emotion/styled";
import { Colors } from "@blueprintjs/core";

const DisabledFilterIndicator = styled("div")`
  margin-left: auto;
  position: absolute;
  right: 0;
  top: 0;
  height: 16px;
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:before {
    background-color: ${Colors.GRAY4};
    border-radius: 50%;
    content: "";
    display: inline-block;
    height: 6px;
    width: 6px;
  }
`;

export default DisabledFilterIndicator;
