import React from "react";
import { Button, Spinner } from "@blueprintjs/core";

import { dateOptions } from "models/SystemSettings.model";
import { SystemSettingsControlLabel, SystemSettingsSelect } from "./SystemSettingsUI";
import SystemSettingsMenuItem from "./SystemSettingsMenuItem";

type Props = {
  dateFormat: string,
  isLoading: boolean,
  onChange: Function
};

function DateFormatControl({ dateFormat, onChange, isLoading }: Props) {
  const dateFormatLabel = dateOptions.find(option => option.value === dateFormat)?.label;
  const rightIcon = isLoading ? <Spinner size={12} /> : "caret-down";
  const buttonText = isLoading ? "Loading..." : dateFormatLabel;

  return (
    <div className="d-flex justify-content-end align-items-center mb-3 text-right">
      <SystemSettingsControlLabel className="m-0 mr-2">Format</SystemSettingsControlLabel>
      <SystemSettingsSelect
        disabled={isLoading}
        filterable={false}
        itemRenderer={(item, itemProps) => (
          <SystemSettingsMenuItem
            key={item.label}
            data-testid="date-format-menu-item"
            item={item}
            itemProps={itemProps}
            selected={dateFormat}
          />
        )}
        items={dateOptions}
        onItemSelect={option => onChange({ dateFormat: option.value })}
        popoverProps={{ minimal: true }}
      >
        <Button
          data-testid="system-settings-date-format-select"
          disabled={isLoading}
          rightIcon={rightIcon}
          text={buttonText}
        />
      </SystemSettingsSelect>
    </div>
  );
}

export default DateFormatControl;
