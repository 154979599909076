import React from "react";
import { observer } from "mobx-react";
import { Button } from "@blueprintjs/core";

import AggregationSingleSelect from "../Select/AggregationSingleSelect";
import ModuleTypeSelect from "../Dashboard/ModuleTypeSelect";
import { exploreMetrics } from "modules/Explore/metrics/metrics";
import metricLabels from "shared/metricLabels/metricLabels";

import { useStores } from "store/Store";

type Props = {
  changeAggregation: Function;
  changeMetric: Function;
  leftAggregation: string;
  rightAggregation: string;
  selectedMetric: string;
  xDayBuild: number;
};

function PivotTableTools(props: Props) {
  const { systemSettingsStore } = useStores();
  const { isMiles } = systemSettingsStore;

  const {
    changeAggregation,
    changeMetric,
    leftAggregation = "",
    rightAggregation = "",
    selectedMetric,
    xDayBuild = 7
  } = props;

  const allMetrics = exploreMetrics.reduce((acc: any, item: any) => {
    const groupMetrics = item[1];
    const skipMetrics = ["distance", "depTime"];
    return [
      ...acc,
      ...groupMetrics.map(el => ({
        group: metricLabels({ isMiles, withUnits: true, xDayBuild })[item[0]],
        label: metricLabels({ isMiles, withUnits: true, xDayBuild })[el.key],
        value: el.key
      }))
    ].filter(metric => !skipMetrics.includes(metric.value));
  }, []);

  return (
    <>
      <AggregationSingleSelect
        aggregations={[leftAggregation]}
        onSelect={agg => changeAggregation("leftAggregation", agg)}
      />

      <Button
        className="mx-1"
        icon="swap-horizontal"
        onClick={() => changeAggregation("leftAggregation", rightAggregation)}
      />

      <AggregationSingleSelect
        aggregations={[rightAggregation]}
        onSelect={agg => changeAggregation("rightAggregation", agg)}
      />
      <ModuleTypeSelect
        className="watchlist-module-type-select ml-1"
        onChange={changeMetric}
        selected={selectedMetric}
        selectOptions={allMetrics}
      />
    </>
  );
}

export default observer(PivotTableTools);
