// @flow

import React from "react";
import { observer } from "mobx-react";
import { AnchorButton, Colors, Icon, Popover, PopoverInteractionKind, Position } from "@blueprintjs/core";
import styled from "@emotion/styled";
import { css, Global } from "@emotion/react";

const StyledReadMoreButton = styled(AnchorButton)`
  background: none !important;

  &:focus {
    outline: none;
  }
`;

const StyledIcon = styled(Icon)`
  svg:hover {
    fill: ${Colors.DARK_GRAY5};
  }
`;

const globalStyle = (
  <Global
    styles={css`
      .read-more-popover {
        .bp3-popover-arrow-fill {
          fill: ${Colors.DARK_GRAY5};
        }

        .bp3-popover-content {
          background: ${Colors.DARK_GRAY5};
          color: ${Colors.LIGHT_GRAY5};
        }
      }
    `}
  />
);

type Props = {
  href: string,
  text: string
};

function ReadMoreButton(props: Props) {
  const { href = "https://help.cirrus.ai", text = "Click to read more about these metrics in Help Center" } = props;

  return (
    <>
      {globalStyle}
      <Popover
        content={<div className="py-2 px-3">{text}</div>}
        hoverOpenDelay={1000}
        interactionKind={PopoverInteractionKind.HOVER}
        popoverClassName="read-more-popover"
        position={Position.BOTTOM}
      >
        <StyledReadMoreButton aria-label="Read more" href={href} minimal rel="noopener noreferrer" target="_blank">
          <StyledIcon color={Colors.LIGHT_GRAY2} icon="help" />
        </StyledReadMoreButton>
      </Popover>
    </>
  );
}

export default observer(ReadMoreButton);
