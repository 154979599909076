import isEmpty from "lodash.isempty";
import { ConditionalFilterValue } from "models/Tab/Tab.types";

export default function groupConditionalFilters(conditionalFilters: ConditionalFilterValue[]) {
  if (!isEmpty(conditionalFilters)) {
    const conditionNameIndex = {};
    return conditionalFilters.reduce((result, item) => {
      const { func, name, value } = item;
      conditionNameIndex[name] = name in conditionNameIndex ? conditionNameIndex[name] + 1 : 0;
      const newCondition = { func, index: conditionNameIndex[name], value };
      return {
        ...result,
        [name]: result[name] ? [...result[name], newCondition] : [newCondition]
      };
    }, {});
  }
  return {};
}
