import React from "react";
import { observer } from "mobx-react";
import styled from "@emotion/styled";
import uniqBy from "lodash.uniqby";

import WatchlistItem from "./WatchlistItem";
import { Status } from "modules/App/Status";
import { useStores } from "store/Store";

const StyledList = styled("ul")`
  max-height: 100%;
  overflow: auto;
`;

function Watchlist() {
  const { dashboardWatchlistStore } = useStores();
  const { data, filterWatchlist, selectedMetric } = dashboardWatchlistStore;

  const list = uniqBy(data.slice().reverse(), ({ label, id }) => `${label}${id}`)
    .sort((a, b) => {
      // sort desc numbers and push nulls values at end
      return (
        // @ts-ignore
        (b.data[selectedMetric] !== null) - (a.data[selectedMetric] !== null) ||
        a.data[selectedMetric] - b.data[selectedMetric]
      );
    })
    .filter(item => {
      if (!filterWatchlist || item.status === Status.ERROR) {
        return true;
      }
      return !!item?.data?.numberOfFlights;
    })
    .map(watchlistItem => {
      const { id, label } = watchlistItem;
      return <WatchlistItem key={`watchlist-${label}-${id}`} watchlistItem={watchlistItem} />;
    });
  return <StyledList className="my-0 p-0 list-unstyled">{list}</StyledList>;
}

export default observer(Watchlist);
