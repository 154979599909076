import React from "react";
import { endOfToday, startOfToday, subYears } from "date-fns";
import difference from "lodash.difference";

import AdministrativeFilters from "../../Analysis/AnalysisSidebarFilterGroups/AdministrativeFilters";
import DepartureDateFilters from "../../Analysis/AnalysisSidebarFilterGroups/DepartureDateFilters";
import FlightFilters from "../../Analysis/AnalysisSidebarFilterGroups/FlightFilters";
import MarketFilters from "../../Analysis/AnalysisSidebarFilterGroups/MarketFilters";
import * as metricGroups from "shared/metricLabels/constants";
import { RootStore } from "modules/App/Root.model";
import SidebarDateRangeSelect from "shared/components/Sidebar/SidebarDateRangeSelect/SidebarDateRangeSelect";
import SidebarSelect from "shared/components/Sidebar/SidebarSelect/SidebarSelect";
import { Tab } from "models/Tab/Tab.model";
import { FilterKey } from "types/Tab.types";

const getLacItems = (rootStore: RootStore, pageContext: Tab) => {
  const { analysisMappingsStore } = rootStore;
  const matchingBookingClasses = analysisMappingsStore.getBookingClassesByCabinClass(pageContext.filters.cabinClass);
  const otherBookingClasses = difference(analysisMappingsStore.getBookingClasses(), matchingBookingClasses);
  return [
    {
      groupItems: analysisMappingsStore.getBookingClassesByCabinClass(pageContext.filters.cabinClass),
      label: "Matching pre-selected"
    },
    { groupItems: otherBookingClasses, label: "All classes" }
  ];
};

export type MetricFilter = {
  isDisabled?: boolean;
  isMetric?: boolean;
  isNonNegative?: boolean;
  key: FilterKey;
  excludeInFilters?: boolean;
  max?: number;
  renderer?: {
    Component: React.FunctionComponent<any>;
    items?: (rootStore: RootStore, pageContext: Tab) => string[] | object[];
    props: {
      isDateTime?: boolean;
      maxDate?: Date;
      minDate?: Date;
      fuzzySearch?: boolean;
    };
    selectedItemProp?: "dates" | "selectedItems";
  };
};

export type Metric = {
  Component?: React.FunctionComponent<any>;
  filters: MetricFilter[];
  key: string;
  name: string;
};

export const filterGroups: Metric[] = [
  {
    Component: MarketFilters,
    filters: [
      { key: "regionId" },
      { key: "subregionId" },
      { key: "rtMarket" },
      { key: "owMarket" },
      { key: "origin" },
      { key: "destination" }
    ],
    key: "market",
    name: "Market"
  },
  {
    Component: FlightFilters,
    filters: [
      { key: "flightNumber" },
      { isMetric: true, key: "depTime" },
      { key: "depTimeBucket" },
      { key: "cabinClass" },
      { isMetric: true, isNonNegative: true, key: "distance" }
    ],
    key: "flight",
    name: "Flight"
  },
  {
    Component: DepartureDateFilters,
    filters: [
      { key: "quarter" },
      { key: "ndo" },
      { key: "depMonth" },
      { key: "depWeek" },
      { key: "depDate" },
      { key: "depDow" }
    ],
    key: "dep-date",
    name: "Departure Date"
  },
  {
    Component: AdministrativeFilters,
    filters: [{ key: "fusionrmStatus" }, { key: "airline" }, { key: "analystId" }],
    key: "administrative",
    name: "Administrative"
  },
  {
    filters: [
      { isMetric: true, key: "xDayRevenuePotential" },
      { isMetric: true, isNonNegative: true, key: "finalRevenueExpected" },
      { isMetric: true, isNonNegative: true, key: "finalRevenueBaseline" },
      { isMetric: true, isNonNegative: true, key: "xDayFinalRevenueExpected" }
    ],
    key: "finalRevenueGroup",
    name: "Final Revenue"
  },
  {
    filters: [
      { isMetric: true, isNonNegative: true, key: "finalRaskExpected" },
      { isMetric: true, isNonNegative: true, key: "xDayFinalRaskExpected" },
      { isMetric: true, key: "xDayFinalRaskBuildExpected" },
      { isMetric: true, isNonNegative: true, key: "finalRaskBaseline" }
    ],
    key: "finalRaskGroup",
    name: "Final Rask"
  },
  {
    filters: [
      { isMetric: true, isNonNegative: true, key: "rask" },
      { isMetric: true, isNonNegative: true, key: "raskBaseline" },
      { isMetric: true, key: "raskDiffToBaseline" },
      { isMetric: true, isNonNegative: true, key: "xDayRask" },
      { isMetric: true, isNonNegative: true, key: "xDayRaskBaseline" },
      { isMetric: true, key: "xDayRaskDiffToBaseline" },
      { isMetric: true, key: "xDayRaskBuild" },
      { isMetric: true, key: "xDayRaskBuildBaseline" },
      { isMetric: true, key: "xDayRaskBuildDiffToBaseline" }
    ],
    key: "raskGroup",
    name: "RASK"
  },
  {
    filters: [
      { isMetric: true, isNonNegative: true, key: "revenue" },
      { isMetric: true, isNonNegative: true, key: "revenueBaseline" },
      { isMetric: true, key: "diffToErb" },
      { isMetric: true, isNonNegative: true, key: "xDayRevenue" },
      { isMetric: true, isNonNegative: true, key: "xDayRevenueBaseline" },
      { isMetric: true, key: "xDayRevenueDiffToBaseline" },
      { isMetric: true, key: "xDayRevenueBuildDiff" },
      { isMetric: true, key: "xDayRevenueBuildBaseline" },
      { isMetric: true, key: "xDayRevenueBuildDiffToBaseline" }
    ],
    key: "revenueGroup",
    name: "Revenue"
  },
  {
    filters: [
      { isMetric: true, isNonNegative: true, key: "finalLoadFactorExpected" },
      { isMetric: true, isNonNegative: true, key: "xDayFinalLoadFactorExpected" },
      { isMetric: true, key: "xDayFinalLoadFactorBuildExpected" },
      { isMetric: true, isNonNegative: true, key: "finalLoadFactorBaseline" }
    ],
    key: "finalLoadFactorGroup",
    name: "Final Load Factor"
  },
  {
    filters: [
      { isMetric: true, isNonNegative: true, key: "loadFactor" },
      { isMetric: true, isNonNegative: true, key: "loadFactorBaseline" },
      { isMetric: true, key: "diffToElb" },
      { isMetric: true, isNonNegative: true, key: "xDayLoadFactor" },
      { isMetric: true, isNonNegative: true, key: "xDayLoadFactorBaseline" },
      { isMetric: true, key: "xDayLoadFactorDiffToBaseline" },
      { isMetric: true, key: "xDayLoadFactorBuild" },
      { isMetric: true, key: "xDayLoadFactorBuildBaseline" },
      { isMetric: true, key: "xDayLoadFactorBuildDiffToBaseline" }
    ],
    key: "loadFactorGroup",
    name: "Load Factor"
  },
  {
    filters: [
      { isMetric: true, isNonNegative: true, key: "netBookings" },
      { isMetric: true, isNonNegative: true, key: "netBookingsBaseline" },
      { isMetric: true, key: "netBookingsDiffToBaseline" },
      { isMetric: true, isNonNegative: true, key: "xDayNetBookings" },
      { isMetric: true, isNonNegative: true, key: "xDayNetBookingsBaseline" },
      { isMetric: true, key: "xDayNetBookingsDiffToBaseline" },
      { isMetric: true, key: "xDayNetBookingsBuild" },
      { isMetric: true, key: "xDayNetBookingsBuildBaseline" },
      { isMetric: true, key: "xDayNetBookingsBuildDiffToBaseline" }
    ],
    key: "bookingsGroup",
    name: "Bookings"
  },
  {
    filters: [
      { isMetric: true, isNonNegative: true, key: "aircraftCapacity" },
      { isMetric: true, isNonNegative: true, key: "sellableCapacity" },
      { isMetric: true, isNonNegative: true, key: "xDayAircraftCapacity" },
      { isMetric: true, isNonNegative: true, key: "xDaySellableCapacity" },
      { isMetric: true, key: "xDayAircraftCapacityBuild" },
      { isMetric: true, key: "xDaySellableCapacityBuild" }
    ],
    key: "capacityGroup",
    name: "Capacity"
  },
  {
    filters: [
      { isMetric: true, isNonNegative: true, key: "finalYieldExpected" },
      { isMetric: true, isNonNegative: true, key: "xDayFinalYieldExpected" },
      { isMetric: true, key: "xDayFinalYieldBuildExpected" },
      { isMetric: true, isNonNegative: true, key: "finalYieldBaseline" }
    ],
    key: "finalYieldGroup",
    name: "Final Yield"
  },
  {
    filters: [
      { isMetric: true, isNonNegative: true, key: "yield" },
      { isMetric: true, isNonNegative: true, key: "yieldBaseline" },
      { isMetric: true, key: "yieldDiffToBaseline" },
      { isMetric: true, isNonNegative: true, key: "xDayYield" },
      { isMetric: true, isNonNegative: true, key: "xDayYieldBaseline" },
      { isMetric: true, key: "xDayYieldDiffToBaseline" },
      { isMetric: true, key: "xDayYieldBuild" },
      { isMetric: true, key: "xDayYieldBuildBaseline" },
      { isMetric: true, key: "xDayYieldBuildDiffToBaseline" }
    ],
    key: "yieldGroup",
    name: "Yield"
  },
  {
    filters: [
      { isMetric: true, isNonNegative: true, key: "finalRevenuePerBookingExpected" },
      { isMetric: true, isNonNegative: true, key: "xDayFinalRevenuePerBookingExpected" },
      { isMetric: true, key: "xDayFinalRevenuePerBookingBuildExpected" },
      { isMetric: true, isNonNegative: true, key: "finalRevenuePerBookingBaseline" }
    ],
    key: "finalRevenuePerBookingGroup",
    name: "Final Revenue per Booking"
  },
  {
    filters: [
      { isMetric: true, isNonNegative: true, key: "revenuePerBooking" },
      { isMetric: true, isNonNegative: true, key: "revenuePerBookingBaseline" },
      { isMetric: true, key: "revenuePerBookingDiffToBaseline" },
      { isMetric: true, isNonNegative: true, key: "xDayRevenuePerBooking" },
      { isMetric: true, isNonNegative: true, key: "xDayRevenuePerBookingBaseline" },
      { isMetric: true, key: "xDayRevenuePerBookingDiffToBaseline" },
      { isMetric: true, key: "xDayRevenuePerBookingBuild" },
      { isMetric: true, key: "xDayRevenuePerBookingBuildBaseline" },
      { isMetric: true, key: "xDayRevenuePerBookingBuildDiffToBaseline" }
    ],
    key: "revenuePerBookingGroup",
    name: "Revenue per Booking"
  },
  {
    filters: [
      { isMetric: true, isNonNegative: true, key: "sellingPrice" },
      { isMetric: true, isNonNegative: true, key: "xDaySellingPrice" },
      { isMetric: true, isNonNegative: true, key: "sellingPriceFrm" },
      { isMetric: true, isNonNegative: true, key: "xDaySellingPriceFrm" },
      { isMetric: true, key: "xDayPriceBuildDiff" }
    ],
    key: "sellingFareGroup",
    name: "Selling Fare"
  },
  {
    filters: [
      { isMetric: true, isNonNegative: true, key: "pricePercentile" },
      { isMetric: true, isNonNegative: true, key: "xDayPricePercentile" },
      { isMetric: true, isNonNegative: true, key: "pricePercentileFrm" },
      { isMetric: true, isNonNegative: true, key: "xDayPricePercentileFrm" },
      { isMetric: true, key: "xDayPricePercentileBuild" }
    ],
    key: "pricePercentileGroup",
    name: "Price percentile"
  },
  {
    filters: [
      { isMetric: true, isNonNegative: true, key: "lacRank" },
      {
        isMetric: true,
        key: "lacRbd",
        renderer: {
          Component: SidebarSelect,
          items: getLacItems,
          props: { fuzzySearch: true }
        }
      },
      { isMetric: true, isNonNegative: true, key: "xDayLacRank" },
      {
        isMetric: true,
        key: "xDayLacRbd",
        renderer: {
          Component: SidebarSelect,
          items: getLacItems,
          props: { fuzzySearch: true }
        }
      },
      { isMetric: true, isNonNegative: true, key: "lacRankFrm" },
      {
        isMetric: true,
        key: "lacRbdFrm",
        renderer: {
          Component: SidebarSelect,
          items: getLacItems,
          props: { fuzzySearch: true }
        }
      },
      { isMetric: true, isNonNegative: true, key: "xDayLacRankFrm" },
      {
        isMetric: true,
        key: "xDayLacRbdFrm",
        renderer: {
          Component: SidebarSelect,
          items: getLacItems,
          props: { fuzzySearch: true }
        }
      },
      { isMetric: true, key: "xDayLacBuildDiff" }
    ],
    key: "lacGroup",
    name: "LAC"
  },
  {
    filters: [{ isMetric: true, isNonNegative: true, key: "lacSeatsAvailable" }],
    key: "lacSeatsAvailableGroup",
    name: "Seat Allocations"
  },
  {
    filters: [
      {
        isMetric: true,
        key: "compFareCirrusMatchAirline",
        renderer: {
          Component: SidebarSelect,
          items: (rootStore: RootStore) => rootStore.analysisMappingsStore.data.compFareAirline,
          props: { fuzzySearch: true }
        }
      },
      { isMetric: true, isNonNegative: true, key: "compFareCirrusMatchFare" },
      { isMetric: true, key: "sellingFareDiffToCirrusCompFarePercentile" },
      { isMetric: true, key: "sellingFareDiffToCirrusCompFare" },
      {
        isMetric: true,
        key: "compFareTimeMatchAirline",
        renderer: {
          Component: SidebarSelect,
          items: (rootStore: RootStore) => rootStore.analysisMappingsStore.data.compFareAirline,
          props: { fuzzySearch: true }
        }
      },
      { isMetric: true, isNonNegative: true, key: "compFareTimeMatchFare" },
      { isMetric: true, key: "sellingFareDiffToTimePeriodCompFarePercentile" },
      { isMetric: true, key: "sellingFareDiffToTimePeriodCompFare" },
      {
        isMetric: true,
        key: "compFareDateMatchAirline",
        renderer: {
          Component: SidebarSelect,
          items: (rootStore: RootStore) => rootStore.analysisMappingsStore.data.compFareAirline,
          props: { fuzzySearch: true }
        }
      },
      { isMetric: true, isNonNegative: true, key: "compFareDateMatchFare" },
      { isMetric: true, key: "sellingFareDiffToDepDateCompFarePercentile" },
      { isMetric: true, key: "sellingFareDiffToDepDateCompFare" }
    ],
    key: "competitorFareGroup",
    name: metricGroups.baseCompetitorFareGroup().competitorFareGroup
  },
  {
    filters: [
      { excludeInFilters: true, isMetric: true, key: "numberOfImpactedFlights" },
      {
        isMetric: true,
        key: "lastModifiedInfluenceDate",
        renderer: {
          Component: SidebarDateRangeSelect,
          props: {
            isDateTime: true,
            maxDate: endOfToday(),
            minDate: subYears(startOfToday(), 3)
          },
          selectedItemProp: "dates"
        }
      },
      {
        isMetric: true,
        key: "lastModifiedInfluenceAnalystId",
        renderer: {
          Component: SidebarSelect,
          items: (rootStore: RootStore) => rootStore.influenceHistoryStore.mappings.creators,
          props: { fuzzySearch: true }
        }
      },
      { isMetric: true, isNonNegative: true, key: "compSensitivity", max: 10 },
      { isMetric: true, key: "priceAdjustment" },
      { isMetric: true, key: "absolutePriceAdjustment" },
      { isMetric: true, isNonNegative: true, key: "minmaxAdjustmentMin" },
      { isMetric: true, isNonNegative: true, key: "minmaxAdjustmentMax" }
    ],
    key: "influenceGroup",
    name: "Influence"
  }
];

const filterIsMetric = filter => filter.isMetric;

export const exploreMetrics: [string, MetricFilter[]][] = filterGroups
  .filter(group => group.filters.some(filterIsMetric))
  .map(({ key, filters }) => {
    const metrics = filters.filter(filterIsMetric);
    return [key, metrics];
  });

export const buildCurvesMetrics = [
  ["raskGroup", [{ key: "rask" }, { key: "raskBaseline" }, { key: "finalRaskExpected" }, { key: "raskExpected" }]],
  [
    "revenueGroup",
    [{ key: "revenue" }, { key: "revenueBaseline" }, { key: "finalRevenueExpected" }, { key: "revenueExpected" }]
  ],
  [
    "revenuePerBookingGroup",
    [{ key: "revenuePerBooking" }, { key: "revenuePerBookingBaseline" }, { key: "finalRevenuePerBookingExpected" }]
  ],
  [
    "loadFactorGroup",
    [
      { key: "loadFactor" },
      { key: "loadFactorBaseline" },
      { key: "finalLoadFactorExpected" },
      { key: "loadFactorExpected" }
    ]
  ],
  ["yieldGroup", [{ key: "yield" }, { key: "yieldBaseline" }, { key: "finalYieldExpected" }]],
  ["sellingFareGroup", [{ key: "sellingPrice" }]],
  ["competitorFareGroup", [{ key: "compFareCirrusMatchFare" }]],
  ["pricePercentileGroup", [{ key: "pricePercentile" }]],
  ["lacGroup", [{ key: "lacRank" }]]
];

export const allMetricsGroupedByKey = () => {
  const initialValue = {};

  buildCurvesMetrics.forEach(([key, metrics]: [string, { key: string }[]]) =>
    metrics.forEach(metric => (initialValue[metric.key] = key))
  );

  return initialValue;
};

export const buildCurvesMetricsPositions = {
  competitorFareGroup: "right",
  lacGroup: "left",
  loadFactorGroup: "left",
  pricePercentileGroup: "left",
  raskGroup: "left",
  revenueGroup: "right",
  revenuePerBookingGroup: "right",
  sellingFareGroup: "right",
  yieldGroup: "left"
};

export const filterOrder = filterGroups.flatMap(({ filters }) => filters.map(({ key }) => key));
