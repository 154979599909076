export const columns = [
  "overbookingIdentifier",
  "type",
  "value",
  "appliedDatetime",
  "analystName",
  "comment",
  "numberOfFutureFlights",
  "cabinClass",
  "minDepDate",
  "maxDepDate",
  "owMarkets"
];

export const columnLabels = {
  analystName: "Analyst",
  appliedDatetime: "Date Applied",
  cabinClass: "Cabin Class",
  comment: "Comment",
  maxDepDate: "Max Dep. Date",
  minDepDate: "Min Dep. Date",
  numberOfFutureFlights: "Num. Flights",
  overbookingIdentifier: "ID",
  owMarkets: "OW Markets",
  type: "Type",
  value: "Value"
};
