const canEnableMetric = (aggregations, metricKey: string): boolean => {
  switch (metricKey) {
    case "depTime": {
      const patterns = [
        ["owMarket", "flightNumber", "depDate"],
        ["rtMarket", "flightNumber", "depDate"],
        ["origin", "destination", "flightNumber", "depDate"]
      ];

      return patterns.some(requiredAggregations => {
        return requiredAggregations.every(aggregation => aggregations.includes(aggregation));
      });
    }
    default:
      return true;
  }
};

export default canEnableMetric;
