// @flow

import cloneDeep from "lodash.clonedeep";
import difference from "lodash.difference";
import intersection from "lodash.intersection";
import isEmpty from "lodash.isempty";
import isEqual from "lodash.isequal";
import union from "lodash.union";
import omit from "lodash.omit";
import { action, computed, makeObservable, observable, toJS } from "mobx";
import { addDays, differenceInDays, format, parseISO, subDays } from "date-fns";

import type { TabsStore } from "../Tabs.model";

import api, { isCancel } from "services/Api";
import { AnalysisSubpages } from "modules/App/App.types";
import getRowObjectIds from "shared/helpers/getRowObjectIds/getRowObjectIds";
import removeEmptyFilters from "shared/helpers/removeEmptyFilters/removeEmptyFilters";
import parseGraphSeries from "modules/BuildCurves/parseGraphSeries/parseGraphSeries";
import prepareStructure from "shared/helpers/prepareStructure/prepareStructure";
import { Status } from "modules/App/Status";
import initGroupStatus from "modules/Tab/initGroupStatus/initGroupStatus";
import clearDataFromPriceAdjExtraKeys from "modules/Tab/clearDataFromPriceAdjExtraKeys/clearDataFromPriceAdjExtraKeys";
import getRowId from "shared/helpers/getRowId/getRowId";
import isNumber from "shared/helpers/isNumber/isNumber";
import {
  mergePastAndFutureTimeline,
  mergePastAndFutureTimelines,
  sharedSeries
} from "modules/BuildCurves/mergePastAndFutureTimelines/mergePastAndFutureTimelines";
import { FlightsTable } from "modules/FlightsTable/FlightsTable.model";
import { PivotTable } from "../PivotTable/PivotTable.model";
import { DAYS_BACK, FILTERS_INIT, forecastedSeries, synchronizedCollapses, TAB_INIT, TabStruct } from "./Tab.utils";
import type { MySavedAnalysis } from "modules/Templates/Templates.model";
import type {
  ConditionalFilterKey,
  DisabledFilters,
  FilterKey,
  ModulePreferences,
  RegularFilterKey,
  TabPreferences
} from "stypes/Tab.types";
import groupConditionalFilters from "shared/helpers/groupConditionalFilters/groupConditionalFilters";
import getEnabledFilters from "shared/helpers/getEnabledFilters/getEnabledFilters";
import removeValueInFilter from "shared/helpers/removeValueInFilter/removeValueInFilter";
import toggleFilterValue from "shared/helpers/toggleFilterValue/toggleFilterValue";
import { filterGroups } from "modules/Explore/metrics/metrics";
import type { Condition } from "stypes/Sidebar.types";
import filterCountsByGroup from "shared/helpers/filterCountsByGroup/filterCountsByGroup";

export const defaultTabNameRegex = /(Analysis)(.?)([0-9]{0,})/g;

export class Tab {
  @observable activeSubPage: string | null;
  @observable analystMappingEnabled: $PropertyType<TabStruct, "analystMappingEnabled">;
  @observable applied: $PropertyType<TabStruct, "applied">;
  @observable buildCurves: $PropertyType<TabStruct, "buildCurves">;
  @observable conditionalFilters: $PropertyType<TabStruct, "conditionalFilters">;
  @observable disabledFilters: DisabledFilters;
  @observable displayGraphMenu: boolean | null;
  @observable editAnalysis: boolean;
  @observable editInfluence: boolean;
  @observable filters: $PropertyType<TabStruct, "filters">;
  @observable filtersEnabled: $PropertyType<TabStruct, "filtersEnabled">;
  @observable flightsCount: $PropertyType<TabStruct, "flightsCount">;
  @observable id: string;
  @observable label: string;
  @observable parentId: number | null;
  @observable pivotTable: $PropertyType<TabStruct, "pivotTable">;
  @observable preferences: TabPreferences;
  // savedViewId is only used for legacy tabs with old structure
  @observable savedViewId: number | null;
  @observable sidebar: $PropertyType<TabStruct, "sidebar">;
  @observable state: $PropertyType<TabStruct, "state">;
  @observable xDayBuild: number;

  duplicatedFromSavedId: number | null;
  flightsTable: FlightsTable;
  pivotTable: PivotTable;
  tabsStore: TabsStore;

  constructor(tabsStore: TabsStore, tab?: TabStruct) {
    makeObservable(this);
    const newTab = prepareStructure({
      arraysToMerge: [{ identifier: "label", key: "groupStatuses" }],
      keysToMerge: ["fixedColumns"],
      object: tab || {},
      template: TAB_INIT
    });

    const seed = {
      ...newTab,
      flightsTable: new FlightsTable(newTab.flightsTable),
      pivotTable: new PivotTable(newTab.pivotTable)
    };

    Object.assign(this, initGroupStatus(seed, TAB_INIT));

    // tab can have serialized tabsStore etc. so these need to come last
    this.tabsStore = tabsStore;
    this.flightsTable = seed.flightsTable;
    this.pivotTable = seed.pivotTable;
  }

  @action.bound
  toggleFilter(filterKey: FilterKey) {
    const isFilterDisabled = filterKey in this.disabledFilters;
    const isRegularFilter = filterKey in this.filters;

    if (isFilterDisabled) {
      delete this.disabledFilters[filterKey];
    } else {
      this.disabledFilters[filterKey] = isRegularFilter
        ? this.filters[filterKey]
        : this.conditionalFilters
            .filter(conditionalFilter => conditionalFilter.name === filterKey)
            .map((_, index) => index);
    }

    this.submitSearchForm();
  }

  @action.bound
  toggleFilterValue(filterKey: string, filterValueToToggle: string | number) {
    toggleFilterValue(filterKey, filterValueToToggle, this.disabledFilters);

    this.submitSearchForm();
  }

  @action.bound
  setSidebarOpen(isOpen: boolean) {
    this.sidebar.isOpen = isOpen;
    if (!isOpen) {
      this.sidebar.filterQuery = "";
      this.sidebar.filterKey = null;
    }
  }

  @action.bound
  toggleFiltersEnabled() {
    this.filtersEnabled = !this.filtersEnabled;
    this.refetchFlightsTableData();
    this.getBuildCurvesData();
  }

  @action.bound
  toggleAnalystMapping() {
    this.analystMappingEnabled = !this.analystMappingEnabled;
  }

  @action.bound
  setSidebarFilterQuery(filterQuery: string, filterKey?: string) {
    const { sidebar } = this;
    sidebar.filterQuery = filterQuery;
    sidebar.filterKey = filterKey;
  }

  @action
  renameTab(name: string) {
    this.label = name;
    this.saveTabs();
  }

  @action
  flushTab() {
    this.flightsTable.flushData();
  }

  @action.bound
  toggleShowOnlySelected() {
    this.flightsTable.toggleShowOnlySelected();
    this.refetchFlightsTableData();
  }

  @action.bound
  getRowInfluenceIds(rowId: string): AxiosPromise<string[]> {
    const { conditionalFilters, filters } = this.getNormalizedFilters;
    const rowObjectId = getRowObjectIds(this.flightsTable.aggregations, [rowId]).pop();

    return api
      .getRowInfluenceIds({
        conditionalFilters,
        filters,
        rowId: rowObjectId,
        xDayBuild: this.xDayBuild
      })
      .then(response => response.data.influenceIds)
      .catch(thrown => {
        this.status = Status.ERROR;
      });
  }

  @action
  setModuleCollapsed(moduleId: string, isCollapsed: boolean) {
    const affectedModuleIds = synchronizedCollapses.find(ids => ids.includes(moduleId)) || [moduleId];

    affectedModuleIds.forEach(id => {
      this[id].isCollapsed = isCollapsed;
    });

    this.saveTabs();
  }

  @action.bound
  toggleGraphMenu(isVisible: boolean) {
    this.displayGraphMenu = isVisible;

    this.saveTabs();
  }

  @action.bound
  changeDateOption(isNdo: boolean) {
    this.buildCurves.isNdo = isNdo;

    this.saveTabs();
    this.getBuildCurvesData();
  }

  @action.bound
  refetchFlightsTableData(params: Object, options: Object) {
    this.getFlightsData(params);
    this.getFlightsCountData(params);

    if (options && options.saveOptions) {
      this.saveTabs();
    }
  }

  @computed
  get getNormalizedFilters(): {
    conditionalFilters: $PropertyType<TabStruct, "conditionalFilters">,
    filters: $PropertyType<TabStruct, "filters">
  } {
    const enabledFilters = getEnabledFilters(
      this.applied.filters,
      this.disabledFilters,
      FILTERS_INIT,
      this.filtersEnabled
    );

    const nameCounts = {};
    const enabledConditionalFilters = this.applied.conditionalFilters.filter(filter => {
      nameCounts[filter.name] = filter.name in nameCounts ? nameCounts[filter.name] + 1 : 0;

      return !this.disabledFilters[filter.name]?.includes(nameCounts[filter.name]);
    });

    const filters = this.filtersEnabled ? enabledFilters : FILTERS_INIT;
    const conditionalFilters = this.filtersEnabled ? enabledConditionalFilters : [];

    return {
      conditionalFilters,
      filters: removeEmptyFilters(filters, FILTERS_INIT)
    };
  }

  @action
  getFlightsData(params: object) {
    const { conditionalFilters, filters } = this.getNormalizedFilters;

    const flightsDataParams = {
      ...params,
      conditionalFilters,
      filters,
      tabId: this.id,
      xDayBuild: this.xDayBuild
    };

    const influenceGroup = this.flightsTable.columns.find(([columnGroup]) => columnGroup === "influenceGroup");
    const isAnyInfluenceColumn = !isEmpty(influenceGroup?.[1]);

    if (isAnyInfluenceColumn) {
      flightsDataParams.extraColumns = ["adjustmentExists"];
    }

    this.flightsTable.fetchFlightsData(flightsDataParams).then(response => {
      if (response && this.tabsStore?.rootStore?.systemSettingsStore) {
        this.tabsStore.rootStore.systemSettingsStore.distanceUnit = response.data.distanceUnit;
      }

      this.flightsTable.data.forEach(row => {
        const rowId = getRowId(this.flightsTable.aggregations, row);

        if (!isEmpty(this.flightsTable.selectedRows) && this.flightsTable.selectedRows.includes(rowId)) {
          this.updateSelectedRows(this.flightsTable.selectedRows, { [rowId]: row.numberOfFlights });
        }
      });
    });
  }

  @action
  getFlightsCountData(params: Object) {
    const { flightsCount, id, xDayBuild } = this;
    const { conditionalFilters, filters } = this.getNormalizedFilters;

    flightsCount.status = Status.LOADING;
    flightsCount.numberOfRows = undefined;
    flightsCount.totalNumberOfFlights = undefined;

    const flightsCountParams = {
      ...params,
      conditionalFilters,
      filters,
      tabId: id,
      xDayBuild
    };

    this.flightsTable
      .fetchFlightsCountData(flightsCountParams)
      .then(data => {
        const { numberOfRows, totalNumberOfFlights } = data;
        flightsCount.numberOfRows = numberOfRows;
        flightsCount.totalNumberOfFlights = totalNumberOfFlights;
        flightsCount.status = Status.DONE;
      })
      .catch(thrown => {
        if (isCancel(thrown)) {
          flightsCount.status = Status.LOADING;
          return;
        }
        flightsCount.status = Status.ERROR;
      });
  }

  @action
  getToday() {
    return this.buildCurves.data?.today || this.tabsStore.todayDate;
  }

  @action
  getBuildCurvesData() {
    const { aggregations = [], selectedRows = [] } = this.flightsTable;

    const { cabinClass, isNdo } = this.buildCurves;
    const { conditionalFilters, filters } = this.getNormalizedFilters;

    const ndoRange = {
      end: 0,
      start: 500
    };

    const historicalDateRange = isNdo ? { ndoAggregation: ndoRange } : { dateAggregation: { daysBack: DAYS_BACK } };

    const expectedDateRange = isNdo
      ? { ndoAggregation: ndoRange }
      : {
          dateAggregation: {
            end: format(addDays(this.getToday(), 365), "yyyy-MM-dd"),
            start: format(subDays(this.getToday(), 2), "yyyy-MM-dd") // subtract two days to make sure series matches
          }
        };

    if (isEmpty(this.buildCurvesFlatSeries)) {
      return;
    }

    const historicalSeries = this.enabledHistoricalSeries;
    const expectedSeries = this.enabledForecastedSeries;

    const baseParams = {
      conditionalFilters,
      filters: {
        ...filters,
        cabinClass
      },
      rowIds: getRowObjectIds(aggregations, selectedRows),
      xDayBuild: this.xDayBuild
    };

    const historicalSeriesPromise =
      (this.isHistoricalMode || this.isMixedMode) &&
      api.getBuildCurves(
        {
          ...baseParams,
          ...historicalDateRange,
          series: historicalSeries
        },
        this.id
      );

    const expectedSeriesPromise =
      (this.isForecastMode || this.isMixedMode) &&
      api.getForecastedBuildCurves(
        {
          ...baseParams,
          ...expectedDateRange,
          series: expectedSeries
        },
        this.id
      );

    const promiseArray = [historicalSeriesPromise, expectedSeriesPromise].filter(Boolean);

    this.buildCurves.status = Status.LOADING;

    return Promise.all(promiseArray)
      .then(responses => {
        const singleTimeline = responses.length === 1;
        if (singleTimeline) {
          const mergedData = mergePastAndFutureTimeline(responses[0], isNdo);
          this.buildCurves.data = parseGraphSeries(mergedData, isNdo);
        } else {
          const mergedData = mergePastAndFutureTimelines(responses, isNdo);
          this.buildCurves.data = parseGraphSeries(mergedData, isNdo);
        }

        if (isNdo && !isEmpty(this.buildCurves.data.ndo)) {
          const ndoDataMin = Math.min(...this.buildCurves.data.ndo);
          const ndoDataMax = Math.max(...this.buildCurves.data.ndo);

          // set timeline from 0 to 60
          this.buildCurves.range = {
            end: ndoDataMax,
            start: ndoDataMin
          };
          this.changeSelectedRange({ end: ndoDataMax, start: ndoDataMin });
        }

        if (!isNdo && !isEmpty(this.buildCurves.data.date)) {
          const today = this.getToday();
          const historicalData = responses[0]?.data;
          const forecastData = responses[1]?.data;
          const earliestDate = parseISO(historicalData?.date[0]);
          const start = differenceInDays(earliestDate, today);
          const setRangeStart = () => {
            // special case for only forecasted series
            if (start === 0) {
              return 0;
            }
            return isNumber(start) ? Math.min(start, DAYS_BACK * -1) : DAYS_BACK * -1;
          };

          const obsDateEndRange = forecastData
            ? forecastData.date[forecastData.date.length - 1]
            : historicalData.date[historicalData.date.length - 1];

          // default set timeline from -30 to 0 or 360
          this.buildCurves.range = {
            end: this.isForecastMode || this.isMixedMode ? differenceInDays(parseISO(obsDateEndRange), today) : 0,
            start: setRangeStart()
          };

          // init selected range if there is none
          if (
            !this.buildCurves.selectedRange.start &&
            !this.buildCurves.selectedRange.end &&
            !isEmpty(historicalData?.date)
          ) {
            this.buildCurves.selectedRange = {
              end: this.isForecastMode || this.isMixedMode ? this.buildCurves.range.end : 0,
              start: this.isHistoricalMode || this.isMixedMode ? this.buildCurves.range.start : 0
            };
          }

          // case when selected range is bigger than maximum
          if (this.buildCurves.selectedRange.end > this.buildCurves.range.end) {
            this.changeSelectedRange();
          }

          // case when graph is scaled to historic data and forecasted series are added
          if (!this.isHistoricalMode && this.buildCurves.selectedRange.end <= 0) {
            this.changeSelectedRange();
          }

          // case when there is no historic data
          if (this.isForecastMode && !this.isMixedMode && this.buildCurves.selectedRange.start <= 0) {
            this.changeSelectedRange({ end: this.buildCurves.selectedRange.end });
          }
        }

        this.buildCurves.status = Status.DONE;
      })
      .catch(thrown => {
        if (isCancel(thrown)) {
          this.buildCurves.status = Status.LOADING;
          return;
        }
        this.buildCurves.status = Status.ERROR;
      });
  }

  @action
  patchMySavedAnalysisId(mySavedAnalysisId: number | null) {
    this.parentId = mySavedAnalysisId;
    this.parentType = "saved";
    this.saveTabs();
  }

  @action
  submitSearchForm() {
    // cast values for conditional filters to numbers and wipe wrong values
    this.conditionalFilters = this.conditionalFilters
      .map(filter => {
        const value = filter.value === "" ? "" : Number(filter.value);
        return { ...filter, value };
      })
      .filter(filter => isNumber(filter.value));

    this.applied.filters = cloneDeep(toJS(this.filters));
    this.applied.conditionalFilters.replace(this.conditionalFilters);

    this.syncDisabledFilters();
    this.saveTabs();
    this.getFlightsData();
    this.getFlightsCountData();
    this.getBuildCurvesData();

    this.updateSelectedRows([], []);
  }

  @action
  syncDisabledFilters() {
    Object.keys(this.disabledFilters).forEach(filterKey => {
      if (filterKey in this.filters) {
        this.syncDisabledStandardFilters(filterKey);
      } else {
        this.syncDisabledConditionalFilters(filterKey);
      }
    });
  }

  @action
  syncDisabledStandardFilters(filterKey: RegularFilterKey) {
    const filterValue = this.applied.filters[filterKey];
    if (typeof filterValue !== "boolean" && (isEmpty(filterValue) || isEqual(filterValue, FILTERS_INIT[filterKey]))) {
      delete this.disabledFilters[filterKey];
      return;
    }
    if (Array.isArray(filterValue)) {
      const newDisabledFilters = intersection(this.disabledFilters[filterKey], filterValue);
      if (isEmpty(newDisabledFilters)) {
        delete this.disabledFilters[filterKey];
      } else {
        this.disabledFilters[filterKey] = newDisabledFilters;
      }
    }
  }

  @action
  syncDisabledConditionalFilters(filterKey: ConditionalFilterKey) {
    const conditionalFilterValues = this.applied.conditionalFilters
      .filter(conditionalFilter => conditionalFilter.name === filterKey)
      .map((_, index) => index);
    this.disabledFilters[filterKey] = intersection(this.disabledFilters[filterKey], conditionalFilterValues);
    if (isEmpty(this.disabledFilters[filterKey])) {
      delete this.disabledFilters[filterKey];
    }
  }

  @action.bound
  clearSearchParam(name: string) {
    if (name in this.filters) {
      this.filters[name] = FILTERS_INIT[name];
    } else {
      const otherFilters = this.conditionalFilters.filter(filter => filter.name !== name);

      this.conditionalFilters.replace(otherFilters);
    }

    delete this.disabledFilters[name];

    this.submitSearchForm();
  }

  @action.bound
  toggleFixedColumn(column: string) {
    this.flightsTable.toggleFixedColumn(column);

    this.saveTabs();
  }

  @action.bound
  resetFixedColumns() {
    this.flightsTable.resetFixedColumns(TAB_INIT.flightsTable.fixedColumns);
  }

  @action.bound
  hideAggregation(columnId: string) {
    this.flightsTable.hideAggregation(columnId);

    this.saveTabs();
  }

  @action.bound
  hideColumn(columnId: string) {
    this.flightsTable.hideColumn(columnId);

    this.saveTabs();
  }

  @action.bound
  changeBuildPeriod(buildPeriod: number) {
    this.xDayBuild = buildPeriod;
    this.submitSearchForm();
  }

  @action
  changeBuildCurvesParams(key, items, options = { refetch: true, skipSave: false }) {
    if (!key) return;

    const { refetch, skipSave } = options;

    Object.assign(this.buildCurves, {
      ...(refetch && { data: [], status: Status.LOADING }),
      [key]: items
    });

    if (refetch) {
      this.getBuildCurvesData();
    }

    if (!skipSave) {
      this.saveTabs();
    }
  }

  @action.bound
  changeAggregation(items, options = { refetch: true, savePageIndex: false, skipSave: false }) {
    this.updateSelectedRows([], []);
    this.changeFlightsTableParams("aggregations", items, options);
  }

  @action
  changeFlightsTableParams(key, items, options = { refetch: true, savePageIndex: false, skipSave: false }) {
    if (!key) return;

    const { refetch, skipSave, savePageIndex } = options;

    this.flightsTable.changeFlightsTableParams(key, items, options);

    if (refetch) {
      const params = savePageIndex ? { pageIndex: this.flightsTable.pagination.pageIndex } : {};

      this.getFlightsData(params);
      this.getFlightsCountData(params);
    }

    if (!skipSave) {
      this.saveTabs();
    }
  }

  getEndValue(endValue: number): number {
    const { end } = this.buildCurves.range;
    if (this.buildCurves.isNdo && isNumber(endValue)) {
      return endValue;
    }
    if (isNumber(endValue)) {
      return endValue;
    }
    if (this.isForecastMode || this.isMixedMode) {
      return this.buildCurves.range.end;
    }
    return end;
  }

  getStartValue(startValue: number): number {
    const { start } = this.buildCurves.range;
    if (this.buildCurves.isNdo && isNumber(startValue)) {
      return startValue;
    }
    const min = isNumber(start) ? Math.max(start, DAYS_BACK * -1) : DAYS_BACK * -1;
    if (isNumber(startValue)) {
      return Math.max(startValue, min);
    }
    if (this.isHistoricalMode || this.isMixedMode) {
      return min;
    }
    return start;
  }

  @action
  changeSelectedRange(range: { start: number, end: number } = {}) {
    const newEnd = this.getEndValue(range.end);
    const newStart = this.getStartValue(range.start);
    this.buildCurves.selectedRange = { end: Math.max(newStart, newEnd), start: Math.min(newStart, newEnd) };
  }

  @action
  updatePreviewData(columns: Array, data: Object) {
    if (columns == null && this.flightsTable.influenceImpactGroupColumns == null) {
      return;
    }

    const flightsData = columns ? this.flightsTable.data : clearDataFromPriceAdjExtraKeys(this.flightsTable.data);

    Object.assign(this.flightsTable, {
      data: data || flightsData,
      influenceImpactGroupColumns: columns
    });
  }

  @action.bound
  resetInfluence() {
    this.updatePreviewData(undefined);
  }

  @action.bound
  finishInfluence() {
    this.resetInfluence();
    this.getFlightsData();
    this.getFlightsCountData();
    this.updateSelectedRows([], []);
  }

  @action
  changeSeries(series: Array<string | Array<string>>) {
    this.buildCurves.series = series;

    this.saveTabs();
    this.getBuildCurvesData();
  }

  @action
  changeBuildCurvesClass(cabinClass: string) {
    this.buildCurves.cabinClass = [cabinClass];

    this.saveTabs();
    this.getBuildCurvesData();
  }

  @action.bound
  changeFilter(filterKey: string, value) {
    this.filters[filterKey] = value;
  }

  @action.bound
  clearAllFilters() {
    this.filters = { ...FILTERS_INIT };
    this.conditionalFilters = [];
    this.disabledFilters = {};
  }

  @action.bound
  resetFiltersToDefaults(analysis: ?MySavedAnalysis) {
    const filtersState = this.tabsStore.createFiltersState();

    this.filters = analysis ? { ...analysis.view.filters } : filtersState.filters;
    this.conditionalFilters = analysis ? [...analysis.view.conditionalFilters] : filtersState.conditionalFilters;
    this.disabledFilters = {};
  }

  @action.bound
  removeFilterValue(filterKey: string, valueToRemove: string | object | boolean) {
    const { conditionalFilters, disabledFilters, filters } = this;
    if (filters[filterKey]) {
      removeValueInFilter(filterKey, valueToRemove, disabledFilters, filters, FILTERS_INIT[filterKey]);
    } else {
      const optionToRemove = conditionalFilters.find(
        ({ name, func, value }) => name === filterKey && func === valueToRemove.func && valueToRemove.value === value
      );
      conditionalFilters.remove(optionToRemove);
    }

    this.submitSearchForm();
  }

  @action.bound
  changeConditionalFilter(conditionName: string, conditionIndex: number, newCondition: Condition) {
    const matchedConditions = this.conditionalFilters.filter(condition => condition.name === conditionName);
    const indexOfConditionToChange = this.conditionalFilters.indexOf(matchedConditions[conditionIndex]);

    this.conditionalFilters[indexOfConditionToChange] = {
      ...this.conditionalFilters[indexOfConditionToChange],
      ...newCondition
    };
  }

  @action.bound
  changeActiveSubPage(newSubPage: typeof AnalysisSubpages | null) {
    this.activeSubPage = newSubPage;
  }

  @action.bound
  addCondition(newCondition) {
    this.conditionalFilters.push(newCondition);
  }

  @action.bound
  deleteCondition(conditionName: string, conditionIndex: number) {
    const matchedConditions = this.conditionalFilters.filter(condition => condition.name === conditionName);

    this.conditionalFilters.remove(matchedConditions[conditionIndex]);
  }

  @action.bound
  updateSelectedRows(selectedRows: string[], selectedRowsNumberOfFlights: object) {
    const { flightsTable, flightsCount, refetchFlightsTableData } = this;
    let shouldRefetch = false;
    if (flightsTable.showOnlySelected) {
      shouldRefetch = selectedRows.length < flightsTable.selectedRows.length;
      if (isEmpty(selectedRows)) {
        this.toggleShowOnlySelected();
      }
    }
    flightsTable.selectedRows.replace(selectedRows);

    flightsCount.selectedRowsNumberOfFlights = {
      ...flightsCount.selectedRowsNumberOfFlights,
      ...selectedRowsNumberOfFlights
    };

    if (shouldRefetch) {
      refetchFlightsTableData();
    }
  }

  @action.bound
  shiftToggleRows(selectedRow: string, clickedRow: string) {
    const { flightsTable, flightsCount, refetchFlightsTableData } = this;
    const { aggregations, data, selectedRows } = flightsTable;

    const isToggleOn = selectedRows.includes(clickedRow);
    const rowIds = data.map(row => getRowId(aggregations, row));
    const rowNumberOfFlights = data.map(row => row.numberOfFlights);

    const sliceIndexes = [rowIds.indexOf(selectedRow), rowIds.indexOf(clickedRow)].sort((a, b) => a - b);
    const actionRowIds = rowIds.slice(sliceIndexes[0], sliceIndexes[1] + 1);
    const actionRowNumberOfFlights = rowNumberOfFlights.slice(sliceIndexes[0], sliceIndexes[1] + 1);

    let shouldRefetch = false;
    if (this.flightsTable.showOnlySelected) {
      shouldRefetch = actionRowIds.length <= selectedRows.length;
      if (isEqual(actionRowIds, selectedRows)) {
        this.toggleShowOnlySelected();
      }
    }

    this.flightsTable.selectedRows = !isToggleOn
      ? union(selectedRows, actionRowIds)
      : difference(selectedRows, actionRowIds);

    const selectedRowsNumberOfFlights = {};
    actionRowIds.forEach((rowId, index) => {
      const value = !isToggleOn ? actionRowNumberOfFlights[index] : undefined;
      selectedRowsNumberOfFlights[rowId] = value;
    });

    flightsCount.selectedRowsNumberOfFlights = {
      ...flightsCount.selectedRowsNumberOfFlights,
      ...selectedRowsNumberOfFlights
    };

    if (shouldRefetch) {
      refetchFlightsTableData();
    }
  }

  @action
  saveTabs() {
    if (!this.isEditingTemplate) {
      this.tabsStore.saveTabs();
    }
  }

  @computed
  get selectedRowsNumberOfFlights(): number {
    const { flightsTable, flightsCount } = this;
    return Object.entries(flightsCount.selectedRowsNumberOfFlights)
      .map(([flightId, value]) => (flightsTable.selectedRows.includes(flightId) ? value : 0))
      .reduce((a, b) => a + b, 0);
  }

  @computed
  get buildCurvesFlatSeries(): Array<string> {
    return this.buildCurves.series.flatMap(group => group[1]);
  }

  @computed
  get enabledHistoricalSeries(): Array<string> {
    return difference(this.buildCurvesFlatSeries, forecastedSeries);
  }

  @computed
  get enabledForecastedSeries(): Array<string> {
    return intersection(this.buildCurvesFlatSeries, [...forecastedSeries, ...sharedSeries]);
  }

  @computed
  get isHistoricalMode(): boolean {
    return !isEmpty(difference(this.enabledHistoricalSeries, sharedSeries));
  }

  @computed
  get isForecastMode(): boolean {
    return !isEmpty(difference(this.enabledForecastedSeries, sharedSeries));
  }

  @computed
  get isMixedMode(): boolean {
    return (
      (this.isHistoricalMode && this.isForecastMode) || // both historical and forecast series are enabled
      (!this.isHistoricalMode && !this.isForecastMode) // only shared series are enabled
    );
  }

  @computed
  get isBuildCurvesZoomed(): boolean {
    const { buildCurves } = this;
    if (!buildCurves.selectedRange) {
      return false;
    }

    const isStartChanged = buildCurves.selectedRange.start !== this.getStartValue();
    const isEndChanged = buildCurves.selectedRange.end !== this.getEndValue();
    return isStartChanged || isEndChanged;
  }

  @computed
  get hasDefaultLabel() {
    return this.label && this.label.match(defaultTabNameRegex);
  }

  @computed
  get isMySavedAnalysis() {
    return this.parentId && this.parentType === "saved";
  }

  @computed
  get isEditingTemplate() {
    return this.editAnalysis;
  }

  @computed
  get isEditingInfluence() {
    return this.editInfluence;
  }

  @computed
  get isEditMode() {
    return this.editInfluence || this.editAnalysis;
  }

  @computed
  get isTabLoading() {
    const isLoading = status => status === Status.LOADING;

    return [this.flightsTable.status, this.flightsCount.status, this.buildCurves.status].some(isLoading);
  }

  @action
  setModuleState(moduleKey: string, newState: Partial<ModulePreferences>) {
    const currentState = this.preferences?.modules[moduleKey] || {};
    this.preferences = {
      modules: {
        ...(this.preferences?.modules || {}),
        [moduleKey]: { ...currentState, ...newState }
      }
    };

    this.saveTabs();
  }

  @computed
  get isResizingModules() {
    return Object.values(this.preferences?.modules || {}).some((state: ModulePreferences) => state.isResizing);
  }

  @computed
  get allAppliedFilters() {
    return {
      ...this.applied.filters,
      ...groupConditionalFilters(this.applied.conditionalFilters)
    };
  }

  @computed
  get allFilters() {
    return {
      ...this.filters,
      ...groupConditionalFilters(this.conditionalFilters)
    };
  }

  @computed
  get filterCountsByGroup() {
    return filterCountsByGroup(
      filterGroups,
      this.allFilters,
      this.allAppliedFilters,
      this.disabledFilters,
      FILTERS_INIT
    );
  }

  @computed
  get canStartCapacityAdjustment(): boolean {
    const noRowsSelected = isEmpty(this.flightsTable.selectedRows);
    const noCabinClassAggregation = !this.flightsTable.aggregations.includes("cabinClass");
    const multipleCabinClassSelected = !this.flightsTable.isOnlyOneCabinClassSelected;

    return !(noRowsSelected || noCabinClassAggregation || multipleCabinClassSelected);
  }

  toJSON(): TabStruct {
    const tab = {
      ...this,
      flightsTable: this.flightsTable.toJSON()
    };

    return omit(toJS(tab), ["tabsStore", "sidebar"]);
  }
}
