export default function numberFormat(
  minimumFractionDigits?: number,
  maximumFractionDigits?: number,
  style?: string,
  signDisplay?: string
) {
  return new Intl.NumberFormat("en-US", {
    maximumFractionDigits,
    minimumFractionDigits,
    signDisplay,
    style
  });
}
