import React from "react";
import { observer } from "mobx-react";
import { Button, ButtonGroup, Colors } from "@blueprintjs/core";

import styled from "@emotion/styled";
import kebabCase from "lodash.kebabcase";
import { daysOfWeek } from "modules/App/constants";

type Props = {
  onChange: Function;
  selectedItems: number[];
  title: string;
};

const StyledButton = styled(Button)`
  &:focus {
    outline: ${Colors.BLUE3}99 auto 2px !important;
    z-index: 100 !important;
  }
`;

function SidebarDaySelect(props: Props) {
  const { selectedItems = [], title = "", onChange } = props;

  // https://github.com/FlyrInc/fusionrm/blob/master/applications/acp-remake/doc/adr/0007-day-of-week-coding.md
  const days = daysOfWeek.filter(day => day != null).map(day => day && day.short);

  const handleSelect = item => {
    let result;
    const isSelected = selectedItems.includes(item);
    if (!isSelected) {
      result = [...selectedItems, item];
    } else {
      result = selectedItems.filter(selectedItem => selectedItem !== item);
    }
    onChange(result);
  };

  const buttons = days.map((dayLabel, index) => {
    const dayIndex = index + 1;
    const isSelected = selectedItems.includes(dayIndex);
    return (
      <StyledButton
        key={dayLabel}
        active={isSelected}
        className="px-0 flex-grow-1"
        onClick={() => handleSelect(dayIndex)}
      >
        {dayLabel}
      </StyledButton>
    );
  });
  const testId = kebabCase(`${title}-buttons`);
  return (
    <div className="d-flex flex-column mb-3" data-testid={testId}>
      <h6 className="bp3-heading">{title}</h6>
      <ButtonGroup>{buttons}</ButtonGroup>
    </div>
  );
}

export default observer(SidebarDaySelect);
