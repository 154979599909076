// @flow

import createAuth0Client from "@auth0/auth0-spa-js";
import { Intent } from "@blueprintjs/core";
import { action, computed, makeObservable, observable } from "mobx";
import jwtDecode from "jwt-decode";
import isEmpty from "lodash.isempty";

import api from "services/Api";
import { AppToaster } from "services/Toaster";

function isCypress(accessToken: string) {
  if (!accessToken) return false;

  const user = jwtDecode(accessToken)["https://flyrlabs.com/"];
  return user && user.email && user.email.includes("acp-dev");
}

export default class Auth {
  @observable lock: ?Object;
  @observable config: ?Object;
  @observable accessToken: ?string;
  @observable user: ?Object;

  @observable isLoginViaPopup: boolean = false;

  constructor(config: Object) {
    makeObservable(this);
    this.config = config;
  }

  @action
  initLock() {
    const { domain, clientId } = this.config || {};
    const audience = `https://${domain}/api/v2/`;

    if (!domain || !clientId) {
      return Promise.reject();
    }

    return createAuth0Client({
      audience,
      client_id: clientId,
      domain,
      redirect_uri: document.location.origin,
      scope: "openid"
    }).then(result => {
      this.lock = result;
    });
  }

  @action
  fetchAccessToken() {
    if (!this.lock) {
      return Promise.reject("Lock is not initialized");
    }

    const isDevelopment = process.env.NODE_ENV === "development";
    const accessToken = localStorage.getItem("accessToken");

    if (accessToken && isDevelopment) {
      this.accessToken = accessToken;
      return Promise.resolve("Token from local storage for dev purposes");
    }

    if (accessToken && isCypress(accessToken)) {
      this.accessToken = accessToken;
      return Promise.resolve("Token from local storage for Cypress testing");
    }

    return this.lock
      .getTokenSilently()
      .then(accessToken => {
        this.accessToken = accessToken;
        if (isDevelopment || isCypress(accessToken)) {
          localStorage.setItem("accessToken", accessToken);
        }
      })
      .catch(() => {
        return Promise.reject("Error fetching access token");
      });
  }

  @action
  authorizeWithPopup() {
    if (!this.lock) {
      return Promise.reject("Lock is not initialized");
    }

    return this.lock
      .loginWithPopup({ prompt: "login" })
      .then(() => this.fetchAccessToken())
      .catch(() => {
        return Promise.reject("Rejected?");
      });
  }

  @action
  authorizeWithToken(accessToken: string) {
    if (!this.lock) {
      return Promise.reject("Lock is not initialized");
    }

    this.accessToken = accessToken;
    localStorage.setItem("accessToken", accessToken);

    return Promise.resolve();
  }

  @action
  logOut() {
    if (!this.lock) {
      return Promise.reject("Lock is not initialized");
    }
    localStorage.removeItem("accessToken");
    this.lock.logout({ returnTo: document.location.origin });
    return Promise.resolve("Logged out");
  }

  @action
  getCurrentUser() {
    if (!this.lock) {
      return Promise.reject("Lock is not initialized");
    }

    return api
      .getCurrentUser()
      .then(response => {
        this.user = {
          ...response.data,
          tabs: response.data.tabs.map(tab => {
            const newTab = { ...tab };
            newTab.flightsTable.selectedRows = [];
            return newTab;
          })
        };

        return this.user;
      })
      .catch(() => {
        return Promise.reject("Error fetching current user");
      });
  }

  @action.bound
  editCurrentUser(metadata: Object, resolve, reject) {
    api
      .editCurrentUser(metadata)
      .then(response => {
        if (resolve && response && response.data) {
          this.user = response.data;
        }
        resolve && resolve();
      })
      .catch(() => {
        AppToaster.show({
          intent: Intent.DANGER,
          message: "Error editing user metadata"
        });
        reject && reject();
      });
  }

  @action.bound
  editCurrentUserName(name: Object, resolve, reject) {
    api
      .editCurrentUser({ name })
      .then(response => {
        if (response && response.data) this.user.name = response.data.name;
        resolve();
      })
      .catch(() => {
        AppToaster.show({
          intent: Intent.DANGER,
          message: "Error editing user metadata"
        });
        reject();
      });
  }

  @action.bound
  editUser(userId: string, metadata: Object) {
    api.editUser(userId, metadata).catch(() => {
      AppToaster.show({
        intent: Intent.DANGER,
        message: "Error editing user metadata"
      });
    });
  }

  @computed
  get isAdmin(): boolean {
    if (!this.user) {
      return false;
    }
    try {
      return this.user.group === "Admin";
    } catch (err) {
      this.logOut();
      AppToaster.show({
        intent: Intent.DANGER,
        message: "No group assigned, please contact admin"
      });
      return false;
    }
  }

  @computed
  get firstTabId(): ?string {
    return !isEmpty(this.user?.tabs) ? this.user?.tabs[0].id : undefined;
  }
}
