// @flow

import React, { useState } from "react";
import clsx from "clsx";
import { observer } from "mobx-react";
import { Classes, Tooltip } from "@blueprintjs/core";
import styled from "@emotion/styled";

import { defaultTabNameRegex } from "models/Tab/Tab.model";
import { useStores } from "store/Store";

const StyledInput = styled("input")`
  box-shadow: none;
  max-height: 28px;
  position: relative;
  width: 100%;
`;

type Props = {
  inputRef: Object,
  isDuplicateName: Object,
  onSave: Function,
  setDuplicateName: Function,
  setFocusedInput: Function,
  tab: Tab
};

function TabLabelInput(props: Props) {
  const { templatesStore } = useStores();
  const { savedAnalyses } = templatesStore;

  const { inputRef, isDuplicateName, onSave, setDuplicateName, setFocusedInput, tab } = props;
  const [activeName, setActiveName] = useState(tab.label);

  const maxLabelInputLength = 50;
  const isMaxLabelLength = activeName.length === maxLabelInputLength;

  const checkSaveViewExistence = (label: string) =>
    savedAnalyses.find(analysis => analysis.label === label) || label.match(defaultTabNameRegex);

  const changeActiveName = (name: string) => {
    if (isDuplicateName.match) {
      setDuplicateName({
        match: true,
        show: checkSaveViewExistence(name),
        type: isDuplicateName.type
      });
    }
    setActiveName(name);
  };

  const onKeyDown = (e: SyntheticKeyboardEvent<HTMLInputElement>) => {
    if (e && e.key === "Escape") {
      setFocusedInput(false);
      setDuplicateName({ match: false, show: false });
      setActiveName(tab.label);
    }

    if (e && e.key === "Enter") {
      const trimmedName = activeName && activeName.trim();
      const newName = trimmedName || tab.label;
      if (isDuplicateName.match && checkSaveViewExistence(newName)) {
        setFocusedInput(true);
      }

      setFocusedInput(false);
      setActiveName(newName);

      tab.renameTab(newName);
      if (isDuplicateName.match && !checkSaveViewExistence(newName)) {
        onSave();
        setDuplicateName({ match: false, show: false });
      }
    }
  };

  const onBlur = () => {
    const trimmedName = activeName && activeName.trim();
    const newName = trimmedName || tab.label;

    tab.renameTab(newName);
    if (isDuplicateName.match && !checkSaveViewExistence(newName)) {
      onSave();
    }

    setActiveName(newName);
    setFocusedInput(false);
    setDuplicateName({ match: false, show: false });
  };

  return (
    <Tooltip
      boundary="viewport"
      className={Classes.TOOLTIP_INDICATOR}
      content={
        !isMaxLabelLength && !isDuplicateName.show ? null : (
          <span>
            {isMaxLabelLength ? `Limit of ${maxLabelInputLength} characters has been reached` : null}
            {isDuplicateName.show && !isMaxLabelLength ? `Specify a unique name to save this analysis` : null}
          </span>
        )
      }
      isOpen={isMaxLabelLength || isDuplicateName.show}
      popoverClassName="sv__tooltip"
    >
      <StyledInput
        ref={inputRef}
        autoFocus
        className={clsx(Classes.INPUT, "px-2")}
        data-testid="tab-label"
        id="tab-label"
        maxLength={maxLabelInputLength}
        onBlur={onBlur}
        onChange={e => changeActiveName(e.target.value)}
        onKeyDown={onKeyDown}
        type="text"
        value={activeName}
      />
    </Tooltip>
  );
}

export default observer(TabLabelInput);
