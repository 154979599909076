import getEnv from "@beam-australia/react-env";
import { Environment } from "modules/App/App.types";

export const INTERCOM_APP_ID = "lok1x2ke";
export const HEAP_ID = {
  integration: "1906242136",
  production: "2662965322"
};
export const LOGROCKET_ID = "s0xmes/web-app-xmqpy";
export const GTAG_ID = {
  integration: "G-P2XRS12QN1",
  production: "G-NEK9TDJM7F"
};

export const SENTRY_RELEASE = getEnv("SENTRY_RELEASE");
export const SENTRY_DSN = {
  integration: "https://3b3aaa933c1049498e30de6a9fc5126a@o308020.ingest.sentry.io/5323597",
  production: "https://defe9b0612434239be736b02a3c8f12f@o308020.ingest.sentry.io/5323599",
  staging: "https://071198d9d0b34c52b2d63a140f926e27@o308020.ingest.sentry.io/5404307"
};

export const env = getEnv("ENV") as Environment;
export const partner = getEnv("PARTNER");

export type ApiConfig = {
  apiUrl: string;
  clientId: string;
  domain: string;
};

const apiConfig: ApiConfig = {
  apiUrl: getEnv("API_URL"),
  clientId: getEnv("CLIENT_ID"),
  domain: getEnv("DOMAIN")
};

export default apiConfig;
