import React from "react";
import styled from "@emotion/styled";

const RowStyled = styled.div`
  margin-right: -0.25rem;
  margin-left: -0.25rem;

  & > [class^="col"] {
    padding-right: 0.25rem;
    padding-left: 0.25rem;
  }
`;

type Props = {
  className?: string;
  children?: React.ReactNode;
}

function Row(props: Props) {
  const { className = "", children = null } = props;

  return <RowStyled className={`row ${className}`}>{children}</RowStyled>;
}

export default Row;
