import React, { Fragment, useState } from "react";
import clsx from "clsx";
import intersection from "lodash.intersection";
import isEmpty from "lodash.isempty";
import kebabCase from "lodash.kebabcase";
import styled from "@emotion/styled";
import { Button, Menu, MenuItem, Position } from "@blueprintjs/core";
import { isValid, parseISO } from "date-fns";
import { MultiSelect, IItemListRendererProps } from "@blueprintjs/select";
import { observer } from "mobx-react";

import formatValueWithUnit from "../../../helpers/formatValueWithUnit/formatValueWithUnit";

type CollectionItem = {
  label: string;
  subregionIds?: number[];
  value: string | number | null;
};

type SelectItem = {
  label: string;
  groupItems: (string | CollectionItem)[];
};

const StyledSidebarSelect = styled("div")`
  display: flex;
  flex-direction: column;
`;

const noResults = () => <MenuItem disabled text="No results." />;

const getDuplications = (
  source: (null | string | number)[],
  arrayToCheck: (CollectionItem | string | null | number)[],
  selectedItems: (CollectionItem | string | null | number)[],
  isItemIsObject: boolean
) =>
  source.filter(name =>
    isItemIsObject
      ? (arrayToCheck as CollectionItem[]).map(item => item.value).includes(name) || selectedItems.includes(name)
      : arrayToCheck.includes(name) || selectedItems.includes(name)
  );

const SEPARATORS = {
  comma: ",",
  semicolon: ";"
};

const SEPARATOR_REGEXES = {
  commaWithSemicolon: /[;,\n\r]/,
  default: /[,\n\r]/
};

interface Props {
  className?: string;
  computedDateFormat?: string;
  computedDateMonthFormat?: string;
  dataTestId?: string;
  filterItems?: Function;
  fuzzySearch?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  isTemporarilyDisabled?: boolean;
  itemRenderer?: Function;
  items: (SelectItem | CollectionItem | string | null | number)[];
  maxItems?: number;
  onChange: Function;
  placeholder?: string;
  popoverClassName?: string;
  popoverProps?: object;
  portalClassName?: string;
  renderTagName?: Function;
  selectedItems: (CollectionItem | string | null | number)[];
  sortFn?: Function;
  tagInputProps?: object;
  title: string;
  withAutoSeparatedItems?: boolean;
}

function SidebarSelect(props: Props) {
  const {
    className,
    computedDateFormat = "yyyy-MM-dd",
    computedDateMonthFormat = "yyyy-MM",
    dataTestId,
    fuzzySearch = false,
    isDisabled = false,
    isLoading = false,
    items = [],
    maxItems = 50,
    onChange,
    placeholder = "Search...",
    popoverClassName = "sidebar-select",
    popoverProps = {},
    portalClassName,
    selectedItems = [],
    sortFn,
    tagInputProps = {},
    title = "",
    withAutoSeparatedItems = false
  } = props;

  const [querySelect, setQuery] = useState("");
  const testId = dataTestId || kebabCase(`${title}-select`);

  const isGrouped = (items as SelectItem[]).every(item => typeof item === "object" && item?.groupItems);
  const isCollection = isGrouped
    ? (items as SelectItem[]).every(({ groupItems }) => groupItems?.every(groupItem => typeof groupItem === "object"))
    : (items as CollectionItem[]).every(item => typeof item === "object" && !Object.keys(item).includes("groupItems"));

  const isDate = typeof items[0] === "string" && isValid(parseISO(items[0]));

  const getValue = item => (typeof item === "object" && item !== null ? item.value : item);
  const getLabel = item => (typeof item === "object" && item !== null ? item.label : item);

  const dateFormats = {
    "departure-month": computedDateMonthFormat,
    "departure-week": computedDateFormat
  };

  const flatItems = (isGrouped ? items.flatMap(({ groupItems }: SelectItem) => groupItems) : items) as (
    | string
    | CollectionItem
  )[];
  const visibleItems = flatItems.filter(item => !selectedItems.map(getValue).includes(getValue(item)));
  const isItemIsObject = typeof flatItems?.[0] === "object";

  const addItem = (itemValue: string | number) => {
    const isSelected = selectedItems.includes(itemValue);
    if (!isSelected) {
      const list = flatItems.map(getValue).filter(item => {
        return item === itemValue || selectedItems.includes(item);
      });
      onChange(list, itemValue);
    }
  };

  const itemRenderer = (
    item: CollectionItem | string,
    itemProps: {
      handleClick: (event) => void;
      index: number;
      modifiers: {
        active: boolean;
        disabled: boolean;
        matchesPredicate: boolean;
      };
      query: string;
    }
  ) => {
    const { handleClick, query, modifiers } = itemProps;
    let text = typeof item === "object" ? item.label : item;

    if (isDate) {
      text = formatValueWithUnit(item, kebabCase(title), dateFormats);
    }

    const queriedText = query
      ? text.replace(query, match => {
          return `<strong>${match}</strong>`;
        })
      : text;

    const itemClassName = clsx("bp3-menu-item", {
      "bp3-active bp3-intent-primary": modifiers.active
    });

    return (
      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
      <li key={text} className={itemClassName} onClick={handleClick}>
        <div
          className="bp3-text-overflow-ellipsis bp3-fill"
          dangerouslySetInnerHTML={{ __html: queriedText }} // eslint-disable-line react/no-danger
        />
      </li>
    );
  };

  const renderItemGroup = (
    group: SelectItem,
    renderItem: (item: any, index: number) => void,
    listProps: IItemListRendererProps<any>
  ) => {
    const { label, groupItems } = group;
    let itemsToRender = [...groupItems];
    let renderInfoItem = false;
    if (typeof sortFn === "function") {
      sortFn(itemsToRender, listProps);
    }

    if (groupItems.length > maxItems) {
      itemsToRender = itemsToRender.splice(0, maxItems);
      renderInfoItem = true;
    }

    return (
      <Fragment key={`group-${label}`}>
        <li className="bp3-menu-header">
          <h4 className="my-2">{label}</h4>
        </li>
        {itemsToRender.map(renderItem)}
        {isEmpty(groupItems) && noResults()}
        {renderInfoItem && <MenuItem key="info-item" disabled text={`${groupItems.length - maxItems} more...`} />}
      </Fragment>
    );
  };

  const itemListRenderer = (listProps: IItemListRendererProps<any>) => {
    const { renderItem, filteredItems, itemsParentRef } = listProps;

    if (isEmpty(filteredItems)) {
      return <Menu ulRef={itemsParentRef}>{noResults()}</Menu>;
    }

    if (!isGrouped) {
      const itemsToRender = intersection(visibleItems, filteredItems);
      if (sortFn) {
        sortFn(itemsToRender, listProps);
      }
      return <Menu ulRef={itemsParentRef}>{itemsToRender.map(renderItem)}</Menu>;
    }

    const groupsToRender = items.reduce((acc, group: SelectItem) => {
      const { label, groupItems } = group;

      if (isEmpty(groupItems)) {
        return acc;
      }
      return [...acc, { groupItems: intersection(groupItems, filteredItems), label }];
    }, []);

    return (
      <Menu ulRef={itemsParentRef}>{groupsToRender.map(group => renderItemGroup(group, renderItem, listProps))}</Menu>
    );
  };

  const removeItem = (item: string, index: number) => {
    if (index >= 0) {
      const list = selectedItems.filter((_, i) => i !== index);
      onChange(list);
    }
  };

  const filterItems = (query, item, index, exactMatch) => {
    const normalizedQuery = query.toLowerCase();
    let normalizedTitle;

    if (typeof item === "object") {
      normalizedTitle = item.label.toLowerCase();
    } else if (isDate) {
      normalizedTitle = formatValueWithUnit(item, kebabCase(title), dateFormats);
    } else {
      normalizedTitle = item.toLowerCase();
    }

    if (exactMatch) {
      return normalizedTitle === normalizedQuery;
    }

    const foundIndex = normalizedTitle.indexOf(normalizedQuery);
    return fuzzySearch ? foundIndex > -1 : foundIndex === 0;
  };

  const clearAllButton = selectedItems.length ? (
    <Button data-testid="clear-all-button" icon="cross" minimal onClick={() => onChange([])} />
  ) : null;

  const renderTagName = (tag: string) => {
    if (isCollection) {
      const foundItemTag = (flatItems as CollectionItem[]).find(item => getValue(item) === tag) || {
        label: "Undefined"
      };
      return foundItemTag.label;
    }
    if (isDate) {
      return formatValueWithUnit(tag, kebabCase(title), dateFormats);
    }
    return tag;
  };

  const addItemBySeparator = (separator: string, query: string) => {
    const pureQuery = query
      .split(separator)
      ?.map(text => text.replace("  ", " ").replace(/\s{2,}/g, "")) // removing extra spaces
      .filter(text => text.length)[0];

    const itemValue = isItemIsObject
      ? (flatItems as CollectionItem[]).find(item => getLabel(item)?.toLowerCase() === pureQuery?.toLowerCase())?.value
      : pureQuery?.toUpperCase();

    if (isDate) {
      let newItem;
      const newList: string[] = [];

      (flatItems as string[]).forEach(item => {
        const formatted = formatValueWithUnit(item, kebabCase(title), dateFormats);
        if (formatted === itemValue) {
          newItem = item;
        }

        if (formatted === itemValue || selectedItems.includes(item)) {
          newList.push(item);
        }
      });

      if (newItem && pureQuery.length) {
        const queryWithSeparator = itemValue + separator;
        const newQuery = query.replace(queryWithSeparator, "");
        onChange(newList, newItem);
        setQuery(newQuery);
      }
    } else {
      const list = flatItems.map(getValue).filter(item => {
        return item === itemValue || selectedItems.includes(item);
      });

      if (list.includes(itemValue) && pureQuery.length) {
        const queryWithSeparator = pureQuery + separator;
        const newQuery = query.replace(queryWithSeparator, "");
        onChange(list, itemValue);
        setQuery(newQuery);
      }
    }
  };

  const onItemsPaste = (items: string[]) => {
    if (withAutoSeparatedItems && items.length) {
      const list = getDuplications(flatItems.map(getValue), items, selectedItems, isItemIsObject);
      items.forEach(itemValue => onChange(list, itemValue));
    }
  };

  const onQueryChange = (query: string) => {
    setQuery(query);

    if (withAutoSeparatedItems && query.length) {
      if (query.includes(SEPARATORS.comma)) {
        addItemBySeparator(SEPARATORS.comma, query);
      } else if (query.includes(SEPARATORS.semicolon)) {
        addItemBySeparator(SEPARATORS.semicolon, query);
      }
    }
  };

  return (
    <StyledSidebarSelect className="mb-3" data-testid={testId}>
      <h6 className="bp3-heading">{title}</h6>
      <MultiSelect
        className={className}
        fill
        itemListRenderer={itemListRenderer}
        // @ts-ignore
        itemPredicate={props.filterItems || filterItems}
        // @ts-ignore
        itemRenderer={props.itemRenderer || itemRenderer}
        items={visibleItems}
        noResults={noResults}
        onItemSelect={item => addItem(getValue(item))}
        onItemsPaste={onItemsPaste}
        onQueryChange={onQueryChange}
        placeholder={isLoading ? "Loading..." : placeholder}
        popoverProps={{
          minimal: true,
          modifiers: {
            // @ts-ignore
            offset: 100
          },
          popoverClassName,
          portalClassName,
          position: Position.BOTTOM,
          ...popoverProps
        }}
        query={querySelect}
        resetOnSelect
        scrollToActiveItem={false}
        selectedItems={selectedItems}
        tagInputProps={{
          disabled: isDisabled || isLoading,
          inputProps: {
            // @ts-ignore
            "data-testid": `${testId}-input`
          },
          onRemove: removeItem,
          // @ts-ignore
          rightElement: clearAllButton,
          separator: withAutoSeparatedItems ? SEPARATOR_REGEXES.commaWithSemicolon : SEPARATOR_REGEXES.default,
          ...tagInputProps
        }}
        // @ts-ignore
        tagRenderer={props.renderTagName || renderTagName}
      />
    </StyledSidebarSelect>
  );
}

export default observer(SidebarSelect);
