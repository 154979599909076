import { observable, action, toJS, computed, makeObservable } from "mobx";
import cloneDeep from "lodash.clonedeep";
import union from "lodash.union";
import difference from "lodash.difference";

import api from "services/Api";
import { Status } from "modules/App/Status";
import { FILTERS_INIT } from "./Cirrus.utils";
import removeValueInFilter from "shared/helpers/removeValueInFilter/removeValueInFilter";
import getEnabledFilters from "shared/helpers/getEnabledFilters/getEnabledFilters";
import toggleFilter from "shared/helpers/toggleFilter/toggleFilter";
import toggleFilterValue from "shared/helpers/toggleFilterValue/toggleFilterValue";
import syncDisabledFilters from "shared/helpers/syncDisabledFilters/syncDisabledFilters";

export const PAGE_INIT = {
  applied: {
    filters: { ...FILTERS_INIT }
  },
  disabledFilters: {},
  filters: { ...FILTERS_INIT },
  filtersEnabled: true,
  sidebar: {
    filterKey: "",
    filterQuery: "",
    isOpen: false
  },
  table: {
    columns: [
      ["status", ["cirrusStatus"]],
      ["flights", ["owMarket", "cabinClass"]]
    ],
    data: [],
    dataStats: {
      disabled: 0,
      enabled: 0
    },
    pagination: {
      pageCount: 1,
      pageIndex: 0,
      pageSize: 25,
      totalRows: 0
    },
    selectedRows: [],
    sortBy: {
      direction: "asc",
      field: "owMarket"
    },
    status: Status.INIT
  }
};

export class CirrusDefaultMarketStore {
  @observable page = cloneDeep(PAGE_INIT);

  constructor() {
    makeObservable(this);
  }

  @action.bound
  toggleFiltersEnabled() {
    this.page.filtersEnabled = !this.page.filtersEnabled;
    this.fetchData();
  }

  @action.bound
  toggleFilter(filterKey: string) {
    toggleFilter(filterKey, this.page.filters, this.page.disabledFilters);
    this.fetchData();
  }

  @action.bound
  toggleFilterValue(filterKey: string, filterValueToToggle: string | number) {
    toggleFilterValue(filterKey, filterValueToToggle, this.page.disabledFilters);

    this.fetchData();
  }

  @action.bound
  setSidebarOpen(isOpen: boolean) {
    const { sidebar } = this.page;
    sidebar.isOpen = isOpen;
    if (!isOpen) {
      sidebar.filterQuery = "";
      sidebar.filterKey = "";
    }
  }

  @action.bound
  setSidebarFilterQuery(filterQuery: string, filterKey: string) {
    const { sidebar } = this.page;
    sidebar.filterQuery = filterQuery;
    sidebar.filterKey = filterKey;
  }

  @action.bound
  fetchData(params = { pageIndex: undefined, pageSize: undefined, sortBy: undefined }) {
    const { table } = this.page;
    const { pageIndex = 0, pageSize = table.pagination.pageSize, sortBy = table.sortBy } = params;

    table.status = Status.LOADING;
    table.pagination.pageIndex = pageIndex;
    table.pagination.pageSize = pageSize;
    table.sortBy = sortBy;

    api
      .getCirrusStatus({
        filters: this.normalizedFilters,
        pagination: {
          offset: pageIndex * pageSize,
          size: pageSize
        },
        sortBy
      })
      .then(({ data }) => {
        const { cirrusStatusSummary, pagination, rows } = data;
        const pageCount = Math.ceil(pagination.totalRows / pageSize);

        table.data = rows;
        table.dataStats = cirrusStatusSummary;
        table.pagination.totalRows = pagination.totalRows;
        table.pagination.pageCount = pageCount;
        table.status = Status.DONE;
      })
      .catch(() => {
        table.status = Status.ERROR;
      });
  }

  @action
  modifyStatus(status: boolean) {
    const { table } = this.page;
    table.status = Status.LOADING;
    table.selectedRows.clear();

    api.switchCirrusStatus({ filters: this.normalizedFilters }, status).then(() => {
      this.fetchData();
    });
  }

  @action
  modifyStatusByIds(rowIds: number[], status: boolean) {
    const { table } = this.page;
    table.status = Status.LOADING;
    table.selectedRows.clear();

    api.switchCirrusStatus({ rowIds }, status).then(() => {
      this.fetchData();
    });
  }

  @action.bound
  resetSearchParam(filterKey: string) {
    this.page.filters[filterKey] = FILTERS_INIT[filterKey];
    if (this.page.disabledFilters[filterKey]) {
      delete this.page.disabledFilters[filterKey];
    }
    this.submitSearchForm();
  }

  @action.bound
  shiftToggleRows(selectedRow: string, clickedRow: string) {
    const { data, selectedRows } = this.page.table;
    const isToggleOn = selectedRows.includes(clickedRow);
    const tableIdRows = data.map(row => `${row.flightId}_${row.fusionrmStatus ? "on" : "off"}`);
    const sliceIndexes = [tableIdRows.indexOf(selectedRow), tableIdRows.indexOf(clickedRow)].sort((a, b) => a - b);
    const actionRows = tableIdRows.slice(sliceIndexes[0], sliceIndexes[1] + 1);

    this.page.table.selectedRows = !isToggleOn ? union(selectedRows, actionRows) : difference(selectedRows, actionRows);
  }

  @action.bound
  changeSelectedRows(selectedRows: string[]) {
    this.page.table.selectedRows.replace(selectedRows);
  }

  @action.bound
  submitSearchForm() {
    const { applied, filters, sidebar, table } = this.page;

    applied.filters = cloneDeep(toJS(filters));
    this.syncDisabledFilters();
    this.fetchData();
    sidebar.isOpen = false;
    sidebar.filterQuery = "";
    table.selectedRows.clear();
  }

  @action.bound
  changeFilter(filterKey: string, value: string | object) {
    this.page.filters[filterKey] = value;
  }

  @action.bound
  removeFilterValue(filterKey: string, valueToRemove: string) {
    const { disabledFilters, filters } = this.page;
    removeValueInFilter(filterKey, valueToRemove, disabledFilters, filters, FILTERS_INIT[filterKey]);
    this.submitSearchForm();
  }

  @action.bound
  syncDisabledFilters() {
    syncDisabledFilters(this.page.disabledFilters, this.page.filters, FILTERS_INIT);
  }

  @computed
  get decoratedPage() {
    const { changeFilter } = this;
    return {
      ...this.page,
      changeFilter
    };
  }

  @computed
  get normalizedFilters() {
    return getEnabledFilters(
      this.page.applied.filters,
      this.page.disabledFilters,
      FILTERS_INIT,
      this.page.filtersEnabled
    );
  }
}
