import styled from "@emotion/styled";
import { Colors } from "@blueprintjs/core";

export const StyledDataTableContainer = styled("div")`
  max-height: 100%;
  overflow: auto;
  width: 100%;
`;

export const StyledDataTable = styled("table")<{ isMac: boolean; showPagination: boolean }>`
  border-spacing: 0;
  width: 100%;

  tbody {
    position: relative;
    z-index: 0;

    tr:last-child {
      margin-bottom: ${({ isMac, showPagination }) => (isMac && showPagination ? "0.5rem" : "0")};
    }
  }

  td {
    font-size: 12px;
  }

  thead {
    position: sticky;
    top: 0;
    z-index: 3;
  }

  th,
  td {
    border-bottom: 1px solid ${Colors.LIGHT_GRAY1};
    border-right: 1px solid ${Colors.LIGHT_GRAY1};
    margin: 0;
    padding: 0.2rem 0.25rem;

    &:last-child {
      border-right: 0;
    }
  }

  th {
    align-items: center;
    display: flex;
    padding: 0.35rem 0.25rem;
  }

  [data-sticky-td] {
    position: sticky;
  }

  [data-sticky-last-left-td] {
    border-right: 2px solid ${Colors.LIGHT_GRAY1};
    box-shadow: 4px 0 3px -1px #75757533;
  }

  [data-sticky-first-right-td] {
    border-left: 2px solid ${Colors.LIGHT_GRAY1};
    box-shadow: -4px 0 3px -1px #75757533;
  }

  input:disabled {
    cursor: not-allowed !important;
  }
`;

export const StyledTfoot = styled("tfoot")`
  background-color: ${Colors.WHITE};
  bottom: 0;
  position: sticky;

  tr {
    border-bottom: 1px solid ${Colors.LIGHT_GRAY1};
    border-top: 1px solid ${Colors.LIGHT_GRAY1};
    min-height: 22px;
    td div {
      background-color: ${Colors.WHITE};
    }

    td:last-of-type div,
    td:first-of-type {
      background-color: ${Colors.LIGHT_GRAY4};
    }
  }
`;
