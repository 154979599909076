import React from "react";
import { Colors, Icon, Intent } from "@blueprintjs/core";
import { observer } from "mobx-react";
import pluralize from "pluralize";
import { useHistory } from "react-router-dom";
import { Status } from "modules/App/Status";

import ModuleItem, { RenderItemContent } from "./ModuleItem";
import { useStores } from "store/Store";
import { AppToaster } from "services/Toaster";
import { tabCountReachedMessage } from "modules/App/messages";

type Props = {
  watchlistItem: {
    data: any;
    label: string;
    status: Status;
    type: string;
  };
};

function WatchlistItem(props: Props) {
  const history = useHistory();
  const { dashboardWatchlistStore, tabsStore } = useStores();
  const { refetchWatchlistItem, selectedMetric } = dashboardWatchlistStore;
  const { canCreateTab, createTabFromSavedAnalysis } = tabsStore;
  const { watchlistItem } = props;
  const { data, label, status, type } = watchlistItem;

  const isTeamAnalysis = type === "teamAnalysis";
  const isSystemAnalysis = type === "systemAnalysis";

  const refetchItem = () => refetchWatchlistItem(watchlistItem);

  const renderIcon = icon => (
    <Icon className="mr-1 vertical-align-baseline" color={Colors.GRAY1} icon={icon} iconSize={16} />
  );

  const titleRender = (
    <>
      {isTeamAnalysis && renderIcon("people")}
      {isSystemAnalysis && renderIcon("cog")}
      {label}
    </>
  );

  const subtitle = `${data.numberOfFlights} ${pluralize("flights", data.numberOfFlights)}`;

  const onClickCreateExplore = () => {
    if (canCreateTab) {
      createTabFromSavedAnalysis(watchlistItem, type);
      history.push(`/analysis/${tabsStore.lastTabId}/explore`);
    } else {
      AppToaster.show({ intent: Intent.WARNING, message: tabCountReachedMessage });
    }
  };

  return (
    <ModuleItem
      onClick={onClickCreateExplore}
      refetchItem={refetchItem}
      status={status}
      subtitle={subtitle}
      title={titleRender}
    >
      <RenderItemContent data={data} selectedMetric={selectedMetric} />
    </ModuleItem>
  );
}

export default observer(WatchlistItem);
