import isEmpty from "lodash.isempty";
import React from "react";
import { observer } from "mobx-react";

import TextButton from "shared/components/TextButton/TextButton";
import { useCurrentTab } from "shared/helpers/useCurrentTab/useCurrentTab";

function CapacityStepSelectionHelpText() {
  const tab = useCurrentTab();
  const { flightsTable } = tab;

  const noRowsSelected = isEmpty(flightsTable.selectedRows);
  const isCabinClassAggregation = flightsTable.aggregations.includes("cabinClass");

  if (noRowsSelected) {
    return <>Please, select rows in the table below to start a capacity impact.</>;
  }

  if (!isCabinClassAggregation) {
    return (
      <span className="d-flex">
        <span>Please&nbsp;</span>
        <TextButton onClick={() => tab.changeAggregation([...flightsTable.aggregations, "cabinClass"])}>
          add Cabin Class aggregation
        </TextButton>
      </span>
    );
  }

  if (!flightsTable.isOnlyOneCabinClassSelected) {
    return (
      <>
        Only one cabin class at a time can be adjusted. Please, make sure you have only rows of the same cabin selected
        in the table below.
      </>
    );
  }

  return null;
}

export default observer(CapacityStepSelectionHelpText);
