import styled from "@emotion/styled";
import React, { useEffect, useRef, useState } from "react";

type Props = {
  position: "right" | "left";
  isResizingModules: boolean;
  onCollapse: () => void;
  onExpand: () => void;
};

const StyledCollapseTrigger = styled("div")<{
  position: Props["position"];
  isResizingModules: Props["isResizingModules"];
}>`
  position: absolute;
  left: ${({ position }) => (position === "right" ? "auto" : 0)};
  right: ${({ position }) => (position === "right" ? 0 : "auto")};
  width: 150px;

  pointer-events: ${({ isResizingModules }) => (isResizingModules ? "auto" : "none")};
`;

function ResizableCollapseTrigger({ position, isResizingModules, onCollapse, onExpand }: Props) {
  const defaultElement = document.createElement("div");
  const ref = useRef(defaultElement);
  const [shouldCollapse, setShouldCollapse] = useState(false);
  const [clientRect, setClientRect] = useState<DOMRect>(defaultElement.getBoundingClientRect());

  const updateElementPosition = () => setClientRect(ref.current.getBoundingClientRect());

  const onMouseMove = (mouseEvent: MouseEvent) => {
    const shouldCollapse =
      position === "right"
        ? mouseEvent.clientX > clientRect.left
        : mouseEvent.clientX < clientRect.left + clientRect.width;

    setShouldCollapse(shouldCollapse);
  };

  useEffect(() => {
    updateElementPosition();
  }, []);

  useEffect(() => {
    if (isResizingModules) {
      shouldCollapse ? onCollapse() : onExpand();
    }
  }, [shouldCollapse]);

  useEffect(() => {
    window.addEventListener("resize", updateElementPosition);

    if (isResizingModules) {
      document.addEventListener("mousemove", onMouseMove);
    } else {
      document.removeEventListener("mousemove", onMouseMove);
    }
    return () => {
      document.removeEventListener("mousemove", onMouseMove);
      window.removeEventListener("resize", updateElementPosition);
    };
  }, [isResizingModules]);

  return <StyledCollapseTrigger ref={ref} isResizingModules={isResizingModules} position={position} />;
}

export default ResizableCollapseTrigger;
