import React from "react";
import { observer } from "mobx-react";

import InfluenceControlTable from "modules/Influence/InfluenceControlTable/InfluenceControlTable";
import InfluenceControlForm from "modules/Influence/InfluenceControlForm/InfluenceControlForm";
import { useStores } from "store/Store";

function InfluenceControlSet() {
  const { influenceStore } = useStores();
  const { data, status, selectedInfluence } = influenceStore;
  const { isPreview, rows } = selectedInfluence;

  return (
    <div className="p-2" data-testid="influence-control-set">
      <InfluenceControlForm />
      {isPreview ? <InfluenceControlTable data={data} rows={rows} status={status} /> : null}
    </div>
  );
}

export default observer(InfluenceControlSet);
