import React, { useMemo } from "react";
import { observer } from "mobx-react";
import { css, Global } from "@emotion/react";

import isEmpty from "lodash.isempty";
import Module from "shared/components/Module/Module";
import DataTable from "shared/components/DataTable/DataTable";
import MarketMappingsButton from "./MarketMappingsButton";
import { useStores } from "store/Store";
import { SortingBy } from "shared/components/DataTable/DataTable.types";

const columnLabels = {
  analystName: "Analyst",
  numberOfFlights: "Number of Flights",
  regionName: "Region",
  rtMarket: "Market",
  subregionName: "Subregion"
};

const sortBy = {
  direction: "asc",
  field: "rtMarket"
};

function MarketMappingsTable() {
  const { marketMappingsStore } = useStores();
  const { table, isLoaded, changeSelectedRows } = marketMappingsStore;

  const conditionalProps = useMemo(() => {
    if (isEmpty(table.data)) {
      return {};
    }

    return {
      pagination: {
        pageCount: 1,
        pageIndex: 0,
        pageSize: table.data.length,
        totalRows: table.data.length
      }
    };
  }, [table.data]);

  return (
    <Module
      childrenClassName="h-100"
      className="market-mappings-table h-100 p-0"
      title="Market Mappings"
      titleTools={isLoaded && <MarketMappingsButton />}
    >
      <Global
        styles={css`
          .market-mappings-table {
            width: 680px;
            thead tr:first-of-type {
              display: none !important;
            }
          }
        `}
      />
      <DataTable
        {...table}
        {...conditionalProps}
        columnLabels={columnLabels}
        onRowToggle={changeSelectedRows}
        onShiftToggle={marketMappingsStore.shiftToggleRows}
        showPagination={false}
        sortBy={sortBy}
        sortingBy={SortingBy.Frontend}
      />
    </Module>
  );
}

export default observer(MarketMappingsTable);
