import React from "react";
import { observer } from "mobx-react";
import { Button, NonIdealState } from "@blueprintjs/core";
import { useHistory } from "react-router-dom";

function ApplicationErrorInformation() {
  const history = useHistory();
  const refreshPage = () => (history ? history.go(0) : window.location.reload());

  return (
    <NonIdealState
      action={
        <Button icon="refresh" onClick={refreshPage}>
          Refresh
        </Button>
      }
      description="It looks like something went wrong. The team has been notified about this issue and will take a look at it shortly. Please, refresh the page."
      icon="build"
      title="Application Error"
    />
  );
}

export default observer(ApplicationErrorInformation);
