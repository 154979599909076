import React from "react";
import { observer } from "mobx-react";
import isEqual from "lodash.isequal";
import { Button, Intent } from "@blueprintjs/core";

import { InfluenceType, PriceLimitsInfluenceParams } from "types/Influence.types";
import { influenceSteps, InfluenceStatus } from "modules/Influence/Influence.model";
import { useStores } from "store/Store";
import { Tab } from "models/Tab/Tab.model";

type Props = {
  disabled: boolean;
  getInfluencePreview: (
    type: InfluenceType,
    inputValue: number | null | PriceLimitsInfluenceParams[],
    params?: { isBaseValue?: boolean }
  ) => void;
  tab: Tab;
  updateInfluence: () => void;
  saveInfluence: () => void;
};

function InfluenceNextButton(props: Props) {
  const { disabled, tab, getInfluencePreview, updateInfluence, saveInfluence } = props;
  const { influenceStore } = useStores();
  const { changeStep, parameters, state, selectedInfluence, status, updateParameters } = influenceStore;
  const { comment, inputValue, value } = parameters;
  const { influenceStep, influenceType } = state;
  const { SELECTION, SET, SAVE, SUMMARY } = influenceSteps;

  const buttonProps = {
    className: "flex-shrink-0",
    intent: Intent.PRIMARY
  };

  if ([SELECTION, SUMMARY].includes(influenceStep)) {
    return null;
  }

  if (influenceStep === SET) {
    const isPreview = selectedInfluence.isPreview;
    const isValueEqual = isEqual(value, inputValue);
    const isPreviewReady = status === InfluenceStatus.DONE_PREVIEW && isValueEqual;

    const setButtonProps = {
      ...buttonProps,
      disabled: disabled || status.includes(InfluenceStatus.LOADING)
    };

    if (!isPreview) {
      return (
        <Button
          {...setButtonProps}
          onClick={() => {
            updateParameters({ value: inputValue });
            changeStep(1);
          }}
        >
          Next
        </Button>
      );
    }

    if (isPreviewReady) {
      return (
        <Button {...setButtonProps} onClick={() => changeStep(1)}>
          Next
        </Button>
      );
    }

    return (
      <Button {...setButtonProps} onClick={() => getInfluencePreview(influenceType, inputValue)}>
        Preview
      </Button>
    );
  }

  // save
  if (influenceStep === SAVE) {
    return (
      <Button
        {...buttonProps}
        disabled={!comment || status.includes(InfluenceStatus.LOADING)}
        onClick={() => {
          if (tab.editInfluence) {
            return updateInfluence();
          }
          saveInfluence();
          changeStep(1);
        }}
      >
        Save
      </Button>
    );
  }

  return null;
}

export default observer(InfluenceNextButton);
